<template>
  <div style="position: fixed; bottom: 16px; z-index: 9;">
    <v-alert
      prominent
      type="error"
      style="width: calc(100vw - 4px);"
    >
      <v-row align="center">
        <v-col class="grow text-left pl-6">
          <span v-html="$t('AlertLoadingError.errorLoadingContent')" />
        </v-col>
        <v-col class="shrink d-flex justify-end">
          <v-btn
            class="ma-1"
            text
            @click="forceUpdate"
          >
            {{ $t('refresh') }}
          </v-btn>
        </v-col>
        <v-col class="shrink d-flex justify-end">
          <v-btn
            class="ma-1 font-weight-bold"
            text
            @click="logout"
          >
            {{ $t('logout') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="errors != null" class="pr-4">
        <v-col cols="auto">
          <v-expand-transition>
            <span v-show="showErrorMessages">
              {{ JSON.stringify(errors, null, 2) }}
            </span>
          </v-expand-transition>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn
            text
            outlined
            @click="showErrorMessages = !showErrorMessages"
          >
            <span v-if="showErrorMessages">
              Hide error
            </span>
            <span v-if="!showErrorMessages">
              Show error
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import Vue from 'vue'
import store from "@/stores";
import rating_mixin from "@/components/mixins/rating_mixin";

export default Vue.extend({
  name: "AlertLoadingError",
  mixins: [rating_mixin],
  props: {
    errors: {type: Array, required: false, default: null}
  },
  data: () => ({
    showErrorMessages: false
  }),
  computed: {
  },
  methods: {
    async forceUpdate() {
      await this.$router.push(
        this.makeTastingRoute(this.tastingRoutes.ciderList, this.getCurrentTasting) + "?reload=true")
      // this.$forceUpdate();
      // location.reload(true);
    },
    async logout () {
      await store.dispatch("usersStore/logout");
    },
  },
});
</script>

<style scoped>

</style>