<template>
  <v-container>
    <v-avatar
      class="ma-2 rounded-xl"
      :color="partner.backgroundColor || 'primary'"
      size="100"
      rounded
    >
      <a
        :href="partner.url"
        target="_blank"
      >
        <v-img
          contain
          max-width="80"
          max-height="80"
          class="pa-0 rounded-0"
          :src="getImageUrl(partner.logo, 500, false, false, false)"
          :lazy-src="getImageUrl(partner.logo, 100, false, false, false)"
          :alt="partner.name"
          style="margin: auto; cursor: pointer;"
        />
      </a>
    </v-avatar>
    <div class="text-center text-caption">
      {{ partner.name }}
    </div>
  </v-container>
</template>

<script>
import logos_mixin from "@/components/mixins/logos_mixin";

export default {
  name: "PartnerAvatar",
  mixins: [logos_mixin],
  props: {
    partner: {type: Object, required: true, default: null},
  },
}
</script>

<style scoped>

</style>