<template>
  <v-tooltip
    v-if="user != null && user.userSettings != null"
    top
  >
    <template #activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
      >
        <v-badge
          v-if="modalShowEasterEgg"
          avatar
          overlap
          label=""
          color="primary"
          icon="mdi-crown"
          left
          :offset-x="size / 2 + 10"
          style="z-index: 9; font-size: 16px !important;"
        >
          <v-avatar
            :size="size"
          >
            <img
              :src="avatar"
              alt="Avatar"
            >
          </v-avatar>
        </v-badge>
        <v-avatar
          v-else
          :size="size"
        >
          <img
            :src="avatar"
            alt="Avatar"
          >
        </v-avatar>
      </div>
    </template>
    <div>
      <span>
        {{ user.firstName }} {{ user.lastName }}
      </span>
      <span
        v-if="modalShowEasterEgg"
        style="font-size: 10px;"
      >
        <br>
        <v-icon
          x-small
          class="mb-1"
        >
          mdi-crown
        </v-icon>
        Has found an Easter Egg!
        <v-icon
          x-small
          class="mb-1"
        >
          mdi-egg-easter
        </v-icon>
      </span>
    </div>
  </v-tooltip>
</template>

<script>
import {User} from "@/types/user";
import {apiTyped} from "@/api";

export default {
  name: "JurorAvatar",
  props: {
    user: {type: User, required: true, default: null},
    size: {type: Number, required: false, default: 48},
    hideEasterEgg: {type: Boolean, required: false, default: false},
    showEasterEgg: {type: Boolean, required: false, default: false},
  },
  computed: {
    modalShowEasterEgg() {
      return (!this.hideEasterEgg && this.user.userSettings.easterEggActive) || this.showEasterEgg;
    },
    avatar() {
      return apiTyped.getAvatarUrl(300, this.user, this.$vuetify.theme.currentTheme)
    },
  },
}
</script>

<style scoped>

</style>