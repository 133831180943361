<template>
  <v-container fluid>
    <v-row v-if="invoice">
      <v-col
        cols="12"
        sm="auto"
      >
        <div class="text-left text-h5">
          Invoice {{ invoice.invoiceNumber}}
        </div>
        <div class="d-flex justify-start pt-2 flex-gap align-center">
          <v-chip
            outlined
            text-color="text_std"
            small
            :color="invoiceStatusColor(invoice.status)"
            class="pr-2 text--secondary"
          >
            <v-icon
              small
              left
            >
              {{ invoiceStatusIcon(invoice.status) }}
            </v-icon>
            {{ invoiceStatusText(invoice.status) }}
          </v-chip>
          <div class="text--secondary text-body-2 pl-3">
            Created {{ invoice.dateCreated.toLocaleString() }}
          </div>
        </div>

      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="d-flex justify-center"
        style="min-width: 560px !important;"
      >
        <vue-pdf-embed
          v-if="fileUrl != null"
          height="800"
          :source="fileUrl"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import purchase_mixin from "@/components/mixins/purchase_mixin";
import {apiTyped} from "@/api";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "InvoiceDetail",
  components: {VuePdfEmbed},
  mixins: [purchase_mixin],
  props: {
    invoice: { type: Object, required: true, default: null }
  },
  data: () => ({
    fileUrl: null,
    fileBlob: null,
  }),
  async created() {
    console.log("Getting file", this.invoice.invoicePrintId);
    this.fileUrl = apiTyped.getFile(this.invoice.invoicePrintId);
    // this.fileBlob = await fetch(fileUrl).then(res => res.blob());
  }
}
</script>

<style scoped>

</style>