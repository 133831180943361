import Vue from 'vue';
import Vuex from 'vuex';
import {make} from 'vuex-pathify';
import {RoleName, Theme, User} from "@/types/user";
import {Tasting} from "@/types/tasting";
import router from "@/router";
import {apiTyped} from "@/api";
import i18n from '@/i18n'
import store, {rootGetter} from "@/stores";

Vue.use(Vuex);


type storeStates = typeof state;
// @ts-ignore
type storeGetters = typeof getters;
type rootStates = typeof store.state;
type rootGetters = typeof rootGetter;

const state = {
    me: <User><unknown>undefined,
    theme: Theme.Auto,
    DEFAULT_LANG: 'en-US',
    language: 'en-US',
    tastings: Array<Tasting>(),
    // currentTastingIdx: 0,
    currentTastingId: -1,
    showUserMenu: false,
}

const mutations = {
    ...make.mutations(state),
    setUser(state: storeStates, user: User) {
        state.me = user;
        i18n.locale = user.language?.split("-")[0] || i18n.locale;
    },
    updateTasting(state: storeStates, tasting: Tasting) {
        const updateTastingIdx = state.tastings.findIndex(t => t.id === tasting.id);
        if (updateTastingIdx === -1) return;
        Vue.set(state.tastings, updateTastingIdx, tasting);
    }
}

// @ts-ignore
const getters = {
    ...make.getters(state),
    user: (state: storeStates, getters: storeGetters, rootState: rootStates, rootGetter: rootGetters) =>
            (): User | null => {
        if (state.me == null) return null;
        if (state.me.role === RoleName.Admin && rootGetter["adminStore/emulatedUserActive"]()) {
            return rootGetter["adminStore/getEmulatedUser"]();
        } else {
            return state.me;
        }
    },
    userId: (state: storeStates, getters: storeGetters, rootState: rootStates, rootGetter: rootGetters) =>
            (): string | null => {
        if (state.me.role === RoleName.Admin && rootGetter["adminStore/emulatedUserActive"]()) {
            return rootGetter["adminStore/getEmulatedUserId"]();
        } else {
            return state.me.id;
        }
    },
    fullName: (state: storeStates, getters: storeGetters) => (): string => {
        const me = getters["user"]();
        if (me == null) return "Unauthorized";
        return `${me.firstName} ${me.lastName}`;
    },
    isChair: (state: storeStates, getters: storeGetters, rootState: rootStates, rootGetter: rootGetters) =>
            (): boolean => {
        const me = getters["user"]();
        if (me == null) return false;
        const table = rootGetter["tableStore/getCurrentTable"]();
        if (table == null) return false;
        let chairUserId;
        if (typeof table.chair === "string") chairUserId = table.chair;
        else chairUserId = table.chair.id;
        return me.id === chairUserId;
    },
    getUserLanguage: (state: storeStates, getters: storeGetters) => (): string => {
        const me = getters["user"]();
        if (me == null) return state.language;
        else return me.language || 'en-US';
    },
    getUserLanguageCode: (state: storeStates, getters: storeGetters) => (): string => {
        return getters.getUserLanguage().split("-")[0] || i18n.locale;
    },
    getUserTheme: (state: storeStates) => (): Theme | undefined => {
        const me = getters["user"]();
        if (me === undefined || me.theme === undefined) {
            return state.theme;
        }
        if (me.theme === Theme.Dark) {
            return Theme.Dark;
        }
        if (me.theme === Theme.Light) {
            return Theme.Light;
        }
        if (me.theme === Theme.Auto) {
            return Theme.Auto;
        }
        return state.theme;
    },
    currentTasting: (state: storeStates) => (): Tasting | undefined => {
        return state.tastings.find(t => t.id === state.currentTastingId);
        // return state.tastings[state.currentTastingIdx];
    },
    getTastingIdxByTastingId: (state: storeStates) => (tastingId: number | null | undefined): number | undefined => {
        if (tastingId == null || isNaN(tastingId)) return 0;
        return state.tastings.findIndex(t => t.id === tastingId) || 0;
    },
}

const actions = {
    async logout(context: any, route = "/login"): Promise<void> {
        await apiTyped.logout();
        context.state.me = undefined;
        console.log("Logging app Store out")
        context.commit("appStore/logout", null, { root: true });
        // context.rootState.appStore.authenticated = false;
        // context.commit("appStore/authenticated", false);
        // context.rootState.appStore.username = null;
        // context.commit("appStore/username", null);
        // context.rootState.appStore.password = null;
        // context.commit("appStore/password", null);
        context.rootState.active_tab = 0;
        console.log("Logged out:", context.rootState.appStore)
        if (route != null) await router.push(route)
    },
    async setLanguage(context: any, languageCode: string) {
        context.commit('language', languageCode);
        if (context.getters["user"]() != null) {
            const newLanguage = await apiTyped.setLanguage(languageCode);
            if (newLanguage != null) {
                context.getters["user"]().language = newLanguage;
                context.commit('me', context.state.me);
            }
        }
        console.log("Setting new lang", context.getters.getUserLanguageCode())
        i18n.locale = context.getters.getUserLanguageCode();
    },
    async setTheme(context: any, theme: Theme) {
        context.state.theme = theme;
        if (context.getters["user"]() != null) {
            await apiTyped.updateTheme(theme);
            context.state.me.theme = theme;
        }
    }
}

export default {
    namespaced: true, // add this if in module
    state,
    getters,
    mutations,
    actions,
}