<template>
  <div v-if="pageData !== null">
    <div
      v-for="(cidersGroup, cidersIndex) in pageData"
      :key="cidersIndex"
      class="d-flex flex-column"
    >
      <page
        size="A4"
        style="position: relative;"
      >
        <div
          style="overflow: visible !important;"
          class="d-flex flex-column"
        >
          <RanglisteHeader
            v-if="index === 0 && cidersIndex === 0"
            :cider="Object.values(pageData[0])[0][0]"
            :style="{'max-height': headerHeightPx}"
            class="pa-5"
          />
          <v-data-table
            v-for="(ciders, idx) in Object.values(cidersGroup)"
            :key="idx"
            :ref="`table_${index + cidersIndex}`"
            :items="ciders"
            hide-default-footer
            disable-pagination
            :headers="headers"
            sort-by="totalPoints"
            :sort-desc="true"
            hide-default-header
            class="pa-0 pl-5 pr-5"
            style="background-color: white !important; overflow: visible !important; padding-bottom: 0px !important;"
          >
            <template #header="{ props: { headers } }">
              <thead
                class="v-data-table-header"
                style="overflow: hidden !important; background-color: white !important; display: table-header-group;"
              >
                <tr>
                  <th
                    v-for="(header, headerIndex) in headers"
                    :key="header.value"
                    scope="col"
                    class="table_header pt-6 pb-4"
                    :style="{'min-width': header.width + '!important', 'width': header.width + '!important',
                             'text-align': header.align ? header.align : 'left'}"
                    style="overflow: visible !important;"
                  >
                    {{ header.text }}
                    <div
                      v-if="headerIndex === 0"
                      style="position: relative; overflow: visible !important;"
                    >
                      <badge
                        :badge-data="ciders[0].badgeData"
                        :show-none-badge="true"
                        :badge-resolution="2000"
                        :badge-shadow="false"
                        :badge-width="badgeWidth - 2*16"
                        class="overflow-visible"
                        style="position: absolute; top: 25px; left: 0px;"
                      />
<!--                      <v-img-->
<!--                        :max-height="badgeWidth - 2*16 + 'px'"-->
<!--                        :max-width="badgeWidth - 2*16 + 'px'"-->
<!--                        :src="$store.getters['badgesStore/getBadgeIcon'](badgePoints(ciders), 500)"-->
<!--                        alt="Badge"-->
<!--                        style="position: absolute; top: 25px; left: 0px;"-->
<!--                      />-->
                    </div>
                  </th>
                </tr>
              </thead>
            </template>
            <template #item="props">
              <tr>
                <td
                  v-for="col in headers"
                  :key="col.value"
                  style="vertical-align: top"
                  :style="{'text-align': col.align + '!important'}"
                >
                  <span style="text-align: left !important">
                    {{ deepValue(props.item, col.value) }}
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <RanglisteFooter
          style="width: 29.7cm;"
          :kategorie="cidersGrouped[0][0].kategorie.name"
          class="divFooter pl-4 pr-4 pb-3 overflow-visible"
        />
      </page>
    </div>
  </div>
</template>

<script>
import logos_mixin from "@/components/mixins/logos_mixin";
import cider_mixin from "@/components/mixins/cider_mixin";
import RanglisteHeader from "@/components/admin/rangliste/rangliste_header";
import RanglisteFooter from "@/components/admin/rangliste/rangliste_footer";
import {groupBy} from "@/util/helper";
import Badge from "@/components/badge";

export default {
  name: "RanglisteTable",
  components: {
    Badge,
    RanglisteFooter,
    RanglisteHeader
  },
  mixins: [logos_mixin, cider_mixin],
  props: {
    cidersGrouped: {type: Array, required: true, default: () => null},  // in shape array[<badges>][<ciders>]
    index: {type: Number, required: true, default: () => null},
    isFirst: {type: Boolean, required: true, default: () => null},
    isLast: {type: Boolean, required: true, default: () => null},
  },
  data: () => ({
    badgeWidth: 150,
    firstPageMarginPx: 190,
    headerHeightPx: 72,
    headerMarginPx: 64,
    rowHeightPx: 48,
    pageData: null,
  }),
  computed: {
    // allCidersPrepped() {
    //   return this.ciders.map((cider, index) => {
    //     cider.uniqueId = index;
    //     cider.totalPoints = this.calculateTotalPointsForCider(cider);
    //     return cider;
    //   });
    // },
    headers() {
      return [
        {text: "Award", value: '', align: 'center', sortable: false, groupable: false, width: "150px"},
        {text: "Producer", value: 'produzent', align: 'right', sortable: false, groupable: false},
        {text: 'Country', value: 'land.name', align: 'left', sortable: false, groupable: false},
        {text: 'Product', value: 'name', align: 'left', sortable: false, groupable: false},
        {text: 'Points', value: 'totalPoints', align: 'left', sortable: false, groupable: false, width: "96px"},
      ]
    }
  },
  watch: {
    cidersGrouped () {
      this.calcPageCiders();
    },
  },
  created() {
    // const allCiders = this.cidersGrouped.reduce((partialSum, ciders) => partialSum + ciders.length, 0);
    this.calcPageCiders();
  },
  methods: {
    padding_footer(cidersIndex) {
      console.log("HEADERS", this.$refs[`table_${cidersIndex}`]);
      return "100px";
    },
    badgePoints(ciders) {
      return ciders[0].totalPoints || 0;
    },
    deepValue(obj, path){
      // console.log("PATH", path, obj)
      if (path.search(".") === -1) return obj[path];
      path = path.split('.')
      for (let i=0, len=path.length; i<len; i++) {
        if (path[i] !== undefined && obj[path[i]] !== undefined && obj[path[i]] !== null)
          obj = obj[path[i]];
        else
          obj = "--";
      }
      // console.log("output deepValue", obj)

      return obj
    },
    pxToCm(px) {
      return px * 0.026458333333333334;
    },
    cmToPx(cm) {
      return cm * 37.7952755905511801;
    },
    calcPageCiders() {
      console.log("Calc page")
      let availableSpaceCm = 21 - this.pxToCm(this.headerHeightPx + (this.isFirst ? this.firstPageMarginPx : 0));
      let pageCiders = [];
      let currentPageCiders = [];
      const newPageAtCm = this.pxToCm(64);
      const allCidersFlat = this.cidersGrouped.flatMap(c => c).sort(
        (c1, c2) => c2.totalPoints - c1.totalPoints);
      console.log("All ciders", allCidersFlat)
      while (allCidersFlat.length > 0) {
        const currentCider = allCidersFlat.shift();  // first element
        // check if new badge and add size
        if (currentPageCiders.length > 0
            && currentPageCiders[currentPageCiders.length - 1].badgeData.punkteMin !== currentCider.badgeData.punkteMin) {
          availableSpaceCm -= this.pxToCm(this.headerMarginPx + this.headerHeightPx);
        }
        console.log("Added cider", currentCider.name, "available space", availableSpaceCm)
        // add cider to row
        // Check if available space is enough, new page at 8.5mm margin bottom
        if (availableSpaceCm - this.pxToCm(this.rowHeightPx) < newPageAtCm) {
          console.log("Starting new page", pageCiders, currentPageCiders)
          currentPageCiders.push(currentCider);
          pageCiders.push(currentPageCiders);
          currentPageCiders = [];
          availableSpaceCm = 21 - this.pxToCm(this.headerHeightPx);
          console.log("Started new page", pageCiders, currentPageCiders)
        } else {
          availableSpaceCm -= this.pxToCm(this.rowHeightPx);
          currentPageCiders.push(currentCider);
        }
      }
      // Push the remaining ciders to all
      pageCiders.push(currentPageCiders);
      console.log("Rest ciders", pageCiders, currentPageCiders);
      // split by badge again
      this.pageData = pageCiders.map(ciders => {
        const g = groupBy(ciders, cider => cider.badgeData.punkteMin);
        const sortedKeys = Object.keys(g).sort((k1, k2) => Number(k2) - Number(k1)).map(n => Number(n));
        return sortedKeys.map(k => g[k]);
      }).filter(p => p.length !== 0);
    }
  }
}
</script>

<style scoped>
/*table { page-break-after:auto }*/
/*tr    { page-break-inside:avoid; page-break-after:auto }*/
/*td    { page-break-inside:avoid; page-break-after:auto }*/
/*thead { display: table-row-group; }*/
/*tfoot { display:table-footer-group }*/

/*thead {display: table-header-group;}*/
/*tfoot {display: table-footer-group;}*/

@media screen {
  div.divFooter {
    /*display: none;*/
    position: absolute;
    bottom: 0;
    left: 0;
    margin-top: 0 !important;
  }
}

@media print {
  div.divFooter {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

@page {
    margin: 0 0 0 0 !important;
}

/*@media print {*/
/*  thead {*/
/*    display: table-header-group;*/
/*  }*/
/*}*/


page[size="A4"] {
  width: 29.7cm !important;
  height: 21cm;
  min-height: 21cm;
  min-width: 29.7cm !important;
  max-height: 21cm;
  max-width: 29.7cm !important;
}

page {
  background: white;
  /*display: block;*/
  margin: 0 auto 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
}

@media print {
  page {
    position: absolute;
    top: 0;
    width: 29.7cm !important;
    height: 21cm !important;
    min-height: 21cm !important;
    min-width: 100%;
    max-height: 21cm !important;
    max-width: 100%;
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
    box-shadow: unset !important;
    background-color: white !important;
  }
}

@media print {
  html, body {
    height: 100%;
  }
}

.table_header {
  color: black !important;
  font-weight: normal !important;
  font-size: large !important;
}

/deep/ .table_header:after {
  content: ""; /* This is necessary for the pseudo element to work. */
  display: block; /* This will put the pseudo element on its own line. */
  width: 100%; /* Change this to whatever width you want. */
  /*border-bottom: 3px solid var(--v-logo_green-base) !important;*/
}

/deep/ .v-data-table {
  /*min-height: 100% !important;*/
  overflow: visible !important;
  background-color: white !important;
}

/deep/ .v-data-table > .v-data-table__wrapper {
  min-height: 200px !important;
  overflow: visible !important;
  background-color: white !important;
}

/deep/ .v-data-table thead tr:last-child th {
  border-bottom: none !important;
  /*text-decoration: underline var(--v-primary-base);*/
  /*text-underline-offset: 10%;*/
  /*text-decoration-thickness: 3px;*/
  /*border-bottom: 3px solid var(--v-primary-base) !important;*/
  /*margin-right: 12px !important;*/
}

/deep/ .table_header {
  position: relative;
}
/deep/ .table_header:after {
  position: absolute;
  content: '';
  width: calc(100% - 16px);
  border-bottom: 3px solid var(--v-logo_green-base) !important;
  /*transform: translate(-0%, -24px);*/
  bottom: 12px;
  left: 16px;
}

.v-data-table thead tr:last-child th,
.v-data-table tbody tr:not(:last-child) td:last-child,
.v-data-table tbody tr td,
.v-data-table tbody th:not(.table_header) tr:not(:last-child) td:not(.v-data-table__mobile-row) /deep/  {
  /*border-bottom: none !important;*/
}

/deep/ .v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}

.min_top_hidden {
  min-height:40px;
  margin-bottom:-40px;
}
.min_top {
  margin-top:40px;
}

</style>