<template>
  <v-container fluid>
    <PageNavigation
      :title-translation="getCurrentTasting.name"
      :breadcrumbs="[{text: $t('NavJuror.dashboard'), to: allRoutes.tastings.my},
                     {text: $t('NavJuror.productOverview'),
                      to: makeTastingRoute(allRoutes.tastings.ciderList, getCurrentTasting)},
                     {text: $t('compareProductResults'), to: '', disabled: true}]"
      class="pb-6 pl-3"
    />

    <div
      class="ma-auto pl-md-4 pr-md-4"
    >
      <judges-cider-table
        ref="ciderTableMain"
        :products="allCidersPrepped || []"
        :loading-products="loading"
        :show-select="false"
        single-select
        hide-legend
        hide-flight-select
        class="pb-4"
        initial-group-by="kategorie.name"
        @clickRow="row => selectRow(row, 'main')"
      />
    </div>

    <v-card
      v-for="(productRatings, index) in productsGroupedByCategory"
      :key="'productRating' + productRatings[0].flightsCidersId"
      class="ma-0 mt-4 mb-4 pb-3 ml-md-4 mr-md-4"
      outlined
    >
      <v-data-table
        v-if="productRatings.length > 0"
        :ref="'table' + productRatings[0].kategorie.id"
        v-model="selectedProductGrouped[index]"
        :headers="headersOfCategory(productRatings[0].kategorie)"
        :items="productBewertungen(productRatings)"
        single-select
        mobile-breakpoint="0"
        disable-pagination
        hide-default-footer
        item-key="flightsCidersId"
        @click:row="(item, row) => selectRow({item: item, row: row}, null)"
      >
        <template #top>
          <div
            class="d-flex justify-start pl-4 pt-4 text-h5"
          >
            <span>
              {{ getTranslation(productRatings[0].kategorie.translationMessages, 'name') }}
            </span>
            <v-switch
              v-model="showWeightedPointsGrouped[index]"
              inset
              class="ml-auto mt-0 pr-4"
            >
              <template #label>
                <span
                  v-if="!showWeightedPointsGrouped[index]"
                  class="text-caption"
                >
                  {{ $t('showWeightedScores') }}
                </span>
                <span
                  v-if="showWeightedPointsGrouped[index]"
                  class="text-caption"
                >
                  {{ $t('hideWeightedScores') }}
                </span>
              </template>
            </v-switch>
          </div>
        </template>
        <template #header>
          <thead class="v-data-table-header">
            <tr>
              <th
                colspan="2"
                style="border: 0;"
              />
              <th
                v-for="bewertungskategorie in productRatings[0].kategorie.bewertungskategorien"
                :key="bewertungskategorie.id"
                :colspan="bewertungskategorie.bewertungen.length"
                class="px-3"
                style="border-color: var(--v-divider-base); border-style: solid; border-width: 0 0 1px 1px !important;"
              >
                <div
                  class="d-flex justify-center"
                >
                  {{ getTranslation(bewertungskategorie.translationMessages, 'pruefmerkmal') }}
                </div>
              </th>
            </tr>
          </thead>
        </template>

        <template #item.totalPoints="{ item }">
          <ChipPoints
            :points="item.totalPoints || 0"
            :dark-background="false"
          />
        </template>

        <template
          v-for="eigenschaft in allEigenschaftenHeaders(productRatings[0].kategorie)"
          #[makeSlotEigenschaftName(eigenschaft)]="{ item }"
        >
          <div
            :key="eigenschaft"
            class="d-flex flex-row justify-center align-center pa-1"
          >
            <!--            <v-chip-->
            <!--              :color="getBestBewertungForProducts(productBewertungen, getEigenschaftIdFromHeader(eigenschaft)) === item[eigenschaft] ? 'primary' : 'on_surface_variant'"-->
            <!--              outlined-->
            <!--              label-->
            <!--              style="min-width: 48px; border-radius: 10px !important;"-->
            <!--              class="pa-1 justify-center"-->
            <!--            >-->
            <!--              <v-icon-->
            <!--                small-->
            <!--                class="mr-1"-->
            <!--              >-->
            <!--                mdi-star-->
            <!--              </v-icon>-->
            <!--              {{ item[eigenschaft + 'Raw'] }}-->
            <!--            </v-chip>-->

            <v-chip
              v-show="!showWeightedPointsGrouped[index]"
              :color="getBestBewertungForProducts(productBewertungen(productRatings), getEigenschaftIdFromHeader(eigenschaft)) === item[eigenschaft] ? 'primary' : 'divider'"
              label
              style="min-width: 48px; margin:0 auto; border-radius: 10px !important;"
              class="pl-1 pr-1 align-center justify-center ml-0 mr-0"
              :style="{color: getBestBewertungForProducts(productBewertungen(productRatings), getEigenschaftIdFromHeader(eigenschaft)) === item[eigenschaft] ? 'var(--v-text_std_inv-base)' : 'var(--v-text_std-base)'}"
            >
              <v-icon
                small
                class="mr-1"
              >
                mdi-star-outline
              </v-icon>
              {{ item[eigenschaft + 'Raw'] }}
            </v-chip>
            
            <v-chip
              v-show="showWeightedPointsGrouped[index]"
              label
              small
              color="divider"
              class="pr-0 ml-0 pl-3 mb-1 mt-1"
              style="overflow: visible; border-radius: 8px !important;"
            >
              <span
                class="font-weight-light justify-end"
                style="transform: translateX(-4px);"
              >
                x {{ item[eigenschaft + 'Faktor'] }}
              </span>
              <v-chip
                :color="getBestBewertungForProducts(productBewertungen(productRatings), getEigenschaftIdFromHeader(eigenschaft)) === item[eigenschaft] ? 'primary' : 'divider'"
                label
                style="min-width: 48px; margin:0 auto; border-radius: 10px !important;"
                class="pl-1 pr-1 align-center justify-center ml-0 mr-0"
                :style="{color: getBestBewertungForProducts(productBewertungen(productRatings), getEigenschaftIdFromHeader(eigenschaft)) === item[eigenschaft] ? 'var(--v-text_std_inv-base)' : 'var(--v-text_std-base)'}"
              >
                <v-icon
                  small
                  class="mr-1"
                >
                  mdi-star-outline
                </v-icon>
                {{ item[eigenschaft] }}
              </v-chip>
            </v-chip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import Vue from 'vue';
import {get} from "vuex-pathify";
import JurorCider from "@/types/jurorCider";
import {apiTyped} from "@/api";
import rating_mixin from "@/components/mixins/rating_mixin";
import JudgesCiderTable from "@/components/judges_cider_table.vue";
import cider_mixin from "@/components/mixins/cider_mixin";
import user_mixin from "@/components/mixins/user_mixin";
import PageNavigation from "@/components/page_navigation.vue";
import i18n from "@/i18n";
import util from "@/util";
import ChipPoints from "@/components/chip_points.vue";
import {groupBy} from "@/util/helper";
import translation_mixin from "@/components/mixins/translation_mixin";

export default {
  name: "CompareProductResults",
  components: {ChipPoints, PageNavigation, JudgesCiderTable},
  mixins: [rating_mixin, cider_mixin, user_mixin, translation_mixin],
  props: {
    products: {type: [Array, String], required: true, default: null},
  },
  data: () => ({
    loading: true,
    productResults: null,
    showWeightedPoints: true,
    selectTimeout: null,
    selectedRows: [],
    selectedProductGrouped: [],
    showWeightedPointsGrouped: [],
  }),
  i18n: {
    messages: {
      de: {
        'showWeightedScores': 'Faktoren anzeigen',
        'hideWeightedScores': 'Faktoren ausblenden',
      },
      en: {
        'showWeightedScores': 'Show weighted Scores',
        'hideWeightedScores': 'Hide weighted Scores',
      }
    }
  },
  computed: {
    kategorien: get("kategorienStore/kategorieData"),
    productsGroupedByCategory() {
      if (this.productResults == null || this.productResults.length === 0) return [];
      const productsGroupedByCategory = Object.values(groupBy(this.productResults, p => p.kategorie.id)).sort(
        (g1, g2) => this.getTranslation(g1[0].kategorie.translationMessages, 'name').localeCompare(
          this.getTranslation(g2[0].kategorie.translationMessages, 'name')
        ));
      return productsGroupedByCategory;
    },
    flightCidersIds() {
      let p = this.products;
      if (!Array.isArray(this.products)) p = [this.products];
      return p.map(p => Number(p)) || [];
    },
    allCidersPrepped() {
      if (this.productResults == null) return [];
      const preppedCiders = this.productResults.map((cider, index) => {
        cider.uniqueId = index;
        cider.totalPoints = cider.points;
        console.log("CIDER PO", cider.points)
        cider.badgeData = this.$store.getters['badgesStore/getBadgeData'](cider.totalPoints);
        cider.stateIcon = this.getCiderStateIcon(cider);
        cider.stateText = this.getCiderStateText(cider);
        if (cider.land === undefined || cider.land === null) cider.land = {name: "--", code: "--"};
        return cider;
      }).sort((p1, p2) => p2.points - p1.points);
      return preppedCiders;
    },
  },
  async created() {
    // get full ratings
    const isFinished = this.getCurrentTasting.finished;
    const flightsCiders = await apiTyped.getFlightsCiders(this.flightCidersIds, true, isFinished);
    const currentTable = this.$store.getters["tableStore/getCurrentTable"]();
    flightsCiders.forEach(f => f.flights_id = currentTable.flights.find(f_ => f_.id === f.flights_id));
    const products = flightsCiders.map(
      f => JurorCider.fromApiData(
        f.ciders_id,
        this.kategorien.find(k => Number(k.id) === Number(f.ciders_id.kategorie)),
        f,
        this.$store.getters["usersStore/userId"](),
        false,
        []
      )
    );
    this.productResults = products;
    this.selectedProductGrouped = this.productsGroupedByCategory.map(_ => []);
    this.showWeightedPointsGrouped = this.productsGroupedByCategory.map(_ => true);
    this.loading = false;
  },
  methods: {

    selectRow(data, source) {
      this.selectedProductGrouped = this.productsGroupedByCategory.map(_ => []);  // clear

      const allTablesRefs = this.productsGroupedByCategory.flatMap(
        group => "table" + group[0].kategorie.id);
      const allTables = allTablesRefs.map(ref => this.$refs[ref]).flat(1).filter(r => r != null);
      // allTables.forEach(t => t.value = []);  // deselect in all rating tables
      // if (this.selectTimeout != null) {
      //   window.clearTimeout(this.selectTimeout);
      // }

      this.selectedRows.forEach(r => r.select(false));

      data.row.select(true);
      this.selectedRows.push(data.row);

      // this.selectTimeout = window.setTimeout(() => {
      //   data.row.select(false);
      //   const index = this.selectedRows.indexOf(data.row);
      //   if (index > -1) {  // only splice array when item is found
      //     this.selectedRows.splice(index, 1);
      //   }
      // }, 2500);

      if (source == null) {
        // select in main table
        this.$refs.ciderTableMain.selectRow(data.item.flightsCidersId);
        this.$vuetify.goTo(this.$refs.ciderTableMain);
      } else {
        for (const [index, productsGroupedByCategory] of this.productsGroupedByCategory.entries()) {
          const found = productsGroupedByCategory.find(p => p.flightsCidersId === data.item.flightsCidersId);
          console.log("ITERA", productsGroupedByCategory, data.item.flightsCidersId, found)
          if (found != null) {
            Vue.set(this.selectedProductGrouped, index, [found]);
            // find table
            const table = this.$refs["table" + data.item.kategorie.id];
            console.log("GOOTOTT", table)
            if (table != null && table.length > 0) this.$vuetify.goTo(table[0]);
            break;
          }
        }
      }
      console.log("select", allTables.map(t => t))
    },
    productBewertungen(products) {
      if (products == null) return [];
      return products.map(p => {
        const eigenschaften = this.allEigenschaften(p.kategorie);
        const allBewertungen = Object.values(p.allBewertungen).flat(1);
        const resultObj = {
          totalPoints: p.points,
          displayId: p.displayId,
          flightsCidersId: p.flightsCidersId,
        };
        eigenschaften.forEach(e => {
          const allBewertungenForEigenschaft = allBewertungen.filter(b => b.eigenschaftId === e.eigenschaftId);
          const sum = this.$store.getters['ciderStore/getPointsFromBewertungen'](allBewertungenForEigenschaft);
          const sumRounded = util.util.round_to_decimal(sum / allBewertungenForEigenschaft.length, 0.1);
          resultObj[`eigenschaft${e.eigenschaftId}`] = isNaN(sumRounded) ? '--' : sumRounded;
          resultObj[`eigenschaft${e.eigenschaftId}Faktor`] = e.faktor;
          resultObj[`eigenschaft${e.eigenschaftId}Raw`] = util.util.round_to_decimal(sumRounded / e.faktor, 0.1);
        })
        return resultObj;
      }).sort((r1, r2) => r2.totalPoints - r1.totalPoints);
    },
    getBestBewertungForProducts(productBewertungen, eigenschaftId) {
      console.log("GET MAX", productBewertungen, eigenschaftId)
      return Math.max(...productBewertungen.map(p => p[`eigenschaft${eigenschaftId}`]).filter(score => !isNaN(score)));
    },
    allEigenschaften(kategorie) {
      return kategorie.bewertungskategorien.flatMap(b => b.bewertungen);
    },
    allEigenschaftenHeaders(kategorie) {
      return this.allEigenschaften(kategorie).map(e => `eigenschaft${e.eigenschaftId}`);
    },
    getEigenschaftIdFromHeader(e) {
      return Number(e.replace("eigenschaft", ""));
    },
    makeSlotEigenschaftName(eigenschaft) {
      return 'item.' + eigenschaft;
    },
    headersOfCategory(kategorie) {
      return [
        { text: i18n.t('nr'), value: 'displayId', align: 'left', sortable: true, groupable: false },
        { text: i18n.tc('point', 2), value: 'totalPoints', align: 'center', sortable: true, groupable: false },
        ...(kategorie.bewertungskategorien.flatMap(bewkat => bewkat.bewertungen.map(
          (b, index) => {
            const header = {
              text: this.getTranslation(b.translationMessages, 'bezeichnung'), value: `eigenschaft${b.eigenschaftId}`, align: 'center', sortable: true, groupable: false
            }
            console.log("INEXX", index, bewkat.bewertungen.length -1)
            if (index === 0 || bewkat.bewertungen.length === 1) header.class = "border-left";
            return header;
          }
          ))),
      ];
    },
  }
}
</script>

<style scoped>
/deep/ .v-data-table {
  background-color: inherit !important;
}

/deep/ .border-left {
  border-color: var(--v-divider-base); border-style: solid; border-width: 0 0 1px 1px !important;
}

/deep/ .border-right {
  /*border-color: var(--v-divider-base); border-style: solid; border-width: 0 1px 1px 0 !important;*/
}

/deep/ tr.v-data-table__selected {
  background: var(--v-divider-base) !important;
}
</style>