<!--File not in use-->
<template>
  <v-navigation-drawer
    app
    permanent
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          Application
        </v-list-item-title>
        <v-list-item-subtitle>
          subtext
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "ProducerNavigationDrawer"
}
</script>

<style scoped>

</style>