<template>
  <v-row
    justify="center"
    class="no_scrollbar"
  >
    <v-dialog
      v-model="dialog"
      persistent
      class="no_scrollbar"
      max-width="450"
    >
      <v-card
        rounded="xl"
        :outlined="$vuetify.theme.dark"
        style="border-color: var(--v-divider-base) !important"
        color="background"
        class="no_scrollbar"
      >
        <v-card-title
          class="text-h5 text-left"
          style="word-break: keep-all;"
        >
          <v-icon class="pr-1">
            {{ icon }}
          </v-icon>
          {{ $t(titleKey) }}
        </v-card-title>
        <v-card-text
          class="text-left"
          v-html="$t(textKey)"
        />
        <v-card-actions
          style="bottom: 0; background-color: var(--v-background-base);"
          :style="{'position': dialog ? 'sticky !important' : 'absolute',}"
        >
          <v-spacer />
          <v-btn
            color="error"
            text
            rounded
            @click.native="cancel"
          >
            {{ $t(cancelText) }}
          </v-btn>
          <v-btn
            color="primary"
            text
            rounded
            :disabled="agreeDisabled"
            @click.native="agree"
          >
            {{ $t(okayText) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import cider_mixin from "@/components/mixins/cider_mixin";

export default {
  name: "ModalConfirm",
  components: {},
  mixins: [cider_mixin],
  data: () => ({
    dialog: false,
    resolve: null,
    agreeDisabled: false,
    reject: null,
    titleKey: "",
    textKey: "",
    icon: "",
    okayText: "",
    cancelText: "",
    routerTarget: null,
  }),
  computed: {
  },
  i18n: {
    messages: {
      de: {
        titleRestartCiderRating: "Bewertungen bearbeiten",
        messageRestartCiderRating:
          `Wenn die Bewertung neu gestartet wird, können alle Juroren ihre bisherige Bewertung bearbeiten.<br>
           Erst nachdem alle Juroren den Cider als abgeschlossen markiert haben,
           kann er wieder als bewertet vom Chair markiert werden.`,
        titleRequestCider: "Angefragter Cider",
        messageRequestCider:
          `Der Cider ist als angefragt markiert. Kann die Anfrage entfernt werden?`,
      },
      en: {
        titleRestartCiderRating: "Edit ratings",
        messageRestartCiderRating:
          `By restarting the evaluation, all judges may edit their previous evaluations.<br>
           Only after all judges have marked the cider as complete,
           it can again be marked as finished by the chair.`,
        titleRequestCider: "Requested Cider",
        messageRequestCider:
          `The cider is marked as requested. Can the request be removed?`,
      }
    }
  },
  methods: {
    open(titleKey,
         textKey,
         icon,
         agreeDisabled = false,
         okayText = "okay",
         cancelText = "cancel",
         routerTarget = null
    ) {
      this.titleKey = titleKey;
      this.textKey = textKey;
      this.icon = icon;
      this.agreeDisabled = agreeDisabled;
      this.okayText = okayText;
      this.cancelText = cancelText;
      this.routerTarget = routerTarget;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },
    async agree() {
      this.resolve(true)
      this.dialog = false
      if (this.routerTarget != null) await this.$router.push(this.routerTarget);
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    },
  }
}
</script>

<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.no_scrollbar::-webkit-scrollbar /deep/ {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no_scrollbar /deep/ {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}
</style>