import '@/config.js';
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import Vuex from "vuex";
import store from "@/stores";
import router, {allRoutes} from "@/router/index"
import './assets/css/main.css';
Vue.use(Vuex)
// @ts-ignore
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import "@fontsource/roboto/100.css"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "@fontsource/roboto/900.css"
import "@fontsource/nunito-sans/200.css"
import "@fontsource/nunito-sans/400.css"
import "@fontsource/nunito-sans/600.css"
import "@fontsource/nunito-sans/800.css"

// @ts-ignore
import { VueMasonryPlugin } from "vue-masonry";
import VueTour from 'vue-tour'
import i18n from './i18n'

require('vue-tour/dist/vue-tour.css')
import Vuetify from 'vuetify'

Vue.use(Vuetify)
Vue.use(TiptapVuetifyPlugin, {
  vuetify: vuetify,
  iconsGroup: 'mdi'
})

Vue.config.productionTip = false
// console.log("STORE:", store.state)
Vue.use(VueMasonryPlugin);
Vue.use(VueTour)
// import VueTooltipDirective from 'vue-tooltip-directive'
// import DefaultTooltip from "vue-tooltip-directive"
// Vue.use(VueTooltipDirective, {
//   component: DefaultTooltip
// })

new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')

