<template>
  <v-app-bar
    app
    flat
    style="backdrop-filter: blur(6px);"
    :color="$vuetify.theme.currentTheme.app_bar"
  >
    <v-btn
      icon
      class="ml-2"
    >
      <v-img
        contain
        :lazy-src="getLogoUrl('logo_app', 300)"
        :src="getLogoUrl('logo_app', 300)"
        alt="CiderAwardLogo"
        style="margin: auto;"
        max-height="32px"
        max-width="40px"
        @click="$router.push('/producer/home')"
      />
    </v-btn>

    <v-spacer />

    <!--    <v-btn-->
    <!--      icon-->
    <!--      @click="addCountries"-->
    <!--    >-->
    <!--      <v-icon>-->
    <!--        mdi-bell-->
    <!--      </v-icon>-->
    <!--    </v-btn>-->

    <v-menu
      v-model="showUserMenu"
      class="pt-2"
      bottom
      left
      rounded="xl"
      offset-y
      transition="slide-y-transition"
      :close-on-content-click="false"
      style="z-index: 99;"
    >
      <template #activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <!--          <template #activator="{ on: tooltip }">-->
          <!--            <v-btn-->
          <!--              id="appBarUserMenu"-->
          <!--              icon-->
          <!--              v-bind="attrs"-->
          <!--              class="ma-1"-->
          <!--              v-on="{ ...tooltip, ...menu }"-->
          <!--            >-->
          <!--              <v-icon>-->
          <!--                mdi-account-circle-->
          <!--              </v-icon>-->
          <!--            </v-btn>-->
          <!--          </template>-->
          <!--          <span>{{ $t('account') }}</span>-->
          <template #activator="{ on: tooltip }">
            <v-btn
              id="appBarUserMenu"
              text
              v-bind="attrs"
              class="ma-1"
              style="text-transform: unset !important;"
              v-on="{ ...tooltip, ...menu }"
            >
              <span class="text--primary">{{ $store.getters["usersStore/fullName"]() }}</span>
              <v-icon right>
                mdi-account-circle
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $store.getters["usersStore/fullName"]() }}</span>
        </v-tooltip>
      </template>
      <v-card
        ref="userCard"
        max-width="400"
        rounded="20"
      >
        <v-list
          class="pa-0"
          color="dialog_background"
        >
          <v-list-item>
            <theme-menu />
            <v-list-item-content>
              <v-list-item-title style="text-align: right">
                {{ $store.getters["usersStore/user"]().firstName }} {{ $store.getters["usersStore/user"]().lastName }}
              </v-list-item-title>
              <v-list-item-subtitle style="text-align: right">
                {{ $t($store.getters["usersStore/user"]().role.toLowerCase()) }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-img
                v-if="$store.state.producerStore.producerData != null
                  && $store.state.producerStore.producerData.assets != null
                  && $store.state.producerStore.producerData.assets.logo != null"
                :src="getImageUrl($store.state.producerStore.producerData.assets.logo, 250)"
              />
              <img
                v-else
                :src="getUserAvatar(200)"
                alt="Avatar"
              >
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
        <v-divider style="background-color: var(--v-divider-base)" />
        <v-list
          color="dialog_background"
        >
          <v-list-item
            align="right"
            class="ma-0"
            active-class="primary"
          >
            <language-select
              style="transform: translateY(16px);"
            />
          </v-list-item>
          <v-list-item-group
            v-model="selectedItem"
            active-class=""
          >
            <v-list-item
              link
              color="text_std"
              align="right"
              class="ma-0"
              @click="$router.push('/producer/edit/information'); showUserMenu = false; selectedItem = null;"
            >
              <v-list-item-icon class="ml-2 mr-4">
                <v-icon size="28">
                  mdi-account-edit
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="font-size: 16px;">
                  {{ $t('editAccountInformation') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              color="text_std"
              align="right"
              class="ma-0"
            >
              <v-list-item-icon class="ml-2 mr-4">
                <v-icon size="28">
                  mdi-face-agent
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="font-size: 16px;">
                  <a
                    style="color: unset !important; text-decoration: none;"
                    href="mailto:info@cider-world.com?subject=Award-App%20I%20need%20support"
                    class="ml-1"
                  >
                    {{ $t('requestEmailSupport') }}
                  </a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              color="text_std"
              align="right"
              class="ma-0"
              @click="$store.state.feedbackDialog = true"
            >
              <v-list-item-icon class="ml-2 mr-4">
                <v-icon size="28">
                  mdi-message-processing-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="font-size: 16px;">
                  {{ $t('provideFeedback') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-divider style="background-color: var(--v-divider-base) !important;" />
        <v-card-actions style="background-color: var(--v-dialog_background-base) !important">
          <v-spacer />
          <v-btn
            color="primary"
            text
            rounded
            @click="logout"
          >
            {{ $t('logout') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import logos_mixin from "@/components/mixins/logos_mixin";
import user_mixin from "@/components/mixins/user_mixin";
import ThemeMenu from "@/components/theme_menu";
import LanguageSelect from "@/components/language_select";
import {apiTyped} from "@/api";

export default {
  name: "ProducerAppBar",
  components: {LanguageSelect, ThemeMenu},
  mixins: [logos_mixin, user_mixin],
  data: () => ({
    showUserMenu: false,
    selectedItem: null,
  }),
  methods: {
    async logout() {
      localStorage.clear();
      await this.$store.dispatch("usersStore/logout");
      this.showUserMenu = false;
      window.localStorage.clear();
    },
  },
}
</script>

<style scoped>

</style>