import Vue from 'vue';
import Vuex from 'vuex';
import {make} from 'vuex-pathify';
import {User} from "@/types/user";
// import store, {rootGetter} from "@/stores";

Vue.use(Vuex);

type storeStates = typeof state;
// @ts-ignore
// type storeGetters = typeof getters;
// type rootStates = typeof store.state;
// type rootGetters = typeof rootGetter;

const state: {
    emulatedUser: User | null,
    emulatedUserActive: boolean,
} = {
    emulatedUser: null,
    emulatedUserActive: false,
}

// @ts-ignore
const getters = {
    ...make.getters(state),
    getEmulatedUserId: (state: storeStates) => (): string | null => {
      return state.emulatedUser?.id || null;
    },
    getEmulatedUser: (state: storeStates) => (): User | null => {
        return state.emulatedUser;
    },
    emulatedUserActive: (state: storeStates) => (): boolean => {
        return state.emulatedUserActive;
    },
}

const mutations = {
    ...make.mutations(state),
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}