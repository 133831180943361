<template>
  <div
    class="ma-0"
    style="border-radius: 20px; margin: 0 !important;"
  >
    <v-card
      v-if="tasting !== null"
      :elevation="elevation"
      ripple
      outlined
      style="box-sizing: border-box !important; height: 100%;"
      :style="{'border-color': borderColor + '!important', 'border-width': borderWidth + 'px !important'}"
      class="animate-shadow overflow-visible"
    >
      <v-row>
        <v-col
          cols="11"
          class="pb-0"
          style="padding-right: 76px;"
        >
          <v-card-text class="text-left pt-3 pb-0">
            <div class="text--secondary align-baseline d-flex">
              <v-icon
                small
                left
              >
                mdi-calendar
              </v-icon>
              {{ $t('tastingCard.date') }} {{ new Date(tasting.datum).toLocaleDateString() }}
              {{ new Date(tasting.datum).toLocaleTimeString() }}
            </div>
          </v-card-text>
          <v-card-title class="pb-5 pt-0 text-break text-ellipsis text-left">
            {{ tasting.name }}
          </v-card-title>
        </v-col>
        <v-col cols="1">
          <v-img
            style="position: absolute; top: 8px; right: 8px;"
            width="64"
            class="pa-0"
            :src="getImageUrl(tasting.logoMesse, 200)"
          />
        </v-col>
      </v-row>

      <v-card-text class="d-flex justify-start text-left align-end pb-0 pt-0 pl-3">
        <v-icon
          :color="statusColor"
          left
        >
          {{ statusIcon }}
        </v-icon>
        {{ statusText }}
        <!--        <v-chip-->
        <!--          :color="statusColor"-->
        <!--          class="pr-2"-->
        <!--          small-->
        <!--        >-->
        <!--          <v-icon>-->
        <!--            {{ statusIcon }}-->
        <!--          </v-icon>-->
        <!--        </v-chip>-->
        <!--        {{ statusText }}-->
      </v-card-text>
      <v-row
        no-gutters
        justify="space-between"
        class="px-2 pt-2 pb-3"
      >
        <v-col
          cols="6"
          align-self="start"
        >
          <div class="text-body-1 mr-auto text-left px-2">
            <span>
              <v-icon
                left
                small
              >
                mdi-glass-wine
              </v-icon>
              {{ allCiders.length }} Ciders
            </span>
          </div>
        </v-col>
        <v-divider vertical />
        <v-col
          cols="6"
          align-self="end"
        >
          <div class="text-body-1 ml-auto text-left px-2">
            <span>
              <v-icon
                left
                small
              >
                mdi-airplane-takeoff
              </v-icon>
              {{ allFlightsCount }} Flights
            </span>
          </div>
        </v-col>
        <v-col
          v-if="!loading"
          col="auto"
        />
      </v-row>

      <v-card-subtitle
        class="text-left pb-3 mb-0 pt-0 overflow-text-vertical"
        style="height: 100px;"
        v-html="tasting.beschreibung || $t('noTastingDescription')"
      />

      <v-card-text
        class="text--secondary text-caption text-left pb-1 pt-1"
      >
        {{ $t('TastingDashboard.myRatings') }}:
      </v-card-text>
      <div
        v-if="!loading && tastingStarted"
        class="pl-0 pb-3 overflow-visible"
      >
        <v-row
          no-gutters
          class="pl-2 pr-2 pb-0 justify-start overflow-visible"
          justify="start"
        >
          <template
            v-for="(key, index) in badgeDataKeys"
          >
            <v-col
              :key="badgeData[key][0].id"
              class="overflow-visible"
            >
              <div
                style="height: 40px;"
                class="d-flex overflow-visible justify-center"
              >
                <badges-stack
                  v-if="badgeData[key].length !== 0"
                  :show-none-badge="false"
                  :badges-data="badgeData[key]"
                  :badge-width="40"
                  :distance="6"
                  :max-count-badges="maxBadgesToShow"
                  :best-of-category="badgeData[key].map(b => b.cider.best_of_category)"
                  class="overflow-visible"
                  @click.native.stop=""
                />
              </div>
              <span
                v-if="badgeData[key].length !== 0"
                class="text-caption text--secondary text-no-wrap"
              >
                {{ badgeData[key][0].stufe }} ({{ badgeData[key].length }})
              </span>
            </v-col>
            <v-divider
              v-if="index !== badgeDataKeys.length - 1"
              :key="`${badgeData[key][0].id}_divider`"
              class="mx-1"
              vertical
              inset
            />
          </template>
        </v-row>
      </div>
      <div
        v-if="!loading && !tastingStarted"
        class="pt-4"
        style="min-height: 84px; max-height: 84px; height: 84px;"
      >
        <span class="text--secondary text-caption align-center">
          {{ $t('noRatingsYet') }}
        </span>
      </div>
      <div
        v-if="tastingStarted"
        v-show="loading"
        style="height: 76px;"
      >
        <v-progress-circular
          size="48"
          indeterminate
        />
      </div>
      <!--      <v-expand-transition>-->
      <!--        <v-card-actions-->
      <!--          class="pt-1 align-start"-->
      <!--        >-->
      <!--          <v-spacer />-->
      <!--          <v-btn-->
      <!--            text-->
      <!--            color="primary"-->
      <!--            rounded-->
      <!--            @click.native.stop=""-->
      <!--          >-->
      <!--            <v-icon-->
      <!--              left-->
      <!--              color="primary"-->
      <!--            >-->
      <!--              mdi-information-outline-->
      <!--            </v-icon>-->
      <!--            {{ $t('moreInfos') }}-->
      <!--          </v-btn>-->
      <!--        </v-card-actions>-->
      <!--      </v-expand-transition>-->
    </v-card>
  </div>
</template>

<script>
import {Tasting} from "@/types/tasting";
import logos_mixin from "@/components/mixins/logos_mixin";
import cider_mixin from "@/components/mixins/cider_mixin";
import {groupBy} from "@/util/helper";
import {apiTyped} from "@/api";
import BadgesStack from "@/components/badges_stack";

export default {
  name: "TastingJurorCard",
  components: {BadgesStack,},
  mixins: [logos_mixin, cider_mixin],
  props: {
    tasting: { type: Object, required: true, default: null },
    tables: { type: Array, required: true, default: null },
    elevation: { type: Number, required: false, default: 0 },
    borderWidth: { type: Number, required: false, default: 1 },
    borderColor: { type: String, required: false, default: 'var(--v-divider-base)' },
  },
  data: () => ({
    loading: true,
    badges: null,
    badgeData: null,
  }),
  computed: {
    maxBadgesToShow() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 5;
        case 'sm': return 3;
        case 'md': return 5;
        default: return 3;
      }
    },
    tastingStarted() {
      return this.tasting.started;
    },
    allCiders() {
      return this.tables.flatMap(table => table.flights.flatMap(f => f.ciders))
    },
    allFlightsCount() {
      return this.tables.flatMap(table => table.flights).length;
    },
    badgeDataKeys() {
      return Object.keys(this.badgeData).sort((a,b) => a - b);
    },
    statusText() {
      if (this.tasting.finished) return this.$i18n.t('finished');
      if (this.tasting.started) return this.$i18n.t('started');
      if (!this.tasting.started) return this.$i18n.t('tastingNotStarted');
      return this.$i18n.t('finished')
    },
    statusColor() {
      if (this.tasting.finished) return 'text_std';
      if (this.tasting.started) return 'primary';
      if (!this.tasting.started) return 'secondary';
      return 'on_surface_variant'
    },
    statusIcon() {
      if (this.tasting.finished) return 'mdi-check-circle-outline';
      if (this.tasting.started) return 'mdi-play-circle';
      if (!this.tasting.started) return 'mdi-clock-outline';
      return 'mdi-check';
    },
  },
  async created() {
    this.badges = await apiTyped.getBadges([this.tasting]);
    this.badgeData = groupBy(this.allCiders.filter(c => c.points != null).map(
        c => {
          const badgeData = this.getBadgeData(c.points, this.badges);
          badgeData.cider = c;
          return badgeData
        }),
      b => b.punkteMin);
    this.loading = false;
  }
}
</script>

<style scoped>
.animate-shadow {
  transition: box-shadow 0.3s ease-in-out;
}
.overflow-text-vertical {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
</style>