<template>
  <v-container
    fluid
    class="pl-md-8 pr-md-8 pl-sm-2 pr-sm-2"
  >
    <PageNavigation
      :title-translation="$t('updateProductData')"
      :breadcrumbs="[
        {text: $t('home'), to: '/producer/home'},
        {text: selectedTasting.name, to: `/producer/submitted/overview/${selectedTasting.id}`},
        {text: $t('submitProductData'), disabled: true}
      ]"
    />

    <step-navigation
      class="py-4"
      :prev-disabled="false"
      :next-disabled="true"
      hide-next
      @prev="$router.push(`/producer/submit-data/confirm-products`)"
    />

    <div class="text-left text-h5 pb-3">
      {{ ciders.length }} {{ $t('paymentHandling.submittedProducts') }}
    </div>
    <div
      class="flex-row d-flex align-center justify-center flex-wrap pa-0"
    >
      <v-row class="justify-center">
        <v-col
          v-for="cider in ciders"
          :key="cider.id"
          cols="12"
          xs="12"
          sm="6"
          md="4"
          lg="3"
          align-self="center"
          class="d-flex justify-center align-self-center"
        >
          <cider-card
            style="width: 100%; max-width: 400px;"
            class="d-flex align-center justify-center pb-4"
            :select-revision="selectedTasting.id"
            disable-revisions
            :cider="cider"
            outlined
            hide-stats
            :elevation="0"
          />
        </v-col>
      </v-row>
    </div>

    <div
      v-if="loaded"
      class="pt-2 pb-3 align-start justify-center d-flex mx-auto"
    >
      <v-icon
        left
        small
        style="opacity: 0.6; transform: translateY(2px);"
      >
        mdi-information-outline
      </v-icon>
      <span
        class="text-body-2 text--secondary text-left align-start justify-start"
      >
        {{ $t('paymentHandling.forProductTastingCreated') }}
        <!--<strong>{{ ciders[0].getRevisionName(new Date(Date.now()), selectedTasting.abbreviation, false, false) }})</strong>-->
        <strong>{{ selectedTasting.abbreviation }})</strong>
        <br>
        <template v-if="selectedTasting.tastingSettings.allowProductDataUpdate">
          {{ $t('paymentHandling.dataLockedAfterSubmissionClosed',
                {closingDate: new Date(selectedTasting.einreichenBis).toLocaleString()}) }}
        </template>
        <template v-else>
          {{ $t('paymentHandling.dataLockedAfterFinishedOrSubmissionClosed',
                {closingDate: new Date(selectedTasting.einreichenBis).toLocaleString()}) }}
        </template>
      </span>
    </div>
    <div
      v-if="loaded"
      class="mx-auto"
      style="max-width: 600px;"
    >
      <div>
        <v-btn
          elevation="0"
          color="primary"
          class="my-3"
          :loading="loading"
          :disabled="finishedUpdatingData"
          @click="submitUpdatedCiders"
        >
          <v-expand-x-transition>
            <v-icon
              v-show="finishedUpdatingData"
              left
            >
              mdi-check
            </v-icon>
          </v-expand-x-transition>
          {{ $t('submitProductData') }}
        </v-btn>
      </div>
    </div>

    <v-progress-circular
      v-if="!loaded"
      indeterminate
      class="mt-auto mb-auto ml-auto mr-auto"
      size="92"
    />
    <Confirm_dialog ref="confirm" />
    <v-dialog
      v-model="errorDialog"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('errorOccurred') }}
        </v-card-title>

        <v-card-text class="text-left">
          {{ $t('pleaseContactUs') }}
        </v-card-text>
        <v-card-text style="color: red;">
          {{ errorText }}
        </v-card-text>

        <v-btn
          color="error"
          text
          outlined
          class="mb-2"
        >
          <a
            style="color: unset !important; text-decoration: none;"
            :href="'mailto:info@cider-world.com?subject=Error&body=' + errorTextEncoded"
            class="ml-1"
          >
            {{ $t('sendErrorMail') }}
          </a>
        </v-btn>
        <v-divider class="mx-16" />
        <v-card-subtitle class="text--secondary pt-2">
          info@cider-world.com
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error darken-1"
            text
            @click="errorDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import translation_mixin from "@/components/mixins/translation_mixin";
import {sync} from "vuex-pathify";
import Confirm_dialog from "@/components/confirm_dialog.vue";
import CiderCard from "@/components/cider_card.vue";
import PageNavigation from "@/components/page_navigation.vue";
import StepNavigation from "@/components/producer/step_navigation.vue";
import producer_submit_mixin from "@/components/mixins/producer_submit_mixin";
import {apiTyped} from "@/api";

export default {
  name: "SubmitDataOverview",
  components: {StepNavigation, PageNavigation, CiderCard, Confirm_dialog},
  mixins: [translation_mixin, producer_submit_mixin],
  async beforeRouteLeave(to, from, next) {
    if (this.finishedUpdatingData
        || (this.matchesWhitelist(to.path) && !to.path.startsWith('/producer/submitted/overview'))) {
      next();
    }
    const okayToLeave = await this.$refs.confirm.open(
      this.$t('unsavedChanges'), this.$t('unsavedChangesMessageProducerSubmit'),
      {color: 'secondary_card', thirdButton: true, thirdButtonText: this.$t('discard')}, {btnOkay: 'stay'}, true)
    if (okayToLeave === "third") next();
  },
  data: () => ({
    loaded: false,
    ciders: [],
    expectedErrorDialog: false,
    expectedErrorText: 'None yet, all good :)',
    errorDialog: false,
    errorText: 'None yet :(',
    loading: false,
    finishedUpdatingData: false,
  }),
  computed: {
    selectedTasting: sync('submitStore/selectedTasting'),
    selectedCiders: sync('submitStore/selectedCiders'),
    tastingPurchase: sync('submitStore/tastingPurchase'),
    selectedProductAmount: sync('submitStore/selectedProductAmount'),
    submitProductsWithoutData: sync('submitStore/submitProductsWithoutData'),
    confirmedCiderIds: sync('submitStore/confirmedCiderIds'),
    selectedTastingProducts: sync('submitStore/selectedTastingProducts'),
    producerData: sync('producerStore/producerData'),
    errorTextEncoded() {
      return encodeURIComponent(this.errorText);
    },
  },
  async created() {
    await this.$store.dispatch('producerStore/getProducerData');
    if (this.selectedCiders.length > 0) {
      this.ciders = await apiTyped.producerGetAllCiders(
        this.$store.state.producerStore.producerId,
        0, 1, -1,
        { id: {_in: this.selectedCiders.map(c => Number(c.id)) } }
      );
    } else {
      this.ciders = [];
    }
    const submittedCiders = await apiTyped.getSubmittedCidersForTasting(this.selectedTasting.id);
    console.log("this.ciders", this.ciders, this.selectedCiders)
    for (const cider of this.ciders) {
      console.log("Going through cider", cider)
      if (cider.editRevisionInfos == null) {
        // create editRevisionInfos, TODO maybe move to first step when loading existing eingereichte ciders!
        cider.editRevisionInfos = {
          kommentar: "",
          revisionId: -1,
          status: "draft",
        };
      }
      const ciderWasSubmitted = submittedCiders.find(s => s.cider === cider.id);
      if (ciderWasSubmitted != null) {
        console.log("ciderWasSubmitted", ciderWasSubmitted)
        // do not create a jahrgang preview, already submitted this cider
        cider.editRevisionInfos = {
          kommentar: ciderWasSubmitted.kommentar,
          revisionId: ciderWasSubmitted.productRevision,
          status: "draft",
        }
      } else {
        const infos = JSON.parse(JSON.stringify(cider.ciderInfos));
        const [e, rev] = cider.createPreviewJahrgang(infos, this.selectedTasting, cider.editRevisionInfos.kommentar);
        cider.submitted.unshift(e);
        cider.revisions.unshift(rev);
        console.log("CIDER REVS", e, rev)
      }
    }
    this.loaded = true;
  },
  methods: {
    async submitUpdatedCiders() {
      this.loading = true;
      try {
        let submittedCiderIds;
        const submittedCiders = await apiTyped.getSubmittedCidersForTasting(this.selectedTasting.id);
        if (submittedCiders == null) {
          console.error("Error getting Eingereichte Ciders");
          this.errorText = "Error getting Eingereichte Ciders";
          this.errorDialog = true;
          this.error = true;
          return;
        }
        const ciderIds = this.ciders.map(c => c.id);
        console.log("Got submitted Ciders", this.selectedTasting.id, submittedCiders, ciderIds)
        const unSubmittedCiders = submittedCiders.filter(
          submittedCider => !ciderIds.includes(submittedCider.productRevision));
        if (unSubmittedCiders.length > 0) {
          console.log("Deleting existing submissions", unSubmittedCiders)
          await apiTyped.deleteSubmittedCidersForTasting(unSubmittedCiders);
        }
        const addResult = await apiTyped.addSubmittedCidersForTasting(
          this.ciders,
          this.selectedTasting,
          this.$store.state.producerStore.producerId,
          false,
          null,  // no longer needed?
          "payment_success"
        );
        submittedCiderIds = addResult.newItemIds;
        console.log("addResult", addResult)
        if (addResult.success == null) {
          console.error("Error adding Eingereichte Ciders", this.ciders, unSubmittedCiders, addResult);
          this.errorText = "Error adding Eingereichte Ciders";
          this.errorDialog = true;
          this.error = true;
          this.loadingSubmission = false;
          this.loadingPayment = false;
        } else {
          this.loading = false;
          this.finishedUpdatingData = true;
          // wait a moment before redirect to tasting infos
          await new Promise(resolve => setTimeout(resolve, 1800));
          await this.$router.push(`/producer/submitted/overview/${this.selectedTasting.id}`);
        }
      } catch (e) {
        console.log("Error submitting Ciders", e, this.ciders);
        this.errorText = `Error submitting Ciders ${e}`;
        this.errorDialog = true;
        this.error = true;
        this.loadingSubmission = false;
        this.loadingPayment = false;
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>

</style>