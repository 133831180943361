import Vue from 'vue';
import Vuex from 'vuex';
import {make} from 'vuex-pathify';
import {Country} from "@/types/country";
import {apiTyped} from "@/api";
import {PRODUCER_FOLDER_ID} from "@/util/constants.js"
import {ProducerData} from "@/types/producer";
// import store, {rootGetter} from "@/stores";

Vue.use(Vuex);


type storeStates = typeof state;
// @ts-ignore
// type storeGetters = typeof getters;
// type rootStates = typeof store.state;
// type rootGetters = typeof rootGetter;

const state: {
    name: string | null,
    country: Country | null,
    producerId: string | null,
    ciderImagesFolderId: string | null,
    producerData: ProducerData | null,
    lastRouteBeforePrint: string | null,
    } = {
    name: null,
    country: null,
    producerId: null,
    ciderImagesFolderId: null,
    producerData: null,
    lastRouteBeforePrint: null,
}

// @ts-ignore
const getters = {
    ...make.getters(state),
}

const mutations = {
    ...make.mutations(state),
}

const actions = {
    async getProducerInfos(context: any): Promise<void> {
        const data = await apiTyped.producerGetInfos(context.rootGetters["usersStore/userId"]());
        context.state.name = data.name;
        context.state.country = data.land;
        context.state.producerId = data.id;
        context.state.producerData = data.produzenten_data;
        context.state.ciderImagesFolderId = data.cider_images_folder;
        // console.log("Creating Folder", context.state.ciderImagesFolderId == null , context.state.producerId != null)
        // TODO assets, etc..
        if (context.state.ciderImagesFolderId == null && context.state.producerId != null) {
            // Create image folder
            const folderResp = await apiTyped.sdk.folders.createOne({
                name: `Producer_${context.state.producerId}`,
                parent: PRODUCER_FOLDER_ID,
            });
            if (folderResp != null) {
                const resp = await apiTyped.sdk.items("produzenten").updateOne(context.state.producerId, {
                    cider_images_folder: folderResp.id,
                });
                context.state.ciderImagesFolderId = resp?.cider_images_folder;
            }
        }
    },
    async getProducerData(context: any): Promise<void> {
        context.state.producerData = await apiTyped.producerGetData(context.rootGetters["usersStore/userId"]());
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}