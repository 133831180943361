<template>
  <v-card
    ref="card"
    :outlined="$vuetify.theme.dark"
  >
    <v-card-title class="text-h4 d-flex flex-row text-no-wrap">
      Länder Verteilung
      <div class="d-flex align-center ml-auto">
        <span
          class="subtitle-1 pr-3"
          style="min-width: 105px;"
        >{{ groupType ? 'Kontinente' : 'Länder' }}</span>
        <v-switch
          v-model="groupType"
          inset
        />
      </div>
    </v-card-title>
    <v-card-subtitle
      v-if="!groupType"
      class="text-left pb-0 pl-7"
      style="font-size: 12pt !important;"
    >
      {{ Object.keys(ciderGroupedByCountry).length - (ciderGroupedByCountry.hasOwnProperty("none") ? 1 : 0) }} Länder
    </v-card-subtitle>
    <v-card-subtitle
      v-if="groupType"
      class="text-left pb-0 pl-7"
      style="font-size: 12pt !important;"
    >
      {{ Object.keys(ciderGroupedByContinent).length - (ciderGroupedByCountry.hasOwnProperty("none") ? 1 : 0) }} Kontinente
    </v-card-subtitle>
    <div
      v-if="!groupType"
      class="pb-6"
    >
      <div
        v-for="countryCode in Object.keys(ciderGroupedByCountry)"
        :key="countryCode"
        class="pl-4 pr-4 pt-4 pb-2 ma-2"
      >
        <v-row>
          <v-col
            cols="auto"
            class="justify-start ma-0 pa-0"
          >
            <country-flag
              v-if="countryCode !== 'none'"
              class="elevation-2"
              :country="countryCode"
              size="normal"
            />
            <div
              v-else
              class="ml-4"
            />
          </v-col>
          <v-col
            cols="auto"
            class="justify-start pl-0"
          >
            <span class="d-flex justify-start text--primary mb-1">
              {{ !ciderCountryNames.hasOwnProperty(countryCode) ? "Nicht angegeben" : ciderCountryNames[countryCode] }}
            </span>
          </v-col>
          <v-col
            cols="auto"
            class="justify-end ml-auto"
          >
            <span class="d-flex justify-start text--primary mb-1">
              {{ ciderGroupedByCountry[countryCode].length }}/{{ allCiders.length }}
            </span>
          </v-col>
        </v-row>
        <v-row class="pl-3 pr-2 mt-0">
          <v-progress-linear
            :value="ciderGroupedByCountry[countryCode].length / allCiders.length * 100"
            rounded
            width="18"
            height="6"
            color="secondary"
          />
        </v-row>
      </div>
    </div>
    <div
      v-if="groupType"
      class="pb-6"
    >
      <div
        v-for="(arr, continent) in ciderGroupedByContinent"
        :key="continent"
        class="pl-4 pr-4 pt-4 pb-2 ma-2"
      >
        <v-row>
          <v-col
            cols="auto"
            class="ml-4 justify-start pl-0"
          >
            <span class="d-flex justify-start text--primary mb-1">
              {{ continent }}
            </span>
          </v-col>
          <v-col
            cols="auto"
            class="justify-end ml-auto"
          >
            <span class="d-flex justify-start text--primary mb-1">
              {{ arr.length }}/{{ allCiders.length }}
            </span>
          </v-col>
        </v-row>
        <v-row class="pl-3 pr-2 mt-0">
          <v-progress-linear
            :value="arr.length / allCiders.length * 100"
            rounded
            width="18"
            height="6"
            color="secondary"
          />
        </v-row>
      </div>
    </div>
  </v-card>
</template>

<script>
import CountryFlag from "vue-country-flag";
import {groupBy} from "@/util/helper";

export default {
  name: "CardCountryGrouped",
  components: {CountryFlag},
  props: {
    allCiders: {type: Array, required: true, default: null},
  },
  data: () => ({
    groupType: false,
  }),
  computed: {
    ciderGroupedByCountry () {
      const sortedCiders = [...this.allCiders].sort(
        (cider1, cider2) => {
          const val1 = cider1.land === null ? "aaaaa" : cider1.land.code;
          const val2 = cider2.land === null ? "aaaaa" : cider2.land.code;
          return val1.localeCompare(val2)
        })
      const grouped = groupBy(sortedCiders, cider => cider.land !== null ? cider.land.code : "none");
      // const groupedLengths = Object.values(grouped).map(arr => arr.length).sort((len1, len2) => len1 - len2);
      return grouped;
    },
    ciderGroupedByContinent () {
      const sortedCiders = [...this.allCiders].sort(
        (cider1, cider2) => {
          const val1 = cider1.land === null ? "aaaaa" : cider1.land.kontinent;
          const val2 = cider2.land === null ? "aaaaa" : cider2.land.kontinent;
          return val1.localeCompare(val2)
        })
      const grouped = groupBy(sortedCiders, cider => cider.land !== null ? cider.land.kontinent : "Nicht angegeben");
      // const groupedLengths = Object.values(grouped).map(arr => arr.length).sort((len1, len2) => len1 - len2);
      return grouped;
    },
    ciderCountryNames () {
      const countryNames = {};
      this.allCiders.forEach(cider => { if (cider.land !== null) countryNames[cider.land.code] = cider.land.name});
      return countryNames;
    }
  },
  watch: {
    groupType: function () {
      this.$nextTick(() => {
        // this.$emit("size", {name: "CardCountryGrouped", size: this.$refs.card.$el.clientHeight})
        this.$emit("redrawMasonry")
      });
    },
  },
  mounted() {
    this.$emit("size", {name: "CardCountryGrouped", size: this.$refs.card.$el.clientHeight})
  },
}
</script>

<style scoped>

</style>