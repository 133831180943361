import {sync} from "vuex-pathify";

export default {
    data: () => ({
        localeMessages: {}
    }),
    methods: {
    },
    // computed: {
    //     localeMessages() {
    //         return this.$store.get("localeStore/localeMessages");
    //     }
    // },
    // watch: {
    //     "$i18n.locale": function (newLocale, oldLocale) {
    //         if (newLocale === oldLocale) {
    //             return
    //         }
    //         console.log("Setting Lang Messages", this.localeMessages[newLocale])
    //         this.$i18n.setLocaleMessage(newLocale, this.localeMessages[newLocale])
    //     }
    // },
}