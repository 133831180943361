<script>
import LoginComponent from '@/components/login'
import Vue from "vue";
import {apiTyped} from "@/api";
import {RoleName} from "@/types/user";
import login_mixin from "@/components/mixins/login_mixin";

const AdminLogin = LoginComponent.extend({
  mixins: [login_mixin],
  data() {
    return {
      loginFailed: false,
      title: 'Admin-Login',
    };
  },
  methods: {
    async login() {
      console.log("Admin Login");
      this.loggingIn = true;
      this.$store.state.app_bar_loading = true;
      const user_ = await apiTyped.login(this.username, this.password);
      if (user_.role !==  RoleName.Admin) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error("Kein Admin.")
      }
      this.$store.set("appStore/authenticated", true);
      this.$store.commit("usersStore/setUser", user_);
      this.$store.set("appStore/username", this.username);
      this.$store.set("appStore/password", this.password);
      this.$store.state.app_bar_loading = false;
      this.loginFailed = false;
      console.log(this.username, this.password)
      await this.$router.push('/admin/home');
      // } catch (error) {
      //   this.loggingIn = false;
      //   this.loginFailed = true;
      //   console.error(error)
      // }
    },
  }
});

export default Vue.component('AdminLogin', AdminLogin);

</script>