import CiderRatingState from "@/types/ciderRatingState";
import {allRoutes, makeRoute} from "@/router";

export default {
    computed: {
        tastingIsRunning() {
            const currentTasting = this.$store.getters['usersStore/currentTasting']();
            return currentTasting?.started === true && currentTasting?.finished === false;
        },
        tastingIsNotStarted() {
            const currentTasting = this.$store.getters['usersStore/currentTasting']();
            return currentTasting?.started === false && currentTasting?.finished === false;
        },
        tastingStatusText() {
            const currentTasting = this.$store.getters['usersStore/currentTasting']();
            if (currentTasting?.started === false && currentTasting?.finished === false) return 'tastingNotStarted';
            if (currentTasting?.started === true && currentTasting?.finished === false) return '';
            if ((currentTasting?.started === true && currentTasting?.finished === true)
                || (currentTasting?.started === false && currentTasting?.finished === true)) return 'tastingIsFinished';
            return 'Error loading tasting, refresh the page.'
        },
        getCurrentTasting() {
            return this.$store.getters['usersStore/currentTasting']();
        },
        ciderState () {
            return this.$store.getters["ciderStore/getCiderRatingState"]();
        },
        showCiderChairFinish() {
            if (this.$store.getters["usersStore/isChair"]()) {
                return (this.ciderState === CiderRatingState.finishedByUser || this.currentCider.chairNotes?.restarted > 0)
                    && this.isSummary
                    && this.$store.getters["ciderStore/allBewertungFinished"]()
                    && this.markedFinishNotStartedOrRestarted;
            }
            else return false;
        },
        markedFinishNotStartedOrRestarted() {
            // Cider is marked as finished or restarted AND no Juror is still rating it
            return (this.markedFinished || this.currentCider.chairNotes?.restarted > 0)
                && !Object.values(this.currentCider?.allNotes).some(
                    note => note != null && note.started === true && note.finished === false);
        },
        tastingRoutes() {
            return allRoutes.tastings;
        }
    },
    methods: {
        makeTastingRoute(route, tasting) {
            // makes the base url '/tastings/:tastingAbbr/:tastingId/ ... '
            return makeRoute(route, {tastingAbbr: tasting.abbreviation || "tasting", tastingId: tasting.id});
        }
    }
}