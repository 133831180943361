<template>
  <page
    v-if="cider !== null"
    ref="pageA3"
    size="A3"
    class="pa-0 pb-0 ma-0"
    style="transform-origin: 0 0;"
  >
    <div
      style="position: absolute;"
    >
      <v-img
        v-if="getBadgeData(points).urkunde != null && !hideBackground"
        :src="urkundeUrl"
        alt="Certificate"
        style="position: relative; top: 0; left: 0; height: 42cm; width: 29.7cm; min-height: 42cm; max-width: 29.7cm"
      />
    </div>
    <div
      style="position:relative; top: 29.4cm; left: 0; width: 100%"
    >
      <span
        class="urkunde_text"
      >
        Category: {{ cider.kategorie.name }}<br>
        Product: {{ cider.name }}<br>
        Producer: {{ cider.produzent }}<br>
        Points: {{ calculateTotalPointsForCider(cider, true) }} of 120<br>
        Frankfurt, {{ new Date(tasting.datum).toLocaleDateString(
          "nl", { year: 'numeric', month: 'numeric', day: 'numeric' }) }}<br>
      </span>
    </div>
  </page>
</template>

<script>
import logos_mixin from "@/components/mixins/logos_mixin";
import cider_mixin from "@/components/mixins/cider_mixin";
import tasting_mixin from "@/components/mixins/tasting_mixin";
import {sync} from "vuex-pathify";
import {addUrkundenToBadges, Format} from "@/types/badge";
import {apiTyped} from "@/api";

export default {
  name: "UrkundePageA3",
  mixins: [logos_mixin, cider_mixin, tasting_mixin],
  props: {
    cider: {type: Object, required: true, default: () => null},
    tasting: {type: Object, required: true, default: () => null},
    badgeData: {type: Object, required: false, default: null},
    hideBackground: {type: Boolean, required: false, default: false},
  },
  data: () => ({
    componentsLoaded: 0,
    points: 0,
    urkundeUrl: null,
  }),
  computed: {
    badgesData: sync("badgesStore/badgesData"),
  },
  watch: {
    cider: function () {
      this.updateCertificate();
    },
  },
  async created() {
    this.badgesData = await addUrkundenToBadges(this.badgesData, null, [Format.A4]);
    this.updateCertificate();
  },
  methods: {
    updateCertificate() {
      this.points = this.$store.getters['ciderStore/getTotalPointsForCider'](this.cider);
      const urkunde = this.getBadgeData(this.points).urkunde;
      if (urkunde != null) {
        this.urkundeUrl = apiTyped.getImgUrl(urkunde.image, 0, true, true)
      }
    },
  },
}
</script>

<style scoped>
page[size="A3"] {
  height: 42cm;
  width: 29.7cm;
  min-height: 42cm;
  min-width: 29.7cm;
  max-height: 42cm;
  max-width: 29.7cm;
}

.page_grid {
  display: grid;
  align-items: stretch;
  height: 100%;
  grid-template-rows: min-content auto auto;
}

page {
  background: white;
  display: block;
  margin: 0 auto 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
}

@media print {
  page {
    height: 42cm;
    width: 29.7cm;
    min-height: 42cm;
    min-width: 29.7cm;
    max-height: 42cm;
    max-width: 29.7cm;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: unset !important;
  }
}

.urkunde_text {
  color: rgb(38, 38, 38);
  text-align: center;
  font-family: "Nunito Sans", serif;
  font-size: 18pt;
  line-height: 25pt;
  letter-spacing: 0;
}

div#preload {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}
</style>