<template>
  <v-container
    fluid
    class="mt-0 pa-0"
    style="height: 100vh; max-width: 100%;"
  >
    <v-row
      align="center"
      no-gutters
      style="height: 100vh;"
      class="d-flex flex-row flex-nowrap"
    >
      <v-col
        style="height: 100%; max-height: 100% !important; min-width: min(450px, 100vw) !important; overflow: auto !important"
        class="d-flex flex-column pb-0 pa-4"
      >
        <div
          class="d-flex flex-row ml-auto mr-auto"
        >
          <language-select
            class="pr-4"
          />
          <div style="padding-top: 6px;">
            <theme-menu />
          </div>
        </div>
        <cookie-banner
          class="ml-auto mr-auto text-left mb-3"
          style="max-width: 800px;"
        />
        <div
          class="d-flex flex-column justify-center align-center ma-auto"
          style="flex: 1 0 auto !important; min-width: min(100%, 400px);"
        >
          <login-header />
          <router-view
            :loading="loggingIn"
            :error-msg="errorMsg"
            :requires-otp="requiresOtp"
            @login="login"
          />

          <div
            class="d-flex flex-column pt-12 pb-8"
          >
            <span class="text-h5 pb-0">
              {{ $i18n.t('Login.youAreAProducer') }}
            </span>
            <span
              class="text-h6 font-weight-regular pb-2"
              style="cursor: pointer;"
              @click="$router.push('/requestAccount')"
            >
              {{ $i18n.t('Login.clickToSignUp') }}
            </span>
            <v-btn
              rounded
              outlined
              @click="$router.push('/requestAccount')"
            >
              <span
                class="text-subtitle-1"
                style="text-transform: none !important;"
              >
                {{ $i18n.t('Login.requestAccount') }}
              </span>
            </v-btn>
          </div>
        </div>
        <div
          class="ml-auto mr-auto text-caption pb-4 pt-4"
          style="font-size: 14px !important;"
        >
          {{ $t('Login.needHelp') }}
          <a
            style="color: unset !important;"
            href="mailto:info@cider-world.com?subject=Award-Login%20I%20need%20support"
            class="ml-1"
          >
            E-Mail Support
          </a>

          <div
            style="width: 100%;"
            class="partner-title text-left pl-3 pt-6 pb-0"
          >
            {{ $tc('partner', 2) }}
          </div>
          <v-row
            class="mt-0 mb-0"
            justify="center"
          >
            <v-col
              v-for="partner in partners"
              :key="partner.id"
              class="pt-1"
              style="max-width: 196px;"
            >
              <partner-avatar :partner="partner" />
            </v-col>
          </v-row>
        </div>
        <div
          class="d-flex flex-column justify-end align-center"
          style="flex-shrink: 0;"
        >
          <login-footer-card />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
import Vue from "vue";
import {CurrentPage} from "@/stores/app";
import login_mixin from "@/components/mixins/login_mixin";
import LoginHeader from "@/components/login/login_header";
import logos_mixin from "@/components/mixins/logos_mixin";
import LanguageSelect from "@/components/language_select";
import ThemeMenu from "@/components/theme_menu";
import LoginFooterCard from "@/components/login_footer_card";
import CookieBanner from "@/components/cookie_banner";
import i18n from '@/i18n'
import {apiTyped} from "@/api";
import PartnerAvatar from "@/components/partner_avatar";

export default Vue.extend({
  components: {
    PartnerAvatar,
    CookieBanner,
    LoginFooterCard,
    ThemeMenu,
    LanguageSelect,
    LoginHeader
  },
  mixins: [login_mixin, logos_mixin],
  props: {
    errorMsg: {type: String, required: false, default: null},
    loggingIn: {type: Boolean, required: true, default: false},
    requiresOtp: {type: Boolean, required: true, default: false},
  },
  data() {
    return {
      partners: [],
      title: 'Login',
      username: '',
      password: '',
    };
  },
  computed: {
    getBanner() {
      this.getImageUrl()
      return this.getLogoUrl('image_background_fair', -1, true);
    },
    isSmallScreen () {
      console.log("Viewpoint:", this.$vuetify.breakpoint.name)
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case "sm":
          return true
        default: return false
      }
    },
  },
  async mounted() {
    this.$store.state.appStore.currentPage = CurrentPage.login;
    this.partners = await apiTyped.publicGetPartners();
    console.log("this.partners", this.getImageUrl(this.partners[1].logo, 500, false, false, false))
    this.loading = false;
  },
  methods: {
    login({username, password, otp}) {
      this.$emit('login', {username: username, password: password, otp: otp});
    },
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.changedTheme = true;
    },
  }
});
</script>

<style>
.partner-title {
  font-family: "Nunito Sans", serif !important;
  font-weight: 400;
  font-size: 18pt !important;
}
</style>