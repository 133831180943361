<template>
  <v-container
    fluid
    class="ma-0"
  >
    <v-row>
      <v-col>
        <div class="d-flex align-center reset-title">
          {{ $i18n.t('Login.resetPassword') }}
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-0 mb-0">
      <v-col>
        <v-text-field
          v-model="username"
          outlined
          hide-details
          name="username"
          label="E-Mail"
          type="text"
          placeholder="E-Mail"
          @keyup.enter="resetPassword"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-fade-transition>
      <p
        v-show="resetSuccessful"
        class="mb-2 mt-2 ml-4 mr-2 text-left"
      >
        {{ $i18n.t('passwortResetSend') }}
      </p>
    </v-fade-transition>
    <v-row
      class="mt-0 mb-0"
      justify="space-between"
    >
      <v-col cols="auto">
        <v-btn
          text
          @click="$router.push('/login')"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
          {{ $i18n.t('Login.backToLogin') }}
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="ml-auto"
      >
        <v-btn
          elevation="0"
          color="primary"
          :loading="loading"
          :disabled="resetSuccessful"
          @click="resetPassword"
        >
          <v-icon
            v-show="resetSuccessful"
            left
          >
            mdi-check
          </v-icon>
          {{ $i18n.t('Login.reset') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {apiTyped} from "@/api";

export default {
  name: "ResetPasswordForm",
  data() {
    return {
      username: '',
      loading: false,
      resetSuccessful: false,
    };
  },
  i18n: {
    messages: {
      de: {
        "passwortResetSend": "Wir haben einen Link zum Passwort-Reset an ihre E-Mail geschickt"
      },
      en: {
        "passwortResetSend": "We have send a link for a password reset to your E-Mail"
      }
    }
  },
  methods: {
    async resetPassword() {
      this.loading = true;
      // this.$emit('resetPassword', {username: this.username});
      await apiTyped.resetPasswort(this.username);
      this.resetSuccessful = true;
      this.loading = false;
    },
  }
}
</script>

<style scoped>
.reset-title {
  font-family: "Nunito Sans", serif !important;
  font-weight: 800;
  font-size: 32pt !important;
  text-align: left;
  line-height: 1.25;
}
</style>