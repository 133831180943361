<template>
  <v-app
    v-if="created"
  >
    <v-main
      style="min-height: calc(100vh - 64px)"
    >
      <ServiceNavigationDrawer
        v-if="created === true"
      />
<!--      <v-btn-->
<!--        fab-->
<!--        elevation="6"-->
<!--        absolute-->
<!--        style="position: absolute; top: 8px; left: 8px;"-->
<!--        top-->
<!--        left-->
<!--        class="mt-10"-->
<!--        @click.stop="showNavigationDrawer = !showNavigationDrawer"-->
<!--      >-->
<!--        <v-icon>-->
<!--          mdi-menu-->
<!--        </v-icon>-->
<!--      </v-btn>-->
      <v-container
        fluid
        class="pa-0 ma-0"
      >
        <router-view
          v-if="created"
          :key="reloadRouter"
        />
      </v-container>
    </v-main>
    <ServiceFooter
      v-if="created === true"
    />
  </v-app>
</template>

<script>
import {apiTyped} from "@/api";
import router from "@/router";
import {RoleName} from "@/types/user";
import {createBadgeFromDatabase} from "@/types/badge";
import CiderFooter from "@/components/cider_footer";
import LoginFooterCard from "@/components/login_footer_card";
import {sync} from "vuex-pathify";
import ServiceNavigationDrawer from "@/components/service/navigation_drawer";
import ServiceFooter from "@/components/service/service_footer";

export default {
  name: "LayoutService",
  components: {ServiceFooter, ServiceNavigationDrawer},
  data: () => ({
    created: false,
  }),
  computed: {
    showNavigationDrawer: sync("serviceStore/showNavigationDrawer"),
    reloadRouter() {
      return this.$store.state.reload_router;
    },
  },
  async created() {
    document.title = "Cider Award - Service"
    const user = await apiTyped.loginRefresh();
    if (user == null) await router.push("/login");
    this.$store.commit("usersStore/setUser", user);
    if (this.$store.state.appStore.authenticated === false ||
      this.$store.getters["usersStore/user"]().role !== RoleName.Service) {
      await router.push("/login")
    }
    // this.setTheme();
    await this.$store.dispatch("serviceStore/getAllTastings");
    if (this.$store.state.serviceStore.tastings == null || this.$store.state.serviceStore.tastings.length === 0) {
      await router.push("/login")
    }
    if (this.$store.state.serviceStore.currentTastingId === -1) {
      this.$store.state.serviceStore.currentTastingId = this.$store.state.serviceStore.tastings[0].id;
    }
    this.created = true;
  },
}
</script>

<style scoped>

</style>