var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAuthenticated)?_c('v-app-bar',{staticClass:"blur_effect no-print",staticStyle:{"max-width":"100vw"},attrs:{"app":"","flat":"","color":_vm.$vuetify.theme.currentTheme.app_bar}},[(_vm.isSmallScreen)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleNavBar()}}},[_c('v-icon',[_vm._v(" mdi-menu ")])],1):_vm._e(),_c('v-toolbar-title',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(_vm.$store.getters["usersStore/currentTasting"]() !== undefined ? _vm.$store.getters["usersStore/currentTasting"]().name : 'Loading...')+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"appBarRefresh","icon":""},on:{"click":_vm.reload_page}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,1436685974)},[_c('span',[_vm._v(_vm._s(_vm.$t('refreshContent')))])]),_c('v-menu',{staticClass:"pt-2",staticStyle:{"z-index":"99"},attrs:{"rounded":"xl","bottom":"","left":"","offset-y":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"id":"appBarUserMenu","icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-account-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Account")])])]}}],null,false,476855188),model:{value:(_vm.showUserMenu),callback:function ($$v) {_vm.showUserMenu=$$v},expression:"showUserMenu"}},[_c('v-card',{attrs:{"color":"dialog_background"}},[_c('v-list',[_c('v-list-item',[_c('theme-menu'),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.user_info.firstName)+" "+_vm._s(_vm.user_info.lastName)+" ")]),_c('v-list-item-subtitle',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.user_info.role)+" ")])],1),_c('v-list-item-avatar',[_c('img',{attrs:{"src":_vm.get_user_avatar(200),"alt":"Avatar"}})])],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.clear_cookies}},[_vm._v(" Komplett neu laden ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.logout}},[_vm._v(" Logout ")])],1)],1)],1),_c('v-progress-linear',{attrs:{"active":_vm.$store.state.pageLoading,"indeterminate":_vm.$store.state.pageLoading,"absolute":"","bottom":""}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }