<template>
  <v-container
    fluid
    class="pl-md-8 pr-md-8 pl-sm-2 pr-sm-2"
  >
    <PageNavigation
      :title-translation="$t('paymentHandling.submitProducts')"
      :breadcrumbs="[{text: $t('home'), to: '/producer/home'}, {text: $t('paymentHandling.submitProducts'), disabled: true}]"
    />

    <producer-submit-stepper
      :current-step="submitTastingSteps.selectTasting"
    />
    <step-navigation
      :next-text="selectedTasting != null && selectedTasting.eingereichteCiderIds.length > 0 ? $t('edit').toUpperCase() : $t('next').toUpperCase()"
      :next-disabled="selectedTasting == null"
      :prev-disabled="true"
      :hide-prev="true"
      @next="$router.push(`/producer/submit/selectTastingProducts`)"
    />

    <div
      class="ml-auto mr-auto"
      style="max-width: 2000px;"
    >
      <div class="d-flex text-h4 mb-0 mt-4 align-center">
        {{ $t('paymentHandling.availableTastings') }}
      </div>
      <div class="d-flex text-body-2 mb-4 mt-1 align-center text-left text--secondary">
        {{ $t('paymentHandling.selectAvailableTasting') }}
      </div>

      <template
        v-if="tastings !== null && loaded"
      >
        <v-item-group
          v-model="selectedTastingId"
          active-class=""
        >
          <v-row>
            <v-col
              v-for="(tasting, index) in tastings"
              :key="tasting.id"
              cols="12"
              xs="12"
              sm="6"
              md="6"
              lg="4"
            >
              <v-hover
                v-slot="{ hover }"
              >
                <v-item
                  v-slot="{ active, toggle }"
                  :value="tasting.id"
                  :disabled="tastingDisabled(index)"
                >
                  <div
                    @click="myToggle(toggle, index)"
                  >
                    <TastingCard
                      :tasting="tasting"
                      :submitted-ciders="submittedCiders[index]"
                      :elevation="hover ? 8 : 0"
                      :selected="active"
                      :max-submission-count="submitCountFor(tasting)"
                      :hide-submission-sub-text="false"
                      :show-cancel-submission="false"
                      :has-purchasables="tasting.tastingSettings.hasPurchasables"
                      :is-participating="tastingPurchases[index] != null"
                      show-more-info-btn
                      style="cursor: pointer;"
                      @cancelSubmission="reloadTasting(index)"
                    >
                      <template
                        v-if="tasting.tastingSettings.submissionNeedsPayment && !producerMaySubmit(tasting)"
                        #additionalInfos
                      >
                        <v-alert
                          class="pa-3 ml-2 mr-2 text-left alert-light-icon rounded-lg"
                          color="secondary_container"
                          style="color: var(--v-text_std-lighten5); font-size: 12px !important;"
                          border="left"
                          light
                          type="info"
                          @click.stop
                        >
                          <template #prepend>
                            <v-icon
                              class="pl-2 pr-3"
                              color="text_std lighten-5"
                            >
                              mdi-information-outline
                            </v-icon>
                          </template>
                          To submit products for this tasting, you first have to register.
                          <a
                            :href="tasting.tastingSettings.paymentUrl"
                            target="_blank"
                          >
                            Click here
                          </a>
                          for registration. If you are already registered, please contact us via
                          <a
                            :href="$t('mailToTextSubmit', { tastingTitle: tasting.name })"
                            class="ml-1"
                          >
                            E-Mail
                          </a>
                        </v-alert>
                      </template>
                    </TastingCard>
                  </div>
                </v-item>
              </v-hover>
            </v-col>
          </v-row>
        </v-item-group>
      </template>
      <v-card
        v-if="loaded && tastingsEmpty"
        width="300"
        outlined
        style="border-style: dashed; border-width: medium; transition: box-shadow 0.2s ease-in-out;"
        class="mt-16 ml-auto mr-auto mb-8"
      >
        <v-icon size="192">
          mdi-basket-off-outline
        </v-icon>
        <v-card-subtitle class="pb-2">
          {{ $t('paymentHandling.noTastingAvailable') }}
        </v-card-subtitle>
      </v-card>
      <Confirm_dialog ref="confirm" />
    </div>
    <step-navigation
      v-if="loaded"
      :next-text="selectedTasting != null && selectedTasting.eingereichteCiderIds.length > 0 ? $t('edit').toUpperCase() : $t('next').toUpperCase()"
      :next-disabled="selectedTasting == null"
      :prev-disabled="true"
      :hide-prev="true"
      @next="$router.push(`/producer/submit/selectTastingProducts`)"
    />
    <v-progress-circular
      v-if="!loaded"
      indeterminate
      class="mt-auto mb-auto ml-auto mr-auto"
      size="92"
    />
  </v-container>
</template>

<script>
import TastingCard from "@/components/producer/tasting_card";
import {apiTyped} from "@/api";
import ProducerSubmitStepper from "@/components/producer/producer_submit_stepper";
import {commit, get, sync} from 'vuex-pathify'
import Confirm_dialog from "@/components/confirm_dialog";
import popup_mixin from "@/components/mixins/popup_mixin";
import StepNavigation from "@/components/producer/step_navigation";
import producer_submit_mixin from "@/components/mixins/producer_submit_mixin";
import PageNavigation from "@/components/page_navigation";
import {SubmitTastingStep} from "@/types/submitTastingStep";

export default {
  name: "ProducerTastingAddCider",
  components: {PageNavigation, StepNavigation, Confirm_dialog, ProducerSubmitStepper, TastingCard},
  mixins: [producer_submit_mixin],
  i18n: {
    messages: {
      de: {
        "mailToTextSubmit": "mailto:info@cider-world.com?subject={tastingTitle}%20Einreichung:%20Es%20können%20keine%20Produkte%20eingereicht%20werden"
      },
      en: {
        "mailToTextSubmit": "mailto:info@cider-world.com?subject={tastingTitle}%20Submission:%20Unable%20to%20Submit%20Products"
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.selectedTasting == null || this.tastingsEmpty || this.matchesWhitelist(to.path)) {
      next();
    }
    // check if new tasting is selected and create warning
    if (this.lastSelectedTastingId != null && this.lastSelectedTastingId !== this.selectedTasting.id) {
      // TODO messages anpassen
      const okayToLeave = await this.$refs.confirm.open(
        this.$t('unsavedChanges'), this.$t('unsavedChangesMessageProducerSubmit'),
        {color: 'secondary_card', thirdButton: true, thirdButtonText: this.$t('discard')}, {btnOkay: 'stay'}, true)
      if (okayToLeave === "third") next();
    } else {
      const okayToLeave = await this.$refs.confirm.open(
        this.$t('unsavedChanges'), this.$t('unsavedChangesMessageProducerSubmit'),
        {color: 'secondary_card', thirdButton: true, thirdButtonText: this.$t('discard')}, {btnOkay: 'stay'}, true)
      if (okayToLeave === "third") next();
    }
  },
  data: () => ({
    tastings: null,
    lastSelectedTastingId: null,
    submittedCiders: null,
    paidProducts: [],
    tastingPurchases: [],
    loaded: false,
  }),
  computed: {
    submitTastingSteps() {
      return SubmitTastingStep
    },
    numberOfPaidProducts: sync('submitStore/numberOfPaidProducts'),
    selectedTasting: sync('submitStore/selectedTasting'),
    selectedTastingId: {
      get() {
        return this.selectedTasting != null ? this.selectedTasting.id : null;
      },
      set(tastingId) {
        if (tastingId == null) this.selectedTasting = null;
        else this.selectedTasting = this.tastings.find(t => t.id === tastingId);
        // const selectedTastingIdx = this.tastings.findIndex(t => t.id === tastingId);
      }
    },
    tastingsEmpty() {
      return this.tastings == null || this.tastings.length === 0;
    },
  },
  watch: {
    selectedTasting: function (newTasting) {
      this.numberOfPaidProducts = this.submitCountFor(newTasting);
      console.log("Got submit Count", this.numberOfPaidProducts);
    }
  },
  async created() {
    this.tastings = await apiTyped.producerGetAvailableTastings();
    if (this.tastings != null && this.tastings.length > 0) {
      console.log("Get submitted")
      this.paidProducts = await apiTyped.producerGetPaidProducts(
        this.tastings.map(t => t.id), this.$store.state.producerStore.producerId);
      console.log("Get submitted")
      this.submittedCiders = await Promise.all(this.tastings.map(t => apiTyped.getSubmittedCidersForTasting(t.id)));
      this.tastingPurchases = await Promise.all(this.tastings.map(t => {
        if (t.tastingSettings.hasPurchasables) {
          return apiTyped.producerGetTastingPurchases(t.id);
        } else {
          return new Promise(function(resolve) { resolve(null); });
        }
      }));
    }
    this.lastSelectedTastingId = this.selectedTasting != null ? this.selectedTasting.id : null;
    this.loaded = true;
  },
  methods: {
    async reloadTasting(index) {
      await apiTyped.deleteSubmittedCidersForTasting(this.submittedCiders[index]);
      this.tastings = await apiTyped.producerGetAvailableTastings();
      this.submittedCiders = await Promise.all(this.tastings.map(t => apiTyped.getSubmittedCidersForTasting(t.id)));
      this.lastSelectedTastingId = null;
    },
    tastingDisabled(index) {
      const tasting = this.tastings[index];
      if (tasting.tastingSettings.allowProductDataUpdate) return false;
      if (this.submittedCiders != null && this.submittedCiders[index].length > 0) {
        if (tasting.tastingSettings.allowProductDataUpdate) {
          return !(this.submittedCiders[index][0].status === 'draft'
              || this.submittedCiders[index][0].status === 'unpaid')
            && this.submittedCiders[index].every(c => c.productRevision != null && c.cider != null);
        } else {
          return !(this.submittedCiders[index][0].status === 'draft'
              || this.submittedCiders[index][0].status === 'unpaid')
        }
      }
      if (!this.producerMaySubmit(tasting)) return true;
      return false;
    },
    async myToggle(t, i) {
      if (this.tastingDisabled(i)) await this.$router.push(`/producer/submitted/overview/${this.tastings[i].id}`)
      else t();
    },
    producerMaySubmit(tasting) {
      if (tasting.tastingSettings.submissionNeedsPayment) {
        return this.paidProducts.find(p => p.tastingId === tasting.id && p.numberOfProducts > 0) != null;
      }
      return true;
    },
    submitCountFor(tasting) {
      if (tasting == null) return 0;
      if (tasting.tastingSettings.submissionNeedsPayment) {
        const producerPaidProducts = this.paidProducts.find(p => p.tastingId === tasting.id && p.numberOfProducts > 0);
        if (producerPaidProducts != null) {
            return Number(producerPaidProducts.numberOfProducts);
        }
        return null
      } else if (tasting.tastingSettings.maxCiders > 0) {
        return Number(tasting.tastingSettings.maxCiders);
      }
      return tasting.tastingSettings.maxCiders;
    }
    // async selectTasting(tasting) {
    //   console.log("setting tasting", tasting)
    //   commit('submitStore/selectedTasting', tasting);
    //   await this.$router.push(`/producer/submit/${tasting.id}/selectCiders`);
    // }
  }
}
</script>

<style scoped>
</style>