import {apiTyped} from "@/api";
import CiderRatingState from "@/types/ciderRatingState";
import JurorCider from "@/types/jurorCider";

export default {
  methods: {
    async loadTables() {
      if (this.currentTasting == null) {
        this.tables = null;
      }
      else {
        this.tables = await apiTyped.adminGetTablesAndFlights(this.currentTasting.id) || null;
        this.tables.sort((t1, t2) =>
          t1.nummer.localeCompare(t2.nummer, undefined, {numeric: true, sensitivity: 'base'}));
      }
    },
    flightsOrTablesChanged(newFlightsCiders) {
      if (this.cidersByTable == null) return false;  // first load
      // changed, if number of results are not the same, or any flight id or table id has changed:
      const flightsCiders = newFlightsCiders.flat(1);
      const existingFlightsCiders = this.cidersByTable.flat(1);
      if (flightsCiders.length !== existingFlightsCiders.length) {
        return true;
      }
      for (const flightCider of flightsCiders) {
        const matchingCider = existingFlightsCiders.find(
          c => c.flightsCidersId === flightCider.flightsCidersId);
        if (matchingCider === undefined) {
          console.log("No match found for", flightCider);
          return true;
        }
        if (matchingCider.flightId !== flightCider.flightId) {
          console.log("Flight changed for", flightCider);
          return true;
        }
        if (matchingCider.table.id !== flightCider.table.id) {
          console.log("Table changed for", flightCider);
          return true;
        }
      }
    },
    _getCiderIdsByTable() {
      return this.tables.map(table => table.flights.flatMap(f => f.ciders.flatMap(c => c)));
    },
    async _getFlightsCiders() {
      // split request by tables:
      const results = [];
      const ciderIdsByTable = this._getCiderIdsByTable();
      for (const ciderIds of ciderIdsByTable) {
        const res = await apiTyped.serviceGetCidersClient(ciderIds, this.kategorien, 50)
        results.push(res);
      }
      return results;
    },
    async loadCiders() {
      const results = await this._getFlightsCiders();
      // if (this.flightsOrTablesChanged(results)) {
      //   await this.loadTables();
      //   await this._getFlightsCiders();
      // }
      const ciderIdsByTable = this._getCiderIdsByTable();
      // reorder
      for (let i=0; i<ciderIdsByTable.length; i++) {
        results[i] = results[i].sort(
          (a,b) => ciderIdsByTable[i].indexOf(a.flightsCidersId) - ciderIdsByTable[i].indexOf(b.flightsCidersId))
      }
      this.cidersByTable = results;
      this.cidersByTable.forEach((table, index) => {
        this.tables[index].requestedCiders = 0;
        this.tables[index].restartedCiders = 0;
        this.tables[index].nearlyFinishedCiders = 0;
        table.forEach(c => {
          let countBewertungenAndNotes = 1;  // 1 as starting counter for Chair note!
          let finishedBewertungenAndNotes = 0;
          const allBewertungen = Object.values(c.allBewertungen).flat(1);
          countBewertungenAndNotes += allBewertungen.length;
          const alleBewertungenFinished = allBewertungen.filter(b => b != null && b.punkte != null).length;
          console.log("alleBewertungenFinished", alleBewertungenFinished, allBewertungen.length)
          finishedBewertungenAndNotes += alleBewertungenFinished;
          const allNotes = Object.values(c.allNotes).flat(1);
          countBewertungenAndNotes += allNotes.length;
          const allNotesFinished = allNotes.filter(n => n != null && n.finished === true).length;
          finishedBewertungenAndNotes += allNotesFinished;
          c.allNotesFinished = allNotesFinished;
          c.allNoteslength = allNotes.length;
          if (c.chairNotes?.finished === true) finishedBewertungenAndNotes += 1;
          if (!c.chairNotes?.started && !c.chairNotes?.finished && !c.chairNotes?.restarted) c.chairRatingState = CiderRatingState.notStarted;
          if (c.chairNotes?.started && !c.chairNotes?.finished && !c.chairNotes?.restarted) c.chairRatingState = CiderRatingState.startedByChair;
          if (c.chairNotes?.started && c.chairNotes?.finished && !c.chairNotes?.restarted) c.chairRatingState = CiderRatingState.finishedByChair;
          if (c.chairNotes?.restarted) c.chairRatingState = CiderRatingState.restartedByChair;
          // Add additional fields
          c.progress = finishedBewertungenAndNotes / countBewertungenAndNotes;
          c.kategorie = this.kategorien.find(k => Number(k.id) === Number(c.kategorieId));
          if (c.requestCider === true) this.tables[index].requestedCiders += 1;
          else if (c.chairNotes?.restarted === true) this.tables[index].restartedCiders += 1;
          else if (c.progress > 0.85 && c.progress < 1) this.tables[index].nearlyFinishedCiders += 1;  // 90 % finished is also an action
        });
      });
    },
  }
}