<template>
  <v-card :outlined="$vuetify.theme.dark">
    <v-card-text class="pl-4 text-left overflow-hidden">
      <p class="d-flex justify-start text-h5 text--primary mb-1">
        <v-icon class="pr-2">
          mdi-account
        </v-icon>
        Juroren
      </p>
      <v-card-title
        class="text-h6 text-center justify-center"
        style="font-size: 32pt !important;"
      >
        {{ userCount }}
      </v-card-title>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CardUserCount",
  props: {
    tables: {type: Array, required: true, default: null},
  },
  computed: {
    userCount() {
      return [... new Set(this.tables.flatMap(table => table.juroren))].length;
    }
  }
}
</script>

<style scoped>

</style>