<template>
  <v-progress-circular
    class="d-flex justify-center align-center overlay"
    color="primary"
    style="position: relative;"
    size="75"
    width="7"
    indeterminate
  >
    <div class="d-flex flex-column">
      <v-img
        contain
        :src="customImage != null ? getImageUrl(customImage, 300) : getLogoUrl('logo_app', 300)"
        alt="CiderAwardLogo"
        style="margin: auto;"
        max-height="40px"
        max-width="40px"
      />
      <div
        class="d-flex flex-column"
        style="margin-top: 128px; min-width: 300px !important;"
      >
        <span>
          {{ loadingMessage }}
        </span>
      </div>
    </div>
  </v-progress-circular>
</template>

<script>
import logos_mixin from "@/components/mixins/logos_mixin";

export default {
  name: "LoadingComponentSpinner",
  mixins: [logos_mixin],
  props: {
    longLoading: {type: Boolean, required: false, default: false},
    loadingMessage: {type: String, required: false, default: "Loading..."},
    customImage: {type: String, required: false, default: null},
  },
  data: () => ({
    showMessages: false,
  })
}
</script>

<style scoped>

</style>