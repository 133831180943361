import { render, staticRenderFns } from "./rangliste_header.vue?vue&type=template&id=642d09dc&scoped=true&"
import script from "./rangliste_header.vue?vue&type=script&lang=js&"
export * from "./rangliste_header.vue?vue&type=script&lang=js&"
import style0 from "./rangliste_header.vue?vue&type=style&index=0&id=642d09dc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642d09dc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VThemeProvider } from 'vuetify/lib/components/VThemeProvider';
installComponents(component, {VImg,VThemeProvider})
