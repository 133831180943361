<template>
  <v-card
    outlined
    class="d-flex flex-column rounded-xl"
  >
    <div style="max-height: 204px; min-height: 204px; width: 100%; position: relative;">
      <v-img
        v-if="tasting.tastingInfos.headerImage != null"
        :src="getImageUrl(tasting.tastingInfos.headerImage, 2000)"
        :lazy-src="getImageUrl(tasting.tastingInfos.headerImage, 200)"
        :contain="false"
        height="200"
        class="rounded-xl"
      />
      <v-img
        v-else
        style="background-color: white"
        height="200"
        class="rounded-xl"
      />
      <div style="position: relative;">
        <div style="position: absolute; transform: translateX(-50%); left: 75%; top: -100px;">
          <v-card
            outlined
            class="pa-2"
          >
            <v-img
              v-if="tasting.logoMesse != null"
              :src="getImageUrl(tasting.logoMesse, 2000)"
              :lazy-src="getImageUrl(tasting.logoMesse, 200)"
              width="100"
              height="100"
              contain
              class="rounded-xl"
            />
          </v-card>
        </div>
      </div>
    </div>
    <v-card-title
      class="pb-1 text-left award-title"
      style="font-family: 'Nunito Sans', serif !important; max-width:100%"
    >
      {{ tasting.name }}
    </v-card-title>
    <div
      class="d-flex py-1 px-4 text-body-2"
      style="column-gap: 16px;"
    >
      <div class="d-flex align-baseline text--secondary">
        <v-icon
          small
          left
        >
          mdi-calendar
        </v-icon>
        <span>
          {{ new Date(tasting.datum).toLocaleDateString() }}
        </span>
      </div>
      <div class="d-flex align-baseline text--secondary">
        <v-icon
          small
          left
        >
          mdi-map-marker
        </v-icon>
        <span>
          {{ tasting.country }}, {{ tasting.city }}
        </span>
      </div>
    </div>
    <v-card-text class="pt-2 pb-1">
      <span
        class="text-left text-body-2 text--secondary overflow-text-vertical"
        style="max-height: 60px; min-height: 60px;"
        v-html="getTranslation(tasting.translations,'beschreibung', currentLanguage)"
      />
    </v-card-text>
    <slot name="banner" />
    <div class="mt-auto d-flex grow flex-grow-1 align-end justify-end">
      <v-card-actions>
        <v-btn
          text
          color="primary"
          rounded
          @click="$router.push(`/producer/submitted/overview/${tasting.id}`)"
          @click.native.stop=""
        >
          <v-icon
            left
            color="primary"
          >
            mdi-information-outline
          </v-icon>
          {{ $t('tastingCard.moreInfo') }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import logos_mixin from "@/components/mixins/logos_mixin";
import translation_mixin from "@/components/mixins/translation_mixin";
import user_mixin from "@/components/mixins/user_mixin";

export default {
  name: "TastingInfoCard",
  mixins: [logos_mixin, translation_mixin, user_mixin],
  props: {
    tasting: { type: Object, required: true, default: null },
  },
  data: () => ({
  }),
}
</script>

<style scoped>
.overflow-text-vertical {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.award-title {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
</style>