import {User, UserId} from "@/types/user";
import {components, MyCollections} from "@/api/types/my-collections";
import {Tischfunktion} from "@/types/tischfunktion";
import JurorCider from "@/types/jurorCider";
import {createFlightFromDatabase, Flight} from "@/types/flight";


export interface Table {
    id: number;
    status: "draft" | "published" | "archived",
    nummer: string;
    chair: User | UserId;
    flights: Flight[];
    juroren: User[];
    comment?: string;
    myTischfunktionen: Tischfunktion;
    tischfunktionen: Tischfunktion[];
    ciders?: JurorCider[];
    tasting: number;
}

export function createTableFromDatabase(table: MyCollections["tables"]): Table {
    return <Table>{
        id: table.id,
        status: table.status || "draft",
        nummer: table.nummer,
        chair: typeof table.chair === "string" ? table.chair : new User(table.chair as components["schemas"]["Users"]),
        flights: table?.flight_ids !== undefined && typeof table?.flight_ids[0] === "number" ?
            table.flight_ids :
            table.flight_ids?.map(f => typeof f !== "number"
                ? typeof f.flights_id !== "number" && f.flights_id !== null && f.flights_id !== undefined
                    ? createFlightFromDatabase(f.flights_id, f.sort || null)
                    : f.flights_id
                : f),
        juroren: table.juror_ids?.filter(user => typeof user !== "number" && user?.directus_users_id !== null).map(
            user => new User((user as any).directus_users_id as components["schemas"]["Users"])),
        comment: table.kommentar,
        tasting: typeof table.tasting === "number" ? table.tasting : table.tasting?.id,
    }
    // TODO sort flights
}
