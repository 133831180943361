<template>
  <div
    style="align-self: flex-start !important; height: auto !important; width: auto !important;
           display: unset !important; border-color: var(v--divider-base) !important;"
    class="ma-0"
  >
    <ConfirmDialog ref="confirm" />

    <modal-finish-cider
      ref="finishCiderDialog"
      :points="currentCiderPoints || 0"
      :cider="currentCider"
    />

    <modal-confirm ref="modalConfirm" />

    <v-expansion-panels
      id="ciderInfoExpPanel"
      ref="ciderInfoExpPanel"
      v-model="open_infos"
      :class="{ sticky: pin_info_header, 'elevation-0': info_panel_elevation === 2, 'elevation-6': info_panel_elevation === 6 }"
      :style="{ 'max-width': cardWidth, 'border-style': $vuetify.theme.dark ? 'solid': 'unset',
                'top': isSmallScreen && pin_info_header ? '64px !important': '12px !important' }"
      class="mb-4 animate_change blur_effect mt-2 overflow-y-auto"
      flat
      accordion
      multiple
      style="margin:0 auto; border-radius: 20px; align-self: flex-start !important; border-color: var(--v-divider-base) !important; max-height: 90vh; z-index: 11;"
    >
      <!--    :class="`elevation-${info_panel_elevation}`"-->
      <v-expansion-panel
        class="animate_change"
        style="border-top-right-radius: 20px; border-top-left-radius: 20px"
        :class="{'expansion_panel_shadow_2': info_panel_elevation === 2,'expansion_panel_shadow_6': info_panel_elevation === 6,}"
        :style="{'background-color': $vuetify.theme.currentTheme.expansion_panel,
                 'border-bottom-right-radius': showCiderActions ? '0px' : '20px',
                 'border-bottom-left-radius': showCiderActions ? '0px' : '20px'}"
        @click="expandPanel"
      >
        <v-tooltip
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              icon
              absolute
              top
              right
              small
              style="top: 0; right: 0"
              v-bind="attrs"
              v-on="on"
              @click="toggle_info_header"
              @click.native.stop=""
            >
              <v-icon
                v-if="pin_info_header"
                id="ciderInfoExpPanelPin"
              >
                mdi-pin-outline
              </v-icon>
              <v-icon
                v-if="!pin_info_header"
              >
                mdi-pin-off-outline
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('CiderInfoExpPanel.pinCard') }}</span>
        </v-tooltip>
        <v-expansion-panel-header
          class="pt-2 pl-4 pr-4 pb-0 animate_change"
        >
          <v-container
            class="ma-0 pa-0 pb-2 fill-height"
            fluid
          >
            <v-row
              cols="12"
              align="start"
              no-gutters
              class="grow fill-height justify-space-between align-stretch"
            >
              <v-col
                cols="6"
                no-gutters
                align="start"
                class="d-flex"
                style="flex-direction:column"
              >
                <v-row
                  align="center"
                  no-gutters
                >
                  <v-col cols="12">
                    <p
                      class="text--primary mt-auto mb-auto font-weight-light animate_change pb-1"
                      :class="{'text-h5': expanded, 'text-h7': !expanded, 'pt-1': !expanded,}"
                    >
                      {{ $t('CiderInfoExpPanel.currentCider') }}: #{{ currentCider.displayId }}
                    </p>
                  </v-col>
                </v-row>
                <v-row
                  align="center"
                  no-gutters
                >
                  <v-col cols="12">
                    <p
                      class="text--primary mt-auto mb-auto animate_change"
                      style="line-height: normal;"
                      :class="{'text-h5': expanded, 'text-h6': !expanded}"
                    >
                      {{ currentCider.kategorie.name }}
                    </p>
                  </v-col>
                </v-row>
                <v-row
                  align="start"
                  no-gutters
                >
                  <v-col cols="12">
                    <div class="d-flex align-center">
                      <p
                        v-if="currentCider.land != null && currentCider.land.code != null"
                        class="text-subtitle-1 text--primary mt-auto mb-auto"
                      >
                        {{ currentCider.land.name }}
                      </p>

                      <country-flag
                        v-if="currentCider.land != null && currentCider.land.code != null"
                        :country="currentCider.land.code"
                        class="elevation-2"
                        style="border-radius: 10px !important;"
                        size="normal"
                      />
                      <p
                        v-if="currentCider.land == null || currentCider.land.code == null"
                        class="pt-2 pb-1 text--secondary"
                      >
                        {{ $t('countryNotSpec') }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="6"
                no-gutters
                style="height: auto;"
                class="d-flex align-stretch flex-column justify-end fill-height"
              >
                <v-row
                  no-gutters
                  class="justify-end fill-height align-stretch"
                  style="overflow: visible !important;"
                >
                  <v-col
                    cols="auto"
                    class="animate_change d-flex justify-end"
                  >
                    <div
                      class="animate_change justify-end align-stretch"
                    >
                      <v-tooltip
                        bottom
                      >
                        <template #activator="{ on, attrs }">
                          <v-btn
                            :disabled="!(tastingIsNotStarted || tastingIsRunning)"
                            icon
                            v-bind="attrs"
                            large
                            :loading="heartLoading"
                            v-on="on"
                            @click="toggleHeart"
                            @click.native.stop=""
                          >
                            <v-icon v-if="currentCider.notes != null && currentCider.notes.isFavourite">
                              mdi-heart
                            </v-icon>
                            <v-icon v-else>
                              mdi-heart-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('markCiderAsFavourite') }}</span>
                      </v-tooltip>
                      <v-tooltip
                        bottom
                      >
                        <template #activator="{ on, attrs }">
                          <v-btn
                            :disabled="!(tastingIsNotStarted || tastingIsRunning)"
                            icon
                            v-bind="attrs"
                            large
                            :loading="bookmarkLoading"
                            @click="togglePin"
                            v-on="on"
                            @click.native.stop=""
                          >
                            <v-icon v-if="currentCider.notes != null && currentCider.notes.isPinned">
                              mdi-bookmark
                            </v-icon>
                            <v-icon v-else>
                              mdi-bookmark-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('markCiderAsBookmarked') }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="animate_change justify-center d-flex align-center"
                  >
                    <div
                      class="my_container flex-wrap animate_change mr-0"
                      style="z-index: 5; overflow: visible !important;"
                    >
                      <!--                      <span-->
                      <!--                        class="box mb-1 animate_change"-->
                      <!--                        style="border-bottom: 1px solid #CCC;"-->
                      <!--                        :class="{'font_size_0': !expanded && showBadgeSummary}"-->
                      <!--                      >-->
                      <!--                        {{ labelPunkte }}-->
                      <!--                      </span>-->
                      <v-fab-transition>
                        <BadgeComponent
                          v-show="currentBadgeData.hasImage"
                          id="badgeImage"
                          ref="badgeImage"
                          :badge-data="currentBadgeData"
                          :badge-width="56"
                          :show-none-badge="false"
                          :badge-resolution="400"
                          :points="currentCiderPoints"
                          class="pb-1"
                          style="z-index: 5;"
                        />
                      </v-fab-transition>
                      <ChipPoints
                        id="ciderInfoExpPanelPoints"
                        class="mt-auto animate_change"
                        :points="currentCiderPoints"
                        :dark-background="false"
                      />
                      <!--                    <v-chip-->
                      <!--                      id="ciderInfoExpPanelPoints"-->
                      <!--                      label-->
                      <!--                      style="min-width: 64px; max-width: 64px;"-->
                      <!--                      class="pl-1 pr-1 d-flex justify-center box animate_change"-->
                      <!--                      align="center"-->
                      <!--                      :outlined="getColor(currentCiderPoints) === null"-->
                      <!--                      :color="getColor(currentCiderPoints) !== null ? getColor(currentCiderPoints) : '#aaa'"-->
                      <!--                      dark-->
                      <!--                      :text-color="'#' + $store.getters['badgesStore/getTextColor'](-->
                      <!--                        currentCiderPoints, $vuetify.theme.dark)"-->
                      <!--                    >-->
                      <!--                      <v-icon-->
                      <!--                        small-->
                      <!--                      >-->
                      <!--                        mdi-star-->
                      <!--                      </v-icon>-->
                      <!--                      {{ currentCiderPoints }}-->
                      <!--                    </v-chip>-->
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <!--            <v-fab-transition>-->
          <!--              <v-speed-dial-->
          <!--                v-show="showBadgeSummary"-->
          <!--                class="mr-8 animate_change"-->
          <!--                style="height: 56px; width: 56px; z-index: 6; right: 12px;"-->
          <!--                color="transparent"-->
          <!--                open-on-hover-->
          <!--                direction="bottom"-->
          <!--                absolute-->
          <!--                top-->
          <!--                right-->
          <!--                @click.native.stop=""-->
          <!--              >-->
          <!--                <template #activator>-->
          <!--                  <BadgeComponent-->
          <!--                    :key="reloadBadge"-->
          <!--                    :badge-data="currentBadgeData"-->
          <!--                    :badge-width="54"-->
          <!--                    :show-none-badge="false"-->
          <!--                    :badge-resolution="400"-->
          <!--                    :points="currentCiderPoints"-->
          <!--                  />-->
          <!--&lt;!&ndash;                  <v-btn&ndash;&gt;-->
          <!--&lt;!&ndash;                    id="badgeImage"&ndash;&gt;-->
          <!--&lt;!&ndash;                    ref="badgeImage"&ndash;&gt;-->
          <!--&lt;!&ndash;                    color="background"&ndash;&gt;-->
          <!--&lt;!&ndash;                    dark&ndash;&gt;-->
          <!--&lt;!&ndash;                    fab&ndash;&gt;-->
          <!--&lt;!&ndash;                    class=""&ndash;&gt;-->
          <!--&lt;!&ndash;                  >&ndash;&gt;-->
          <!--&lt;!&ndash;                    <v-img&ndash;&gt;-->
          <!--&lt;!&ndash;                      :key="getBadgeUrl(200, currentPoints)"&ndash;&gt;-->
          <!--&lt;!&ndash;                      max-height="56px"&ndash;&gt;-->
          <!--&lt;!&ndash;                      max-width="56px"&ndash;&gt;-->
          <!--&lt;!&ndash;                      :lazy-src="getBadgeUrl(200, currentPoints)"&ndash;&gt;-->
          <!--&lt;!&ndash;                      :src="getBadgeUrl(200, currentPoints)"&ndash;&gt;-->
          <!--&lt;!&ndash;                      alt="Badge"&ndash;&gt;-->
          <!--&lt;!&ndash;                    />&ndash;&gt;-->
          <!--&lt;!&ndash;                    &lt;!&ndash;                  <v-img&ndash;&gt;&ndash;&gt;-->
          <!--&lt;!&ndash;                    &lt;!&ndash;                    v-show="!inRatingTab"&ndash;&gt;&ndash;&gt;-->
          <!--&lt;!&ndash;                    &lt;!&ndash;                    :key="getBadgeUrl(200, getTotalPoints())"&ndash;&gt;&ndash;&gt;-->
          <!--&lt;!&ndash;                    &lt;!&ndash;                    max-height="56px"&ndash;&gt;&ndash;&gt;-->
          <!--&lt;!&ndash;                    &lt;!&ndash;                    max-width="56px"&ndash;&gt;&ndash;&gt;-->
          <!--&lt;!&ndash;                    &lt;!&ndash;                    :lazy-src="currentBadgeUrl"&ndash;&gt;&ndash;&gt;-->
          <!--&lt;!&ndash;                    &lt;!&ndash;                    :src="getBadgeUrl(200, currentBadgeUrl)"&ndash;&gt;&ndash;&gt;-->
          <!--&lt;!&ndash;                    &lt;!&ndash;                    alt="Badge"&ndash;&gt;&ndash;&gt;-->
          <!--&lt;!&ndash;                    &lt;!&ndash;                  />&ndash;&gt;&ndash;&gt;-->
          <!--&lt;!&ndash;                  </v-btn>&ndash;&gt;-->
          <!--                </template>-->
          <!--                <div class="" />-->
          <!--                <div-->
          <!--                  v-for="badge in getBadgesData()"-->
          <!--                  :key="badge.id"-->
          <!--                  class=""-->
          <!--                >-->
          <!--                  <v-tooltip-->
          <!--                    disabled-->
          <!--                    left-->
          <!--                    eager-->
          <!--                    nudge-left="24"-->
          <!--                    :value="true"-->
          <!--                  >-->
          <!--                    <template #activator="{ on, attrs }">-->
          <!--                      <v-btn-->
          <!--                        v-bind="attrs"-->
          <!--                        fab-->
          <!--                        dark-->
          <!--                        small-->
          <!--                        color="white"-->
          <!--                        :elevation="10"-->
          <!--                        min-height="56px"-->
          <!--                        min-width="56px"-->
          <!--                        v-on="on"-->
          <!--                      >-->
          <!--                        <v-img-->
          <!--                          :key="badge.punkteMin"-->
          <!--                          max-height="56px"-->
          <!--                          max-width="56px"-->
          <!--                          :lazy-src="getBadgeUrl(300, badge.punkteMin)"-->
          <!--                          :src="getBadgeUrl(300, badge.punkteMin)"-->
          <!--                          alt="Badge"-->
          <!--                        />-->
          <!--                      </v-btn>-->
          <!--                    </template>-->
          <!--                    <span>{{ badge.stufe }}: {{ badge.punkteMax }}-{{ badge.punkteMin }}</span>-->
          <!--                  </v-tooltip>-->
          <!--                  <br>-->
          <!--                </div>-->
          <!--              </v-speed-dial>-->
          <!--            </v-fab-transition>-->
          <!--              </v-list-item>-->
          <!--          </v-col>-->
        </v-expansion-panel-header>
        <v-expand-transition>
          <template
            v-if="nextBadge != null && Math.abs(nextBadge.punkteMin - currentCiderPoints) <= 5"
          >
            <div
              class="d-flex justify-end ml-auto text--secondary align-center pb-2 pr-4 text-caption"
              style="font-size: 14px !important;"
            >
              <strong class="pr-1">{{ pointsToNextBadge }}</strong>
              {{ pointsToNextBadge === 1 ? $t('pointToNextMessage') : $t('pointsToNextMessage') }}
              <v-icon
                small
                class="pl-1 pr-1"
              >
                mdi-arrow-right
              </v-icon>
              <BadgeComponent
                :badge-data="nextBadge"
                :badge-width="32"
                :badge-shadow="false"
                :points="nextBadge.punkteMin"
              />
            </div>
          </template>
        </v-expand-transition>
        <v-divider
          v-show="expanded"
          class="animate_change"
        />
        <v-expansion-panel-content class="pl-0">
          <v-row
            align="baseline"
            no-gutters
            class="mt-2 pl-0"
          >
            <v-col
              v-if="currentCider.salesDesignation != null && currentCider.salesDesignation !== ''"
              cols="12"
              justify="start"
              style="text-align: left"
            >
              <div class="d-flex flex-column">
                <span class="info-label text--secondary text-caption text-left">
                  {{ $t('CiderInfoExpPanel.salesDesignation') }}
                </span>
                <span class="data-entry text-caption text-left">
                  {{ currentCider.salesDesignation }}
                </span>
              </div>
            </v-col>
<!--            <v-col-->
<!--              v-if="typ"-->
<!--              cols="12"-->
<!--              align="start"-->
<!--              style="text-align: left"-->
<!--            >-->
<!--              <span class="text-left info-label text&#45;&#45;secondary text-caption">Profile</span>-->
<!--              <p-->
<!--                class="mb-1"-->
<!--                v-html="typ"-->
<!--              />-->
<!--            </v-col>-->
          </v-row>
          <v-row
            justify="start"
            class="pt-2 pb-2"
          >
            <v-col
              cols="4"
              class="pt-0 pb-0"
            >
              <div class="d-flex flex-row">
                <div class="d-flex flex-column align-start">
                  <span class="info-label text--secondary text-caption text-left">
                    {{ $t('alcoholAbv') }}
                  </span>
                  <span class="data-entry text-caption text-left">
                    {{ currentCider.alkohol }} % vol.
                  </span>
                </div>
              </div>
            </v-col>
            <v-col
              cols="4"
              class="pt-0 pb-0"
            >
              <div class="d-flex flex-row">
                <div class="d-flex flex-column align-start">
                  <span class="info-label text--secondary text-caption text-left">
                    {{ $t('filtrationClarity') }}
                  </span>
                  <span class="data-entry text-caption text-left">
                    {{ currentCider.filtrationClarity || '-' }}
                  </span>
                </div>
              </div>
            </v-col>
            <v-col
              cols="4"
              class="pt-0 pb-0"
            >
              <div class="d-flex flex-column align-start">
                <span class="info-label text--secondary text-caption text-left">
                  {{ $t('method') }}
                </span>
                <span class="data-entry text-caption text-left">
                  {{ currentCider.method || '-' }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row
            justify="start"
            class="pt-2 pb-2"
          >
            <v-col
              cols="4"
              class="pt-0 pb-0"
            >
              <div class="d-flex flex-column align-start">
                <span class="text-left info-label text--secondary text-caption">
                  {{ $t('pressure') }}
                </span>
                <span class="text-wrap data-entry text-caption text-left">
                  {{ currentCider.pressure || '-' }}
                </span>
              </div>
            </v-col>
            <v-col
              cols="8"
              class="pt-0 pb-0"
            >
              <div class="d-flex flex-column align-start">
                <span class="info-label text--secondary text-caption text-left">
                  {{ $t('residualSugar') }}
                </span>
                <div class="d-flex align-start">
                  <span
                    class="d-flex data-entry text-caption text-left"
                    style="min-width: 64px;"
                  >
                    {{ currentCider.residualSugar || '-' }} g/L
                  </span>
                  <v-icon>
                    mdi-circle-small
                  </v-icon>
                  <span class="d-flex data-entry text-caption text-left flex-grow-0">
                    {{ currentCider.residualSugarDescription || '-' }}
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
        <v-expand-transition>
          <v-alert
            v-if="ciderRatingFinished"
            text
            style="width: 94%;"
            border="left"
            class="text-left mb-2 ml-auto mr-auto rounded-lg"
            type="success"
            icon="mdi-check-decagram"
          >
            {{ $t('CiderStepper.step3SubtitleFinishedByChair') }}
          </v-alert>
          <v-alert
            v-if="ciderRatingInProgress"
            text
            style="width: 94%;"
            border="left"
            class="text-left mb-2 ml-auto mr-auto rounded-lg"
            type="info"
            icon="mdi-account-edit"
          >
            {{ $t('CiderStepper.step3SubtitleFinishedByUserNotComplete') }}
          </v-alert>
        </v-expand-transition>
      </v-expansion-panel>
      <v-divider />
      <v-expansion-panel
        v-if="tastingIsRunning"
        readonly
        class="animate_change"
        style="border-bottom-right-radius: 20px; border-bottom-left-radius: 20px"
        :class="{'expansion_panel_shadow_2': info_panel_elevation === 2,'expansion_panel_shadow_6': info_panel_elevation === 6,}"
        :style="{'background-color': $vuetify.theme.currentTheme.expansion_panel}"
      >
        <v-expansion-panel-header
          v-show="showCiderActions"
          class="pa-0 animate_change"
        >
          <v-card-actions
            id="ciderInfoExpPanelActions"
            class="pa-0 pl-1 pr-1"
          >
            <v-spacer />
            <v-btn
              v-show="showCiderChairStart"
              color="primary lighten-2"
              text
              rounded
              :loading="loadingTableNotes"
              :disabled="loadingTableNotes"
              @click="chairStartCider"
              @click.native.stop=""
            >
              <template #loader>
                <v-progress-circular
                  indeterminate
                  size="24"
                />
                <span>
                  {{ $t('CiderInfoExpPanel.enableRatingChair') }}
                </span>
              </template>
              <v-icon class="pr-1">
                mdi-account-multiple-check
              </v-icon>
              {{ $t('CiderInfoExpPanel.enableRatingChair') }}
            </v-btn>
            <v-btn
              v-show="showCiderChairFinish"
              color="primary lighten-2"
              text
              rounded
              :loading="loadingTableNotes"
              :disabled="loadingTableNotes"
              @click="updateChairNotesFieldFinishedTrue"
              @click.native.stop=""
            >
              <template #loader>
                <v-progress-circular
                  indeterminate
                  size="24"
                />
                <span>{{ $t('CiderInfoExpPanel.finishRatingChair') }}</span>
              </template>
              <v-icon class="pr-1">
                mdi-check-decagram
              </v-icon>
              {{ $t('CiderInfoExpPanel.finishRatingChair') }}
            </v-btn>
            <v-btn
              v-show="showCiderChairRestart"
              color="primary lighten-2"
              text
              rounded
              :loading="loadingTableNotes"
              :disabled="loadingTableNotes"
              @click="restartCider"
              @click.native.stop=""
            >
              <template #loader>
                <v-progress-circular
                  indeterminate
                  size="24"
                />
                <span>{{ $t('CiderInfoExpPanel.restartRatingChair') }}</span>
              </template>
              <v-icon class="pr-1">
                mdi-restart
              </v-icon>
              {{ $t('CiderInfoExpPanel.restartRatingChair') }}
            </v-btn>

            <v-btn
              v-show="showCiderUserStart"
              color="primary lighten-2"
              text
              rounded
              :loading="loadingTableNotes"
              :disabled="loadingTableNotes"
              @click="updateUserNotesFieldStartedTrue"
              @click.native.stop=""
            >
              <template #loader>
                <v-progress-circular
                  indeterminate
                  size="24"
                />
                <span style="opacity: 0.4 !important;">{{ $t('CiderInfoExpPanel.startRatingJuror') }}</span>
              </template>
              <v-icon class="pr-1">
                mdi-circle-edit-outline
              </v-icon>
              {{ $t('CiderInfoExpPanel.startRatingJuror') }}
            </v-btn>
            <v-btn
              v-show="showCiderUserNotFinished"
              plain
              :ripple="false"
              color="primary lighten-2"
              class="btn-no-hover"
              text
              rounded
              @click="$emit('userWantsToFinish')"
              @click.native.stop=""
            >
              <v-icon class="pr-1">
                mdi-star-check
              </v-icon>
              {{ $t('CiderInfoExpPanel.finishRatingJuror') }}
            </v-btn>
            <v-btn
              v-show="showCiderUserFinished"
              color="primary lighten-2"
              text
              rounded
              :loading="loadingTableNotes"
              :disabled="loadingTableNotes"
              @click="updateUserNotesFieldFinishedFalse"
              @click.native.stop=""
            >
              <template #loader>
                <v-progress-circular
                  indeterminate
                  size="24"
                />
                <span>{{ $t('CiderInfoExpPanel.editRatingJuror') }}</span>
              </template>
              <v-icon class="pr-1">
                mdi-circle-edit-outline
              </v-icon>
              {{ $t('CiderInfoExpPanel.editRatingJuror') }}
            </v-btn>
            <v-btn
              v-show="showCiderUserFinish"
              color="primary lighten-2"
              text
              rounded
              :loading="loadingTableNotes"
              :disabled="loadingTableNotes"
              @click="updateUserNotesFieldFinishedTrue"
              @click.native.stop=""
            >
              <template #loader>
                <v-progress-circular
                  indeterminate
                  size="24"
                />
                <span>{{ $t('CiderInfoExpPanel.finishRatingJuror') }}</span>
              </template>
              <v-icon class="pr-1">
                mdi-star-check
              </v-icon>
              {{ $t('CiderInfoExpPanel.finishRatingJuror') }}
            </v-btn>
          </v-card-actions>
          <template #actions>
            <v-icon color="primary" />
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content />
      </v-expansion-panel>
    </v-expansion-panels>

    <v-snackbar
      v-model="showSnackbarActions"
      color="secondary_card"
      rounded="xl"
      multi-line
      bottom
      :vertical="showSnackbarActionClose && snackbarAction != null"
      style="margin-top: 108px"
    >
      <span class="text-left text-subtitle-1">
        {{ $t(snackbarMessage) }}
      </span>
      <template #action="{ attrs }">
        <div
          class="d-flex"
          :class="{'flex-column-reverse': isSmallScreen}"
          style="width: 100%;"
        >
          <v-btn
            v-if="showSnackbarActionClose"
            color="white"
            text
            rounded
            class="ml-0 ml-md-2"
            v-bind="attrs"
            @click="showSnackbarActions = false"
          >
            {{ $t('close') }}
          </v-btn>
          <v-btn
            v-if="snackbarAction != null"
            color="white"
            class="ml-0 ml-md-2"
            text
            rounded
            v-bind="attrs"
            @click="snackbarActionClick"
          >
            <v-icon class="pr-1">
              {{ snackbarActionIcon }}
            </v-icon>
            {{ $t(snackbarActionText) }}
          </v-btn>
        </div>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Vue from "vue";
import i18n from '@/i18n'
import {apiTyped} from "@/api";
import CountryFlag from 'vue-country-flag'
import {sync} from "vuex-pathify";
import CiderRatingState from "@/types/ciderRatingState";
import {createCiderNotesFromDatabase} from "@/types/ciderNotes";
import {createChairNotesFromDatabase} from "@/types/chairNotes";
import party from "party-js";
import ChipPoints from "@/components/chip_points";
import ConfirmDialog from "@/components/confirm_dialog";
import ModalFinishCider from "@/components/modal_finish_cider";
import ModalConfirm from "@/components/modal_confirm";
import BadgeComponent from "@/components/badge";
import rating_mixin from "@/components/mixins/rating_mixin";
import {addAllBewertungenToCiders} from "@/types/jurorCider";
import {allRoutes, makeRoute} from "@/router";
import cider_mixin from "@/components/mixins/cider_mixin";
import util from "@/util";

const Component = Vue.extend({
  name: "CiderInfoExpPanel",
  components: {
    BadgeComponent,
    ModalConfirm,
    ModalFinishCider,
    ConfirmDialog,
    ChipPoints,
    CountryFlag
  },
  mixins: [rating_mixin, cider_mixin],
  props: {
    flightsCidersId: Number,
    markedFinished: Boolean,
    currentPoints: Number,
    isSummary: Boolean,
  },
  data: () => ({
    expandedHeight: 0,
    showSnackbarActions: false,
    showSnackbarActionClose: false,
    snackbarMessage: "",
    snackbarActionIcon: "",
    snackbarActionText: "",
    snackbarAction: null,
    snackbarActionArgumentList: [],
    heartLoading: false,
    bookmarkLoading: false,
    reloadBadge: 0,
    open_infos: [0],
    last_scroll: 0,
    wait_for_info_panel: false,
    wait_for_info_panel_timeout: null,
    info_panel_elevation: 2,
    pin_info_header: true,
    is_favourite: false,
    is_pinned: false,
    isExpanding: false,
    isExpandingTimeout: false,
    oldBadgeData: null,
    currentBadgeUrl: null,
    loadingTableNotes: false,
    // markedFinished: false,
  }),
  i18n: {
    messages: {
      de: {
        "pointsToNextMessage": "Punkte bis zur nächsten Badge",
        "pointToNextMessage": "Punkt bis zur nächsten Badge",
      },
      en: {
        "pointsToNextMessage": "points to next badge",
        "pointToNextMessage": "point to next badge",
      },
    }
  },
  watch: {
    isSummary: function () {
      console.log("CHANGEDSD")
      this.reloadBadge += 1;
    },
    currentCiderPoints: function (newPoints, oldPoints) {
      const badgeData = this.$store.getters["badgesStore/getBadgeData"](newPoints);
      console.log("CHANGEDSD", newPoints, oldPoints, badgeData.punkteMin, this.oldBadgeData)
      if (this.showBadgeSummary && this.$refs.badgeImage != null && this.$refs.badgeImage.$el != null
          && badgeData != null && badgeData.hasImage && this.oldBadgeData !== null) {
        console.log("CONFETTI", badgeData.punkteMin , this.oldBadgeData.punkteMin)
        if (badgeData.punkteMin > this.oldBadgeData.punkteMin) {
          party.confetti(this.$refs.badgeImage.$el, {
            // Specify further (optional) configuration here.
            count: party.variation.range(50, 100),
            size: party.variation.range(0.6, 1.4),
            color: party.Color.fromHex(badgeData.color),
          });
        }
        if (badgeData.punkteMin < this.oldBadgeData.punkteMin && badgeData.color != null) {
          party.confetti(this.$refs.badgeImage.$el, {
            // Specify further (optional) configuration here.
            count: party.variation.range(10, 15),
            size: party.variation.range(0.6, 1.4),
            // color: party.Color.fromHex('#000000'),
            color: party.Color.fromHex(badgeData.color),
            spread: 0,
            speed: 1,
          });
        }
      }
      this.oldBadgeData = badgeData;
      this.reloadBadge += 1;
      this.currentBadgeUrl = this.getBadgeUrl(200, newPoints);
    }
  },
  computed: {
    currentBadgeData() {
      return this.$store.getters["badgesStore/getBadgeData"](this.currentCiderPoints);
    },
    pointsToNextBadge() {
      return util.util.round_to_decimal(this.nextBadge.punkteMin - this.currentCiderPoints, 0.1);
    },
    nextBadge() {
      const currentPoints = this.currentCiderPoints;
      const currentBadgeData = this.$store.getters["badgesStore/getBadgeData"](currentPoints);
      const badgesData = this.$store.getters["badgesStore/getBadgesSorted"]()
      return badgesData.find(b => b.punkteMin > currentBadgeData.punkteMax);
    },
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return false
        default: return false
      }
    },
    // isSummary() {
    //   return this.$attrs['is-summary'].toLowerCase() === "true";
    // },
    expanded () {
      return this.open_infos.includes(0);
    },
    cardWidth () {
      return this.expanded ? "600px" : "400px";
    },
    // is_small_screen () {
    //   switch (this.$vuetify.breakpoint.name) {
    //     case 'xs': return true
    //     default: return false
    //   }
    // },
    showCiderActions() {
      if (this.ciderState === CiderRatingState.unknown) return false;
      return (this.showCiderUserStart
        || this.showCiderUserFinish
        || this.showCiderUserFinished
        || this.showCiderUserNotFinished
        || this.showCiderChairStart
        || this.showCiderChairFinish
        || this.showCiderChairRestart) && this.tastingIsRunning;
    },
    showCiderJurorChairStart() {
      // true if cider is not started and current juror is not a chair
      if (!this.$store.getters["usersStore/isChair"]())
        return (this.ciderState === CiderRatingState.notStarted ||
          this.ciderState === CiderRatingState.restartedByChair) && !this.isSummary;
      else return false;
    },
    showCiderChairStart() {
      if (this.$store.getters["usersStore/isChair"]())
        return (this.ciderState === CiderRatingState.notStarted ||
          this.ciderState === CiderRatingState.restartedByChair) && !this.isSummary;
      else return false;
    },
    showCiderChairRestart() {
      if (this.$store.getters["usersStore/isChair"]()) {
        console.log("this.$store.getters[\"ciderStore/allBewertungFinishedOrEmpty\"]();", this.$store.getters["ciderStore/allBewertungFinishedOrEmpty"]());
        return this.ciderState === CiderRatingState.finishedByChair
            && this.$store.getters["ciderStore/allBewertungFinishedOrEmpty"]();
      }
      else return false;
    },
    showCiderUserStart() {
      return (this.ciderState === CiderRatingState.startedByChair) && !this.isSummary;
    },
    showCiderUserNotFinished() {
      return this.ciderState === CiderRatingState.startedByUser
        && !this.showCiderUserFinished
        && !this.showCiderUserFinish
        && !this.isSummary;
    },
    showCiderUserFinish() {
      return this.ciderState === CiderRatingState.startedByUser
          && this.$store.getters["ciderStore/userBewertungFinished"]() && !this.isSummary;
    },
    showCiderUserFinished() {
      return this.ciderState === CiderRatingState.finishedByUser && !this.isSummary;
    },
    ciderRatingFinished() {
      return this.ciderState === CiderRatingState.finishedByChair
        && this.$store.getters["ciderStore/allBewertungFinished"]();
    },
    ciderRatingInProgress() {
      return (this.ciderState === CiderRatingState.finishedByUser || this.ciderState === CiderRatingState.startedByUser)
        && !Object.values(this.currentCider.allNotes).every(note => note != null && note.finished === true)
        && this.isSummary;
    },
    showBadgeSummary () {
      return this.$store.getters["badgesStore/showBadge"](null, this.isSummary);
    },
    currentCiderIdx () {
      return this.$store.getters["ciderStore/getCiderIdxByFlightsCidersId"](this.flightsCidersId);
    },
    currentCider: sync('ciderStore/ciderList@[:currentCiderIdx]'),
    currentTableIdx: sync("tableStore/currentTable"),
    tischfunktionen: sync('tableStore/tables@[:currentTableIdx].myTischfunktionen'),
    currentCiderPoints () {
      console.log("SUMMERY FINSIHED", this.$store.getters["ciderStore/getFinishedUserIds"]())
      return this.isSummary ?
          this.$store.getters["ciderStore/getTotalPointsForCider"](
              this.currentCider, 0.1, this.$store.getters["ciderStore/getFinishedUserIds"]())
        : this.$store.getters["ciderStore/getPointsForCider"](this.currentCider);
    },
    typ() {
      return this.getProfile(this.currentCider);
    //   let returnTyp = "";
    //   const userLanguageCode = this.$store.getters["usersStore/getUserLanguage"]();
    //   if (this.currentCider.translations != null
    //       && this.currentCider.translations.hasOwnProperty(userLanguageCode)
    //       && this.currentCider.translations[userLanguageCode].typ != null) {
    //     // if typ translation is provided by us in backend use this instead of producer profile
    //     console.log("Returning type", this.currentCider.translations, userLanguageCode)
    //     returnTyp = this.currentCider.translations[userLanguageCode].typ;
    //   }
    //   if (returnTyp === "" && (this.currentCider.typ == null || this.currentCider.typ === "")
    //     && this.currentCider.profilTranslations != null) {
    //     // return this.currentCider.profile
    //     console.log("Profile has translations__")
    //     const userLanguage = this.$store.state.usersStore.language;
    //     if (this.currentCider.profilTranslations.hasOwnProperty(userLanguage)) {
    //       returnTyp = this.currentCider.profilTranslations[userLanguage];
    //     } else {
    //       const defaultLanguage = this.$store.state.usersStore.DEFAULT_LANG;
    //       if (this.currentCider.profilTranslations.hasOwnProperty(defaultLanguage)) {
    //         returnTyp = this.currentCider.profilTranslations[defaultLanguage];
    //       } else {
    //         returnTyp = this.currentCider.profil;
    //       }
    //     }
    //   } else {
    //     // fallback for old tastings where typ was used.
    //     returnTyp = this.currentCider.translations !== null && this.currentCider.translations.hasOwnProperty(userLanguageCode)
    //       ? this.currentCider.translations[userLanguageCode].typ : this.currentCider.typ;
    //   }
    //   if (returnTyp === "") returnTyp = this.currentCider.profil;
    //   // const regExProducer = new RegExp(this.currentCider.produzent, "ig");  // case insensitive
    //   // const regExProduct = new RegExp(this.currentCider.name, "ig");  // case insensitive
    //   // return returnTyp.replace(regExProducer, "---").replace(regExProduct, "---");
    //   return returnTyp;
    },
    labelPunkte () {
      return this.isSummary ? this.$t("totalScore") : this.$tc('point', 2);
    },
  },
  mounted() {
    this.currentBadgeUrl = this.getBadgeUrl(200, this.currentCiderPoints);
    if (this.isSummary) {
      this.pin_info_header = false;
    }
    this.reloadBadge += 1;
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    async snackbarActionClick() {
      this.showSnackbarActions = false;
      if (this.snackbarAction != null) {
        await this.snackbarAction();
      }
    },
    ratingClick() {
      this.snackbarAction = null;
      this.snackbarMessage = "";
      this.showSnackbarActionClose = true;
      this.snackbarActionArgumentList= [];
      let showSnackbar = false;
      if (this.showCiderJurorChairStart) {
        this.snackbarMessage = "CiderInfoExpPanel.notStartedByChairSnackbar";
        showSnackbar = true;
      } else if (this.showCiderChairStart && this.getCurrentTasting.started && !this.getCurrentTasting.finished) {
        this.snackbarMessage = "CiderInfoExpPanel.chairStartSnackbar";
        this.snackbarActionIcon = "mdi-account-multiple-check"
        this.snackbarActionText = "CiderInfoExpPanel.enableRatingChair";
        this.snackbarAction = this.chairStartCider;
        showSnackbar = true;
      }
      // else if (this.showCiderChairFinish) {  // can maybe be removed
      //   this.snackbarMessage = "chairFinishSnackbar?";
      //   this.snackbarActionIcon = "mdi-check-decagram"
      //   this.snackbarActionText = "CiderInfoExpPanel.finishRatingChair";
      //   this.snackbarAction = this.updateChairNotesFieldFinishedTrue;
      //   showSnackbar = true;
      // }
      else if (this.showCiderChairRestart && this.getCurrentTasting.started && !this.getCurrentTasting.finished) {
        this.snackbarMessage = "CiderInfoExpPanel.chairRestartSnackbar";
        this.snackbarActionIcon = "mdi-restart"
        this.snackbarActionText = "CiderInfoExpPanel.restartRatingChair";
        this.snackbarAction = this.restartCider;
        showSnackbar = true;
      } else if (this.showCiderUserStart && this.getCurrentTasting.started && !this.getCurrentTasting.finished) {
        this.snackbarMessage = "CiderInfoExpPanel.userStartSnackbar";
        this.snackbarActionIcon = "mdi-circle-edit-outline"
        this.snackbarActionText = "CiderInfoExpPanel.startRatingJuror";
        this.snackbarAction = this.updateUserNotesFieldStartedTrue;
        showSnackbar = true;
      } else if (this.showCiderUserFinished && this.getCurrentTasting.started && !this.getCurrentTasting.finished) {
        this.snackbarMessage = "CiderInfoExpPanel.userFinishSnackbar";
        this.snackbarActionIcon = "mdi-circle-edit-outline"
        this.snackbarActionText = "CiderInfoExpPanel.editRatingJuror";
        this.snackbarAction = this.updateUserNotesFieldFinishedFalse;
        showSnackbar = true;
      }
      this.showSnackbarActions = showSnackbar;
    },
    expandPanel() {
      window.clearTimeout(this.isExpandingTimeout);
      this.isExpanding = true;
      this.isExpandingTimeout = window.setTimeout(() => {
        this.isExpanding = false;
      }, 500);
    },
    getColor (points) {
      return this.$store.getters["badgesStore/getColor"](points);
    },
    calculateTotalPointsForCider (item) {
      if (item === null) return 0;
      return this.$store.getters["ciderStore/getTotalPointsForCider"](item);
    },
    // getKategoryInfo() {
    //   return this.currentCider.kategorie.info;
    // },
    getTotalPoints () {
      // let abschlussbewertung_ = this.get_abschlussbewertung;
      // if (abschlussbewertung_ === undefined || abschlussbewertung_ === null)
      //   return 0;
      return this.$store.getters["ciderStore/getTotalPointsForCider"](this.currentCider);
    },
    // get_total_points: async function () {
    //   console.log("GETTING TOTLA")
    //   const abschlussbewertung_ = await this.abschlussbewertung();
    //   console.log("Fot Abschlussbewertung", abschlussbewertung_)
    //   return this.$store.getters.get_points_from_category(abschlussbewertung_);
    // },
    // abschlussbewertung: async function () {
    //   let abschlussbewertung_ = this.$store.state.abschlussbewertung;
    //   if (abschlussbewertung_ === undefined || abschlussbewertung_ === null) {
    //     abschlussbewertung_ = await api.getGesamtbewertung(this.$store.get("current_cider"), false);
    //     this.$store.set('abschlussbewertung', abschlussbewertung_);
    //   }
    //   console.log("Fot Abschlussbewertung", this.$store.state.abschlussbewertung.bewertungskategorien)
    //   return this.$store.state.abschlussbewertung.bewertungskategorien;
    // },
    toggle_info_header() {
      this.pin_info_header = !this.pin_info_header;
      this.isLeft = !this.isLeft;
    },
    set_info_panel_timeout() {
      this.wait_for_info_panel = false;
      this.wait_for_info_panel_timeout = null;
    },
    getBadgesData() {
      return this.$store.state.badgesStore.badgesData;
    },
    getBadgeUrl (width, points = null) {
      if (points === null) points = this.$store.getters["ciderStore/getPointsForCurrentCider"]();
      return this.$store.getters["badgesStore/getBadgeIcon"](points, width);
    },
    // getCurrentBadgeUrl () {
    //   const points = this.$store.getters["ciderStore/getPointsForCurrentCider"]();
    //   const badgeData = this.$store.getters["badgesStore/getBadgeData"](points);
    //   if (this.showBadgeSummary && this.$refs.badgeImage !== undefined && this.$refs.badgeImage.$el !== undefined
    //       && badgeData !== null && badgeData.hasImage && this.oldBadgeData !== null) {
    //     console.log("CONFETTI", badgeData.punkteMin, this.oldBadgeData.punkteMin)
    //     if (badgeData.punkteMin > this.oldBadgeData.punkteMin) {
    //       party.confetti(this.$refs.badgeImage.$el, {
    //         // Specify further (optional) configuration here.
    //         count: party.variation.range(0, 100),
    //         size: party.variation.range(0.6, 1.4),
    //       });
    //     }
    //     console.log("CONF", )
    //     if (badgeData.punkteMin < this.oldBadgeData.punkteMin) {
    //       party.confetti(this.$refs.badgeImage.$el, {
    //         // Specify further (optional) configuration here.
    //         count: party.variation.range(0, 5),
    //         size: party.variation.range(0.6, 1.4),
    //       });
    //     }
    //   }
    //   this.oldBadgeData = badgeData;
    //   return this.getBadgeUrl(200, points);
    // },
    handleScroll () {
      // let expHeight = 0;
      // const viewHeight = document.documentElement.clientHeight;
      if (!this.isExpanding
        && this.pin_info_header
        && this.expanded
        && this.$refs.ciderInfoExpPanel != null
        && this.$refs.ciderInfoExpPanel.$el != null
      ) {
        const topHeight = this.isSmallScreen ? 68 : 16;
        console.log("expHeight", this.$refs.ciderInfoExpPanel.$el.getBoundingClientRect().top)
        if (this.$refs.ciderInfoExpPanel.$el.getBoundingClientRect().top < topHeight) {
          if (this.open_infos.length !== 0) {
            this.open_infos = [];  // close panel
            this.expandPanel();
          }
          if (this.pin_info_header) {
            this.info_panel_elevation = 6;
          }
        }
      }
      // if (window.scrollY !== 0 && !this.isExpanding && this.pin_info_header && this.last_scroll - window.scrollY < -1
      //     && window.scrollY > (expHeight || 25)) {
      //   if (this.open_infos.length !== 0) {
      //     this.open_infos = [];  // close panel
      //     this.expandPanel();
      //   }
      //   if (this.pin_info_header) {
      //     this.info_panel_elevation = 6;
      //   }
      // }
      if (!this.isExpanding && this.last_scroll - window.scrollY > 1 && window.scrollY <= 25) {
        if (this.open_infos.length === 0) {
          this.info_panel_elevation = 2;
          this.open_infos = [0]  // open panel
          this.expandPanel();
        }
      } else {
        // if (this.pin_info_header) {
        //   console.log("LELELELE")
        //   this.info_panel_elevation = 6;
        // }
      }
      this.last_scroll = window.scrollY;
    },
    closePanelFromParent() {
      if (this.isExpanding || !this.pin_info_header) return;
      console.log("CLose form par", this.last_scroll - window.scrollY);
      if (this.last_scroll < window.scrollY) return;  // scrolling up, do not collapse
      if (this.open_infos.length !== 0) {
        this.open_infos = [];  // close panel
        this.expandPanel();
      }
      if (this.pin_info_header) {
        this.info_panel_elevation = 6;
      }
    },
    async toggleHeart () {
      if (this.currentCider.notes == null) return;  // not yet loaded
      const timeout = window.setTimeout(() => { this.heartLoading = true }, 1000);
      this.currentCider.notes.isFavourite = await apiTyped.updateNotesField(this.currentCider.notes.id, this.currentCider.id,
          'is_favourite', !this.currentCider.notes.isFavourite);
      if (timeout != null) window.clearTimeout(timeout);
      this.heartLoading = false;
    },
    async togglePin () {
      if (this.currentCider.notes == null) return;  // not yet loaded
      const timeout = window.setTimeout(() => { this.bookmarkLoading = true }, 1000);
      this.currentCider.notes.isPinned = await apiTyped.updateNotesField(this.currentCider.notes.id, this.currentCider.id,
          'is_pinned', !this.currentCider.notes.isPinned);
      if (timeout != null) window.clearTimeout(timeout);
      this.bookmarkLoading = false;
    },
    async chairStartCider() {
      this.loadingTableNotes = true;
      await this.updateChairNotesField('started', true, true)
      await this.updateUserNotesField('started', true, true)
      await this.updateChairNotesField('restarted', false, true);
      // remove requested cider
      if (this.currentCider.requestCider &&
        (await this.$refs.modalConfirm.open(
          "titleRequestCider",
          "messageRequestCider",
          "mdi-glass-wine",
          false, "yes", "no") === true)) {
        this.currentCider.requestCider = await apiTyped.updateFlightsCidersRequest(
          this.currentCider.flightsCidersId, false)
      }
      this.loadingTableNotes = false;
    },
    async updateChairNotesFieldFinishedTrue() {
      this.$emit("updateChairNotesFieldFinishedTrue");
      // await this.updateChairNotesField('finished', true);
    },
    async doUpdateChairNotesFieldFinishedTrue() {
      await this.updateChairNotesField('finished', true);
    },
    async updateChairNotesField (field, value) {
      this.loadingTableNotes = true;
      const payload = {};
      // Refresh current cider points
      await addAllBewertungenToCiders(
        [this.currentCider], [], this.$store.getters["tableStore/getTableMembersIds"]());
      if (field === "finished" && value &&
        await this.$refs.finishCiderDialog.open(
          true,
          this.currentCider.chairNotes.kommentar,
          true,
        ) === false) {
        this.loadingTableNotes = false;
        return;
      }
      if (field === "finished" && value === true) {
        payload["restarted"] = false;
      }
      payload[field] = value;
      if (field + "At" in this.currentCider.chairNotes) payload[field + "_at"] = new Date().toJSON();
      const resp = await apiTyped.updateChairNotesForCider(
        this.currentCider.chairNotes.id, this.currentCider.id, payload);
      this.currentCider.chairNotes = createChairNotesFromDatabase(resp);
      if (field === "finished" && value === true) {
        const badgeData = this.$store.getters["badgesStore/getBadgeData"](this.currentCiderPoints);
        console.log("FINISHEDING ", this.flightsCidersId, this.currentCiderPoints, badgeData.id)
        await apiTyped.updateFlightsCidersPoints(this.flightsCidersId, this.currentCiderPoints, badgeData.id);
        if (this.$refs.badgeImage != null && badgeData.color != null) {
          party.confetti(this.$refs.badgeImage.$el, {
            count: party.variation.range(50, 100),
            size: party.variation.range(0.6, 1.4),
            color: party.Color.fromHex(badgeData.color),
          });
        }
        // fail-safe: update all juror cider notes finish status to true
        const updatePromises = [];
        for (const [jurorId, jurorNote] of Object.entries(this.currentCider.allNotes)) {
          if (jurorNote == null) continue;
          const payload = {
            finished: true,
            finished_at: new Date().toJSON(),
          }
          updatePromises.push(
            apiTyped.updateNotesForCider(jurorNote.id, this.currentCider.id, payload));
        }
        await Promise.all(updatePromises);
      }
      this.loadingTableNotes = false;
    },
    async updateUserNotesFieldStartedTrue() {
      await this.updateUserNotesField('started', true, true);
    },
    async updateUserNotesFieldFinishedFalse() {
      await this.updateUserNotesField('finished', false, false);
    },
    async updateUserNotesFieldFinishedTrue() {
      this.$emit("updateUserNotesFieldFinishedTrue");
    },
    async doUpdateUserNotesFieldFinishedTrue() {
      await this.updateUserNotesField('finished', true, true)
    },
    async updateUserNotesField(field, value, time=false) {
      if (this.currentCider.notes == null) {
        console.error("Notes are null for current product", this.currentCider);
        return;
      }
      this.loadingTableNotes = true;
      if (field === "finished" && value === true
        && await this.$refs.finishCiderDialog.open(
          false,
          this.currentCider.notes.kommentar,
          true,
        ) === false) {
        this.loadingTableNotes = false;
        return;
      }
      const payload = {};
      payload[field] = value;
      if (time && field + "At" in this.currentCider.notes) {
        // Don't update started_at if rating is restarted:
        console.log(this.currentCider.chairNotes)
        if (field === "started" && this.currentCider.chairNotes?.restartedCount > 0) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const a = 0
        }
        else {
          payload[field + "_at"] = new Date().toJSON();
        }
      }
      const resp = await apiTyped.updateNotesForCider(this.currentCider.notes.id, this.currentCider.id, payload);
      this.currentCider.notes = createCiderNotesFromDatabase(resp);
      const badgeData = this.$store.getters["badgesStore/getBadgeData"](this.currentCiderPoints);
      if (field === "finished" && value && this.$refs.badgeImage != null && badgeData.color != null) {
        party.confetti(this.$refs.badgeImage.$el, {
          count: party.variation.range(50, 100),
          size: party.variation.range(0.6, 1.4),
          color: party.Color.fromHex(badgeData.color),
        });
      }
      this.loadingTableNotes = false;
      if (field === "finished" && value === true) {
        const path = makeRoute(
          this.makeTastingRoute(allRoutes.tastings.summary, this.getCurrentTasting),
          { flightsCidersId: this.flightsCidersId})
        await this.$router.push(path);
      }
    },
    async restartCider() {
      this.loadingTableNotes = true;
      if (await this.$refs.modalConfirm.open(
        "titleRestartCiderRating", "messageRestartCiderRating", "mdi-restart") === false) {
        this.loadingTableNotes = false;
        return
      }
      const now = new Date().toJSON();
      const payload = {
        "restarted_count": this.currentCider.chairNotes.restartedCount + 1,
        "restarted": true,
        "restarted_at": now,
        "started": true,
        "started_at": now,
        "finished": false,
        "finished_at": null,
      }
      const resp = await apiTyped.updateChairNotesForCider(this.currentCider.chairNotes.id, this.currentCider.id,
          payload);
      this.currentCider.chairNotes = createChairNotesFromDatabase(resp);
      const notes = await apiTyped.resetCiderState(this.currentCider);
      const myNotes = notes?.find(note => note.juror_id === this.$store.getters["usersStore/userId"]())
      this.currentCider.notes.finished = myNotes?.finished;
      this.currentCider.notes.started = myNotes?.started;
      this.loadingTableNotes = false;
    },
  },
});
export default Component;
</script>

<style scoped>
.flex-gap > div {
  margin: 6px;
}

.panel-top-adjustment {
}

.sticky {
  position: -webkit-sticky !important; /* Safari */
  position: sticky !important;
  z-index: 7;
  /*transition: all 1.5s ease;*/
}
.exp_header {
  /*-moz-transition: all 1.5s ease;*/
  /*-o-transition: all 1.5s ease;*/
  /*-webkit-transition: all 1.5s ease;*/
  /*transition: all 1.5s ease;*/
  /*top: 0px;*/
  /*position: relative;*/
}
.animate_change {
  transition:all 0.25s ease;
}
.abs_left {
  left: 0%;
}
.abs_right {
  right: 0%;
}

.my_container {
  display: flex;              /* establish flex container */
  flex-direction: column;     /* stack flex items vertically */
  justify-content: center;    /* center items vertically, in this case */
  align-items: center;        /* center items horizontally, in this case */
  flex: 1 1 auto;
  overflow-y: auto;
  /*flex-wrap: wrap;*/
  /*min-width:  auto;*/
}

.box {
  text-align: center;
}

.blur_effect {
  backdrop-filter: blur(2px);
  -webkit-box-shadow: inset 0 0 200px #00000000;
  -moz-box-shadow: inset 0 0 200px #00000000;
  box-shadow: inset 0 0 200px #00000000;
}

.font_size_0 {
  font-size: 0;
}

.expansion_panel_shadow_2:before {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.expansion_panel_shadow_6:before {
  box-shadow:  0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}

/deep/ .v-alert__border {
  background-color: var(--v-success-base);
}

/deep/ .btn-no-hover > .v-btn:hover:before {
  display: none !important;
}

.info-label {
  font-size: 14px !important;
}
.data-entry {
  font-size: 16px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  /*width: 100%;*/
}

.info-label-text {
  text-decoration: underline var(--v-divider-base);
  text-underline-offset: 4px;
  opacity: 0.6;
}
</style>
