<template>
  <div
    class="ma-0"
    style="border-radius: 20px; margin: 0 !important; overflow: hidden !important;"
    :class="{'selected-cider': selected}"
  >
    <v-card
      v-if="tasting !== null"
      ref="tastingCardRef"
      :elevation="elevation"
      outlined
      style="overflow: hidden !important;"
      class="animate-shadow overflow-hidden"
      :class="{'border-color-primary': selected}"
    >
      <div
        v-show="selected"
        style="z-index: 100;"
        class="triangle-top-right elevation-0 rounded-t-xl overflow-hidden"
      />
      <v-icon
        v-show="selected"
        color="white"
        size="20"
        style="z-index: 101; position: absolute; top: 8px; right: 8px;"
      >
        mdi-check
      </v-icon>
      <v-row>
        <v-col
          cols="11"
          class="pb-0"
          style="padding-right: 76px;"
        >
          <v-card-text class="text-left pt-3 pb-0">
            <div class="text--secondary align-baseline d-flex">
              <v-icon
                small
                left
              >
                mdi-calendar
              </v-icon>
              {{ $t('tastingCard.date') }} {{ new Date(tasting.datum).toLocaleDateString() }}
            </div>
          </v-card-text>
          <v-card-title class="pb-5 pt-0 text-break text-ellipsis text-left">
            {{ tasting.name }}
          </v-card-title>
        </v-col>
        <v-col cols="1">
          <v-img
            style="position: absolute; top: 8px; right: 8px;"
            width="64"
            class="pa-0"
            :src="getImageUrl(tasting.logoMesse, 250)"
          />
        </v-col>
      </v-row>
      <v-card-subtitle
        class="text-left pb-0 mb-0 pt-0"
        v-html="tastingDescription"
      />
      <v-row
        v-if="!hideMoreInfos"
        no-gutters
      >
        <v-col col="auto">
          <div>
            <v-list-item two-line>
              <v-list-item-icon class="mr-2">
                <v-icon size="20">
                  mdi-calendar-clock
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content
                v-if="new Date(tasting.einreichenAb) < Date.now()"
                class="pb-0 pt-0"
              >
                <v-list-item-title>
                  {{ $t('tastingCard.submissionUntil') }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ new Date(tasting.einreichenBis).toLocaleString() }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content
                v-if="new Date(tasting.einreichenAb) > Date.now()"
                class="pb-0 pt-0"
              >
                <v-list-item-title>
                  {{ $t('tastingCard.submissionFrom') }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ new Date(tasting.einreichenAb).toLocaleString() }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-col>
        <v-col col="auto">
          <div>
            <v-list-item two-line>
              <v-list-item-icon
                v-if="!hasPurchasables || (hasPurchasables && isParticipating)"
                class="mr-2"
              >
                <v-icon size="20">
                  mdi-glass-wine
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content
                v-if="!hasPurchasables"
                class="pb-0 pt-0"
              >
                <v-list-item-title>
                  <strong v-if="submittedCiders.length">
                    {{ submittedCiders.length }}
                    <template v-if="maxSubmissionCount != null">
                      / {{ maxSubmissionCount }}
                    </template>
                  </strong>
                  <template v-else>
                    {{ $t('noProducts') }}
                  </template>
                  {{ $t('submittedProducts', {product: $tc('product', tasting.eingereichteCiderIds.length)}) }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="submittedCiders != null && submittedCiders.length > 0">
                  <v-badge
                    :color="statusColor"
                    inline
                    dot
                    left
                  >
                    {{ statusText }}
                  </v-badge>
                </v-list-item-subtitle>
                <!--                TODO TRANSLATIONS-->
                <template v-if="!hideSubmissionSubText">
                  <v-list-item-subtitle
                    v-if="maxSubmissionCount != null && submittedCiders.length === 0 && maxSubmissionCount > 0"
                  >
                    {{ $tc('upToSubmitted', maxSubmissionCount, {maxSubmissionCount: maxSubmissionCount}) }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="maxSubmissionCount === 0 && submittedCiders.length === 0"
                  >
                    {{ $t('noProductsMaySubmitted') }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="maxSubmissionCount === null"
                  >
                    &nbsp;
                  </v-list-item-subtitle>
                </template>
                <v-list-item-subtitle
                  v-if="hideSubmissionSubText && (submittedCiders == null || submittedCiders.length === 0)"
                >
                  &nbsp;
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-content
                v-if="hasPurchasables"
                class="pb-0 pt-0"
              >
                <v-list-item-title>
                  {{ isParticipating ? $t('alreadyParticipating') : '&nbsp;' }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  &nbsp;
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-col>
      </v-row>
      <slot name="additionalInfos" />
      <v-expand-transition>
        <v-card-actions
          v-show="!hideButtons 
            && (showCancelSubmissionComputed || showStartNewAfterRefund 
              || (submittedCiders == null || submittedCiders.length === 0) || showMoreInfoBtn)"
          class="pt-1 align-start"
          :class="{'flex-column-reverse': isSmSmallScreen,}"
        >
          <v-btn
            v-show="showCancelSubmissionComputed && !showStartNewAfterRefund"
            text
            rounded
            @click="cancelSubmission(tasting)"
            @click.native.stop=""
          >
            <v-icon
              left
            >
              mdi-close
            </v-icon>
            {{ $t('tastingCard.cancelSubmission') }}
          </v-btn>
          <v-btn
            v-show="!showCancelSubmissionComputed && showStartNewAfterRefund"
            text
            rounded
            @click="restartSubmission(tasting)"
            @click.native.stop=""
          >
            <v-icon
              left
            >
              mdi-restart
            </v-icon>
            {{ $t('tastingCard.restartSubmission') }}
          </v-btn>
          <v-spacer
            :class="{'mt-2': isSmSmallScreen,}"
          />
          <v-btn
            v-show="showMoreInfoBtn"
            text
            color="primary"
            rounded
            @click="showTasting(tasting)"
            @click.native.stop=""
          >
            <v-icon
              left
              color="primary"
            >
              mdi-information-outline
            </v-icon>
            {{ $t('tastingCard.moreInfo') }}
          </v-btn>

          <v-btn
            v-show="showPayNow && new Date(tasting.einreichenBis) > Date.now()"
            text
            color="primary"
            rounded
            :loading="payNowLoading"
            class="ml-0"
            @click="payNow"
            @click.native.stop=""
          >
            <v-icon
              left
              color="primary"
            >
              mdi-credit-card
            </v-icon>
            {{ $t('paymentHandling.payNow') }}
          </v-btn>

          <v-spacer
            v-show="!showMoreInfoBtn && (submittedCiders == null || submittedCiders.length === 0)"
            :class="{'mt-2': isSmSmallScreen,}"
          />
          <v-btn
            v-show="!showMoreInfoBtn
              && (submittedCiders == null || submittedCiders.length === 0)
              && tasting.einreichenBis > Date.now()
              && tasting.einreichenAb < Date.now()
            "
            text
            color="primary"
            rounded
            @click="startCiderSubmit"
            @click.native.stop=""
          >
            <v-icon
              left
              color="primary"
            >
              mdi-basket-plus
            </v-icon>
            {{ $t('submitProducts') }}
          </v-btn>
        </v-card-actions>
      </v-expand-transition>
    </v-card>
    <Submission_cancel_dialog ref="confirm" />
  </div>
</template>

<script>
import i18n from '@/i18n'
import logos_mixin from "@/components/mixins/logos_mixin";
import Submission_cancel_dialog from "@/components/producer/submission_cancel_dialog";
import translation_mixin from "@/components/mixins/translation_mixin";
import user_mixin from "@/components/mixins/user_mixin";
import {apiTyped} from "@/api";

export default {
  name: "TastingCard",
  components: {Submission_cancel_dialog},
  mixins: [logos_mixin, translation_mixin, user_mixin],
  props: {
    tasting: { type: Object, required: true, default: null },
    submittedCiders: { type: Array, required: false, default: null },
    elevation: { type: Number, required: false, default: 0 },
    selected: { type: Boolean, required: false, default: false },
    hideMoreInfos: { type: Boolean, required: false, default: false },
    hideSubmissionSubText: { type: Boolean, required: false, default: true },
    showCancelSubmission: { type: Boolean, required: false, default: false },
    showMoreInfoBtn: { type: Boolean, required: false, default: false },
    maxSubmissionCount: { type: Number, required: false, default: null },
    hideButtons: { type: Boolean, required: false, default: false },
    showPayNow: { type: Boolean, required: false, default: false },
    hasPurchasables: { type: Boolean, required: false, default: false },
    isParticipating: { type: Boolean, required: false, default: false },
  },
  data: () => ({
    updateWidth: 1,
    payNowLoading: false,
    cancellingSubmission: false,
    // pSubmittedCiders: null
  }),
  // watch: {
  //   submittedCiders: function (val) { this.pSubmittedCiders = val }
  // },
  // created() {
  //   this.pSubmittedCiders = this.submittedCiders;
  // },
  i18n: {
    messages: {
      de: {
        "alreadyParticipating": "Sie nehmen an dieser Veranstaltung teil",
        "noProducts": "Keine",
        "noProductsMaySubmitted": "Momentan können keine Produkte eingereicht werden",
        "upToSubmitted": "bis zu {maxSubmissionCount} Produkt kann eingereicht werden | bis zu {maxSubmissionCount} Produkte können eingereicht werden",
        "submittedProducts": "eingereichte {product}",
      },
      en: {
        "alreadyParticipating": "You are participating in this event",
        "noProducts": "No",
        "noProductsMaySubmitted": "No products can currently be submitted",
        "upToSubmitted": "up to {maxSubmissionCount} product may be submitted | up to {maxSubmissionCount} products may be submitted",
        "submittedProducts": "submitted {product}",
      }
    }
  },
  computed: {
    tastingDescription() {
      if (Object.keys(this.tasting.translations).length === 0) return this.tasting.beschreibung;
      return this.getTranslation(this.tasting.translations,'beschreibung', this.currentLanguage);
    },
    showCancelSubmissionComputed() {
      return false;
      // return this.showCancelSubmission && this.submittedCiders.length > 0
      //   && this.submittedCiders.every(s => s.status === 'draft' || s.status === 'unpaid')
      //   && this.tasting.datum.setDate(this.tasting.datum.getDate() - 1) > new Date(Date.now())
    },
    showStartNewAfterRefund() {
      return this.showCancelSubmission && this.submittedCiders.length > 0
        && this.submittedCiders.every(s => s.status === 'archived' || s.status === 'refunded')
        && this.tasting.datum.setDate(this.tasting.datum.getDate() - 1) > new Date(Date.now())
    },
    daysUntilDeadline() {
      return Math.ceil(Math.abs(new Date(this.tasting.einreichenBis) - Date.now()) / (1000 * 60 * 60 * 24));
    },
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        default: return false
      }
    },
    isSmSmallScreen () {
      this.updateWidth;
      if (this.$refs.tastingCardRef != null) {
        console.log("this.$refs.tastingCardRef.clientWidth", this.$refs.tastingCardRef)
        return this.$refs.tastingCardRef.$el.clientWidth < 450;
      }
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        default: return false
      }
    },
    statusText() {
      const status = this.submittedStatus;
      switch (status) {
        case 'draft':
          return this.$t('saved')
        case 'unpaid':
          return 'Unpaid'
        case 'payment_success':
          return 'Paid'
        case 'request_refund':
          return 'Requested Refund'
        case 'refunded':
          return 'Refunded'
        case 'missing_data':
          return 'Missing Product Data'
        default:
          return ''
      }
    },
    submittedStatus() {
      const status = this.submittedCiders[0].status;
      if (this.tasting.tastingSettings.allowProductDataUpdate && status === 'payment_success') {
        if (this.submittedCiders.every(c => c.productRevision != null && c.cider != null)) {
          return status;
        } else {
          return 'missing_data';
        }
      } else {
        return status;
      }
    },
    statusColor() {
      const status = this.submittedStatus;
      switch (status) {
        case 'draft':
          return '#a2a448'
        case 'unpaid':
        case 'missing_data':
          return 'error'
        case 'payment_success':
          return 'primary'
        case 'request_refund':
          return 'error'
        case 'refunded':
          return 'secondary'
        default:
          return '#ff0000'
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.updateWidth++;
    });
    window.addEventListener("resize", this.resizedWindow);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizedWindow);
  },
  methods: {
    async payNow() {
      this.payNowLoading = true;
      const ciders = await apiTyped.producerGetCiderByIds(
        this.$store.state.producerStore.producerId, this.submittedCiders.map(c => Number(c.cider)), true);
      if (ciders == null) return;
      this.$store.commit("submitStore/hydrate", {tasting: this.tasting, ciders: ciders});
      console.log("Click____", this.tasting)
      if (this.tasting.tastingSettings.hasPurchasables) {
        await this.$router.push('/producer/submit/selectTastingProducts');
      } else {
        await this.$router.push('/producer/submit/summary');
      }
    },
    startCiderSubmit() {
      this.$store.commit("submitStore/clear");
      this.$router.push('/producer/submit/tastings');
    },
    resizedWindow() {
      this.updateWidth++;
    },
    async showTasting(tasting) {
      await this.$router.push(`/producer/submitted/overview/${tasting.id}`);
    },
    async cancelSubmission(tasting) {
      this.cancellingSubmission = true;
      const cancelSubmission = await this.$refs.confirm.open(this.tasting, this.submittedCiders)
      if (cancelSubmission === false) return;
      await this.$emit('cancelSubmission')
      this.cancellingSubmission = false;
    },
    async restartSubmission(tasting) {
      this.cancellingSubmission = true;
      const cancelSubmission = await this.$refs.confirm.open(this.tasting, this.submittedCiders, true)
      if (cancelSubmission === false) return;
      await this.$emit('restartSubmission')
      this.cancellingSubmission = false;
    }
  },
}
</script>

<style scoped>
.animate-shadow {
  transition: box-shadow 0.3s ease-in-out;
}

.triangle-top-right {
  position: absolute;
  top: -40px;
  right: -40px;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  background: var(--v-primary-base);
  z-index: 6 !important;
}

.selected-cider {
  outline: 0 !important;
  border-color: transparent !important;
  box-shadow: 0 0 0 2px var(--v-primary-base) !important;
}

.border-color-primary {
  border-color: var(--v-primary-base);
}
</style>