<template>
  <v-container
    fluid
    class="pa-0 ma-auto d-flex justify-center flex-column animate_change"
  >
    <ConfirmDialog ref="confirm" />
    <v-card
      class="d-flex pl-4 pr-4"
      flat
      rounded="0"
      :outlined="$vuetify.theme.dark"
      color="transparent"
      :style="{'background-color': 'var(--v-background-base)'}"
    >
      <v-chip-group
        column
        class="animate_change"
      >
        <v-chip
          class="animate_change"
          @click="changeShowFlights()"
        >
          <v-icon
            :class="{rotate_180: !showFlights.every(show => show === false)}"
            left
          >
            mdi-chevron-down
          </v-icon>
          <span v-if="showFlights.every(show => show === false)">Show All Table Infos</span>
          <span v-else>Hide All Table Infos</span>
        </v-chip>
        <v-chip
          @click="updateData(true)"
        >
          <v-icon left>
            mdi-refresh
          </v-icon>
          Update Data
        </v-chip>
      </v-chip-group>
    </v-card>
    <v-divider />
    <v-expand-transition>
      <div
        v-show="!loaded"
        class="pt-3 pb-3 mx-8"
      >
        <v-progress-linear
          height="8"
          rounded
          :value="getCidersForTablesProgress * 100"
        />
      </div>
    </v-expand-transition>
    <v-row
      v-if="loaded"
      v-masonry="'masonryAdminTables'"
      transition-duration="0.3s"
      :horizontal-order="true"
      justify="center"
      align="center"
      class="pa-0 ma-0 animate_change"
    >
      <v-col
        v-for="(table, tableIdx) in tables"
        :key="table.id"
        class="justify-start"
        justify="start"
        align="center"
        cols="12"
        :xl:="showFlights[tableIdx] ? 4 : 3"
        :lg="showFlights[tableIdx] ? 6 : 3"
        :md="showFlights[tableIdx] ? 6 : 3"
        sm="6"
        xs="12"
        @load="$redrawVueMasonry('masonryAdminTables')"
      >
        <v-card
          :key="table.id"
          :outlined="$vuetify.theme.dark"
          class="overflow-auto"
        >
          <div
            class="d-flex justify-end pt-1 pr-4 align-center"
            style="gap: 4px"
          >
            <div class="d-flex justify-end ml-auto pl-2">
              <v-btn
                color="#616161"
                small
                icon
                @click="updateShowFlights(tableIdx)"
              >
                <v-icon :class="{rotate_180: showFlights[tableIdx]}">
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </div>
            <v-spacer />
            <v-chip
              v-if="getTimeDiffTextForTischfunktion(getTischfunktionenSummary(table.tischfunktionen)) !== 0"
              label
              small
              color="transparent"
              class="d-flex body-2 font-italic font-weight-100 align-end text-decoration-underline"
              style="color: var(--v-text_disabled-base)"
            >
              vor {{ getTimeDiffTextForTischfunktion(getTischfunktionenSummary(table.tischfunktionen)) }}
            </v-chip>
            <div
              v-else
              class="mt-8"
            />
            <v-chip v-if="showTischfunktion(getTischfunktionenSummary(table.tischfunktionen))">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    v-if="getTischfunktionenSummary(table.tischfunktionen).melden > 0"
                    v-on="on"
                  >
                    mdi-hand-back-left
                  </v-icon>
                </template>
                {{ tischfunctionMap['melden'] }}
              </v-tooltip>

              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    v-if="getTischfunktionenSummary(table.tischfunktionen).nextCiderId !== null"
                    v-on="on"
                  >
                    mdi-glass-wine
                  </v-icon>
                </template>
                {{ tischfunctionMap['next_cider_id'] }}
              </v-tooltip>

              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    v-if="getTischfunktionenSummary(table.tischfunktionen).frage > 0"
                    v-on="on"
                  >
                    mdi-help-circle
                  </v-icon>
                </template>
                {{ tischfunctionMap['frage'] }}
              </v-tooltip>
            </v-chip>
          </div>
          <v-card-title
            class="pt-1 text-left d-flex flex-row pr-2 pb-4"
            style="word-break: keep-all;"
          >
            {{ table.nummer }}: {{ tableChair(table).firstName + ' ' + tableChair(table).lastName }}
          </v-card-title>
          <v-card-subtitle
            v-if="table.nextCider !== null && table.nextCider !== undefined"
            class="text-left pb-1"
          >
            Nächster Cider #{{ (table.nextCider !== null && table.nextCider !== undefined)
              ? String(table.nextCider.hasOwnProperty('displayId') ? table.nextCider.displayId : cider.id) + ": "
                + table.nextCider.name + ", " + table.nextCider.produzent : "" }}
          </v-card-subtitle>
          <span
            v-if="requestedCidersForTable(table).length > 0"
            class="subheading d-flex justify-start pl-4"
          >
            Angefragte Cider
          </span>
          <v-chip-group
            v-if="requestedCidersForTable(table).length > 0"
            column
            class="pl-6 overflow-auto"
          >
            <v-chip
              v-for="(cider, index) in requestedCidersForTable(table)"
              :key="cider.id + '_' + cider.flightId + '_' + index"
              color="primary"
              class="justify-start overflow-x-auto"
              small
              @click="resetRequested(cider)"
            >
              #{{ String(cider.hasOwnProperty('displayId') ? cider.displayId : cider.id) + ": " + cider.name + ", " + cider.produzent }}
            </v-chip>
          </v-chip-group>
          <v-expand-transition>
            <div v-show="showFlights[tableIdx]">
              <v-card-text class="pb-0 pt-0">
                <span class="subheading d-flex justify-start">Flights</span>
                <v-chip-group
                  column
                  class="pl-2"
                >
                  <v-menu
                    v-for="(flight, index) in table.flights"
                    :key="flight.id"
                    v-model="flight.value"
                    top
                    offset-y
                    transition="scale-transition"
                    origin="bottom left"
                    class="d-flex justify-start"
                  >
                    <template #activator="{ on }">
                      <v-chip
                        small
                        v-on="on"
                      >
                        <v-icon
                          left
                          small
                        >
                          {{ flightFinished(table, index) === true ? 'mdi-check-decagram' : undefined }}
                        </v-icon>
                        {{ flight.flightName }} ({{ flight.ciders.length }})
                      </v-chip>
                    </template>
                    <FlightMenu
                      :show="false"
                      :table="table"
                      :flight="flight"
                      :index="index"
                      @menuClose="flight.value = !flight.value"
                    />
                    <!--                    <v-card-->
                    <!--                      class="text-left"-->
                    <!--                      @click.native.stop=""-->
                    <!--                    >-->
                    <!--                      <v-list>-->
                    <!--                        <v-list-item>-->
                    <!--                          <v-list-item-content>-->
                    <!--                            <v-list-item-title>{{ flight.flightName }}</v-list-item-title>-->
                    <!--                            <v-list-item-subtitle>-->
                    <!--                              <v-icon-->
                    <!--                                v-if="flightFinished(table, index)"-->
                    <!--                                left-->
                    <!--                                small-->
                    <!--                              >-->
                    <!--                                {{ flightFinished(table, index) === true ? 'mdi-check-decagram' : undefined }}-->
                    <!--                              </v-icon>-->
                    <!--                              Insgesamt {{ flight.ciders.length }} Ciders-->
                    <!--                            </v-list-item-subtitle>-->
                    <!--                          </v-list-item-content>-->
                    <!--                          <v-list-item-action>-->
                    <!--                            <v-btn-->
                    <!--                              icon-->
                    <!--                            >-->
                    <!--                              <v-icon>mdi-close-circle</v-icon>-->
                    <!--                            </v-btn>-->
                    <!--                          </v-list-item-action>-->
                    <!--                        </v-list-item>-->
                    <!--                      </v-list>-->
                    <!--                      <v-divider />-->
                    <!--                      <v-list>-->
                    <!--                        <v-list-item-->
                    <!--                          v-for="cider in getCidersForFlight(table, index)"-->
                    <!--                          :key="cider.id"-->
                    <!--                          @click="() => {}"-->
                    <!--                        >-->
                    <!--                          <v-list-item-avatar-->
                    <!--                            class="elevation-3"-->
                    <!--                          >-->
                    <!--                            <v-btn-->
                    <!--                              v-if="showBadge(getTotalPointsForCider(cider))"-->
                    <!--                              color="transparent"-->
                    <!--                              dark-->
                    <!--                              fab-->
                    <!--                            >-->
                    <!--                              <v-img-->
                    <!--                                max-height="40px"-->
                    <!--                                max-width="40px"-->
                    <!--                                :lazy-src="getBadgeUrl(200, getTotalPointsForCider(cider))"-->
                    <!--                                :src="getBadgeUrl(200, getTotalPointsForCider(cider))"-->
                    <!--                                alt="Badge"-->
                    <!--                              />-->
                    <!--                            </v-btn>-->
                    <!--                          </v-list-item-avatar>-->
                    <!--                          <v-list-item-content>-->
                    <!--                            <v-list-item-title>-->
                    <!--                              {{ cider.name }}-->
                    <!--                            </v-list-item-title>-->
                    <!--                            <v-list-item-subtitle>-->
                    <!--                              <v-icon-->
                    <!--                                small-->
                    <!--                                :color="getCiderStateIconColor(cider)"-->
                    <!--                              >-->
                    <!--                                {{ getCiderStateIcon(cider) }}-->
                    <!--                              </v-icon>-->
                    <!--                              {{ getCiderStateText(cider) }}-->
                    <!--                            </v-list-item-subtitle>-->
                    <!--                          </v-list-item-content>-->
                    <!--                          <v-list-item-action class="d-flex justify-center align-center">-->
                    <!--                            <v-chip-->
                    <!--                              :color="$store.getters['badgesStore/getColor'](getTotalPointsForCider(cider))"-->
                    <!--                              label-->
                    <!--                              small-->
                    <!--                              style="margin:0 auto; min-width: 64px"-->
                    <!--                              class="pl-1 pr-1 justify-center ml-0 mr-0"-->
                    <!--                            >-->
                    <!--                              <v-icon small>-->
                    <!--                                mdi-star-->
                    <!--                              </v-icon>-->
                    <!--                              {{ getTotalPointsForCider(cider) }}-->
                    <!--                            </v-chip>-->
                    <!--                          </v-list-item-action>-->
                    <!--                        </v-list-item>-->
                    <!--                      </v-list>-->
                    <!--                    </v-card>-->
                  </v-menu>
                </v-chip-group>
              </v-card-text>
              <v-card-text class="pb-0 pt-0">
                <span class="subheading d-flex justify-start">Cider</span>
                <v-chip-group
                  column
                  class="pl-2"
                >
                  <template
                    v-for="(cider, index) in table.ciders"
                  >
                    <v-tooltip
                      v-if="index === 0"
                      :key="cider.id + '_' + cider.flightId + '__' + index + 'flight0'"
                      top
                    >
                      <template #activator="{ on }">
                        <v-chip
                          small
                          class="pa-0 pl-2 mr-0"
                        >
                          <v-icon
                            left
                            small
                            class="ma-0"
                            color="primary"
                            v-on="on"
                          >
                            mdi-airplane-takeoff
                          </v-icon>
                          <v-icon
                            left
                            small
                            class="ma-0"
                            color="primary"
                            v-on="on"
                          >
                            mdi-chevron-right
                          </v-icon>
                        </v-chip>
                      </template>
                      <span>{{ table.ciders[0].flightName }}</span>
                    </v-tooltip>
                    <v-tooltip
                      v-if="(index > 0 && index < table.ciders.length && cider.flightId !== table.ciders[index - 1].flightId)"
                      :key="cider.id + '_' + cider.flightId + '__' + index + 'flight'"
                      top
                    >
                      <template #activator="{ on }">
                        <v-chip
                          small
                          class="pa-0 pl-2 mr-0"
                        >
                          <v-icon
                            left
                            small
                            class="ma-0"
                            color="primary"
                            v-on="on"
                          >
                            mdi-airplane-takeoff
                          </v-icon>
                          <v-icon
                            left
                            small
                            class="ma-0"
                            color="primary"
                            v-on="on"
                          >
                            mdi-chevron-right
                          </v-icon>
                        </v-chip>
                      </template>
                      <span>{{ table.ciders[index].flightName }}</span>
                    </v-tooltip>
                    <v-menu
                      :key="cider.id + '_' + cider.flightId + '__' + index"
                      v-model="cider.value"
                      top
                      offset-y
                      transition="scale-transition"
                      origin="bottom left"
                      class="d-flex justify-start"
                      max-width="600px"
                    >
                      <template #activator="{ on }">
                        <v-chip
                          :outlined="getCiderState(cider) === 0"
                          :style="{'background-color': getCiderState(cider) === 0 ? 'var(--v-background-base)':''}"
                          text-color="var(--v-text_std-base)"
                          :color="getChipColor(cider)"
                          small
                          class="ml-1 mr-1"
                          v-on="on"
                        >
                          <v-tooltip top>
                            <template #activator="{ on }">
                              <v-icon
                                left
                                small
                                :color="getCiderStateIconColor(cider)"
                                v-on="on"
                              >
                                {{ getCiderStateIcon(cider) }}
                              </v-icon>
                            </template>
                            <span>{{ getCiderStateText(cider) }}</span>
                          </v-tooltip>

                          #{{ cider.displayId }}: {{ cider.name }} ({{ getTotalPointsForCider(cider) }})
                          <v-img
                            v-if="showBadge(getTotalPointsForCider(cider))"
                            max-height="18px"
                            max-width="18px"
                            class="ml-1"
                            :lazy-src="getBadgeUrl(200, getTotalPointsForCider(cider))"
                            :src="getBadgeUrl(200, getTotalPointsForCider(cider))"
                            alt="Badge"
                          />
                        </v-chip>
                      </template>
                      <CiderMenu
                        :cider="cider"
                        :table="table"
                        :show="false"
                        @menuClose="cider.value = !cider.value"
                      />
                    </v-menu>
                  </template>
                  
                  <!--                  <v-menu-->
                  <!--                    v-for="(cider, index) in table.ciders"-->
                  <!--                    :key="cider.id"-->
                  <!--                    v-model="cider.value"-->
                  <!--                    top-->
                  <!--                    offset-y-->
                  <!--                    transition="scale-transition"-->
                  <!--                    origin="bottom left"-->
                  <!--                    class="d-flex justify-start"-->
                  <!--                    max-width="600px"-->
                  <!--                  >-->
                  <!--                    <template #activator="{ on }">-->
                  <!--                      <v-tooltip-->
                  <!--                        v-if="index === 0 || cider.flightId !== table.ciders[Math.max(index - 1, 0)].flightId"-->
                  <!--                        top-->
                  <!--                      >-->
                  <!--                        <template #activator="{ onIcon }">-->
                  <!--                          <v-icon-->
                  <!--                            left-->
                  <!--                            small-->
                  <!--                            class="ma-0"-->
                  <!--                            :color="getCiderStateIconColor(cider)"-->
                  <!--                            v-on="onIcon"-->
                  <!--                          >-->
                  <!--                            mdi-chevron-right-->
                  <!--                          </v-icon>-->
                  <!--                        </template>-->
                  <!--                        <span>{{ cider.flightName }}</span>-->
                  <!--                      </v-tooltip>-->
                  <!--                      <v-chip-->
                  <!--                        :outlined="getCiderState(cider) === 0"-->
                  <!--                        text-color="rgba(0, 0, 0, 0.87)"-->
                  <!--                        :color="getChipColor(cider)"-->
                  <!--                        small-->
                  <!--                        class="ml-1 mr-1"-->
                  <!--                        v-on="on"-->
                  <!--                      >-->
                  <!--                        <v-tooltip top>-->
                  <!--                          <template #activator="{ onCiderIcon }">-->
                  <!--                            <v-icon-->
                  <!--                              left-->
                  <!--                              small-->
                  <!--                              :color="getCiderStateIconColor(cider)"-->
                  <!--                              v-on="onCiderIcon"-->
                  <!--                            >-->
                  <!--                              {{ getCiderStateIcon(cider) }}-->
                  <!--                            </v-icon>-->
                  <!--                          </template>-->
                  <!--                          <span>{{ getCiderStateText(cider) }}</span>-->
                  <!--                        </v-tooltip>-->

                  <!--                        #{{ cider.displayId }}: {{ cider.name }} ({{ getTotalPointsForCider(cider) }})-->
                  <!--                        <v-img-->
                  <!--                          v-if="showBadge(getTotalPointsForCider(cider))"-->
                  <!--                          max-height="18px"-->
                  <!--                          max-width="18px"-->
                  <!--                          class="ml-1"-->
                  <!--                          :lazy-src="getBadgeUrl(200, getTotalPointsForCider(cider))"-->
                  <!--                          :src="getBadgeUrl(200, getTotalPointsForCider(cider))"-->
                  <!--                          alt="Badge"-->
                  <!--                        />-->
                  <!--                      </v-chip>-->
                  <!--                    </template>-->
                  <!--                    <v-card-->
                  <!--                      class="text-left"-->
                  <!--                      @click.native.stop=""-->
                  <!--                    >-->
                  <!--                      <v-list>-->
                  <!--                        <v-list-item>-->
                  <!--                          <v-list-item-content>-->
                  <!--                            <v-list-item-title>{{ cider.name }}</v-list-item-title>-->
                  <!--                            <v-list-item-subtitle class="d-flex align-center">-->
                  <!--                              <div class="d-flex align-center">-->
                  <!--                                <span>{{ cider.land.name }}</span>-->
                  <!--                                <country-flag-->
                  <!--                                  class="elevation-2"-->
                  <!--                                  :country="cider.land.code"-->
                  <!--                                  size="normal"-->
                  <!--                                />-->
                  <!--                              </div>-->
                  <!--                            </v-list-item-subtitle>-->
                  <!--                            <v-list-item-subtitle>-->
                  <!--                              {{ cider.produzent }}-->
                  <!--                            </v-list-item-subtitle>-->
                  <!--                          </v-list-item-content>-->
                  <!--                          <v-list-item-action>-->
                  <!--                            <v-btn-->
                  <!--                              icon-->
                  <!--                              @click="cider.value = false"-->
                  <!--                              @click.native.stop="cider.value = false"-->
                  <!--                            >-->
                  <!--                              <v-icon>mdi-close-circle</v-icon>-->
                  <!--                            </v-btn>-->
                  <!--                          </v-list-item-action>-->
                  <!--                        </v-list-item>-->
                  <!--                      </v-list>-->
                  <!--                      <v-divider />-->
                  <!--                      <v-list>-->
                  <!--                        <v-list-item-->
                  <!--                          v-for="juror in table.juroren"-->
                  <!--                          :key="juror.id"-->
                  <!--                        >-->
                  <!--                          <v-list-item-avatar style="overflow: unset">-->
                  <!--                            <v-badge-->
                  <!--                              avatar-->
                  <!--                              overlap-->
                  <!--                              label=""-->
                  <!--                              style="z-index: 9"-->
                  <!--                              :color="showBadge(cider.allPoints[juror.id]) ? 'white' : 'transparent'"-->
                  <!--                            >-->
                  <!--                              <template #badge>-->
                  <!--                                <v-avatar-->
                  <!--                                  v-if="showBadge(cider.allPoints[juror.id])"-->
                  <!--                                  size="56"-->
                  <!--                                  class="elevation-5"-->
                  <!--                                >-->
                  <!--                                  <v-img :src="getBadgeUrl(100, cider.allPoints[juror.id])" />-->
                  <!--                                </v-avatar>-->
                  <!--                                &lt;!&ndash;                                <v-img&ndash;&gt;-->
                  <!--                                &lt;!&ndash;                                  v-if="showBadge(cider.allPoints[juror.id])"&ndash;&gt;-->
                  <!--                                &lt;!&ndash;                                  width="20"&ndash;&gt;-->
                  <!--                                &lt;!&ndash;                                  height="20"&ndash;&gt;-->
                  <!--                                &lt;!&ndash;                                  :src="getBadgeUrl(100, cider.allPoints[juror.id])"&ndash;&gt;-->
                  <!--                                &lt;!&ndash;                                />&ndash;&gt;-->
                  <!--                              </template>-->
                  <!--                              <v-avatar-->
                  <!--                                size="40"-->
                  <!--                              >-->
                  <!--                                <v-img-->
                  <!--                                  alt="Avatar"-->
                  <!--                                  :src="getUserAvatar(200, juror)"-->
                  <!--                                />-->
                  <!--                              </v-avatar>-->
                  <!--                            </v-badge>-->
                  <!--                          </v-list-item-avatar>-->
                  <!--                          <v-list-item-content>-->
                  <!--                            <v-list-item-title>-->
                  <!--                              {{ juror.firstName }} {{ juror.lastName }}-->
                  <!--                              <v-icon-->
                  <!--                                v-if="cider.allNotes[juror.id].isFavourite"-->
                  <!--                                small-->
                  <!--                              >-->
                  <!--                                mdi-heart-->
                  <!--                              </v-icon>-->
                  <!--                              <v-icon-->
                  <!--                                v-if="cider.allNotes[juror.id].isPinned"-->
                  <!--                                small-->
                  <!--                              >-->
                  <!--                                mdi-bookmark-->
                  <!--                              </v-icon>-->
                  <!--                            </v-list-item-title>-->
                  <!--                            <v-list-item-subtitle>-->
                  <!--                              <v-tooltip top>-->
                  <!--                                <template #activator="{ on }">-->
                  <!--                                  <span v-on="on">{{ cider.allNotes[juror.id].kommentar }}</span>-->
                  <!--                                </template>-->
                  <!--                                <div-->
                  <!--                                  style="white-space: pre; text-align: left;"-->
                  <!--                                  v-html="cider.allNotes[juror.id].kommentar"-->
                  <!--                                />-->
                  <!--                              </v-tooltip>-->
                  <!--                            </v-list-item-subtitle>-->
                  <!--                          </v-list-item-content>-->
                  <!--                          <v-list-item-action class="d-flex justify-center align-center">-->
                  <!--                            <v-chip-->
                  <!--                              :color="$store.getters['badgesStore/getColor'](cider.allPoints[juror.id])"-->
                  <!--                              :outlined="!showBadge(cider.allPoints[juror.id])"-->
                  <!--                              label-->
                  <!--                              small-->
                  <!--                              style="margin:0 auto; min-width: 64px"-->
                  <!--                              class="pl-1 pr-1 justify-center ml-0 mr-0"-->
                  <!--                            >-->
                  <!--                              <v-icon small>-->
                  <!--                                mdi-star-->
                  <!--                              </v-icon>-->
                  <!--                              {{ cider.allPoints[juror.id] }}-->
                  <!--                            </v-chip>-->
                  <!--                          </v-list-item-action>-->
                  <!--                        </v-list-item>-->
                  <!--                      </v-list>-->
                  <!--                    </v-card>-->
                  <!--                  </v-menu>-->
                </v-chip-group>
              </v-card-text>
            </div>
          </v-expand-transition>
          <v-divider />
          <v-list class="pt-1 pb-1">
            <template v-for="(juror, index) in table.juroren">
              <v-list-item :key="juror.id">
                <v-list-item-avatar
                  :size="showFlights[tableIdx] ? 40 : 30"
                >
                  <img
                    :src="getUserAvatar(200, juror)"
                    alt="Avatar"
                  >
                </v-list-item-avatar>
                <v-list-item-content class="text-left d-flex align-center pt-1 pb-1">
                  <v-list-item-title>
                    <v-tooltip
                      top
                    >
                      <template #activator="{ on }">
                        <span
                          :ref="'juror_' + juror.id"
                          v-on="on"
                        >{{ juror.firstName }} {{ juror.lastName }}</span>
                      </template>
                      <span>{{ juror.firstName }} {{ juror.lastName }}</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <v-expand-transition>
                    <div v-show="showFlights[tableIdx]">
                      <v-list-item-subtitle>
                        <v-chip-group
                          column
                        >
                          <v-tooltip top>
                            <template #activator="{ on }">
                              <v-chip
                                label
                                outlined
                                small
                                v-on="on"
                              >
                                <v-img
                                  v-if="showBadge(getAvgCiderPointsForJuror(juror))"
                                  max-height="18px"
                                  max-width="18px"
                                  class="mr-1"
                                  :lazy-src="getBadgeUrl(200, getAvgCiderPointsForJuror(juror))"
                                  :src="getBadgeUrl(200, getAvgCiderPointsForJuror(juror))"
                                  alt="Badge"
                                />
                                {{ getAvgCiderPointsForJuror(juror) }}
                              </v-chip>
                            </template>
                            <span>Average Total Points</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template #activator="{ on }">
                              <v-chip
                                label
                                outlined
                                small
                                v-on="on"
                              >
                                <v-icon small>
                                  mdi-star
                                </v-icon>
                                {{ getAvgPointsForJuror(juror) }}
                              </v-chip>
                            </template>
                            <span>Average Individual Points</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template #activator="{ on }">
                              <v-chip
                                label
                                outlined
                                small
                                v-on="on"
                              >
                                <v-icon small>
                                  mdi-clock-outline
                                </v-icon>
                                {{ getAvgRatingTimeForJuror(juror) }} m
                              </v-chip>
                            </template>
                            <span>Average Rating Time</span>
                          </v-tooltip>
                        </v-chip-group>
                      </v-list-item-subtitle>
                    </div>
                  </v-expand-transition>
                </v-list-item-content>
                <v-list-item-action class="mt-0 mb-0 d-flex align-center">
                  <v-list-item-icon
                    class="ma-0 d-flex align-center"
                    style="gap: 4px"
                  >
                    <v-chip
                      v-if="showTischfunktion(getTischfunktionByJuror(juror, table.tischfunktionen))"
                      outlined
                      @click="setUserTischfunktionen('(alle)', juror, table)"
                    >
                      <v-tooltip
                        v-if="getTischfunktionByJuror(juror, table.tischfunktionen, 'melden') > 0"
                        top
                      >
                        <template #activator="{ on }">
                          <v-icon
                            color="grey"
                            v-on="on"
                            @click="setUserTischfunktionen('melden', juror, table)"
                            @click.native.stop=""
                          >
                            mdi-hand-back-left
                          </v-icon>
                        </template>
                        <span>
                          Vor {{ getTimeDiffTextForTischfunktion(getTischfunktionByJuror(juror, table.tischfunktionen)) }}
                        </span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="getTischfunktionByJuror(juror, table.tischfunktionen, 'nextCiderId') !== null"
                        top
                      >
                        <template #activator="{ on }">
                          <v-icon
                            color="grey"
                            v-on="on"
                            @click="setUserTischfunktionen('next_cider_id', juror, table)"
                            @click.native.stop=""
                          >
                            mdi-glass-wine
                          </v-icon>
                        </template>
                        <span>
                          Vor {{ getTimeDiffTextForTischfunktion(getTischfunktionByJuror(juror, table.tischfunktionen)) }}
                        </span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="getTischfunktionByJuror(juror, table.tischfunktionen, 'frage') > 0"
                        top
                      >
                        <template #activator="{ on }">
                          <v-icon
                            color="grey"
                            v-on="on"
                            @click="setUserTischfunktionen('frage', juror, table)"
                            @click.native.stop=""
                          >
                            mdi-help-circle
                          </v-icon>
                        </template>
                        <span>
                          Vor {{ getTimeDiffTextForTischfunktion(getTischfunktionByJuror(juror, table.tischfunktionen)) }}
                        </span>
                      </v-tooltip>
                    </v-chip>
                  </v-list-item-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="showFlights[tableIdx]"
                :key="(juror.id + String(index))"
                :inset="index < table.juroren.length - 1"
              />
            </template>
            <v-expand-transition>
              <div v-show="true || showFlights[tableIdx]">
                <v-list-item>
                  <v-list-item-avatar
                    style="border-style: solid; border-width: 1px; border-color: var(--v-divider-base);"
                    :size="showFlights[tableIdx] ? 40 : 30"
                  >
                    <v-icon size="28">
                      mdi-account-group
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="text-left d-flex align-center pt-1 pb-1">
                    <v-list-item-title v-show="showFlights[tableIdx]">
                      Gesamter Tisch
                    </v-list-item-title>
                    <v-expand-transition>
                      <div v-show="true || showFlights[tableIdx]">
                        <v-list-item-subtitle>
                          <v-chip-group
                            column
                          >
                            <v-tooltip top>
                              <template #activator="{ on }">
                                <v-chip
                                  small
                                  v-on="on"
                                >
                                  <v-img
                                    v-if="showBadge(table.averageCiderPoints)"
                                    max-height="18px"
                                    max-width="18px"
                                    class="mr-1"
                                    :lazy-src="getBadgeUrl(200, table.averageCiderPoints)"
                                    :src="getBadgeUrl(200, table.averageCiderPoints)"
                                    alt="Badge"
                                  />
                                  {{ table.averageCiderPoints }}
                                </v-chip>
                              </template>
                              <span>Average Total Points</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template #activator="{ on }">
                                <v-chip
                                  small
                                  v-on="on"
                                >
                                  <v-icon small>
                                    mdi-star
                                  </v-icon>
                                  {{ table.averageRatingPoints }}
                                </v-chip>
                              </template>
                              <span>Average Rating Points</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template #activator="{ on }">
                                <v-chip
                                  small
                                  v-on="on"
                                >
                                  <v-icon small>
                                    mdi-clock-outline
                                  </v-icon>
                                  {{ table.averageRatingTime }} m
                                </v-chip>
                              </template>
                              <span>Average Rating Time</span>
                            </v-tooltip>
                          </v-chip-group>
                        </v-list-item-subtitle>
                      </div>
                    </v-expand-transition>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-expand-transition>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import {newTischfunktion} from "@/types/tischfunktion";
import {apiTyped} from "@/api";
import store from "@/stores";
import {setIntervalAsync} from "set-interval-async/fixed";
import {clearIntervalAsync} from "set-interval-async";
import user_mixin from "@/components/mixins/user_mixin";
import CiderRatingState from "@/types/ciderRatingState";
import cider_mixin from "@/components/mixins/cider_mixin";
import util from "@/util";
import ConfirmDialog from "@/components/confirm_dialog"
import {sync} from "vuex-pathify";
import CiderMenu from "@/components/admin/menu/cider_menu";
import FlightMenu from "@/components/admin/menu/flight_menu";
import JurorCider from "@/types/jurorCider";
import admin_mixin from "@/components/mixins/admin_mixin";

export default {
  name: "AdminTableCard",
  components: {FlightMenu, CiderMenu, ConfirmDialog},
  mixins: [admin_mixin, user_mixin, cider_mixin],
  props: {
    // tasting: {type: Object, required: true, default: () => null},
    // tables: {type: Array, required: true, default: () => []},
    // kategorien: {type: Array, required: true, default: () => []},
  },
  data: () => ({
    intervalUpdateData: null,
    masonryAdminTables: null,
    allTischfunktionen: [],
    allBewertungen: [],
    allCiders: [],
    allUserNotes: [],
    loaded: false,
    showFlights: [],
    cidersByTable: null,
    notesForJuror: null,
    bewertungenForJuror: null,
    allPointsForJuror: null,
    tischfunctionMap: {
      'melden': "Produktfrage",
      'frage': "App Support",
      '(alle)': "alle",
      'next_cider_id': "Nächstes Produkt",
    }
  }),
  watch: {
    // showFlights: function () {
    //   this.$nextTick(() => {
    //     this.$redrawVueMasonry('masonryAdminTables');
    //   });
    // }
  },
  computed: {
    tables: sync("tableStore/tables"),
    tastings: sync("usersStore/tastings"),
    kategorien: sync("kategorienStore/kategorieData"),
    // currentTastingIdx() {
    //   return this.$store.getters["usersStore/getTastingIdxByTastingId"](Number(this.$route.query.tasting));
    // },
    // tasting: sync('usersStore/tastings@[:currentTastingIdx]'),
    tasting() {
      return this.$store.getters["usersStore/currentTasting"]();
    },
    requestedCiders() {
      return this.allCiders.filter(cider => cider.requestCider
        && this.allTischfunktionen.find(t => t.nextCiderId === cider.id) === undefined);
    },
    // allNextCiders() {
    //   return this.allTischfunktionen.map(tischfunktion => tischfunktion.nextCiderId);
    // },
  },
  mounted() {
    this.$nextTick(() => {
      this.$redrawVueMasonry('masonryAdminTables');
    });
  },
  async created() {
    console.log("Tables:", this.tables);
    // await this.updateData();
    await this.getAllCidersForTable();
    await this.getAllTischfunktionen();
    this.intervalUpdateData = setIntervalAsync(this.updateData, 10000);
    this.loaded = true;
    this.showFlights = this.tables.map(_ => true);
    // this.tables.forEach(table => showFlights[tableIdx] = true);
    window.setTimeout(() => this.$redrawVueMasonry('masonryAdminTables'), 500);
  },
  async beforeDestroy() {
    if (this.intervalUpdateData != null) await clearIntervalAsync(this.intervalUpdateData);
  },
  updated() {
    this.$redrawVueMasonry('masonryAdminTables')
  },
  methods: {
    async updateData(getAllCiders = false) {
      this.$store.state.pageLoading = true;
      if (getAllCiders) {
        await this.getAllCidersForTable();
      }
      await this.getAllTischfunktionen();
      this.$store.state.pageLoading = false;
    },
    tableChair(table) {
      return store.getters["tableStore/getChairFromTable"](table);
    },
    async changeShowFlights() {
      this.showFlights.find(show => show === true) === undefined
        ? this.showFlights = this.showFlights.map(show => true)
        : this.showFlights = this.showFlights.map(show => false);
      for (const i in [1,2]) {
        window.setTimeout(() => {
          this.$redrawVueMasonry('masonryAdminTables');
        }, 600);
        await new Promise(resolve => setTimeout(resolve, 100));
      }
    },
    async getAllCidersForTable() {
      // // Get all Ciders for Tables:
      // const ciderPromises = [];
      // for (const table of this.tables) {
      //   ciderPromises.push(apiTyped.getCidersByTable(table.id,
      //       ["id", "display_id", "name", "kategorie", "land.*", "produzent.name"],
      //       this.kategorien));
      // }
      // const allCiderPromises = Promise.all(ciderPromises);
      // const allCiders = await allCiderPromises;
      // // Get all Notes for all Ciders:
      // const allChairNotesPromise = apiTyped.getChairNotesForCider(allCiders.flat(), false);
      // const allUserNotesPromise = apiTyped.getAllNotesForCiders(
      //     allCiders.flat(), this.tables.flatMap(table => table.juroren).map(user => user.id));
      // const allBewertungenPromise = apiTyped.getBewertungen(
      //     allCiders.flat(), this.tables.flatMap(table => table.juroren.flatMap(juror => juror.id)));
      // // Await Notes und Bewertungen
      // const allChairNotes = await allChairNotesPromise;
      // this.allBewertungen = await allBewertungenPromise;
      // this.allUserNotes = await allUserNotesPromise;
      // // Add all Bewertungen to all Ciders
      // await addAllBewertungenToCiders(allCiders.flat(), this.allBewertungen);
      // for (const [index, table] of this.tables.entries()) {
      //   // Add Ciders to Tables:
      //   table.ciders = allCiders[index];
      //   // Add notes to Ciders:
      //   for (const cider of table.ciders) {
      //     const chairNote = allChairNotes.find(chairNote => chairNote.ciderId === cider.id);
      //     if (chairNote !== undefined) cider.chairNotes = chairNote;
      //   }
      //   console.log("Got All Ciders for Table:", table);
      // }
      // for (const cider of allCiders.flat()) {
      //   await addAllNotesForCider(cider, [], this.allUserNotes);
      // }

      // const ciderIdsByTable = this.tables.map(table => table.flights.flatMap(f => f.ciders.flatMap(c => c.id)));
      // const cidersPromises = ciderIdsByTable.map(ciderIds => apiTyped.serviceGetCiders(ciderIds));
      // this.cidersByTable = await Promise.all(cidersPromises);

      // this.cidersByTable = [];
      // for (const table of this.tables) {
      //   const flightsCidersIds = this.$store.getters["tableStore/getFlightsCidersIds"](table);
      //   const flightsCiders = await apiTyped.getFlightsCiders(flightsCidersIds, true, true);
      //   flightsCiders.forEach(f => f.flights_id = table.flights.find(f_ => f_.id === f.flights_id));
      //   const ciders = flightsCiders.map(
      //     f => JurorCider.fromApiData(
      //       f.ciders_id,
      //       this.kategorien.find(k => Number(k.id) === Number(f.ciders_id.kategorie)),
      //       f, this.$store.state.usersStore.me.id,
      //       false,
      //       []
      //     )
      //   );
      //   this.cidersByTable.push(ciders);
      // }

      this.cidersByTable = await this.getCidersForTables(50);

      // for (let i=0; i<ciderIdsByTable.length; i++) {
      //   this.cidersByTable[i] = this.cidersByTable[i].sort(
      //     (a,b) => ciderIdsByTable[i].indexOf(a.flightsCidersId) - ciderIdsByTable[i].indexOf(b.flightsCidersId))
      // }
      // const resp = await apiTyped.getCidersPreparedByTable(this.tables, this.kategorien);
      // if (resp === undefined) return;
      // const [allCiders, , allBewertungen, allUserNotes] = resp;
      // for (const cider of allCiders.flat()) {
      //   cider.allPoints = {};
      //   // Set precomputed total points for juroren:
      //   if (cider.allBewertungen === undefined || cider.allBewertungen === null) {
      //     cider.allPoints = undefined;
      //   } else {
      //     for (const jurorId of Object.keys(cider.allBewertungen)) {
      //       cider.allPoints[jurorId] =
      //         this.$store.getters['ciderStore/getPointsFromBewertungen'](cider.allBewertungen[jurorId])
      //     }
      //   }
      // }

      // Set next Ciders:
      // for (const table of this.tables) {
      //   table.nextCider = this.$store.getters["ciderStore/getNextCider"](table.ciders) || null;
      // }
      for (let i = 0; i < this.tables.length; i++) {
        this.tables[i].nextCider = this.$store.getters["ciderStore/getNextCider"](this.cidersByTable[i]) || null;
        this.tables[i].ciders = this.cidersByTable[i];
      }

      // const oldFlightValues = this.tables.flatMap(table => table.flights.map(flight => flight.value || false));
      // // Restore open values
      // let sumFlights = 0;
      // this.tables.forEach(table => {
      //   table.flights.flatMap((flight, fIndex) =>
      //       flight.value = oldFlightValues.length >= (fIndex + sumFlights) ? oldFlightValues[fIndex + sumFlights] : false);
      //   sumFlights += table.flights.length || 0;
      // }, 0);
      // set prop for v-menu:
      const oldCiderValues = this.cidersByTable.flat().map(cider => cider.value || false);
      // allCiders.forEach((cider, index) => cider.value = oldCiderValues.length >= index ? oldCiderValues[index] : false);
      // this.allCiders = allCiders.flat();
      this.allCiders = this.cidersByTable.flat()
      this.allCiders.forEach((cider, index) => {
        cider.value = oldCiderValues.length >= index ? oldCiderValues[index] : false;
        cider.totalPoints = cider.points;
        cider.badgesData = this.$store.getters['badgesStore/getBadgeData'](cider.totalPoints);
      });
      this.notesForJuror = {};
      this.bewertungenForJuror = {};
      this.allPointsForJuror = {};
      for (const juror of this.tables.flatMap(t => t.juroren)) {
        this.notesForJuror[juror.id] = [];
        this.bewertungenForJuror[juror.id] = [];
        this.allPointsForJuror[juror.id] = [];
      }
      for (const cider of this.allCiders) {
        for (const [jurorId, note] of Object.entries(cider.allNotes)) {
          if (!this.notesForJuror.hasOwnProperty(jurorId)) this.notesForJuror[jurorId] = [];
          this.notesForJuror[jurorId].push(note);
        }
        for (const [jurorId, bewertungen] of Object.entries(cider.allBewertungen)) {
          if (bewertungen == null) continue;
          if (!this.bewertungenForJuror.hasOwnProperty(jurorId)) this.bewertungenForJuror[jurorId] = [];
          if (!this.allPointsForJuror.hasOwnProperty(jurorId)) this.allPointsForJuror[jurorId] = [];
          this.bewertungenForJuror[jurorId].push(...bewertungen);
          this.allPointsForJuror[jurorId].push(
            this.$store.getters["ciderStore/getPointsForJuror"](cider, jurorId));
        }
        // for (const [jurorId, points] of Object.entries(cider.allPoints)) {
        //   this.allPointsForJuror[jurorId].push(points);
        // }
      }
      // this.allBewertungen = allBewertungen;
      // this.allUserNotes = allUserNotes;
      this.calculateTableStats();
    },
    async getAllTischfunktionen() {
      const newTischfunktionen = await apiTyped.getTischfunktionen(
          this.tables.map(table => table.id), this.tables.flatMap(table => table.juroren.flatMap(juror => juror.id)));
      this.allTischfunktionen = newTischfunktionen;
      for (const table of this.tables) {
        const tableTischfunktionen = newTischfunktionen.filter(
            tischfunktion => table.id === tischfunktion.tableId);
        if (tableTischfunktionen === undefined) continue;
        Vue.set(table, "tischfunktionen", tableTischfunktionen);
      }
    },
    async setUserTischfunktionen(attribute, juror, table) {
      const resp = await this.$refs.confirm.open('User Tischfunktionen ändern',
          `Soll die Tischfunktion '${this.tischfunctionMap[attribute]}' des Jurors ${juror.firstName} ${juror.lastName} zurückgesetzt werden?`,
          { color: 'secondary_card', thirdButton: true, thirdButtonText: "Alle" })
      if (resp !== false) {
        const tischfunktion = this.getTischfunktionByJuror(juror, table.tischfunktionen);
        let newTischfunktion = {};
        if (resp === "third") {
          const payload = {melden: 0, frage: 0, next_cider_id: null};
          newTischfunktion = await apiTyped.updateTischfunktionen(tischfunktion.id, payload);
        }
        else {
          const payload = {};
          payload[attribute] = attribute === "next_cider_id" ? null : 0;
          newTischfunktion = await apiTyped.updateTischfunktionen(tischfunktion.id, payload);
        }
        // Set new:
        const tischfunktionIdx = table.tischfunktionen.findIndex(
            tischfunktion => tischfunktion.id === newTischfunktion.id);
        console.log("Setting new t",tischfunktionIdx, newTischfunktion)
        if (tischfunktionIdx >= 0)
          Vue.set(table.tischfunktionen, Number(tischfunktionIdx), newTischfunktion);
      }
    },
    calculateTableStats() {
      for (const table of this.tables) {
        const averageRatingPoints = table.juroren.map(juror => this.getAvgPointsForJuror(juror)).filter(t => !isNaN(t));
        table.averageRatingPoints = util.util.round_to_decimal(averageRatingPoints.reduce(
          (partialSum, a) => !isNaN(a) ? partialSum + a : partialSum, 0) / averageRatingPoints.length, 0.1);
        const averageCiderPoints = table.juroren.map(juror => this.getAvgCiderPointsForJuror(juror)).filter(
          t => !isNaN(t));
        table.averageCiderPoints = util.util.round_to_decimal(averageCiderPoints.reduce(
              (partialSum, a) => !isNaN(a) ? partialSum + a : partialSum, 0) / averageCiderPoints.length, 0.1);
        const averageRatingTime = table.juroren.map(juror => this.getAvgRatingTimeForJuror(juror)).filter(
          t => !isNaN(t));
        table.averageRatingTime = util.util.round_to_decimal(
            averageRatingTime.reduce((partialSum, a) => partialSum + a, 0) / averageRatingTime.length, 0.1);
      }
    },
    async resetRequested(cider) {
      if (await this.$refs.confirm.open('Angefragten Cider zurücksetzen',
          `Soll die Anfrage des Ciders ${cider.name} zurückgesetzt werden?`,
          { color: 'secondary_card'}) === false) return
      const newRequestCider = await apiTyped.updateFlightsCidersRequest(cider.flightsCidersId, false);
      cider.requestCider = newRequestCider || true;
      const ciderIdx = this.allCiders.findIndex(ciderInList => ciderInList.flightsCidersId === cider.flightsCidersId);
      console.log("ciderIdxciderIdx", ciderIdx)
      Vue.set(this.allCiders, ciderIdx, cider);
    },
    getCidersForFlight(table, flightIdx) {
      if (table.ciders === undefined) return [];
      return table.ciders.filter(cider => cider.flightId === table.flights[flightIdx].id);
    },
    flightFinished(table, flightIdx) {
      const ciders = this.getCidersForFlight(table, flightIdx);
      return ciders.every(cider =>
          this.$store.getters["ciderStore/getCiderRatingState"](cider) === CiderRatingState.finishedByChair);
    },
    requestedCidersForTable(table) {
      if (table.ciders == null) return []
      const tableCiderIds = table.ciders.map(c => c.flightsCidersId);
      return this.requestedCiders.filter(c => tableCiderIds.includes(c.flightsCidersId));
    },
    showTischfunktion(tischfunktion) {
      if (tischfunktion === null) return false;
      const show = tischfunktion.melden > 0 || tischfunktion.nextCiderId !== null || tischfunktion.frage > 0;
      return show;
    },
    getTischfunktionByJuror(juror, tischfunktionen, attribute = null) {
      if (tischfunktionen === undefined) return null
      const tischfunktion = tischfunktionen.find(tischfunktion => tischfunktion.userId === juror.id);
      if (tischfunktion === undefined) return null;
      if (attribute === null) return tischfunktion;
      return tischfunktion[attribute];
    },
    getJurorById(jurorId, table, attribute = null) {
      const juror = table.juroren.find(juror => juror.id === jurorId);
      if (juror === undefined) return null;
      if (attribute === null) return juror;
      return juror[attribute];
    },
    getTotalPointsForCider(cider) {
      return this.$store.getters["ciderStore/getTotalPointsForCider"](cider);
    },
    getAvgCiderPointsForJuror(juror) {
      // const bewertungen = this.allBewertungen.filter(bewertung => bewertung.jurorId === juror.id);
      // const bewertungenNotZero = bewertungen.filter(bewertung => bewertung.punkte !== null);
      const pointsForJuror = this.allPointsForJuror.hasOwnProperty(juror.id)
        ? this.allPointsForJuror[juror.id]
        : [];
      return util.util.round_to_decimal(pointsForJuror.reduce(
        (partialSum, b) => b + partialSum, 0) / pointsForJuror.filter(p => p != null && !isNaN(p)).length, 0.1);
      // return util.util.round_to_decimal(
      //     this.$store.getters["ciderStore/getPointsFromBewertungen"](
      //       bewertungenNotZero, this.kategorien) / bewertungenNotZero.length, 0.1);
    },
    getAvgRatingTimeForJuror(juror) {
      const jurorFinishedNotes = (this.notesForJuror.hasOwnProperty(juror.id) ? this.notesForJuror[juror.id] : []) || [];
      // const jurorFinishedNotes = this.allUserNotes.filter(userNote => userNote.juror_id === juror.id).filter(note =>
      //   note.finished === true);
      // const jurorFinishedNotes = this.allCiders.filter(c => c.allNotes.hasOwnProperty(juror.id)).flat()
      let avgTimeS = 0;
      jurorFinishedNotes.forEach(note => {
        if (note.finishedAt != null && note.startedAt != null && note.startedAt < note.finishedAt) {
          const time_ = (Date.parse(note.finishedAt) - Date.parse(note.startedAt)) / 1000;
          avgTimeS += time_;
        }
      });
      return util.util.round_to_decimal(avgTimeS / 60 / jurorFinishedNotes.length, 0.1);
    },
    getAvgPointsForJuror(juror) {
      // const ciders = this.allCiders.filter(cider =>
      //   cider.allBewertungen !== undefined ? Object.keys(cider.allBewertungen).includes(juror.id) : false);
      // let sum = 0;
      // let len = 0;
      // ciders.forEach(cider => {
      //   const bewertungen = cider.allBewertungen[juror.id];
      //   if (bewertungen.find(bewertung => bewertung.punkte === null)) sum += 0;  // dont add up not finished ciders
      //   else {
      //     sum += this.$store.getters["ciderStore/getPointsFromBewertungen"](bewertungen);
      //     len += 1;
      //   }
      // });
      const bewertungen = this.bewertungenForJuror.hasOwnProperty(juror.id)
        ? this.bewertungenForJuror[juror.id]
        : [];
      const bewertungenNotNull = bewertungen.filter(b => b.punkte != null);
      const sum = bewertungenNotNull.reduce((sum, bewertung) => sum + bewertung.punkte, 0);
      return util.util.round_to_decimal(sum / bewertungenNotNull.length, 0.1);
    },
    getTischfunktionenSummary(tischfunktionen) {
      const tischfunktionSummary = newTischfunktion();
      if (tischfunktionen === undefined) return tischfunktionSummary;
      for (const tischfunktion of tischfunktionen) {
        if (tischfunktion.melden > tischfunktionSummary.melden) tischfunktionSummary.melden = tischfunktion.melden;
        if (tischfunktion.frage > tischfunktionSummary.frage) tischfunktionSummary.frage = tischfunktion.frage;
        if (tischfunktion.nextCiderId > tischfunktionSummary.nextCiderId)
          tischfunktionSummary.nextCiderId = tischfunktion.nextCiderId;
        if (tischfunktionSummary.dateUpdated === null ||
            Date.parse(tischfunktion.dateUpdated) > Date.parse(tischfunktionSummary.dateUpdated))
          tischfunktionSummary.dateUpdated = tischfunktion.dateUpdated;
      }
      // console.log("Tischfunktionen Summary", tischfunktionSummary)
      return tischfunktionSummary;
    },
    getTimeDiffTextForTischfunktion(tischfunktion) {
      const timeDiff = (Date.now() - Date.parse(tischfunktion.dateUpdated)) / 1000;  // time in s
      if(isNaN(timeDiff)) return 0;
      if (timeDiff < 60)
        return String(Math.floor(timeDiff)) + " s";
      if (timeDiff < 3600)
        return String(Math.floor(timeDiff / 60)) + " m";
      return String(Math.floor(timeDiff / 3600)) + " h";
    },
    async updateShowFlights(tableIdx) {
      Vue.set(this.showFlights, tableIdx, !this.showFlights[tableIdx]);
      // this.$store.state.tableStore.tables[tableIdx].showMoreInfo = !this.tables[tableIdx].showMoreInfo;
      // this.tables[tableIdx].showMoreInfo = !this.tables[tableIdx].showMoreInfo;
      // this.$store.set("tableStore/tables@tableIdx@showMoreInfo", !this.tables[tableIdx].showMoreInfo);
      // Vue.set(table, "showMoreInfo", !showFlights[tableIdx])
      // showFlights[tableIdx] = !showFlights[tableIdx];
      // this.showFlights = this.showFlights.find(show => true) !== undefined
      //     ? this.tables.map(table => false)
      //     : this.tables.map(table => true)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const i in [1,2,3,4,5]) {
        window.setTimeout(() => {
          this.$redrawVueMasonry('masonryAdminTables');
        }, 100);
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      this.$redrawVueMasonry("masonryAdminTables");
    },
    toggleFlightPanel(tableId, flightId, value = false) {
      const flightIdx = this.tables[tableId].flights.findIndex(flight => flight.id === flightId);
      const table = this.tables[flightIdx];
      const flight = table.flights[flightIdx];
      flight.value = value;
      console.log("sett", table)
      Vue.set(this.tables, tableId, table);
    },
    isOverflowing(ref) {
      const element = this.$refs[ref];
      return element !== undefined ? element.offsetWidth < element.scrollWidth : false;
    }
  },
}
</script>

<style >
.rotate_180 {
  -moz-transform:rotate(180deg);
  -webkit-transform:rotate(180deg);
  transform:rotate(180deg);
}

.animate_change {
  transition:all 0.25s ease;
}

</style>