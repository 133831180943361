import {apiTyped} from "@/api";

export default {
    data: () => ({
        code: [38, 38, 40, 40, 37, 39, 37, 39, 66, 65, 13],
        buffer: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        index: 0,
        codeSuccess: false,
    }),
    watch: {
        codeSuccess: async function(val) {
            this.buffer = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            this.index = 0;
            if (val === false) return;
            if (this.$refs.easterEggModal == null) return;
            const easterEggShowoff = await this.$refs.easterEggModal.open(this.$store.getters["usersStore/user"]())
            const resp =
              await apiTyped.updateUserSettings({ "easter_egg_active": easterEggShowoff });
            this.$store.getters["usersStore/user"]().userSettings.easterEggActive = resp.easter_egg_active;
        }
    },
    methods: {
        _keyListener(e) {
            this.buffer[this.index] = e.keyCode;
            this.index += 1;
            if (this.index >= this.code.length) this.index = 0;
            if (e.keyCode === 13) this.checkCode();
        },
        checkCode() {
            let start = this.index;
            for (let i = 0; i < this.buffer.length; i++) {
                let pos2 = i + start;
                if (i + start >= this.code.length) pos2 -= this.buffer.length;
                if (this.code[i] !== this.buffer[pos2]){
                    this.codeSuccess = false;
                    break;
                }
                this.codeSuccess = false;
                this.codeSuccess = true;
            }
        }
    },
}