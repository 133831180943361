<template>
  <v-container
    fluid
    class="mt-0 pa-0"
    style="max-width: 100%; height: 100vh !important;"
  >
    <v-row
      align="center"
      no-gutters
      style="height: 100vh;"
      class="d-flex flex-row flex-nowrap"
    >
      <v-col
        style="height: 100%; min-width: min(600px, 100%) !important; overflow: auto !important"
        class="d-flex flex-column pb-0 pa-4"
      >
        <div
          class="d-flex flex-row ml-auto mr-auto"
        >
          <language-select
            class="pr-4"
          />
          <theme-menu
            class="mt-2"
          />
        </div>
        <cookie-banner
          class="ml-auto mr-auto text-left mb-3"
          style="max-width: 768px;"
        />
        <div
          class="d-flex flex-column justify-center align-center ma-auto"
          style="flex: 1 0 auto !important; max-width: min(100%, 800px) !important;"
        >
          <v-expand-transition>
            <v-alert
              v-if="false"
              v-model="loginAlert"
              colored-border
              :outlined="$vuetify.theme.dark"
              prominent
              width="95.5%"
              style="
                max-width: min(100%, 800px) !important;
                background-color: var(--v-background-base) !important;
                border-color: #a2a448 !important;
              "
              :elevation="$vuetify.theme.dark ? 0 : 6"
              border="left"
              rounded
              class="text-left ml-auto mr-auto rounded-xl overflow-hidden"
              type="success"
              icon="mdi-information-outline"
              transition="v-expand-transition"
            >
              <div style="opacity: 0.9;">
                <span class="text-h6">
                  {{ $t('nica2022Results') }}
                </span>
                <br>
                {{ $t('gotoLoginPage1') }}
                <router-link to="/login">{{ $t('loginPage') }}</router-link>
                {{ $t('gotoLoginPage2') }}
                <br>
                <div v-html="$t('doNot')" />
                <br>
              </div>
              <v-divider
                class="my-1"
                style="border-color: var(--v-divider-base) !important;"
              />
              <ul
                class="text-caption"
                style="opacity: 0.8; font-size: 14px !important;"
              >
                <li>
                  {{$t('click') }} <router-link to="/login/reset">{{ $t('here') }}</router-link> {{ $t('toResetPwd') }}
                </li>
                <li>
                  {{ $t('troubleLogin') }}
                  <a
                    style="color: unset !important;"
                    href="mailto:info@cider-world.com?subject=Award-Registration%20I%20need%20support"
                    class="ml-1"
                  >
                    E-Mail Support
                  </a>
                </li>
              </ul>
            </v-alert>
          </v-expand-transition>
          <login-header />
          <span class="my-title ml-4 pb-4 text-left">
            {{ $i18n.t('requestProducerAccount') }}
          </span>
          <v-card
            v-if="successRequest"
            :outlined="$vuetify.theme.dark"
          >
            <v-icon size="72">
              mdi-account-plus
            </v-icon>
            <v-card-title
              class="pb-5"
              style="overflow-wrap: break-word !important; word-break: keep-all !important;"
            >
              {{ $i18n.t('receivedAccountRequestTitle') }}
            </v-card-title>
            <v-card-subtitle class="pb-2 text-left">
              {{ $i18n.t('receivedAccountRequestMessage') }}
            </v-card-subtitle>
            <span
              style="text-align: center"
              class="pb-0 pt-0"
            >
              {{ email }}
            </span>
            <v-card-actions class="pb-4">
              <v-spacer />
              <v-btn
                rounded
                outlined
                style="text-transform: none !important;"
                @click="$router.push('/login')"
              >
                <v-icon>
                  mdi-arrow-left
                </v-icon>
                {{ $i18n.t('Login.backToLogin') }}
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
          <template v-if="!successRequest">
            <v-form
              ref="form"
              v-model="valid"
              class="pl-4 pr-4"
              style="width: 100%;"
            >
              <v-row class="mt-0 mb-0">
                <v-col class="pt-0 pb-0">
                  <v-text-field
                    v-model="companyName"
                    outlined
                    type="text"
                    :label="$i18n.t('companyName')"
                    :placeholder="$i18n.t('companyName')"
                    :rules="companyNameRules"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 mb-0">
                <v-col class="pt-0 pb-0">
                  <v-text-field
                    v-model="email"
                    outlined
                    label="E-Mail"
                    placeholder="E-Mail"
                    :rules="emailRules"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 mb-0">
                <v-col class="pt-0 pb-0">
                  <v-checkbox
                    v-model="agb"
                    :rules="[v => !!v || $i18n.t('acceptPrivacy')]"
                    :label="$i18n.t('requestAndAccept') + $i18n.t('agbs') + $i18n.t('olderThan18')"
                    required
                  >
                    <template #label>
                      <div>
                        {{ $i18n.t('requestAndAccept') }}

                        <v-tooltip top>
                          <template #activator="{ on }">
                            <a
                              class="mr-1"
                              target="_blank"
                              href="https://cider-world.de/datenschutzerklaerung/"
                              @click.stop
                              v-on="on"
                            >
                              {{ $i18n.t('agbs') }}
                            </a>
                          </template>
                          {{ $i18n.t('opensInNew') }}
                        </v-tooltip>
                        {{ $i18n.t('olderThan18') }}
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-form>
<!--            <vue-recaptcha-->
<!--              ref="recaptcha"-->
<!--              sitekey="6LcudrogAAAAAB_NfCG6URKM3f2jhiuDiSmP__2z"-->
<!--              size="invisible"-->
<!--              :badge="'left: 110'"-->
<!--              @verify="onVerify"-->
<!--              @expired="onExpired"-->
<!--              @error="onError"-->
<!--            />-->
            <v-fade-transition>
              <p
                v-show="errorMsg !== null"
                class="mb-2 mt-2"
              >
                {{ errorMsg }}
              </p>
            </v-fade-transition>
            <v-container
              fluid
              style="flex-wrap: wrap !important; flex-direction: row-reverse;"
              class="d-flex flex-nowrap flex-row justify-space-between pl-4 pr-4"
            >
              <div class="d-flex justify-start">
                <v-btn
                  text
                  class="mb-2"
                  @click="$router.push('/login')"
                >
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                  {{ $i18n.t('Login.backToLogin') }}
                </v-btn>
              </div>
              <div class="d-flex justify-end ml-auto">
                <v-btn
                  elevation="0"
                  color="primary"
                  :loading="loading"
                  @click="requestAccount"
                >
                  {{ $i18n.t('Login.requestAccount') }}
                </v-btn>
              </div>
            </v-container>
          </template>
        </div>
        <div
          class="ml-auto mr-auto text-caption pb-4 pt-4"
          style="font-size: 14px !important;"
        >
          {{ $t('Login.needHelp') }}
          <a
            style="color: unset !important;"
            href="mailto:info@cider-world.com?subject=Award-Registration%20I%20need%20support"
            class="ml-1"
          >
            E-Mail Support
          </a>
        </div>
        <div
          class="d-flex flex-column justify-end align-center"
          style="flex-shrink: 0;"
        >
          <login-footer-card />
        </div>
      </v-col>
<!--      <v-col-->
<!--        v-if="!isSmallScreen"-->
<!--        class="pa-0"-->
<!--        style="height: 100vh;"-->
<!--      >-->
<!--        <v-img-->
<!--          :contain="false"-->
<!--          style="height: 100%; width: 100%"-->
<!--          :src="getBanner"-->
<!--        />-->
<!--      </v-col>-->
    </v-row>
  </v-container>
</template>

<script>
// import { VueRecaptcha } from 'vue-recaptcha';
import LoginHeader from "@/components/login/login_header";
import login_mixin from "@/components/mixins/login_mixin";
import logos_mixin from "@/components/mixins/logos_mixin";
import {apiTyped} from "@/api";
import LanguageSelect from "@/components/language_select";
import ThemeMenu from "@/components/theme_menu";
import LoginFooterCard from "@/components/login_footer_card";
import i18n from '@/i18n'
import CookieBanner from "@/components/cookie_banner";

export default {
  name: "RequestAccount",
  components: {
    CookieBanner,
    LoginFooterCard,
    ThemeMenu,
    LanguageSelect,
    LoginHeader,
    // VueRecaptcha
  },
  mixins: [login_mixin, logos_mixin],
  data() {
    return {
      errorMsg: null,
      verified: false,
      valid: false,
      loading: false,
      successRequest: false,
      companyName: '',
      email: '',
      agb: false,
      loginAlert: true,
    };
  },
  i18n: {
    messages: {
      de: {
        "madeWith": "Mit",
        "inHessen": "in Hessen entwickelt",
        "requestAndAccept": "Konto anfragen. Ich akzeptiere die ",
        "agbs": "Datenschutzbestimmungen",
        "olderThan18": "und bestätige, dass ich 18 Jahre oder älter bin.",
        "companyName": "Firmenname",
        "requestProducerAccount": "Produzenten-Account anfragen",
        "receivedAccountRequestTitle": "Wir haben Ihre Account Anfrage erhalten",
        "receivedAccountRequestMessage": "Nach einer kurzen Prüfung werden wir eine Bestätigung mit " +
          "Anmeldeformular an folgende Adresse versenden:",
        "acceptPrivacy": "Um einen Account zu erstellen, müssen Sie die Datenschutzbestimmungen akzeptieren und " +
          "bestätigen, dass Sie mind. 18 Jahre alt sind",
        "isRequired": "wird benötigt",
        "mustBeValid": "muss gültig sein",
        "expiredCaptcha": "Captcha abgelaufen. Nochmals versuchen",
        "errorCaptcha": "Überprüfung des Captcha fehlerhalt. Nochmals versuchen",
        "errorCreatingAccount": "Beim Anfragen des Accounts ist win Fehler aufgetreten 🥲.",
        "nica2022Results": "Sie suchen die Nordic International Cider Awards 2022 Ergebnisse?",
        "loginPage": "Anmeldeseite",
        "gotoLoginPage1": 'Um die Ergebnisse einzusehen, besuchen Sie bitte die',
        "gotoLoginPage2": 'und geben Sie die Zugangsdaten ein, die auch zum Einreichen der Produkte verwendet wurden.',
        "doNot": `Bitte benutzen Sie <strong>nicht</strong> dieses Formular, um einen neuen Account anzufragen.`,
        "here": "hier",
        "click": "Klicken Sie",
        "toResetPwd": "um Ihr Passwort zurückzusetzen.",
        "troubleLogin": "Falls Sie Probleme beim Anmelden haben, kontaktieren Sie uns via"
      },
      en: {
        "madeWith": "Made with",
        "inHessen": "in Hessen",
        "requestAndAccept": "Request Account. I accept the ",
        "agbs": "privacy policy",
        "olderThan18": "and confirm, that I am at least 18 years old.",
        "companyName": "Company Name",
        "requestProducerAccount": "Request Producer Account",
        "receivedAccountRequestTitle": "We have received your account request",
        "receivedAccountRequestMessage": "After a brief review you will receive a registration " +
          "and signup mail to your address:",
        "acceptPrivacy": "To create an account you have to accept die privacy policy and confirm to be 18 years or older",
        "isRequired": "is required",
        "mustBeValid": "must be valid",
        "expiredCaptcha": "Expired Captcha. Try again",
        "errorCaptcha": "Error solving Captcha. Try again",
        "errorCreatingAccount": "An error occurred while requesting account 🥲.",
        "nica2022Results": "You are looking for Nordic International Cider Awards 2022 results?",
        "loginPage": "login page",
        "gotoLoginPage1": `To view your results, please visit the`,
        "gotoLoginPage2": `and enter the credentials you used for submitting products.`,
        "doNot": `Please do <strong>not</strong> use this form to request another account.`,
        "here": "here",
        "click": "Click",
        "toResetPwd": "to reset your password.",
        "troubleLogin": "If you have trouble logging in, please contact us via"
      }
    }
  },
  computed: {
    companyNameRules() {
      return [
        v => !!v || this.$i18n.t('companyName') + ' ' + this.$i18n.t('isRequired'),
      ]
    },
    emailRules() {
      return [
        v => !!v || 'E-mail ' + this.$i18n.t('isRequired'),
        v => /.+@.+/.test(v) || 'E-mail ' + this.$i18n.t('mustBeValid'),
      ]
    },
    getBanner() {
      return this.getLogoUrl('image_background_fair', -1, true);
    },
    isSmallScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case "sm":
          return true
        default:
          return false
      }
    },
  },
  methods: {
    async onVerify (response) {
      this.verified = true;
      try {
        this.successRequest = await apiTyped.createProducerAccountRequest(this.companyName, this.email);
      } catch (e) {
        this.errorMsg = this.$i18n.t('errorCreatingAccount');
        console.error(e)
      }
      this.loading = false;
    },
    async onExpired () {
      console.log('Expired')
      this.errorMsg = this.$i18n.t('expiredCaptcha');
      this.verified = false;
      this.loading = false;
    },
    async onError () {
      console.log('Error')
      this.errorMsg = this.$i18n.t('errorCaptcha');
      this.verified = false;
      this.loading = false;
    },
    async requestAccount () {
      this.$refs.form.validate();
      this.errorMsg = null;
      if (this.valid) {  // TODO && this.verified
        this.loading = true;
        // this.$refs.recaptcha.execute();
        await this.onVerify();
      }
    },
  }
}
</script>

<style>
.my-title {
  font-family: "Nunito Sans", serif !important;
  font-weight: 800;
  font-size: 32pt !important;
}

.grecaptcha-badge {
  z-index: 999999 !important;
  left: 4px !important;
  bottom: 64px !important;
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}
</style>