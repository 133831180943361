<template>
  <v-card
    ref="card"
    :outlined="$vuetify.theme.dark"
  >
    <v-card-text class="pb-0">
      <p class="d-flex justify-start text-h4 text--primary mb-0">
        Medaillen
      </p>
    </v-card-text>
    <v-card-text
      v-if="badges.length === 0"
      class="text--secondary"
    >
      No ratings yet
    </v-card-text>
    <v-list v-if="badges.length > 0" color="background">
      <v-list-item
        v-for="badge in badges"
        :key="badge.id"
      >
        <v-list-item-avatar
          class="ma-0 mr-2 elevation-2"
          style="overflow: unset"
        >
          <v-avatar
            size="42"
          >
            <v-img :src="getBadgeUrl(100, badge.punkteMin)" />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-progress-linear
            :value="100 * badgeCount(badge) / allCiders.length"
            height="25"
            :color="badge.hasImage ? badge.color : 'secondary'"
          >
            <strong>{{ badgeCount(badge) || 0 }} </strong>
            <span class="text--secondary pl-1">({{ Math.round(100 * badgeCount(badge) / allCiders.length) }}%)</span>
          </v-progress-linear>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import {sync} from "vuex-pathify";
import cider_mixin from "@/components/mixins/cider_mixin";

export default {
  name: "CardBadgesCount",
  mixins: [cider_mixin],
  props: {
    allCiders: {type: Array, required: true, default: null},
    badges: {type: Array, required: true, default: null},
  },
  computed: {
    // badges: sync('badgesStore/badgesData'),
    badgesData () {
      return this.badges.filter(badge => badge.hasImage === true);
    },
  },
  mounted() {
    console.log("Emit size changed CardBadgesCount", this.$refs.card.$el.clientHeight)
    this.$emit("size", {name: "CardBadgesCount", size: this.$refs.card.$el.clientHeight})
  },
  methods: {
    badgeCount(badge) {
      return (this.allCiders.filter(cider => cider.badgeData.punkteMin === badge.punkteMin).length || 0);
    }
  },
}
</script>

<style scoped>

</style>