<template>
  <v-snackbar
    v-model="feedbackStatus"
    :timeout="60000"
    rounded="xl"
    right
    max-width="100%"
    style="color: var(--v-text_std-base) !important; max-width: 100% !important;"
  >
    <div
      class="d-flex align-start"
    >
      <div
        class="text-h6"
      >
        {{ title }}
      </div>
      <v-spacer />
      <v-btn
        icon
        class="ml-2"
        style="margin-right: -12px;"
        @click="changeStatus(false)"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </div>
    <v-expand-transition>
      <div v-show="showThankYou">
        <div
          class="d-flex flex-column justify-center align-center"
        >
          <v-icon x-large>
            mdi-emoticon-outline
          </v-icon>
          <v-card-title class="text-break text-center">
            {{ $t('Feedback.thanks') }}
          </v-card-title>
        </div>
      </div>
    </v-expand-transition>
    <v-expand-transition>
      <div v-show="!showThankYou" class="ml-auto mr-auto">
        <vue-feedback-reaction
          v-model="feedback"
          :emoji-height="isSmallScreen ? '32px' : '54px'"
          :emoji-width="isSmallScreen ? '32px' : '54px'"
          class="justify-center no-animation ml-auto mr-auto"
          style="width: 100%;"
          :class="{'no-animation': isSmallScreen}"
          :labels="computedLabels"
        />
        <v-expand-transition>
          <div
            v-show="feedbackMoreInfo"
          >
            <v-textarea
              v-model="feedbackText"
              rows="3"
              outlined
              hide-details
              hint=""
              :label="$t('Feedback.additional')"
            />
            <v-card-actions
              class="pr-0 pb-0 pt-3"
            >
              <v-spacer />
              <v-btn
                elevation="0"
                color="primary"
                :loading="loadingFeedback"
                @click="sendFeedback"
              >
                {{ $t('send') }}
              </v-btn>
            </v-card-actions>
          </div>
        </v-expand-transition>
      </div>
    </v-expand-transition>
  </v-snackbar>
</template>

<script>
import { VueFeedbackReaction  } from 'vue-feedback-reaction';
import {apiTyped} from "@/api";

export default {
  name: "FeedbackDialog",
  components: {VueFeedbackReaction},
  props: {
    value: {type: Boolean, required: false, default: false},
    title: {type: String, required: false, default: 'How satisfied were you with the process?'},
    origin: {type: String, required: false, default: 'feedback'},
  },
  data: () => ({
    feedback: '',
    feedbackText: "",
    feedbackStatus: false,
    feedbackMoreInfo: false,
    loadingFeedback: false,
    showThankYou: false,
  }),
  computed: {
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        default: return false
      }
    },
    computedLabels() {
      const labels = [
        this.$i18n.t('Feedback.feedback1'),
        this.$i18n.t('Feedback.feedback2'),
        this.$i18n.t('Feedback.feedback3'),
        this.$i18n.t('Feedback.feedback4'),
        this.$i18n.t('Feedback.feedback5')
      ];
      if (this.isSmallScreen) {
        if (this.feedback !== '') {
          const f = Number(this.feedback) - 1;
          return labels.map((l,i) => {
            if (i === f) return labels[i];
            return '';
          });
        }
        return [];
      }
      return labels;
    }
  },
  watch: {
    feedback: function () {
      this.feedbackMoreInfo = true;
    },
    value: function (val) {
      this.feedbackStatus = val;
    },
  },
  created() {
    this.feedbackStatus = this.value;
  },
  methods: {
    changeStatus(val) {
      this.feedbackStatus = val;
      if (!val) this.feedbackMoreInfo = false;
      this.$emit('input', val)
    },
    show() {
      this.feedbackStatus = true;
      this.$emit('input', true)
    },
    async sendFeedback() {
      this.loadingFeedback = true;
      const storeData = this.$store.state;
      await apiTyped.addFeedback(this.feedback, this.feedbackText, this.origin, navigator.userAgent, storeData);
      this.feedback = "";
      this.feedbackText = "";
      this.feedbackMoreInfo = false;
      this.loadingFeedback = false;
      this.showThankYou = true;
      window.setTimeout(() => {  // close after 2.5 s
        this.changeStatus(false);
      }, 2500);
      window.setTimeout(() => {  // rest after 3 s
        this.showThankYou = false;
        this.feedbackMoreInfo = false;
      }, 3000);
    }
  },
}
</script>

<style scoped>
/deep/ .v-snack__content {
  max-width: 100%;
}
/deep/ .vue-reaction > *  {
  -o-transition-property: none !important;
  -moz-transition-property: none !important;
  -ms-transition-property: none !important;
  -webkit-transition-property: none !important;
  transition-property: none !important;
  /*CSS transforms*/
  -o-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -webkit-transform: none !important;
  transform: none !important;
  /*CSS animations*/
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}
</style>