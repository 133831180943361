<template>
  <div>
    <v-toolbar flat>
      <v-slide-group class="d-flex align-center">
        <v-text-field
          v-model="textSearch"
          style="max-width: 320px;"
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          outlined
          clearable
          dense
          rounded
          hide-details
          class="filter-chip"
        />
        <country-multi-select
          v-model="selectedCountries"
          :countries="allCountries"
          class="filter-chip"
        />
      </v-slide-group>
    </v-toolbar>
    <div>
      <v-chip-group>
        <v-chip
          v-for="(country, index) in selectedCountries"
          :key="country.id"
          close
          @click:close="selectedCountries.splice(index, 1)"
        >
          <country-flag
            :country="(country.flag_code || country.flagCode) || country.code.split('-')[0]"
            size="normal"
          />
          {{ country.name }}
        </v-chip>
      </v-chip-group>
    </div>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import CountrySelect from "@/components/producer/country_select.vue";
import {sync} from "vuex-pathify";
import {apiTyped} from "@/api";
import CountryMultiSelect from "@/components/admin/filter/country_multiselect.vue";

export default {
  name: "JudgesFilter",
  components: {
    CountryMultiSelect,
    CountryFlag
  },
  data: () => ({
    allCountries: [],
    selectedCountries: [],
    textSearch: "",
  }),
  computed: {
    tasting: sync('tableDesignerStore/tasting'),
    filter() {
      return "";
    }
  },
  async created() {
    this.allCountries = await apiTyped.getAllCountries() || [];
  },
  methods: {
  },
}
</script>

<style scoped>
.filter-chip {
  margin: 4px !important;
}
</style>