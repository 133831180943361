<template>
  <v-container
    fluid
    class="pa-4 pl-2 d-flex align-center"
  >
    <v-img
      contain
      class="pa-3 rounded-0"
      src="/img/ciderWorldServiceLogo.png"
      alt="CiderWorld Service Logo"
      style="margin: auto; cursor: pointer;"
      max-width="160px"
      transition="fade-transition"
    />
    <!--:src="getLogoColoredUrl('logo_app', 600, $vuetify.theme.dark)"-->
  </v-container>
</template>

<script>
export default {
  name: "LoginHeader",
}
</script>

<style scoped>
</style>