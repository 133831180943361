<template>
  <div
    class="ml-auto mr-auto pl-3 pr-3"
    style="max-width: 2000px;"
  >
    <div
      v-if="openTastings !== null && loaded && openTastings.length > 0"

      class="d-flex text-left pl-0 pb-4 text-h2 font-weight-medium align-center"
      style="font-size: 36px !important; font-family: 'Nunito Sans', serif !important;"
    >
      {{ $t('availableTastings') }}
    </div>
    <template
      v-if="openTastings !== null && loaded && openTastings.length > 0"
    >
      <v-row>
        <v-col
          v-for="tasting in openTastings"
          :key="tasting.id"
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="4"
        >
          <v-hover
            v-slot="{ hover }"
          >
            <TastingCard
              :tasting="tasting"
              :submitted-ciders="[]"
              :elevation="hover ? 8 : 0"
              show-more-info-btn
            />
          </v-hover>
        </v-col>
      </v-row>
    </template>

    <div
      v-if="openTastings !== null && loaded && openTastings.length > 0"

      class="d-flex text-left pl-0 pt-4 pb-4 text-h2 font-weight-medium align-center"
      style="font-size: 36px !important; font-family: 'Nunito Sans', serif !important;"
    >
      {{ $t('myEvents') }}
    </div>
    <template
      v-if="myTastings !== null && loaded && myTastings.length > 0"
    >
      <v-row class="pb-8">
        <v-col
          v-for="(tasting, index) in myTastings"
          :key="tasting.id"
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="4"
        >
          <v-hover
            v-slot="{ hover }"
          >
            <TastingCard
              :tasting="tasting"
              :submitted-ciders="submittedCiders[index]"
              :elevation="hover ? 8 : 0"
              show-cancel-submission
              show-more-info-btn
              @cancelSubmission="reloadTasting(index)"
            />
          </v-hover>
        </v-col>
      </v-row>
    </template>

    <v-container
      v-if="loaded && myTastings != null && myTastings.length === 0"
      class="ml-auto mr-auto"
      style="position: relative; min-height: 60vh"
    >
      <v-hover
        v-slot="{ hover }"
      >
        <v-card
          class="center-on-page pb-4"
          width="300"
          outlined
          :elevation="hover ? 12 : 0"
          style="border-style: dashed; border-width: medium; cursor: pointer; transition: box-shadow 0.2s ease-in-out;"
          @click="startCiderSubmit"
        >
          <v-icon size="192">
            mdi-basket-plus-outline
          </v-icon>
          <v-card-subtitle class="pb-2">
            {{ $t('noEventsSubmittedYet') }}
          </v-card-subtitle>
          <v-card-actions>
            <v-btn
              class="ml-auto mr-auto text-center"
              rounded
              outlined
              text
            >
              {{ $t('submitProduct') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-hover>
    </v-container>

    <div
      v-if="upcomingTastings !== null && loaded && upcomingTastings.length > 0"
      class="d-flex text-left pl-0 pb-4 text-h2 font-weight-medium align-center"
      style="font-size: 36px !important; font-family: 'Nunito Sans', serif !important;"
    >
      {{ $t('upcomingTastings') }}
    </div>
    <template
      v-if="upcomingTastings !== null && loaded && upcomingTastings.length > 0"
    >
      <v-row>
        <v-col
          v-for="tasting in upcomingTastings"
          :key="tasting.id"
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="4"
        >
          <v-hover
            v-slot="{ hover }"
          >
            <TastingCard
              :tasting="tasting"
              :submitted-ciders="[]"
              :elevation="hover ? 8 : 0"
              show-more-info-btn
            />
          </v-hover>
        </v-col>
      </v-row>
    </template>

    <div
      v-if="pastTastings !== null && loaded && pastTastings.length > 0"
      class="d-flex text-left pl-0 pb-4 text-h2 font-weight-medium align-center"
      style="font-size: 36px !important; font-family: 'Nunito Sans', serif !important;"
    >
      {{ $t('pastEvents') }}
    </div>
    <template
      v-if="pastTastings !== null && loaded && pastTastings.length > 0"
    >
      <v-row>
        <v-col
          v-for="tasting in pastTastings"
          :key="tasting.id"
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="4"
        >
          <v-hover
            v-slot="{ hover }"
          >
            <TastingCard
              :tasting="tasting"
              :submitted-ciders="[]"
              :elevation="hover ? 8 : 0"
              show-more-info-btn
            />
          </v-hover>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import {apiTyped} from "@/api";
import TastingCard from "@/components/producer/tasting_card";

export default {
  name: "ProducerTastingsTab",
  components: {TastingCard},
  data: () => ({
    myTastings: null,
    pastTastings: null,
    upcomingTastings: null,
    openTastings: null,
    lastSelectedTastingId: null,
    submittedCiders: null,
    loaded: false,
  }),
  async created() {
    // TODO pagination
    this.myTastings = await apiTyped.producerGetMyTastings(0, 1, 50);
    this.pastTastings = await apiTyped.producerGetPastTastings(
      0, 1, 50, null, this.myTastings.map(t => t.id));
    this.upcomingTastings = await apiTyped.producerGetUpcomingTastings(
      0, 1, 50, null, this.myTastings.map(t => t.id));
    console.log("upcomingTastings", this.upcomingTastings)
    this.openTastings = await apiTyped.producerGetOpenTastings(
      0, 1, 50, null, this.myTastings.map(t => t.id));
    this.submittedCiders = await Promise.all(this.myTastings.map(t => apiTyped.getSubmittedCidersForTasting(t.id)));
    this.loaded = true;
  },
  methods: {
    async reloadTasting(index) {
      await apiTyped.deleteSubmittedCidersForTasting(this.submittedCiders[index]);
      this.myTastings = await apiTyped.producerGetAvailableTastings();
      this.pastTastings = await apiTyped.producerGetPastTastings(
        0, 1, 50, null, this.myTastings.map(t => t.id));
      this.upcomingTastings = await apiTyped.producerGetUpcomingTastings(
        0, 1, 50, null, this.myTastings.map(t => t.id));
      this.openTastings = await apiTyped.producerGetOpenTastings(
        0, 1, 50, null, this.myTastings.map(t => t.id));
      this.submittedCiders = await Promise.all(this.myTastings.map(t => apiTyped.getSubmittedCidersForTasting(t.id)));
    },
    startCiderSubmit() {
      this.$store.commit("submitStore/clear");
      this.$router.push('submit/tastings');
    },
  }
}
</script>

<style scoped>
.center-on-page {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
}
</style>