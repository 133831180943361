<template>
  <v-container
    fluid
    style="min-height: 70vh;"
  >
    <div class="text-h5 text-left pb-4">
      Submit Cider to Tasting
    </div>

    <v-card
      color="transparent"
      class="d-flex flex-row align-baseline flex-grow-1 mb-2"
      style="width: 100%;"
      flat
      ripple
      @click="stepTastingHidden = !stepTastingHidden"
    >
      <span
        class="d-flex ml-0 mt-4 text-left text-h5 pb-3 text--secondary"
        style="color: var(--v-background_text-base)"
      >
        <template v-if="selectedTasting == null">
          <v-icon left>
            mdi-numeric-1-circle-outline
          </v-icon>
          Choose a Tasting
        </template>
        <template v-if="selectedTasting != null">
          <v-icon left>
            mdi-check-circle
          </v-icon>
          {{ selectedTasting.name }}, {{ selectedTasting.datum.toLocaleString() }}
        </template>
      </span>
      <div class="d-flex justify-end align-end ml-auto pr-2">
        <v-btn
          icon
        >
          <v-icon
            :class="{'rotate-180': stepTastingHidden}"
          >
            mdi-chevron-up
          </v-icon>
        </v-btn>
      </div>
    </v-card>

    <v-expand-transition>
      <div v-show="!stepTastingHidden">
        <v-dialog
          v-model="selectTastingDialog"
          width="800"
        >
          <template #activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="d-flex justify-center"
              v-on="on"
            >
              <v-card
                v-if="selectedTasting == null"
                style="cursor: pointer; max-width: 240px"
                class="pa-4"
                outlined
              >
                Click to select a Tasting
              </v-card>
    
              <tasting-card
                v-else
                :tasting="selectedTasting"
                hide-buttons
                hide-more-infos
                hide-submission-sub-text
                :show-cancel-submission="false"
                :show-more-info-btn="false"
                style="cursor: pointer; max-width: 600px"
              />
            </div>
          </template>
  
          <v-card class="pb-3">
            <div
              class="mb-3"
              style="position: sticky; top: 0; z-index: 4; background-color: var(--v-card_background-base)"
            >
              <v-card-title
                class="text-h5"
              >
                Select a Tasting to submit a product to
              </v-card-title>
              <v-divider />
            </div>
  
            <div
              v-for="tasting_ in tastings"
              :key="tasting_.id"
              @click="selectedTasting = tasting_; selectTastingDialog = false;"
            >
              <tasting-card
                :tasting="tasting_"
                hide-buttons
                hide-more-infos
                hide-submission-sub-text
                :show-cancel-submission="false"
                :show-more-info-btn="false"
                style="cursor: pointer;"
                class="pt-1 pb-1 px-2"
              />
            </div>
          </v-card>
        </v-dialog>
      </div>
    </v-expand-transition>

    <v-card
      color="transparent"
      class="d-flex flex-row align-baseline flex-grow-1 mb-2 mt-8"
      style="width: 100%;"
      flat
      ripple
      @click="stepProductHidden = !stepProductHidden"
    >
      <span
        class="d-flex ml-0 mt-4 text-left text-h5 pb-3 text--secondary"
        style="color: var(--v-background_text-base)"
      >
        <template v-if="selectedProduct == null">
          <v-icon left>
            mdi-numeric-2-circle-outline
          </v-icon>
          Choose a Product
        </template>
        <template v-if="selectedProduct != null">
          <v-icon left>
            mdi-check-circle
          </v-icon>
          {{ selectedProduct.ciderInfos.name }}, (ID: {{ selectedProduct.id }})
          <v-btn
            icon
            class="ml-1"
          >
            <a
              target="_blank"
              :href="ciderUrl"
              style="color: unset !important; text-decoration: none !important;"
              @click.stop
            >
              <v-icon>
                mdi-open-in-new
              </v-icon>
            </a>
          </v-btn>
        </template>
      </span>
      <div class="d-flex justify-end align-end ml-auto pr-2">
        <v-btn
          icon
        >
          <v-icon
            :class="{'rotate-180': stepProductHidden}"
          >
            mdi-chevron-up
          </v-icon>
        </v-btn>
      </div>
    </v-card>
    <v-expand-transition>
      <div v-show="!stepProductHidden">
        <template
          v-if="selectedProduct == null"
        >
          <v-row
            class="align-baseline"
          >
            <v-col cols="2">
              <v-text-field
                v-model="productId"
                outlined
                clearable
                rounded
                type="number"
                step="1"
                class="rounded-lg"
                label="Product ID"
                value=""
                @keydown.enter="loadProducts"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="productName"
                outlined
                clearable
                rounded
                step="1"
                class="rounded-lg"
                label="Product Name"
                value=""
                @keydown.enter="loadProducts"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="producerName"
                outlined
                clearable
                rounded
                step="1"
                class="rounded-lg"
                label="Producer Name"
                value=""
                @keydown.enter="loadProducts"
              />
            </v-col>
            <v-col cols="2">
              <v-btn
                text
                height="60"
                color="primary"
                outlined
                class="rounded-lg"
                :loading="searching"
                @click="loadProducts"
              >
                Search
              </v-btn>
            </v-col>
          </v-row>
          <div>
            <div
              v-show="products.length === 0"
              class="text--secondary text-body-1"
              style="min-height: 68px;"
            >
              No products matching the search
            </div>
            <producer-all-products
              :ciders="products"
              :loaded="false"
              :loading-more-products="false"
              :more-products-available="false"
              @clickProduct="p => selectedProduct = p"
            />
          </div>
        </template>

        <div
          v-if="selectedProduct != null"
          style="max-width: 800px;"
          class="mx-auto"
        >
          <cider-card
            :cider="selectedProduct"
            full-content
            :show-edit="false"
            outlined
          />
        </div>

        <div />
      </div>
    </v-expand-transition>

    <div class="d-flex justify-end pa-4">
      <v-btn
        elevation="0"
        class="mr-3"
        color="error"
        :disabled="!selectedCiderIsSubmitted || selectedProduct == null || selectedTasting == null"
        @click="removeSubmission"
      >
        Remove Submission
      </v-btn>
      <v-btn
        elevation="0"
        color="primary"
        :disabled="selectedCiderIsSubmitted || selectedProduct == null || selectedTasting == null"
        @click="submitProduct"
      >
        Submit Product
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import {sync} from "vuex-pathify";
import TastingCard from "@/components/producer/tasting_card.vue";
import {apiTyped} from "@/api";
import ProducerAllProducts from "@/components/producer/producer_all_products.vue";
import CiderCard from "@/components/cider_card.vue";

export default {
  name: "AdminSubmitCider",
  components: {CiderCard, ProducerAllProducts, TastingCard},
  data: () => ({
    stepTastingHidden: false,
    stepProductHidden: false,
    selectTastingDialog: false,
    selectedTasting: null,
    selectedProduct: null,
    productId: null,
    productName: null,
    producerName: null,
    searching: false,
    products: [],
  }),
  computed: {
    tables: sync("tableStore/tables"),
    tastings: sync("usersStore/tastings"),
    kategorien: sync("kategorienStore/kategorieData"),
    tasting() {
      return this.$store.getters["usersStore/currentTasting"]();
    },
    ciderUrl() {
      if (this.selectedProduct != null)
        return apiTyped.baseUrl + "admin/content/ciders/" + this.selectedProduct.id
      return "";
    },
    selectedCiderIsSubmitted() {
      if (this.selectedProduct == null
        || this.selectedTasting == null
        || this.selectedProduct.submitted.length === 0) return false;
      return this.selectedProduct.submitted.find(s => s.tasting.id === this.selectedTasting.id) != null;
    }
  },
  methods: {
    clearForm() {
      this.productId = null;
      this.productName = null;
      this.producerName = null;
      this.selectedProduct = null;
      this.selectedTasting = null;
    },
    async submitProduct() {
      this.selectedProduct.editRevisionInfos = {
        kommentar: "Added by Admin",
        revisionId: -1,
        status: "draft",
      }
      const addResult = await apiTyped.addSubmittedCidersForTasting(
        [this.selectedProduct], this.selectedTasting, this.selectedProduct.producerId);
      if (addResult.success == null) {
        console.error("Error adding Eingereichte Ciders", this.selectedProduct, this.selectedTasting);
        return;
      }
      this.clearForm();
    },
    async removeSubmission() {
      const submittedCiders = await apiTyped.getSubmittedCidersForTasting(
        this.selectedTasting.id, [this.selectedProduct.id]);
      if (submittedCiders == null) {
        console.error("Error getting Eingereichte Ciders");
        return;
      }
      console.log("GOT SUBMITTED", submittedCiders)
      await apiTyped.deleteSubmittedCidersForTasting(submittedCiders);
      this.clearForm();
    },
    async loadProducts() {
      this.searching = true;
      const filterOr = [];
      if (this.productId != null && this.productId > 0) filterOr.push({ id: { _eq: this.productId } });
      if (this.productName != null && this.productName !== "") filterOr.push({ name: { _icontains: this.productName } });
      if (this.producerName != null && this.producerName !== "") filterOr.push(
        { 'produzent': { 'produzenten_data': { 'company_name': { _icontains: this.producerName } } } });
      const filter = {
        "_or": filterOr
      };
      console.log("filterfilter", filter)
      const newProducts = await apiTyped.producerGetAllCiders(
        null, 0, 1, 10, filter) || null;
      if (newProducts.length > 0) {
        if (this.ciders == null) this.ciders = [];  // init cider list
        this.products = newProducts;
      }
      this.searching = false;
    }
  }
}
</script>

<style scoped>
.rotate-180 {
    -moz-transform:rotate(-180deg);
    -webkit-transform:rotate(-180deg);
    transform:rotate(-180deg);
}
</style>