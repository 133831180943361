import {MyCollections} from "@/api/types/my-collections";

export interface RevisionForCider {
    revisionId: number;
    ciderId: number;
}

export interface EingereichteCiders {
    id: number;
    status: string | undefined;
    dateCreated: Date | undefined;
    dateUpdated: Date | undefined;
    ciderRevision: number | undefined;
    kommentar: string;
    cider: number;
    tasting: number;
    productRevision: number;
}

export function createEingereichteCidersFromDb(eingereichteCiders: MyCollections["eingereichte_ciders"]) {
    return <EingereichteCiders>{
        id: eingereichteCiders.id,
        status: eingereichteCiders.status,
        dateCreated: eingereichteCiders.date_created,
        dateUpdated: eingereichteCiders.date_updated,
        ciderRevision: eingereichteCiders.cider_revision,
        kommentar: eingereichteCiders.kommentar,
        cider: eingereichteCiders.cider,
        tasting: eingereichteCiders.tasting,
        productRevision: eingereichteCiders.product_revision,
    }
}