<!--uses locales-->
<template>
  <v-container
    class="pl-md-8 pr-md-8 pl-sm-2 pr-sm-2"
    fluid
  >
    <PageNavigation
      :breadcrumbs="[{text: $t('home'), to: '/producer/home'}, {text: $t('paymentHandling.submitProducts'), disabled: true}]"
      :title-translation="$t('paymentHandling.submitProducts')"
    />

    <producer-submit-stepper
      :current-step="submitTastingSteps.selectTastingProducts"
    />
    <step-navigation
      :next-disabled="nextStepDisabled"
      :prev-disabled="false"
      @next="awardExcluded ? $router.push(`/producer/submit/summary`) : $router.push(`/producer/submit/selectCiders`)"
      @prev="$router.push(`/producer/submit/tastings`)"
    />

    <div
      v-if="loaded"
      class="ml-auto mr-auto "
      style="max-width: 2000px;"
    >
      <div
        class="ml-auto mr-auto"
        style="width: 100%; max-width: 1250px;"
      >
        <div class="d-flex text-h4 mb-0 mt-4 align-center">
          {{ $t('paymentHandling.tastingProducts') }}
        </div>

        <div
          v-if="isParticipatingWithAward"
          class="pt-4 pb-2"
        >
          <v-alert
            v-ripple
            border="left"
            rounded
            text
            type="success"
            icon="mdi-basket-plus"
            style="cursor: pointer;"
            @click="submitAdditionalProducts"
          >
            <v-row
              no-gutters
            >
              <v-col
                class="text-left text-body-1"
                cols="12"
                xs="12"
                sm="8"
              >
                <div class="font-weight-bold">
                  {{ $t('isParticipating') }}
                </div>
                <div>
                  {{ $t('purchaseAdditionalAwards') }}
                </div>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="4"
                class="d-flex justify-end"
              >
                <v-btn
                  icon
                  elevation="0"
                  color="text_std lighten-3"
                  class="ml-auto my-auto"
                >
                  <v-icon>
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </div>

        <div
          v-if="tastingPurchase != null && tastingPurchase.status !== 'paid'"
          class="d-flex text-body-2 mb-4 mt-1 align-center text-left text--secondary"
        >
          {{ $t('paymentHandling.chooseTastingProducts') }}
        </div>
        <div
          v-if="tastingPurchase != null && tastingPurchase.status === 'paid'"
          class="d-flex text-body-2 mb-4 mt-1 align-center text-left text--secondary"
        >
          {{ $t('paymentHandling.tastingProductsPurchased') }}
        </div>
      </div>

      <div
        class="ml-auto mr-auto"
        style="width: 100%; max-width: 1250px;"
      >
        <v-row>
          <v-col>
            <div
              id="select-participation-type"
              class="ml-auto mr-auto"
              style="width: 100%; max-width: 1250px;"
            >
              <h3 class="pb-5 pt-10 text-left">
                {{ $t('paymentHandling.chooseParticipationType') }}
              </h3>
              <v-row
                class="d-flex justify-center ml-0 mr-0 align-content-stretch align-stretch"
              >
                <v-col
                  v-for="participationProduct in participationProducts"
                  :key="participationProduct.productId"
                  class="align-self-stretch"
                  :class="{'not-highlighted-card': !isSmallScreen && participationProduct.type !== 'booth'}"
                  cols="12"
                  xs="12"
                  sm="6"
                  md="3"
                  lg="3"
                >
                  <v-hover
                    v-slot="{ hover }"
                    style="height: 100%;"
                  >
                    <v-card
                      :elevation="hover ? 10 : $vuetify.theme.dark ? 0 : 1"
                      :class="{'selected-card': participationProduct.productId === selectedParticipationProduct,
                               'highlighted-background': participationProduct.type === 'booth'}"
                      :outlined="$vuetify.theme.dark"
                      class="text-left align-content-stretch align-stretch pt-2 pb-6 !elevation-6"
                      :disabled="servicesDisabled
                        || (getTastingProductById(participationProduct.productId) != null
                          && getTastingProductById(participationProduct.productId).availableAmount === 0)"
                      @click="selectParticipationProduct(participationProduct)"
                    >
                      <participation-card
                        feature-colored
                        :participation-product="participationProduct"
                        :show-details="showParticipationDetails"
                        :available="getTastingProductById(participationProduct.productId) != null
                          ? getTastingProductById(participationProduct.productId).availableAmount
                          : null
                        "
                      />
                      <v-expand-transition>
                        <v-card-actions
                          v-show="participationProduct.type === 'booth' && selectedParticipationType === 'booth'"
                          class="mt-auto"
                        >
                          <v-spacer />
                          <v-btn
                            text
                            @click="showParticipationDetails = !showParticipationDetails"
                            @click.native.stop=""
                          >
                            {{ showParticipationDetails ? $t('hideDetails') : $t('showDetails') }}
                          </v-btn>
                        </v-card-actions>
                      </v-expand-transition>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </div>
            <div
              v-show="selectedParticipationType === 'booth' || selectedParticipationType === 'merch'"
              id="select-booth-type"
              class="ml-auto mr-auto"
              style="width: 100%; max-width: 1250px;"
            >
              <h3 class="pb-5 pt-10 text-left">
                {{ $t('paymentHandling.chooseBoothType') }}
              </h3>
              <v-row
                class="d-flex justify-center ml-0 mr-0"
                style="width: 100%;"
              >
                <v-col
                  v-for="boothProduct in boothProducts.filter(b => b.type === selectedParticipationType)"
                  :key="boothProduct.productId"
                  class="align-self-stretch"
                  cols="12"
                  xs="12"
                  sm="6"
                  md="3"
                  lg="3"
                >
                  <v-hover
                    v-slot="{ hover }"
                    style="height: 100%;"
                  >
                    <div>
                      <v-card
                        :elevation="hover && !servicesDisabled && !getTastingProductById(boothProduct.productId).availableAmount <= 0
                          ? 10 : $vuetify.theme.dark ? 0 : 1"
                        :class="{'selected-card': boothProduct.productId === selectedBoothProduct}"
                        :outlined="$vuetify.theme.dark"
                        class="text-left align-content-stretch align-stretch pt-2 pb-6"
                        :disabled="servicesDisabled
                          || getTastingProductById(boothProduct.productId).availableAmount <= 0"
                        @click="selectBoothProduct(boothProduct)"
                      >
                        <v-card-title class="pb-5">
                          {{ boothProduct.name }}
                        </v-card-title>
                        <v-card-subtitle class="pb-0">
                          <span
                            class="text--primary darken-2 font-weight-bold text-h5"
                            v-html="boothProduct.priceDescription"
                          />
                          <span class="text--primary darken-2 font-weight-bold pl-1">€</span>
                        </v-card-subtitle>
                        <v-card-text
                          v-if="getTastingProductById(boothProduct.productId) != null
                            && getTastingProductById(boothProduct.productId).availableAmount != null"
                          class="text--secondary py-0 pb-1"
                        >
                          <strong>
                            {{ getTastingProductById(boothProduct.productId).availableAmount }}
                          </strong> {{ $t('available') }}
                        </v-card-text>
                        <v-card-text class="py-1 d-flex align-baseline">
                          <div
                            class="text-subtitle-1 text-center"
                            style="width: 24px;"
                            v-text="boothProduct.features.products"
                          />
                          {{ $t('paymentHandling.includedProducts') }}
                        </v-card-text>
                        <v-card-text class="py-1 align-baseline d-flex flex-col">
                          <v-icon
                            small
                            style="width: 24px;"
                            v-text="boothProduct.features.preview ? 'mdi-check' : 'mdi-close'"
                          />
                          {{ $t('paymentHandling.awardPreview') }}
                        </v-card-text>
                        <v-card-text class="py-1 d-flex align-baseline">
                          <div
                            class="text-subtitle-1 text-center"
                            style="width: 24px;"
                            v-text="boothProduct.features.award"
                          />
                          {{ $t('paymentHandling.ceremonyParticipants') }}
                        </v-card-text>
                        <v-card-text class="py-1 d-flex align-baseline">
                          <div
                            class="text-subtitle-1 text-center"
                            style="width: 24px;"
                            v-text="boothProduct.features.exkursion"
                          />
                          {{ $t('paymentHandling.tripParticipants') }}
                        </v-card-text>
                        <v-card-text class="py-1 d-flex align-baseline">
                          <div
                            class="text-subtitle-1 text-center"
                            style="width: 24px;"
                            v-text="boothProduct.features.party"
                          />
                          {{ $t('paymentHandling.partyParticipants') }}
                        </v-card-text>
                        <v-divider class="mx-4" />
                        <v-card-text class="pt-3 pb-1 d-flex align-baseline">
                          {{ $t('paymentHandling.expoInfo') }}
                        </v-card-text>
                        <v-card-text class="py-1 pl-6 d-flex align-baseline font-weight-bold">
                          {{ boothProduct.features.table }}
                        </v-card-text>
<!--                        <v-card-text class="py-1 d-flex align-baseline">-->
<!--                          <div-->
<!--                            class="text-subtitle-1 text-center"-->
<!--                            style="width: 24px;"-->
<!--                            v-text="boothProduct.features.seminar"-->
<!--                          />-->
<!--                          {{ $t('paymentHandling.seminarParticipants') }}-->
<!--                        </v-card-text>-->
                        <v-card-text class="py-1 align-baseline d-flex flex-col">
                          <v-icon
                            small
                            style="width: 24px;"
                            v-text="boothProduct.features.marketing ? 'mdi-check' : 'mdi-close'"
                          />
                          {{ $t('paymentHandling.marketingPackage') }}
                        </v-card-text>
                        <v-card-text
                          class="pb-2"
                          v-html="boothProduct.description || '&nbsp;'"
                        />
                      </v-card>
                    </div>
                  </v-hover>
                </v-col>
              </v-row>
            </div>
            <div class="pt-10">
              <v-expand-transition>
                <div
                  v-show="selectedParticipationType === 'corporate'"
                >
                  <v-card
                    outlined
                    class="text-left pt-2 pb-6 px-5"
                  >
                    <v-card-title>
                      {{ $t('paymentHandling.askViaEmail') }}
                    </v-card-title>
                    <v-card-text class="text-body-1">
                      {{ $t('paymentHandling.wantToBookCorp') }}
                    </v-card-text>

                    <a
                      href="mailto:info@cider-world.com?subject=Award-App20%Coorporate%20booking"
                      class="px-3"
                    >
                      <v-btn
                        class="mr-md-16 animate_change"
                        elevation="0"
                        color="secondary"
                      >
                        {{ $t('paymentHandling.bookCorp') }}
                        <v-icon right>
                          mdi-arrow-right
                        </v-icon>
                      </v-btn>
                    </a>
                  </v-card>
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div
                  v-show="false"
                >
                  <v-card
                    class="text-left pt-2 pb-6 px-5"
                    outlined
                  >
                    <v-card-title>
                      {{ $t('paymentHandling.askViaEmail') }}
                    </v-card-title>
                    <v-card-text class="text-body-1">
                      {{ $t('paymentHandling.wantToBookMerch') }}
                    </v-card-text>
                    <a
                      href="mailto:info@cider-world.com?subject=Award-App%20Merchandise%20booking"
                      class="px-3"
                    >
                      <v-btn
                        class="mr-md-16 animate_change"
                        elevation="0"
                        color="secondary"
                      >
                        {{ $t('paymentHandling.bookMerch') }}

                        <v-icon right>
                          mdi-arrow-right
                        </v-icon>
                      </v-btn>
                    </a>
                  </v-card>
                </div>
              </v-expand-transition>
            </div>
            <div
              v-show="selectedParticipationType === 'booth' || selectedParticipationType === 'merch'"
              id="additional-products"
              class="ml-auto mr-auto pb-3"
              style="width: 100%; max-width: 1250px;"
            >
              <h3 class="pb-5 pt-10 text-left">
                {{ $t('paymentHandling.moreServices') }}
              </h3>
              <v-row
                class="d-flex justify-center ml-0 mr-0"
                style="width: 100%;"
              >
                <v-col
                  v-for="extraProduct in extraProducts"
                  :key="extraProduct.productId"
                  cols="12"
                  xs="12"
                  sm="6"
                  align-self="start"
                >
                  <v-hover
                    v-slot="{ hover }"
                    style="height: 100%;"
                  >
                    <v-card
                      :elevation="hover ? 10 : $vuetify.theme.dark ? 0 : 1"
                      :class="{'selected-card': selectedExtraProducts.includes(extraProduct.productId)}"
                      :outlined="$vuetify.theme.dark"
                      class="text-left pt-2 pb-6"
                      :disabled="servicesDisabled"
                      @click="selectExtraProduct(extraProduct)"
                    >
                      <extra-card
                        :extra-product="extraProduct"
                        :available="getTastingProductById(extraProduct.productId) != null
                          ? getTastingProductById(extraProduct.productId).availableAmount : null"
                      />
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <step-navigation
      v-if="loaded"
      :next-disabled="nextStepDisabled"
      :prev-disabled="false"
      @next="awardExcluded ? $router.push(`/producer/submit/summary`) : $router.push(`/producer/submit/selectCiders`)"
      @prev="$router.push(`/producer/submit/tastings`)"
    />

    <v-progress-circular
      v-if="!loaded"
      class="mt-auto mb-auto ml-auto mr-auto"
      indeterminate
      size="92"
    />
    <Confirm_dialog ref="confirm" />
  </v-container>
</template>

<script>
import {apiTyped} from "@/api";
import ProducerSubmitStepper from "@/components/producer/producer_submit_stepper";
import {sync} from 'vuex-pathify'
import StepNavigation from "@/components/producer/step_navigation";
import producer_submit_mixin from "@/components/mixins/producer_submit_mixin";
import PageNavigation from "@/components/page_navigation";
import {SubmitTastingStep} from "@/types/submitTastingStep";
import ParticipationCard from "@/components/producer/submit/award23/participation_card.vue";
import tasting_products_mixin from "@/components/mixins/tasting_products_mixin";
import Confirm_dialog from "@/components/confirm_dialog.vue";
import ExtraCard from "@/components/producer/submit/award23/extra_card.vue";

export default {
  name: "ProducerTastingAddTastingProducts",
  components: {ExtraCard, Confirm_dialog, ParticipationCard, PageNavigation, StepNavigation, ProducerSubmitStepper},
  mixins: [producer_submit_mixin, tasting_products_mixin],
  i18n: {
    messages: {
      de: {
        "hideDetails": "Details verbergen",
        "showDetails": "Details zeigen",
        "isParticipating": "Sie nehmen bereits am Award teil",
        "purchaseAdditionalAwards": "Hier klicken, um weitere Produkte am Award anzumelden"
      },
      en: {
        "hideDetails": "hide details",
        "showDetails": "show details",
        "isParticipating": "You are already participating at the award",
        "purchaseAdditionalAwards": "Click here to submit additional products to the award"
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    console.log("Before leave")
    if (this.selectedTasting == null || this.tastingsEmpty || this.matchesWhitelist(to.path)) {
      next();
      return;
    }
    console.log("Before leave2")
    const okayToLeave = await this.$refs.confirm.open(
        this.$t('unsavedChanges'), this.$t('unsavedChangesMessageProducerSubmit'),
        {color: 'secondary_card', thirdButton: true, thirdButtonText: this.$t('discard')}, {btnOkay: 'stay'}, true)
    if (okayToLeave === "third") next();
  },
  // TODO: Vuetify Item groups - documentation 2.7
  // submit.ts - state tastingproducts
  // AN Select ciders orientieren line 143 item group (ggf. 165)
  // TODO: WARENKORB wegmachen
  // TODO: Selected products unter computed in storesync machen -> wirklich analog zu selectedCiders
  // TODO: Routegards
  // TODO: Stepper anpassen (conditionally)
  data: () => ({
    showParticipationDetails: true,
    selectedParticipationType: null,
    selectedParticipationProduct: null,
    selectedBoothProduct: null,
    selectedExtraProducts: [],
    tastings: null,
    tastingPurchases: [],
    isParticipatingWithAward: false,
    servicesDisabled: false,
    lastSelectedTastingId: null,
    submittedCiders: null,
    paidProducts: [],
    loaded: false,
    selected_participation_type: {
      id: null,
      name: null,
      price: null,
      priceID: null
    },
    selected_booth: {
      id: null,
      name: null,
      price: null,
      priceID: null
    },
    selected_class: false,
    selected_bowl: false,
    cart: {
      participation: null,
      class: {
        name: null,
        price: null,
        priceID: null
      },
      bowl: {
        name: null,
        price: null,
        priceID: null
      },
    },
    selected_extras: {
      "champagne": false,
      "class": false
    },
    cart_items: {},
    cart_items_array: [],
  }),
  computed: {
    spotlightEnabled() {
      return this.selectedTasting.products.every(p => p.isLimited && p.availableAmount > 0);
    },
    awardExcluded() {
      return this.selectedTastingProducts.some(p => p.excludesAward) || false;
    },
    submitTastingSteps() {
      return SubmitTastingStep
    },
    nextStepDisabled() {
      if (this.selectedParticipationType == null) {
        return true;
      }
      if (this.selectedParticipationType === 'corporate' || this.selectedParticipationType === 'merchandise') {
        return true;
      }
      else if (this.selectedParticipationType === 'booth' || this.selectedParticipationType === 'merch') {
        return this.selectedBoothProduct == null;
      } else if (this.selectedParticipationType != null) {
        return false;  // merch or spotlight
      }
      return true;  // default
    },
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return true
        default:
          return false
      }
    },
    numberOfPaidProducts: sync('submitStore/numberOfPaidProducts'),
    selectedTasting: sync('submitStore/selectedTasting'),
    tastingPurchase: sync('submitStore/tastingPurchase'),
    selectedTastingProducts: sync('submitStore/selectedTastingProducts'),
    selectedCiders: sync('submitStore/selectedCiders'),
    selectedTastingId: {
      get() {
        return this.selectedTasting != null ? this.selectedTasting.id : null;
      },
      set(tastingId) {
        if (tastingId == null) this.selectedTasting = null;
        else this.selectedTasting = this.tastings.find(t => t.id === tastingId);
        // const selectedTastingIdx = this.tastings.findIndex(t => t.id === tastingId);
      }
    },
    tastingsEmpty() {
      return this.tastings == null || this.tastings.length === 0;
    },
  },
  watch: {
    selectedParticipationProduct: function () {
      if (!this.loaded) return;
      if (this.selectedParticipationType !== 'booth' && this.selectedParticipationType !== 'merch') {
        this.selectedTastingProducts = this.selectedTasting.products.filter(
          p => p.id === this.selectedParticipationProduct);
      }  // no extras if not booth
      else {
        const productIds = [...this.selectedExtraProducts];  // copy
        if (this.selectedBoothProduct != null) {
          productIds.push(this.selectedBoothProduct);
        }
        console.log("adding ids", productIds)
        this.selectedTastingProducts = this.selectedTasting.products.filter(p => productIds.includes(p.id));
      }
    },
    selectedBoothProduct: function (newValue, oldValue) {
      if (!this.loaded) return;
      if (oldValue != null) {
        console.log("oldValue", oldValue)
        this.selectedTastingProducts = this.selectedTastingProducts.filter(p => p.id !== oldValue);
      }
      if (newValue != null) {
        const newProduct = this.selectedTasting.products.find(p => p.id === newValue);
        if (this.selectedTastingProducts.find(p => p.id === newProduct.id) == null)
          this.selectedTastingProducts.push(newProduct);
      }
    },
    selectedExtraProducts: function (newValues, oldValues) {
      if (!this.loaded) return;
      // remove old
      console.log("oldValues", oldValues)
      this.selectedTastingProducts = this.selectedTastingProducts.filter(p => !oldValues.includes(p.id));
      // add new
      console.log("newValues", newValues)
      const newProducts = this.selectedTasting.products.filter(p => newValues.includes(p.id));
      for (const newProduct of newProducts) {
        if (this.selectedTastingProducts.find(p => p.id === newProduct.id) == null){
          this.selectedTastingProducts.push(newProduct);
        }
      }
    },
    selectedTasting: function (newTasting) {
      this.numberOfPaidProducts = this.submitCountFor(newTasting);
      console.log("Got submit Count", this.numberOfPaidProducts);
    }
  },
  async created() {
    this.tastings = await apiTyped.producerGetAvailableTastings(
      0, 1, 1, [this.selectedTasting.id], false);
    console.log("Got this.tastings", this.tastings, this.selectedTasting.id)
    this.selectedTasting = this.tastings.find(t => t.id === this.selectedTasting.id);  // update tasting data
    if (this.tastings != null && this.tastings.length > 0) {
      console.log("Get submitted")
      this.paidProducts = await apiTyped.producerGetPaidProducts(
          this.tastings.map(t => t.id), this.$store.state.producerStore.producerId);
      console.log("Get submitted")
      this.submittedCiders = await Promise.all(this.tastings.map(t => apiTyped.getSubmittedCidersForTasting(t.id)));
    }
    this.lastSelectedTastingId = this.selectedTasting != null ? this.selectedTasting.id : null;
    if (this.selectedTasting.tastingSettings.hasPurchasables) {
      console.log("Get products");
      this.selectedTasting.products = await apiTyped.producerGetTastingProducts(this.selectedTasting.id);
      console.log("Getting tasting purchase", this.selectedTasting);
      this.tastingPurchases = await apiTyped.producerGetTastingPurchases(this.selectedTasting.id) || [];
      if (this.tastingPurchases.length > 0) {
        this.isParticipatingWithAward = true;
      }
      // this assumes, that in every purchase there is one product that excludes an award.
      // If multiple purchases all have the exclude award == true, then isParticipatingWithAward is set to false
      if (this.tastingPurchases.every(
        purchase => purchase.purchasedProducts.some(
          product => product.tastingProduct.excludesAward))) {
        // purchases exclude award
        this.isParticipatingWithAward = false;
      }
      // this.tastingPurchase = await apiTyped.producerGetTastingPurchaseById(this.tastingPurchaseId);
      // console.log("Got tasting purchase", this.tastingPurchase)
      // if (this.tastingPurchase != null && this.tastingPurchase.status === 'paid') {
      //   // populate purchased products
      //   this.servicesDisabled = this.tastingPurchase.status === 'paid';
      //   const purchasedProductIds = this.tastingPurchase.purchasedProducts.map(p => p.tastingProduct.id);
      //   this.selectedTastingProducts = purchasedProductIds.map(p => { return { id: p } } )
      // }
      console.log("Got products", this.selectedTasting.products);
    }
    const selectedProductIds = this.selectedTastingProducts.map(p => p.id);
    console.log("selectedProductIds", selectedProductIds, this.selectedTastingProducts)
    const selectedParticipationProduct =
      this.participationProducts.find(p => selectedProductIds.includes(p.productId)) || null;
    if (selectedParticipationProduct) {
      this.selectedParticipationProduct = selectedParticipationProduct.productId;
      this.selectedParticipationType = selectedParticipationProduct.type;
    }
    const selectedBoothProduct = this.boothProducts.find(p => selectedProductIds.includes(p.productId)) || null;
    console.log("selectedBoothProduct", selectedBoothProduct)
    if (selectedBoothProduct != null) {
      this.selectedParticipationProduct = -1;
      this.selectedParticipationType = 'booth';
      this.selectedBoothProduct = selectedBoothProduct.productId;
    }
    const selectedExtraProducts = this.extraProducts.filter(p => selectedProductIds.includes(p.productId)) || null;
    if (selectedExtraProducts != null) {
      this.selectedExtraProducts = selectedExtraProducts.map(p => p.productId);
    }

    // check for availability
    const boothProduct = this.getTastingProductById(this.selectedBoothProduct);
    console.log("boothProduct", boothProduct)
    if (this.tastingPurchase != null && this.tastingPurchase.status !== 'paid'
      && boothProduct != null && boothProduct.availableAmount <= 0
    ) {
      this.selectedBoothProduct = null;
      this.selectedParticipationProduct = null;
      this.selectedParticipationType = null;
    }
    this.loaded = true;
  },
  methods: {
    async submitAdditionalProducts() {
      this.selectedExtraProducts = [];
      this.selectedTastingProducts = [];
      await this.$router.push('/producer/submit/selectCiders');
    },
    getTastingProductById(productId) {
      return this.selectedTasting.products.find(p => p.id === productId);
    },
    selectParticipationProduct(product) {
      this.selectedBoothProduct = null;
      console.log("product", product)
      if (!product.allowsAward) {
        this.$store.state.submitStore.selectedCiders = [];
        this.$store.state.submitStore.confirmedCiderIds = [];
      }
      if (this.selectedParticipationProduct === product.productId) {
        // clear
        this.selectedParticipationType = null;
        this.selectedParticipationProduct = null;
        this.showParticipationDetails = true;
      } else {
        this.selectedParticipationType = product.type;
        this.selectedParticipationProduct = product.productId;
        this.showParticipationDetails = this.selectedParticipationType !== 'booth';
      }
      if (this.selectedParticipationType === 'merch') {
        this.selectedCiders = [];
        // remove award products
        this.$store.state.submitStore.selectedProductAmount = 0;
      }
    },
    selectBoothProduct(booth) {
      if (this.selectedBoothProduct === booth.productId) {
        // clear
        this.selectedBoothProduct = null;
      } else {
        this.selectedBoothProduct = booth.productId;
      }
    },
    selectExtraProduct(product) {
      if (this.selectedExtraProducts.includes(product.productId)) {
        // clear
        this.selectedExtraProducts = this.selectedExtraProducts.filter(p => p !== product.productId);
      } else {
        this.selectedExtraProducts.push(product.productId);
      }
    },
    async reloadTasting(index) {
      await apiTyped.deleteSubmittedCidersForTasting(this.submittedCiders[index]);
      this.tastings = await apiTyped.producerGetAvailableTastings();
      this.submittedCiders = await Promise.all(this.tastings.map(t => apiTyped.getSubmittedCidersForTasting(t.id)));
      this.lastSelectedTastingId = null;
    },
    tastingDisabled(index) {
      if (this.submittedCiders != null && this.submittedCiders[index].length > 0) {
        return !(this.submittedCiders[index][0].status === 'draft'
            || this.submittedCiders[index][0].status === 'unpaid')
      }
      if (!this.producerMaySubmit(this.tastings[index])) return true;
      return false;
    },
    async myToggle(t, i) {
      if (this.tastingDisabled(i)) await this.$router.push(`/producer/submitted/overview/${this.tastings[i].id}`)
      else t();
    },
    producerMaySubmit(tasting) {
      if (tasting.tastingSettings.submissionNeedsPayment) {
        return this.paidProducts.find(p => p.tastingId === tasting.id && p.numberOfProducts > 0) != null;
      }
      return true;
    },
    submitCountFor(tasting) {
      if (tasting == null) return 0;
      if (tasting.tastingSettings.submissionNeedsPayment) {
        const producerPaidProducts = this.paidProducts.find(p => p.tastingId === tasting.id && p.numberOfProducts > 0);
        if (producerPaidProducts != null) {
          return Number(producerPaidProducts.numberOfProducts);
        }
        return null
      } else if (tasting.tastingSettings.maxCiders > 0) {
        return Number(tasting.tastingSettings.maxCiders);
      }
      return tasting.tastingSettings.maxCiders;
    }

  }
}
</script>

<style scoped>
.selected-card {
  border: 2px solid green;
}
.not-highlighted-card {
  margin-top: 32px;
  margin-bottom: 32px;
}
.highlighted-background {
  border-width: 3px;
  background: var(v--background-base) !important;
  //background: linear-gradient(42deg, var(v--card_background-base) 0%, var(v--primary-base) 80%) !important;
}
</style>