var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[(!_vm.noCiders)?_c('v-row',{staticClass:"mb-2 pt-2 align-center"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn-toggle',{staticClass:"ml-auto pr-0",attrs:{"mandatory":"","rounded":"","dense":""},model:{value:(_vm.dataMode),callback:function ($$v) {_vm.dataMode=$$v},expression:"dataMode"}},[_c('v-btn',{attrs:{"outlined":"","height":"40","value":"cards"}},[_c('v-icon',[_vm._v("mdi-view-grid")])],1),_c('v-btn',{attrs:{"outlined":"","height":"40","value":"table"}},[_c('v-icon',[_vm._v("mdi-menu")])],1)],1)],1),(_vm.dataMode === 'cards')?_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"mr-auto"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pr-0",staticStyle:{"text-transform":"unset"},attrs:{"text":"","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-sort-variant ")]),_vm._v(" "+_vm._s(_vm.sortByText)+" "),_c('v-btn',{staticClass:"ml-1 animate_change",class:{'rotate-180': !_vm.sortAsc},attrs:{"icon":""},on:{"click":function($event){_vm.sortAsc = !_vm.sortAsc}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-arrow-down")])],1)],1)]}}],null,false,2561512990)},[_c('v-list',[_c('v-subheader',[_vm._v(" "+_vm._s(_vm.$t('sortBy'))+" ")]),_vm._l((_vm.sortByList),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){_vm.sortBy = item.value; _vm.sortByText = item.text;}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)})],2)],1)],1)]):_vm._e(),_c('v-col',[_c('v-text-field',{staticClass:"ml-auto",staticStyle:{"max-width":"400px","min-width":"300px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"outlined":"","clearable":"","dense":"","rounded":"","single-line":"","hide-details":""},model:{value:(_vm.searchProducts),callback:function ($$v) {_vm.searchProducts=$$v},expression:"searchProducts"}})],1)],1):_vm._e(),(_vm.dataMode === 'cards')?_c('div',{staticClass:"pl-xl-8 pr-xl-8",staticStyle:{"background-color":"var(v--background-base)"}},[_c('v-row',{staticClass:"align-self-auto align-stretch"},_vm._l((_vm.cidersFiltered),function(cider){return _c('v-col',{key:cider.id,staticClass:"align-self-stretch align-stretch",staticStyle:{"cursor":"pointer"},attrs:{"cols":"12","xs":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticStyle:{"height":"100%"},on:{"click":function($event){return _vm.$emit('clickProduct', cider)}}},[_c('cider-card',{staticClass:"align-self-stretch",staticStyle:{"max-width":"100%","height":"100%"},attrs:{"cider":cider,"full-content":false,"elevation":hover ? 10 : 1}})],1)]}}],null,true)})],1)}),1)],1):_vm._e(),(_vm.dataMode === 'table' && !_vm.noCiders)?_c('div',{staticClass:"d-flex"},[_c('ProducerCiderTable',{attrs:{"ciders":_vm.ciders,"search-products":_vm.searchProducts},on:{"clickProduct":function (product) { return _vm.$emit('clickProduct', product); }}})],1):_vm._e(),(_vm.loaded && _vm.noCiders)?_c('v-container',{staticStyle:{"position":"relative","min-height":"60vh"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"center-on-page pb-4 ml-auto mr-auto",staticStyle:{"border-style":"dashed","border-width":"medium","cursor":"pointer","transition":"box-shadow 0.2s ease-in-out"},attrs:{"width":"300","outlined":"","elevation":hover ? 12 : 0},on:{"click":function($event){return _vm.$router.push('/producer/ciders/create')}}},[_c('v-icon',{attrs:{"size":"192"}},[_vm._v(" mdi-plus ")]),_c('v-card-subtitle',{staticClass:"pb-2"},[_vm._v(" "+_vm._s(_vm.$t('noProducts'))+" ")]),_c('v-btn',{staticClass:"ml-auto mr-auto text-center",attrs:{"rounded":"","elevation":"0","outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('addProduct'))+" ")])],1)]}}],null,false,1012113704)})],1):_vm._e(),_c('v-expand-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.loaded && _vm.moreProductsAvailable),expression:"loaded && moreProductsAvailable"},{name:"intersect",rawName:"v-intersect",value:(_vm.onLoadingButtonIntersect),expression:"onLoadingButtonIntersect"}],staticClass:"mt-4 mb-4 text-capitalize",attrs:{"text":"","large":"","outlined":"","loading":_vm.loadingMoreProducts},on:{"click":_vm.loadMoreProducts}},[_vm._v(" "+_vm._s(_vm.$t('loadMore'))+" ")])],1),(_vm.ciders != null && _vm.loaded)?_c('div',{staticClass:"d-flex justify-center pt-4"},[_c('span',{staticClass:"text--secondary text-body-2 pt-2 pb-4"},[_vm._v(" "+_vm._s(_vm.cidersFiltered.length)+" "+_vm._s(_vm.moreProductsAvailable ? '' : '/' + _vm.ciders.length)+" "+_vm._s(_vm.$tc('products', 2))+" "+_vm._s(_vm.$t('areDisplayed'))+" ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }