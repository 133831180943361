<!--uses locales-->
<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    rounded="xl"
    @keydown.esc="cancel"
  >
    <v-card
      rounded="xl"
      color="background"
      style="min-height: 200px;"
    >
      <v-card-title>
        {{ restart ? $t('paymentHandling.cancelSubmission') : $t('paymentHandling.restartSubmission') }}
      </v-card-title>
      <div
        v-if="tasting != null && ciders != null"
        style="max-height: 900px; overflow-y: auto"
      >
        <v-card-text
          class="pa-4 pb-2 pt-0 text-left pl-6 text--secondary"
        >
          {{ restart ? $t('paymentHandling.restartSubmission01') : $t('paymentHandling.cancelSubmission01') }}

          <br>
          <span class="pl-4">
            <strong>{{ tasting.name }}</strong>
          </span>
          <br>
          <template v-if="!restart">
            {{ $t('paymentHandling.cancelSubmission02') }}
            <br>
            <span
              v-for="(cider, index) in ciders"
              :key="cider.id"
              class="pa-4 pt-0 text-left pl-6"
            >
              <strong>
                {{ index+1 }}. {{ cider.ciderInfos.name }}
              </strong>
              <br>
            </span>
          </template>
        </v-card-text>
        <div
          class="pt-2 pb-3 pl-5 align-start justify-start d-flex ml-auto mr-auto"
          style="max-width: 450px;"
        >
          <v-icon
            left
            small
            style="opacity: 0.6;"
          >
            mdi-information-outline
          </v-icon>
          <span
            class="text-body-2 text--secondary text-left align-start justify-start pr-2"
          >
            {{ restart ? $t('paymentHandling.restartActionCanNotBeUndone') : $t('paymentHandling.actionCanNotBeUndone') }}
          </span>
        </div>

        <v-card-actions
          class="pt-0 d-flex"
          :class="{'flex-column-reverse': isSmallScreen}"
        >
          <v-spacer />
          <v-btn
            color="grey"
            text
            rounded
            :width="isSmallScreen ? '100%' : ''"
            @click.native="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            :color="restart ? 'primary darken-1' : 'error darken-1'"
            text
            rounded
            :width="isSmallScreen ? '100%' : ''"
            @click.native="agree"
          >
            {{ restart ? $t('paymentHandling.restartSubmission') : $t('paymentHandling.removeSubmission') }}
          </v-btn>
        </v-card-actions>
      </div>
      <v-progress-circular
        v-else
        indeterminate
        class="ml-auto mr-auto"
        size="32"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import {apiTyped} from "@/api";

export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    tasting: null,
    restart: false,
    ciders: null,
    valid: false,
    comment: '',
    options: {
      color: 'primary',
      width: 450,
      zIndex: 200,
      thirdButton: false,
      thirdButtonText: "",
    },
  }),
  computed:{
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true
        default:
          return false
      }
    }
  },
  methods: {
    async open(tasting, submittedCiders, restart = false) {
      const ciderIds = submittedCiders.map(s => s.cider).filter(c => c != null);
      this.tasting = tasting;
      this.restart = restart;
      this.dialog = true;
      if (ciderIds.length > 0) {
        this.ciders = await apiTyped.producerGetCiderByIds(this.$store.state.producerStore.producerId, ciderIds)
      } else {
        this.ciders = [...Array(submittedCiders.length)].map((x, index) => { return {
          ciderInfos: {
            name: this.$t('placeholder') + " " + Number(index + 1)
          }
        }});
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    async agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    },
  },
}
</script>

<style>
.v-dialog {
  border-radius: 24px !important;
}
</style>