import Vue from "vue";
import VueRouter from "vue-router";

import cider_table from "@/components/cider_table.vue";
import LayoutLogin from "@/layouts/login"
import LayoutTasting from "@/layouts/tasting"
import LayoutTastingDashboard from "@/layouts/tasting_dashboard"
import LayoutAdmin from "@/layouts/admin"
import LayoutProducer from "@/layouts/producer"
import LayoutService from "@/layouts/service"
import rating from "@/components/rating.vue";
import summary from "@/components/summary.vue";
import login from "@/components/login.vue";
import AdminLogin from "@/components/admin/admin_login";
import GesamtauswertungPrint from "@/components/admin/all_cider";
import admin_table_card from "@/components/admin/admin_table_card";
import gesamtbewertung_a4 from "@/components/admin/print/gesamtbewertung_a4";
import AdminDashboard from "@/components/admin/admin_dashboard";
import rangliste from "@/components/admin/rangliste/rangliste";
import TableDesignerLayout from "@/components/admin/tableDesigner/layout.vue";
import TableDesignerAddJudges from "@/components/admin/tableDesigner/add_judges.vue";
import Rangliste_a4 from "@/components/admin/print/rangliste_a4";
import Admin_users from "@/components/admin/admin_users";
import Admin_timeline from "@/components/admin/admin_timeline";
import Urkunde_a4 from "@/components/admin/print/urkunde_a4";
import Urkunde_a3 from "@/components/admin/print/urkunde_a3";
import login_form from "@/components/login/login_form";
import reset_password_form from "@/components/login/reset_password_form";
import new_password from "@/components/login/new_password";
import request_account from "@/components/login/request_account";
import accept_invite from "@/components/login/accept_invite";
import Producer_dashboard from "@/components/producer/producer_dashboard";
import Producer_edit_cider_page from "@/components/producer/producer_edit_cider_page";
import Producer_create_cider_page from "@/components/producer/producer_create_cider_page";
import Producer_submit_select_tasting from "@/components/producer/producer_submit_select_tasting";
import Producer_submit_select_tastingproducts from "@/components/producer/producer_submit_select_tastingproducts";
import Producer_submit_select_ciders from "@/components/producer/producer_submit_select_ciders";
import Producer_submit_confirm_ciders from "@/components/producer/producer_submit_confirm_ciders";
import Producer_edit_producer_data_page from "@/components/producer/producer_edit_producer_data_page";
import Producer_submit_summary from "@/components/producer/producer_submit_summary";
import Producer_submit_success from "@/components/producer/producer_submit_success";
import Producer_tasting_submitted_overview from "@/components/producer/producer_tasting_submitted_overview";
import Producer_submit_cancel from "@/components/producer/producer_submit_cancel";
import tasting_dashboard from "@/components/tasting_dashboard";
import table_view from "@/components/service/table_view";
import Producer_cider_view from "@/components/producer/producer_cider_view";
import Gesamtauswertung_print from "@/components/producer/print/gesamtauswertung_print";
import Certificate_a4 from "@/components/producer/print/certificate_a4";
import admin_debug from "@/components/admin/admin_debug";
import compare_product_results from "@/components/compare_product_results";
import admin_submit_cider from "@/components/admin/admin_submit_cider.vue";
import print_tasting_tables_view from "@/components/service/print_tasting_tables_view.vue";
import Manual_rating_judge_a4 from "@/components/admin/print/manual_rating_judge_a4.vue";
import Producer_tastings_tab from "@/components/producer/producer_tastings_tab.vue";
import All_purchases from "@/components/admin/purchases/all_purchases.vue";
import PurchaseDetail from "@/components/admin/purchases/purchaseDetail.vue";
import Submit_select_products from "@/components/producer/submit/submit_select_products.vue";
import submit_confirm_products from "@/components/producer/submit/submit_confirm_products.vue";
import submit_data_overview from "@/components/producer/submit/submit_data_overview.vue";

const { isNavigationFailure, NavigationFailureType } = VueRouter

Vue.use(VueRouter)


export const allRoutes = {
    tastings: {
        my: '/tastings/my',
        ciderList: '/tastings/:tastingAbbr/:tastingId/overview',
        compare: '/tastings/:tastingAbbr/:tastingId/compare',
        rating: '/tastings/:tastingAbbr/:tastingId/rating/:flightsCidersId',
        summary: '/tastings/:tastingAbbr/:tastingId/summary/:flightsCidersId',
    }
}

export function makeRoute(route, params) {
    return String(Object.entries(params).reduce(
        (routeResult, [paramName, paramValue]) => routeResult.replace(":" + paramName, paramValue), route));
}


const routes = [
    {
        path: allRoutes.tastings.my,
        component: LayoutTastingDashboard,
        children: [
            {
                name: 'Tastings Dashboard',
                path: '',
                component: tasting_dashboard,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: allRoutes.tastings.ciderList,
        component: LayoutTasting,
        children: [
            {
                name: 'Cider List',
                path: '',
                component: cider_table,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: allRoutes.tastings.compare,  // '/compare',
        component: LayoutTasting,
        children: [
            {
                name: 'Compare',
                path: '',
                component: compare_product_results,
                props: route => ({ products: route.query.product })
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: allRoutes.tastings.rating,
        component: LayoutTasting,
        children: [
            {
                name: 'Rating',
                path: '',
                component: rating,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: allRoutes.tastings.summary,
        component: LayoutTasting,
        children: [
            {
                name: 'Summary',
                path: '',
                component: summary,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/login',
        component: LayoutLogin,
        children: [
            {
                path: '',
                component: login,
                children: [
                    {
                        name: 'Login',
                        path: '',
                        component: login_form,
                    }
                ]
            },
        ],
        meta: {
            requiresAuth: false
        }
    },
    {
        path:'/login/reset',
        component: LayoutLogin,
        children: [
            {
                path: '',
                component: login,
                children: [
                    {
                        name: 'Login Reset Password',
                        path: '',
                        component: reset_password_form,
                    }
                ]
            },
        ],
        meta: {
            requiresAuth: false
        }
    },
    {
        path:'/newPassword',
        component: LayoutLogin,
        children: [
            {
                path: '',
                component: login,
                children: [
                    {
                        name: 'New Password',
                        path: '',
                        component: new_password,
                    }
                ]
            },
        ],
        meta: {
            requiresAuth: false
        }
    },
    {
        path:'/invite',
        component: LayoutLogin,
        children: [
            {
                path: '',
                component: login,
                children: [
                    {
                        name: 'Accept Invite',
                        path: '',
                        component: accept_invite,
                    }
                ]
            },
        ],
        meta: {
            requiresAuth: false
        }
    },
    {
        path:'/requestAccount',
        component: LayoutLogin,
        children: [
            {
                name: 'Request Account',
                path: '',
                component: request_account,
            },
        ],
        meta: {
            requiresAuth: false
        }
    },
    {
        path:'/admin/login',
        component: LayoutLogin,
        children: [
            {
                name: 'Admin Login',
                path: '',
                component: AdminLogin,
            },
        ],
        meta: {
            requiresAuth: false
        }
    },
    {
        path:'/admin/home',
        component: LayoutAdmin,
        children: [
            {
                name: 'Dashboard',
                path: '',
                component: AdminDashboard,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/admin/tables',
        component: LayoutAdmin,
        children: [
            {
                name: 'Tables',
                path: '',
                component: admin_table_card,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/admin/ciders',
        component: LayoutAdmin,
        children: [
            {
                name: 'All Ciders',
                path: '',
                component: GesamtauswertungPrint,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/admin/judges',
        component: LayoutAdmin,
        children: [
            {
                name: 'All Judges',
                path: '',
                component: Admin_users,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/admin/purchases',
        component: LayoutAdmin,
        children: [
            {
                name: 'All Purchases',
                path: '',
                component: All_purchases,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/admin/purchase/:purchaseId',
        component: LayoutAdmin,
        children: [
            {
                name: 'All Purchases',
                path: '',
                component: PurchaseDetail,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/admin/timeline',
        component: LayoutAdmin,
        children: [
            {
                name: 'Timeline',
                path: '',
                component: Admin_timeline,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/admin/submit-product',
        component: LayoutAdmin,
        children: [
            {
                name: 'Submit Product',
                path: '',
                component: admin_submit_cider,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/admin/table-designer',
        component: LayoutAdmin,
        children: [
            {
                name: 'Table Designer',
                path: '',
                component: TableDesignerLayout,
                children: [
                    {
                        name: 'Add Judges',
                        path: '/admin/table-designer/add-judges',
                        component: TableDesignerAddJudges,
                    }
                ]
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        // path:'/gesamtbewertung_a4/:table/:flight/:cider',
        path: '/admin/print/gesamtbewertung_a4',
        name: 'Gesamtbewertung A4',
        component: gesamtbewertung_a4,
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/admin/ranking',
        component: LayoutAdmin,
        children: [
            {
                name: 'Ranking',
                path: '',
                component: rangliste,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin/print/ranking_a4',
        name: 'Ranglist Druckansicht A4',
        component: Rangliste_a4,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin/print/urkunde_a4',
        name: 'Urkunde Druckansicht A4',
        component: Urkunde_a4,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin/print/urkunde_a3',
        name: 'Urkunde Druckansicht A3',
        component: Urkunde_a3,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin/print/manual-rating',
        name: 'Manual Rating Sheet',
        component: Manual_rating_judge_a4,
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/admin/debug',
        component: LayoutAdmin,
        children: [
            {
                path: '',
                component: admin_debug,
            },
        ],
        meta: {
            requiresAuth: false
        }
    },
    {
        path:'/producer/home',
        component: LayoutProducer,
        children: [
            {
                name: 'Producer Home',
                path: '',
                component: Producer_dashboard,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/producer/ciders/create',
        component: LayoutProducer,
        children: [
            {
                name: 'Create Cider',
                path: '',
                component: Producer_create_cider_page,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/producer/print/cider/overview',
        name: 'Print Cider Overview',
        component: Gesamtauswertung_print,
        meta: {
            requiresAuth: false
        }
    },
    {
        path:'/producer/print/cider/certificateA4',
        name: 'Print Cider Overview',
        component: Certificate_a4,
        meta: {
            requiresAuth: false
        }
    },
    {
        path:'/producer/ciders/view/:ciderId',
        component: LayoutProducer,
        children: [
            {
                name: 'View Cider',
                path: '',
                component: Producer_cider_view,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/producer/ciders/edit/:ciderId',
        component: LayoutProducer,
        children: [
            {
                name: 'Edit Cider',
                path: '',
                component: Producer_edit_cider_page,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/producer/submit-data/select-products',
        component: LayoutProducer,
        children: [
            {
                name: 'Select Product Data',
                path: '',
                component: Submit_select_products,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/producer/submit-data/confirm-products',
        component: LayoutProducer,
        children: [
            {
                name: 'Confirm Product Data',
                path: '',
                component: submit_confirm_products,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/producer/submit-data/overview',
        component: LayoutProducer,
        children: [
            {
                name: 'Summary Product Data',
                path: '',
                component: submit_data_overview,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/producer/submit/tastings',
        component: LayoutProducer,
        children: [
            {
                name: 'Add Ciders to Tasting',
                path: '',
                component: Producer_submit_select_tasting,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/producer/submit/selectTastingProducts',
        component: LayoutProducer,
        children: [
            {
                name: 'Select Tasting Products and Services for the Award Show',
                path: '',
                component: Producer_submit_select_tastingproducts,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/producer/submit/selectCiders',
        component: LayoutProducer,
        children: [
            {
                name: 'Select Products for Tasting',
                path: '',
                component: Producer_submit_select_ciders,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/producer/submit/confirmData',
        component: LayoutProducer,
        children: [
            {
                name: 'Confirm Cider Data',
                path: '',
                component: Producer_submit_confirm_ciders,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/producer/submit/summary',
        component: LayoutProducer,
        children: [
            {
                name: 'Submit Summary',
                path: '',
                component: Producer_submit_summary,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/producer/submit/success',
        component: LayoutProducer,
        children: [
            {
                name: 'Success submitting Products',
                path: '',
                component: Producer_submit_success,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/producer/submit/cancel',
        component: LayoutProducer,
        children: [
            {
                name: 'Cancelled Product Payment',
                path: '',
                component: Producer_submit_cancel,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/producer/edit/information',
        component: LayoutProducer,
        children: [
            {
                name: 'Edit Producer Information',
                path: '',
                component: Producer_edit_producer_data_page,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/producer/tastings',
        component: LayoutProducer,
        children: [
            {
                name: 'Tastings',
                path: '',
                component: Producer_tastings_tab,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/producer/submitted/overview/:tastingId',
        component: LayoutProducer,
        children: [
            {
                name: 'Overview of submitted Ciders',
                path: '',
                component: Producer_tasting_submitted_overview,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/service/table_overview',
        component: LayoutService,
        children: [
            {
                name: 'Overview of Tables',
                path: '',
                component: table_view,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/service/print-tables',
        component: LayoutService,
        children: [
            {
                name: 'Overview of Tables',
                path: '',
                component: print_tasting_tables_view,
            },
        ],
        meta: {
            requiresAuth: true
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    routes, // short for `routes: routes`
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})

router.beforeEach( async (to, from, next) => {
    try {
        if (!to.matched.some(record => record.path)) {
            next('/login')
        } else {
            if (to.matched.some(record => record.meta.requiresAuth)) {
                // const is_logged_in = await api.login(store.state.user.login, store.state.user.password)
                // TODO
                // const is_logged_in = apiTyped.isLoggedIn();
                // console.log("Is logged in ", is_logged_in)
                // if (!is_logged_in) {
                //     next('/login')
                // }
                next();
            } else {
                next();
            }
        }
    } catch (failure) {
        console.log("Failire")
        if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
            console.error(failure)
        }
    }
});

export default router