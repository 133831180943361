<!--uses lcoales-->
<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    rounded="xl"
    @keydown.esc="cancel"
  >
    <v-card
      rounded="xl"
      color="background"
    >
      <v-card-title>
        {{ $t('paymentHandling.refundSubmitted') }}
      </v-card-title>
      <div
        v-if="tasting != null && ciders != null"
        style="max-height: 900px; overflow-y: auto"
      >
        <v-card-text
          class="pa-4 pb-5 pt-0 text-left pl-6 text--secondary"
        >
          {{ $t('paymentHandling.sureYouRefund01') }}
          <br>
          <span class="pl-4">
            <strong>{{ tasting.name }}</strong>
          </span>
          <br>
          <template v-if="ciders.length > 0">
            {{ $t('paymentHandling.sureYouRefund02') }}
            <br>
            <span
              v-for="(cider, index) in ciders"
              :key="cider.id"
              class="pa-4 pt-0 text-left pl-6"
            >
              <strong>{{ index+1 }}. {{ cider.ciderInfos.name }}</strong><br>
            </span>
          </template>
          <template v-if="purchase != null">
            {{ $t('paymentHandling.sureYouRefund03', {purchaseDate: purchase.dateCreated.toLocaleString()}) }}
            <br>
            <span
              v-for="(cider, index) in ciders"
              :key="cider.id"
              class="pa-4 pt-0 text-left pl-6"
            >
              <strong>{{ index+1 }}. {{ cider.ciderInfos.name }}</strong><br>
            </span>
          </template>
        </v-card-text>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-textarea
            v-model="comment"
            :rules="[v => !!v || 'Please provide a short comment']"
            outlined
            label="Comment"
            class="ml-5 mr-5"
            rows="3"
          />
        </v-form>
        <div
          class="pt-2 pb-3 pl-5 align-start justify-start d-flex ml-auto mr-auto"
          style="max-width: 450px;"
        >
          <v-icon
            left
            small
            style="opacity: 0.6;"
          >
            mdi-information-outline
          </v-icon>
          <span
            class="text-body-2 text--secondary text-left align-start justify-start"
          >
            {{ $t('paymentHandling.informationRefund') }}
          </span>
        </div>
      </div>
      <v-card-actions
        class="pt-0 d-flex"
        :class="{'flex-column-reverse': isSmallScreen}"
      >
        <v-spacer />
        <v-btn
          rounded
          color="grey"
          text
          :width="isSmallScreen ? '100%' : ''"
          @click.native="cancel"
        >
          {{ $t('paymentHandling.cancel') }}
        </v-btn>
        <v-btn
          rounded
          color="primary darken-1"
          text
          :width="isSmallScreen ? '100%' : ''"
          @click.native="agree"
        >
          {{ $t('paymentHandling.refund') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    tasting: null,
    ciders: null,
    purchase: null,
    valid: false,
    comment: '',
    options: {
      color: 'primary',
      width: 450,
      zIndex: 200,
      thirdButton: false,
      thirdButtonText: "",
    },
  }),
  computed:{
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true
        default:
          return false
      }
    }
  },
  methods: {
    open(tasting, ciders, purchase = null) {
      this.ciders = ciders;
      this.purchase = purchase;
      this.tasting = tasting;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    async agree() {
      await this.$refs.form.validate();
      if (this.valid) {
        this.resolve(this.comment);
        this.dialog = false;
      }
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    },
  },
}
</script>

<style>
.v-dialog {
  border-radius: 24px !important;
}
</style>