<template>
  <v-container
    fluid
    class="d-flex flex-column"
  >
    <cider-card
      ref="ciderCardRef"
      :cider="cider"
      :full-content="true"
    />
  </v-container>
</template>

<script>
import {CProducerCiderResults} from "@/types/producerCider";
import CiderCard from "@/components/cider_card";

export default {
  name: "ProducerCiderInfosCard",
  components: {CiderCard},
  props: {
    cider: { type: [Object, CProducerCiderResults], required: true, default: null },
    hideYears: {type: Boolean, required: false, default: false},
    selectRevision: {type: Number, required: false, default: null},
  },
}
</script>

<style scoped>

</style>