export default {
    methods: {
        getTranslation(translationMessages, key, lang = null, defaultText = "translation error") {
            if (lang == null) lang = this.$store.getters['usersStore/getUserLanguage']();
            if (translationMessages == null || translationMessages === {}) return "no translations";
            if (!translationMessages.hasOwnProperty(lang)) {
                lang = lang.split("-")[0]  // fallback for e.g. "de-DE" instead of only "de"
            }
            if (translationMessages.hasOwnProperty(lang) && translationMessages[lang].hasOwnProperty(key)) {
                return translationMessages[lang][key];
            } else {
                try {
                    return translationMessages['de-DE'][key];
                } catch {
                    try {
                        return translationMessages[Object.keys(translationMessages)[0]][key];
                    } catch {
                        return defaultText
                    }
                }
            }
        },
    },
}