import {
    Bewertungskategorie,
    createBewertungskategorieFromDatabase
} from "./bewertungskategorie";
import {components, MyCollections} from "@/api/types/my-collections";
import {createMultiLocaleMessages} from "@/locales/createLocaleMessageObject";
import {Bewertung} from "@/types/bewertung";
import {createTranslationMessagesFromDb, TranslationMessages} from "@/types/translationMessages";

interface Eigenschaft {
    eigenschaftId: number;
    bezeichnung: string;
    info: string;
    faktor: number;
    bewertungskategorie?: Bewertungskategorie;
    translations: EigenschaftTranslations[] | undefined;
    translationMessages: TranslationMessages | null;
    sort: number;
}

interface EigenschaftTranslations {
    lang: string;
    info: string;
    bezeichnung: string;
}


export function createEigenschaftFromDatabase(
    eigenschaft: MyCollections["eigenschaften"], sort = -1
): Eigenschaft {
    let translations = undefined;
    if (eigenschaft.translations?.every(t => typeof t !== "number")) {
        const eigenschaft_translations =
            eigenschaft.translations as components["schemas"]["ItemsEigenschaftenTranslations"][];
        translations = eigenschaft_translations?.map(t => {
            if ((t.languages_code as components["schemas"]["ItemsLanguages"]) !== null)
                return <EigenschaftTranslations>{
                    lang: (t.languages_code as components["schemas"]["ItemsLanguages"]).code?.substring(0, 2),
                    info: t.info,
                    bezeichnung: t.bezeichnung,
                }
        });
    }
    // noinspection UnnecessaryLocalVariableJS
    const newEigenschaft = <Eigenschaft>{
        eigenschaftId: Number(eigenschaft.id),
        bezeichnung: eigenschaft.bezeichnung,
        info: eigenschaft.info,
        faktor: Number(eigenschaft.faktor),
        bewertungskategorie: eigenschaft.bewertungskategorien?.map(bewertungskategorie =>
            createBewertungskategorieFromDatabase(bewertungskategorie as MyCollections["bewertungskategorien"])),
        translations: translations,
        translationMessages: createTranslationMessagesFromDb(eigenschaft.translations as any[]),
        sort: sort,
    }
    return newEigenschaft;
}

export function createLocaleMessagesForEigenschaft(eigenschaft: Eigenschaft | Bewertung): any[] {
    const messageObjects = createMultiLocaleMessages(eigenschaft.translations,
        getEigenschaftTranslationPrefix(eigenschaft))
    return messageObjects;
}

export function getEigenschaftTranslationPrefix(eigenschaft: Eigenschaft | Bewertung): string {
    return "eigenschaft_" + String(eigenschaft.eigenschaftId) + "_";
}

export { Eigenschaft, EigenschaftTranslations };