export interface TranslationMessages {
    [key: string]: {  // e.g. 'de'
        [key: string]: string;  // e.g. 'point': 'Punkt | Punkte'
    };
}

export function createTranslationMessagesFromDb(translations: any[] | null): TranslationMessages | null {
    if (translations == null) return null;
    const translationMessages: any = {};
    for (const translationItem of translations) {
        if (translationItem.languages_code == null) continue;  // if language was deleted
        // translationMessages[translationItem.languages_code.split("-")[0]] = translationItem;
        let langCode;
        if (translationItem.languages_code.hasOwnProperty("code")) {  // deep query, languages_code is object
            langCode = translationItem.languages_code.code;
        } else {
            langCode = translationItem.languages_code;
        }
        translationMessages[langCode] = translationItem;
        delete translationMessages[langCode]["languages_code"];
    }
    return translationMessages as TranslationMessages;
}