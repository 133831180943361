export default {
  methods: {
    getNumberOfAwards(purchases) {
      return purchases.reduce(
        (partialSum, purchase) => partialSum += purchase.purchasedProducts.reduce(
          (partialSum2, product) => partialSum2 += product.tastingProduct.includesAwards * product.amount, 0), 0);
    },
    formatCurrency(amount, lang = 'de-DE') {
      if (amount == null) return "-";
      let euroFormat = new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: 'EUR',
      });
      return euroFormat.format(amount);
    },
    invoiceStatusColor(status) {
      switch (status) {
        case "draft":
          return "divider";
        case "published":
          return "primary";
        case "posted":
          return "secondary";
        case "correction":
          return "error";
        case "archived":
          return "#1b90ff";
      }
    },
    invoiceStatusText(status) {
      switch (status) {
        case "draft":
          return "Draft";
        case "published":
          return "Published";
        case "posted":
          return "Posted";
        case "correction":
          return "Correction";
        case "archived":
          return "Archived";
      }
    },
    invoiceStatusIcon(status) {
      if (status === 'draft') {
        return "mdi-file-edit";
      } else if (status === 'published') {
        return "mdi-check";
      } else if (status === 'posted') {
        return "mdi-check-decagram";
      } else if (status === 'correction') {
        return "mdi-keyboard-return";
      } else if (status === 'archived') {
        return "mdi-archive";
      }
    },
    statusColor(status) {
      switch (status) {
        case "draft":
        case "unpaid":
          return "divider";
        case "paid":
          return "primary";
        case "request_refund":
          return "error";
        case "refunded":
          return "#1b90ff";
      }
    },
    statusText(status) {
      switch (status) {
        case "draft":
          return "Draft";
        case "unpaid":
          return "Unpaid";
        case "paid":
          return "Paid";
        case "request_refund":
          return "Refund Request";
        case "refunded":
          return "Refunded";
      }
    },
    statusIcon(status) {
      switch (status) {
        case "draft":
          return "mdi-file-edit";
        case "unpaid":
          return "mdi-credit-card-clock-outline";
        case "paid":
          return "mdi-credit-card-check";
        case "request_refund":
          return "mdi-credit-card-refund";
        case "refunded":
          return "mdi-cash-refund";
      }
    },
  }
}