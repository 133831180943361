import {components, MyCollections} from "@/api/types/my-collections";
import {createTastingFromDatabase, Tasting} from "@/types/tasting";
// import {Table} from "@/types/table";
// import {type} from "os";

type UserId = string;

enum RoleName {
    Admin = "Administrator",
    Chair = "Chair",
    Juror = "Juror",
    Producer = "Producer",
    Service = "Service",
    None = "None",
}

function getRoleNameFromString(str: string | undefined | null): RoleName {
    if (str === null || str === undefined) return RoleName.Juror;
    switch (str.toLowerCase()) {
        case "administrator":
            return RoleName.Admin;
        case "chair":
            return RoleName.Chair;
        case "juror":
            return RoleName.Juror;
        case "produzent":
            return RoleName.Producer;
        case "service staff":
            return RoleName.Service;
        // Default Unknown / None Role
        default:
            return RoleName.None;
    }
}

export enum Theme {
    Auto = "auto",
    Light = "light",
    Dark = "dark",
}

export function getThemeFromString(str: string | undefined | null): Theme {
    if (str === null || str === undefined) return Theme.Light;
    switch (str.toLowerCase()) {
        case "auto":
            return Theme.Auto;
        case "light":
            return Theme.Light;
        case "dark":
            return Theme.Dark;
        // Default light theme
        default:
            return Theme.Light;
    }
}

type UserSettings = {
    tourTableComplete: boolean;
    tourRatingComplete: boolean;
    tourSummaryComplete: boolean;
    adminDashboardGridLayout: any | null;
    easterEggActive: boolean;
}

export function createUserSettingsFromDatabase(userSettings: MyCollections["user_settings"]) {
    return <UserSettings>{
        tourTableComplete: userSettings.tour_table_complete,
        tourRatingComplete: userSettings.tour_rating_complete,
        tourSummaryComplete: userSettings.tour_summary_complete,
        adminDashboardGridLayout: (userSettings.admin_dashboard_grid_layout !== null
                && userSettings.admin_dashboard_grid_layout !== undefined)
            ? userSettings.admin_dashboard_grid_layout
            : null,
        easterEggActive: userSettings.easter_egg_active,
    }
}

class User {
    id: UserId;
    firstName: string;
    lastName: string;
    email?: string;
    password?: string;
    location?: string | null;
    title?: string | null;
    description?: string | null;
    tags?: string[] | null | undefined;
    avatar: components["schemas"]["Files"] | string | null | undefined;
    language: string | undefined;
    role?: RoleName;
    roleData: components["schemas"]["Roles"] | null;
    theme?: Theme;
    tfa_secret?: string | null;
    currentTasting: Tasting | null;
    userSettings: UserSettings | undefined;
    easterEggActive: boolean;

    constructor(directusUser: components["schemas"]["Users"]) {
        if (directusUser.id === undefined) throw TypeError("User has no id" + directusUser);
        this.id = directusUser.id;
        this.firstName = directusUser.first_name || "";
        this.lastName = directusUser.last_name || "";
        this.email = directusUser.email;
        this.password = "";
        this.location = directusUser.location;
        this.description = directusUser.description;
        this.tags = directusUser.tags;
        this.avatar = directusUser.avatar;
        this.language = directusUser.language ? directusUser.language : "en-US";
        this.theme = getThemeFromString(directusUser.theme);
        this.tfa_secret = directusUser.tfa_secret;
        this.role = directusUser.role !== undefined && typeof directusUser.role !== "string"
            ? getRoleNameFromString(directusUser.role?.name) : RoleName.Juror;
        this.roleData = directusUser.role !== undefined && typeof directusUser.role !== "string" ?
            directusUser.role : null;
        this.currentTasting = (typeof directusUser.current_tasting !== "number"
            && directusUser.current_tasting !== null && directusUser.current_tasting !== undefined) ?
            createTastingFromDatabase(directusUser.current_tasting) : null;
        this.userSettings = directusUser.user_settings != null && typeof directusUser.user_settings[0] !== "string"
            ? createUserSettingsFromDatabase(directusUser.user_settings as any as MyCollections["user_settings"])
            : undefined;
        this.easterEggActive = directusUser.easter_egg_active || false;
    }
}

export function getUserId(userId: string | components["schemas"]["Users"] | undefined): UserId {
    if (userId === undefined) return "";
    return typeof userId === "string" ? userId : userId.id as UserId;
}

export { User, UserId, RoleName };