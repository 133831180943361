<template>
  <v-container
    fluid
    class="text-center center justify-center"
    style="margin: 0 auto;"
  >
    <alert-loading-error
      v-show="error"
      :errors="errors"
    />

    <!--    <Alert_loading_error-->
    <!--      v-if="error"-->
    <!--    />-->
    <!--    <h1-->
    <!--      style="font-weight: normal"-->
    <!--      class="mb-1"-->
    <!--    >-->
    <!--      Zusammenfassung-->
    <!--    </h1>-->

    <Confirm_dialog ref="confirm" />


    <PageNavigation
      :title-translation="currentTasting.name"
      :breadcrumbs="[{text: $t('NavJuror.allTastings'), to: allRoutes.tastings.my},
                     {text: $t('NavJuror.productOverview'),
                      to: makeTastingRoute(allRoutes.tastings.ciderList, currentTasting)},
                     {text: $t('NavJuror.ratingOf', {number: currentCider.displayId}),
                      to: makeRoute(makeTastingRoute(allRoutes.tastings.rating, currentTasting), 
                                    {flightsCidersId: currentCider.flightsCidersId})},
                     {text: $t('NavJuror.summaryOf', {number: currentCider.displayId}), to: '', disabled: true}]"
      class="pb-6 pl-md-3 pl-0"
    />


    <CiderRatingStepper
      ref="ciderRatingStepper"
      :marked-finished="markedFinished"
      active-step="3"
    />

    <CiderInfoExpPanel
      ref="ciderInfoExpPanel"
      :flights-ciders-id="currentCider.flightsCidersId"
      :marked-finished="markedFinished"
      :is-summary="true"
      @updateChairNotesFieldFinishedTrue="updateChairNotesFieldFinishedTrue"
    />

    <v-slide-y-reverse-transition>
      <v-btn
        v-if="showHomeBtn && !hideToSummaryButton"
        fixed
        bottom
        left
        rounded
        :fab="isFabSmallScreen()"
        color="primary"
        elevation="9"
        style="z-index: 99"
        @click="toList()"
      >
        <span
          v-if="!isFabSmallScreen()"
          style="color: var(--v-primary_text-base)"
        >
          {{ $t('toCiderList') }}
        </span>
        <v-icon
          v-else
          color="primary_text"
        >
          mdi-arrow-left
        </v-icon>
      </v-btn>
    </v-slide-y-reverse-transition>

    <v-container
      v-intersect="{
        handler: onIntersect,
        options: {
          threshold: [1]
        }
      }"
      class="d-flex flex-column justify-center pl-0 pl-md-3"
      style="max-width: 1800px"
    >
      <span
        class="d-flex justify-start ml-0 ml-md-4 mt-4 text-left text-h5"
        style="color: var(--v-background_text-base)"
      >
        {{ $t('Summary.headerTextRatingsComments') }}
      </span>
    </v-container>

    <v-row
      v-show="loading"
      v-masonry="'masonrySkeletonComments'"
      transition-duration="0.3s"
      class="pa-0 ma-auto justify-center align-center"
      :style="{'max-width': widthCommentsMasonry}"
    >
      <v-col
        v-for="id in [-10, -20, -30]"
        :key="id"
        class="justify-center"
        justify="center"
        align="center"
        cols="12"
        xl="3"
        md="4"
        sm="6"
        xs="12"
        @load="$redrawVueMasonry('masonrySkeletonComments')"
      >
        <v-skeleton-loader
          class="elevation-2 pa-0 ma-0 rounded-xl"
          type="card"
          style="max-width: 300px; min-height: 106px; max-height: 106px;"
        />
      </v-col>
    </v-row>
    
    <v-row
      v-show="!loading && currentCider !== null"
      id="summaryComments"
      v-masonry="'masonryComments'"
      horizontal-order="true"
      transition-duration="0.3s"
      class="pa-0 ma-auto justify-center align-center"
      :style="{'max-width': widthCommentsMasonry}"
    >
      <v-col
        v-for="(note, userKey) in currentCider.allNotes"
        :key="`note_${userKey}`"
        class="justify-center ma-0 px-0 px-md-3"
        justify="center"
        align="center"
        cols="12"
        xl="3"
        md="4"
        sm="6"
        xs="12"
        @load="$redrawVueMasonry('masonryComments')"
      >
        <v-card
          v-if="note != null"
          class="mx-auto justify-center"
          color="secondary_card"
          :max-width="$vuetify.breakpoint.name === 'xs' ? '600px' : '325px'"
          align="center"
          dark
        >
          <div
            v-if="finishedUserIds.includes(userKey)"
            style="position: absolute; left: 12px; top: 16px"
          >
            <v-icon
              v-if="note != null && note.isFavourite"
              class="ml-2"
            >
              mdi-heart
            </v-icon>
            <v-icon
              v-if="note != null && note.isPinned"
              class="ml-2"
            >
              mdi-bookmark
            </v-icon>
          </div>
          <v-card-title class="pa-2" />
          <juror-avatar
            v-if="!finishedUserIds.includes(userKey) && getUserById(userKey) != null"
            :user="getUserById(userKey)"
            :size="56"
          />
          <!--          <v-avatar-->
          <!--            v-if="!finishedUserIds.includes(userKey)"-->
          <!--            size="56"-->
          <!--            class="elevation-2"-->
          <!--          >-->
          <!--            <v-img-->
          <!--              alt="Avatar"-->
          <!--              :src="getUserAvatar(200, userKey)"-->
          <!--            />-->
          <!--          </v-avatar>-->
          <v-badge
            v-else
            avatar
            overlap
            offset-x="15"
            offset-y="15"
            label=""
            :color="showBadge(getPointsForJuror(userKey)) ? 'white' : 'transparent'"
          >
            <template #badge>
              <v-avatar
                v-if="showBadge(getPointsForJuror(userKey)) && finishedUserIds.includes(userKey)"
                size="56"
                class="elevation-5"
              >
                <v-img
                  v-if="finishedUserIds.includes(userKey)"
                  :src="getBadgeUrl(100, getPointsForJuror(userKey))"
                  style="z-index: 9;"
                />
              </v-avatar>
            </template>
            <juror-avatar
              v-if="getUserById(userKey) != null"
              :user="getUserById(userKey)"
              :size="56"
            />
            <!--            <v-avatar-->
            <!--              size="56"-->
            <!--              class="elevation-2"-->
            <!--            >-->
            <!--              <v-img-->
            <!--                alt="Avatar"-->
            <!--                :src="getUserAvatar(200, userKey)"-->
            <!--              />-->
            <!--            </v-avatar>-->
          </v-badge>

          <!--          <v-chip-->
          <!--            v-if="finishedUserIds.includes(userKey)"-->
          <!--            style="position: absolute; right: 12px; top: 16px; max-width: 65px"-->
          <!--            class="pl-2 pr-2 d-flex justify-center box animate_change"-->
          <!--            align="center"-->
          <!--            :outlined="$store.getters['badgesStore/getColor'](getPointsForJuror(userKey)) === null"-->
          <!--            :color="$store.getters['badgesStore/getColor'](getPointsForJuror(userKey)) !== null ?-->
          <!--              $store.getters['badgesStore/getColor'](getPointsForJuror(userKey)) : '#ffffff'"-->
          <!--            dark-->
          <!--            :text-color="'#' + $store.getters['badgesStore/getTextColor'](getPointsForJuror(userKey), $vuetify.theme.dark)"-->
          <!--            label-->
          <!--          >-->
          <!--            <v-icon-->
          <!--              small-->
          <!--              class="mr-1"-->
          <!--            >-->
          <!--              mdi-star-->
          <!--            </v-icon>-->
          <!--            {{ getPointsForJuror(userKey) }}-->
          <!--          </v-chip>-->
          <ChipPoints
            v-if="finishedUserIds.includes(userKey)"
            style="position: absolute; right: 12px; top: 16px;"
            :points="getPointsForJuror(userKey)"
            :max-width="65"
            :dark-background="true"
            :round-to="0.05"
          />

          <div
            v-if="note != null && note.kommentar && finishedUserIds.includes(userKey)"
            class="d-flex flex-row justify-center"
          >
            <span
              v-if="note.kommentar"
              class="pt-0 pr-1"
            >
              ”
            </span>
            <v-card-subtitle
              class="pa-2 pb-0"
              style="text-align: center; max-width: 86%;"
              v-html="note.kommentar ? note.kommentar : $t('noComment')"
            />
            <span
              v-if="note.kommentar"
              class="pt-0 pl-1"
            >
              ”
            </span>
          </div>
          <v-card-subtitle
            v-if="!finishedUserIds.includes(userKey)
              && Object.keys(currentCider.allBewertungen).includes(userKey) && currentCider.allBewertungen[userKey].every(b => b.punkte != null)"
            class="pa-2 pb-0"
            style="white-space: pre; text-align: center;"
          >
            <span class="font-weight-light font-italic">{{ $t('notRatedComplete') }}</span>
          </v-card-subtitle>
          <v-card-subtitle
            v-if="!finishedUserIds.includes(userKey)
              && Object.keys(currentCider.allBewertungen).includes(userKey) && currentCider.allBewertungen[userKey].every(b => b.punkte == null)"
            class="pa-2 pb-0"
            style="white-space: pre; text-align: center;"
          >
            <span class="font-weight-light font-italic">{{ $t('notRatedAtAll') }}</span>
          </v-card-subtitle>

          <v-card-actions class="pt-1">
            <!--                <v-list-item>-->
            <!--                  <v-list-item-content>-->
            <!--                    <v-list-item-title>{{note.juror_id.first_name + " " + note.juror_id.last_name}}</v-list-item-title>-->
            <!--                  </v-list-item-content>-->
            <!--                  <v-list-item-content>-->
            <!--                    <v-chip outlined color="primary" label style="min-width: 70px" >-->
            <!--                      <v-icon small class="mr-1">mdi-star</v-icon>-->
            <!--                      120-->
            <!--                    </v-chip>-->
            <!--                  </v-list-item-content>-->
            <!--                </v-list-item>-->
            <!--                <v-row align="baseline">-->
            <!--                  <v-col align="left" cols="12" xs="8">-->

            <!--            <v-tooltip-->
            <!--              bottom-->
            <!--            >-->
            <!--              <template #activator="{ on, attrs }">-->
            <!--                <v-icon-->
            <!--                  left-->
            <!--                  small-->
            <!--                  class="pa-1 ml-3 mb-2 elevation-0"-->
            <!--                  style="background-color: var(&#45;&#45;v-background-base); border-radius: 99999px; opacity: 1"-->
            <!--                  :color="$store.getters['ciderStore/getCiderStateIconColorByState'](-->
            <!--                    $store.getters['ciderStore/getCiderRatingStateByNotes'](note, currentCider.chairNotes), $vuetify)[1]"-->
            <!--                  v-bind="attrs"-->
            <!--                  v-on="on"-->
            <!--                >-->
            <!--                  {{ $store.getters["ciderStore/getCiderStateIconColorByState"](-->
            <!--                    $store.getters['ciderStore/getCiderRatingStateByNotes'](note, currentCider.chairNotes), $vuetify)[0] }}-->
            <!--                </v-icon>-->
            <!--              </template>-->
            <!--              <span>{{ getCiderStateText($store.getters['ciderStore/getCiderRatingStateByNotes'](note, currentCider.chairNotes)) }}</span>-->
            <!--            </v-tooltip>-->
            <v-tooltip
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  left
                  dense
                  color="white"
                  class="pa-1 ml-3 mb-1 elevation-0"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $store.getters["ciderStore/getCiderStateIconColorByState"](
                    $store.getters['ciderStore/getCiderRatingStateByNotes'](note, currentCider.chairNotes), $vuetify)[0] }}
                </v-icon>
              </template>
              <span>{{ getCiderStateText($store.getters['ciderStore/getCiderRatingStateByNotes'](note, currentCider.chairNotes)) }}</span>
            </v-tooltip>

            <v-spacer />
            <span class="subtitle-2 mr-2 font-italic font-weight-light text-right">
              {{ getUserById(userKey, "firstName") + " " + getUserById(userKey, "lastName") }}
              <span
                v-if="$store.getters['tableStore/getChairFromTable']().id === userKey"
              >
                <strong>- Chair</strong>
              </span>
            </span>
            <!--                  </v-col>-->
            <!--                <v-spacer></v-spacer>-->
            <!--                <span class="mr-1">·</span>-->
            <!--                  <v-spacer></v-spacer>-->
            <!--                  <v-col cols="12" xs="4">-->

            <!--                    <v-chip outlined color="white" label >-->
            <!--                      <v-icon small class="mr-1">mdi-star</v-icon>-->
            <!--                      {{ note.points }}-->
            <!--                    </v-chip>-->

            <!--                  </v-col>-->
            <!--                </v-row>-->
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-container
      class="d-flex flex-column justify-center px-md-3 px-0"
      style="max-width: 1800px"
    >
      <span
        class="d-flex justify-start ml-md-4 ml-0 mt-4 text-left text-h5"
        style="color: var(--v-background_text-base)"
      >
        {{ $t('Summary.headerTextFinalComment') }}
      </span>
      <div
        class="pt-2 pb-0 pl-md-4 pl-0 align-start justify-start d-flex"
        style="max-width: 1000px;"
      >
        <v-icon
          left
          small
          style="opacity: 0.6;"
        >
          mdi-information-outline
        </v-icon>
        <span
          class="text-body-2 text--secondary text-left align-start justify-start"
        >
          {{ $t('Summary.provideFinalComment') }}
        </span>
      </div>
    </v-container>
    <v-row
      v-show="loading"
      v-masonry="'abschlusskommentarSkeleton'"
      transition-duration="0.3s"
      class="pa-0 ma-auto justify-start align-center"
      style="max-width: 2400px;"
    >
      <v-col
        class="justify-start px-md-3 px-0"
        justify="start"
        align="start"
        cols="12"
        xl="4"
        md="10"
        sm="12"
        xs="12"
        offset-xl="4"
        offset-md="1"
        @load="$redrawVueMasonry('abschlusskommentarSkeleton')"
      >
        <v-skeleton-loader
          class="elevation-2 pa-0 ma-0 rounded-xl"
          type="article"
        />
      </v-col>
    </v-row>
    <v-row
      v-show="!loading && currentCider !== null"
      id="summaryAbschlusskommentar"
      v-masonry="'masonryAbschlusskommentar'"
      class="pa-0 ma-0 mx-auto justify-center align-center"
      style="max-width: 2400px"
    >
      <v-col
        v-if="!loading && currentCider !== null"
        class="justify-center px-md-3 px-0"
        cols="12"
        xl="4"
        md="10"
        sm="12"
        xs="12"
        offset-xl="4"
        offset-md="1"
        @load="$redrawVueMasonry('masonryAbschlusskommentar')"
      >
        <!--    <v-row>-->
        <!--      <v-col>-->
        <v-card
          class="mx-auto"
          color="secondary_card"
          dark
        >
          <v-card-title
            class="text-h6 pa-3 pb-1 mb-1"
            style=""
          >
            {{ $t('finalComment') }}
          </v-card-title>
          <v-divider />
          <!--          <v-textarea-->
          <!--            v-model="currentCider.chairNotes.kommentar"-->
          <!--            class="ma-2"-->
          <!--            hide-details-->
          <!--            label="Please enter a comment"-->
          <!--            outlined-->
          <!--            full-width-->
          <!--            rows="3"-->
          <!--            :readonly="!$store.getters['usersStore/isChair']()"-->
          <!--          />-->
          <CommentEditor
            :readonly="!$store.getters['usersStore/isChair']() || !tastingIsRunning"
            :content="!$store.getters['usersStore/isChair']() && (currentCider.chairNotes == null || currentCider.chairNotes.kommentar == null)
              ? $t('Summary.chairNoComment') : currentCider.chairNotes.kommentar"
            background-color="secondary_card"
            @input="(changedInput) => {abschlusskommentarDraft = changedInput}"
          />
          <v-card-actions
            v-if="$store.getters['usersStore/isChair']()"
            class="pt-2"
          >
            <v-spacer />
            <v-btn
              v-if="$store.getters['usersStore/isChair']()"
              :disabled="!commentChanged || !tastingIsRunning"
              class="white--text animate_change"
              color="white"
              text
              rounded
              @click="kommentarSubmit()"
            >
              <span>{{ $t('save') }}</span>
              <v-progress-circular
                v-if="sending_comment"
                indeterminate
                color="white"
                style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);"
              />
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-container
      class="d-flex flex-column justify-center px-md-3 px-0"
      style="max-width: 1800px"
    >
      <span
        class="d-flex justify-start ml-md-4 ml-0 mt-4 text-left text-h5"
        style="color: var(--v-background_text-base)"
      >
        {{ $t('Summary.headerTextIndividualRatings') }}
      </span>
    </v-container>

    <v-row
      v-show="loading"
      v-masonry="'masonrySkeleton'"
      transition-duration="0.3s"
      class="pa-0 ma-auto justify-center align-center"
      :style="{'max-width': widthRatingMasonry}"
    >
      <v-col
        v-for="id in [-1, -2, -3, -4, -5, -6]"
        :key="id"
        class="justify-center px-md-3 px-0"
        justify="center"
        align="center"
        cols="12"
        :xl="showIndividual ? 4 : 3"
        :md="showIndividual ? 6 : 4"
        :sm="showIndividual ? 12 : 6"
        xs="12"
        @load="$redrawVueMasonry('masonrySkeleton')"
      >
        <v-skeleton-loader
          class="elevation-2 pa-0 ma-0 rounded-xl"
          type="table-heading, divider, list-item@4"
          style="max-width: 600px"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="!loading && currentCider !== null"
      id="masonrySummary"
      v-masonry="'masonry_summary'"
      transition-duration="0.3s"
      class="pa-0 ma-auto justify-center align-center"
      :style="{'max-width': widthRatingMasonry}"
    >
      <!--suppress HtmlDeprecatedAttribute -->
      <v-col
        v-for="(bewertungsKategorie, index) in currentCider.kategorie.bewertungskategorien"
        :key="bewertungsKategorie.pruefmerkmal"
        class="justify-center px-md-3 px-0"
        justify="center"
        align="center"
        cols="12"
        :xl="showIndividual ? 4 : 3"
        :md="showIndividual ? 6 : 4"
        :sm="showIndividual ? 12 : 6"
        xs="12"
        @load="$redrawVueMasonry('masonry_summary')"
      >
        <v-card
          fill-height
          class="pa-0"
          align="center"
          style="max-width: 600px"
          :outlined="$vuetify.theme.dark"
        >
          <v-card-title class="pa-3 pb-1 justify-end">
            <v-row
              align="baseline"
              class="ma-0"
            >
              <v-tooltip
                top
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    dense
                    color="grey"
                    align="center"
                    dark
                    v-bind="attrs"
                    class="animate_change mr-1"
                    :class="{rotate_180: showIndividual}"
                    v-on="on"
                    @click="toggleIndividual"
                  >
                    mdi-chevron-down
                  </v-icon>
                </template>
                <span>Individuelle Bewertungen anzeigen</span>
              </v-tooltip>
              <p
                class="text-h6 text--primary mb-1"
                style="font-weight: normal"
              >
                {{ index + 1 }}: {{
                  getTranslation(bewertungsKategorie.translationMessages, 'pruefmerkmal', $store.getters['usersStore/getUserLanguage']())
                }}
              </p>
              <v-tooltip
                v-if="bewertungsKategorie.info"
                right
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    dense
                    color="grey"
                    align="start"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span
                  class="text-left"
                  style="text-align: start !important;"
                  v-html="getTranslation(bewertungsKategorie.translationMessages,'info',$store.getters['usersStore/getUserLanguage']())"
                />
              </v-tooltip>
              <v-spacer />
              <div class="d-flex flex-row align-center mb-1 ml-auto">
                <p
                  class="text-h6 mb-0"
                  style="font-weight: normal; text-align: right;"
                >
                  {{ calculatePointsForBewertungskategorie(bewertungsKategorie) }} /
                  {{ calculateMaxPointsForBewertungskategorie(bewertungsKategorie) }}
                </p>
                <transition name="slide-x-reverse-transition">
                  <v-icon
                    v-if="calculatePointsForBewertungskategorie(bewertungsKategorie) ===
                      calculateMaxPointsForBewertungskategorie(bewertungsKategorie)"
                  >
                    mdi-star
                  </v-icon>
                </transition>
              </div>
            </v-row>
          </v-card-title>
          <v-divider />

          <div id="container">
            <!--            Juroren:-->
            <v-expand-transition>
              <div
                v-if="showIndividual"
                class="d-flex mb-0 flex-scroll header_left justify-end"
                style="flex: 1 0 100%; background-color:transparent;"
                :style="{'border-bottom': 'solid ' + $vuetify.theme.currentTheme.divider + ' 1px'}"
              >
                <v-card
                  v-for="userId in finishedUserIds"
                  :key="userId"
                  tile
                  flat
                  class="d-flex pa-2 align-self-center justify-center"
                  style="min-width: 58px;"
                  :style="{'border-right': 'solid ' + $vuetify.theme.currentTheme.divider + ' 1px',
                           'border-color': $vuetify.theme.currentTheme.divider }"
                >
                  <v-tooltip
                    top
                  >
                    <template #activator="{ on, attrs }">
                      <juror-avatar
                        v-if="getUserById(userId) != null"
                        v-bind="attrs"
                        :user="getUserById(userId)"
                        :size="40"
                        v-on="on"
                      />
                      <!--                      <v-list-item-avatar-->
                      <!--                        v-bind="attrs"-->
                      <!--                        class="ma-auto"-->
                      <!--                        v-on="on"-->
                      <!--                      >-->
                      <!--                        <img-->
                      <!--                          :src="getAvatar(200, userId)"-->
                      <!--                          alt="Avatar"-->
                      <!--                        >-->
                      <!--                      </v-list-item-avatar>-->
                    </template>
                    <span>
                      {{ getUserData(userId).firstName }} {{ getUserData(userId).lastName }}
                    </span>
                  </v-tooltip>
                </v-card>
                <div
                  :style="{ 'min-width': calc_max_width_factor(bewertungsKategorie.bewertungen) + 48 + 12 + 'px',
                            'max-width': calc_max_width_factor(bewertungsKategorie.bewertungen) + 48 + 12 + 'px'}"
                />
              </div>
            </v-expand-transition>
            <!--            Prüfmerkmale:-->
            <template v-for="(bewertung, bewertungIndex) in bewertungsKategorie.bewertungen">
              <div
                :key="bewertung.id"
                class="d-flex mb-0 flex-scroll header_left"
                style="flex: 1;"
              >
                <v-card
                  :v-ripple="bewertung.info"
                  class="pa-2 pl-4 flex-grow-1 justify-start"
                  flat
                  tile
                  style="white-space: nowrap; flex: 0 0 100%;"
                  @click="toggleEigenschaftenInfoStates(bewertung.index)"
                >
                  {{
                    getTranslation(bewertung.translationMessages, 'bezeichnung', $store.getters['usersStore/getUserLanguage']())
                  }}
                  <v-btn
                    v-if="bewertung.info"
                    icon
                    :class="{rotate_180: eigenschaftenInfoStates[bewertung.index]}"
                    @click="toggleEigenschaftenInfoStates(bewertung.index)"
                    @click.native.stop=""
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                  <v-expand-transition>
                    <div v-if="eigenschaftenInfoStates[bewertung.index]">
                      <span
                        style="text-align: start !important; font-size: 9pt;"
                        class="text-wrap"
                        v-html="getTranslation(bewertung.translationMessages,'info',$store.getters['usersStore/getUserLanguage']()) || bewertung.info"
                      />
                    </div>
                  </v-expand-transition>
                  <!--                  <v-tooltip-->
                  <!--                    v-if="bewertung.info"-->
                  <!--                    right-->
                  <!--                  >-->
                  <!--                    <template #activator="{ on, attrs }">-->
                  <!--                      <v-icon-->
                  <!--                        small-->
                  <!--                        color="grey"-->
                  <!--                        align="center"-->
                  <!--                        dark-->
                  <!--                        v-bind="attrs"-->
                  <!--                        v-on="on"-->
                  <!--                      >-->
                  <!--                        mdi-information-outline-->
                  <!--                      </v-icon>-->
                  <!--                    </template>-->
                  <!--                    <span-->
                  <!--                      class="d-flex justify-start text-left"-->
                  <!--                      style="text-align: start !important;"-->
                  <!--                      v-html="bewertung.info"-->
                  <!--                    />-->
                  <!--                  </v-tooltip>-->
                </v-card>
              </div>
              <div
                :key="bewertung.id"
                class="d-flex mb-0 flex-scroll justify-end flex-row align-end"
                style="margin-left: auto;"
              >
                <template v-if="showIndividual">
                  <v-card
                    v-for="userId in finishedUserIds"
                    :key="userId"
                    class="pa-2 justify-end d-flex animate_change"
                    tile
                    flat
                    style="min-width: 58px;"
                    :style="{'border-right': 'solid ' + $vuetify.theme.currentTheme.divider + ' 1px'}"
                  >
                    <v-chip
                      :color="getChipColorPoints(bewertung.eigenschaftId, userId)"
                      :outlined="ratingMedianDifference(bewertung.eigenschaftId, userId) == null
                        ? true : Math.abs(ratingMedianDifference(bewertung.eigenschaftId, userId)) < 2"
                      label
                      style="min-width: 32px; border-radius: 10px !important;"
                      class="pa-1 justify-center"
                    >
                      <v-icon
                        small
                        class="mr-1"
                      >
                        mdi-star
                      </v-icon>
                      {{ getBewertungDisplayString(bewertung.eigenschaftId, userId) }}
                    </v-chip>
                  </v-card>
                </template>
                <v-card
                  class="pt-2 pb-2 pr-2 pl-1 justify-end align-center header"
                  tile
                  flat
                  style="white-space: nowrap; background-color: transparent; backdrop-filter: blur(2px); box-shadow: 0 0 10px 16px #000;"
                >
                  <v-chip
                    label
                    small
                    class="pr-0 pl-0 mb-1 mt-1"
                    style="overflow: visible; border-radius: 8px !important;"
                  >
                    <span
                      ref="listItem"
                      class="font-weight-light justify-end"
                      :style="{ 'width': calc_max_width_factor(bewertungsKategorie.bewertungen) + 'px',
                                display: 'inline-block' }"
                    >
                      x {{ bewertung.faktor }}
                    </span>
                    <v-chip
                      color="secondary_card"
                      label
                      style="max-width: 48px; min-width: 48px; margin:0 auto; border-radius: 10px !important; color: white;"
                      class="pl-1 pr-1 align-center justify-center ml-0 mr-0"
                    >
                      <v-icon
                        small
                        class="mr-1"
                      >
                        mdi-star
                      </v-icon>
                      {{ isNaN(getAverageBewertung(bewertung.eigenschaftId))
                        ? '--' : getAverageBewertung(bewertung.eigenschaftId) }}
                    </v-chip>
                  </v-chip>
                </v-card>
              </div>
              <p
                :key="bewertung.id"
                class="d-flex ma-0 justify-center"
                style="flex-basis: 100%;"
              >
                <v-divider
                  v-if="bewertungIndex !== bewertungsKategorie.bewertungen.length - 1"
                  :key="bewertung.id"
                  class="animate_change"
                  :style="{'max-width': showIndividual ? '1000px': '32px'}"
                />
              </p>
            </template>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-card
      v-if="currentCiderFinished"
      class="mx-auto pt-4 pb-2 mt-8"
      width="500"
      outlined
      style="border-width: medium; transition: box-shadow 0.2s ease-in-out;"
    >
      <v-icon size="148">
        mdi-check-decagram
      </v-icon>
      <v-card-subtitle class="pb-2 text-center text-subtitle-1">
        {{ $t('CiderStepper.step3SubtitleFinishedByChair') }}
      </v-card-subtitle>
      <v-card-actions>
        <v-btn
          v-intersect="onIntersectToOverviewCard"
          color="primary"
          elevation="0"
          rounded
          class="ml-auto mr-auto pl-4 pr-4"
          @click="toList()"
        >
          {{ $t('toCiderList') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="showCiderChairFinish && getCurrentTasting.started && !getCurrentTasting.finished"
      class="mx-auto pt-4 pb-2 mt-8"
      width="500"
      outlined
      style="border-width: medium; transition: box-shadow 0.2s ease-in-out;"
    >
      <v-icon
        size="148"
        style="opacity: 0.66;"
      >
        mdi-check-decagram-outline
      </v-icon>
      <v-card-subtitle class="pb-2 text-center text-subtitle-1">
        {{ $t('CiderStepper.step3SubtitleFinishedByUserChair') }}
      </v-card-subtitle>
      <v-card-actions>
        <v-btn
          color="primary"
          elevation="0"
          rounded
          class="ml-auto mr-auto pl-4 pr-4"
          @click="finishCider"
        >
          <v-icon
            v-if="$vuetify.breakpoint.name !== 'xs'"
            size="22"
            class="pr-1"
          >
            mdi-check-decagram
          </v-icon>
          {{ $t('CiderInfoExpPanel.finishRatingChair') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <!--      </v-col>-->
    <!--    </v-row>-->

    <h1
      v-if="currentCider === null"
      class="vertical-align: middle; fill-height;"
    >
      Please select a Cider in Cider List
    </h1>
    <easter-egg-modal ref="easterEggModal" />
    <MyTour
      name="ciderSummaryTour"
      :steps="tourSteps"
      :options="$store.getters['tourStore/getTourOptions']()"
      :callbacks="$store.state.tourStore.callbacks"
    />
  </v-container>
</template>

<script>
import Vue from 'vue'
import store from "@/stores";
import {apiTyped} from "@/api";
import {VueMasonryPlugin} from 'vue-masonry';
import CiderInfoExpPanel from "@/components/cider_info_exp_panel";
import CommentEditor from "@/components/comment_editor";
import util from "@/util";
import {sync} from "vuex-pathify";
import CiderRatingStepper from "@/components/cider_rating_stepper"
import {clearIntervalAsync} from 'set-interval-async'
import {setIntervalAsync} from 'set-interval-async/fixed'
import {CurrentPage} from "@/stores/app";
import {createLocaleMessagesForKategorie} from "@/types/kategorieRated";
import {extendLocaleMessages} from "@/locales/createLocaleMessageObject";
import MyTour from "@/components/my_tour";
import ChipPoints from "@/components/chip_points";
import CiderRatingState, {getStateKeysNames} from "@/types/ciderRatingState";
import Confirm_dialog from "@/components/confirm_dialog";
import translation_mixin from "@/components/mixins/translation_mixin";
import logos_mixin from "@/components/mixins/logos_mixin";
import JurorAvatar from "@/components/juror_avatar";
import EasterEggModal from "@/components/easter_egg_modal";
import easter_egg_mixin from "@/components/mixins/easter_egg_mixin";
import rating_mixin from "@/components/mixins/rating_mixin";
import PageNavigation from "@/components/page_navigation";
import user_mixin from "@/components/mixins/user_mixin";
import {allRoutes} from "@/router";
import AlertLoadingError from "@/components/alert_loading_error.vue";
Vue.use(VueMasonryPlugin)

export default Vue.extend({
  name: "Summary",
  components: {
    AlertLoadingError,
    PageNavigation,
    EasterEggModal,
    JurorAvatar,
    Confirm_dialog,
    ChipPoints,
    MyTour,
    CiderRatingStepper,
    CiderInfoExpPanel,
    CommentEditor
  },
  mixins: [translation_mixin, logos_mixin, easter_egg_mixin, rating_mixin, user_mixin],
  async beforeRouteLeave(to, from, next) {
    if (this.$store.getters['usersStore/isChair']() && this.commentChanged) {
      const confirmResult = await this.$refs.confirm.open(
        this.$t('unsavedChanges'), this.$t('unsavedChangesMessage'),
        { color: 'secondary_card', thirdButton: true, thirdButtonText: this.$t('discard') },
        {btnOkay: "save"})
      if (confirmResult === true) {
        await this.kommentarSubmit();
        next();
      }
      if (confirmResult === false){
        this.$refs.ciderRatingStepper.setStep(3);
      }
      if (confirmResult === "third") {
        next()
      }
    } else {
      next();
    }
  },
  data: () => ({
    hideToSummaryButton: false,
    allJurors: [],
    abschlusskommentarDraft: "",
    liveKommentar: "",
    deMessages: {},
    enMessages: {},
    isSummary: true,
    showIndividual: true,
    masonry_summary: null,
    masonryComments: null,
    masonrySkeleton: null,
    masonryAbschlusskommentar: null,
    loading: true,
    error: false,
    errors: [],
    rating: 0,
    notes: null,
    bewertungen: null,
    sending_comment: false,
    last_kommentar: "",
    lastChairKommentar: null,
    abschlussbewertung: null,
    localeMessages: null,
    eigenschaftenInfoStates: [],
    loading_attrs: {
      class: 'mb-6',
      boilerplate: true,
      elevation: 2,
    },
    markedFinished: false,
    intervalUpdateData: null,
    tableUsers: [],
    finishedUserIds: [],
  }),
  i18n: {
    messages: {
      de: {
        "tourAllComments": "Alle Bewertungen & Kommentare",
        "tourAllCommentsMessage": "Die Punktzahlen und Kommentare der anderen Juroren des Tisches",
        "tourAllIndividualRatings": 'Alle individuellen Bewertungen',
        "tourAllIndividualRatingsMessage": `Die individuellen Bewertungen der anderen Juroren zu den einzelnen Prüfmerkmalen.
                <br>Kann eingeklappt werden.`,
        "tourFinalCommentMessage": `Der finale Kommentar zur Gesamtbewertung, Feedback für den Produzenten.
                <br>Kann nur vom Chair ausgefüllt werden.`,
        "tourFinishCider": 'Bewertung Abschließen',
        "tourFinishCiderMessage": `Sobald alle Juroren ihre Bewertung abgeschlossen haben und ein finaler Kommentar abgegeben ist,
                  kann der Chair den Cider als bewertet markieren.<br>
                  Danach sind ohne erneute Freigabe keine Änderungen der Bewertung mehr zulässig.<br>
                  Der Chair kann die erneute Bewertung nochmals freigeben.`,
      },
      en: {
        "tourAllComments": "All Ratings & Comments",
        "tourAllCommentsMessage": "The scores and comments of the other judges at your table",
        "tourAllIndividualRatings": 'All individual Ratings',
        "tourAllIndividualRatingsMessage": `The individual evaluation of the other jurors for the individual test characteristics.
                 <br>Can be collapsed.`,
        "tourFinalCommentMessage": `The final comment on the overall evaluation, feedback for the producer.
                 <br>Can only be filled out by the Chair.`,
        "tourFinishCider": 'Finish Overall Evaluation',
        "tourFinishCiderMessage": `Once all judges have completed their evaluation and a final comment has been made,
          the chair may mark the cider as completed.<br>
          After that, without re-opening, no more changes to the ratings are allowed.<br>
          The chair can start the re-evaluation again.`,
      }
    }
  },
  computed: {
    tourSteps() {
      return [
      {
        target: '#summaryComments',
        header: {
          title: this.$t('tourAllComments'),
        },
        content: this.$t('tourAllCommentsMessage'),
        params: {
          placement: 'top',
          enableScrolling: true,
        }
      },
      {
        target: '#summaryAbschlusskommentar',
        header: {
          title: this.$t('finalComment'),
        },
        content: this.$t('tourFinalCommentMessage'),
        params: {
          placement: 'top',
          enableScrolling: true,
        }
      },
      {
        target: '#masonrySummary > div:nth-child(1) > div',
        header: {
          title: this.$t('tourAllIndividualRatings'),
        },
        content: this.$t('tourAllIndividualRatingsMessage'),
        params: {
          placement: 'top',
          enableScrolling: true,
        }
      },

      {
        target: '#ciderInfoExpPanel',
        header: {
          title: this.$t('tourFinishCider'),
        },
        content: this.$t('tourFinishCiderMessage'),
        params: {
          placement: 'bottom',
          enableScrolling: true,
        }
      },
    ]
    },
     showHomeBtn () {
       const ciderState = this.$store.getters["ciderStore/getCiderRatingState"]();
       return ciderState === CiderRatingState.finishedByChair || ciderState === CiderRatingState.restartedByChair;
     },
     currentTasting() {
       return this.$store.getters["usersStore/currentTasting"]();
     },
     commentChanged() {
       if (this.loading || this.currentCider.chairNotes == null) return false;  // still loading or not present?
       // if (this.currentCider.chairNotes.kommentar === '<p></p>'
       //   || this.currentCider.chairNotes.kommentar === ''
       //   || this.currentCider.chairNotes.kommentar == null) return false;
       return this.hashString(this.currentCider.chairNotes.kommentar) !== this.hashString(this.abschlusskommentarDraft);
     },
     // currentPoints() {
     //   return this.$store.getters["ciderStore/getTotalPointsForCider"](this.currentCider);
     // },
     currentCiderIdx () {
       return this.$store.getters["ciderStore/getCiderIdxByFlightsCidersId"](Number(this.$route.params.flightsCidersId));
     },
     currentCider: sync('ciderStore/ciderList@[:currentCiderIdx]'),
     currentCiderFinished() {
       return this.$store.getters["ciderStore/getCiderRatingState"](this.currentCider) === CiderRatingState.finishedByChair;
     },
     currentCiderCanBeFinished() {
       return this.showCiderChairFinish;
     },
     // abschlusskommentar: {
     //   get () {
     //     return this.currentCider.abschlusskommentar;
     //   },
     //   // set (value) {
     //   //   store.getters["ciderStore/currentCider"]()?.abschlusskommentar = value;
     //   // },
     // },
     widthRatingMasonry() {
       switch (this.$vuetify.breakpoint.name) {
         case 'xs': return "600px"
         case 'sm': return "1200px"
         case 'md': return "1200px"
         case 'lg': return "1200px"
         case 'xl': return "1800px"
         default: return "1200px"
       }
     },
     widthCommentsMasonry() {
       switch (this.$vuetify.breakpoint.name) {
         case 'xs': return "600px"
         case 'sm': return "1200px"
         case 'md': return "900px"
         case 'lg': return "900px"
         case 'xl': return "1200px"
         default: return "1200px"
       }
     }
    },
  async created() {
    window.addEventListener('keydown', this._keyListener);
    this.$store.state.ciderStore.currentFlightsCidersId = Number(this.$route.params.flightsCidersId);
    this.$store.state.appStore.currentPage = CurrentPage.summary;
    this.$store.state.active_tab = 2;
    this.$store.state.app_bar_loading = true;
    try {
      await this.updateData();
      // await store.dispatch("ciderStore/addAllBewertungenToCiders",
      //     {bewertungen: [], ciders: [this.currentCider]});

      this.tableUsers = this.$store.getters["tableStore/getTableMembers"]();
      this.lastChairKommentar = this.currentCider.chairNotes != null ? this.currentCider.chairNotes.kommentar : '';
      // Translations:
      this.localeMessages = createLocaleMessagesForKategorie(this.currentCider.kategorie);
      const currentLangCode = this.$store.getters["usersStore/getUserLanguageCode"]();
      await extendLocaleMessages(currentLangCode, this.localeMessages[currentLangCode])
      this.$i18n.setLocaleMessage(currentLangCode, {...this.$i18n.messages[currentLangCode], ...this.localeMessages[currentLangCode]})
      let idxCount = 0;
      this.currentCider.kategorie.bewertungskategorien.forEach(
        bewKat => bewKat.bewertungen.forEach(bew => {
          bew.index = idxCount;
          idxCount += 1;
        }));
      const noOfEigenschaften = this.currentCider.kategorie.bewertungskategorien.flatMap(
        bewKat => bewKat.bewertungen).length;
      this.eigenschaftenInfoStates = new Array(noOfEigenschaften).fill(false);
      this.abschlusskommentarDraft = this.currentCider.chairNotes != null ? this.currentCider.chairNotes.kommentar : '';
      this.allJurors = this.$store.getters["tableStore/getTableMembers"]();
    } catch (error) {
      console.error(error);
      this.error = true;
      this.errors.push(JSON.stringify(error, Object.getOwnPropertyNames(error)));
    }

    this.$redrawVueMasonry('masonry_summary')
    this.$redrawVueMasonry('masonryComments')
    this.$redrawVueMasonry('masonryAbschlusskommentar')
    window.setTimeout(() => {
      this.$redrawVueMasonry('masonry_summary')
      this.$redrawVueMasonry('masonryComments')
      this.$redrawVueMasonry('masonryAbschlusskommentar')
    }, 500);
    this.intervalUpdateData = setIntervalAsync(this.updateData, 10000);

    this.$watch(
        "$i18n.locale",
        (newLocale, oldLocale) => {
          if (newLocale === oldLocale) {
            return
          }
          this.$i18n.setLocaleMessage(newLocale, {...this.$i18n.messages[newLocale], ...this.localeMessages[newLocale]})
          console.log("ALL MESSAGES new", this.$i18n.messages)
          console.log("All messages nwe", {...this.$i18n.messages[newLocale], ...this.localeMessages[newLocale]})
          // this.i18n.messages[currentLangCode] = this.localeMessages[currentLangCode];
          // this.$i18n.setLocaleMessage(newLocale, localeMessages[newLocale])
        },
        { immediate: true }
    )

    this.loading = false;
    this.$store.state.app_bar_loading = false;
    if (!this.$store.getters["usersStore/user"]().userSettings.tourSummaryComplete) {
      this.$tours['ciderSummaryTour'].start();
    }
  },
  updated() {
    this.$redrawVueMasonry('masonry_summary')
    this.$redrawVueMasonry('masonryComments')
    this.$redrawVueMasonry('masonryAbschlusskommentar')
  },
  async beforeDestroy() {
    await clearIntervalAsync(this.intervalUpdateData);
    window.removeEventListener("keydown", this._keyListener);
  },
  methods: {
    getBewertungDisplayString(eigenschaftId, userId) {
      const bewertung = this.getBewertung(eigenschaftId, userId);
      return bewertung == null || bewertung.punkte == null || isNaN(bewertung.punkte)
        ? "--"
        : bewertung.punkte;
    },
    onIntersectToOverviewCard (entries, observer) {
      this.hideToSummaryButton = entries[0].isIntersecting;
    },
    ratingMedianDifference(eigenschaftId, userId) {
      return this.getBewertungPunkte(eigenschaftId, userId) != null && this.getMedianBewertung(eigenschaftId) != null
        ? this.getMedianBewertung(eigenschaftId) - this.getBewertungPunkte(eigenschaftId, userId)
        : null;
    },
    getChipColorPoints(eigenschaftId, userId) {
      // const avg = this.getAverageBewertung(eigenschaftId);
      // const my = this.getBewertung(eigenschaftId, userId) != null
      //   ? this.getBewertung(eigenschaftId, userId).punkte || avg
      //   : avg;
      if (this.getBewertungDisplayString(eigenschaftId, userId) === '--') return 'error';
      const difference = this.ratingMedianDifference(eigenschaftId, userId);
      if (difference > 2) return 'error';
      if (difference < -2) return 'primary';
      return '';
    },
    onIntersect (entries) {
      if (entries[0]["intersectionRatio"] >= 1) this.$refs.ciderInfoExpPanel?.closePanelFromParent();
    },
    async updateData() {
      // get cider notes, chair notes and all bewertungen and refresh current cider
      const currentFlightsCider = await apiTyped.getFlightsCiderNotesAndRating(
        this.$store.state.ciderStore.currentFlightsCidersId);
      const userId = this.$store.getters["usersStore/userId"]();
      this.currentCider.updateFlightsCidersData(
        currentFlightsCider, userId, null, false);
      this.currentCider.addNotes(currentFlightsCider.cider_notes, userId, true);
      this.currentCider.addAllJurorBewertungen(currentFlightsCider.bewertungen, userId);
      const oldKommentar = this.currentCider.chairNotes != null ? this.currentCider.chairNotes.kommentar : '';
      if (oldKommentar !== this.lastChairKommentar && this.currentCider.chairNotes != null) {
        this.currentCider.chairNotes.kommentar = oldKommentar;
      }
      this.markedFinished = Object.values(this.currentCider.allNotes).every(
        note => note != null && note.finished === true);
      this.finishedUserIds = this.$store.getters["ciderStore/getFinishedUserIds"]();

      // get easter egg infos:
      if (this.tableUsers != null && this.tableUsers.length > 0) {
        const userSettings = await apiTyped.getUserSettings(this.tableUsers.map(t => t.id));
        for (let i = 0; i<this.tableUsers.length; i++) {
          const tableUser = this.tableUsers.find(t => t.id === this.tableUsers[i].id);
          if (tableUser == null) continue;
          const juror = this.$store.getters["tableStore/getCurrentTable"]().juroren.find(
            j => j.id === userSettings[i]?.user_id);
          if (juror != null) juror.userSettings.easterEggActive = userSettings[i].easter_egg_active || false;
        }
      }

      // redraw
      this.$redrawVueMasonry('masonry_summary');
      this.$redrawVueMasonry('masonryComments')
      this.$redrawVueMasonry('masonryAbschlusskommentar')
    },
    // async updateAllBewertungen() {
    //   if (this.currentCider.allNotes == null) return;
    //   // const finishedUsers = Object.entries(this.currentCider.allNotes).filter(userKeyNote =>
    //   //     userKeyNote[1].finished === true).map(userKeyNote => userKeyNote[0]);
    //   // console.log("Finished users", finishedUsers);
    //   // await addAllBewertungenToCiders([this.currentCider], [], finishedUsers)
    //   // const oldUserKeys = Object.keys(this.currentCider.allBewertungen);
    //   await addAllBewertungenToCiders([this.currentCider], [], this.$store.getters["tableStore/getTableMembersIds"]());
    //   // if (oldUserKeys.length !== Object.keys(this.currentCider.allBewertungen).length) {
    //     // Update keys for reactivity:
    //     // for (const userKey of Object.keys(this.currentCider.allBewertungen)) {
    //     //   console.log("Setting", userKey, this.currentCider.allBewertungen[userKey])
    //     //   Vue.set(this.currentCider.allBewertungen, userKey, this.currentCider.allBewertungen[userKey]);
    //     // }
    //   // }
    //   // await store.dispatch("ciderStore/addAllBewertungenToCiders",
    //   //     {bewertungen: [], ciders: [this.currentCider]});  // TODO This is not reactive!?!
    // },
    // async updateMarkedFinished() {
    //   this.markedFinished = await this.$store.dispatch("ciderStore/allBewertungenMarkedFinished", null);
    // },
    // async updateCiderNotes() {
    //   const ciderNotes = await apiTyped.getUserNotesForCider([this.currentCider]);
    //   await addAllNotesForCider(this.currentCider,
    //       this.$store.getters["tableStore/getCurrentTable"]().juroren.map(juror => juror.id));
    //   const chairNotes = await apiTyped.getChairNotesForCider([this.currentCider])
    //   this.currentCider.notes = createCiderNotesFromDatabase(ciderNotes[0])
    //   const oldKommentar = this.currentCider.chairNotes.kommentar
    //   this.currentCider.chairNotes = chairNotes[0];
    //   if (oldKommentar !== this.lastChairKommentar) this.currentCider.chairNotes.kommentar = oldKommentar;
    //
    //   this.markedFinished = Object.values(this.currentCider.allNotes).every(note => note.finished === true);
    //   this.finishedUserIds = this.$store.getters["ciderStore/getFinishedUserIds"]();
    // },
    async updateChairNotesFieldFinishedTrue() {
      if (this.$store.getters['usersStore/isChair']() && this.commentChanged) {
        const confirmResult = await this.$refs.confirm.open(
          this.$t('unsavedChanges'), this.$t('unsavedChangesMessage'),
          { color: 'secondary_card', thirdButton: true, thirdButtonText: this.$t('discard') },
          {btnOkay: "save"})
        if (confirmResult === true) {
          await this.kommentarSubmit();
          await this.$refs.ciderInfoExpPanel.doUpdateChairNotesFieldFinishedTrue();
        }
        if (confirmResult === false){
        }
        if (confirmResult === "third") {
          await this.$refs.ciderInfoExpPanel.doUpdateChairNotesFieldFinishedTrue();
        }
      } else {
        await this.$refs.ciderInfoExpPanel.doUpdateChairNotesFieldFinishedTrue();
      }
    },
    getCiderStateText(state) {
      const stateNames = getStateKeysNames().map(idx_name => idx_name[1]);
      // console.log("State names", stateNames, state, stateNames[state])
      return this.$t(String(stateNames[state]));
    },
    // tKategorieString(kategorie) {
    //   return getKategorieTranslationPrefix(kategorie);
    // },
    // tBewertungskategorieString(bewertungskategorie) {
    //   return getBewertungskategorieTranslationPrefix(bewertungskategorie);
    // },
    // tEigenschaftenString(eigenschaft) {
    //   return getEigenschaftTranslationPrefix(eigenschaft);
    // },
    async toggleEigenschaftenInfoStates(index) {
      Vue.set(this.eigenschaftenInfoStates, index, !this.eigenschaftenInfoStates[index]);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const _ in [1,2]) {
        window.setTimeout(() => {
          this.$redrawVueMasonry('masonry_summary');
        }, 500);
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      this.$redrawVueMasonry("masonry_summary");
    },
    hashString(string) {
      if (string === undefined || string === null) return null;
      let hash = 0, chr;
      if (string.length === 0) return hash;
      for (let i = 0; i < string.length; i++) {
        chr   = string.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    getUserById(id, attr = null) {
      const user = this.tableUsers.find(user => user.id === id);
      if (attr == null) return user;
      return user !== undefined ? user[attr] : "-";
    },
    async toggleIndividual() {
      this.showIndividual = !this.showIndividual;
      // for (const i in [1,2]) {
      //   window.setTimeout(() => {
      //     this.$redrawVueMasonry('masonry_summary');
      //   }, 300);
      //   await new Promise(resolve => setTimeout(resolve, 310));
      // }
      // this.$redrawVueMasonry("masonry_summary");
      window.setTimeout(() => {
        this.$redrawVueMasonry('masonry_summary');
        this.$redrawVueMasonry('masonryComments')
        this.$redrawVueMasonry('masonryAbschlusskommentar')
      }, 500);
    },
    async finishCider() {
      await this.$vuetify.goTo(0, {duration: 250});
      await this.$refs.ciderInfoExpPanel?.updateChairNotesFieldFinishedTrue();
    },
    getPointsForJuror(jurorId) {
      // console.log("Calc points for ", jurorId, this.currentCider)
      return this.$store.getters["ciderStore/getPointsForJuror"](this.currentCider, jurorId);
    },
    getBewertung(eigenschaftId, jurorId) {
      return store.getters["ciderStore/getBewertungForJuror"](eigenschaftId, jurorId);
    },
    getBewertungPunkte(eigenschaftId, jurorId) {
      const bewertung = store.getters["ciderStore/getBewertungForJuror"](eigenschaftId, jurorId);
      return bewertung != null ? bewertung.punkte : null;
    },
    getAverageBewertung(eigenschaftId) {
      return store.getters["ciderStore/getBewertungForJuroren"](eigenschaftId);
    },
    getMedianBewertung(eigenschaftId) {
      return store.getters["ciderStore/getBewertungMedian"](eigenschaftId);
    },
    getUserData(userId) {
      return store.getters["tableStore/getUserDataForUserId"](userId);
    },
    calculatePointsForBewertungskategorie(bewertungskategorie) {
      return store.getters["ciderStore/getAllPointsForBewertungskategorie"](bewertungskategorie, this.currentCider);
    },
    calculateMaxPointsForBewertungskategorie(bewertungskategorie) {
      return store.getters["ciderStore/getMaxPointsForBewertungskategorie"](bewertungskategorie);
    },
    // get_points_faktor(points, faktor) {
    //   return util.util.round_to_decimal(points * faktor, 0.1);
    // },
    get_points(points) {
      return util.util.round_to_decimal(points, 0.1);
    },
    getBadgeUrl (width, points) {
      return this.$store.getters["badgesStore/getBadgeIcon"](points, width);
    },
    makeComment(comment) {
      return '"' + comment + '"';
    },
    showBadge (points) {
      console.log("SHOWING", points, this.$store.getters["badgesStore/showBadge"](points));
      return this.$store.getters["badgesStore/showBadge"](points);
    },
    getUserAvatar(width, userId) {
      // console.log("Getting avatar:", userId, this.getUserData(userId))
      return apiTyped.getAvatarUrl(width, this.getUserData(userId), this.$vuetify.theme.currentTheme)
    },
    async kommentarSubmit() {
      this.sending_comment = true;
      this.currentCider.chairNotes.kommentar = await apiTyped.updateChairNotesField(
          this.currentCider.chairNotes.id, this.currentCider.id,
          "kommentar", this.abschlusskommentarDraft);
      this.lastChairKommentar = this.currentCider.chairNotes.kommentar;
      window.setTimeout(() => {
        this.sending_comment = false;
        this.$vuetify.goTo(this.$refs.ciderRatingStepper);
      }, 850)
    },
    getTextWidth(text, font) {
      // re-use canvas object for better performance
      const canvas = this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement("canvas"));
      const context = canvas.getContext("2d");
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    },
    // calculate_sum(pruefmerkmal) {
    //   let sum = 0;
    //   pruefmerkmal.forEach(function (eigenschaft) {
    //     sum += eigenschaft.punkte * eigenschaft.faktor;
    //   });
    //   return util.util.round_to_decimal(sum, 0.1);
    // },
    // calculate_max_sum(pruefmerkmal) {
    //   let sum = 0;
    //   const max_points = 5;
    //   pruefmerkmal.forEach(function (eigenschaft) {
    //     sum = sum + max_points * eigenschaft.faktor;
    //   });
    //   return sum;
    // },
    calc_max_width_factor(bewertungen) {
      const char_count = bewertungen.map(bewertung => bewertung.faktor.toString().length)
      const max_characters = Math.max(...char_count)
      switch (max_characters) {
        case 1: return 30;
        case 3: return 40;
        case 4: return 45;
        default: return 45;
      }
    },
    getAvatar (width, userId) {
      try {
        const userData = this.$store.getters["tableStore/getUserDataForUserId"](userId)
        return apiTyped.getAvatarUrl(width, userData, this.$vuetify.theme.currentTheme);
      } catch(error) {
        console.error("Error getting Avatar URL:", error)
        return false;
      }
    },
    isFabSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        default: return false
      }
      return false;
    },
    async toList() {
      await this.$router.push(this.makeTastingRoute(allRoutes.tastings.ciderList, this.getCurrentTasting));
    },
  }
})
</script>

<style>


.flex-gap > div {
  margin: 6px;
}

.full-height .flex {
  display: flex
}

.md-card {
  width: 320px;
  margin: 4px;
  display: inline-block;
  vertical-align: top;
}


.row_bezeichnung {
  /*display: grid;*/
  grid-template-columns: repeat(auto-fit,minmax(auto, auto));
  /*grid-template-columns: max-content 1fr 1fr;*/
}

.row_factor {
  /*display: grid;*/
  /*grid-template-columns: repeat(auto-fit,minmax(auto, auto));*/
  grid-template-columns: max-content 1fr 1fr;
}

.my_min {
  width: min-content;
}

.full-height .flex > .card {
  flex: 1 1 auto
}

#container {
  display: flex;
  overflow: auto;
  flex-flow: row wrap;
  /*justify-content: end;*/
}

.flex-scroll {
  /*padding: 4px 4px;*/
  display: flex;
  /*align-items: start;*/
  justify-content: flex-end;
  flex-flow: nowrap;
}

.header_left {
  position: sticky;
  left: 0;
  z-index: 2;
  justify-self: start;
  text-align: start;
}

.header {
  position: sticky;
  right: 0;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 4px;
  border-radius: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 16px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbcbcb;
  border-radius: 16px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8c8c8c;
  border-radius: 16px;
}

.animate_change {
  transition:all 0.25s linear;
}
.fade-enter-active, .fade-leave-active {
  transition: max-height 0.25s ease-in;
}

.collapsed {
  max-height: 0;
  overflow: hidden;
  padding: 0;
  background-color: transparent;
}

.rotate_180 {
  -moz-transform:rotate(180deg);
  -webkit-transform:rotate(180deg);
  transform:rotate(180deg);
}
/*#list {*/
/*  margin: 0 auto;*/
/*}*/
</style>
