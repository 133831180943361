import {MyCollections} from "@/api/types/my-collections";

type Country = {
    id: number;
    code: string;
    name: string;
    kontinent?: string;
    reverseChargePossible: boolean;
}

export function createCountry(country: MyCollections["countries"]) {
    return <Country>{
        id: country.id || -1,
        code: country.code || "",
        name: country.name || "",
        kontinent: country.kontinent || "",
        reverseChargePossible: country.reverse_charge_possible || false,
    }
}

export { Country }