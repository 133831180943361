<template>
  <v-footer
    padless
    bottom
    inset
  >
    <v-card
      flat
      tile
      width="100%"
      class="lighten-1 text-center"
      :color="backgroundColor"
    >
      <v-divider />
      <v-card-text
        class="d-flex align-center justify-center"
      >
        <v-img
          contain
          :src="getLogoUrl('logo_app', 300)"
          alt="CiderAwardLogo"
          class="mr-2"
          max-height="32px"
          max-width="40px"
        />
        <span>{{ new Date().getFullYear() }}</span>
        <v-icon
          small
          class="mr-0 ml-0"
        >
          mdi-circle-small
        </v-icon>
        <span class="font-weight-bold">{{ tastingName }}</span>
        <v-icon
          v-if="showDate"
          small
          class="mr-0 ml-0"
        >
          mdi-circle-small
        </v-icon>
        <span v-if="showDate">{{ tastingDate }}</span>
        <v-img
          contain
          :src="getImageUrl(tasting.logoMesse, 300)"
          alt="CiderAwardLogo"
          class="ml-2"
          max-height="32px"
          max-width="40px"
        />
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import logos_mixin from "@/components/mixins/logos_mixin";
import {get} from "vuex-pathify";

export default {
  name: "ServiceFooter",
  mixins: [logos_mixin],
  props: {
    showDate: {type: Boolean, required: false, default: true},
    backgroundColor: {type: String, required: false, default: () => 'background'},
  },
  computed: {
    tasting: get("serviceStore/currentTasting"),
    tastingName() {
      return this.tasting?.hasOwnProperty("name") ? this.tasting.name : "";
    },
    tastingDate() {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return this.tasting?.hasOwnProperty("datum") ? new Date(this.tasting.datum).toLocaleDateString("de", options) : "";
    },
  },
}
</script>

<style scoped>

</style>