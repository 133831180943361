<template>
  <v-app style="margin-top: 0 !important; padding-top: 0 !important;">
    <v-main
      style="background-color: white !important; overflow-x: visible;"
      class="pa-0 ma-0"
    >
      <v-container
        id="allCider"
        fluid
        class="pa-0 ma-0"
      >
        <v-btn
          fab
          fixed
          bottom
          right
          x-large
          class="mr-8 mb-8 no-print"
          @click="print"
        >
          <v-icon>
            mdi-printer
          </v-icon>
        </v-btn>
        <manual-rating-judge
          v-for="(cider, index) in ciders"
          :key="index"
          :product="cider"
          :tasting="tasting"
          :badges-data-array="badgesData"
          style="background-color: white !important; overflow: visible !important;"
          class="mt-8 mb-8 ml-auto mr-auto page_print"
        />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {sync} from "vuex-pathify";
import ManualRatingJudge from "@/components/admin/manualRating/manual_rating_judge.vue";

export default {
  name: "ManualRatingJudgeA4",
  components: {ManualRatingJudge},
  computed: {
    ciders: sync("ciderStore/printCiders"),
    badgesData: sync("badgesStore/badgesData"),
    tasting() {
      return this.$store.getters["usersStore/currentTasting"]();
    },
  },
  methods: {
    print() {
      window.print();
    },
  },
}
</script>

<style scoped>
@media print {
    .page_print {
        margin: 0 !important;
        padding: 0 !important;
    }
}

@page {
    size: portrait;
    margin: 0 0 0 0 !important;
}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>