<template>
  <v-card-text class="pa-0 pb-4 text-caption">
    <v-tooltip top>
      <template #activator="{ on }">
        <a
          target="_blank"
          href="https://cider-world.de/datenschutzerklaerung/"
          style="color: unset !important;"
          @click.stop
          v-on="on"
        >
          Privacy Policy
        </a>
      </template>
      {{ $i18n.t('opensInNew') }}
    </v-tooltip>
    <v-icon
      small
      class="pr-2 pl-2"
    >
      mdi-circle-small
    </v-icon>

    <v-tooltip top>
      <template #activator="{ on }">
        <a
          target="_blank"
          href="https://cider-world.de/impressum/"
          style="color: unset !important;"
          @click.stop
          v-on="on"
        >
          Impressum
        </a>
      </template>
      {{ $i18n.t('opensInNew') }}
    </v-tooltip>
  </v-card-text>
</template>

<script>
export default {
  name: "FooterImpressumPrivacy"
}
</script>

<style scoped>

</style>