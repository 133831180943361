import JurorCider from "@/types/jurorCider";
import {MyCollections} from "@/api/types/my-collections";


interface Flight {
    id: number;
    status: "draft" | "published" | "archived",
    flightName: string;
    sort: number | null
    tables?: number[];
    ciders: number[] | JurorCider[];
}

export function createFlightFromDatabase(flight: MyCollections["flights"], sort: number | null): Flight {
    const newFlight = <Flight>{
        id: Number(flight.id),
        status: flight.status || "draft",
        flightName: flight.name,
        sort: sort,
        tables: flight.tables,
        ciders: flight.cider_ids,
    }
    newFlight.ciders = (newFlight.ciders as any).sort((c1: any, c2: any) => c1.sort - c2.sort);
    return newFlight;
}

export { Flight };