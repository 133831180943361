import Vue from 'vue';
import Vuex from 'vuex';
import {make} from 'vuex-pathify';
import {Kategorie} from "@/types/kategorie";
import {Bewertung, BewertungData} from "@/types/bewertung";
import {createKategorieRatedFromKategorie, KategorieRated} from "@/types/kategorieRated";
// import store, {rootGetter} from "@/stores";

Vue.use(Vuex);


type storeStates = typeof state;
// @ts-ignore
type storeGetters = typeof getters;
// type rootStates = typeof store.state;
// type rootGetters = typeof rootGetter;

const state = {
    kategorieData: new Array<Kategorie>(),
    kategorienRated: new Array<KategorieRated>(),
    // faktorForEigenschaft: new Map<number, number>(),  // faktorForEigenschaft[eigenschaftId] = faktor
    faktorForEigenschaft: {},  // faktorForEigenschaft[eigenschaftId] = faktor
}

// @ts-ignore
const getters = {
    ...make.getters(state),
    getKategorieById: (state: storeStates) => (id: number): Kategorie | undefined => {
        if (state.kategorieData.length > 0) {
            // console.log("Found category data", state.category_data.find(kategorie => kategorie.id == id), "ID:", id);
            return state.kategorieData.find(kategorie => kategorie.id == Number(id));
        }
        return undefined;
    },
    getEigenschaftenIds: (state: storeStates, getters: storeGetters) => (categoryId: number): number[] => {
        const category: Kategorie = getters.getKategorieById(categoryId);
        if (category === undefined) return [];
        const eigenschaftenIds = Array<number>();
        category.bewertungskategorien.forEach(function (bewertungskategorie) {
            bewertungskategorie.eigenschaften.forEach(async function (eigenschaft) {
                eigenschaftenIds.push(eigenschaft.eigenschaftId);
            })
        });
        return eigenschaftenIds;
    },
    // getCurrentKategorie: (state: storeStates, getters: storeGetters, rootState: rootStates, rootGetter: rootGetters) =>
    //     (): Kategorie | undefined => {
    //     return rootGetter["ciderStore/currentCider"]()?.kategorie;
    // },
    getPointsForBewertung: () => (bewertung: Bewertung): number => {
        if (bewertung.punkte === null) return 0;
        return bewertung.punkte * bewertung.faktor;
    },
    getPointsForBewertungData: (state: storeStates) => (bewertung: BewertungData): number => {
        if (bewertung.punkte === null || bewertung?.bewertungId === undefined) return 0;
        // let faktor = state.faktorForEigenschaft.get(bewertung?.eigenschaftId);

        let faktor = bewertung.hasOwnProperty("faktor")
            ? bewertung.faktor
            // @ts-ignore
            : state.faktorForEigenschaft[Number(bewertung?.eigenschaftId)];
        if (faktor === undefined) {
            console.error("Could not find a faktor for Bewertung:", bewertung, "Faktoren:", state.faktorForEigenschaft);
            faktor = 1;
        }
        return bewertung.punkte * faktor;
    },
    getPointsForBewertungenData: (state: storeStates, getter: storeGetters) => (bewertungen: BewertungData[]
            ): number => {
        if (bewertungen === null || bewertungen.length === 0) return 0;
        let points = 0;
        for (const bewertung of bewertungen) {
            points += getter.getPointsForBewertungData(bewertung);
        }
        return points;
    },
}

const mutations = {
    ...make.mutations(state),
    setKategorieData(state: storeStates, kategorien: Kategorie[]) {
        state.kategorieData = kategorien;
        // const faktorForEigenschaft = new Map<number, number>();
        const faktorForEigenschaft: { [key: number]: number; } = {};
        state.kategorieData.forEach(kategorie => {
            kategorie.bewertungskategorien.forEach(bewertungskategorie => {
                bewertungskategorie.eigenschaften.forEach(eigenschaft => {
                    // faktorForEigenschaft.set(eigenschaft.eigenschaftId, eigenschaft.faktor);
                    faktorForEigenschaft[eigenschaft.eigenschaftId] = eigenschaft.faktor;
                });
            });
        });
        state.faktorForEigenschaft = faktorForEigenschaft;
        state.kategorienRated = kategorien.map(k => createKategorieRatedFromKategorie(k));
    }
}

export default {
    namespaced: true, // add this if in module
    state,
    getters,
    mutations,
    // actions,
}