<template>
  <v-container fluid>
    <v-card
      class="ma-4"
      min-width="100"
      style="overflow: hidden"
      color="light_grey"
      :outlined="$vuetify.theme.dark"
    >
      <v-card-title class="pt-3 pb-3 align-center">
        <v-row
          align="center"
          class="ma-0"
        >
          <p class="ma-0 text-h5">
            All Purchases
          </p>

          <v-spacer />

          <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            :label="$t('search')"
            outlined
            clearable
            dense
            rounded
            single-line
            hide-details
            class="ml-auto"
            style="width: 200px; max-width: 400px;"
          >
            <template #append>
              <v-progress-circular
                v-if="loading"
                indeterminate
                class="mt-auto mb-auto ml-auto mr-auto"
                size="24"
                width="2"
              />
            </template>
          </v-text-field>
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="()=>{}">
                <v-list-item-icon class="mr-2">
                  <v-icon v-text="'mdi-account-switch'" />
                </v-list-item-icon>
                <v-list-item-title>
                  Migrate existing Judges
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </v-card-title>
<!--      <v-row align="center">-->
<!--        <v-col>-->
<!--        </v-col>-->
<!--      </v-row>-->

      <v-data-table
        :key="dataTableKey"
        :items="purchases"
        :headers="purchasesHeaders"
        :loading="loading"
        show-group-by
        multi-sort
        :items-per-page="20"
        :footer-props="{'items-per-page-options': [10, 20, 50, 100, 250, 500]}"
        loading-text="Loading Purchases... Please wait"
        item-key="id"
        :search="search"
        fixed-header
        height="calc(100vh - 128px - 64px - 24px)"
        show-select
        style="overflow-y: scroll"
        :options.sync="tableOptions"
        :server-items-length="totalItems"
        @click:row="item => $router.push(`/admin/purchase/${item.id}`)"
      >
        <template #item.menu="{ item }">
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="createInvoice(item)"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon>
                    mdi-file-document-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Create Invoice
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.publishedInvoice"
                @click="sendInvoice(item)"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon>
                    mdi-send
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Send Invoice
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template #item.status="{ item }">
          <v-chip
            :color="statusColor(item.status)"
            small
            text
          >
            {{ statusText(item.status) }}
          </v-chip>
        </template>
        <template #item.totalAmount="{ item }">
          <span>
            {{ formatCurrency(item.totalAmount) }}
          </span>
        </template>
        <template #item.subtotalAmount="{ item }">
          <span>
            {{ formatCurrency(item.subtotalAmount) }}
          </span>
        </template>
        <template #item.publishedInvoiceSent="{ item }">
          <v-chip
            :color="item.publishedInvoice != null && item.publishedInvoice.sent ? 'primary' : 'error'"
            class="px-1"
            small
            text
          >
            <v-icon
              v-if="item.publishedInvoice != null && item.publishedInvoice.sent"
              small
            >
              mdi-check
            </v-icon>
            <v-icon
              v-else
              small
            >
              mdi-close
            </v-icon>
          </v-chip>
        </template>
        <template #item.publishedInvoicePosted="{ item }">
          <v-chip
            :color="item.publishedInvoice != null && item.publishedInvoice.posted ? 'primary' : 'error'"
            class="px-1"
            small
            text
          >
            <v-icon
              v-if="item.publishedInvoice != null && item.publishedInvoice.posted"
              small
            >
              mdi-check
            </v-icon>
            <v-icon
              v-else
              small
            >
              mdi-close
            </v-icon>
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      v-model="showStatusMessage"
      rounded="xl"
      :color="statusMessageColor"
    >
      <strong>
        {{ statusMessageText }}
      </strong>
      <template #action="{ attrs }">
        <v-btn
          text
          rounded
          v-bind="attrs"
          @click="showStatusMessage = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import {apiTyped} from "@/api";
import purchase_mixin from "@/components/mixins/purchase_mixin";

export default {
  name: "AllPurchases",
  mixins: [purchase_mixin],
  data: () => ({
    dataTableKey: 0,
    search: "",
    loading: false,
    searchText: "",
    page: 1,
    tableOptions: {},
    getPurchasesTimeout: null,
    currentTasting: null,
    purchases: [],
    totalItems: 0,
    showStatusMessage: false,
    statusMessageText: "",
    statusMessageColor: "primary",
  }),
  computed: {
    purchasesHeaders() {
      return [
        {text: "Status", value: 'status', align: 'start', sortable: true, groupable: false},
        {text: "Company", value: 'companyName', align: 'start', sortable: true, groupable: false},
        {text: "Contact", value: 'contactPerson', align: 'start', sortable: true, groupable: false},
        {text: "E-Mail", value: 'email', align: 'start', sortable: true, groupable: false},
        {text: "Country", value: 'country', align: 'start', sortable: true, groupable: true},
        {text: "Tax Exempt", value: 'taxExempt', align: 'start', sortable: true, groupable: true},
        {text: "Subtotal", value: 'subtotalAmount', align: 'start', sortable: true, groupable: false},
        {text: "Total", value: 'totalAmount', align: 'start', sortable: true, groupable: false},
        {text: "Invoice Count", value: 'invoiceCount', align: 'start', sortable: true, groupable: false},
        {text: "Invoice Number", value: 'publishedInvoice.invoiceNumber', align: 'start', sortable: true, groupable: false},
        {text: "Invoice Sent", value: 'publishedInvoiceSent', align: 'start', sortable: true, groupable: true},
        {text: "Invoice Posted", value: 'publishedInvoicePosted', align: 'start', sortable: true, groupable: true},
        {text: "", value: 'menu', align: 'end', sortable: false, groupable: false},
      ]
    }
  },
  watch: {
    tableOptions: {
      handler () {
        this.getPurchasesDebounced();
      },
      deep: true,
    },
    searchText: function (newValue) {
      if (newValue !== "") {
        this.getPurchasesDebounced();
      }
    },
  },
  async created() {
    this.currentTasting = this.$store.getters["usersStore/currentTasting"]()
  },
  methods: {
    async sendInvoice(purchase) {
      console.log("sending purchase", purchase);
      const resp = await apiTyped.sendInvoice(purchase.id, purchase.publishedInvoice.id);
      if (resp.toLowerCase() === "success") {
        this.statusMessageColor = "primary";
        this.statusMessageText = "Send successful";
        this.showStatusMessage = true;
        purchase.publishedInvoice.sent = true;
      } else {
        this.statusMessageColor = "error";
        this.statusMessageText = `Error while sending mail ${resp}`;
        this.showStatusMessage = true;
      }
    },
    async createInvoice(purchase) {
      const resp = await apiTyped.createInvoice(purchase.id, 'draft', false, null);
      console.log("Created invoice", resp);
      if (resp != null) {
        this.statusMessageColor = "primary";
        this.statusMessageText = "Invoice Created";
        this.showStatusMessage = true;
        purchase.invoices.unshift(resp);
        this.dataTableKey += 1;
      } else {
        this.statusMessageColor = "error";
        this.statusMessageText = `Error while creating invoice ${resp}`;
        this.showStatusMessage = true;
      }
    },
    getPurchasesDebounced() {
      // cancel pending call
      if (this.getPurchasesTimeout != null) {
        clearTimeout(this.getPurchasesTimeout)
      }
      // delay new call 500ms
      this.getJudgesTimeout = setTimeout(async () => { await this.getPurchases() }, 500)
    },
    async getPurchases() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.tableOptions;
      console.log("Got options", this.tableOptions, sortBy, sortDesc, page, itemsPerPage);
      if (sortBy == null) return;
      const sortByMap = {
        firstName: "judge.first_name",
      }
      const sortByList = sortBy.length > 0
        ? sortBy.map((s, index) => {
          if (sortDesc[index]) return "-" + sortByMap[s];
          else return sortByMap[s];
        })
        : ['-id'];
      const searchText = this.searchText !== "" ? this.searchText : null;
      console.log("Getting purchases", 0, page, itemsPerPage + 1, {}, searchText, sortByList)
      this.purchases = await apiTyped.adminGetPurchases(
        0, page, itemsPerPage + 1, {status: { _neq: "draft"} }, searchText, sortByList);
      this.purchases.forEach(p => p.invoiceCount = p.invoices.length);
      this.totalItems = this.purchases.length;
      this.loading = false;
    }
  }
}
</script>

<style scoped>
/deep/ .v-data-table {
  background-color: var(--v-card_background-base) !important;
}
/deep/ .v-data-table-header > tr > th {
  background-color: transparent !important;
}
/deep/ .v-data-table--fixed-header > tr > th {
  background-color: transparent !important;
}
</style>