import Vue from 'vue';
import Vuex from 'vuex';
import {make} from 'vuex-pathify';
import {Breadcrumb} from "@/types/breadcrumb";
// import store, {rootGetter} from "@/stores";

Vue.use(Vuex);

export enum CurrentPage {
    login,
    ciderTable,
    rating,
    summary
}

type storeStates = typeof state;
// @ts-ignore
// type storeGetters = typeof getters;
// type rootStates = typeof store.state;
// type rootGetters = typeof rootGetter;

const state: {
    hydrated: boolean,
    hydrating: boolean,
    error: any,
    authenticated: boolean,
    accessTokenExpiry: number,
    currentPage: number,
    breadcrumbs: Breadcrumb[]
} = {
    hydrated: false,
    hydrating: false,
    error: null,
    authenticated: false,
    accessTokenExpiry: 0,
    currentPage: 0,
    breadcrumbs: [],
}

// @ts-ignore
const getters = {
    ...make.getters(state),
}

const mutations = {
    ...make.mutations(state),
    logout: (state: storeStates) => {
        state.authenticated = false;
    },
}

// const persistentStates = ['username', 'password']
// const defaultStates = [null, null]

// export const appPersistentStates = createPersistedState({
//     paths: persistentStates
// })

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}