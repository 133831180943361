<template>
  <v-container
    v-if="!loading"
  >
    <v-btn
      fab
      fixed
      bottom
      right
      x-large
      class="mr-8 mb-8 no-print"
      @click="print"
    >
      <v-icon>
        mdi-printer
      </v-icon>
    </v-btn>
    <v-select
      v-model="selectedKategorie"
      :items="kategorien"
      item-value="id"
      :item-text="item => (item.displayId !== 'undefined' ? item.displayId : item.id) + ': ' + item.name"
      style="max-width: 500px;"
      class="no-print ml-auto mr-auto"
      label="Kategorie"
    />
    <div class="d-flex flex-row no-print justify-center">
      <span class="text-h6 d-flex">Seiten Zusammenfassen:</span>
      <v-checkbox
        v-for="(n, index) in (badgesDataSorted.length - 1)"
        :key="index"
        v-model="printGroupedBadges"
        :value="index"
        :label="getMergeText(index)"
        class="d-flex pl-2 pr-2 mt-0"
      />
      <v-checkbox
        v-if="allCiders.length > 0"
        v-model="hideNoneBadge"
        :label="`Cider ohne Medaille ausblenden`"
        class="d-flex pl-2 pr-2 mt-0 ml-8"
        on-icon="mdi-eye-off"
        off-icon="mdi-eye"
      />
    </div>
<!--    <span v-html="allCidersCsv"></span>-->
    <template
      v-for="(cidersByBadge, index) in allCidersByBadgeGrouped"
      style="page-break-after: avoid;"
    >
      <div :key="index">
        <v-theme-provider light>
          <RanglisteTable
            :index="index"
            :is-first="index === 0"
            :is-last="index === allCidersByBadgeGrouped.length - 1"
            :ciders-grouped="cidersByBadge"
          />
        </v-theme-provider>
      </div>
    </template>
  </v-container>
</template>

<script>
import {apiTyped} from "@/api";
import {sync, get} from "vuex-pathify";
import RanglisteTable from "@/components/admin/rangliste/rangliste_table";
import cider_mixin from "@/components/mixins/cider_mixin";
import logos_mixin from "@/components/mixins/logos_mixin";
import {groupBy} from "@/util/helper";
import JurorCider from "@/types/jurorCider";

export default {
  name: "Rangliste",
  components: {
    RanglisteTable
  },
  mixins: [cider_mixin, logos_mixin],
  data: () => ({
    allCiders: [],
    showIndividual: true,
    pageScale: 1,
    pageSizeCm: 21,
    printGroupedBadges: [],
    selectedKategorie: null,
    hideNoneBadge: true,
    loading: true,
  }),
  computed: {
    allCidersCsv() {
      let s = "";
      for (const cider of this.allCidersPrepped) {
        s = s + cider.produzent;
        s = s + ";" + (cider.land != null ? cider.land.name : "");
        s = s + ";" + cider.name;
        s = s + ";" + cider.totalPoints;
        s = s + ";" + cider.kategorie.id;
        s = s + `<br>`;
      }
      return s;
    },
    badgesData: get("badgesStore/badgesData"),
    tables: sync("tableStore/tables"),
    tastings: sync("usersStore/tastings"),
    kategorien: sync("kategorienStore/kategorieData"),
    // currentTastingIdx() {
    //   return this.$store.getters["usersStore/getTastingIdxByTastingId"](Number(this.$route.query.tasting));
    // },
    // tasting: sync('usersStore/tastings@[:currentTastingIdx]'),
    tasting() {
      return this.$store.getters["usersStore/currentTasting"]();
    },
    // hiddenCiderCount() {
    //   if (this.allCiders === null) return 0;
    //   return this.allCiders.filter(cider => this.$store.getters['badgesStore/getBadgeData'](cider.totalPoints) !== null && this.$store.getters['badgesStore/getBadgeData'](cider.totalPoints).hasImage === false && cider.kategorie.id === this.selectedKategorie.id).length
    // },
    badgesDataSorted() {
      return [...this.badgesData].sort((b1, b2) => b2.punkteMin - b1.punkteMin);
    },
    allCidersPrepped() {
      if (this.allCiders === null) return [];
      return this.allCiders.map((cider, index) => {
        cider.uniqueId = index;
        // cider.totalPoints = this.calculateTotalPointsForCider(cider, true, false, 0.5);
        cider.totalPoints = cider.points;
        cider.badgeData = this.$store.getters["badgesStore/getBadgeData"](cider.points);
        return cider;
      });
    },
    mergedPages() {
      return Array(this.badgesData.length - 1).fill(false).map((value, index) =>
        this.printGroupedBadges.includes(index));
    },
    allCidersByBadgeGrouped() {
      let allCiders = this.allCidersPrepped
      if (this.hideNoneBadge) allCiders = allCiders.filter(
        cider => this.$store.getters["badgesStore/getBadgeData"](cider.totalPoints).hasImage === true);
      const cidersByKategorie = allCiders.filter(
        c => Number(c.kategorie.id) === Number(this.selectedKategorie || this.kategorien[0].id));
      if (cidersByKategorie.length === 0) return [];
      let cidersByBadge = [...cidersByKategorie].sort(
          (ciderA, ciderB) => Number(ciderA.totalPoints) - Number(ciderB.totalPoints))
      cidersByBadge = this.badgesDataSorted.map(badgeData =>
          cidersByBadge.filter(cider => cider.badgeData.punkteMin === badgeData.punkteMin));
            // this.$store.getters["badgesStore/getBadgeData"](Math.floor(cider.totalPoints)).punkteMin === badgeData.punkteMin));
      // TODO merge two different badges kategorien if wanted?
      const missingBadgesCount = cidersByBadge.filter(b => b.length === 0).length;
      console.log("cidersByBadge", cidersByBadge)
      let arrayLength = cidersByBadge.length - missingBadgesCount - this.mergedPages.filter(p => p === true).length;
      if (arrayLength < 0) arrayLength = 0;
      console.log("ARRAY LEFNTH", arrayLength)
      const cidersByBadgeGrouped = Array(arrayLength).fill([]);
      let pushedCount = 0;
      for (const [index, cidersByOneBadge] of cidersByBadge.filter(b => b.length !== 0).entries()) {
        if (cidersByOneBadge === undefined || cidersByOneBadge.length === 0) continue;
        // determine last badge stufe index, if one badge kategorie is missing:
        let lastBadgeKategorieOffset = 1
        // while (index > 0 && Object.values(cidersByBadge)[index - 1].length === 0) lastBadgeKategorieOffset = lastBadgeKategorieOffset - 1;
        if (index > 0 && this.mergedPages[index - lastBadgeKategorieOffset] === true) {
          cidersByBadgeGrouped[index - lastBadgeKategorieOffset - pushedCount].push(cidersByOneBadge);
          pushedCount = pushedCount + 1;
        }
        else {
          cidersByBadgeGrouped[index - pushedCount] = [cidersByOneBadge];
        }
      }
      return cidersByBadgeGrouped

      // const cidersByBadgeGrouped = [];
      // let lastPushedIdx = 0;
      // for (const [index, cidersByOneBadge] of cidersByBadge.entries()) {
      //   if (cidersByOneBadge === undefined || cidersByOneBadge.length === 0) continue;
      //   if (index > 0 && this.mergedPages[index - 1]) {
      //     cidersByBadgeGrouped[lastPushedIdx].push(cidersByOneBadge);
      //     lastPushedIdx = index - 1;
      //   } else {
      //     cidersByBadgeGrouped.push([cidersByOneBadge]);
      //   }
      //   // lastPushedIdx = index;
      // }
      // return cidersByBadgeGrouped
    },
  },
  async created() {
    this.$store.state.badgesStore.badgesData = await apiTyped.getBadges([this.tasting]);
    const flightsCidersIds = this.tables.flatMap(
      table => this.$store.getters["tableStore/getFlightsCidersIds"](table));
    const flightsCiders = await apiTyped.getFlightsCiders(flightsCidersIds, false, true);
    // Add flights data to ciders object
    const flightsData = this.tables.flatMap(table => table.flights);
    flightsCiders.forEach(f => f.flights_id = flightsData.find(f_ => f_.id === f.flights_id));
    // Create ciders
    const ciders = flightsCiders.map(
      f => JurorCider.fromApiData(
        f.ciders_id,
        this.kategorien.find(k => Number(k.id) === Number(f.ciders_id.kategorie)),
        f,
        this.$store.getters["usersStore/userId"](),
        false,
        []
      )
    );
    this.allCiders = ciders;
    this.loading = false;
  },
  methods: {
    getMergeText(index) {
      const badgesDataReversed = this.badgesData.map((item,idx) => this.badgesData[this.badgesData.length-1-idx]);
      console.log("badgesDataReversed", badgesDataReversed)
      return `${badgesDataReversed[index].stufe} und ${badgesDataReversed[index + 1].stufe}`
    },
    print() {
      window.print();
    }
  },
}
</script>

<style scoped>
.v-application {
  background-color: white !important;
}

.v-container {
  background-color: white !important;
}

.v-card {
  background-color: white !important;
  border-radius: 20px !important;
}

@media print
{
  .no-print, .no-print
  {
    display: none !important;
  }
}

* {
  font-family: 'Nunito Sans', serif !important;
}

@page {
    size: auto !important;
    margin: 0 0 0 0 !important;
}
</style>