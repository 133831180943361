<template>
  <v-app style="margin-top: 0 !important; padding-top: 0 !important;">
    <v-main
      style="background-color: white !important; overflow-x: visible;"
      class="pa-0 ma-0"
    >
      <v-container
        id="allCider"
        fluid
        class="pa-0 ma-0"
      >
        <v-btn
          fab
          fixed
          bottom
          right
          x-large
          class="mr-8 mb-8 no-print"
          @click="print"
        >
          <v-icon>
            mdi-printer
          </v-icon>
        </v-btn>
        <v-btn
          fab
          fixed
          bottom
          right
          x-large
          class="mr-8 no-print"
          style="margin-bottom: 128px;"
          @click="hideImages = !hideImages"
        >
          <v-icon v-if="!hideImages">
            mdi-eye
          </v-icon>
          <v-icon v-if="hideImages">
            mdi-eye-off
          </v-icon>
        </v-btn>
        <UrkundePageA4
          v-for="(pageCider, index) in filteredCiders"
          :key="index"
          :cider="pageCider"
          :tasting="tasting"
          :badge-data="pageCider.badgeData"
          :hide-background="hideImages"
          style="background-color: white !important; overflow: visible !important;"
          class="mt-8 mb-8 ml-auto mr-auto page_print"
        />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import UrkundePageA4 from "@/components/admin/urkunde/urkunde_page_a4";
import {sync} from "vuex-pathify";

export default {
  name: "UrkundeA4",
  components: {UrkundePageA4},
  data: () => ({
    hideImages: false,
  }),
  computed: {
    // currentTastingIdx() {
    //   return this.$store.getters["usersStore/getTastingIdxByTastingId"](Number(this.$route.query.tasting));
    // },
    // tasting: sync('usersStore/tastings@[:currentTastingIdx]'),
    tasting() {
      return this.$store.getters["usersStore/currentTasting"]();
    },
    ciders: sync("ciderStore/printCiders"),
    filteredCiders() {
      if (this.ciders === undefined) return [];
      return this.ciders.filter(cider => cider !== null).sort((c1, c2) => c2.totalPoints - c1.totalPoints);
    },
  },
  methods: {
    print() {
      window.print();
    },
  },
}
</script>

<style scoped>
@media print {
  .page_print {
    margin: 0 !important;
    padding: 0 !important;
  }
}

@page {
  size: portrait;
  margin: 0 0 0 0 !important;
}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>