import {CiderNotes, createCiderNotesFromDatabase} from "@/types/ciderNotes";
import {UserId} from "./user";
import {addBewertungenToKategorie, createKategorieRatedFromKategorie, KategorieRated} from "@/types/kategorieRated";
import {BewertungData, createBewertungFromDatabase} from "@/types/bewertung";
import {Country} from "@/types/country"
import {MyCollections} from "@/api/types/my-collections";
import {Kategorie} from "@/types/kategorie";
import {groupBy} from "@/util/helper";
import {apiTyped} from "@/api";
import {ChairNotes, createChairNotesFromDatabase} from "@/types/chairNotes";
import {Table} from "@/types/table";
import {createTranslationMessagesFromDb, TranslationMessages} from "@/types/translationMessages";
import CiderRatingState from "@/types/ciderRatingState";
import {Flight} from "@/types/flight";

// interface FlightsCider {
//     id: number;
//     sort: number;
//     flightsId: number;
//     cidersId: number;
//     requestCider: number
// }

// interface ApiJurorCider extends components["schemas"]["ItemsCiders"]

class JurorCider {
    id: number;
    flightsCidersId: number;
    flightsSort = 0;
    displayId: string;
    flightId: number;
    typ = "";
    profil = "";
    profilTranslations: TranslationMessages | null = null;
    land: Country | null = null;
    kategorie: KategorieRated;
    isChair = false;
    tableMembers: string[];
    produzentId: number | null = null;
    kategorieId: number
    bewertungen: BewertungData[] | null = null;
    allBewertungen: { [key: UserId]: BewertungData[] | null; } = {};
    allPoints: { [key: UserId]: number; } = {};
    notes: CiderNotes | null = null;
    allNotes: { [key: UserId]: CiderNotes | null; } = {};
    chairNotes: ChairNotes | null = null;
    requestCider = false;
    points: number | null = null;
    flightName = "";
    sort: number | null = null;
    table: Table | null = null;
    tableId: number | null = null;
    translations: TranslationMessages | null = null;
    chairRatingState: CiderRatingState = CiderRatingState.unknown;

    // these attributes are only available for revealed products:
    name = "";
    produzent = "";
    images: string[] = [];
    salesDesignation: string;
    designationOfOrigin: string;
    alkohol = 0.0;
    varieties = "";
    residualSugar = 0.0;
    residualSugarDescription = "";
    filtrationClarity = "";
    pressure = "";
    method = "";
    vintage = "";
    bottleContent = 0.0;
    price = "";

    public constructor(
        id: number,
        flightsCidersId: number,
        displayId: string,
        flightId: number,
        kategorie: KategorieRated,
        tableMembers: string[]
    ) {
        this.id = id;
        this.flightsCidersId = flightsCidersId;
        this.displayId = displayId;
        this.flightId = flightId;
        this.kategorie = kategorie;
        this.kategorieId = Number(kategorie.id);
        this.tableMembers = tableMembers;
        this.salesDesignation = "";
        this.designationOfOrigin = "";
        return this;
    }

    public static fromApiData(
        ciderEntry: MyCollections["ciders"],
        kategorie: Kategorie,
        flightsCidersData: MyCollections["flights_ciders"],
        userId: string,
        isChair: boolean,
        tableMembers: string[]
    ): JurorCider {
        console.log("CReating cider:", flightsCidersData, ciderEntry)
        const flightData = flightsCidersData.flights_id != null ? flightsCidersData.flights_id as Flight : null;
        if (flightData == null) {
            console.error("Wrong arguments", ciderEntry, kategorie, flightsCidersData);
            throw new Error("Wrong arguments for cider");
        }
        const newCider = new JurorCider(
            (ciderEntry.id || -1) as number,
            (flightsCidersData.id || -1) as number,
            String(flightsCidersData?.display_id
                || ciderEntry?.display_id
                || ciderEntry.revision_of
                || ciderEntry?.id
                || "-1"),
            (flightData.id || -1) as number,
            createKategorieRatedFromKategorie(kategorie),
            tableMembers
        );
        newCider.flightsSort = flightData?.sort || 0;
        newCider.updateFlightsCidersData(flightsCidersData, userId, tableMembers, true);

        newCider.isChair = isChair;
        newCider.salesDesignation = ciderEntry.sales_designation || "";
        newCider.designationOfOrigin = ciderEntry.designation_of_origin || "";
        newCider.profil = ciderEntry?.profil || "";
        newCider.typ = ciderEntry?.typ || "";
        newCider.land = typeof ciderEntry?.land === "number" ? null : <Country>ciderEntry?.land;
        newCider.flightId = flightData?.id || -1;
        newCider.flightName = flightData?.flightName || "";
        newCider.translations = createTranslationMessagesFromDb(ciderEntry?.translations || null);

        console.log("Creating newcider", ciderEntry.produzent)
        newCider.produzent = typeof ciderEntry.produzent !== "number" ? ciderEntry.produzent?.name || "" : "";
        newCider.name = ciderEntry?.name || "";
        newCider.produzentId = typeof ciderEntry.produzent !== "number"
            ? ciderEntry.produzent?.id || null
            : ciderEntry.produzent || null;
        let companyName = ((ciderEntry.produzent as MyCollections["produzenten"])
            ?.produzenten_data as MyCollections["produzenten_data"])?.company_name;
        if (ciderEntry.produzent != null && (companyName == null || companyName === "")) companyName =
            (ciderEntry.produzent as MyCollections["produzenten"])?.name || "";
        console.log("Creating newcider done", ciderEntry.produzent)
        if (newCider.produzent === "" && companyName != null) newCider.produzent = companyName;
        if (ciderEntry?.alkohol != null) newCider.alkohol = ciderEntry?.alkohol;
        if (ciderEntry?.varieties != null) newCider.varieties = ciderEntry?.varieties;
        if (ciderEntry?.residual_sugar != null) newCider.residualSugar = ciderEntry?.residual_sugar;
        if (ciderEntry?.residual_sugar_description != null)
            newCider.residualSugarDescription = ciderEntry?.residual_sugar_description;
        if (ciderEntry?.filtration_clarity != null) newCider.filtrationClarity = ciderEntry?.filtration_clarity;
        if (ciderEntry?.pressure != null) newCider.pressure = ciderEntry?.pressure;
        if (ciderEntry?.method != null) newCider.method = ciderEntry?.method;
        if (ciderEntry?.vintage != null) newCider.vintage = ciderEntry?.vintage;
        if (ciderEntry?.bottle_content != null) newCider.bottleContent = ciderEntry?.bottle_content;
        if (ciderEntry?.retail_price != null) newCider.price = ciderEntry?.retail_price;
        if (ciderEntry?.images != null) newCider.images = ciderEntry.images?.map((i: any) => i.directus_files_id) || [];
        console.log("ciderEntry?.images", ciderEntry?.images, newCider.images)
        // @ts-ignore
        newCider.profilTranslations = ciderEntry?.profil_translations || null;

        if (flightsCidersData.bewertungen != null
            && flightsCidersData.bewertungen?.length > 0
            && flightsCidersData.bewertungen?.every(b => typeof b !== "number")) {
            newCider.addAllJurorBewertungen(flightsCidersData.bewertungen as MyCollections["bewertungen"][], "");
        }

        return newCider;
    }

    public updateFlightsCidersData(
        flightsCidersData: MyCollections["flights_ciders"],
        userId: string,
        newTableMembers: string[] | null = null,
        overwriteJudgesNotes = false
    ) {
        const jurorNotes = flightsCidersData.cider_notes != null ?
            flightsCidersData.cider_notes as MyCollections["cider_notes"][] : [];
        const chairNotes = flightsCidersData.chair_note != null && flightsCidersData.chair_note.length > 0
            ? (flightsCidersData.chair_note[0] as MyCollections["chair_notes"]) : null;
        const bewertungen = flightsCidersData.bewertungen != null
            ? flightsCidersData.bewertungen as MyCollections["bewertungen"][] : [];

        if (newTableMembers != null) {
            this.updateTableMembers(newTableMembers);
        }
        this.displayId = flightsCidersData?.display_id || this.displayId || String(this.id) || "-1";
        this.sort = flightsCidersData.sort != null ? flightsCidersData.sort : this.id;
        if (flightsCidersData?.request_cider != null) this.requestCider = flightsCidersData?.request_cider || false;
        if (flightsCidersData?.points != null) this.points = flightsCidersData?.points || null;
        if (chairNotes != null) {
            this.chairNotes = createChairNotesFromDatabase(chairNotes as MyCollections["chair_notes"]);
        }
        this.addNotes(jurorNotes, userId, overwriteJudgesNotes);
        this.addAllJurorBewertungen(bewertungen, userId);
    }

    public updateTableMembers(tableMembers: string[]) {
        this.tableMembers = tableMembers;
        for (const tableMemberId of tableMembers) {
            if (!this.allNotes.hasOwnProperty(tableMemberId)) {
                this.allNotes[tableMemberId] = null;
            }
            if (!this.allBewertungen.hasOwnProperty(tableMemberId)) {
                this.allBewertungen[tableMemberId] = null;
            }
        }
    }

    public addNotes(allNotes: MyCollections["cider_notes"][], userId: string, overwriteJudgesNotes = false) {
        for (const apiNote of allNotes) {
            const note = createCiderNotesFromDatabase(apiNote);
            if (overwriteJudgesNotes) {
                this.allNotes[note.userId] = note;
            } else {
                if (this.allNotes.hasOwnProperty(note.userId)) {
                    console.warn("More than one Note Entry found for Juror:", note.userId, allNotes, this.allNotes);
                } else {
                    this.allNotes[note.userId] = note;
                }
            }
        }
        if (allNotes.length > 0 && this.allNotes?.hasOwnProperty(userId)) {
            this.notes = this.allNotes[userId];
        }
    }

    public addBewertungen(bewertungen: MyCollections["bewertungen"][]) {
        addBewertungenToKategorie(this.kategorie, bewertungen);
        this.bewertungen = bewertungen.map(bewertung => createBewertungFromDatabase(bewertung, this.kategorie));
    }

    public addAllJurorBewertungen(bewertungen: MyCollections["bewertungen"][], userId: string) {
        console.log("ADding rating", bewertungen)
        if (bewertungen.length === 0) return;
        const bewertungenGroupedByJuror = groupBy(bewertungen,
            ((bewertung: MyCollections["bewertungen"]) => bewertung.juror_id));
        for (const jurorId in bewertungenGroupedByJuror) {
            if (!bewertungenGroupedByJuror.hasOwnProperty(jurorId)) continue;
            const bewertungenByJuror: MyCollections["bewertungen"][] = bewertungenGroupedByJuror[jurorId];
            if (userId === jurorId) {
                this.addBewertungen(bewertungenByJuror);
            }
            const newBewertungen = bewertungenByJuror.map(
                bewertung => createBewertungFromDatabase(bewertung, this.kategorie));
            this.allBewertungen[jurorId] = newBewertungen;
        }
    }

    public async createNotes(userId: string) {
        if (this.isChair && this.chairNotes == null) {
            const chairNotes = await apiTyped.createChairNoteForCider(this, userId);
            this.chairNotes = createChairNotesFromDatabase(chairNotes);
            console.log("Created Chair notes")
        }
        console.log("Created notes")
        const jurorNote = await apiTyped.createNoteForCider(this, userId);
        this.addNotes([jurorNote], userId, true);
    }

    public async createBewertungen(userId: string) {
        const eigenschaftIds = this.kategorie.bewertungskategorien.flatMap(
            bewertungskategorie => bewertungskategorie.bewertungen.map(
                bewertung => bewertung.eigenschaftId));
        const bewertungen = await apiTyped.createBewertungenForCider(eigenschaftIds, this, userId);
        if (bewertungen == null) {
            console.log("Error creating Ratings for Cider", this);
            throw new Error("Error creating Ratings for Cider" + this.id);
        }
        this.addAllJurorBewertungen(bewertungen, userId);
    }
}

// function createCider(ciderEntry: MyCollections["ciders"],
//                      kategorie: Kategorie | null = null,
//                      notes: MyCollections["cider_notes"] | null = null,
//                      flight: MyCollections["flights"] | null = null,
//                      flightsCidersData: MyCollections["flights_ciders"] | undefined = undefined): JurorCider {
//     const newCider = new JurorCider(1,1,"1",1, createKategorieRatedFromKategorie(kategorie as Kategorie), []);
//     newCider.id = ciderEntry?.id || -1;
//     newCider.displayId = flightsCidersData?.display_id || ciderEntry?.display_id || String(ciderEntry?.id) || "-1";
//     newCider.name = ciderEntry?.name || "";
//     newCider.profil = ciderEntry?.profil || "";
//     newCider.typ = ciderEntry?.typ || "";
//     newCider.land = typeof ciderEntry?.land === "number" ? null : <Country>ciderEntry?.land;
//     if (kategorie !== null) {
//         newCider.kategorieId = kategorie.id;
//         newCider.kategorie = createKategorieRatedFromKategorie(kategorie);
//     }
//     newCider.flightsCidersId = flightsCidersData!.id || -1;
//     newCider.requestCider = flightsCidersData?.request_cider || false;
//     newCider.flightId = flight?.id || -1;
//     newCider.flightName = flight?.name || "";
//     newCider.notes = notes != null ? createCiderNotesFromDatabase(notes) : null;
//     newCider.produzent = typeof ciderEntry.produzent !== "number" ? ciderEntry.produzent?.name || "" : "";
//     newCider.produzentId = typeof ciderEntry.produzent !== "number" ? ciderEntry.produzent?.id || -1 : -1;
//     newCider.translations = createTranslationMessagesFromDb(ciderEntry?.translations || null);
//     if (ciderEntry?.alkohol != null) newCider.alkohol = ciderEntry?.alkohol;
//     if (ciderEntry?.varieties != null) newCider.varieties = ciderEntry?.varieties;
//     if (ciderEntry?.residual_sugar != null) newCider.residualSugar = ciderEntry?.residual_sugar;
//     if (ciderEntry?.residual_sugar_description != null) newCider.residualSugarDescription = ciderEntry?.residual_sugar_description;
//     if (ciderEntry?.filtration_clarity != null) newCider.filtrationClarity = ciderEntry?.filtration_clarity;
//     if (ciderEntry?.pressure != null) newCider.pressure = ciderEntry?.pressure;
//     if (ciderEntry?.method != null) newCider.method = ciderEntry?.method;
//     // @ts-ignore
//     newCider.profilTranslations = ciderEntry?.profil_translations || null;
//     // newCider.allBewertungen = new Map<UserId, BewertungData[]>();
//     // newCider.allNotes = new Map<UserId, CiderNotes>();
//     return newCider;
// }

export async function addBewertungenToCidersKategorieRated(
    ciders: JurorCider[], bewertungen: MyCollections["bewertungen"][], tastingId: number): Promise<void> {
    const bewertungenGrouped = groupBy(bewertungen, ((bewertung: MyCollections["bewertungen"]) => bewertung.cider_id));
    // for (const [ciderId, values] of Object.entries(bewertungenGrouped)) {
    console.log("bewertungen", bewertungen, bewertungenGrouped)
    for (const cider of ciders) {
        // // const values = bewertungenGrouped[ciderId] as Bewertung;
        // const ciderIdx = ciders.findIndex(
        //     (cider: Cider) => cider.id === Number(ciderId)
        //         && cider.flightId === Number((values as MyCollections["bewertungen"][])[0].flight_id));
        // if (ciderIdx === -1) {
        //     console.error("Error finding Cider for Id:", ciderId, ciders);
        //     continue;
        // }
        // const cider: Cider = ciders[ciderIdx];
        const values = bewertungenGrouped[cider.id];  // possible bewertungen
        console.log("VLAUES", values, bewertungenGrouped, cider.id)
        const bewertungen = (values as any[]).filter(
            v => v.cider_id === cider.id && v.flight_id === cider.flightId) as MyCollections["bewertungen"][];
        console.log("Bewertungen for coder", cider.id, cider.flightId, bewertungen)
        if (cider.kategorie === undefined) {
            const newCiders = await apiTyped.addKategorienToCiders([cider], tastingId);
            cider.kategorie = newCiders[0].kategorie;
        }
        if (cider.kategorie === undefined) continue
        console.log("Adding b to c", cider, bewertungen)
        addBewertungenToKategorie(cider.kategorie, bewertungen);
        cider.bewertungen = bewertungen.map((bewertung: MyCollections["bewertungen"]) =>
            createBewertungFromDatabase(bewertung, cider.kategorie as KategorieRated));
    }
}

export async function addAllBewertungenToCiders(
    ciders: JurorCider[], bewertungen: MyCollections["bewertungen"][] = [], userIds: UserId[] = []): Promise<void> {
    if (bewertungen.length === 0) {
        // bewertungen = await apiTyped.getBewertungen(ciders, store.getters["tableStore/getTableMembersIds"]());
        bewertungen = await apiTyped.getBewertungen(ciders, userIds);
    }
    // console.log("Got bewertungen:", ciders, bewertungen)
    const bewertungenGrouped = groupBy(bewertungen,
        ((bewertung: MyCollections["bewertungen"]) => bewertung.cider_id));
    for (const ciderId in bewertungenGrouped) {
        if (!bewertungenGrouped.hasOwnProperty(ciderId)) continue;
        const bewertungenGroupedByCider: MyCollections["bewertungen"][] = bewertungenGrouped[ciderId];
        const bewertungenGroupedByFlight = groupBy(bewertungenGroupedByCider,
            ((bewertung: MyCollections["bewertungen"]) => bewertung.flight_id));
        for (const flightId in bewertungenGroupedByFlight) {
            if (!bewertungenGroupedByFlight.hasOwnProperty(flightId)) continue;
            const bewertungen_: MyCollections["bewertungen"][] = bewertungenGroupedByFlight[flightId];
            const bewertungenGroupedByJuror = groupBy(bewertungen_,
                ((bewertung: MyCollections["bewertungen"]) => bewertung.juror_id));
            // Find Cider by checking the bewertungen for both CiderId and FlightId,
            // in Case multiple Flights have the same Cider!
            const ciderIdx = ciders.findIndex((cider: JurorCider) =>
                cider.id === Number(ciderId) && cider.flightId === Number(flightId));
            for (const juror in bewertungenGroupedByJuror) {
                if (!bewertungenGroupedByJuror.hasOwnProperty(juror)) continue;
                const bewertungByJuror: MyCollections["bewertungen"][] = bewertungenGroupedByJuror[juror];
                const cider = ciders[ciderIdx];
                addAllBewertungenForJurorToCider(cider, bewertungByJuror);
            }
        }
    }
}

function addAllBewertungenForJurorToCider(
    cider: JurorCider, bewertungenByJuror: MyCollections["bewertungen"][]
): void {
    if (cider == null) return;
    const newBewertungen = bewertungenByJuror.map(
        bewertung => createBewertungFromDatabase(bewertung, cider.kategorie as KategorieRated));
        if (cider.allBewertungen == null) {
            cider.allBewertungen = {};
    }
    if (newBewertungen[0].jurorId !== undefined) {
        cider.allBewertungen[newBewertungen[0].jurorId] = newBewertungen || null;
    }
}

// export async function addAllNotesForCiders(ciders: JurorCider[], userIds: UserId[] = []): Promise<void> {
//     const allNotes = await apiTyped.getAllNotesForCiders(ciders, userIds);
//     for (const cider of ciders) {
//         await addAllNotesForCider(cider, userIds, allNotes);
//     }
// }
//
// export async function addAllNotesForCider(cider: JurorCider, userIds: UserId[] = [],
//                                           allNotes: MyCollections["cider_notes"][] = []): Promise<void> {
//     if (allNotes.length === 0) {
//         allNotes = await apiTyped.getAllNotesForCider(cider, userIds);
//     }
//     allNotes = allNotes.filter(note =>
//         note.cider_id === cider.id && note.flight_id === cider.flightId);
//     const allNotesGroupedByJuror = groupBy(allNotes,
//         ((note: MyCollections["cider_notes"]) => note.juror_id));
//     for (const juror in allNotesGroupedByJuror) {
//         if (!allNotesGroupedByJuror.hasOwnProperty(juror)) continue;
//         const notesByJuror: MyCollections["cider_notes"][] = allNotesGroupedByJuror[juror];
//         const notes = notesByJuror.map(note => createCiderNotesFromDatabase(note));
//         if (cider.allNotes === undefined) {
//             // cider.allNotes = new Map<string, CiderNotes>();
//             cider.allNotes = {};
//         }
//         if (notes.length > 1) {
//             console.warn("More than one Note Entry found for Juror:", juror, notes.map(n => n.id), notes);
//         }
//         cider.allNotes[juror] = notes[0];
//     }
// }

// export async function addAbschlusskommentarForCider(cider: Cider): Promise<void> {
//     const ciderId = cider.id;
//     const abschlusskommentar = await apiTyped.getAbschlusskommentar(ciderId);
//     cider.abschlusskommentar = createAbschlusskommentarFromDatabase(abschlusskommentar);
// }

// export { createCider }

export default JurorCider;