<template>
  <v-theme-provider light>
    <v-container>
      <v-select
        v-if="created"
        v-model="selectedTables"
        :items="tables"
        item-value="id"
        return-object
        multiple
        :item-text="item => `${item.nummer}`"
        style="max-width: 200px;"
        class="no-print ml-auto mr-auto"
        label="Tables"
      >
        <template #prepend-item>
          <v-list-item
            ripple
            @click="toggleAllTables"
          >
            <v-list-item-action>
              <v-icon :color="selectedTables.length > 0 ? 'primary' : ''">
                {{ selectionIcon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('selectAll') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2" />
        </template>
        <template #selection="{ index }">
          <v-chip
            v-if="index === 0"
          >
            <span>{{ selectedAllTables ? "All" : selectedTables.length }} Tables</span>
          </v-chip>
        </template>
      </v-select>
      <page
        v-if="created"
        ref="pageA4"
        size="A4"
        class="pa-8 pb-2 ma-0"
        style="transform-origin: 0 0; overflow: scroll;"
        :style="{'background-color': $vuetify.theme.currentTheme.page_background}"
      >
        <v-card
          v-for="table in tables"
          :key="table.nummer"
          width="500"
          height="600"
          style="page-break-inside: avoid;"
        >
          <span>{{ table.nummer }}</span>
        </v-card>
      </page>
    </v-container>
  </v-theme-provider>
</template>

<script>
import {get} from "vuex-pathify";
import service_mixin from "@/components/mixins/service_mixin";

export default {
  name: "PrintTastingTablesView",
  mixins: [service_mixin],
  data: () => ({
    selectedTables: [],
    created: false,
    tables: null,
    cidersByTable: null,
  }),
  computed: {
    currentTasting: get('serviceStore/currentTasting'),
    currentTastingId: get('serviceStore/currentTastingId'),
    selectedAllTables() {
      return this.selectedTables.length === this.tables.length;
    },
    selectionIcon() {
      if (this.selectedAllTables) return 'mdi-close-box'
      if (this.selectedTables.length > 0) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  async created() {
    this.$vuetify.theme.dark = false;
    await this.loadTables();
    await this.loadTables();
    this.selectedTables = this.tables;
    this.created = true;
  },
  methods: {
    toggleAllTables() {
      this.$nextTick(() => {
        if (this.selectedAllTables) {
          this.selectedTables = [];
        } else {
          this.selectedTables = this.tables.slice();
        }
      });
    },
  },
}
</script>

<style scoped>
page[size="A4"] {
    width: 21cm;
    height: 29.7cm;
    min-height: 29.7cm;
    min-width: 21cm;
    max-height: 29.7cm;
    max-width: 21cm;
    overflow: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

page::-webkit-scrollbar {
    display: none;
}

page {
    display: block;
    margin: 0 auto 0.5cm;
}

@media print {
    page {
        width: 21cm;
        height: 29.7cm;
        min-height: 29.7cm;
        min-width: 21cm;
        max-height: 29.7cm;
        max-width: 21cm;
        margin: 0 !important;
        padding: 0 !important;
        box-shadow: unset !important;
    }
}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>