<template>
  <div
    class="ml-auto mr-auto pl-3 pr-3"
    style="max-width: 2000px;"
  >
    <div
      class="ml-auto mr-auto pb-4"
    >
      <v-row
        flat
        color="background"
        class="d-flex pa-0 pt-0 pb-0 ma-0"
      >
        <v-spacer />
        <v-btn
          rounded
          outlined
          elevation="0"
          class="mt-0"
          @click="startCiderSubmit"
        >
          <v-icon left>
            mdi-basket-plus
          </v-icon>
          {{ $t('submitProducts') }}
        </v-btn>
      </v-row>
    </div>

    <v-row
      class="mb-2 pt-2 align-center d-flex justify-space-between"
      no-gutters
    >
      <v-col
        cols="5"
        class="d-flex justify-start"
      >
        <div>
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                style="text-transform: unset;"
                text
                rounded
                class="pr-0"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>
                  mdi-sort-variant
                </v-icon>
                {{ sortByText }}
                <v-btn
                  class="ml-1 animate_change"
                  icon
                  :class="{'rotate-180': !sortAsc}"
                  @click="sortAsc = !sortAsc"
                  @click.native.stop=""
                >
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </v-btn>
            </template>
            <v-list>
              <v-subheader>
                {{ $t('sortBy') }}
              </v-subheader>
              <v-list-item
                v-for="(item, index) in sortByList"
                :key="index"
                link
                @click="sortBy = item.value; sortByText = item.text;"
              >
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
      <v-col
        cols="7"
        class="d-flex justify-end"
      >
        <v-text-field
          v-model="searchText"
          append-icon="mdi-magnify"
          :label="$t('search')"
          outlined
          clearable
          dense
          rounded
          single-line
          hide-details
          class="ml-auto"
          style="width: 100%; max-width: 400px;"
        >
          <template #append>
            <v-progress-circular
              v-if="loading"
              indeterminate
              class="mt-auto mb-auto ml-auto mr-auto"
              size="24"
              width="2"
            />
          </template>
        </v-text-field>
      </v-col>
    </v-row>

    <div
      v-if="openTastings !== null && loaded && openTastings.length > 0"
      class="d-flex text-left pl-0 pb-4 text-h2 font-weight-medium align-center"
      style="font-size: 36px !important; font-family: 'Nunito Sans', serif !important;"
    >
      {{ $t('availableTastings') }}
    </div>
    <template v-if="openTastings !== null && loaded && openTastings.length > 0" >
      <v-row>
        <v-col
          v-for="tasting in sortTastings(openTastings)"
          :key="tasting.id"
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="4"
        >
          <tasting-info-card
            :tasting="tasting"
          >
            <template #banner>
              <v-alert
                v-ripple
                type="success"
                border="left"
                rounded
                text
                class="text-left my-0 mx-4"
                style="cursor: pointer;"
                icon="mdi-basket-plus"
                @click="$router.push('/producer/submit/tastings')"
              >
                {{ $t('submissionOpenUntil', {deadline: new Date(tasting.einreichenBis).toLocaleString()}) }}
                <template #append>
                  <v-icon color="primary">
                    mdi-arrow-right
                  </v-icon>
                </template>
              </v-alert>
            </template>
          </tasting-info-card>
        </v-col>
      </v-row>
    </template>


    <v-row v-if="myTastings !== null && loaded && myTastings.length > 0">
      <v-col
        cols="12"
        md="10"
      >
        <div
          class="d-flex text-left pl-0 pb-4 text-h2 font-weight-medium align-center"
          style="font-size: 36px !important; font-family: 'Nunito Sans', serif !important;"
        >
          {{ $t('myEvents') }}
        </div>
      </v-col>
      <v-col
        v-if="isSmallScreen && moreMyTastings"
        cols="12"
        md="2"
        class="d-flex justify-end align-center"
      >
        <v-btn
          text
          class="mb-2"
        >
          {{ $t('showMore') }}
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="myTastings !== null && loaded && myTastings.length > 0">
      <div
        class="gallery px-4 pb-6"
        style="margin-left: -32px; width: 100vw;"
      >
        <div class="wrapper">
          <tasting-info-card
            v-for="tasting in sortTastings(myTastings)"
            :key="tasting.id"
            :tasting="tasting"
            style="width: min(80vw, 400px);"
            class="mx-2 item"
          />
          <v-btn
            v-if="moreMyTastings"
            text
            height="80"
            class="rounded-lg"
          >
            <div class="text--secondary">
              <div>
                <v-icon class="pb-2">
                  mdi-arrow-right
                </v-icon>
              </div>
              <div>
                {{ $t('showMore') }}
              </div>
            </div>
          </v-btn>
        </div>
      </div>
    </template>

    <v-container
      v-if="loaded && myTastings != null && myTastings.length === 0"
      class="ml-auto mr-auto"
      style="position: relative; min-height: 400px"
    >
      <v-hover
        v-slot="{ hover }"
      >
        <v-card
          class="center-on-page pb-4"
          width="300"
          outlined
          :elevation="hover ? 12 : 0"
          style="border-style: dashed; border-width: medium; cursor: pointer; transition: box-shadow 0.2s ease-in-out;"
          @click="startCiderSubmit"
        >
          <v-icon size="192">
            mdi-basket-plus-outline
          </v-icon>
          <v-card-subtitle class="pb-2">
            {{ $t('noEventsSubmittedYet') }}
          </v-card-subtitle>
          <v-card-actions>
            <v-btn
              class="ml-auto mr-auto text-center"
              rounded
              outlined
              text
            >
              {{ $t('submitProduct') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-hover>
    </v-container>

    <div
      v-if="upcomingTastings !== null && loaded && upcomingTastings.length > 0"
      class="d-flex text-left pl-0 pb-4 text-h2 font-weight-medium align-center"
      style="font-size: 36px !important; font-family: 'Nunito Sans', serif !important;"
    >
      {{ $t('upcomingTastings') }}
    </div>
    <template
      v-if="upcomingTastings !== null && loaded && upcomingTastings.length > 0"
    >
      <v-row>
        <v-col
          v-for="tasting in sortTastings(upcomingTastings)"
          :key="tasting.id"
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="4"
        >
          <tasting-info-card :tasting="tasting" />
        </v-col>
      </v-row>
    </template>

    <v-row v-if="pastTastings !== null && loaded && pastTastings.length > 0">
      <v-col
        cols="12"
        md="10"
      >
        <div
          class="d-flex text-left pl-0 pb-4 text-h2 font-weight-medium align-center"
          style="font-size: 36px !important; font-family: 'Nunito Sans', serif !important;"
        >
          {{ $t('pastEvents') }}
        </div>
      </v-col>
      <v-col
        v-if="isSmallScreen && morePastTastings"
        cols="12"
        md="2"
        class="d-flex justify-end align-center"
      >
        <v-btn
          text
          class="mb-2"
        >
          {{ $t('showMore') }}
        </v-btn>
      </v-col>
    </v-row>

    <template
      v-if="pastTastings !== null && loaded && pastTastings.length > 0"
    >
      <div
        class="gallery px-4"
        style="margin-left: -32px; width: 100vw;"
      >
        <div class="wrapper">
          <tasting-info-card
            v-for="tasting in sortTastings(pastTastings)"
            :key="tasting.id"
            :tasting="tasting"
            style="width: min(80vw, 400px);"
            class="mx-2 item"
          />
          <v-btn
            v-if="morePastTastings"
            text
            height="80"
            class="rounded-lg"
          >
            <div class="text--secondary">
              <div>
                <v-icon class="pb-2">
                  mdi-arrow-right
                </v-icon>
              </div>
              <div>
                {{ $t('showMore') }}
              </div>
            </div>
          </v-btn>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {apiTyped} from "@/api";
import TastingInfoCard from "@/components/producer/tasting_info_card.vue";
import translation_mixin from "@/components/mixins/translation_mixin";

export default {
  name: "ProducerMyTastings",
  components: {TastingInfoCard},
  mixins: [translation_mixin],
  data: () => ({
    getTastingsTimeout: null,
    loading: false,
    searchText: "",
    myTastings: null,
    pastTastings: null,
    upcomingTastings: null,
    openTastings: null,
    lastSelectedTastingId: null,
    submittedCiders: null,
    loaded: false,
    moreMyTastings: false,
    morePastTastings: false,
    sortBy: "datum",
    sortByText: "",
    sortAsc: true,
  }),
  computed: {
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return true
        default:
          return false
      }
    },
    sortByList() {
      return [
        {
          text: "Name",
          value: 'name'
        },
        {
          text: this.$t('date'),
          value: 'datum'
        }
      ];
    },
  },
  i18n: {
    messages: {
      de: {
        "submissionOpenUntil": "Anmeldung geöffnet bis {deadline}"
      },
      en: {
        "submissionOpenUntil": "Submission open until {deadline}"
      }
    }
  },
  watch: {
    searchText: function (newValue) {
      if (newValue !== "") {
        this.fetchTastingsDebounced();
      }
    },
    sortBy: function (newValue) {
      if (newValue !== "") {
        this.fetchTastingsDebounced();
      }
    },
    sortAsc: function () {
      this.fetchTastingsDebounced();
    },
  },
  async created() {
    this.sortByText = this.$t('date');
    await this.getTastings();
    // this.submittedCiders = await Promise.all(this.myTastings.map(t => apiTyped.getSubmittedCidersForTasting(t.id)));
    this.loaded = true;
  },
  methods: {
    sortTastings(tastings) {
      return tastings;
      // return [...tastings].sort((t1, t2) => {
      //   if (this.sortBy === "name") {
      //     if (this.sortAsc) return t2.name.localeCompare(t1.name);
      //     else return t1.name.localeCompare(t2.name);
      //   }
      //   else if (this.sortBy === "date") {
      //     if (this.sortAsc) return t2.datum - t1.datum;
      //     else return t1.datum - t2.datum;
      //   }
      // });
    },
    fetchTastingsDebounced() {
      // cancel pending call
      if (this.getTastingsTimeout != null) {
        clearTimeout(this.getTastingsTimeout)
      }
      // delay new call 500ms
      this.getTastingsTimeout = setTimeout(async () => { await this.getTastings() }, 500)
    },
    async getTastings() {
      // TODO(mo): pagination
      this.loading = true;
      const searchText = this.searchText !== "" ? this.searchText : null;
      const sortByStr = this.sortAsc ? ["-" + this.sortBy] : [this.sortBy];
      console.log("Getting my tastings")
      this.myTastings = await apiTyped.producerGetMyTastings(
        0, 1, 6, null, true, searchText, sortByStr);
      console.log("Got my tastings", this.myTastings)
      this.moreMyTastings = this.myTastings.length > 5;
      this.pastTastings = await apiTyped.producerGetPastTastings(
        0, 1, 6, null, this.myTastings.map(t => t.id), true, searchText, sortByStr);
      this.morePastTastings = this.pastTastings.length > 5;
      this.upcomingTastings = await apiTyped.producerGetUpcomingTastings(
        0, 1, -1, null, this.myTastings.map(t => t.id), true, searchText, sortByStr);
      console.log("upcomingTastings", this.upcomingTastings)
      this.openTastings = await apiTyped.producerGetOpenTastings(
        0, 1, -1, null, this.myTastings.map(t => t.id), true, searchText, sortByStr);
      this.loading = false;
    },
    async reloadTasting(index) {
      await apiTyped.deleteSubmittedCidersForTasting(this.submittedCiders[index]);
      this.myTastings = await apiTyped.producerGetAvailableTastings();
      this.pastTastings = await apiTyped.producerGetPastTastings(
        0, 1, 50, null, this.myTastings.map(t => t.id));
      this.upcomingTastings = await apiTyped.producerGetUpcomingTastings(
        0, 1, 50, null, this.myTastings.map(t => t.id));
      this.openTastings = await apiTyped.producerGetOpenTastings(
          0, 1, 50, null, this.myTastings.map(t => t.id));
      this.submittedCiders = await Promise.all(this.myTastings.map(t => apiTyped.getSubmittedCidersForTasting(t.id)));
    },
    startCiderSubmit() {
      this.$store.commit("submitStore/clear");
      this.$router.push('submit/tastings');
    },
  }
}
</script>

<style scoped>
.center-on-page {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
}


.gallery {
  grid-column: full;
  display: grid;
  grid-template-columns: inherit;
  padding-block: var(--space-md);
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  scroll-snap-type: x proximity;
  justify-items: start;
  justify-content: start;
  scrollbar-width: thin;
}

.gallery::-webkit-scrollbar {
  inline-size: 0 !important;
  display: none;
}

.gallery .wrapper {
  grid-column: content;
  display: flex;
  align-items: center;
  gap: var(--space);
}

.gallery .wrapper::after {
  content: "";
  align-self: stretch;
  padding-inline-end: max(
    var(--space),
    (100vw - var(--content-max-width)) / 2 - var(--space)
  );
}

.gallery .item {
  display: flex;
  align-items: stretch;
  align-self: stretch;
  scroll-snap-align: center;
  inline-size: 100%;
  max-inline-size: 25rem;
  font-size: 2rem;
  background: hotpink;
  border-radius: 4px;
  overflow: hidden;
}

.gallery.alt-sizes .item.lg {
  max-inline-size: 35rem;
}

.gallery.alt-sizes .item.sm {
  max-inline-size: 15rem;
}

.gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@supports not (aspect-ratio: 1) {
  .gallery .item::before {
    float: left;
    padding-top: 56.25%;
    content: "";
  }

  .gallery .item::after {
    display: block;
    content: "";
    clear: both;
  }
}

.rotate-180 {
  -moz-transform:rotate(-180deg);
  -webkit-transform:rotate(-180deg);
  transform:rotate(-180deg);
}
</style>