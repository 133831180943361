<template>
  <v-app
    id="app"
    style="margin-top: 0 !important; overflow-y: auto;"
  >
    <v-main style="background-color: white !important; overflow-x: visible;">
      <v-container
        fluid
        class="pa-0 ma-0 ml-auto mr-auto"
        style="background-color: white !important; overflow: visible !important;"
      >
        <v-theme-provider light>
          <Rangliste v-if="!loading" />
        </v-theme-provider>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Rangliste from "@/components/admin/rangliste/rangliste";
import {apiTyped} from "@/api";
export default {
  name: "RanglisteA4",
  components: {Rangliste},
  data: () => ({
    loading: true,
  }),
  async created() {
    this.$vuetify.theme.dark = false;
    console.log("LOggin in")
    this.$store.commit("usersStore/setUser", await apiTyped.loginRefresh());
    console.log("LOGGED IN")
    this.loading = false;
  }
}
</script>

<style scoped>
/*#app { 	background: white !important; }*/
/*/deep/ * {*/
/*  background: white !important;*/
/*  background-color: white !important;*/
/*}*/
</style>