//import Vue from 'vue'
//import Vuex from 'vuex'
//import pathify, {make} from 'vuex-pathify'
//import createPersistedState from 'vuex-persistedstate';
//import api from "@/api";
// import Math
// import wines_store from "@/store/wines"
// import app_bar_store from "@/store/app_bar"

const util = {

    round_to_decimal(value, dec_place = 0.5) {
        const inv = 1.0 / dec_place;
        return Math.round(value * inv) / inv;
    }

}
//const getters = {
// round(number, digits) {
//     let result;
//     if (digits == 0) {
//
//         result = Math.round(number);
//     } else {
//         result = 'NOT positive';
//      }
//      return result;
//  }

//}


//const util = new ApiAccess();
// Object.freeze(api);

export default {
    util
}

