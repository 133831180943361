var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-stepper',{staticClass:"mt-12 ml-auto mr-auto mb-6",staticStyle:{"max-width":"1250px","min-height":"90px","background-color":"#FFFFFF00","box-shadow":"transparent","overflow":"visible !important"},attrs:{"alt-labels":"","flat":"","elevation":"0","non-linear":"","color":"background"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-stepper-header',{staticClass:"elevation-0",staticStyle:{"justify-content":"space-between"}},[_c('v-stepper-step',{attrs:{"step":_vm.stepNumber(_vm.submitTastingSteps.selectTasting),"editable":_vm.stepSelectTastingEditable,"complete":_vm.currentStep === _vm.submitTastingSteps.selectTasting
        || _vm.currentStep === _vm.submitTastingSteps.selectTastingProducts
        || _vm.currentStep === _vm.submitTastingSteps.selectCiders
        || _vm.currentStep === _vm.submitTastingSteps.confirmCiders
        || _vm.currentStep === _vm.submitTastingSteps.checkoutSummary
        || _vm.currentStep === _vm.submitTastingSteps.checkoutCompleted,"edit-icon":"mdi-check"},on:{"click":function($event){_vm.currentStep !== _vm.submitTastingSteps.selectTasting ? _vm.$router.push("/producer/submit/tastings") : ''}}},[_vm._v(" "+_vm._s(_vm.$t('paymentHandling.selectTasting'))+" "),(_vm.selectedTasting != null)?_c('small',{staticClass:"pt-1",staticStyle:{"line-height":"1 !important"}},[_vm._v(" "+_vm._s(_vm.selectedTasting.name)+", "),_c('br'),_vm._v(" "+_vm._s(new Date(_vm.selectedTasting.datum).toLocaleDateString())+" ")]):_vm._e()]),_c('v-divider',{class:{small_divider: _vm.isSmallScreenCss, big_divider: !_vm.isSmallScreenCss}}),_c('v-stepper-step',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasPurchasables),expression:"hasPurchasables"}],attrs:{"step":_vm.stepNumber(_vm.submitTastingSteps.selectTastingProducts),"editable":_vm.stepSelectTastingProductsEditable,"complete":_vm.currentStep === _vm.submitTastingSteps.selectTastingProducts
        || _vm.currentStep === _vm.submitTastingSteps.selectCiders
        || _vm.currentStep === _vm.submitTastingSteps.confirmCiders
        || _vm.currentStep === _vm.submitTastingSteps.checkoutSummary
        || _vm.currentStep === _vm.submitTastingSteps.checkoutCompleted,"edit-icon":"mdi-check"},on:{"click":function($event){_vm.stepSelectTastingProductsEditable ? _vm.$router.push("/producer/submit/selectTastingProducts") : ''}}},[_vm._v(" "+_vm._s(_vm.$t('paymentHandling.selectTastingProducts'))+" "),_c('small',{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.selectedTastingProducts.length)+" "+_vm._s(_vm.$t('paymentHandling.productsSelected'))+" ")])]),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasPurchasables),expression:"hasPurchasables"}],class:{small_divider: _vm.isSmallScreenCss, big_divider: !_vm.isSmallScreenCss}}),_c('v-expand-x-transition',[_c('v-stepper-step',{directives:[{name:"show",rawName:"v-show",value:(!_vm.awardExcluded),expression:"!awardExcluded"}],attrs:{"step":_vm.stepNumber(_vm.submitTastingSteps.selectCiders),"editable":_vm.stepSelectCidersEditable,"complete":(_vm.currentStep === _vm.submitTastingSteps.selectCiders
          && (_vm.selectExactAmount === false && _vm.selectedCiders.length > 0 || _vm.selectExactAmount === true && _vm.selectedCiders.length === _vm.maxSelection))
          || _vm.currentStep === _vm.submitTastingSteps.confirmCiders
          || _vm.currentStep === _vm.submitTastingSteps.checkoutSummary
          || _vm.currentStep === _vm.submitTastingSteps.checkoutCompleted,"edit-icon":"mdi-check"},on:{"click":function($event){_vm.stepSelectCidersEditable ? _vm.$router.push("/producer/submit/selectCiders") : ''}}},[_vm._v(" "+_vm._s(_vm.$t('paymentHandling.selectProducts'))+" "),(_vm.maxCiders !== null && _vm.currentStep === _vm.submitTastingSteps.selectCiders)?_c('small',{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.$t('paymentHandling.selectUpTo01'))+" "+_vm._s(_vm.maxCiders)+" "+_vm._s(_vm.$t('paymentHandling.selectUpTo02'))+" ")]):_vm._e(),(_vm.selectedTasting != null && !_vm.selectedTasting.tastingSettings.allowProductDataUpdate && _vm.selectedCiders.length > 0)?_c('small',{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.selectedCiders.length)+" "+_vm._s(_vm.$t('paymentHandling.cidersSelected'))+" ")]):_vm._e(),(_vm.selectedTasting != null && _vm.selectedTasting.tastingSettings.allowProductDataUpdate && !_vm.selectExactAmount && _vm.submitProductsWithoutData)?_c('small',{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.selectedProductAmount)+" "+_vm._s(_vm.$t('paymentHandling.cidersSelected'))+" ")]):_vm._e(),(_vm.selectedTasting != null && _vm.selectedTasting.tastingSettings.allowProductDataUpdate && _vm.selectedCiders.length > 0 && !_vm.submitProductsWithoutData)?_c('small',{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.selectedCiders.length)+" "+_vm._s(_vm.maxSelection ? ' / ' + _vm.maxSelection : '')+" "+_vm._s(_vm.$t('paymentHandling.cidersSelected'))+" ")]):_vm._e()])],1),_c('v-expand-x-transition',[_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.awardExcluded),expression:"!awardExcluded"}],class:{small_divider: _vm.isSmallScreenCss, big_divider: !_vm.isSmallScreenCss}})],1),(!_vm.awardExcluded && _vm.submitProductsWithoutData === false)?_c('v-stepper-step',{attrs:{"step":_vm.stepNumber(_vm.submitTastingSteps.confirmCiders),"editable":_vm.stepConfirmCidersEditable,"complete":_vm.currentStep === _vm.submitTastingSteps.confirmCiders
        || _vm.currentStep === _vm.submitTastingSteps.checkoutSummary
        || _vm.currentStep === _vm.submitTastingSteps.checkoutCompleted,"edit-icon":"mdi-check"},on:{"click":function($event){_vm.stepConfirmCidersEditable ? _vm.$router.push("/producer/submit/confirmData") : ''}}},[_vm._v(" "+_vm._s(_vm.$t('paymentHandling.confirmData'))+" "),(_vm.confirmedCiderIds.length > 0 && (_vm.currentStep !== _vm.submitTastingSteps.confirmCiders
          || _vm.confirmedCiderIds.length === _vm.selectedCiders.length))?_c('small',{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.confirmedCiderIds.length === _vm.selectedCiders.length ? _vm.$t('all') : _vm.confirmedCiderIds.length + '/' + _vm.selectedCiders.length)+" "+_vm._s(_vm.$tc('product', 2))+" "+_vm._s(_vm.$t('confirmed'))+" ")]):_vm._e(),(_vm.currentStep === _vm.submitTastingSteps.confirmCiders && _vm.confirmedCiderIds.length !== _vm.selectedCiders.length)?_c('small',{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.$t('paymentHandling.dataNeedsConfirmation'))+" ")]):_vm._e()]):_vm._e(),(!_vm.awardExcluded && _vm.submitProductsWithoutData === false)?_c('v-divider',{class:{small_divider: _vm.isSmallScreenCss, big_divider: !_vm.isSmallScreenCss}}):_vm._e(),_c('v-stepper-step',{attrs:{"step":_vm.stepNumber(_vm.submitTastingSteps.checkoutSummary),"editable":_vm.stepCheckoutSummaryEditable,"complete":_vm.currentStep === _vm.submitTastingSteps.checkoutCompleted,"edit-icon":"mdi-check"},on:{"click":function($event){_vm.stepCheckoutSummaryEditable ? _vm.$router.push("/producer/submit/summary") : ''}}},[_vm._v(" "+_vm._s(_vm.$t('paymentHandling.summary'))+" "),(_vm.currentStep === _vm.submitTastingSteps.checkoutSummary)?_c('small',{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.$t('paymentHandling.verifySubmission'))+" ")]):_vm._e(),(_vm.currentStep === _vm.submitTastingSteps.checkoutCompleted)?_c('small',{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.$t('paymentHandling.orderVerified'))+" ")]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }