
export function nullOrUndefined(data: any): boolean {
    return (data === null || data === undefined);
}


export function dateFromString(str: string | undefined | null): Date | null {
    if (str === undefined || str === null || str === "") return null
    else return new Date(Date.parse(str));
}

export const groupBy = (array: any[], func: any) => array.reduce((a: any, b: any) => ((a[func(b)]||=[]).push(b),a),{});

// as { [key: ReturnType<typeof func>]: T}

// type Test = {a: string, b: number}
// const a = [<Test>{a: "a", b: 1}, <Test>{a: "b", b: 2}]
// const ret = groupBy(a, ((t: Test) => t.b))

export function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
    return o[propertyName]; // o[propertyName] is of type T[K]
}

export function camelToSnake(text: string): string {
    return text.split(/(?=[A-Z])/).join('_').toLowerCase();
}

// const b = getProperty(ret, "a")

// for (const [k, v])

// export const prototypeGroupBy = function(array: any[], keyFunction: any) {
//     const groups: any = {};
//     const o = array.forEach(function(el) {
//         const key = keyFunction(el);
//         if (!(key in groups)) {
//             groups[key] = [];
//         }
//         groups[key].push(el);
//     });
//     return o.keys(groups).map(function(key: any) {
//         return {
//             key: key,
//             values: groups[key]
//         };
//     });
// };

export function contrastingColor(color: string | number[])
{
    if (color === null) return 'fff'
    // console.log("luma(color)", luma(color))
    return (luma(color) >= 152) ? '000' : 'fff';
}
function luma(color: string | number[]) // color can be a hx string or an array of RGB values 0-255
{
    const rgb = (typeof color === 'string') ? hexToRGBArray(color) : color;
    return (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]); // SMPTE C, Rec. 709 weightings
}
function hexToRGBArray(color: string)
{
    if (typeof color === "string" && color.charAt(0) === "#") color = color.slice(1);
    if (color.length === 3)
        color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
    else if (color.length !== 6)
        throw('Invalid hex color: ' + color);
    const rgb = [];
    for (let i = 0; i <= 2; i++)
        rgb[i] = parseInt(color.substr(i * 2, 2), 16);
    return rgb;
}