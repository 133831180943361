<template>
  <v-app style="margin-top: 0 !important; padding-top: 0 !important;">
    <v-main
      style="background-color: white !important; overflow-x: visible;"
      class="pa-0 ma-0"
    >
      <v-container
        id="allCider"
        fluid
        class="pa-0 ma-0"
      >
        <LoadingCircle
          v-if="loading"
        />
        <template v-if="!loading">
          <v-btn
            fab
            fixed
            bottom
            right
            x-large
            class="mr-8 mb-8 no-print"
            @click="print"
          >
            <v-icon>
              mdi-printer
            </v-icon>
          </v-btn>
          <v-theme-provider
            v-if="resultCider != null"
            light
          >
            <urkunde-page-a4
              v-for="(resultCider_, index) in resultCider"
              :key="index"
              id="urkundePage"
              ref="urkundePDF"
              :cider="resultCider"
              :badge-data="badge"
              :tasting="tasting"
              class="mt-8 mb-8 ml-auto mr-auto page_print"
              style="background-color: white !important; overflow: visible !important;"
            />
          </v-theme-provider>
        </template>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LoadingCircle from "@/layouts/loading_circle";
import cider_mixin from "@/components/mixins/cider_mixin";
import {apiTyped} from "@/api";
import UrkundePageA4 from "@/components/admin/urkunde/urkunde_page_a4";

export default {
  name: "ProducerCertificateA4",
  components: {UrkundePageA4, LoadingCircle},
  mixins: [cider_mixin],
  data: () => ({
    error: false,
    data: null,
    loading: true,
    firstLoading: true,
  }),
  computed: {
    dataUuid() {
      return this.$route.query.uuid;
    },
    resultCider() {
      return this.data.resultCider;
    },
    tasting() {
      return this.data.tasting;
    },
    badge() {
      return this.data.badge;
    },
  },
  methods: {
    print() {
      window.print();
    },
  },
  async created() {
    // const loggedIn = await apiTyped.loginToken("FfpXkDsLtbBD0DIaZozOUWdLlyN3J84r");
    this.$vuetify.theme.dark = false;
    const route = this.$store.state.producerStore.lastRouteBeforePrint || "/producer/home";
    const timeout = window.setTimeout(async () => await this.$router.push(route), 15000)
    const data = await apiTyped.getPrintData(this.dataUuid);
    window.clearTimeout(timeout);
    console.log("GOT PRINT DATA")
    if (data == null) {
      this.error = true;
      window.clearTimeout(timeout);
      await this.$router.push(route)
      return;
    }
    this.data = data.data;
    this.$store.set("producerStore/lastRouteBeforePrint", this.data?.__lastRoute__ || null);
    try {
      if (await apiTyped.loginRefresh() == null) {
        await apiTyped.loginToken(data["accessToken"]);
      }
    } catch(e) {
      console.log("ERROR", e)
      await apiTyped.loginToken(data["accessToken"]);
    }
    this.loading = false;
  },
}
</script>

<style scoped>
@media print {
  .page_print {
    margin: 0 !important;
    padding: 0 !important;
  }
}

@page {
  size: portrait;
  margin: 0 0 0 0 !important;
}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>