import {MyCollections} from "@/api/types/my-collections";
import {dateFromString} from "@/util/helper";

interface CiderNotes {
    id: number;
    kommentar?: string | null;
    isPinned: boolean;
    isFavourite: boolean;
    started: boolean;
    startedAt?: Date | null;
    finished: boolean;
    finishedAt?: Date | null;
    dateUpdated?: Date | null;
    userId: string;
}


export function createCiderNotesFromDatabase(ciderNotesDb: MyCollections["cider_notes"]): CiderNotes {
    const ciderNotes = <CiderNotes>{
        id: Number(ciderNotesDb.id),
        kommentar: ciderNotesDb.kommentar || null,
        isPinned: ciderNotesDb.is_pinned || false,
        isFavourite: ciderNotesDb.is_favourite || false,
        started: ciderNotesDb.started,
        finished: ciderNotesDb.finished,
        startedAt: dateFromString(ciderNotesDb.started_at),
        finishedAt: dateFromString(ciderNotesDb.finished_at),
        dateUpdated: dateFromString(ciderNotesDb.date_updated),
        userId: typeof ciderNotesDb.juror_id === "string" ? ciderNotesDb.juror_id : ciderNotesDb.juror_id?.id
    };
    return ciderNotes as CiderNotes;
}

export { CiderNotes };