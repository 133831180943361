<template>
  <v-card
    color="white"
    flat
    tile
    class="lighten-1 text-center align-end"
  >
    <v-row style="font-size: 9pt">
      <v-col
        cols="12"
        sm="4"
      >
<!--        TODO -->
        <div class="d-flex justify-start">
          Leaderboard
        </div>
        <div class="d-flex justify-start">
          {{ kategorie }}
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <div class="d-flex justify-center">
<!--          Apfelwein International UG (haftungsbeschränkt)-->
        </div>
        <div class="d-flex justify-center">
<!--          Krautgartenweg 1 | 60439 Frankfurt am Main | Germany-->
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <div class="d-flex justify-end">
<!--          info@cider-world.com-->
        </div>
        <div class="d-flex justify-end">
<!--          +49 69 153258331-->
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "RanglisteFooter",
  props: {
    kategorie: {type: String, required: true, default: () => ""},
  },
}
</script>