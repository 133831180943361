<template>
  <v-app
    style="margin-top: 0 !important; display: flex; flex-direction: column !important; "
    :style="{background: $vuetify.theme.currentTheme.background}"
  >
    <transition
      name="fade"
      style="height: 100%; display: flex; flex-direction: column !important; "
    >
      <v-container
        v-if="ready"
        fluid
        class="pa-0 ma-0"
        style="height: 100vh; display: flex; flex-direction: column !important;"
      >
        <app_bar />
        <v-main>
          <router-view :key="reloadRouter" />
        </v-main>
        <LoginFooterCard />
      </v-container>
    </transition>
    <transition
      v-if="!ready"
      name="fade"
    >
      <LoadingCircle
        :long-loading="longLoading"
        :loading-message="loadingMessage"
      />
    </transition>
  </v-app>
</template>

<script>
import LoadingCircle from "@/layouts/loading_circle";
import app_bar from "@/components/app_bar";
import logos_mixin from "@/components/mixins/logos_mixin";
import locale_mixin from "@/components/mixins/locale_mixin";
import user_mixin from "@/components/mixins/user_mixin";
import LoginFooterCard from "@/components/login_footer_card";
import tasting_layout_mixin from "@/components/mixins/tasting_layout_mixin";
import {apiTyped} from "@/api";

export default {
  name: "LayoutTastingDashboard",
  components: {
    LoginFooterCard,
    LoadingCircle,
    app_bar,
  },
  mixins: [logos_mixin, locale_mixin, user_mixin, tasting_layout_mixin],
  data: () => ({
    ready: false,
    longLoading: true,
    loadingMessage: "",
  }),
  computed: {
    reloadRouter() {
      return this.$store.state.reload_router;
    },
  },
  async created() {
    this.$store.state.usersStore.currentTastingId = -1;
    try {
      await this.tastingCreated();
      // this.$store.state.usersStore.tastings = await apiTyped.getTastings();
    } catch (e) {
      console.error("Error in dashboard", e);
      await this.$router.push('/login');
    }
    this.ready = true;
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to {
  opacity: 0
}

body {
  /*noinspection CssUnresolvedCustomProperty*/
  background: var(--v-background-base) !important;
}

.v-application {
  background-color: var(--v-background-base) !important;
}

.v-card {
  background-color: var(--v-card_background-base) !important;
  border-radius: 20px !important;
}
</style>