import {apiTyped} from "@/api";

export default {
    methods: {
        makeLogoName(logoName) {
            if (!this.$vuetify.theme.dark) logoName = logoName + "_dark";
            if (this.$vuetify.theme.dark) logoName = logoName + "_light";
            return logoName;
        },
        getLogoUrl(logoName, width, withoutTheme = false) {
            if (!withoutTheme) logoName = this.makeLogoName(logoName);
            const logo_url = this.$store.getters.getLogoUrl(logoName, width);
            if (logo_url === undefined || logo_url === null)
                return "";
            return logo_url;
        },
        getLogoColoredUrl(logoName, width, dark = false) {
            if (dark) logoName = logoName = logoName + "_dark";
            else logoName = logoName = logoName + "_light";
            const logo_url = this.$store.getters.getLogoUrl(logoName, width);
            console.log("GOT LOGO", logoName, logo_url)
            if (logo_url === undefined || logo_url === null)
                return "";
            return logo_url;
        },
        getImageUrl(imgId, width=250, noContain = false, noWidth = false, auth = true) {
            return apiTyped.getImgUrl(imgId, width, noContain, noWidth, auth)
        },
        isValidHttpUrl(s) {
            let url;
            try {
                url = new URL(s);
                return true;
            } catch (_) {
                return false;
            }
        },
    }
};

