<template>
  <div>
    <v-card-text class="pb-0">
      Extra
    </v-card-text>
    <v-card-title class="pt-0">
      {{ extraProduct.name }}
    </v-card-title>
    <v-card-subtitle class="pt-1 pb-0">
      <span
        class="text--primary darken-2 font-weight-bold text-h6"
        v-html="extraProduct.priceDescription"
      />
      <span class="text--primary darken-2 font-weight-bold pl-1">
        €
      </span>
    </v-card-subtitle>
    <v-card-text
      v-if="available != null"
      class="text--secondary py-0"
    >
      {{ available }} {{ $t('available') }}
    </v-card-text>
    <v-card-text
      class="pt-2"
      v-html="extraProduct.description"
    />
  </div>
</template>

<script>
export default {
  name: "ExtraCard",
  props: {
    extraProduct: { type: Object, required: true, default: null },
    available: { type: Number, required: false, default: null }
  },
}
</script>

<style scoped>
</style>