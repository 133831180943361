import {apiTyped} from "@/api";
import JurorCider from "@/types/jurorCider";

export default {
  data: () => ({
    getCidersForTablesProgress: 0,
  }),
  methods: {
    async getCidersForTables(limit = 20){
      const cidersByTable = [];
      for (const [index, table] of this.tables.entries()) {
        this.getCidersForTablesProgress = (index+1) / this.tables.length;
        const flightsCidersIds = this.$store.getters["tableStore/getFlightsCidersIds"](table);
        if (flightsCidersIds.length > 0) {
          const flightsCiders = await apiTyped.getFlightsCiders(
            flightsCidersIds, true, true, limit);
          flightsCiders.forEach(f => f.flights_id = table.flights.find(f_ => f_.id === f.flights_id));

          await this.addMissingCategory(flightsCiders);
          this.$store.commit("kategorienStore/setKategorieData", this.kategorien);

          const ciders = flightsCiders.map(
            f => JurorCider.fromApiData(
              f.ciders_id,
              this.kategorien.find(k => Number(k.id) === Number(f.ciders_id.kategorie)),
              f,
              this.$store.getters["usersStore/userId"](),
              false,
              []
            )
          );
          // sort in place for correct flights order:
          ciders.sort((c1, c2) => (c1.flightsSort - c2.flightsSort) || (c1.sort - c2.sort));
          cidersByTable.push(ciders);
        } else {
          cidersByTable.push([]);
        }
      }
      return cidersByTable;
    },
    async addMissingCategory(flightsCiders) {
      const missingCategoryIds = flightsCiders.filter(
        f => this.kategorien.find(k => Number(k.id) === Number(f.ciders_id.kategorie)) == null).map(
        f => Number(f.ciders_id.kategorie));
      if (missingCategoryIds.length > 0) {
        const additionalCategories = await apiTyped.getAllKategorien(-1, missingCategoryIds);
        this.kategorien.push(...additionalCategories);
      }
    },
  },
}