<template>
  <v-card
    ref="card"
    :outlined="$vuetify.theme.dark"
  >
    <v-card-text class="pl-4 text-left">
      <p
        class="d-flex justify-start text-h5 text--primary mb-1"
        style="letter-spacing:-1px !important; text-overflow: ellipsis !important; overflow: hidden !important; white-space: nowrap !important;"
      >
        <v-icon class="pr-2">
          mdi-store
        </v-icon>
        Produzenten
      </p>
      <v-card-title
        class="text-h6 text-center justify-center"
        style="font-size: 32pt !important;"
      >
        {{ produzentenCount }}
      </v-card-title>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: "CardProduzentenCount",
  props: {
    allCiders: {type: Array, required: true, default: null},
  },
  computed: {
    produzentenCount() {
      return [... new Set(this.allCiders.flatMap(cider => cider.produzentId))].length;
    }
  },
  mounted() {
    // console.log("Emit size changed", "CardProduzentenCount", this.$refs.card.$el.clientHeight)
    if (this.$refs.card != null)
      this.$emit("size", {name: "CardProduzentenCount", size: this.$refs.card.$el.clientHeight})
  },
}
</script>

<style scoped>

</style>