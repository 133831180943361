<template>
  <div>
    <v-row
      no-gutters
      class="pb-4"
    >
      <v-col
        cols="8"
      >
        <span class="d-flex pl-4 text-caption text-left checkout-item-text">
          {{ $t('registrationFee') }}
        </span>
      </v-col>
      <v-col
        cols="4"
        align-self="end"
        class="ml-auto"
      >
        <div class="d-flex pl-4 text-caption text-left font-weight-bold text-right justify-end checkout-item-text">
          {{ submissionFee }} {{ currency }}
        </div>
      </v-col>
    </v-row>
    <div
      v-for="(cider, index) in ciders"
      :key="cider.id"
    >
      <v-row
        no-gutters
        class="pb-4"
      >
        <v-col
          cols="8"
        >
          <span class="d-flex pl-4 text-caption text-left checkout-item-text">
            {{ index + 1 }}. submitted Product
          </span>
        </v-col>
        <v-col
          cols="4"
          align-self="end"
          class="ml-auto"
        >
          <div class="d-flex pl-4 text-caption text-left font-weight-bold text-right justify-end checkout-item-text">
            {{ pricePerCider }} {{ currency }}
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider class="pb-4" />
    <v-row
      no-gutters
      class="pb-2"
    >
      <v-col
        cols="6"
      >
        <span class="d-flex pl-4 text-caption text-left checkout-item-text">
          {{ $t('subtotal') }}
        </span>
      </v-col>
      <v-col
        cols="6"
        align-self="end"
        class="ml-auto"
      >
        <div class="d-flex pl-4 text-caption text-left font-weight-bold text-right justify-end checkout-item-text">
          {{ subTotalPrice }} {{ currency }}
        </div>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="pb-4"
    >
      <v-col
        cols="6"
      >
        <span class="d-flex pl-4 text-caption text-left checkout-item-text">
          {{ $t('taxesEstimated') }}
        </span>
      </v-col>
      <v-col
        cols="6"
        align-self="end"
        class="ml-auto"
      >
        <div class="d-flex pl-4 text-caption text-left font-weight-bold text-right justify-end checkout-item-text">
          {{ subTotalPrice * taxes }} {{ currency }}
        </div>
      </v-col>
    </v-row>
    <v-divider class="pb-4" />
    <v-row
      no-gutters
      class="pb-4"
    >
      <v-col
        cols="6"
      >
        <span
          class="d-flex pl-4 text-caption text-left font-weight-bold checkout-item-text"
          style="font-size: 16px !important;"
        >
          {{ $t('total') }}

        </span>
      </v-col>
      <v-col
        cols="6"
        align-self="end"
        class="ml-auto"
      >
        <div
          class="d-flex pl-4 text-caption text-left font-weight-bold text-right justify-end checkout-item-text"
          style="font-size: 16px !important;"
        >
          {{ totalPrice }} {{ currency }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import util from "@/util";

export default {
  name: "CheckoutPricesSummary",
  props: {
    ciders: { type: Array, required: true, default: null },
    submissionFee: { type: Number, required: true, default: 0 },
    currency: { type: String, required: true, default: "€" },
    pricePerCider: { type: Number, required: true, default: 0 },
    taxes: { type: Number, required: true, default: 0 }
  },
  computed: {
    subTotalPrice() {
      return this.submissionFee + this.ciders.length * this.pricePerCider;
    },
    totalPrice() {
      return util.util.round_to_decimal(this.subTotalPrice + this.subTotalPrice * this.taxes, 0.01);
    },
  },
}
</script>

<style scoped>

</style>