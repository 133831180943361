<template>
  <tiptap-vuetify
    v-model="editorContent"
    :placeholder="$t('enterAComment')"
    :extensions="extensions"
    :disabled="readonly"
    :toolbar-attributes="toolbarAttributes"
    :card-props="{ flat: true, color: backgroundColor, collapse: true, rounded: 'xl', dark: true}"
    class="text-left text-wrap"
    :class="{'mt-0': !$store.getters['usersStore/isChair'](),
             'my-border': $store.getters['usersStore/isChair'](),
             'pb-2': !$store.getters['usersStore/isChair'](),
             'not_rounded': !$store.getters['usersStore/isChair'](),
             'rounded_b': !$store.getters['usersStore/isChair'](),}"
    style="border-radius: 20px !important;"
  >
    <template
      v-if="readonly"
      #toolbar="{ buttons }"
    >
      <!--You can render the buttons as you wish (you can see in the source code how this is done).-->
      <pre>{{ buttons }}</pre>
    </template>
  </tiptap-vuetify>
</template>

<script>
import Vue from "vue";
import i18n from '@/i18n'
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, BulletList, OrderedList, ListItem,
  Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'


const Component = Vue.extend( {
  components: { TiptapVuetify },
  props: {
    content: {type: String, required: false, default: "<p></p>"},
    readonly: {type: Boolean, required: false, default: true},
    backgroundColor: {type: String, required: false, default: "dialog_background"},
  },
  data: () => ({
    extensions: [
      History,
      Bold,
      Underline,
      Italic,
      Strike,
      Blockquote,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      HorizontalRule,
      HardBreak
    ],
  }),
  computed: {
    // editorColor: this.$vuetify.theme.currentTheme.background,
    isDarkMode() {
      return this.$vuetify.theme.dark
    },
    toolbarAttributes() {
      return this.$vuetify.theme.dark
          ? { color: this.$vuetify.theme.currentTheme.secondary_card, dark: true }
          : { color: 'secondary' }
      // return { color: this.$vuetify.theme.currentTheme.background, dark: this.isDarkMode }
    },
    editorContent: {
      get() {
        return this.content;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
});
export default Component;
</script>

<style scoped>
/deep/ .tiptap-vuetify-editor__content {
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 20px !important;
  margin-top: 4px;
  color: white;
  border-radius: 22px !important;
  border: 1px solid white;
  white-space: normal !important;
  /*background-color: var(--v-card_background-base);*/
}

/deep/ .tiptap-vuetify-editor__content--disabled {
  border: 0px !important;
}


/*.not_rounded {*/
/*  border-bottom-right-radius: 0px !important;*/
/*  border-bottom-left-radius: 0px !important;*/
/*}*/

.rounded_b {
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

/deep/ .tiptap-vuetify-editor__content--disabled.v-card {
  color: white !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  white-space: normal !important;
}
/deep/ .tiptap-vuetify-editor__content--disabled {
  color: white !important;
  /*background-color: var(--v-card_background-base) !important;*/
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  white-space: normal !important;
}

/deep/ .tiptap-vuetify-editor__content--disabled:after {
  background-color: unset !important;
}

/deep/ .tiptap-vuetify-editor__content > p {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  border-width: 1px !important;
  border-color: white !important;
  white-space: normal !important;
}
</style>