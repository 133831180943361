<template>
  <v-container
    fluid
  >
    <div class="text-body-2 text-left text--secondary pl-2 pb-2">
      <v-icon class="text--secondary">
        mdi-cash
      </v-icon>
      Tasting Purchase Details
    </div>
    <div class="text-h5 text-left align-center d-flex">
      <v-btn
        icon
        class="mr-2"
        @click="$router.back()"
      >
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <template v-if="purchase != null">
        <span class="pr-3">
          {{ formatCurrency(purchase.totalAmount) }}
        </span>
        <v-chip
          small
          :color="statusColor(purchase.status)"
        >
          {{ statusText(purchase.status) }}
        </v-chip>
      </template>
    </div>
    <v-toolbar
      flat
      color="background"
    >
      <v-spacer />
      <v-btn
        outlined
        rounded
      >
        Action1
      </v-btn>
    </v-toolbar>

    <div class="text-h6 text-left">
      Purchased Products
    </div>
    <v-card
      outlined
      elevation="0"
      class="rounded-lg"
    >
      <v-data-table
        :headers="tastingProductsHeaders"
        :items="tastingProductItems"
        :mobile-breakpoint="1"
        hide-default-footer
        class="rounded-lg"
      >
        <template #item.name="{ item }">
          <div class="d-flex flex-column justify-center align-start py-2">
            <div class="text-left ">
              {{ item.name }}
            </div>
            <div
              class="text-left text--secondary no-p-margin-b"
              v-html="item.description"
            />
          </div>
        </template>

        <template #body.append>
          <tr class="no-border">
            <td
              class="text-right"
              colspan="3"
            >
              <strong>{{ $t('subtotal') }}</strong>
            </td>
            <td
              class="text-right"
            >
              <strong>{{ formatCurrency(purchase.subtotalAmount) }}</strong>
            </td>
          </tr>
          <tr class="no-border">
            <td
              class="text-right"
              colspan="3"
            >
              {{ $t('vatAmount') }}
            </td>
            <td
              class="text-right"
            >
              <strong>€ {{ formatCurrency(purchase.totalAmount - purchase.subtotalAmount) }}</strong>
            </td>
          </tr>
          <tr class="no-border">
            <td
              class="text-right"
              colspan="3"
            >
              <strong>{{ $t('totalAmount') }}</strong>
            </td>
            <td
              class="text-right"
            >
              <strong>{{ formatCurrency(purchase.totalAmount) }}</strong>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <div class="text-h6 text-left pt-6 pb-3">
      Invoices
    </div>
    <template v-if="!isSmallScreen && purchase.invoices != null">
      <v-tabs
        v-model="invoiceTab"
        align-with-title
        background-color="background"
        show-arrows
      >
        <v-tab
          v-for="invoice in purchase.invoices"
          :key="'invoice_' + invoice.id"
          style="text-transform: unset !important;"
        >
          <div class="d-flex align-center">
            {{ invoice.invoiceNumber }}
            <v-chip
              small
              :color="invoiceStatusColor(invoice.status)"
              class="pr-2 ml-2"
            >
              <v-icon
                x-small
                left
              >
                {{ invoiceStatusIcon(invoice.status) }}
              </v-icon>
              {{ invoiceStatusText(invoice.status) }}
            </v-chip>
          </div>
        </v-tab>
      </v-tabs>
    </template>
    <v-select
      v-if="isSmallScreen && purchase.invoices != null"
      v-model="invoiceTab"
      outlined
      flat
      class="d-flex align-center pl-2 pr-2"
      :items="purchase.invoices"
      style="max-width: 100vw;"
      :item-text="item => item.invoiceNumber"
      :item-value="item => purchase.invoices.indexOf(item)"
      label=""
      rounded
      hide-details
    >
      <template #item="{item}">
        {{ item.invoiceNumber }}
        <v-chip
          small
          :color="invoiceStatusColor(item.status)"
          class="pr-2 ml-2"
        >
          <v-icon
            small
            left
          >
            {{ invoiceStatusIcon(item.status) }}
          </v-icon>
          {{ invoiceStatusText(item.status) }}
        </v-chip>
      </template>
      <template #selection="{item}">
        {{ item.invoiceNumber }}
        <v-chip
          small
          :color="invoiceStatusColor(item.status)"
          class="pr-2 ml-2"
        >
          <v-icon
            small
            left
          >
            {{ invoiceStatusIcon(item.status) }}
          </v-icon>
          {{ invoiceStatusText(item.status) }}
        </v-chip>
      </template>
    </v-select>
    <v-tabs-items
      v-if="purchase.invoices != null"
      v-model="invoiceTab"
    >
      <v-tab-item
        v-for="invoice in purchase.invoices"
        :key="`invoice_tab_${invoice.invoiceNumber}`"
      >
        <invoice-detail :invoice="invoice" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import {apiTyped} from "@/api";
import i18n from "@/i18n";
import util from "@/util";
import translation_mixin from "@/components/mixins/translation_mixin";
import purchase_mixin from "@/components/mixins/purchase_mixin";
import InvoiceDetail from "@/components/admin/purchases/invoice_detail.vue";

export default {
  name: "PurchaseDetail",
  components: {InvoiceDetail},
  mixins: [translation_mixin, purchase_mixin],
  data: () => ({
    purchase: null,
    invoiceTab: 0,
  }),
  computed: {
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return true
        default:
          return false
      }
    },
    tasting() {
      return this.$store.getters["usersStore/currentTasting"]();
    },
    purchaseId() {
      return this.$route.params.purchaseId;
    },
    tastingProductsHeaders() {
      return [
        { text: i18n.t('name'), value: 'name', align: 'start', sortable: true, groupable: false, width: 300 },
        { text: i18n.t('quantity'), value: 'quantity', align: 'center', sortable: true, groupable: false, width: 100 },
        { text: i18n.t('unitPrice'), value: 'unitPrice', align: 'end', sortable: true, groupable: false, width: 120 },
        { text: i18n.t('amount'), value: 'amount', align: 'end', sortable: true, groupable: false, width: 100 },
      ]
    },
    tastingProductItems() {
      if (this.purchase == null) return [];
      return this.purchase.purchasedProducts.map(purchasedProduct => {
        return {
          name: this.getTranslation(purchasedProduct.tastingProduct.translations, 'name'),
          description: this.getTranslation(purchasedProduct.tastingProduct.translations, 'description'),
          quantity: purchasedProduct.amount,
          unitPrice: `€ ${purchasedProduct.productPrice.unitPrice}`,
          amount: `€ ${util.util.round_to_decimal(
            purchasedProduct.productPrice.unitPrice * purchasedProduct.amount, 0.01)}`,
        }
      })
    },
  },
  async created() {
    this.purchase = await apiTyped.adminGetPurchaseById(this.purchaseId);
  },
  methods: {

  }
}
</script>

<style scoped>
/deep/ .v-data-table {
  background-color: var(--v-card_background-base) !important;
}
/deep/ .v-data-table-header > tr > th {
  background-color: transparent !important;
}
/deep/ .v-data-table--fixed-header > tr > th {
  background-color: transparent !important;
}
</style>