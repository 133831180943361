import { render, staticRenderFns } from "./producer_dashboard.vue?vue&type=template&id=5c41295e&scoped=true&"
import script from "./producer_dashboard.vue?vue&type=script&lang=js&"
export * from "./producer_dashboard.vue?vue&type=script&lang=js&"
import style0 from "./producer_dashboard.vue?vue&type=style&index=0&id=5c41295e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c41295e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VFadeTransition,VIcon,VProgressCircular,VRow,VSpacer,VTab,VTabItem,VTabs,VTabsItems,VToolbar})
