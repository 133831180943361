<template>
  <v-card
    ref="card"
    :outlined="$vuetify.theme.dark"
    :flat="!isCard"
    class="pa-4 pl-4 text-left"
  >
    <v-card-text class="pa-0 d-flex flex-row">
      <p class="d-flex justify-start text-h4 text--primary mb-0">
        Timeline
      </p>
      <v-select
        v-model="pagSteps"
        :items="[10,20,50,100]"
        class="ml-auto mt-0 pt-0"
        style="max-width: 64px !important;"
        hide-details
      />
      <v-btn
        icon
        @click="updateRevisions"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-text>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
    />
    <v-checkbox
      v-model="onlyTasting"
      class="ma-0"
      hide-details
      label="Nur Einträge aus diesem Tasting"
    />
    <v-card-text class="pl-0 pb-0 text-left">
      <span class="subheading">
        Select Users
      </span>
      <v-autocomplete
        v-model="filteredUsers"
        :items="users"
        class="text-left justify-start"
        style="text-align: start !important; border-color: var(--v-divider-base)"
        color="divider"
        return-object
        hide-details
        :item-text="item => item.firstName + ' ' + item.lastName + ' (' + item.email + ')'"
        item-value="id"
        multiple
      >
        <template #selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="removeUserFromFilter(data.item)"
          >
            <v-avatar left>
              <v-img 
                :src="getUserAvatar(200, data.item)"
                alt="Avatar" 
              />
            </v-avatar>
            {{ data.item.firstName }} {{ data.item.lastName }}
          </v-chip>
        </template>
        <!--        <template #selection="{ index }">-->
        <!--          <span v-if="index === 0">-->
        <!--            {{ filteredUsers.length }} Users-->
        <!--          </span>-->
        <!--        </template>-->
      </v-autocomplete>
    </v-card-text>

    <v-card-text class="pl-0 pb-0">
      <span class="subheading">
        Select Actions
      </span>
      <v-chip-group
        v-model="actionsFilter"
        class=""
        column
        multiple
      >
        <template v-for="action in Object.keys(chipFiltersActions)">
          <v-chip
            :key="action"
            small
            filter
          >
            {{ chipFiltersActions[action] }}
          </v-chip>
        </template>
      </v-chip-group>
    </v-card-text>
    <v-card-text class="pl-0 pt-1">
      <span class="subheading">
        Select Collections
      </span>
      <v-chip-group
        v-model="collectionFilter"
        class=""
        column
        multiple
      >
        <template v-for="collection in Object.keys(chipFilters)">
          <v-chip
            :key="collection"
            small
            filter
          >
            {{ chipFilters[collection] }}
          </v-chip>
        </template>
      </v-chip-group>
    </v-card-text>
    <!--    Timeline-->
    <v-card-text
      class="py-0 pl-0 pr-0"
      style="overflow-y: auto; overflow-x: hidden;  width: 100%;"
    >
      <v-timeline
        v-if="!firstLoad && revisions !== null"
        align-top
        dense
        class="pt-2"
        style="overflow-y: auto; width: 100%;"
      >
        <v-timeline-item
          v-for="revision in revisions"
          :key="revision.id"
          color="teal lighten-3"
          class="pb-0 flex-nowrap"
          small
        >
          <template #opposite>
            <span>{{ getTimeDiffText(revision.activity.timestamp) }}</span>
          </template>
          <template #icon>
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-avatar
                  size="32"
                  v-bind="attrs"
                  class="ma-auto elevation-2"
                  v-on="on"
                >
                  <img
                    :src="getUserAvatar(200, hasUserBuffered(revision.activity.user))"
                    alt="Avatar"
                  >
                </v-avatar>
              </template>
              <span>
                {{ hasUserBuffered(revision.activity.user).firstName }}
                {{ hasUserBuffered(revision.activity.user).lastName }}
              </span>
              <br>
              <v-icon
                color="white"
                small
              >
                mdi-email-outline
              </v-icon> {{ hasUserBuffered(revision.activity.user).email }}
              <br>
              <v-icon
                color="white"
                small
              >
                mdi-clock-outline
              </v-icon> {{ getPrettyDate(revision.activity.timestamp) }}
            </v-tooltip>
          </template>
          <div
            class="timeline_grid"
          >
            <v-card
              tile
              flat
              class="pa-0 align-center"
            >
              <strong>
                <v-icon
                  small
                  style="padding-bottom: 2px;"
                >
                  {{ revision.activity.action === 'update'
                    ? 'mdi-refresh'
                    : revision.activity.action === 'create'
                      ? 'mdi-pencil-circle'
                      : 'mdi-delete' }}
                </v-icon>
                {{ chipFilters[revision.collection] }}
              </strong>
              <div
                class="text-caption mb-2"
                style="text-overflow: ellipsis !important;"
              >
                {{ getTimeDiffText(revision.activity.timestamp) }} | {{ hasUserBuffered(revision.activity.user).firstName }} {{ hasUserBuffered(revision.activity.user).lastName }}
              </div>
            </v-card>

            <!--Bewertung-->
            <div
              v-if="revision.collection === 'bewertungen'"
              class="pa-0 text-truncate"
              style="text-overflow: ellipsis !important; white-space: nowrap !important; overflow: hidden !important;"
            >
              <span class="text-truncate">
                {{ getKategorieByCiderId(revision.data.cider_id).name }}:
                {{ hasCiderBuffered(revision.data.cider_id).name }}
              </span>
              <br>
              <span>
                {{ getKategorieByEigenschaftId(revision.data.eigenschaft_id)[1].pruefmerkmal }}:
                {{ getKategorieByEigenschaftId(revision.data.eigenschaft_id)[2].bezeichnung }}
                <span v-if="revision.activity.action === 'update'">
                  <v-icon x-small>mdi-star</v-icon> {{ revision.delta.punkte }}
                </span>
                <span v-if="revision.activity.action === 'create'">
                  Created Bewertung
                </span>
                <span v-if="revision.activity.action === 'delete'">
                  Deleted Bewertung
                </span>
              </span>
            </div>

            <!--Cider Notes-->
            <div
              v-else-if="revision.collection === 'cider_notes'"
              class="pa-0 text-truncate"
              style="text-overflow: ellipsis !important; white-space: nowrap !important; overflow: hidden !important;"
            >
              <span class="text-truncate">
                {{ hasCiderBuffered(revision.data.cider_id).name }}:
              </span>
              <br>
              <span>
                <span v-if="revision.activity.action === 'update'">
                  <div v-if="revision.delta.hasOwnProperty('kommentar')">
                    <v-icon x-small>mdi-comment</v-icon>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <span
                          style="text-overflow: ellipsis !important;"
                          v-on="on"
                        >
                          Kommentar geändert (hover)
                        </span>
                      </template>
                      <span v-html="revision.delta.kommentar" />
                    </v-tooltip>
                  </div>
                  <div v-if="revision.delta.hasOwnProperty('finished')">
                    <v-icon x-small>
                      {{ revision.delta.finished ? 'mdi-star-check' : 'mdi-account-edit' }}
                    </v-icon>
                    Cider Status: Finished {{ revision.delta.finished }}
                  </div>
                  <div v-if="revision.delta.hasOwnProperty('started')">
                    <v-icon x-small>
                      {{ revision.delta.finished ? 'mdi-account-edit' : 'mdi-account-edit' }}
                    </v-icon>
                    Cider Status: Started {{ revision.delta.started }}
                  </div>
                  <div v-if="revision.delta.hasOwnProperty('is_pinned')">
                    <v-icon x-small>
                      {{ revision.delta.is_pinned ? 'mdi-bookmark' : 'mdi-bookmark-outline' }}
                    </v-icon>
                    Cider {{ revision.delta.is_pinned ? "vorgemerkt" : "vorgemerkt gelöscht" }}
                  </div>
                  <div v-if="revision.delta.hasOwnProperty('is_favourite')">
                    <v-icon x-small>
                      {{ revision.delta.is_favourite ? 'mdi-heart' : 'mdi-heart-outline' }}
                    </v-icon>
                    Cider {{ revision.delta.is_favourite ? "favorisiert" : "favorisiert gelöscht" }}
                  </div>
                </span>
                <span v-if="revision.activity.action === 'create'">
                  Created Cider Note
                </span>
                <span v-if="revision.activity.action === 'delete'">
                  Deleted Cider Note
                </span>
              </span>
            </div>

            <!--Chair Notes-->
            <div
              v-else-if="revision.collection === 'chair_notes'"
              class="pa-0 text-truncate"
              style="text-overflow: ellipsis !important; white-space: nowrap !important; overflow: hidden !important;"
            >
              <span class="text-truncate">
                {{ hasCiderBuffered(revision.data.cider_id).name }}:
              </span>
              <br>
              <span>
                <span v-if="revision.activity.action === 'update'">
                  <div v-if="revision.delta.hasOwnProperty('kommentar')">
                    <v-icon x-small>mdi-comment-account</v-icon>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <span
                          style="text-overflow: ellipsis !important;"
                          v-on="on"
                        >
                          Kommentar geändert (hover)
                        </span>
                      </template>
                      <span v-html="revision.delta.kommentar" />
                    </v-tooltip>
                  </div>
                  <div v-if="revision.delta.hasOwnProperty('finished')">
                    <v-icon x-small>
                      {{ revision.delta.finished ? 'mdi-check-decagram' : 'mdi-clock-edit-outline' }}
                    </v-icon>
                    Cider Status: Finished {{ revision.delta.finished }}
                  </div>
                  <div v-if="revision.delta.hasOwnProperty('started')">
                    <v-icon x-small>
                      {{ revision.delta.finished ? 'mdi-clock-edit-outline' : 'mdi-clock-edit-outline' }}
                    </v-icon>
                    Cider Status: Started {{ revision.delta.started }}
                  </div>
                  <div v-if="revision.delta.hasOwnProperty('restarted')">
                    <v-icon x-small>
                      {{ revision.delta.restarted ? 'mdi-restart' : 'mdi-restart' }}
                    </v-icon>
                    Cider Status: Restarted {{ revision.delta.restarted }}
                  </div>
                </span>
                <span v-if="revision.activity.action === 'create'">
                  Created Chair Note
                </span>
                <span v-if="revision.activity.action === 'delete'">
                  Deleted Chair Note
                </span>
              </span>
            </div>

            <!--Tischfunktionen-->
            <div
              v-else-if="revision.collection === 'tischfunktionen'"
              class="pa-0 text-truncate"
              style="text-overflow: ellipsis !important; white-space: nowrap !important; overflow: hidden !important;"
            >
              <span class="text-truncate">
                Table: {{ hasTableBuffered(revision.data.table_id).name }}
              </span>
              <br>
              <span>
                <span v-if="revision.activity.action === 'update'">
                  <span v-if="revision.delta.hasOwnProperty('frage')">
                    <v-icon x-small>
                      {{ revision.delta.frage ? 'mdi-help-circle' : 'mdi-help-circle-outline' }}
                    </v-icon>
                    {{ revision.delta.frage ? 'Frage gestellt' : 'Frage zurückgenommen / beantwortet' }}
                  </span>
                  <span v-if="revision.delta.hasOwnProperty('melden')">
                    <v-icon x-small>
                      {{ revision.delta.melden ? 'mdi-hand-back-left' : 'mdi-hand-back-left-outline' }}
                    </v-icon>
                    {{ revision.delta.melden ? 'Gemeldet' : 'Melden zurückgenommen / beantwortet' }}
                  </span>
                  <span v-if="revision.delta.hasOwnProperty('next_cider_id')">
                    <v-icon x-small>
                      {{ revision.delta.next_cider_id ? 'mdi-glass-wine' : 'mdi-glass-cocktail-off' }}
                    </v-icon>
                    {{ revision.delta.next_cider_id !== null
                      ? 'Next Cider: ' + hasCiderBuffered(revision.delta.next_cider_id).name
                      : 'Next Cider zurückgenommen' }}
                  </span>
                </span>
                <span v-if="revision.activity.action === 'create'">
                  Created Tischfunktion
                </span>
                <span v-if="revision.activity.action === 'delete'">
                  Deleted Tischfunktion
                </span>
              </span>
            </div>

            <!--Else-->
            <div
              v-else
              class="pa-0 text-truncate"
            >
              (Not implemented)
            </div>
            <v-btn
              icon
              :href="makeRevisionLink(revision)"
              target="_blank"
              style="justify-self: end"
            >
              <v-icon x-small>
                mdi-open-in-new
              </v-icon>
            </v-btn>
          </div>
        </v-timeline-item>
      </v-timeline>
      <v-pagination
        v-model="page"
        :length="page + 8"
        style="position: sticky; bottom: 0;"
        circle
      />
    </v-card-text>
  </v-card>
</template>

<script>
import {apiTyped} from "@/api";
import user_mixin from "@/components/mixins/user_mixin";

export default {
  name: "CardTimeline",
  mixins: [user_mixin],
  props: {
    allCiders: {type: Array, required: true, default: null},
    kategorien: {type: Array, required: true, default: null},
    tables: {type: Array, required: true, default: null},
    isCard: {type: Boolean, required: false, default: true},
  },
  data: () => ({
    firstLoad: true,
    onlyTasting: true,
    loading: false,
    allUsers: [],
    users: [],
    filteredUsers: [],
    dbTables: [],
    dbCider: [],
    dbKategorien: [],
    page: 1,
    pagSteps: 10,
    revisions: null,
    chipFilters: {
      bewertungen: "Bewertungen",
      chair_notes: "Chair Notes",
      cider_notes: "Cider Notes",
      tischfunktionen: "Tischfunktionen",
      user_settings: "User Settings",
    },
    chipFiltersActions: {
      update: "Update",
      create: "Create",
      delete: "Delete"
    },
    collectionFilter: [],
    actionsFilter: [0],
  }),
  computed: {
    filteredCollections() {
      return Object.values(Object.keys(this.chipFilters).filter((f, index) => this.collectionFilter.includes(index)))
    },
    filteredActions() {
      return Object.values(Object.keys(this.chipFiltersActions).filter((f, index) => this.actionsFilter.includes(index)))
    },
    baseUrl() {
      return apiTyped.baseUrl + "admin/content/";
    },
  },
  watch: {
    onlyTasting: async function (newValue) {
      if (newValue) this.users = this.tastingUsers;
      else this.users = this.allUsers;
      this.revisions = await this.getRevisions();
    },
    pagSteps: async function () {
      this.revisions = await this.getRevisions();
    },
    page: async function () {
      this.revisions = await this.getRevisions();
    },
    collectionFilter: async function () {
      this.revisions = await this.getRevisions();
    },
    actionsFilter: async function () {
      this.revisions = await this.getRevisions();
    },
    filteredUsers: async function () {
      this.revisions = await this.getRevisions();
    },
    revisions: function () {
      this.$nextTick(() => { this.emitSizeChanged(); });
    },
  },
  async created() {
    this.tastingUsers = this.tables.flatMap(table => table.juroren);
    this.allUsers = (await apiTyped.adminGetAllUsers()).sort((user1, user2) =>
      user1.lastName.localeCompare(user2.lastName));
    this.users = this.tastingUsers;
    this.collectionFilter = Object.keys(this.chipFilters).map((f, index) => index);
  },
  mounted() {
    // this.$redrawVueMasonry('adminDashboard');
    this.emitSizeChanged();
  },
  methods: {
    async updateRevisions() {
      this.revisions = await this.getRevisions();
    },
    emitSizeChanged() {
      this.$emit("size", {name: "CardTimeline", size: this.$refs.card.$el.clientHeight})
    },
    getCiderById(id) {
      return this.allCiders.find(cider => cider.id === id);
    },
    hasUserBuffered(id) {
      const possibleUser = this.users.find(user => String(user.id) === String(id))
      if (possibleUser !== undefined) return possibleUser
      else return undefined;
    },
    async getUser(id) {
      let user = this.hasUserBuffered(String(id));
      if (user === undefined) {
        user = await apiTyped.getUserById(String(id));
        this.users.push(user);
      }
      return user
    },
    hasTableBuffered(id) {
      const possibleTable = this.dbTables.find(table => table.id === id) || this.tables.find(table => table.id === id);
      if (possibleTable !== undefined) return possibleTable
      else return undefined;
    },
    async getTable(id) {
      let table = this.hasTableBuffered(id);
      if (table === undefined) {
        table = await apiTyped.getTableById(id);
        this.dbTables.push(table);
      }
      return new Promise((resolve) => {resolve(table)});
    },
    hasCiderBuffered(id) {
      const possibleCider = this.dbCider.find(cider => cider.id === id) || this.allCiders.find(cider => cider.id === id);
      console.log("Cider not buffered", id, possibleCider)
      if (possibleCider !== undefined) return possibleCider
      else return undefined;
    },
    async getCider(id) {
      let cider = this.hasCiderBuffered(id);
      if (cider === undefined) {
        cider = await apiTyped.getCiderById(id);
        console.log("Pushing cider", id, cider)
        this.dbCider.push(cider);
      }
      return new Promise((resolve) => {resolve(cider)});
    },
    hasKategorieBuffered(id) {
      const possibleKat = this.dbKategorien.find(k => String(k.id) === String(id)) || this.kategorien.find(k => String(k.id) === String(id));
      if (possibleKat !== undefined) return possibleKat
      else return undefined;
    },
    async getKategorie(id) {
      if (typeof id !== "number") return new Promise((resolve) => {resolve(id)});
      let kategorie = this.hasKategorieBuffered(id);
      if (kategorie === undefined) {
        console.log("Kat not found for ", id, kategorie)
        kategorie = await apiTyped.getAllKategorien(-1, [id]);
        this.dbKategorien.push(...kategorie);
      }
      return new Promise((resolve) => {resolve(kategorie[0])});
    },
    async getRevisions() {
      if (this.filteredActions.length === 0) return []
      this.loading = true;
      const myFilter = {
        "collection": {_in: this.filteredCollections },
      };
      if (this.filteredUsers.length > 0 || this.filteredUsers.length === 0) {  // always true
        const filterUsers = this.filteredUsers.length === 0
          ? this.users.map(user => user.id).join()
          : this.filteredUsers.map(user => user.id).join()
        myFilter["_and"] = [
          {
            "activity": {
              "action": {_in: this.filteredActions},
            }
          },
          {
            "activity": {
              "user": {_in: filterUsers},
            }
          }
        ];
      } else {
        myFilter["activity"] = {
          "action": {_in: this.filteredActions},
        }
      }
      console.log("FITLER", myFilter)
      try {
        const revisions = await apiTyped.sdk.revisions.readByQuery({
          fields: ["id", "activity.*", "collection", "item", "data", "delta", "parent"],
          filter: myFilter,
          sort: '-id',
          limit: this.pagSteps,
          offset: this.pagSteps * (this.page - 1),
        });
        console.log("DB CIDER", revisions)
        // Get the dep. collection items like cider and tables from other tastings:
        const toAddCiderIds = [...new Set(
          revisions.data.filter(rev => rev.data.hasOwnProperty("cider_id")).map(rev => rev.data.cider_id))]
        const toAddTableIds = [...new Set(
          revisions.data.filter(rev => rev.data.hasOwnProperty("table_id")).map(rev => rev.data.table_id))]
        const addCiderPromises = toAddCiderIds.map(id => this.getCider(id));
        const addTablePromises = toAddTableIds.map(id => this.getTable(id));
        const addedCiders = await Promise.all(addCiderPromises);
        await Promise.all(addTablePromises);
        const toAddKategorienPromises = [...new Set(addedCiders.map(cider => cider.kategorie))].map(
          kategorieId => this.getKategorie(kategorieId))
        const k = await Promise.all(toAddKategorienPromises);
        for (let rev of revisions.data) {
          await this.getUser(rev.activity.user);
        }
        this.loading = false;
        this.firstLoad = false;
        return revisions.data;
      } catch (error) {
        console.error("Error while fetching activities");
        this.loading = false;
        this.firstLoad = false;
        return [];
      }
    },
    getKategorieByCiderId(id) {
      const cider = this.hasCiderBuffered(id);
      if (typeof cider.kategorie === "number") return this.hasKategorieBuffered(cider.kategorie)
      else return cider.kategorie
    },
    getTimeDiffText(date) {
      const timeDiff = (Date.now() - Date.parse(date)) / 1000;  // time in s
      if(isNaN(timeDiff)) return 0;
      if (timeDiff < 60)
        return String(Math.floor(timeDiff)) + " s";
      if (timeDiff < 3600)
        return String(Math.floor(timeDiff / 60)) + " m";
      return String(Math.floor(timeDiff / 3600)) + " h";
    },
    getPrettyDate(dateStr) {
      const date = new Date(Date.parse(dateStr));
      return `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}.${date.getSeconds()} Uhr`
    },
    getKategorieByEigenschaftId(id) {
      let kategorie = null;
      let bewertungskategorie = null;
      let eigenschaft = null;
      kategorie = this.kategorien.concat(this.dbKategorien).find(
        kategorie => {
          const b = kategorie.bewertungskategorien.find(
            bew => {
              const e = bew.eigenschaften.find(eig => eig.eigenschaftId === id);
              if (e !== undefined) {
                eigenschaft = e;
                return e;
              }
            }
          )
          if (b !== undefined) {
            bewertungskategorie = b;
            return b
        }})
      return [kategorie, bewertungskategorie, eigenschaft];
    },
    getTableById(id) {
      return this.tables.find(table => table.id === id);
    },
    makeRevisionText(revision) {
      switch (revision.collection) {
        case "bewertungen":
          const [kategorie, bewertungskategorie, eigenschaft] = this.getKategorieByEigenschaftId(
            revision.data.eigenschaft_id);
          return `
            ${kategorie.name}: ${this.getCiderById(revision.data.cider_id).name}<br>
            ${bewertungskategorie.pruefmerkmal} > ${eigenschaft.bezeichnung}: ${revision.data.punkte}`
        case "cider_notes":
          let notesText = "";
          if (revision.delta.hasOwnProperty("finished")) notesText = "Finished"
          if (revision.delta.hasOwnProperty("started")) notesText = "Started"
          return `
            ${this.getCiderById(revision.data.cider_id).kategorie.name}: ${this.getCiderById(revision.data.cider_id).name}<br>
            ${notesText}`
        case "tischfunktionen":
          let funcText = "";
          if (revision.delta.hasOwnProperty("frage")) funcText = "Frage"
          if (revision.delta.hasOwnProperty("melden")) funcText = "Melden"
          if (revision.delta.hasOwnProperty("next_cider_id")) funcText = "Nächster Cider"
          const tischText = this.hasTableBuffered(revision.data.table_id).nummer
          return `
            Tisch ${tischText}<br>
            ${funcText}`
        default:
          return ""
      }
    },
    makeRevisionLink(revision) {
      if (revision.collection === "user_settings") return `${this.baseUrl}${revision.collection}/${revision.data.user_id}`
      return `${this.baseUrl}${revision.collection}/${revision.data.id}`
    },
    ciderNoteFieldChangedText(revision) {
      if (revision.activity.action === "update") {
        if (revision.delta.hasOwnProperty("kommentar")) return "Kommentar: " + revision.delta.kommentar
        if (revision.delta.hasOwnProperty("finished")) return "Cider Status: Finished" + revision.delta.finished
        if (revision.delta.hasOwnProperty("started")) return "Cider Status: Started" + revision.delta.started
        if (revision.delta.hasOwnProperty("is_pinned"))
          return "Cider " + (revision.delta.is_pinned ? "vorgemerkt" : "vorgemerkt gelöscht")
        if (revision.delta.hasOwnProperty("is_favourite"))
          return "Cider " + (revision.delta.is_favourite ? "favorisiert" : "favorisiert gelöscht")
      }
    },
    removeUserFromFilter(item) {
      this.filteredUsers = this.filteredUsers.filter(user => user.id !== item.id);
    }
  }
}
</script>

<style>
.two_lines {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  height: 3.5em; /* Fallback for non-webkit, line-height * 2 */
  line-height: 1.75em;
  -webkit-line-clamp: 2; /* if you change this, make sure to change the fallback line-height and height */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.timeline_grid {
  display: grid;
  grid-template-columns: 150px minmax(200px, auto) auto;
  grid-template-rows: 1fr;
  grid-column-gap: 4px;
  grid-row-gap: 0px;
  justify-items: stretch;
  align-items: stretch;
}
.v-list-item {
  justify-content: start !important;
  align-items: center !important;
  align-self: start !important;
  text-align: start !important;
}
</style>