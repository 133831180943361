<template>
  <v-container
    fluid
    class="d-flex flex-column"
  >
    <PageNavigation
      :title-translation="$t('editProduct')"
      class="pl-md-4 pl-2 mb-6"
    >
      <template #contentRight>
        <v-btn
          icon
          x-large
          class="mr-4"
          @click="deleteCider"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
    </PageNavigation>

    <div
      v-if="loaded"
      class="d-flex"
    >
      <ProducerEditCiderForm
        :cider="cider"
        :check-required-fields="false"
        @changeCounter="changeCounter"
      />
    </div>
    <v-progress-circular
      v-else
      indeterminate
      class="mt-auto mb-auto ml-auto mr-auto"
      size="92"
    />
    <Confirm_dialog ref="confirm" />
    <delete_dialog ref="deleteDialog" />
  </v-container>
</template>

<script>
import ProducerEditCiderForm from "@/components/producer/producer_edit_cider_form";
import {apiTyped} from "@/api";
import ModalConfirm from "@/components/modal_confirm";
import Confirm_dialog from "@/components/confirm_dialog";
import delete_dialog from "@/components/producer/delete_dialog";
import PageNavigation from "@/components/page_navigation";

export default {
  name: "ProducerEditCiderPage",
  components: {PageNavigation, delete_dialog, Confirm_dialog, ProducerEditCiderForm},
  async beforeRouteLeave(to, from, next) {
    if (this.formChangeCounter > 0) {
      if (to.path === "/login") next();
      const okayToLeave = await this.$refs.confirm.open(
        this.$t('unsavedChanges'), this.$t('unsavedChangesMessageProducer'),
        {color: 'secondary_card', thirdButton: true, thirdButtonText: this.$t('discard')}, {btnOkay: 'stay'})
      if (okayToLeave === "third") next();
    } else {
      next();
    }
  },
  data: () => ({
    cider: null,
    loaded: false,
    formChangeCounter: 0,
  }),
  async created() {
    this.cider = await apiTyped.producerGetCiderById(
      this.$store.state.producerStore.producerId, Number(this.$route.params.ciderId));
    this.loaded = true
  },
  methods: {
    async toDashboard() {
      await this.$router.push("/producer/home");
    },
    changeCounter(val) {
      this.formChangeCounter = val;
    },
    async deleteCider() {
      const okayToDel = await this.$refs.deleteDialog.open(
        "Delete Product", `Do you want to delete the product:<br><strong>${this.cider.ciderInfos.name}</strong><br>This will also delete existing submissions for this product.<br><strong>This action cannot be undone.</strong>`,
        {color: 'secondary_card', thirdButton: false})
      if (okayToDel === true) {
        try {
          await apiTyped.deleteCider(this.cider.id);
        } catch (e) {
          console.error(e);
        }
        this.formChangeCounter = 0;
        await this.toDashboard();
      }
    },
  },
}
</script>

<style scoped>

</style>