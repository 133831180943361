<template>
  <v-app-bar
    v-if="isAuthenticated"
    app
    flat
    class="blur_effect no-print"
    :color="$vuetify.theme.currentTheme.app_bar"
    style="max-width: 100vw;"
  >
    <!--    <v-img-->
    <!--        contain-->
    <!--        :lazy-src="getLogoUrl('logo_app', 300)"-->
    <!--        :src="getLogoUrl('logo_app', 300)"-->
    <!--        alt="CiderAwardLogo"-->
    <!--        style="margin: auto;"-->
    <!--        max-height="32px"-->
    <!--        max-width="40px"-->
    <!--    />-->
    <v-btn
      v-if="isSmallScreen"
      icon
      @click="toggleNavBar()"
    >
      <v-icon>
        mdi-menu
      </v-icon>
    </v-btn>

    <v-toolbar-title class="pl-1">
      {{ $store.getters["usersStore/currentTasting"]() !== undefined ? $store.getters["usersStore/currentTasting"]().name : 'Loading...' }}
    </v-toolbar-title>

    <v-spacer />

    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          id="appBarRefresh"
          icon
          v-bind="attrs"
          @click="reload_page"
          v-on="on"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('refreshContent') }}</span>
    </v-tooltip>
    <!--    <v-tooltip bottom>-->
    <!--      <template #activator="{ on, attrs }">-->
    <!--        <v-btn-->
    <!--          id="appBarRefresh"-->
    <!--          icon-->
    <!--          v-bind="attrs"-->
    <!--          @click="migrate"-->
    <!--          v-on="on"-->
    <!--        >-->
    <!--          <v-icon>mdi-delete</v-icon>-->
    <!--        </v-btn>-->
    <!--      </template>-->
    <!--      <span>migrate</span>-->
    <!--    </v-tooltip>-->

    <v-menu
      v-model="showUserMenu"
      class="pt-2"
      rounded="xl"
      bottom
      left
      offset-y
      transition="slide-y-transition"
      :close-on-content-click="false"
      style="z-index: 99;"
    >
      <template #activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn
              id="appBarUserMenu"
              icon
              v-bind="attrs"
              class="ma-1"
              v-on="{ ...tooltip, ...menu }"
            >
              <v-icon>mdi-account-circle</v-icon>
            </v-btn>
          </template>
          <span>Account</span>
        </v-tooltip>
      </template>
      <v-card color="dialog_background">
        <v-list>
          <v-list-item>
            <theme-menu />
            <!--            <div>-->
            <!--              <v-tooltip-->
            <!--                v-if="!$vuetify.theme.dark"-->
            <!--                bottom-->
            <!--              >-->
            <!--                <template #activator="{ on }">-->
            <!--                  <v-btn-->
            <!--                    color="#616161"-->
            <!--                    small-->
            <!--                    fab-->
            <!--                    class="elevation-2"-->
            <!--                    @click="toggleDarkMode"-->
            <!--                    v-on="on"-->
            <!--                  >-->
            <!--                    <v-icon-->
            <!--                      class="mr-1"-->
            <!--                      color="white"-->
            <!--                    >-->
            <!--                      mdi-moon-waxing-crescent-->
            <!--                    </v-icon>-->
            <!--                  </v-btn>-->
            <!--                </template>-->
            <!--                <span>Turn Dark Mode On</span>-->
            <!--              </v-tooltip>-->
            <!--              <v-tooltip-->
            <!--                v-else-->
            <!--                bottom-->
            <!--              >-->
            <!--                <template #activator="{ on }">-->
            <!--                  <v-btn-->
            <!--                    color="#616161"-->
            <!--                    small-->
            <!--                    fab-->
            <!--                    v-on="on"-->
            <!--                    @click="toggleDarkMode"-->
            <!--                  >-->
            <!--                    <v-icon color="white">-->
            <!--                      mdi-white-balance-sunny-->
            <!--                    </v-icon>-->
            <!--                  </v-btn>-->
            <!--                </template>-->
            <!--                <span>Turn Dark Mode Off</span>-->
            <!--              </v-tooltip>-->
            <!--            </div>-->
            <v-list-item-content>
              <v-list-item-title style="text-align: right">
                {{ user_info.firstName }} {{ user_info.lastName }}
              </v-list-item-title>
              <v-list-item-subtitle style="text-align: right">
                {{ user_info.role }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar>
              <img
                :src="get_user_avatar(200)"
                alt="Avatar"
              >
            </v-list-item-avatar>
          </v-list-item>
        </v-list>

        <v-divider />


        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="clear_cookies"
          >
            Komplett neu laden
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="logout"
          >
            Logout
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <v-progress-linear
      :active="$store.state.pageLoading"
      :indeterminate="$store.state.pageLoading"
      absolute
      bottom
    />
  </v-app-bar>
</template>

<script>
import {get} from "vuex-pathify";
import store from "@/stores";
import {apiTyped} from "@/api";
import user_mixin from "@/components/mixins/user_mixin";
import ThemeMenu from "@/components/theme_menu";
import logos_mixin from "@/components/mixins/logos_mixin";

export default {
  name: "AdminAppBar",
  components: {ThemeMenu},
  mixins: [user_mixin, logos_mixin],
  data: () => ({
    showUserMenu: false,
  }),
  computed: {
    isAuthenticated: get("appStore/authenticated"),
    user_info: get("usersStore/me"),
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return true
        default:
          return false
      }
    },
  },
  mounted() {
    if (this.isSmallScreen) this.$store.set('adminNavDrawer', false);
  },
  methods: {
    async migrate() {
      await apiTyped.migrate();
    },
    get_user_avatar (width) {
      try {
        return apiTyped.getUserAvatarUrl(width, null, this.$vuetify.theme.currentTheme);

      } catch(error) {
        console.error("Error getting Avatar URL:", error)
        return false;
      }
    },
    reload_page () {
      this.$store.set('reload_router', !this.$store.state.reload_router);
      this.showUserMenu = false;
    },
    async logout() {
      await store.dispatch("usersStore/logout", "/login");
    },
    async clear_cookies() {
      await this.logout();
      await this.$store.dispatch('clearStore');
    },
    toggleNavBar() {
      this.$store.set('adminNavDrawer', !this.$store.state.adminNavDrawer);
    },
  },
}
</script>

<style scoped>
@media print {
  .no-print, .no-print
  {
    display: none !important;
  }
}
.blur_effect {
  backdrop-filter: blur(2px);
  -webkit-box-shadow: inset 0 0 200px #00000000;
  -moz-box-shadow: inset 0 0 200px #00000000;
  box-shadow: inset 0 0 200px #00000000;
}
</style>