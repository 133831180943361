<template>
  <div class="pa-4">
    <div
      v-if="product != null"
      class="parent"
    >
      <div
        class="logo"
        style="position: relative;"
      >
        <div
          class="overflow-visible"
          style="position: absolute; top: 10px; left: 20px;"
        >
          <v-img
            contain
            :lazy-src="getLogoUrl(tasting.logoMesse, 500)"
            :src="getLogoUrl(tasting.logoMesse, 500)"
            alt="CiderAwardLogo"
            class="overflow-visible"
            max-width="125"
            max-height="108"
          />
        </div>
      </div>
      <div
        class="one_col flex-column justify-start align-center pl-2"
        style="border-left: 1px solid var(--v-divider_light-base);"
      >
        <span
          class="d-flex justify-start big_text font-weight-bold"
          style="font-size: 20px;"
        >
          {{ product.displayId }}
        </span>
        <span class="d-flex justify-start translated_text text--secondary">
          {{ $t('displayId') }}
        </span>
      </div>
      <div
        class="one_col flex-column justify-start align-center pl-2"
        style="border-left: 1px solid var(--v-divider_light-base);"
      >
        <span
          :id="`fontSizeKategorie_${product.id}_${product.flightId}`"
          class="d-flex justify-start big_text"
          :style="{'font-size': fontSizeKategorie + 'px'}"
        >
          {{ product.kategorie.name }}
        </span>
        <span class="d-flex justify-start translated_text text--secondary">
          {{ $tc('category', 1) }}
        </span>
      </div>
    </div>

    <div class="px-4 mx-auto pt-1">
      <v-row
        align="baseline"
        no-gutters
      >
        <v-col
          v-if="getProfile(product) != null"
          cols="12"
          align="start"
          style="text-align: left"
        >
          <span class="text-left info-label text--secondary text-caption">
            Profile
          </span>
          <p
            class="mb-0 small-p-margin overflow-text-vertical"
            style="font-size: .7rem; max-height: 96px !important;"
            v-html="getProfile(product).replace('<br><br>', '<br>')"
          />
        </v-col>
      </v-row>
      <v-row
        justify="start"
        class="pt-0 pb-0"
      >
        <v-col
          cols="4"
          class="pt-0 pb-0"
        >
          <div class="d-flex flex-row">
            <div class="d-flex flex-column align-start">
              <span class="info-label text--secondary text-caption text-left">
                {{ $t('alcoholAbv') }}
              </span>
              <span class="data-entry text-caption text-left">
                {{ product.alkohol }} % vol.
              </span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="4"
          class="pt-0 pb-0"
        >
          <div class="d-flex flex-row">
            <div class="d-flex flex-column align-start">
              <span class="info-label text--secondary text-caption text-left">
                {{ $t('filtrationClarity') }}
              </span>
              <span class="data-entry text-caption text-left">
                {{ product.filtrationClarity || '-' }}
              </span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="4"
          class="pt-0 pb-0"
        >
          <div class="d-flex flex-column align-start">
            <span class="info-label text--secondary text-caption text-left">
              {{ $t('method') }}
            </span>
            <span class="data-entry text-caption text-left">
              {{ product.method || '-' }}
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row
        justify="start"
        class="pt-2 pb-2"
      >
        <v-col
          cols="4"
          class="pt-0 pb-0"
        >
          <div class="d-flex flex-column align-start">
            <span class="text-left info-label text--secondary text-caption">
              {{ $t('pressure') }}
            </span>
            <span class="text-wrap data-entry text-caption text-left">
              {{ product.pressure || '-' }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="8"
          class="pt-0 pb-0"
        >
          <div class="d-flex flex-column align-start">
            <span class="info-label text--secondary text-caption text-left">
              {{ $t('residualSugar') }}
            </span>
            <div class="d-flex align-start">
              <span
                class="d-flex data-entry text-caption text-left"
                style="min-width: 64px;"
              >
                {{ product.residualSugar || '-' }} g/L
              </span>
              <v-icon>
                mdi-circle-small
              </v-icon>
              <span class="d-flex data-entry text-caption text-left flex-grow-0">
                {{ product.residualSugarDescription || '-' }}
              </span>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import JurorCider from "@/types/jurorCider";
import cider_mixin from "@/components/mixins/cider_mixin";
import logos_mixin from "@/components/mixins/logos_mixin";
import {apiTyped} from "@/api";

export default {
  name: "ManualRatingJudgeHeader",
  mixins: [cider_mixin, logos_mixin],
  props: {
    product: {type: JurorCider, required: true, default: () => null},
    tasting: {type: Object, required: false, default: () => null},
  },
  data: () => ({
    fontSizeKategorie: 20,
  }),
  watch: {
    product: function () {
      this.fontSizeKategorie = 20;
      this.updateFlexFont();
    },
  },
  mounted() {
    this.updateFlexFont();
  },
  updated() {
    this.updateFlexFont();
  },
  methods: {
    getImgUrl(id, width) {
      return apiTyped.getImgUrl(id, width);
    },
    updateFlexFont() {
      this.flexFont(`fontSizeCider_${this.product.id}_${this.product.flightId}`, this.fontSizeCider, 'fontSizeCider');
      this.flexFont(`fontSizeProduzent_${this.product.id}_${this.product.flightId}`, this.fontSizeProduzent, 'fontSizeProduzent');
      this.flexFont(`fontSizeKategorie_${this.product.id}_${this.product.flightId}`, this.fontSizeKategorie, 'fontSizeKategorie');
    },
    getLogoUrl(id, width) {
      return apiTyped.getImgUrl(id, width);
    },
    flexFont (id, maxWidth, name) {
      this.$nextTick(() =>
      {
        const div = document.getElementById(id);
        if (div === null || div === undefined) return "20px";
        let fontWidth = 1000000;
        const divWidth = div.offsetWidth;  // TODO estimate new if layout changes
        // const divWidth = 513;
        console.log("OFFSET WIDTH", divWidth)
        // let i = 0;
        const font = this.getCanvasFontSize(div);
        let fontSize = maxWidth // Number(font[1].slice(0, font[1].length - 2));
        fontWidth = this.getTextWidth(div.innerHTML, `${font[0]} ${fontSize}px ${font[2]}`);
        while(divWidth > 0 && divWidth < fontWidth) {
          fontSize = fontSize - 1;
          fontWidth = this.getTextWidth(div.innerHTML, `${font[0]} ${fontSize}px ${font[2]}`);
          console.log("FontW", fontWidth, divWidth, divWidth < fontWidth, fontSize)
        }
        this[name] = fontSize;
      });
    },
    getTextWidth(text, font) {
      // re-use canvas object for better performance
      const canvas = this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement("canvas"));
      const context = canvas.getContext("2d");
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    },
    getCssStyle(element, prop) {
      return window.getComputedStyle(element, null).getPropertyValue(prop);
    },
    getCanvasFontSize(el = document.body) {
      const fontWeight = this.getCssStyle(el, 'font-weight') || 'normal';
      const fontSize = this.getCssStyle(el, 'font-size') || '16px';
      const fontFamily = this.getCssStyle(el, 'font-family') || 'Times New Roman';

      return [fontWeight, fontSize, fontFamily];
    },
  }

}
</script>

<style scoped>
.parent {
    display: grid;
    grid-template-columns: 2fr 7fr;
    grid-template-rows: repeat(3, min-content);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
.logo {
    grid-area: 1 / 1 / 4 / 2;
    max-height: 125px;
    align-items: self-start;
    /*align-self: center;*/
    /*justify-self: center;*/
}
.one_col {
    grid-column: auto;
}
.translated_text_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: center;
}
.translated_text {
    font-weight: 200;
    //font-style: italic;
}
.big_text {
    text-decoration: underline var(--v-divider_light-base);
    text-underline-offset: 10%;
    text-decoration-thickness: 3px;
}
/deep/ .small-p-margin > p {
    margin-bottom: 4px !important;
    margin-top: 0 !important;
}

.overflow-text-vertical {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}
</style>