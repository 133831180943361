import {components, MyCollections} from "@/api/types/my-collections";
import {Badge} from "@/types/badge";
import {createLanguageFromDb, Language} from "@/types/language";
import {createTranslationMessagesFromDb, TranslationMessages} from "@/types/translationMessages";
import {TastingProduct} from "@/types/tastingProduct";

interface Tasting {
    id: number;
    status: string;
    name: string;
    datum: Date | null;
    date_created: Date | null;
    beschreibung: string;
    logoMesse: string | null;
    badges: Badge[];
    einreichenAb: Date | null;
    einreichenBis: Date | null;
    started: boolean;
    finished: boolean;
    resultsPublished: boolean;
    abbreviation: string;
    tastingInfos: TastingInfos | null;
    translations: TranslationMessages | null;
}

interface TastingSettings {
    maxCiders: number | null;
    ciderInfoLanguages: Language[];
    submissionNeedsPayment: boolean;
    allowProductDataUpdate: boolean;
    paymentUrl: string;
    hasPurchasables: boolean;
    hasOnlinePayment: boolean;
}

interface TastingPrices {
    pricePerSubmission: number;
    submissionFee: number;
    taxes: number;
    currency: string;
    priceIncludesTaxes: boolean;
}

interface TastingTimelineEntry {
    date: string;
    eventTranslations: TranslationMessages;
    icon: string;
    color: string;
    url: string;
}

interface TastingPartners {
    status: string;
    name: string;
    url: string;
    logo: string;
    description: string;
    backgroundColor: string;
}

interface TastingInfos {
    status: string;
    // timeline: TastingTimelineEntry[] | null;
    eventTimeline: TastingTimelineEntry[] | null;
    topDescription: string;
    bottomDescription: string;
    partners: TastingPartners[];
    headerImage: string | null;
    tastingHomepage: string | null;
    translations: TranslationMessages | null;
}

interface ProducerTasting extends Tasting {
    eingereichteCiderIds?: number[];
    tastingSettings?: TastingSettings;
    products: TastingProduct[] | null;
    tastingPrices: TastingPrices;
    country: string;
    city: string;
}

interface ProducerPaidProducts {
    status: string;
    tastingId: number;
    numberOfProducts: number;
}

function createPartnerFromDatabase(partner: MyCollections["partners"]) {
    return <TastingPartners>{
        description: partner.description || "",
        logo: partner.logo || "",
        url: partner.url || "",
        name: partner.name || "",
        status: partner.status || "",
        backgroundColor: partner.background_color || "",
    }
}

function createEventTimeline(timeline: { [key: string]: any } | null) {
    console.log("GOT timeline", timeline)
    if (timeline == null) return null;
    return timeline.map((t: { [key: string]: any }) => {
        const eventTranslations: TranslationMessages = {};
        for (const key of Object.keys(t)) {
            if (key.startsWith("event")) {
                const splitKey = key.split("_");
                eventTranslations[splitKey[1] as string] = {"event": t[key]};
            }
        }
        return <TastingTimelineEntry>{
            date: t.date,
            eventTranslations: eventTranslations,
            color: t.color || "",
            icon: t.icon || "",
            url: t.link,
        }
    });
}

export function createTastingFromDatabase(tasting: MyCollections["tastings"]): Tasting {
    let tastingInfos: TastingInfos | null = null;
    if (tasting.tasting_infos != null && typeof tasting.tasting_infos !== "number") {
        const partners = tasting.tasting_infos.partners as components["schemas"]["ItemsTastingInfosPartners"][] || [];
        tastingInfos = {
            bottomDescription: tasting.tasting_infos.bottom_description || "",
            topDescription: tasting.tasting_infos.top_description || "",
            translations: createTranslationMessagesFromDb(tasting.tasting_infos.translations as any[]),
            // timeline: (tasting.tasting_infos.timeline as unknown as TastingTimelineEntry[]) || null,
            eventTimeline: createEventTimeline(tasting.tasting_infos.event_timeline || null),
            partners: partners.map(p => createPartnerFromDatabase(p.partners_id as MyCollections["partners"])),
            status: tasting.tasting_infos.status || "",
            headerImage: tasting.tasting_infos.header_image as string || null,
            tastingHomepage: tasting.tasting_infos.tasting_homepage || null,
        }
        // backwards compatibility
        // if (tastingInfos.eventTimeline == null && tasting.tasting_infos.timeline != null) {
        //     tastingInfos.eventTimeline = (tasting.tasting_infos.timeline as unknown as TastingTimelineEntry[])
        // }
    }
    return <Tasting>{
        id: Number(tasting.id),
        status: tasting?.status,
        name: tasting.name || "",
        date_created: tasting.date_created ? new Date(tasting.date_created) : null,
        datum: tasting.datum ? new Date(tasting.datum) : null,
        beschreibung: tasting.beschreibung || "",
        translations: createTranslationMessagesFromDb(tasting.translations as any[]),
        logoMesse: tasting.logo_messe,
        badges: tasting.medaillien || [],
        einreichenAb: tasting.einreichen_ab ? new Date(tasting.einreichen_ab) : null,
        einreichenBis: tasting.einreichen_bis ? new Date(tasting.einreichen_bis) : null,
        started: tasting.started || false,
        finished: tasting.finished || false,
        resultsPublished: tasting.results_published || false,
        abbreviation: tasting.abbreviation || "",
        tastingInfos: tastingInfos,
    };
}

export function createProducerTastingFromDatabase(tasting: MyCollections["tastings"]): ProducerTasting {
    console.log("createProducerTastingFromDatabase", tasting)
    const newTasting: ProducerTasting = createTastingFromDatabase(tasting) as ProducerTasting;
    newTasting.eingereichteCiderIds = tasting.eingereichte_ciders as number[] || [];
    newTasting.city = tasting.event_city || "";
    newTasting.country = typeof tasting.event_country !== "number" ? tasting.event_country?.name || "" : "";
    if (tasting.tasting_prices == null || typeof tasting.tasting_prices === "number") {
        newTasting.tastingPrices = {
            currency: "€",
            taxes: 0.19,
            pricePerSubmission: 0,
            submissionFee: 0,
            priceIncludesTaxes: true,
        }
    } else {
        console.log("tasting.tasting_prices.price_includes_taxes", tasting.tasting_prices)
        newTasting.tastingPrices = {
            currency: tasting.tasting_prices.currency || "€",
            taxes: tasting.tasting_prices.taxes || 0,
            pricePerSubmission: tasting.tasting_prices.price_per_submission || 0,
            submissionFee: tasting.tasting_prices.submission_fee || 0,
            priceIncludesTaxes: tasting.tasting_prices.price_includes_taxes ?? false
        }
    }
    if (tasting.tasting_settings != null) {
        // create settings
        const settings = tasting.tasting_settings as MyCollections["tasting_settings"];
        newTasting.tastingSettings = {
            maxCiders: Number(settings.max_ciders) || null,
            ciderInfoLanguages: settings?.cider_info_languages?.map(
                (l: any) => createLanguageFromDb(l.languages_code as MyCollections["languages"])) || [],
            submissionNeedsPayment: settings.submission_needs_payment || false,
            paymentUrl: settings.payment_url || "",
            hasPurchasables: settings.has_purchasables || false,
            allowProductDataUpdate: settings.allow_product_data_update || false,
            hasOnlinePayment: settings.has_online_payment || false,
        };
    } else {
        newTasting.tastingSettings = <TastingSettings>{
            maxCiders: null,
            ciderInfoLanguages: [],
            submissionNeedsPayment: false,
            paymentUrl: "",
            hasPurchasables: false,
            allowProductDataUpdate: false,
            hasOnlinePayment: false,
        }
    }
    return newTasting;
}


export {Tasting, ProducerTasting, ProducerPaidProducts};