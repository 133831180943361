import {apiTyped} from "@/api";
import {RoleName} from "@/types/user";

export default {
    methods: {
        async refreshLogin(userRole) {
            const loginUser = await apiTyped.loginRefresh();
            console.log("loginUser", loginUser)
            if (loginUser == null
              || loginUser.role == null
              || (loginUser.role !== userRole && loginUser.role !== RoleName.Admin)) {
                // this.errorMsg = 'tokenExpired';
                // throw {
                //     name: 'TokenExpired',
                //     message: 'Login expired. Please enter credentials again.'
                // };
                return null;
            }
            return loginUser || null;
        }
    }
}