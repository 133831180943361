<template>
  <v-card
    class="ma-2 mb-0"
    width="100%"
    outlined
    elevation="0"
  >
<!--    <v-card-title class="pt-2 pb-0">-->
<!--      <v-row-->
<!--        align="baseline"-->
<!--        class="ma-0"-->
<!--      >-->
<!--        <p class="ma-0">-->
<!--          &lt;!&ndash;          Products&ndash;&gt;-->
<!--        </p>-->
<!--        <v-spacer />-->
<!--        <v-text-field-->
<!--          v-model="searchProducts"-->
<!--          class="mb-2 ml-4"-->
<!--          append-icon="mdi-magnify"-->
<!--          :label="$t('search')"-->
<!--          outlined-->
<!--          dense-->
<!--          rounded-->
<!--          single-line-->
<!--          hide-details-->
<!--        />-->
<!--      </v-row>-->
<!--    </v-card-title>-->
    <v-data-table
      v-if="tableHeaders"
      :headers="tableHeaders"
      :items="ciders"
      :group-by="groupByHeader"
      :items-per-page="100"
      :footer-props="{'items-per-page-options': [10, 25, 50, 100], 'items-per-page-text': $t('rowsPerPage')}"
      show-group-by
      multi-sort
      :loading-text="$t('loadingCidersWait')"
      item-key="id"
      :search="searchProducts"
      single-expand
      :expanded.sync="expanded"
      :show-expand="false"
      @click:row="item => $emit('clickProduct', item)"
    >
      <template #group.header="{ group, groupBy, headers, toggle, remove, isOpen, items }">
        <td
          :colspan="headers.length"
          style="min-height: 48px !important; box-sizing: border-box; margin: 0 !important; position: relative; width: 100vw;"
          :style="{'background-color': $vuetify.theme.currentTheme.surface}"
        >
          <div class="d-flex flex-row align-center justify-start pl-4">
            <v-btn
              :ref="group"
              x-small
              icon
              @click="toggle"
            >
              <transition name="fade">
                <v-icon v-if="isOpen">
                  mdi-chevron-up
                </v-icon>
                <v-icon v-else>
                  mdi-chevron-down
                </v-icon>
              </transition>
            </v-btn>

            <!-- Custom Header Groups -->
            <span
              v-if="groupBy[0] === 'ciderInfos.land.name'"
              class="d-flex align-center mx-5 font-weight-bold"
            >
              <template v-if="items[0].ciderInfos.land != null">
                <country-flag
                  class="elevation-2"
                  :country="items[0].ciderInfos.land.code"
                  style="border-radius: 10px !important; min-width: 26px !important; overflow: visible !important;"
                  size="normal"
                />
                {{ items[0].ciderInfos.land.name }}
              </template>
              <span v-else>
                No Country
              </span>
            </span>
            <span
              v-else
              class="mx-5 font-weight-bold"
            >{{ group }}</span>
            <v-btn
              :ref="group"
              x-small
              fab
              icon
              @click="remove"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </div>
        </td>
      </template>
      
      <template
        #expanded-item="{ headers, item }"
      >
        <td
          :colspan="headers.length"
          class="elevation-0 text-left mb-2"
        >
          <div>
            <v-badge
              :content="item.revisions.length || '1'"
              inline
              left
            >
              {{ $t('tastings') }}
            </v-badge>
            <v-data-table />
          </div>
        </td>
      </template>

      <template #item.ciderInfos.land.name="{ item }">
        <TableCountryField :item="item" />
      </template>

      <template #item.ciderInfos.alkohol="{ item }">
        <span>{{ item.ciderInfos.alkohol }} %</span>
      </template>

      <template #item.ciderInfos.profil="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <div
              style="max-width: 142px; text-overflow: ellipsis; overflow: hidden;"
            >
              <span
                style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                v-on="on"
              >
                {{ extractContent(item.ciderInfos.profil) }}
              </span>
            </div>
          </template>
          <v-card-text
            class="text-left pa-0"
            v-html="item.ciderInfos.profil"
          />
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {CProducerCiderResults} from "@/types/producerCider";
import i18n from "@/i18n";
import TableCountryField from "@/components/producer/table/country_field";
import {Parser as DOMParser} from "canvg";
import CountryFlag from "vue-country-flag";

export default {
  name: "ProducerCiderTable",
  components: {TableCountryField, CountryFlag},
  props: {
    ciders: {type: Array, required: true, default: null},
    searchProducts: {type: String, required: true, default: ''}
  },
  data: () => ({
    groupByHeader: null,
    expanded: [],
  }),
  computed: {
    cidersData() {
      return this.ciders.map(cider => {
        return cider;
      })
    },
    tableHeaders() {
      return [
        {text: 'Name', value: 'ciderInfos.name', align: 'center', sortable: true, groupable: false, myGroupable: true, width: 170},
        {text: 'Category', value: 'ciderInfos.kategorie', align: 'center', sortable: true, groupable: true, myGroupable: true},
        {text: 'Country', value: 'ciderInfos.land.name', align: 'center', sortable: true, groupable: true},
        {text: 'Description', value: 'ciderInfos.profil', align: 'left', width: 128, sortable: false, groupable: false},
        {text: i18n.t('Alcohol'), value: 'ciderInfos.alkohol', align: 'right', sortable: true, groupable: true},

        {text: i18n.t('Sales Designation'), value: 'ciderInfos.salesDesignation', align: 'right', sortable: true, groupable: true},
        {text: i18n.t('Designation of Origin'), value: 'ciderInfos.designationOfOrigin', align: 'right', sortable: true, groupable: true},
        {text: i18n.t('Varieties'), value: 'ciderInfos.varieties', align: 'right', sortable: true, groupable: true},
        {text: i18n.t('Residual Sugar'), value: 'ciderInfos.residualSugar', align: 'right', sortable: true, groupable: true},
        {text: i18n.t('Res. Sugar Description'), value: 'ciderInfos.residualSugarDescription', align: 'right', sortable: true, groupable: true},
        {text: i18n.t('Filtration/Clarity'), value: 'ciderInfos.filtrationClarity', align: 'right', sortable: true, groupable: true},
        {text: i18n.t('Pressure'), value: 'ciderInfos.pressure', align: 'right', sortable: true, groupable: true},
        {text: i18n.t('Method'), value: 'ciderInfos.method', align: 'right', sortable: true, groupable: true},
        {text: i18n.t('Bottle'), value: 'ciderInfos.bottleContent', align: 'right', sortable: true, groupable: true},
        {text: i18n.t('Price'), value: 'ciderInfos.price', align: 'right', sortable: true, groupable: true},
      ]
    },
  },
  methods: {
    extractContent(html) {
      const span = document.createElement('span');
      span.innerHTML = html;
      return span.textContent || span.innerText;
      // return new DOMParser()
      //   .parseFromString(html, "text/html; charset=utf-8")
      //   .documentElement.textContent;
    }
  }

}
</script>

<style>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: unset !important;
  margin-bottom: 8px !important;
}
</style>