<template>
  <div
    style="background-color: var(--v-background-base)"
  >
    <v-card-text
      id="footerBadgesInfo"
      class="d-flex align-center justify-center"
    >
      <div
        v-for="badge in filteredBadgesData"
        :key="badge.id"
        class="ml-2 mr-2"
      >
        <Badge
          :badge-shadow="badgeShadow"
          :badge-data="badge"
          :show-none-badge="false"
          :points="badge.punkteMin"
          :badge-width="56"
          :badge-resolution="-1"
        />
        <!--        <v-btn-->
        <!--          slot="activator"-->
        <!--          fab-->
        <!--          dark-->
        <!--          small-->
        <!--          color="transparent"-->
        <!--          :elevation="badgeShadow ? 10: 0"-->
        <!--          min-height="56px"-->
        <!--          min-width="56px"-->
        <!--        >-->
        <!--          <v-img-->
        <!--            :key="badge.punkteMin"-->
        <!--            contain-->
        <!--            max-height="56px"-->
        <!--            max-width="56px"-->
        <!--            :src="getBadgeUrl(800, badge.punkteMin)"-->
        <!--            alt="Badge"-->
        <!--            @load="initCounter"-->
        <!--          />-->
        <!--        </v-btn>-->
        <span
          style="white-space: nowrap"
          class="pt-2"
        >
          {{ badge.stufe }}: {{ badge.punkteMin }}-{{ badge.punkteMax }}
        </span>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import Badge from "@/components/badge";
export default {
  name: "FooterBadges",
  components: {Badge},
  props: {
    badgesData: {type: Array, required: true, default: () => []},
    showNoneBadge: {type: Boolean, required: false, default: true},
    preloadBadges: {type: Boolean, required: false, default: true},
    badgeShadow: {type: Boolean, required: false, default: true},
  },
  data: () => ({
    imagesLoaded: 0,
  }),
  computed: {
    filteredBadgesData() {
      if (!this.showNoneBadge) {
        return this.badgesData.filter(
            badge => badge.punkteMin !== 0);
      } else {
        return this.badgesData;
      }
    }
  },
  mounted() {
    if (this.preloadBadges) {
      for (const badge of this.badgesData) {
        const image = new Image();
        image.src = this.getBadgeUrl(300, badge.punkteMin);
      }
    }
  },
  // updated() {
  //   console.log("CIDER FOOTER IS UPDATED")
  // },
  methods: {
    getBadgeUrl(width, points) {
      return this.$store.getters["badgesStore/getBadgeIcon"](points, width);
    },
    initCounter() {
      this.imagesLoaded = this.imagesLoaded + 1;
      // if (this.imagesLoaded >= this.badgesData.length)
    }
  }
}
</script>

<style scoped>

</style>