<template>
  <v-container
    fluid
    class="ma-0"
  >
    <v-row>
      <v-col>
        <div class="d-flex align-center login-title">
          Login
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-0 mb-0">
      <v-col>
        <v-text-field
          v-model="username"
          outlined
          hide-details
          name="username"
          label="E-Mail"
          type="text"
          placeholder="E-Mail"
          @keyup.enter="login"
        />
      </v-col>
    </v-row>
    <v-row class="mt-0 mb-0">
      <v-col>
        <v-text-field
          id="password"
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          outlined
          hide-details
          name="password"
          :label="$i18n.t('Login.password')"
          :placeholder="$i18n.t('Login.password')"
          @keyup.enter="login"
          @click:append="showPassword = !showPassword"
        />
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-row
        v-if="requiresOtp"
        class="mt-0 mb-0"
      >
        <v-col>
          <v-text-field
            ref="otpTextField"
            v-model="otp"
            type="text"
            outlined
            hide-details
            name="otp"
            :label="$i18n.t('Login.otp')"
            :placeholder="$i18n.t('Login.otp')"
            @keyup.enter="login"
          />
        </v-col>
      </v-row>
    </v-expand-transition>
    <v-fade-transition>
      <p
        v-show="errorMsg !== null"
        class="mb-2 mt-2"
      >
        {{ errorMsg }}
      </p>
    </v-fade-transition>
    <v-row
      class="mt-0 mb-0"
    >
      <v-col cols="auto">
        <v-btn
          text
          style="text-transform: none !important;"
          @click="$router.push('/login/reset')"
        >
          {{ $i18n.t('Login.forgotPassword') }}
        </v-btn>
      </v-col>
      <v-col
        class="ml-auto"
        cols="auto"
      >
        <v-btn
          elevation="0"
          color="primary"
          :loading="loading"
          @click="login"
        >
          {{ $i18n.t('Login.login') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n'

export default {
  name: "LoginForm",
  props: {
    loading: {type: Boolean, required: true, default: false},
    errorMsg: {type: String, required: false, default: null},
    requiresOtp: {type: Boolean, required: true, default: false},
  },
  data() {
    return {
      username: '',
      password: '',
      otp: null,
      showPassword: false,
    };
  },
  watch: {
    requiresOtp: function (newVal) {
      if (newVal === true) this.$nextTick(() => this.$refs.otpTextField.focus());
    }
  },
  methods: {
    login() {
      console.log("Sending otp", this.otp)
      this.$emit('login', {username: this.username, password: this.password, otp: this.otp});
    },
  }
}
</script>

<style scoped>
.login-title {
  font-family: "Nunito Sans", serif !important;
  font-weight: 800;
  font-size: 32pt !important;
}
</style>