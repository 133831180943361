<!--uses locales-->
<template>
  <v-container
    fluid
    class="pl-md-8 pr-md-8 pl-sm-2 pr-sm-2"
    style="max-width: 2000px;"
  >
    <div class="d-flex text-h2 mb-10 align-center text-left">
      <v-btn
        icon
        x-large
        class="mr-4"
        @click="$router.push('/producer/home')"
      >
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      {{ $t('submitProducts') }}
    </div>

    <producer-submit-stepper
      :current-step="submitTastingSteps.checkoutCompleted"
      disable-all
    />
    <step-navigation
      :next-disabled="true"
      :prev-disabled="false"
      :hide-next="true"
      :hide-prev="false"
      :prev-text="'Back to Submission'"
      @prev="$router.push(`/producer/submit/summary`)"
      @next="$router.push(`/producer/home`)"
    />

    <v-container
      ref="cancelCard"
      class="pt-16 pb-16 ml-auto mr-auto"
    >
      <v-hover
        v-slot="{ hover }"
      >
        <v-card
          class="pb-3 ml-auto mr-auto"
          width="400"
          outlined
          :elevation="hover ? 12 : 0"
          style="border-style: solid; border-width: medium; transition: box-shadow 0.2s ease-in-out;"
        >
          <v-icon
            ref="check"
            size="192"
            class="pb-3"
          >
            mdi-credit-card-remove
          </v-icon>
          <v-card-subtitle
            class="pb-0 d-flex align-end justify-center"
            style="color: var(--v-error-base); font-size: 18px !important;"
          >
            {{ $t('paymentHandling.paymentCancelled') }}
          </v-card-subtitle>
          <v-card-subtitle
            class="pb-2 pt-1"
          >
            <v-badge
              :content="selectedCiders.length"
              inline
              color="primary"
              left
              bordered
            >
              {{ selectedCiders.length === 1 ? 'Product was' : 'Products were' }} still saved for submission
            </v-badge>
          </v-card-subtitle>
          <span
            class="ml-auto mr-auto text-center pr-4 pl-4"
            style="opacity: 0.92;"
          >
            <span>
              {{ selectedTasting.tastingSettings.submissionNeedsPayment
                ? $t('paymentHandling.completeUntilPrepaid')
                : $t('paymentHandling.completeUntilPay') }}
              <br>
              <strong>{{ new Date(selectedTasting.einreichenBis).toLocaleString() }}</strong>
            </span>
          </span>
          <v-card-actions class="pt-3 pb-2 pl-4 pr-4">
            <v-spacer />
            <v-btn
              rounded
              elevation="0"
              color="primary"
              style="text-transform: none !important;"
              @click="$router.push('/producer/submit/summary')"
            >
              <v-icon left>
                mdi-credit-card-refresh
              </v-icon>
              {{ $t('paymentHandling.payNow') }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-hover>
    </v-container>
    <feedback-dialog
      v-model="showFeedback"
      :title="$t('Feedback.title')"
      origin="submit_process"
    />
    <v-divider />
    <div class="d-flex text-h2 mb-10 pt-8 align-center text-left">
      {{ $t('paymentHandling.submittedProducts') }}
    </div>
    <v-progress-circular
      v-if="!loaded"
      indeterminate
      class="mt-auto mb-auto ml-auto mr-auto"
      size="92"
    />
    <div v-if="loaded && ciders != null">
      <v-row no-gutters>
        <v-col
          v-for="cider in ciders"
          :key="cider.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
          class="pa-3"
          style="cursor: pointer;"
        >
          <v-hover
            v-slot="{ hover }"
          >
            <cider-card
              :cider="cider"
              :elevation="hover ? 10 : 1"
              :select-revision="selectedTasting.id"
            />
          </v-hover>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="loaded"
    >
      <div class="d-flex text-h2 mb-10 pt-4 align-center text-left">
        {{ $t('paymentHandling.tasting') }}
      </div>
      <div class="d-flex ml-auto mr-auto justify-center">
        <tasting-card
          :tasting="selectedTasting"
          :submitted-ciders="submittedCiders"
          :max-submission-count="submitCount(selectedTasting)"
          show-more-info-btn
          style="max-width: 500px;"
          class="pa-3"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import {sync} from "vuex-pathify";
import ProducerSubmitStepper from "@/components/producer/producer_submit_stepper";
import StepNavigation from "@/components/producer/step_navigation";
import FeedbackDialog from "@/components/feedback_dialog";
import {apiTyped} from "@/api";
import TastingCard from "@/components/producer/tasting_card";
import CiderCard from "@/components/cider_card";
import producer_submit_mixin from "@/components/mixins/producer_submit_mixin";
import {SubmitTastingStep} from "@/types/submitTastingStep";

export default {
  name: "ProducerSubmitCancel",
  components: {CiderCard, TastingCard, FeedbackDialog, StepNavigation, ProducerSubmitStepper},
  mixins: [producer_submit_mixin],
  data: () => ({
    loaded: false,
    showFeedback: true,
    ciders: null,
    submittedCiders: null,
  }),
  computed: {
    submitTastingSteps() {
      return SubmitTastingStep
    },
    selectedTasting: sync('submitStore/selectedTasting'),
    selectedCiders: sync('submitStore/selectedCiders'),
    confirmedCiderIds: sync('submitStore/confirmedCiderIds'),
    inPayingProcess: sync('submitStore/inPayingProcess'),
    producerData: sync('producerStore/producerData'),
  },
  async created() {
    if (this.selectedTasting == null
        || this.selectedCiders == null
        || this.selectedCiders.length === 0
        || !this.inPayingProcess) {
      await this.$router.push('/producer/submit/tastings');
    }
    this.inPayingProcess = false;
    this.submittedCiders = await apiTyped.getSubmittedCidersForTasting(this.selectedTasting.id);
    this.ciders = await apiTyped.producerGetAllCiders(
      this.$store.state.producerStore.producerId,
      0, 1, -1,
      {id: {_in: this.selectedCiders.map(c => c.id)}}
    );
    const tasting = await apiTyped.producerGetAvailableTastings(0, 1, 1, [this.selectedTasting.id]);
    if (tasting.length > 0 && tasting[0] != null) {
      this.selectedTasting = tasting[0];
    }
    this.loaded = true;
  },
}
</script>

<style scoped>

</style>