<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    rounded="xl"
    @keydown.esc="cancel"
  >
    <v-card
      rounded="xl"
      color="background"
    >
      <v-card-title class="white--text">
        {{ $t('selectCategory') }}
      </v-card-title>
      <v-container class="pa-1">
        <v-item-group
          v-model="selectedKategorie"
          multiple
        >
          <v-row>
            <v-col
              v-for="(category, i) in categories"
              :key="i"
              cols="12"
              md="6"
            >
              <v-item v-slot="{ active }">
                <v-hover
                  v-slot="{ hover }"
                >
                  <v-card
                    outlined
                    width="cardWidth"
                    class="ma-1"
                    :class="{'hovered': hover && active}"
                  >
                    <v-card-title>
                      {{ (category === null || !category) 
                        ? emptyText : category.translationMessages[currentLanguage].name }}
                    </v-card-title>
                    <v-card-text
                      v-if="category !== null && category"
                      class="text-left"
                      v-html="category.translationMessages[currentLanguage].info"
                    />
                  </v-card>
                </v-hover>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
        <v-card-actions class="pt-0">
          <v-btn
            v-if="options.thirdButton"
            color="error"
            text
            @click.native="third"
          >
            {{ options.thirdButtonText }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="grey"
            text
            @click.native="cancel"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click.native="agree"
          >
            {{ $t('okay') }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    selectedKategorie: null,
    categories: null,
    options: {
      color: 'primary',
      width: 450,
      zIndex: 200,
      thirdButton: false,
      thirdButtonText: "",
    }
  }),
  methods: {
    open(categories, options) {
      this.dialog = true
      this.categories = categories
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    },
  }
}
</script>

<style>
.v-dialog {
  border-radius: 24px !important;
}

</style>