import i18n from '@/i18n'
import axios from "axios";

// type Translations = { [key: string]: string | null };

function prefixObj(obj: any | null, prefix: string): any {
    if (obj === null) return null;
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => {
        return [`${prefix}${key}`, typeof value === 'object' ? prefixObj(value, prefix) : value];
    }));
}

export function createMultiLocaleMessages(translations: any, prefix: string) {
    if (translations === undefined) return {};
    return translations.flatMap((t: any) => {
        if (t !== undefined) {
            const {["lang"]: langKey, ...otherKeys} = t;
            const otherKeysPrefixed = prefixObj(otherKeys, prefix);
            return {[langKey]: otherKeysPrefixed}
        }
    });
}

export function mergeMultiLocaleMessages(translations: any[]) {
    // const langCodes = new Set(translations.flatMap(t => Object.keys(t)));
    const merged: any = {};
    for (const translation of translations) {
        if (translation == null) continue
        const langCode = Object.keys(translation)[0];
        merged[langCode] = {...merged[langCode], ...translation[langCode]};
    }
    return merged;
    // a.en = {...a.en, ...b.en}  // merge
}

// export function extendLocaleMessages(locale: string, messages: any) {
//     console.log("Reading ", `${locale}.json`)
//     let lang = fetch(`${locale}.json`).then(response => response.json())
//     lang = {...lang, ...messages}
//     console.log("Merged msg:", lang);
//     return lang;
// }


const loadedLanguages = ['en'] // our default language that is preloaded

function setI18nLanguage(lang: string) {
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html')?.setAttribute('lang', lang)
    return lang
}

export function extendLocaleMessages(lang: string, my_messages: any) {
    // // If the same language
    // if (i18n.locale === lang) {
    //     return Promise.resolve(setI18nLanguage(lang))
    // }
    //
    // // If the language was already loaded
    // if (loadedLanguages.includes(lang)) {
    //     return Promise.resolve(setI18nLanguage(lang))
    // }

    // If the language hasn't been loaded yet
    console.log(`@/locales/${lang}.json`)
    return import(/* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.json`).then(
        messages => {
            console.log("Setting new messages", {...messages.default, ...my_messages});
            i18n.setLocaleMessage(lang, {...messages.default, ...my_messages});
            loadedLanguages.push(lang);
            return setI18nLanguage(lang);
        }
    )
}