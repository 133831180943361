import Vue from 'vue';
import Vuex from 'vuex';
import {make} from 'vuex-pathify';
import {User} from "@/types/user";
import {Table} from "@/types/table";
import {Flight} from "@/types/flight";
import {CProducerCiderResults} from "@/types/producerCider";
import {Tasting} from "@/types/tasting";

Vue.use(Vuex);


type storeStates = typeof state;
// @ts-ignore
// type storeGetters = typeof getters;
// type rootStates = typeof store.state;
// type rootGetters = typeof rootGetter;

const state: {
    tasting: Tasting | null,
    allJudges: User[],
    selectedJudges: User[],
    allProducts: CProducerCiderResults[],
    selectedProducts: CProducerCiderResults[],
    tables: Table[],
    flights: Flight[],
} = {
    tasting: null,
    allJudges: [],
    selectedJudges: [],
    allProducts: [],
    selectedProducts: [],
    tables: [],
    flights: [],
}

// @ts-ignore
const getters = {
    ...make.getters(state),
}

const mutations = {
    ...make.mutations(state),
    // clear(state: storeStates) {
    // },
    // hydrate(state: storeStates, params: {  }) {
    // }
}

const actions = {
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}