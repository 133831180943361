<template>
  <v-overlay
    :class="{'wrapper': fullscreen}"
    color="background"
  >
    <v-progress-circular
      class="d-flex justify-center align-center"
      :class="{'overlay': fullscreen}"
      color="primary"
      style="position: relative;"
      size="75"
      width="7"
      indeterminate
    >
      <div class="d-flex flex-column">
        <v-img
          contain
          :src="customImage != null ? getImageUrl(customImage, 300) : getLogoUrl('logo_app', 300)"
          alt="CiderAwardLogo"
          class="mt-auto mb-auto overlay"
          style="margin: auto;"
          max-height="40px"
          max-width="40px"
        />
        <div
          class="d-flex flex-column"
          style="margin-top: 128px; min-width: 300px !important;"
        >
          <v-scroll-y-transition>
            <v-btn
              v-show="longLoading && !showMessages"
              color="text_std"
              text
              @click="showMessages = true"
            >
              Show infos
            </v-btn>
          </v-scroll-y-transition>
          <v-scroll-y-transition>
            <span
              v-show="showMessages"
            >
              {{ loadingMessage }}
            </span>
          </v-scroll-y-transition>
        </div>
      </div>
    </v-progress-circular>
  </v-overlay>
</template>

<script>
import logos_mixin from "@/components/mixins/logos_mixin";

export default {
  name: "LoadingCircle",
  mixins: [logos_mixin],
  props: {
    longLoading: {type: Boolean, required: false, default: false},
    loadingMessage: {type: String, required: false, default: "Loading..."},
    customImage: {type: String, required: false, default: null},
    fullscreen: {type: Boolean, required: false, default: true},
  },
  data: () => ({
    showMessages: false,
  }),
}
</script>

<style scoped>
.wrapper {
  z-index: 99;
  /*width: 100vw;*/
  /*height: 100vh;*/
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/
  backdrop-filter: blur(11px);
}

.overlay {
  z-index: 98;
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
}
</style>