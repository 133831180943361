import Vue from 'vue';
import Vuex from 'vuex';
import {make} from 'vuex-pathify';
import {ProducerTasting, Tasting} from "@/types/tasting";
import {CProducerCiderResults} from "@/types/producerCider";
import {Kategorie} from "@/types/kategorie";
import {Country} from "@/types/country";
import {TastingProduct} from "@/types/tastingProduct";
import {TastingPurchase} from "@/types/tastingPurchase";
import util from "@/util";
import {rootGetter, rootState} from "@/stores/index";

Vue.use(Vuex);


type storeStates = typeof state;
// @ts-ignore
type storeGetters = typeof getters;
type rootStates = typeof rootState;
type rootGetters = typeof rootGetter;

const state: {
    selectedTasting: ProducerTasting | null,
    selectedCiders: CProducerCiderResults[],
    submitProductsWithoutData: boolean,
    selectedProductAmount: number,
    selectedTastingProducts: TastingProduct[],
    tastingPurchaseId: number | null,
    tastingPurchase: TastingPurchase | null,
    confirmedCiderIds: number[],
    allCategories: Kategorie[] | null,
    allCountries: Country[],
    inPayingProcess: boolean,
    numberOfPaidProducts: number | null,
    includedAwards: number,  // todo only for award 24
} = {
    selectedTasting: null,
    selectedCiders: [],
    submitProductsWithoutData: false,
    selectedProductAmount: 1,
    selectedTastingProducts: [],
    tastingPurchaseId: null,
    tastingPurchase: null,
    confirmedCiderIds: [],
    allCategories: null,
    allCountries: [],
    inPayingProcess: false,
    numberOfPaidProducts: null,
    includedAwards: 0,
}

// @ts-ignore
const getters = {
    ...make.getters(state),
    awardExcluded(state: storeStates): boolean {
        return state.selectedTastingProducts.some(p => p.excludesAward) || false;
    },
    productsToSubmit(state: storeStates): CProducerCiderResults[] {
        const b = state.submitProductsWithoutData;
        if (state.submitProductsWithoutData === false) {
            return state.selectedCiders;
        }
        else {
            return [...Array(state.selectedProductAmount)].map((x, index) => {
                return {
                    id: index,
                    ciderInfos: {
                        id: index,
                        name: "Product " + (Number(index) + 1),
                    }
                } as unknown as CProducerCiderResults
            }) as CProducerCiderResults[]
        }
    },
    taxExemption(state: storeStates, getters: storeGetters, rootState: rootStates, rootGetter: rootGetters) {
        const HOME_COUNTRY_CODE = 'de';
        const country = state.allCountries.find(
            c => rootState.producerStore.producerData.country != null
                && c.id === rootState.producerStore.producerData.country.id);
        if (country == null) {  // none provided yet
            return 'none';
        }
        const isEUCustomer = country.reverseChargePossible;
        let taxExempt = "";
        console.log("isEUCustomer", country)
        if (isEUCustomer) {
            if (rootState.producerStore.producerData.vat && country.code !== HOME_COUNTRY_CODE) {
                // if it is a country in the EU and not from germany, reverse-charge applies
                taxExempt = 'reverse';
            } else {
                // b2c in EU or business from our home country, VAT applies normally
                taxExempt = 'none';
            }
        } else {
            // customer from outside the EU, no VAT applies
            // taxExempt = 'exempt';
            taxExempt = 'none';
        }
        return taxExempt;
    },
    subTotalPrice(state: storeStates, getters: storeGetters): number {
        if (state.selectedTasting == null) return -1;
        let subtotal = 0;
        subtotal += state.selectedTasting.tastingPrices.submissionFee;
        if (getters.productsToSubmit.length > 0) {
            subtotal += (getters.productsToSubmit.length - state.includedAwards)
                * state.selectedTasting.tastingPrices.pricePerSubmission;  // todo includes ciders (getters.productsToSubmit().length - state.includedAwards)
        }
        subtotal += state.selectedTastingProducts.reduce(
            (total, product) => total + (product.defaultPrice?.unitPrice || 0), 0);
        return subtotal;
    },
    taxesAmount(state: storeStates, getters: storeGetters): number {
        if (state.selectedTasting == null) return -1;
        const settings = state.selectedTasting.tastingPrices.priceIncludesTaxes;
        if (state.selectedTasting.tastingSettings != null && state.selectedTasting.tastingSettings.hasPurchasables) {
            const taxExempt = getters.taxExemption;
            const includingTaxes = !(taxExempt === 'exempt' || taxExempt === 'reverse');
            if (includingTaxes) {
                return util.util.round_to_decimal(
                    getters.subTotalPrice * state.selectedTasting.tastingPrices.taxes, 0.01);
            } else {
                return 0.00;
            }
        } else {
            return util.util.round_to_decimal(
                getters.subTotalPrice * state.selectedTasting.tastingPrices.taxes, 0.01);
        }
        // if (state.selectedTasting.tastingPrices.priceIncludesTaxes) {
        //     return util.util.round_to_decimal(
        //         getters.subTotalPrice * state.selectedTasting.tastingPrices.taxes, 0.01);
        // } else {
        //     return util.util.round_to_decimal(
        //         getters.subTotalPrice * state.selectedTasting.tastingPrices.taxes, 0.01);
        // }
    },
    totalPrice(state: storeStates, getters: storeGetters): number {
        if (state.selectedTasting == null) return -1;
        return util.util.round_to_decimal(getters.subTotalPrice + getters.taxesAmount, 0.01);
        // if (state.selectedTasting.tastingPrices.priceIncludesTaxes) {
        //     return util.util.round_to_decimal(getters.subTotalPrice, 0.01);
        // } else {
        //     return util.util.round_to_decimal(
        //         getters.subTotalPrice + getters.subTotalPrice * state.selectedTasting.tastingPrices.taxes,
        //         0.01);
        // }
    },
}

const mutations = {
    ...make.mutations(state),
    clear(state: storeStates) {
        state.selectedTasting = null;
        state.selectedCiders = [];
        state.submitProductsWithoutData = false;
        state.selectedProductAmount = 1;
        state.tastingPurchaseId = null;
        state.tastingPurchase = null;
        state.confirmedCiderIds = [];
        state.allCategories = null;
        state.allCountries = [];
        state.inPayingProcess = false;
        state.numberOfPaidProducts = null;
        state.selectedTastingProducts = [];
    },
    // TODO
    hydrate(state: storeStates,
            params: {
                tasting: Tasting,
                ciders: CProducerCiderResults[],
                tastingProducts: TastingProduct[],
                tastingPurchase: TastingPurchase | null,
                submitProductsWithoutData: boolean,
                selectedProductAmount: number,
            }
    ) {
        console.log("Setting tasting", params.tasting)
        state.selectedTasting = params.tasting as any;
        state.selectedCiders = params.ciders;
        state.submitProductsWithoutData = params.submitProductsWithoutData;
        state.selectedProductAmount = params.selectedProductAmount;
        state.tastingPurchaseId = params.tastingPurchase?.id || null;
        state.tastingPurchase = params.tastingPurchase;
        state.selectedTastingProducts = params.tastingProducts;
        state.confirmedCiderIds = params.ciders.map(c => c.id);
        state.selectedTastingProducts = [];
    }
}

const actions = {
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}