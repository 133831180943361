<template>
  <v-container
    fluid
    class="d-flex flex-column"
    style="max-width: 1800px;"
  >
    <v-progress-circular
      v-if="!loaded || error"
      indeterminate
      class="mt-auto mb-auto ml-auto mr-auto"
      size="92"
    />

    <v-row
      align-content="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="6"
        class="justify-end pl-md-3 pl-1 pr-md-3 pr-1"
      >
        <PageNavigation
          :title-translation="$t('productDetails')"
          :breadcrumbs="[{text: $t('home'), to: '/producer/home'},
                         {text: $t('productResults', {'productName': cider != null ? cider.ciderInfos.name : ''}), disabled: true, to: $router.currentRoute.fullPath}]"
          class="pl-md-3 pl-1 pr-md-3 pr-1 pb-4"
        />

        <cider-card
          v-if="cider != null"
          ref="ciderCardRef"
          :cider="cider"
          :full-content="true"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <producer-cider-results
          v-if="cider != null"
          :cider="cider"
          @selectCiderRevisionId="doSelectCiderRevision"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {apiTyped} from "@/api";
import ProducerCiderInfosCard from "@/components/producer/producer_cider_infos_card";
import ProducerCiderResults from "@/components/producer/producer_cider_results";
import PageNavigation from "@/components/page_navigation";
import CiderCard from "@/components/cider_card.vue";

export default {
  name: "ProducerCiderView",
  components: {CiderCard, PageNavigation, ProducerCiderResults},
  data: () => ({
    cider: null,
    loaded: false,
    error: false,
  }),
  async created() {
    this.cider = await apiTyped.producerGetCiderById(
      this.$store.state.producerStore.producerId, Number(this.$route.params.ciderId))
    if (this.cider == null) this.error = true;
    this.loaded = true;
  },
  methods: {
    doSelectCiderRevision(revId) {
      this.$refs.ciderCardRef.selectedRevisionId = revId;
    },
    async toDashboard() {
      await this.$router.push("/producer/home");
    },
  },
}
</script>

<style scoped>
.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 16px solid;
  border-color: inherit;
  opacity: 0.6;

  -webkit-transform:rotate(-45deg);
  -moz-transform:rotate(-45deg);
  -ms-transform:rotate(-45deg);
  -o-transform:rotate(-45deg);
  transform:rotate(-45deg);
}
</style>