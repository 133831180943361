<template>
  <v-navigation-drawer
    v-if="currentTasting != null"
    v-model="showNavigationDrawer"
    app
    style="min-width: 33vw; max-width: 50vw;"
    temporary
  >
    <div class="pa-2">
      <v-img
        contain
        :lazy-src="getImageUrl(currentTasting.logoMesse, 300)"
        :src="getImageUrl(currentTasting.logoMesse, 300)"
        alt="CiderAwardLogo"
        style="margin: auto;"
        max-width="100px"
      />
      <v-select
        v-if="allTastings !== null && allTastings !== undefined"
        v-model="currentTastingId"
        :outlined="$vuetify.theme.dark"
        class="d-flex align-center pt-4 pl-2 pr-2"
        :items="allTastings"
        style="max-width: 100vw;"
        :item-text="item => item.name + ' (' + getPrettyDate(item.datum) + ')'"
        item-value="id"
        label="Tasting auswählen"
        color="background"
        hide-details
      />
    </div>

    <div style="position: absolute; bottom: 0; width: 100%;">
      <div class="pa-2">
        <div class="pa-2">
          <span class="">
            {{ currentUser.firstName }} {{ currentUser.lastName }}
          </span>
          <v-divider class="pt-1 mt-1" />
          <span class="text--secondary">
            {{ currentUser.role }}
          </span>
        </div>
        <v-btn
          block
          color="error"
          @click="logout"
        >
          Logout
        </v-btn>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import {sync, get} from "vuex-pathify";
import logos_mixin from "@/components/mixins/logos_mixin";
import store from "@/stores";

export default {
  name: "ServiceNavigationDrawer",
  mixins: [logos_mixin],
  data: () => ({
    selectedTasting: null
  }),
  computed: {
    showNavigationDrawer: sync("serviceStore/showNavigationDrawer"),
    currentTasting: get('serviceStore/currentTasting'),
    currentTastingId: sync('serviceStore/currentTastingId'),
    allTastings: get("serviceStore/tastings"),
    currentUser: get("usersStore/me")
  },
  methods: {
    getPrettyDate(dateStr) {
      const date = new Date(Date.parse(dateStr));
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    },
    async logout() {
      localStorage.clear();
      await store.dispatch("usersStore/logout");
      window.localStorage.clear();
      // window.location.reload();
    },
  }

}
</script>

<style scoped>

</style>