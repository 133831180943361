<template>
  <v-select
    v-model="selectedModel"
    :items="countries"
    label="Select Countries"
    style="min-width: 200px;"
    single-line
    return-object
    filled
    multiple
    dense
    rounded
    hide-details
    item-text="name"
    item-value="code"
    class="ma-0 pa-0 elevation-0"
  >
    <template
      #item="{item}"
      class="ma-0"
    >
      <country-flag
        :country="(item.flag_code || item.flagCode) || item.code.split('-')[0]"
        size="normal"
      />
      {{ item.name }}
    </template>
    <template #selection="{item, index}">
      <div v-if="index === 0 || index === 1">
        <div
          v-if="index === 0"
          class="d-flex align-center pr-3"
        >
          <country-flag
            :country="(item.flag_code || item.flagCode) || item.code.split('-')[0]"
            size="normal"
          />
          {{ item.name }}
        </div>
        <span
          v-if="index === 1"
          class="grey--text text-caption"
        >
          (+{{ selectedModel.length - 1 }} others)
        </span>
      </div>
    </template>
  </v-select>
</template>

<script>
import CountryFlag from "vue-country-flag";

export default {
  name: "CountryMultiSelect",
  components: {
    CountryFlag
  },
  props: {
    value: {type: Array, required: true, default: () => []},
    countries: {type: Array, required: true, default: null},
  },
  data: () => ({
    selectedModel: [],
  }),
  watch: {
    selectedModel: function () {
      this.$emit('input', this.selectedModel)
    },
  },
  methods: {
  }
}
</script>

<style scoped>
/deep/ .v-select__selections > input {
  display: none !important;
}

/deep/ .v-select__selection {
  min-width: fit-content !important;
}
</style>