<template>
  <div>
    <v-card-title class="pb-5 text-break">
      {{ participationProduct.name }}
    </v-card-title>
    <v-card-subtitle class="pb-0">
      <span
        class="text--primary darken-2 font-weight-bold text-h5"
        v-html="participationProduct.priceDescription"
      />
      <span class="text--primary darken-2 font-weight-bold pl-1">€</span>
      {{ participationProduct.type === 'corporate' ? '/ Firma' : '' }}
    </v-card-subtitle>
    <v-card-text class="text--secondary py-0 pb-1">
      <template v-if="available != null">
        <strong>{{ available }}</strong> {{ $t('available') }}
      </template>
      <template v-else>
        &nbsp;
      </template>
    </v-card-text>
    <v-expand-transition>
      <div v-show="showDetails">
        <v-card-text class="py-1 d-flex align-baseline">
          <div
            class="text-subtitle-1 text-center"
            style="width: 24px;"
            :style="{'color': participationProduct.features.products > 0 ? $vuetify.theme.currentTheme.primary : $vuetify.theme.currentTheme.error}"
            v-text="participationProduct.features.products"
          />
          {{ $t('paymentHandling.includedProduct') }}
        </v-card-text>
        <v-card-text class="py-1 align-baseline d-flex flex-col">
          <v-icon
            small
            :color="participationProduct.features.preview ? 'primary' : 'error'"
            style="width: 24px;"
            v-text="participationProduct.features.preview ? 'mdi-check' : 'mdi-close'"
          />
          {{ $t('paymentHandling.awardPreview') }}
        </v-card-text>
        <v-card-text class="py-1 d-flex align-baseline">
          <div
            class="text-subtitle-1 text-center"
            style="width: 24px;"
            :style="{'color': participationProduct.features.award > 0 ? $vuetify.theme.currentTheme.primary : $vuetify.theme.currentTheme.error}"
            v-text="participationProduct.features.award"
          />
          {{ $t('paymentHandling.ceremonyParticipants') }}
        </v-card-text>
        <v-card-text class="py-1 d-flex align-baseline">
          <div
            class="text-subtitle-1 text-center"
            style="width: 24px;"
            :style="{'color': participationProduct.features.exkursion > 0 ? $vuetify.theme.currentTheme.primary : $vuetify.theme.currentTheme.error}"
            v-text="participationProduct.features.exkursion"
          />
          {{ $t('paymentHandling.tripParticipants') }}
        </v-card-text>
        <v-card-text class="py-1 d-flex align-baseline">
          <div
            class="text-subtitle-1 text-center"
            style="width: 24px;"
            :style="{'color': participationProduct.features.party > 0 ? $vuetify.theme.currentTheme.primary : $vuetify.theme.currentTheme.error}"
            v-text="participationProduct.features.party"
          />
          {{ $t('paymentHandling.partyParticipants') }}
        </v-card-text>
        <v-divider class="mx-4" />
        <v-card-text class="pt-3 pb-1 d-flex align-baseline">
          {{ $t('paymentHandling.expoInfo') }}
        </v-card-text>
        <v-card-text class="py-1 pl-6 d-flex align-baseline">
          {{ participationProduct.features.table }}
        </v-card-text>
<!--        <v-card-text class="py-1 d-flex align-baseline">-->
<!--          <div-->
<!--            class="text-subtitle-1 text-center"-->
<!--            style="width: 24px;"-->
<!--            :style="{'color': participationProduct.features.seminar > 0 ? $vuetify.theme.currentTheme.primary : $vuetify.theme.currentTheme.error}"-->
<!--            v-text="participationProduct.features.seminar"-->
<!--          />-->
<!--          {{ $t('paymentHandling.seminarParticipants') }}-->
<!--        </v-card-text>-->
        <v-card-text class="py-1 align-baseline d-flex flex-col">
          <v-icon
            small
            :color="participationProduct.features.marketing ? 'primary' : 'error'"
            style="width: 24px;"
            v-text="participationProduct.features.marketing ? 'mdi-check' : 'mdi-close'"
          />
          {{ $t('paymentHandling.marketingPackage') }}
        </v-card-text>
      </div>
    </v-expand-transition>
    <v-card-text
      :style="{'padding-bottom': participationProduct.type !== 'booth' ? '8px' : '16px'}"
      v-html="participationProduct.description"
    />
  </div>
</template>

<script>
export default {
  name: "ParticipationCard",
  props: {
    participationProduct: { type: Object, required: true, default: null },
    featureColored: { type: Boolean, required: true, default: true },
    showDetails: { type: Boolean, required: true, default: true },
    available: { type: Number, required: false, default: null }
  },
}
</script>

<style scoped>
</style>