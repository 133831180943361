<template>
  <v-banner
    v-model="$store.state.showCookieBanner"
    color="background"
    transition="slide-y-transition"
    outlined
    style="border-width: 2px !important; opacity: 0.8;"
    :style="{'border-color': $vuetify.theme.currentTheme.divider + ' !important'}"
    sticky
    rounded="xl"
    class="text-caption"
  >
    <div v-html="$t('cookieBannerText')" />
    <template #actions="{ dismiss }">
      <v-btn
        text
        color="primary"
        rounded
        style="font-size: 12px !important; max-height: 32px !important;"
        @click="dismiss"
      >
        I understand
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
export default {
  name: "CookieBanner",
  i18n: {
    messages: {
      de: {
        "cookieBannerText": `
    Diese Website verwendet Cookies ausschließlich, um den technischen Betrieb der Website zu gewährleisten.
    Abgesehen von der Verwaltung und Nutzung Ihrer Daten durch
    <a
      target="_blank"
      href="https://cider-world.de/impressum/"
    >
      Apfelwein International
    </a>
    und deren Partner findet kein Tracking oder eine Analyse Ihrer Aktivitäten auf dieser Seite statt.
    <br>
    Bitte lesen Sie die Datenschutzerklärung von
    <a
      target="_blank"
      href="https://cider-world.de/datenschutzerklaerung/"
    >
      Apfelwein International
    </a>
    und
    <a
      target="_blank"
      href="https://www.siderlandet.no/de/nordic-international-cider-awards/"
    >
      Siderlandet
    </a>`,
      },
      en: {
        "cookieBannerText": `
    This Website uses cookies solely to guarantee the technical operation of the website
    Apart from the administration and usage of your data by
    <a
      target="_blank"
      href="https://cider-world.de/impressum/"
    >
      Apfelwein International
    </a>
    and their partners, no tracking nor analysis of your activities on this site takes place.
    <br>
    Please review the privacy policy of
    <a
      target="_blank"
      href="https://cider-world.de/datenschutzerklaerung/"
    >
      Apfelwein International
    </a>
    and
    <a
      target="_blank"
      href="https://www.siderlandet.no/de/nordic-international-cider-awards/"
    >
      Siderlandet
    </a>`,
      }
    }
  }
}
</script>

<style scoped>
/deep/ .v-banner__actions {
  margin-left: 0 !important;
  padding-top: 4px !important;
}
</style>