import {dateFromString} from "@/util/helper";
import {MyCollections} from "@/api/types/my-collections";
import {getUserId, UserId} from "@/types/user";

interface Tischfunktion {
    id: number;
    userCreated: UserId | null;
    userUpdated: UserId | null;
    dateCreated: Date | null;
    dateUpdated: Date | null;
    userId: UserId;
    tableId: number;
    melden: number;
    frage: number;
    nextCiderId: number | null;
}

export function newTischfunktion() {
    return <Tischfunktion>{
        id: 0,
        userCreated: null,
        userUpdated: null,
        dateCreated: null,
        dateUpdated: null,
        userId: "",
        tableId: -1,
        melden: 0,
        frage: 0,
        nextCiderId: null,
    }
}

export function createTischfunktionFromDatabase(tischfunktion: MyCollections["tischfunktionen"]): Tischfunktion {
    const newTischfunktion = <Tischfunktion>{
        id: tischfunktion.id,
        userCreated: tischfunktion?.user_created !== null ? getUserId(tischfunktion?.user_created) : null,
        userUpdated: tischfunktion?.user_updated !== null ? getUserId(tischfunktion?.user_updated) : null,
        dateCreated: dateFromString(tischfunktion.date_created),
        dateUpdated: dateFromString(tischfunktion.date_updated),
        userId: tischfunktion?.user_id !== null ? getUserId(tischfunktion?.user_id) : "",
        tableId: tischfunktion.table_id,
        melden: tischfunktion.melden,
        frage: tischfunktion.frage,
        nextCiderId: tischfunktion.next_cider_id,
    }
    return newTischfunktion;
}

export {Tischfunktion}