<template>
  <v-app
    v-if="created"
  >
    <ProducerAppBar
      style="max-width: 100vw;"
    />
    <v-main
      style="min-height: calc(100vh - 64px)"
    >
      <!--      <NavigationDrawer />-->
      <v-container
        fluid
        class="pa-0 ma-0"
      >
        <router-view
          v-if="created"
          :key="reloadRouter"
        />
        <feedback-dialog
          v-model="$store.state.feedbackDialog"
          :title="$t('Feedback.titleApp')"
        />
      </v-container>
    </v-main>
    <ProducerFooter
      style="max-width: 100vw;"
    />
  </v-app>
</template>

<script>
import ProducerAppBar from "@/components/producer/producer_app_bar";
import {apiTyped} from "@/api";
import {RoleName} from "@/types/user";
import router from "@/router";
import ProducerFooter from "@/components/producer/producer_footer";
import NavigationDrawer from "@/components/producer/navigation_drawer";
import user_mixin from "@/components/mixins/user_mixin";
import store from "@/stores";
import {createBadgeFromDatabase} from "@/types/badge";
import FeedbackDialog from "@/components/feedback_dialog";
import login_mixin from "@/components/mixins/login_mixin";

export default {
  name: "LayoutProducer",
  components: {
    FeedbackDialog,
    ProducerFooter,
    ProducerAppBar
  },
  mixins: [user_mixin, login_mixin],
  data: () => ({
    created: false,
  }),
  computed: {
    reloadRouter() {
      return this.$store.state.reload_router;
    },
  },
  async created() {
    document.title = "Producer - Cider Service"
    // const user = await apiTyped.loginRefresh();
    const user = await this.refreshLogin(RoleName.Producer)
    // console.log("Layout - User", user)
    if (user == null) {
      await this.$router.push("/login");
      return;
    }
    this.$store.commit("usersStore/setUser", user);
    // if (this.$store.state.appStore.authenticated === false ||
    //   this.$store.state.usersStore.me?.role !== RoleName.Producer) {
    //   await this.$router.push("/login")
    // }
    this.setTheme();
    // get producer infos
    await this.$store.dispatch('producerStore/getProducerInfos');
    // get first 100 badges, request the others by badgesStore action getBadgeData
    this.$store.state.badgesStore.badgesData = (await apiTyped.sdk.items('medaillen').readByQuery({
      fields: ['*'],
      limit: 100,
    })).data.map(b => createBadgeFromDatabase(b));
    this.created = true;
    if (this.$store.state.producerStore.producerData.firstLogin === true) {
      await this.$router.push('/producer/edit/information');
    }
  },
}
</script>

<style scoped>

</style>