var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{ref:"islandContainer",staticStyle:{"position":"relative"}},[_c('v-row',_vm._l((_vm.islands),function(island,index){return _c('v-col',{key:island.componentName + '_' + index,staticClass:"pt-0 pb-0",attrs:{"cols":"12","xs":island.collapsedWidth.xs,"sm":island.collapsedWidth.sm,"md":island.collapsedWidth.md,"lg":island.collapsedWidth.lg,"xl":island.collapsedWidth.xl}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{ref:island.componentName + '_' + index,refInFor:true,staticClass:"open-island-transition exclude-click-outside",attrs:{"dark":"","color":hover ? 'filled_card darken-1' : 'filled_card',"rounded":"xl","elevation":"0"},on:{"click":function($event){return _vm.expandIsland(island.componentName + '_' + index)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.theme.themes.dark.filled_card)+" ")])]}}],null,true)})],1)}),1),_c('v-card',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
      handler: _vm.closeIslands,
      include: _vm.closeIslandsInclude
    }),expression:"{\n      handler: closeIslands,\n      include: closeIslandsInclude\n    }"}],ref:"allIslands",staticClass:"all-islands",class:{'expanded-islands': _vm.isExpanded, 'open-island-transition': _vm.isExpanded},style:({width: _vm.pressedIslandWidth + 'px',
             height: _vm.pressedIslandHeight + 'px',
             left: _vm.pressedIslandLeft + 'px',
             top: _vm.pressedIslandTop + 'px'})})],1)}
var staticRenderFns = []

export { render, staticRenderFns }