<!--uses locales-->
<template>
  <v-stepper
    v-model="active"
    alt-labels
    class="mt-12 ml-auto mr-auto mb-6"
    flat
    elevation="0"
    non-linear
    color="background"
    style="max-width: 1250px; min-height: 90px; background-color: #FFFFFF00; box-shadow: transparent; overflow: visible !important;"
  >
    <v-stepper-header
      style="justify-content: space-between"
      class="elevation-0"
    >
      <v-stepper-step
        :step="stepNumber(submitTastingSteps.selectTasting)"
        :editable="stepSelectTastingEditable"
        :complete="currentStep === submitTastingSteps.selectTasting
          || currentStep === submitTastingSteps.selectTastingProducts
          || currentStep === submitTastingSteps.selectCiders
          || currentStep === submitTastingSteps.confirmCiders
          || currentStep === submitTastingSteps.checkoutSummary
          || currentStep === submitTastingSteps.checkoutCompleted
        "
        edit-icon="mdi-check"
        @click="currentStep !== submitTastingSteps.selectTasting ? $router.push(`/producer/submit/tastings`) : ''"
      >
        {{ $t('paymentHandling.selectTasting') }}
        <small
          v-if="selectedTasting != null"
          class="pt-1"
          style="line-height: 1 !important;"
        >
          {{ selectedTasting.name }},
          <br>
          {{ new Date(selectedTasting.datum).toLocaleDateString() }}
        </small>
      </v-stepper-step>

      <v-divider :class="{small_divider: isSmallScreenCss, big_divider: !isSmallScreenCss}" />

      <v-stepper-step
        v-show="hasPurchasables"
        :step="stepNumber(submitTastingSteps.selectTastingProducts)"
        :editable="stepSelectTastingProductsEditable"
        :complete="currentStep === submitTastingSteps.selectTastingProducts
          || currentStep === submitTastingSteps.selectCiders
          || currentStep === submitTastingSteps.confirmCiders
          || currentStep === submitTastingSteps.checkoutSummary
          || currentStep === submitTastingSteps.checkoutCompleted
        "
        edit-icon="mdi-check"
        @click="stepSelectTastingProductsEditable ? $router.push(`/producer/submit/selectTastingProducts`) : ''"
      >
        {{ $t('paymentHandling.selectTastingProducts') }}
        <small
          class="pt-1"
        >
          {{ selectedTastingProducts.length }} {{ $t('paymentHandling.productsSelected') }}
        </small>
      </v-stepper-step>

      <v-divider
        v-show="hasPurchasables"
        :class="{small_divider: isSmallScreenCss, big_divider: !isSmallScreenCss}"
      />

      <v-expand-x-transition>
        <v-stepper-step
          v-show="!awardExcluded"
          :step="stepNumber(submitTastingSteps.selectCiders)"
          :editable="stepSelectCidersEditable"
          :complete="(currentStep === submitTastingSteps.selectCiders
            && (selectExactAmount === false && selectedCiders.length > 0 || selectExactAmount === true && selectedCiders.length === maxSelection))
            || currentStep === submitTastingSteps.confirmCiders
            || currentStep === submitTastingSteps.checkoutSummary
            || currentStep === submitTastingSteps.checkoutCompleted
          "
          edit-icon="mdi-check"
          @click="stepSelectCidersEditable ? $router.push(`/producer/submit/selectCiders`) : ''"
        >
          {{ $t('paymentHandling.selectProducts') }}
          <small
            v-if="maxCiders !== null && currentStep === submitTastingSteps.selectCiders"
            class="pt-1"
          >
            {{ $t('paymentHandling.selectUpTo01') }} {{ maxCiders }} {{ $t('paymentHandling.selectUpTo02') }}
          </small>
          <small
            v-if="selectedTasting != null && !selectedTasting.tastingSettings.allowProductDataUpdate && selectedCiders.length > 0"
            class="pt-1"
          >
            {{ selectedCiders.length }} {{ $t('paymentHandling.cidersSelected') }}
          </small>
          <small
            v-if="selectedTasting != null && selectedTasting.tastingSettings.allowProductDataUpdate && !selectExactAmount && submitProductsWithoutData"
            class="pt-1"
          >
            {{ selectedProductAmount }} {{ $t('paymentHandling.cidersSelected') }}
          </small>
          <small
            v-if="selectedTasting != null && selectedTasting.tastingSettings.allowProductDataUpdate && selectedCiders.length > 0 && !submitProductsWithoutData"
            class="pt-1"
          >
            {{ selectedCiders.length }} {{ maxSelection ? ' / ' + maxSelection : '' }} {{ $t('paymentHandling.cidersSelected') }}
          </small>
        </v-stepper-step>
      </v-expand-x-transition>
      <v-expand-x-transition>
        <v-divider
          v-show="!awardExcluded"
          :class="{small_divider: isSmallScreenCss, big_divider: !isSmallScreenCss}"
        />
      </v-expand-x-transition>


      <v-stepper-step
        v-if="!awardExcluded && submitProductsWithoutData === false"
        :step="stepNumber(submitTastingSteps.confirmCiders)"
        :editable="stepConfirmCidersEditable"
        :complete="currentStep === submitTastingSteps.confirmCiders
          || currentStep === submitTastingSteps.checkoutSummary
          || currentStep === submitTastingSteps.checkoutCompleted
        "
        edit-icon="mdi-check"
        @click="stepConfirmCidersEditable ? $router.push(`/producer/submit/confirmData`) : ''"
      >
        {{ $t('paymentHandling.confirmData') }}
        <small
          v-if="confirmedCiderIds.length > 0 && (currentStep !== submitTastingSteps.confirmCiders
            || confirmedCiderIds.length === selectedCiders.length)"
          class="pt-1"
        >
          {{ confirmedCiderIds.length === selectedCiders.length ? $t('all') : confirmedCiderIds.length + '/' + selectedCiders.length }}
          {{ $tc('product', 2) }} {{ $t('confirmed') }}
        </small>
        <small
          v-if="currentStep === submitTastingSteps.confirmCiders && confirmedCiderIds.length !== selectedCiders.length"
          class="pt-1"
        >
          {{ $t('paymentHandling.dataNeedsConfirmation') }}
        </small>
      </v-stepper-step>

      <v-divider
        v-if="!awardExcluded && submitProductsWithoutData === false"
        :class="{small_divider: isSmallScreenCss, big_divider: !isSmallScreenCss}"
      />

      <v-stepper-step
        :step="stepNumber(submitTastingSteps.checkoutSummary)"
        :editable="stepCheckoutSummaryEditable"
        :complete="currentStep === submitTastingSteps.checkoutCompleted"
        edit-icon="mdi-check"
        @click="stepCheckoutSummaryEditable ? $router.push(`/producer/submit/summary`) : ''"
      >
        {{ $t('paymentHandling.summary') }}
        <small
          v-if="currentStep === submitTastingSteps.checkoutSummary"
          class="pt-1"
        >
          {{ $t('paymentHandling.verifySubmission') }}

        </small>

        <small
          v-if="currentStep === submitTastingSteps.checkoutCompleted"
          class="pt-1"
        >
          {{ $t('paymentHandling.orderVerified') }}

        </small>
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
import {get, sync} from "vuex-pathify";
import {SubmitTastingStep} from "@/types/submitTastingStep";

export default {
  name: "ProducerSubmitStepper",
  props: {
    currentStep: { type: String, required: true, default: SubmitTastingStep.selectTasting },
    disableAll: { type: Boolean, required: false, default: false },
  },
  data: () => ({
    active: 1,
  }),
  computed: {
    awardExcluded() {
      return this.$store.getters["submitStore/awardExcluded"];
    },
    submitTastingSteps() {
      return SubmitTastingStep
    },
    totalSteps() {
      if (this.selectedTasting != null && this.selectedTasting.hasPurchasables) return 5;
      return 4;
    },
    selectedProductAmount: sync('submitStore/selectedProductAmount'),
    submitProductsWithoutData: sync('submitStore/submitProductsWithoutData'),
    selectedTasting: get('submitStore/selectedTasting'),
    tastingPurchase: get('submitStore/tastingPurchase'),
    selectedCiders: get('submitStore/selectedCiders'),
    selectedTastingProducts: sync('submitStore/selectedTastingProducts'),
    confirmedCiderIds: get('submitStore/confirmedCiderIds'),
    hasPurchasables() {
      return this.selectedTasting != null? this.selectedTasting?.tastingSettings.hasPurchasables || false : false;
    },
    selectExactAmount() {
      return (this.selectedTasting != null && this.selectedTasting.tastingSettings.allowProductDataUpdate
          && this.tastingPurchase != null
          && this.tastingPurchase.status === 'paid')
        || (this.selectedTasting != null && this.selectedTasting.tastingSettings.submissionNeedsPayment);
    },
    maxSelection() {
      if (this.selectedTasting.tastingSettings.submissionNeedsPayment) {
        return this.$store.state.submitStore.numberOfPaidProducts;
      }
      if (this.selectedTasting.tastingSettings.allowProductDataUpdate
        && this.tastingPurchase != null
        && this.tastingPurchase.status === 'paid'
      ) {
        return this.tastingPurchase.submittedProductIds.length;
      }
      return this.selectedTasting.tastingSettings.maxCiders;
    },
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        default: return false
      }
    },
    isSmallScreenCss () {
      return this.$vuetify.breakpoint.width <= 680;
    },
    maxCiders() {
      if (this.selectedTasting == null || this.selectedTasting.tastingSettings == null) return null
      else return this.selectedTasting.tastingSettings.maxCiders;
    },
    stepSelectTastingEditable() {
      return !this.disableAll && this.currentStep !== SubmitTastingStep.selectTasting;
    },
    stepSelectTastingProductsEditable() {
      return !this.disableAll && this.currentStep !== SubmitTastingStep.selectTastingProducts;
    },
    stepSelectCidersEditable() {
      return !this.disableAll && (
        ((this.currentStep === SubmitTastingStep.confirmCiders
            || this.currentStep === SubmitTastingStep.checkoutSummary
            || this.currentStep === SubmitTastingStep.checkoutCompleted
            || this.selectedCiders.length > 0)
        && this.selectedTasting != null && this.selectedTastingProducts.some(p => p.type === 'booth')
        && this.currentStep !== SubmitTastingStep.selectCiders)
      || (this.currentStep === SubmitTastingStep.selectTasting
          && this.selectedTasting != null
          && this.selectedTastingProducts.some(p => p.type === 'booth')));
    },
    stepConfirmCidersEditable() {
      if (this.selectExactAmount && this.selectedCiders.length !== this.maxSelection) {
        return false;
      }
      if (this.submitProductsWithoutData) return false;
      return !this.disableAll
        && (this.selectedCiders.length > 0
          && this.selectedTasting != null
          && this.currentStep !== SubmitTastingStep.confirmCiders)
        && this.selectedTastingProducts.some(p => p.type === 'booth');
    },
    stepCheckoutSummaryEditable() {
      if (this.selectExactAmount && this.selectedCiders.length !== this.maxSelection) {
        return false;
      }
      return !this.disableAll && (
          ((this.submitProductsWithoutData === false && this.selectedCiders.length === this.confirmedCiderIds.length) || this.submitProductsWithoutData)
          && (this.submitProductsWithoutData === false && this.selectedCiders.length > 0 || this.submitProductsWithoutData)
          && this.selectedTasting != null
          && this.currentStep !== SubmitTastingStep.checkoutSummary)
        && this.selectedTastingProducts.some(p => p.type === 'booth');
    },
  },
  mounted() {
    this.active = this.currentStep;
  },
  methods: {
    stepNumber(stepString) {
      if (stepString === SubmitTastingStep.selectTasting) {
        return 1;
      }
      if (stepString === SubmitTastingStep.selectTastingProducts) {
        return 2;
      }
      if (stepString === SubmitTastingStep.selectCiders) {
        return 3 - (this.hasPurchasables ? 0 : 1);
      }
      if (stepString === SubmitTastingStep.confirmCiders) {
        return 4 - (this.hasPurchasables ? 0 : 1);
      }
      if (stepString === SubmitTastingStep.checkoutSummary) {
        if (this.awardExcluded) {
          return 3;
        }
        return 5 - (this.hasPurchasables ? 0 : 1) - (this.submitProductsWithoutData ? 1 : 0);
      }
      if (stepString === SubmitTastingStep.checkoutCompleted) {
        if (this.awardExcluded) {
          return 4;
        }
        return 6 - (this.hasPurchasables ? 0 : 1);
      }
      return -1;
    }
  }
}
</script>

<style>

@media only screen and (max-width: 680px) {
  div.v-stepper__step--active > div.v-stepper__label {
    display: flex !important;
    text-align: center !important;
    overflow: visible !important;
  }
  div.v-stepper__step--active {
    overflow: visible !important;
  }
}

@media only screen and (max-width: 959px) and (min-width: 680px) {
  div.v-stepper__label {
    display: flex !important;
    text-align: center !important;
  }
}

div.v-stepper__step--editable {
  padding: 4px !important;
}
div.v-stepper__step--editable:hover {
  border-radius: 20px !important;
  background-size: 50% 50%;
}

div.v-stepper__label {
  text-align: center !important;
  align-items: center;
}

div.v-stepper__label > small {
  text-align: center;
  align-items: center;
  padding-bottom: 2px;
}

div.v-stepper__step {
  flex-basis: 240px !important;
  padding: 4px !important;
}

.small_divider {
  max-width: 16px !important;
  margin-top: 16px !important;
}

.big_divider {
  transform: translateY(-20px);
}

@media only screen and (max-width: 680px) {
  div.v-stepper__step {
    max-width: 120px;
  }
}
</style>