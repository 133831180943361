<template>
  <v-app
    style="margin-top: 0 !important; height: 100vh !important;"
  >
    <v-main>
      <v-container
        fluid
        style="height: 100vh !important;"
        class="pa-0"
      >
        <v-expand-transition>
          <v-banner
            v-if="false"
            sticky
            color="background lighten-1"
            class="d-flex align-center justify-center pt-0 pb-0"
          >
            <div
              class="no-p-padding-b text-left text-body-1"
              v-html="$t('maintenanceMessage')"
            />
          </v-banner>
        </v-expand-transition>
        <router-view
          v-if="!loading"
          :key="reloadRouter"
          :loaded="!loading"
          :logging-in="loggingIn"
          :requires-otp="requiresOtp"
          :error-msg="$i18n.t(errorMsg)"
          @login="login"
        />
        <v-overlay
          v-if="loading"
          class="wrapper"
          color="background"
        >
          <v-progress-circular
            class="d-flex justify-center align-center overlay"
            color="primary"
            style="position: relative;"
            size="75"
            width="7"
            indeterminate
          />
        </v-overlay>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {apiTyped} from "@/api";
import {RoleName, Theme} from "@/types/user";
import user_mixin from "@/components/mixins/user_mixin";
import {allRoutes} from "@/router";
import {ErrorType} from "@/api/apiBase";

export default {
  name: "LayoutLogin",
  mixins: [user_mixin],
  data() {
    return {
      loading: true,
      loggingIn: false,
      requiresOtp: false,
      errorMsg: null,
      loginPageMessages: null,
      messageInfoClosed: true,
      messageMaintenanceClosed: true,
    };
  },
  i18n: {
    messages: {
      de: {
        'noFrontendAccess': 'Benutzerkonto und Rolle hat keinen Zugriff auf das Frontend.',
        'wrongCredentials': 'Falsche E-Mail oder Passwort.',
        'maintenanceTitle': 'Geplante Wartungsarbeiten',
        'maintenanceMessage': 'Wir führen planmäßige Wartungsarbeiten an unserer Website durch, um deren Leistung zu verbessern und eine optimale Benutzererfahrung zu gewährleisten.<br>Am 12. April 2023, 20 bis 23 Uhr (GMT+2) ist die Website vorübergehend nicht verfügbar.',
      },
      en: {
        'noFrontendAccess': 'User Role has no access permission to frontend.',
        'wrongCredentials': 'Wrong E-Mail or Password.',
        'maintenanceTitle': 'Scheduled Maintenance Downtime',
        'maintenanceMessage': 'We will be performing scheduled maintenance on our website to improve its performance and ensure optimal user experience.<br>During 12. April 2023, 8 to 11 p.m. (GMT+2) the website will be temporarily unavailable.',
      }
    }
  },
  computed: {
    reloadRouter() {
      return this.$store.state.reload_router;
    },
    hasLoginPageMessages() {
      return this.loginPageMessages != null
        && (this.loginPageMessages.infosEnable || this.loginPageMessages.maintenanceEnable);
    }
  },
  async created() {
    document.title = "Login - Cider Service";
    try {
      this.$store.state.languages = await apiTyped.getLanguages();
      this.$store.state.logo_ids = await apiTyped.getLogoIds();
      // this.loginPageMessages = await apiTyped.getLoginPageMessages();
      console.log("loginPageMessages", this.loginPageMessages)
    } catch (e) {
      console.log("Error login layout", e);
      // await this.$store.dispatch("usersStore/logout", null);
      // location.reload();
    }
    this.loading = false;
  },
  methods: {
    async loginApi(username, password, refresh = false, otp = null) {
      let user;
      if (refresh) {
        user = await apiTyped.loginRefresh();
      } else {
        user = await apiTyped.login(username, password, otp);
        console.log("Got user response", user)
        if (user === ErrorType.otp) {
          console.log("Got user response", user)
          this.requiresOtp = true;
          this.loggingIn = false;
          return {user: null, errorMsg: "One time password required"};
        }
        // console.log("User login", user)
      }
      let errorMsg = null;
      if (user !== undefined && !Object.values(RoleName).includes(user.role)) {
        this.loggingIn = false;
        this.loading = false;
        this.errorMsg = 'noFrontendAccess';
      }
      if (user === undefined) {
        errorMsg = "wrongCredentials";
      }
      return {user: user, errorMsg: errorMsg};
    },
    async loginJuror() {
      this.$store.state.app_bar_loading = false;
      await this.$router.push(allRoutes.tastings.my);
    },
    async adminLogin() {
      await this.$router.push('/admin/home');
    },
    async loginProducer() {
      await this.$router.push('/producer/home');
    },
    async loginService() {
      await this.$router.push('/service/table_overview');
    },
    async loginLogic(user) {
      if (user === undefined) return;
      this.loginFailed = false;
      this.$store.state.appStore.authenticated = true;
      this.$store.commit("usersStore/setUser", user);
      if (this.$store.state.changedTheme) {  // Set Theme if changed before login
        await apiTyped.updateTheme(this.$store.state.newTheme);
      }
      let isDark = this.$store.getters["usersStore/user"]().theme === Theme.Dark
      if (this.$store.getters["usersStore/user"]().theme === Theme.Auto) {
        isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      }
      if (!isDark) this.$vuetify.theme.dark = false;
      if (isDark) this.$vuetify.theme.dark = true;
      if (user.role === RoleName.Admin) {
        await this.adminLogin();
      }
      if (user.role === RoleName.Juror || user.role === RoleName.Chair) {
        await this.loginJuror();
      }
      if (user.role === RoleName.Producer) {
        await this.loginProducer();
      }
      if (user.role === RoleName.Service) {
        await this.loginService();
      }
    },
    async login({username, password, otp}) {
      this.loggingIn = true;
      this.errorMsg = null;
      console.log("GOT OTP ", otp)
      const {user, errorMsg} = await this.loginApi(username, password, false, otp);
      if (errorMsg != null) {
        this.errorMsg = errorMsg;
      } else {
        await this.loginLogic(user)
      }
      this.loggingIn = false;
    },
  }
}
</script>

<style scoped>
.wrapper {
  z-index: 99;
  backdrop-filter: blur(11px);
}
.overlay {
  z-index: 98;
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
}

.v-main__wrap {
  height: 100vh !important;
  margin: 0 !important;
}

/deep/ .no-p-padding-b > p {
    margin-bottom: 0 !important;
}
</style>