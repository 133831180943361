<template>
  <v-container
    id="allCider"
    ref="multiPane"
    fluid
    class="d-flex justify-start "
    style="height: calc(100vh - 128px); overflow-y: scroll; overflow-x: hidden;"
  >
    <template v-if="!isSmallScreen">
      <Multipane
        class="vertical-panes align-start"
        layout="vertical"
        @paneResize="panesResized"
      >
        <CiderTable
          v-if="allCiders != null && allCiders.length > 0"
          ref="ciderTable"
          :all-ciders="allCiders"
          style="overflow: auto;"
          :style="{'width': hideRightSide ? '100%' : '66vw', 'max-width': hideRightSide ? '100vw' : '85vw'}"
          @selectCider="selectCider"
          @toggleIndividual="showIndividual = !showIndividual"
          @printCiders="printCiders"
          @toggleRightSide="hideRightSide = !hideRightSide"
        />
        <loading-component-spinner
          v-else
          style="min-width: 66vw;"
          loading-message="Loading Ciders..."
        />
        <MultipaneResizer
          v-show="!hideRightSide"
          class="mr-4 ml-0"
        />
        <ReportTabView
          v-if="selectedCider !== null && !hideRightSide"
          style="overflow-y: scroll !important;"
          :cider="selectedCider"
          :best-of-category="bestOfCategoryCiders.some(c => c.id === selectedCider.id)"
          :tasting="tasting"
          :show-individual="showIndividual"
          :size-px="pageScale"
          @pageSize="setPageSize"
          @reportChange="setCurrentReport"
        />
      </Multipane>
      <PrintView ref="PrintView" />
    </template>
    <template v-else>
      <div style="min-width: 100%; ">
        <CiderTable
          v-if="allCiders.length > 0"
          ref="ciderTable"
          :all-ciders="allCiders"
          style="min-height: 80vw !important; overflow: hidden;"
          @selectCider="selectCider"
          @toggleIndividual="showIndividual = !showIndividual"
          @printCiders="printCiders"
          @toggleRightSide="hideRightSide = !hideRightSide"
        />
        <ReportTabView
          v-if="selectedCider !== null && !hideRightSide"
          :cider="selectedCider"
          style="width: 100%"
          :best-of-category="bestOfCategoryCiders.some(c => c.id === selectedCider.id)"
          :tasting="tasting"
          :show-individual="showIndividual"
          :size-px="pageScale"
          @reportChange="setCurrentReport"
        />
      </div>
    </template>
    <v-snackbar
      v-model="showTooLargeError"
      rounded="xl"
      color="error"
    >
      <strong>
        Data is too large, failed to store at index {{ lastTooLargeIndex }}
      </strong>
      <template #action="{ attrs }">
        <v-btn
          text
          rounded
          v-bind="attrs"
          @click="showTooLargeError = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import {apiTyped} from "@/api";
import {sync} from "vuex-pathify";
import CiderTable from "@/components/admin/gesamtauswertung/cider_table";
import PrintView from "@/components/admin/print";
import ReportTabView from "@/components/admin/report_tab_view";
import cider_mixin from "@/components/mixins/cider_mixin";
import LoadingComponentSpinner from "@/components/loading_component_spinner";
import {addUrkundenToBadges, Format} from "@/types/badge";
// import GesamtauswertungPage from "@/components/admin/gesamtauswertung/gesamtauswertung_page";

export default {
  name: "AllCider",
  components: {
    LoadingComponentSpinner,
    // GesamtauswertungPage,
    ReportTabView,
    PrintView,
    CiderTable,
    Multipane,
    MultipaneResizer
  },
  mixins: [cider_mixin],
  data: () => ({
    showTooLargeError: false,
    lastTooLargeIndex: -1,
    hideRightSide: false,
    tabNr: 0,
    allCiders: [],
    allChairNotes: [],
    allBewertungen: [],
    allUserNotes: [],
    selectedCider: null,
    cidersByTable: null,
    showIndividual: true,
    pageScale: 1,
    pageSizeCm: 21,
    kategorien: [],
    currentReportRoute: '/admin/print/gesamtbewertung_a4',
  }),
  computed: {
    tables: sync("tableStore/tables"),
    tastings: sync("usersStore/tastings"),
    // currentTastingIdx() {
    //   return this.$store.getters["usersStore/getTastingIdxByTastingId"](Number(this.$route.query.tasting));
    // },
    // tasting: sync('usersStore/tastings@[:currentTastingIdx]'),
    tasting() {
      return this.$store.getters["usersStore/currentTasting"]();
    },
    badgesData: sync("badgesStore/badgesData"),
    allCidersPrepped() {
      return this.allCiders.map((cider, index) => {
        cider.uniqueId = index;
        cider.totalPoints = this.calculateTotalPointsForCider(cider, true, false, 0.1);
        cider.badgeData = this.$store.getters['badgesStore/getBadgeData'](cider.totalPoints);
        cider.stateIcon = this.getCiderStateIcon(cider);
        cider.stateText = this.getCiderStateText(cider);
        if (cider.land === undefined || cider.land === null) cider.land = {name: "--", code: "--"};
        return cider;
      });
    },
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return false
        default: return false
      }
    },
    bestOfCategoryCiders() {
      return this.$store.getters["ciderStore/getBestOfCategory"](this.allCidersPrepped);
    },
  },
  watch: {
    // Watcher for mounted and v-if condition on ref ciderTable
    allCiders: function (newValues) {
      if (newValues.length > 0) {
        this.$nextTick(() => { if (this.$refs.ciderTable !== undefined) this.panesResized(this.$refs.ciderTable.$el); });
      }
    },
    // tableWidth: function () {
    //   this.$nextTick(() => {
    //     this.panesResized(this.$refs.ciderTable.$el);
    //   });
    // },
  },
  async created() {
    // const resp = await apiTyped.getCidersPreparedByTable(this.tables, this.kategorien);
    // if (resp === undefined) return;
    // const [allCiders, allChairNotes, allBewertungen, allUserNotes] = resp;
    // console.log("Responses:", allCiders, allChairNotes, allBewertungen, allUserNotes)
    // // Add tables to ciders:
    // allCiders.map((ciders, tableIndex) => ciders.map(cider => cider.table = this.tables[tableIndex]))
    // this.allCiders = allCiders.flat();
    // this.allChairNotes = allChairNotes;
    // this.allBewertungen = allBewertungen;
    // this.allUserNotes = allUserNotes;
    this.kategorien = await apiTyped.getAllKategorien(this.tasting.id);
    const ciderIdsByTable = this.tables.map(table => table.flights.flatMap(f => f.ciders.flatMap(c => c)));
    const flights = this.tables.flatMap(t => t.flights);
    console.log("glglgl", flights)
    const results = [];
    for (const ciderIds of ciderIdsByTable) {
      console.log("GETTING CIDERs", ciderIds)
      const res = await apiTyped.serviceGetCidersClient(ciderIds, this.kategorien)
      res.forEach(c => {
        const flight = flights.find(f => f.ciders.includes(c.flightsCidersId));
        console.log("flightflight", flight)
        if (flight != null) {
          c.flightId = flight.id;
          c.flightName = flight.flightName;
        }
      })
      results.push(res);
    }
    console.log("CON GDONE", results)
    this.cidersByTable = results;
    // const cidersPromises = ciderIdsByTable.map(ciderIds => apiTyped.serviceGetCidersClient(ciderIds, this.kategorien));
    // this.cidersByTable = await Promise.all(cidersPromises);
    // set table for cider
    for (const [index, table] of this.tables.entries()) {
      this.cidersByTable[index].forEach(c => c.table = table);
    }
    if (this.badgesData.length > 0) {
      this.badgesData = await addUrkundenToBadges(this.badgesData, null, [Format.A4]);
    }
    this.allCiders = this.cidersByTable.flat();
    this.allCiders.forEach(c => c.kategorie = this.kategorien.find(k => Number(k.id) === Number(c.kategorieId)));
    this.selectedCider = this.allCiders[0];
    window.addEventListener("resize", this.resizedWindow);
    if (this.$refs.multiPane != null) {
      this.pageSizeCm = this.$refs.multiPane.clientWidth * 0.026458 - this.pageSizeCm;
    }
    this.allCiders.forEach(c => {
      c.companyName =  c.produzent;
    })
    this.badgesData = await addUrkundenToBadges(this.badgesData);

    // TODO remove when extension fetched produzenten data
    // const producerData = await apiTyped.sdk.items("ciders").readByQuery( {
    //   fields: ["*", "produzent.*.*"],
    //   filter: {
    //     id: { _in: this.allCiders.map(c => c.id) },
    //   },
    //   limit: -1,
    // });
    // console.log("GOT producer", producerData)
    // this.allCiders.forEach(c => {
    //   const p = producerData.data?.find(ciderProducer => Number(c.id) === Number(ciderProducer.id))
    //   console.log("GOT ppp", p?.produzent?.produzenten_data?.company_name)
    //   c.companyName =  p?.produzent?.produzenten_data?.company_name;
    // })
  },
  mounted() {
    if (this.$refs.multiPane != null) {
      this.pageSizeCm = this.$refs.multiPane.clientWidth * 0.026458 - this.pageSizeCm;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizedWindow);
  },
  updated() {
    this.resizedWindow();
  },
  methods: {
    uuidV4() {
      let d = new Date().getTime();  // Timestamp
      // Time in microseconds since page-load or 0 if unsupported
      let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
        } else {  // Use microseconds since page-load if supported
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    },
    browserSize() {
      console.log("WWWW", window.innerWidth)
      return window.innerWidth - 64;
      // try {
      //   return this.$refs.multiPane.$el.clientWidth;
      // } catch (e) {
      //   console.log("ERRR", e)
      //   return null;
      // }
    },
    setCurrentReport(tabNr) {
      console.log("Setting current tab:", tabNr)
      this.tabNr = tabNr;
      switch (tabNr) {
        case 0:
          console.log("Setting current tab 1")
          this.currentReportRoute = '/admin/print/gesamtbewertung_a4';
          break;
        case 1:
          console.log("Setting current tab 2")
          this.currentReportRoute = '/admin/print/urkunde_a4';
          break;
        case 2:
          console.log("Setting current tab 3")
          this.currentReportRoute = '/admin/print/urkunde_a3';
          break;
        case 3:
          console.log("Setting current tab 4")
          this.currentReportRoute = '/admin/print/manual-rating';
          break;
        default:
          this.currentReportRoute = '/admin/print/gesamtbewertung_a4';
      }
    },
    setPageSize(pageSizeCm) {
      this.pageSizeCm = (this.$refs.multiPane.clientWidth + 32) * 0.026458 - pageSizeCm;
    },
    resizedWindow() {
      // this.pageSizeCm = this.$refs.multiPane.clientWidth * 0.026458 - this.pageSizeCm;
      this.$nextTick(() => {
        if (this.$refs.ciderTable !== undefined) this.panesResized(this.$refs.ciderTable.$el);
      });
    },
    panesResized(pane) {
      if (this.isSmallScreen) this.pageScale = this.$refs.multiPane.clientWidth;
      else this.pageScale = (this.$refs.multiPane.clientWidth - pane.clientWidth);
    },
    selectCider(idx) {
      this.selectedCider = this.allCiders[idx];
    },
    async printCiders(flightsCidersIds) {
      // if (this.tabNr === 1) {  // TODO remove
      //   const uuid = this.uuidV4();
      //   await apiTyped.uploadPrintData(uuid, {
      //     resultCider: this.allCiders,
      //     badge: this.selectedCider.badgeData,
      //     tasting: this.tasting,
      //   });
      //   const url = `${apiTyped.frontendUrl}producer/print/cider/certificateA4?uuid=${uuid}`;
      //   // const url = `http://localhost:8080/producer/print/cider/certificateA4?uuid=${uuid}`;
      //   window.open(url, '_blank');
      //   return;
      // }

      console.log("IDXXX", flightsCidersIds)
      this.$store.set("ciderStore/printCiders", Array(flightsCidersIds.length).fill(null));
      let index = 0;
      for (const flightsCidersId of flightsCidersIds) {
        this.allCiders[index].table.ciders = undefined;
        try {
          const cider = this.allCiders.find(c => Number(c.flightsCidersId) === Number(flightsCidersId));
          this.$store.commit("ciderStore/setPrintCider", {index: index, cider: cider});
          index += 1;
        } catch (e) {
          console.log("e.name", e.name);
          if (e.name === "DOMException" || e.name === "QuotaExceededError") {
            this.lastTooLargeIndex = index;
            this.showTooLargeError = true;
            this.$store.set("ciderStore/printCiders", Array(flightsCidersIds.length).fill(null));
          }
          return;
        }
      }
      let route = this.$router.resolve({path: this.currentReportRoute});
      window.open(route.href, '_blank');
      // const element = document.getElementById('page');
      // console.log("PRINT", this.$refs.pagePDF.$el.innerHTML)
      // this.$refs.PrintView.setBodyHtml(this.$refs.pagePDF.$el.innerHTML);
      // // d.print(element, ["./app.5e1b0197.css", "./chunk-vendors.c7bdb07e.css"])
    },

  }
}
</script>

<style scoped>
.vertical-panes > .pane ~ .pane {
  border-left: 1px solid #ccc;
}
.custom-resizer {
  width: 100%;
  height: 1000px;
}
.custom-resizer > .pane {
  text-align: left;
  padding: 15px;
  overflow: hidden;
  background: #eee;
  border: 1px solid #ccc;
}
.custom-resizer > .pane ~ .pane {
}
.custom-resizer > .multipane-resizer {
  margin: 0;
  left: 0;
  position: relative;
}
.multipane-resizer:before {
  display: block;
  content: "";
  width: 3px;
  height: 100%;
  border-left: 6px solid #ccc;
  border-radius: 3px;
}

.multipane-resizer:hover:before {
  border-color: #999;
}
</style>