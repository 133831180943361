<template>
  <v-footer
    padless
    bottom
    inset
    color="background"
  >

    <v-divider class="d-flex mt-1 mb-0 mx-3" />
    <v-card-text
      class="d-flex align-center justify-center pb-0"
    >
      <span>{{ new Date().getFullYear() }}</span>
      <v-icon
        small
        class="mr-0 ml-0"
      >
        mdi-circle-small
      </v-icon>
      <span class="font-weight-bold">Cider World Service</span>
      <v-img
        contain
        :src="getLogoUrl('logo_app', 300)"
        alt="CiderAwardLogo"
        class="ml-2"
        max-height="32px"
        max-width="40px"
      />
    </v-card-text>
    <footer-impressum-privacy />
  </v-footer>
</template>

<script>
import logos_mixin from "@/components/mixins/logos_mixin";
// import i18n from '@/i18n'
import FooterImpressumPrivacy from "@/components/footer_impressum_privacy";

export default {
  name: "ProducerFooter",
  components: {FooterImpressumPrivacy},
  mixins: [logos_mixin],
}
</script>

<style scoped>

</style>