import Vue from 'vue';
import Vuex from 'vuex';
import {make} from 'vuex-pathify';
import {apiTyped} from "@/api";
import i18n from '@/i18n'
// import store, {rootGetter} from "@/stores";

Vue.use(Vuex);


type storeStates = typeof state;
// @ts-ignore
// type storeGetters = typeof getters;
// type rootStates = typeof store.state;
// type rootGetters = typeof rootGetter;

const state = {
    localeMessages: {},
}

const mutations = {
    ...make.mutations(state),
}

// @ts-ignore
const getters = {
    ...make.getters(state),
}

const actions = {
    setLocaleMessages(context: any, newLocaleMessages: Record<string, Record<string, string>>) {
        const currentLangCode = context.rootGetters["usersStore/getUserLanguageCode"]();
        console.log("Setting translations:", newLocaleMessages, currentLangCode, context.state.localeMessages);
        for (const locale in newLocaleMessages) {
            context.state.localeMessages[locale] = {
                ...(context.state.localeMessages.hasOwnProperty(locale) ? context.state.localeMessages[locale] : {}),
                ...i18n.messages[locale],
                ...(newLocaleMessages.hasOwnProperty(locale) ? newLocaleMessages[locale] : {}),
            };
            i18n.setLocaleMessage(locale, context.state.localeMessages[locale]);
        }
    },
}

export default {
    namespaced: true, // add this if in module
    state,
    getters,
    mutations,
    actions,
}