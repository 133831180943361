<template>
    <v-container
    fluid
    class="ma-0 pa-1 pb-4"
    style="overflow-y: hidden; overflow-x: hidden; width: 100%;"
  >
    <v-toolbar
      min-width="100"
      color="secondary_card"
      flat
      class="rounded-t-xl pb-0"
      :dark="!$vuetify.theme.dark"
      style="width: 100%"
    >
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suchen"
        style="max-width: 500px;"
        single-line
        hide-details
        clearable
      />

      <div class="d-flex align-center justify-end ml-auto">
        <v-btn
          icon
          @click="printCiders"
        >
          <v-icon>
            mdi-printer
          </v-icon>
        </v-btn>
        <v-btn
          icon
          @click="toggleRightSide"
        >
          <v-icon>
            {{ showRightSide ? 'mdi-tablet' : 'mdi-dock-right' }}
          </v-icon>
        </v-btn>

        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="allCidersCsv"
            >
              <v-list-item-icon class="mr-2">
                <v-icon v-text="'mdi-download'" />
              </v-list-item-icon>
              <v-list-item-title>
                Download .csv
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="openUploadDocumentsModal"
            >
              <v-list-item-icon class="mr-2">
                <v-icon v-text="'mdi-file-upload'" />
              </v-list-item-icon>
              <v-list-item-title>
                Upload producer documents
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-toolbar>
    <v-card
      color="secondary_card"
      class="rounded-0 pl-2 pr-2 pb-2"
      flat
      :dark="!$vuetify.theme.dark"
      style="width: 100%"
    >
      <div
        class="d-flex justify-start align-stretch"
        style="max-height: 48px !important; overflow:hidden;"
      >
        <v-select
          v-if="!isSmallScreen"
          v-model="hiddenHeaders"
          class="d-flex"
          :items="allHeaders"
          label="Hide Columns"
          multiple
          rounded
          dense
          return-object
          solo
          flat
          hide-details
          clearable
          color="divider"
          style="max-width: 250px; background-color: var(--v-divider-base) !important;"
          :light="!$vuetify.theme.dark"
        >
          <template #selection="{ index }">
            <v-chip
              v-if="index === hiddenHeaders.length - 1"
            >
              <span>{{ hiddenHeaders.length }} Columns hidden</span>
            </v-chip>
            <!--            <span-->
            <!--              v-if="index === hiddenHeaders.length - 1"-->
            <!--              class="grey&#45;&#45;text caption"-->
            <!--            >({{ hiddenHeaders.length }} Spalten ausgeblendet)</span>-->
          </template>
        </v-select>
        <v-chip
          filter
          :color="showBestOfCategory ? 'primary' : 'white'"
          :style="{color: showBestOfCategory ? 'var(--v-text_std_inv-base)' : 'var(--v-text_std-base)'}"
          class="ml-2 d-flex"
          style="height: 38px; border-radius: 99px;"
          :input-value="showBestOfCategory"
          @click="toggleBestOfCategory"
        >
          Best of Category
          <!--          <v-icon>-->
          <!--            {{ showBestOfCategory ? 'mdi-podium-gold' : 'mdi-podium' }}-->
          <!--          </v-icon>-->
        </v-chip>
      </div>
    </v-card>
    <v-card
      v-if="allCiders != null"
      class="mx-auto overflow-y-auto rounded-b-xl"
      min-width="100"
      tile
      :outlined="$vuetify.theme.dark"
      style="overflow: auto"
    >
      <v-data-table
        v-model="selectedCiders"
        :items="tableCiders"
        :headers="showHeaders"
        :group-by="groupByField"
        show-group-by
        multi-sort
        :hide-default-footer="false"
        loading-text="Loading Ciders... Please wait"
        item-key="uniqueId"
        :search="search"
        :disable-pagination="false"
        :items-per-page="50"
        :group-desc.sync="group_desc"
        :footer-props="{'items-per-page-options': [10, 25, 50, 100, 250, 500]}"
        :item-class="row_classes"
        show-select
        fixed-header
        height="calc(100vh - 128px - 64px - 24px - 108px)"
        @click:row="clickTable"
        @update:group-by="updateGroupBy"
      >
        <template #group.header="{ group, groupBy, headers, toggle, remove, isOpen, items }">
          <td
            :colspan="headers.length"
            style="min-height: 48px;"
            :style="{'background-color': $vuetify.theme.currentTheme.light_grey}"
          >
            <div class="d-flex flex-row align-center justify-center">
              <!--              <v-checkbox v-model="headerChecked" @click="checkCorrespondingCiders(group, groupBy)" />-->
              <v-btn
                :ref="group"
                x-small
                icon
                @click="toggle"
              >
                <transition name="fade">
                  <v-icon v-if="isOpen">
                    mdi-chevron-up
                  </v-icon>
                  <v-icon v-else>
                    mdi-chevron-down
                  </v-icon>
                </transition>
              </v-btn>
              <!-- Custom Header Groups -->
              <span
                v-if="groupBy[0] === 'stateText'"
                class="d-flex align-center mx-5 font-weight-bold"
              >
                <v-btn
                  icon
                  small
                  class="d-flex animate_change d-flex"
                  @click.native.stop=""
                >
                  <v-icon
                    class="d-flex align-center justify-center ma-auto"
                    style="display: inline-flex !important"
                    :color="getCiderStateIconColor(items[0])"
                  >
                    {{ items[0].stateIcon }}
                  </v-icon>
                </v-btn>
                {{ items[0].stateText }}
              </span>
              <span
                v-else-if="groupBy[0] === 'land.name'"
                class="d-flex align-center mx-5 font-weight-bold"
              >
                <country-flag
                  class="elevation-2"
                  :country="items[0].land.code"
                  size="normal"
                />
                {{ items[0].land.name }}
              </span>
              <span
                v-else-if="groupBy[0] === 'kategorie.name'"
                class="d-flex align-center mx-5 font-weight-bold"
              >
                {{ items[0].kategorie.displayId || items[0].kategorie.id }}: {{ items[0].kategorie.name }}
              </span>
              <span
                v-else-if="groupBy[0] === 'table'"
                class="mx-5 font-weight-bold"
              >
                {{ items[0].table.nummer }}
              </span>
              <span
                v-else-if="groupBy[0] === 'badgeData.punkteMin'"
                class="d-flex mx-5 font-weight-bold"
              >
                <img
                  alt="Badge"
                  class="align-center animate_change mt-0"
                  style="height: 35px; width: 35px;"
                  :src="getBadgeUrl(300, items[0].totalPoints)"
                >
                <span class="d-flex align-center ml-2">
                  {{ items[0].badgeData.punkteMin }} - {{ items[0].badgeData.punkteMax }} Punkte
                </span>
              </span>
              <!-- Default Header Group -->
              <span
                v-else
                class="mx-5 font-weight-bold"
              >{{ group }}</span>
              <v-btn
                :ref="group"
                x-small
                fab
                icon
                @click="remove"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </div>
          </td>
        </template>

        <template #item.stateText="{ item }">
          <v-tooltip right>
            <template #activator="{ on }">
              <v-btn
                icon
                small
                class="animate_change d-flex"
                @click.native.stop=""
                v-on="on"
              >
                <v-icon
                  class="d-flex align-center justify-center ma-auto"
                  style="display: inline-flex !important"
                  :color="getCiderStateIconColor(item)"
                >
                  {{ item.stateIcon }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ item.stateText }}</span>
          </v-tooltip>
        </template>

        <template #item.uniqueId="{ item }">
          <div
            class="d-flex justify-center"
            style="gap: 0 !important;"
          >
            <v-menu
              top
              offset-y
              transition="scale-transition"
              origin="bottom left"
              class="d-flex justify-start"
              max-width="600px"
              open-on-hover
            >
              <template #activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >{{ item.displayId }}</span>
              </template>
              <CiderMenu
                :cider="item"
                :table="item.table"
                :show="false"
              />
            </v-menu>
          </div>
        </template>
        <template #item.flightName="{ item }">
          <div
            v-if="item.table != null"
            class="d-flex justify-center"
          >
            <v-menu
              top
              offset-y
              transition="scale-transition"
              origin="bottom left"
              class="d-flex justify-start"
              max-width="600px"
              open-on-hover
            >
              <template #activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >{{ item.flightName }}</span>
              </template>
              <FlightMenu
                :flight="item.table.flights.find(flight => flight.id === item.flightId)"
                :table="addCidersToTable(item.table, item.flightId)"
                :show="false"
                :index="item.table.flights.findIndex(flight => flight.id === item.flightId)"
              />
            </v-menu>
          </div>
        </template>
        <template #item.kategorie.name="{ item }">
          <div
            class="d-flex justify-center"
            style="gap: 0 !important;"
          >
            {{ item.kategorie.displayId }}: {{ item.kategorie.name }}
          </div>
        </template>
        <template #item.land.name="{ item }">
          <div
            class="d-flex justify-start align-center pa-0"
            style="gap: 0 !important;"
          >
            <v-tooltip top>
              <template #activator="{ on }">
                <div v-on="on">
                  <country-flag
                    class="elevation-2"
                    :country="item.land.code"
                    size="normal"
                  />
                </div>
              </template>
              <span>{{ item.land.name || item.land }}</span>
            </v-tooltip>
            <!--            {{ item.produzent }}-->
          </div>
        </template>
        <template #item.table="{ item }">
          <div class="d-flex justify-center">
            {{ item.table.nummer }}
          </div>
        </template>

        <template #item.notes.kommentar="{ item }">
          <div>{{ item.notes.kommentar }}</div>
          <!--              <tr>-->
          <!--                <td class="truncate" >{{ item.kommentar}}</td>-->
          <!--              </tr>-->
        </template>

        <template #item.totalPoints="{ item }">
          <!--              <v-chip class="elevation-1" @click="cider_click(item)" :color="getColor(item.punkte)" dark text-color="black" >-->
          <!--                {{ item.punkte }}-->
          <!--              </v-chip>-->
          <ChipPoints
            :points="item.totalPoints"
            :badge-data="item.badgeData"
            :round-to="0.1"
            @click="clickTable(item)"
          />
          <!--          <v-chip-->
          <!--            label-->
          <!--            style="min-width: 70px; max-width: 70px; margin:0 auto"-->
          <!--            class="pl-1 pr-1 d-flex justify-center animate_change"-->
          <!--            :outlined="$store.getters['badgesStore/getColor'](item.totalPoints) === null"-->
          <!--            :color="$store.getters['badgesStore/getColor'](item.totalPoints) !== null -->
          <!--              ? $store.getters['badgesStore/getColor'](item.totalPoints) : '#aaa'"-->
          <!--            dark-->
          <!--            :text-color="'#' + $store.getters['badgesStore/getTextColor'](item.totalPoints, $vuetify.theme.dark)"-->
          <!--            @click="clickTable(item)"-->
          <!--          >-->
          <!--            <v-icon-->
          <!--              v-if="item.totalPoints > 0"-->
          <!--              small-->
          <!--              class=""-->
          <!--            >-->
          <!--              mdi-star-->
          <!--            </v-icon>-->
          <!--            {{ item.totalPoints }}-->
          <!--          </v-chip>-->
        </template>

        <template #item.badgeData.punkteMin="{ item }">
          <div class="d-flex justify-center animate_change">
            <badge
              :badge-data="item.badgeData"
              :badge-width="36"
            />
            <!--            <img-->
            <!--              alt="None"-->
            <!--              class="animate_change"-->
            <!--              style="height: 35px; width: 35px; margin: auto"-->
            <!--              :src="getBadgeUrl(300, item.totalPoints)"-->
            <!--            >-->
          </div>
        </template>
        <template #item.none="{ item }">
          <div class="d-flex justify-center animate_change">
            <v-menu offset-y>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
              <v-list align="left">
                <v-list-item
                  :href="`${baseUrl}ciders/${item.id}`"
                  target="_blank"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-glass-wine</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Öffne Cider #{{ item.id }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  :href="`${baseUrl}flights/${item.flightId}`"
                  target="_blank"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-airplane-takeoff</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Öffne Flight {{ item.flightId }}: {{ item.flightName }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  :href="`${baseUrl}kategorien/${item.kategorie.id}`"
                  target="_blank"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-numeric-1-box-multiple-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Öffne Kategorie {{ item.kategorie.id }}: {{ item.kategorie.name }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  :href="`${baseUrl}tables/${item.table.id}`"
                  target="_blank"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-account-multiple-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Öffne Table {{ item.table.nummer }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
      <!--      <v-list dense>-->
      <!--        <v-subheader>Alle Cider (In Progress)</v-subheader>-->
      <!--        <v-list-item-group-->
      <!--          color="primary"-->
      <!--        >-->
      <!--          <v-list-item-->
      <!--            v-for="(cider, i) in allCiders"-->
      <!--            :key="i"-->
      <!--            @click="$emit('selectCider', i)"-->
      <!--          >-->
      <!--            <v-list-item-content>-->
      <!--              <v-list-item-title v-text="cider.name" />-->
      <!--            </v-list-item-content>-->
      <!--          </v-list-item>-->
      <!--        </v-list-item-group>-->
      <!--      </v-list>-->
    </v-card>

    <upload-producer-documents-modal ref="uploadDocumentsModalRef" />
  </v-container>
</template>

<script>
import i18n from "@/i18n";
import cider_mixin from "@/components/mixins/cider_mixin";
import user_mixin from "@/components/mixins/user_mixin";
import CountryFlag from "vue-country-flag";
import {apiTyped} from "@/api";
import CiderMenu from "@/components/admin/menu/cider_menu";
import FlightMenu from "@/components/admin/menu/flight_menu";
import ChipPoints from "@/components/chip_points";
import Badge from "@/components/badge";
import UploadProducerDocumentsModal from "@/components/admin/upload_producer_documents_modal.vue";

export default {
  name: "CiderTable",
  components: {UploadProducerDocumentsModal, Badge, ChipPoints, FlightMenu, CiderMenu, CountryFlag},
  mixins: [cider_mixin, user_mixin],
  props: {
    allCiders: {type: Array, required: true, default: null},
  },
  data: () => ({
    showRightSide: true,
    reloadPreppedCiders: 0,
    search: '',
    group_desc: false,
    statusFilter: [],
    selectedFlights: [],
    showIndividual: false,
    showBestOfCategory: false,
    selectedCiders: [],
    groupByField: null,
    hiddenHeaders: [],
    openContextMenu: false,
    selectedId: -1,
    selectedFlightId: -1,
    headerChecked: null,
    tableCiders: [],
  }),
  computed: {
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        default: return false
      }
    },
    bestOfCategoryCiders() {
      return this.$store.getters["ciderStore/getBestOfCategory"](this.allCidersPrepped);
    },
    baseUrl() {
      return apiTyped.baseUrl + "admin/content/";
    },
    allCidersPrepped() {
      // console.log("allCidersPrepped")
      // this.reloadPreppedCiders;
      const preppedCiders = this.allCiders.map((cider, index) => {
        cider.uniqueId = index;
        cider.totalPoints = this.calculateTotalPointsForCider(cider, true, false, 0.1);
        cider.badgeData = this.$store.getters['badgesStore/getBadgeData'](this.calculateTotalPointsForCider(cider, true, false, 0.1)),
        cider.stateIcon = this.getCiderStateIcon(cider);
        cider.stateText = this.getCiderStateText(cider);

        if (cider.land === undefined || cider.land === null) cider.land = {name: "--", code: "--"};
        return cider;
      });
      // if (this.groupByField === "kategorie.name") {
      //   console.log("Sort by kat");
      //   preppedCiders.sort((c1, c2) => c1.kategorie.name.localeCompare(c2.kategorie.name))
      // }
      return preppedCiders;
    },
    showHeaders () {
      return this.allHeaders.filter(
          s => !this.hiddenHeaders.find(hiddenHeader => hiddenHeader.text === s.text));
    },
    allHeaders() {
      return [
        {text: i18n.t('status'), value: 'stateText', align: 'center', sortable: true, groupable: true,
          filter: (value, search, cider) => {
            if (this.statusFilter.length === 0) return true
            return this.statusFilter.includes(this.$store.getters["ciderStore/getCiderRatingState"](cider));
          },
        },
        {text: "#Nr.", value: 'displayId', align: 'center', sortable: true, groupable: false},
        {text: i18n.tc('flight', 2) + ' ', value: 'flightName', align: 'center', sortable: true, groupable: true},
        {text: 'Name',  value: 'name', align: 'center', sortable: true, groupable: false},
        {text: i18n.tc('category', 1),  value: 'kategorie.name', align: 'center', sortable: true, groupable: true, width: 164},
        {text: 'Land', value: 'land.name', align: 'center', sortable: true, groupable: true},
        {text: 'Produzent', value: 'companyName', align: 'center', sortable: true, groupable: true},
        {text: 'Table', value: 'table', align: 'center', sortable: true, groupable: true},
        {text: i18n.tc('point', 2), value: 'totalPoints', align: 'center', sortable: true, groupable: false},
        {text: 'Award ', value: 'badgeData.punkteMin', align: 'center', sortable: true, groupable: true},
        {text: '', value: 'none', align: 'center', sortable: false, groupable: false},
      ]
    },
  },
  watch: {
    headerChecked: function (newValue) {
      console.log("checked Header", newValue)
    },
  },
  mounted() {
    this.selectedId = this.allCiders[0].id;
    this.selectedFlightId = this.allCiders[0].flightId;
    this.tableCiders = this.allCidersPrepped;
  },
  methods: {
    async openUploadDocumentsModal() {
      const uploadResult = await this.$refs.uploadDocumentsModalRef?.open(this.tableCiders);
    },
    toggleRightSide() {
      this.showRightSide = !this.showRightSide;
      this.$emit('toggleRightSide');
    },
    allCidersCsv() {
      let s = "\uFEFF";
      for (const cider of this.allCidersPrepped) {
        // s = s + cider.produzent;
        s = s + cider.land.name + ";"
        s = s + cider.name + ";"
        s = s + cider.companyName + ";"
        s = s + cider.totalPoints + ";"
        s = s + cider.kategorie.name + ";"
        s = s + "\r\n" ;
      }
      let text = JSON.stringify(s);
      let filename = 'data.csv';
      let element = document.createElement('a');
      // element.setAttribute('href', 'data:text/plain;charset=utf-8,' + (text));
      element.href = URL.createObjectURL(new Blob([text], {
        type: 'text/csv;charset=utf-8;'
      }));
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      // return s;
    },
    deepValue(obj, path){
      for (let i=0, path=path.split('.'), len=path.length; i<len; i++) {
        if (path[i] !== undefined && obj[path[i]] !== undefined && obj[path[i]] !== null)
          obj = obj[path[i]];
      }
      // console.log("output deepValue", obj)

      return obj
    },
    checkCorrespondingCiders(group, groupBY) {
      console.log("Headerchek executed")
      console.log("output headercheck", group, groupBY)

      this.allCiders.forEach(cider => {
        if (this.deepValue(cider, groupBY) === group) this.selectedCiders.push(cider);
      }  )
    },
    getCiderIdx(ciders) {
      return ciders.map(cider => this.allCiders.findIndex(
          allCider => allCider.id === cider.id && allCider.flightId === cider.flightId))
    },
    clickTable(cider) {
      this.selectedId = cider.id;
      this.selectedFlightId = cider.flightId;
      this.$emit('selectCider', this.getCiderIdx([cider]));
    },
    row_classes(item) {
      if (item.id === this.selectedId && item.flightId === this.selectedFlightId) {
        return "divider"; //can also return multiple classes e.g ["orange","disabled"]
      }
    },
    updateGroupBy(group) {
      if (Array.isArray(group)) group = group[0]
      console.log("Update group by", group)
      this.group_desc = false;
      if (group === 'land') group = 'land.name'
      if (group === 'badgeData' || group === 'badgeData.punkteMin') {
        group = 'badgeData.punkteMin';
        this.group_desc = true;
      }
      console.log("Update group by to", group)
      this.groupByField = group;
      this.reloadPreppedCiders += 1;
    },
    toggleIndividual() {
      this.showIndividual = !this.showIndividual;
      this.$emit('toggleIndividual');
    },
    toggleBestOfCategory() {
      this.showBestOfCategory = !this.showBestOfCategory;
      if (this.showBestOfCategory) {
        this.tableCiders = this.bestOfCategoryCiders;
      } else {
        this.tableCiders = this.allCidersPrepped;
      }
      this.$emit('toggleBestOfCategory');
    },
    printCiders() {
      // let selectedIdx = this.getCiderIdx(this.selectedCiders);
      // if (selectedIdx.length === 0) selectedIdx = this.allCiders.map((_, index) => index);
      console.log("this.selectedCiders", this.selectedCiders, this.selectedCiders.map(c => c.flightsCidersId))
      this.$emit('printCiders', this.selectedCiders.map(c => c.flightsCidersId));
    },
    clickContext(mouse, item) {
      console.log("Right click", item)
      this.openContextMenu = true
    },
    addCidersToTable(table, flightId) {
      // Copy table so that when setting printCiders to store no circularity exists:
      // cider.table.ciders -> points to itself!
      table.ciders = {};
      const newTable = JSON.parse(JSON.stringify(table));
      newTable.ciders = this.allCiders.filter(cider => cider.table.id === table.id && cider.flightId === flightId);
      return newTable;
    },
  },
}
</script>

<style scoped>
.v-text-field.v-text-field--solo-flat > .v-input__control {
  max-height: 48px !important;
}
.selected_row {
  background: #2c3e50 !important;
}
</style>