<template>
  <v-card
    class="elevation-2"
    color="light_grey"
    :outlined="$vuetify.theme.dark"
  >
    <v-card-title class="pt-2 pb-0">
      <v-row
        align="baseline"
        class="ma-0"
      >
        <p class="ma-0 text-h5">
          {{ $tc('cider', products.length) }}
        </p>
        <v-spacer />
        <v-select
          v-if="!hideFlightSelect && false"
          v-model="selectedFlights"
          :items="getFlights"
          clearable
          style="max-width: 350px;"
          multiple
          class="ml-auto pl-6"
          :label="$tc('flight', 2)"
        >
          <template #prepend-item>
            <v-list-item
              ripple
              @click="toggleAllFlights"
            >
              <v-list-item-action>
                <v-icon :color="selectedFlights.length > 0 ? 'primary' : ''">
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('selectAll') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2" />
          </template>
          <template #selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              small
            >
              <span>{{ selectedAllFlights ? "All Flights" : item }}</span>
            </v-chip>
            <span
              v-if="index === 1 && !selectedAllFlights"
              class="grey--text caption"
            >
              (+{{ selectedFlights.length - 1 }})
            </span>
            <span
              v-if="index === 1 && selectedAllFlights"
              class="grey--text caption"
            >
              ({{ selectedFlights.length }})
            </span>
          </template>
        </v-select>
        <v-spacer />
        <v-select
          id="groupByHeader"
          v-model="groupByHeader"
          :items="groupByHeaders"
          item-value="value"
          style="max-width: 350px;"
          class="ml-4"
          :item-text="item => item.myText || item.text"
          :label="$t('CiderTable.groupBy')"
        >
          <template #prepend-item>
            <v-list-item
              ripple
              @click="groupByHeader = null"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('none') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2" />
          </template>
        </v-select>

        <v-text-field
          v-model="search"
          class="mb-2 ml-4"
          style="transform: translateY(-2.5px); max-width: 320px;"
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          outlined
          clearable
          dense
          rounded
          hide-details
        />

        <v-tooltip
          v-if="isSmallScreen"
          top
        >
          <template #activator="{ on }">
            <v-btn
              icon
              @click="isMobileTable = !isMobileTable"
              v-on="on"
            >
              <v-icon>
                {{ isMobileTable ? 'mdi-table-row' : 'mdi-table-column' }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t(isMobileTable ? 'CiderTable.toggleBigTable' : 'CiderTable.toggleSmallTable') }}</span>
        </v-tooltip>
      </v-row>
    </v-card-title>

    <template v-if="!hideLegend">
      <div
        class="d-flex rounded-xl"
        style="cursor: pointer;"
        @click="userHideLegend = !userHideLegend"
      >
        <span
          class="d-flex ml-3 mt-1 justify-start text-left subtitle-1"
        >
          {{ $t('CiderTable.legendRatingSteps') }}
          <v-btn
            icon
            small
            class="ml-1 text--secondary"
            @click="userHideLegend = !userHideLegend"
            @click.native.stop=""
          >
            <v-icon :class="{rotate_180: userHideLegend}">
              mdi-chevron-up
            </v-icon>
          </v-btn>
        </span>
      </div>
      <v-expand-transition>
        <v-chip-group
          v-show="!userHideLegend"
          id="ciderStateChipGroup"
          v-model="statusFilter"
          column
          multiple
          class="ml-3 mr-3"
        >
          <template v-for="(ciderState, idx) in allCiderStates">
            <v-chip
              :key="ciderState[1]"
              small
              outlined
              filter
              class="mr-0"
            >
              <v-icon
                left
                small
                :color="$store.getters['ciderStore/getCiderStateIconColorByState'](ciderState[0], $vuetify)[1]"
              >
                {{ $store.getters["ciderStore/getCiderStateIconColorByState"](ciderState[0], $vuetify)[0] }}
              </v-icon>
              {{ ciderState[1] }}
              ({{ products.filter(p => Number(p.state) === Number(ciderState[0])).length }})
            </v-chip>
            <v-icon
              v-if="idx < allCiderStates.length - 1"
              :key="idx"
              small
            >
              mdi-chevron-right
            </v-icon>
          </template>
        </v-chip-group>
      </v-expand-transition>
      <v-divider class="mt-1" />
    </template>

    <v-row class="d-flex pt-3 pb-3 px-3">
      <v-col class="d-flex justify-md-start justify-end">
        <slot
          class="d-flex justify-md-start justify-end"
          name="leftButtons"
        />
      </v-col>
      <v-spacer />
      <v-col class="d-flex justify-end">
        <v-btn
          outlined
          rounded
          :loading="isRevealedLoading"
          :disabled="!canReveal"
          @click="isRevealed = !isRevealed"
        >
          <v-icon
            v-if="!isRevealed"
            left
          >
            mdi-curtains
          </v-icon>
          <v-icon
            v-if="isRevealed"
            left
          >
            mdi-curtains-closed
          </v-icon>
          <span v-if="!isRevealed">
            {{ $t('revealProducts') }}
          </span>
          <span v-if="isRevealed">
            {{ $t('hideProductInfos') }}
          </span>
        </v-btn>
        <v-tooltip
          top
        >
          <template #activator="{ on }">
            <v-icon
              class="pl-1"
              style="opacity: 0.8;"
              v-on="on"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <div
            style="max-width: 600px;"
          >
            <span>
              {{ $t('revealProductsInfo') }}
            </span>
          </div>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-divider class="pb-1" />

    <v-data-table
      v-if="myHeaders"
      ref="dataTable"
      v-model="selectedProducts"
      :headers="myHeaders"
      :items="productsMutable"
      :loading="loadingProducts"
      :mobile-breakpoint="isMobileTable ? 600 : 1"
      :dense="smallTable"
      :group-by="groupByHeader"
      :show-select="showSelect"
      :custom-filter="customFilter"
      :single-select="singleSelect"
      show-group-by
      multi-sort
      hide-default-footer
      loading-text="Loading Ciders... Please wait"
      item-key="flightsCidersId"
      :search="search"
      :disable-pagination="true"
      :group-desc.sync="group_desc"
      @contextmenu:row="(contextMenu, row) => handleContextMenu(contextMenu, row)"
      @click:row="(item, row) => $emit('clickRow', {item: item, row: row})"
      @update:group-by="updateGroupBy"
    >
      <template #group.header="{ group, groupBy, headers, toggle, remove, isOpen, items }">
        <td
          :colspan="headers.length"
          style="min-height: 48px !important; box-sizing: border-box; margin: 0 !important; position: relative; width: 100vw;"
          :style="{'background-color': $vuetify.theme.currentTheme.surface}"
        >
          <div class="d-flex flex-row align-center justify-start pl-4">
            <v-btn
              :ref="group"
              x-small
              icon
              @click="toggle"
            >
              <transition name="fade">
                <v-icon v-if="isOpen">
                  mdi-chevron-up
                </v-icon>
                <v-icon v-else>
                  mdi-chevron-down
                </v-icon>
              </transition>
            </v-btn>

            <!-- Custom Header Groups -->
            <span
              v-if="groupBy[0] === 'stateText'"
              class="d-flex align-center mx-5 font-weight-bold"
            >
              <v-btn
                icon
                small
                class="d-flex animate_change d-flex"
                @click.native.stop=""
              >
                <v-icon
                  class="d-flex align-center justify-center ma-auto"
                  style="display: inline-flex !important"
                  :color="getCiderStateIconColor(items[0])"
                >
                  {{ items[0].stateIcon }}
                </v-icon>
              </v-btn>
              <span class="pl-2">
                {{ items[0].stateText }}
              </span>
            </span>
            <span
              v-else-if="groupBy[0] === 'land.name'"
              class="d-flex align-center mx-5 font-weight-bold"
            >
              <country-flag
                class="elevation-2"
                style="border-radius: 10px !important;"
                :country="items[0].land.code"
                size="normal"
              />
              {{ items[0].land.name }}
            </span>
            <span
              v-else-if="groupBy[0] === 'badgeData.punkteMin'"
              class="d-flex mx-5 font-weight-bold"
            >
              <badge
                v-if="items[0].badgeData != null"
                :badge-data="items[0].badgeData"
                :badge-width="35"
                :show-none-badge="false"
                :badge-resolution="300"
              />
              <span class="d-flex align-center ml-2">
                {{ items[0].badgeData.punkteMin }} - {{ items[0].badgeData.punkteMax }} {{ $tc('point', 2) }}
              </span>
            </span>
            <span
              v-else-if="groupBy[0] === 'notes.isFavourite' && items[0].notes.isFavourite"
              class="mx-5 font-weight-bold"
            >
              <v-icon
                class="d-flex align-center justify-center ma-auto"
                style="display: inline-flex !important"
              >
                mdi-heart
              </v-icon>
              {{ $tc('favourite', 2) }}
            </span>
            <span
              v-else-if="groupBy[0] === 'notes.isFavourite' && !items[0].notes.isFavourite"
              class="mx-5 font-weight-bold"
            >
              <v-icon
                class="d-flex align-center justify-center ma-auto"
                style="display: inline-flex !important"
              >
                mdi-heart-outline
              </v-icon>
              {{ $tc('notFavourite', 2) }}
            </span>
            <span
              v-else-if="groupBy[0] === 'notes.isPinned' && items[0].notes.isPinned"
              class="mx-5 font-weight-bold"
            >
              <v-icon
                class="d-flex align-center justify-center ma-auto"
                style="display: inline-flex !important"
              >
                mdi-bookmark
              </v-icon>
              {{ $tc('bookmarked') }}
            </span>
            <span
              v-else-if="groupBy[0] === 'notes.isPinned' && !items[0].notes.isPinned"
              class="mx-5 font-weight-bold"
            >
              <v-icon
                class="d-flex align-center justify-center ma-auto"
                style="display: inline-flex !important"
              >
                mdi-bookmark-outline
              </v-icon>
              {{ $tc('notBookmarked') }}
            </span>
            <span
              v-else-if="groupBy[0] === 'flightsSort'"
              class="mx-5 font-weight-bold"
            >
              {{ items[0].flightName }}
            </span>
            <span
              v-else
              class="mx-5 font-weight-bold"
            >
              {{ group }}
            </span>
            <v-btn
              :ref="group"
              x-small
              fab
              icon
              @click="remove"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </div>
        </td>
      </template>

      <template #item.stateText="{ item }">
        <v-tooltip right>
          <template #activator="{ on }">
            <v-btn
              icon
              small
              class="animate_change d-flex"
              @click.native.stop=""
              v-on="on"
            >
              <v-icon
                class="d-flex align-center justify-center"
                style="display: inline-flex !important"
                :color="getCiderStateIconColor(item)"
              >
                {{ item.stateIcon }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ item.stateText }}</span>
        </v-tooltip>
      </template>

      <template #item.displayId="{ item }">
        <div class="d-flex justify-center">
          {{ item.displayId }}
        </div>
      </template>
      <template #item.flightsSort="{ item }">
        <div class="d-flex justify-start">
          {{ item.flightName }}
        </div>
      </template>
      <template #item.kategorie.name="{ item }">
        <div class="d-flex justify-start">
          {{ item.kategorie.name }}
        </div>
      </template>
      <template #item.land="{ item }">
        <div
          v-if="item.land != null && item.land.code != null"
          class="d-flex justify-start align-center flex-shrink-0"
          style="gap: 0 !important;"
        >
          <country-flag
            class="elevation-2"
            style="border-radius: 10px !important;"
            :country="item.land.code"
            size="normal"
          />
          {{ item.land.name || item.land }}
        </div>
        <div
          v-if="item.land == null || item.land.code == null"
          class="d-flex justify-center align-baseline flex-shrink-0"
          style="gap: 0 !important;"
        >
          <p
            class="text-body-2 text--secondary text-center mb-0"
          >
            {{ $t('notSpec') }}
          </p>
        </div>
      </template>

      <template #item.badgeData.punkteMin="{ item }">
        <div
          class="d-flex justify-center animate_change"
        >
          <badge
            v-if="item.badgeData != null"
            :badge-data="item.badgeData"
            :badge-width="35"
            :badge-shadow="false"
            :show-none-badge="false"
            :badge-resolution="300"
          />
        </div>
      </template>

      <template #item.awardBadge="{ item }">
        <!--              <div class="img-circle img-circle--size" v-bind:style="{Image: `url(${getBadgeIcon(item,300)})`, backgroundSize: 'cover', backgroundPosition: 'center' }"></div>-->
        <div class="d-flex justify-center animate_change">
          <img
            alt="None"
            class="animate_change"
            style="height: 35px; width: 35px; margin: auto"
            :src="getBadgeIcon(item, 150)"
          >
        </div>
      </template>

      <template #item.notes.kommentar="{ item }">
        <div
          v-if="item.chairNotes != null && !commentIsEmpty(item.chairNotes.kommentar)"
          v-plaintext
          style="max-width: 400px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; font-size: 0.875rem;"
          v-html="item.chairNotes.kommentar"
        />
        <div
          v-if="item.notes != null && item.chairNotes == null
            && (item.chairNotes != null && commentIsEmpty(item.chairNotes.kommentar))"
          class="d-flex justify-start"
          style="max-width: 400px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; font-size: 0.875rem;"
        >
          {{ item.notes.kommentar }}
        </div>
      </template>

      <template #item.points="{ item }">
        <ChipPoints
          :points="item.points || 0"
          :dark-background="false"
        />
      </template>

      <template #item.images="{ item }">
        <div
          v-if="item.images != null"
          class="d-flex flex-gap justify-start"
        >
          <v-img
            v-for="(image, index) in item.images.slice(0, 2)"
            :key="image"
            :src="getImageUrl(image)"
            height="40"
            width="40"
            max-height="40"
            max-width="40"
            contain
            class="rounded-lg"
            style="cursor: pointer;"
            @click="setGallery(item, index)"
            @click.native.stop=""
          />
          <div
            class="d-flex align-center"
            style="max-width: 40px !important;"
          >
            <v-btn
              v-if="item.images.length > 2"
              class="d-flex align-center rounded-lg text--secondary"
              text
              outlined
              style="min-width: 40px !important;"
              max-width="40"
              height="40"
              @click="setGallery(item, 2)"
              @click.native.stop=""
            >
              +{{ item.images.length - 2 }}
            </v-btn>
          </div>
        </div>
      </template>

      <template #item.notes.isFavourite="{ item }">
        <v-btn
          class="animate_change"
          icon
          :disabled="!(tastingIsNotStarted || tastingIsRunning)"
          :class="{'mb-3': smallTable}"
          @click="favourite_item(item)"
          @click.native.stop=""
        >
          <v-icon
            v-if="item.notes != null && item.notes.isFavourite"
            dark
          >
            mdi-heart
          </v-icon>
          <v-icon
            v-else
            dark
          >
            mdi-heart-outline
          </v-icon>
        </v-btn>
        <v-btn
          v-if="smallTable"
          class="mb-3"
          icon
          @click="pinItem(item)"
          @click.native.stop=""
        >
          <v-icon
            v-if="item.notes != null && item.notes.isPinned"
            dark
          >
            mdi-bookmark
          </v-icon>
          <v-icon
            v-else
            dark
          >
            mdi-bookmark-outline
          </v-icon>
        </v-btn>
      </template>
      <template
        v-if="!smallTable"
        #item.notes.isPinned="{ item }"
      >
        <v-btn
          icon
          :disabled="!(tastingIsNotStarted || tastingIsRunning)"
          @click="pinItem(item)"
          @click.native.stop=""
        >
          <v-icon
            v-if="item.notes != null && item.notes.isPinned"
            dark
          >
            mdi-bookmark
          </v-icon>
          <v-icon
            v-else
            dark
          >
            mdi-bookmark-outline
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-overlay
      v-model="showGallery"
      color="#090909"
      z-index="96"
    >
      <gallery-overlay
        :items="galleryInfo.images.map(i => getImageUrl(i, 2000))"
        :selected="galleryInfo.selectedImage"
        :title="galleryInfo.ciderName"
        @closeGallery="showGallery = false"
        @click.native.stop=""
      />
    </v-overlay>
  </v-card>
</template>

<script>
import Vue from "vue";
import Badge from "@/components/badge.vue";
import ChipPoints from "@/components/chip_points.vue";
import CountryFlag from "vue-country-flag";
import cider_mixin from "@/components/mixins/cider_mixin";
import locale_mixin from "@/components/mixins/locale_mixin";
import logos_mixin from "@/components/mixins/logos_mixin";
import rating_mixin from "@/components/mixins/rating_mixin";
import user_mixin from "@/components/mixins/user_mixin";
import {sync} from "vuex-pathify";
import i18n from "@/i18n";
import CiderRatingState, {getStateNumbers} from "@/types/ciderRatingState";
import {apiTyped} from "@/api";
import GalleryOverlay from "@/components/gallery_overlay.vue";
import TastingCard from "@/components/producer/tasting_card.vue";

Vue.directive('plaintext', {
  bind(el, binding, vnode) {
    el.innerHTML = el.innerText;
    //el.innerHTML = el.innerHTML.replace(/<[^>]+>/gm, '');
  }
});

export default {
  name: "JudgesCiderTable",
  components: {
    GalleryOverlay,
    Badge,
    ChipPoints,
    CountryFlag
  },
  mixins: [cider_mixin, locale_mixin, logos_mixin, rating_mixin, user_mixin],
  props: {
    products: { type: Array, required: true, default: null },
    loadingProducts: { type: Boolean, required: false, default: false },
    showSelect: { type: Boolean, required: false, default: false },
    initialGroupBy: { type: String, required: false, default: "flightsSort" },
    initialSortBy: { type: String, required: false, default: "sort" },
    hideLegend: { type: Boolean, required: false, default: false },
    hideFlightSelect: { type: Boolean, required: false, default: false },
    singleSelect: { type: Boolean, required: false, default: false },
  },
  data: function () {
    return {
      galleryInfo: {
        selectedImage: null,
        images: [],
        ciderName: ''
      },
      showGallery: false,
      isRevealedLoading: false,
      loadedTastingFinishedStatus: false,
      productsMutable: null,
      isRevealed: false,
      enableTableCheckboxes: false,
      selectedProducts: [],
      groupByHeader: null,
      groupByField: null,
      search: '',
      group_desc: false,
      info: null,
      statusFilter: [],
      icon_clicked: false,
      loaded: false,
    }
  },
  watch: {
    selectedProducts: function (val, oldVal) {
      if (val.length > 25) {
        this.$nextTick(() => {
          this.selectedProducts = oldVal;
          this.$emit('productsSelected', this.selectedProducts);
        })
      } else {
        this.$emit('productsSelected', this.selectedProducts);
      }
    },
    products: function (val) {
      this.productsMutable = val;
    },
    isRevealed: function (val, oldVal) {
      if (!this.loaded) return;
      console.log("AAAAA",val, oldVal, this.loadedTastingFinishedStatus)
      if (val === true && oldVal === false && this.loadedTastingFinishedStatus === false) {
        // reload layout, since when first loaded no product infos were loaded since tasting was NOT finished
        this.$store.set("ciderStore/revealInfos", true);
        window.location.reload(true);
        this.isRevealedLoading = true;
      }
    },
    canReveal: function (val) {
      if (!val) this.isRevealed = false;
    }
  },
  i18n: {
    messages: {
      de: {
        "revealProducts": "Produkte aufdecken",
        "hideProductInfos": "Produktinfos verstecken",
        "revealProductsInfo": "Sobald das Tasting abgeschlossen ist, können die Produkte mit weiteren Infos wie Produktnamen oder den Produzenten dargestellt werden.",
      },
      en: {
        "revealProducts": "Reveal Products",
        "hideProductInfos": "Hide product info",
        "revealProductsInfo": "As soon as the tasting is complete, the products additional information can be revealed such as product names or the producer.",
      }
    }
  },
  computed: {
    userHideLegend: sync('ciderStore/hideLegendSteps'),
    isMobileTable: sync('ciderTableMobileLayout'),
    selectedFlights: sync('tableStore/selectedFlights'),
    currentTasting() {
      return this.$store.getters["usersStore/currentTasting"]();
    },
    canReveal() {
      return this.currentTasting.finished;
    },
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        default: return false
      }
    },
    groupByHeaders() {
      const groupByHeaders = this.allHeaders.filter(h => h.myGroupable === true);
      if (this.isRevealed) return groupByHeaders;
      return groupByHeaders.filter(h => h.revealedInfo === false);
    },
    allHeaders() {
      return [
        {text: i18n.t('status'), value: 'stateText', align: 'center', sortable: true, groupable: false, myGroupable: true, width: 100, revealedInfo: false,
          filter: (value, search, cider) => {
            if (this.statusFilter.length === 0) return true
            return this.statusFilter.includes(this.$store.getters["ciderStore/getCiderRatingState"](cider));
          },
        },
        {text: i18n.t('nr'), value: 'displayId', align: 'center', sortable: true, groupable: false, width: 80, revealedInfo: false},
        {text: i18n.tc('flight', 2) + ' ', myText: i18n.tc('flight', 2) + ' ', value: 'flightsSort', align: 'start', sortable: true, groupable: false, myGroupable: true, width: 100, revealedInfo: false,
          filter: value => {
            if (this.selectedFlights.length === 0) return true
            return this.selectedFlights.includes(value);
          },
        },

        {text: i18n.t('productNameBrand'), value: 'name', align: 'start', sortable: true, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('salesDesignation'), value: 'salesDesignation', align: 'start', sortable: true, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('designationOfOrigin'), value: 'designationOfOrigin', align: 'start', sortable: true, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('producer'), value: 'produzent', align: 'start', sortable: true, groupable: true, myGroupable: false, revealedInfo: true},
        {text: i18n.t('images'), value: 'images', align: 'center', sortable: false, groupable: false, myGroupable: false, revealedInfo: true},

        {text: i18n.tc('category', 1), value: 'kategorie.name', align: 'start', sortable: true, groupable: false, myGroupable: true, width: 170, revealedInfo: false},
        {text: i18n.tc('country', 1), value: 'land', align: 'start', class: 'pl-7', sortable: true, groupable: false, myGroupable: true, width: 90, revealedInfo: false},
        {text: i18n.tc('point', 2), value: 'points', align: 'center', sortable: true, groupable: false, width: 100, revealedInfo: false},
        {text: 'Award ', value: 'badgeData.punkteMin', align: 'center', sortable: true, groupable: false, myGroupable: true, width: 100, revealedInfo: false},
        {text: i18n.tc('comment', 1), value: 'notes.kommentar', align: 'start', sortable: true, groupable: false, width: 350, revealedInfo: false},

        {text: i18n.t('alcoholAbv'), value: 'alkohol', align: 'start', sortable: true, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('residualSugar'), value: 'residualSugar', align: 'start', sortable: true, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('descriptionResidualSugar'), value: 'residualSugarDescription', align: 'start', sortable: true, groupable: false, myGroupable: true, revealedInfo: true},
        {text: i18n.t('filtrationClarity'), value: 'filtrationClarity', align: 'start', sortable: true, groupable: false, myGroupable: true, revealedInfo: true},
        {text: i18n.t('pressure'), value: 'pressure', align: 'start', sortable: true, groupable: false, myGroupable: true, revealedInfo: true},
        {text: i18n.t('method'), value: 'method', align: 'start', sortable: true, groupable: false, myGroupable: true, revealedInfo: true},
        {text: i18n.t('vintage'), value: 'vintage', align: 'start', sortable: true, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('bottleContent'), value: 'bottleContent', align: 'start', sortable: true, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('retailPrice'), value: 'price', align: 'start', sortable: true, groupable: false, myGroupable: false, revealedInfo: true},

        {text: '', value: 'notes.isFavourite', align: 'center', sortable: false, groupable: false, myGroupable: true, width: 24, myText: i18n.tc('favourite', 1), revealedInfo: false},
        {text: '', value: 'notes.isPinned', align: 'center', sortable: false, groupable: false, myGroupable: true, width: 24, myText: i18n.tc('bookmarked', 1), revealedInfo: false},
      ]
    },
    myHeaders() {
      let filteredHeaders;
      if (!this.isRevealed) filteredHeaders = this.allHeaders.filter(header => header.revealedInfo === false);
      if (this.isRevealed) filteredHeaders = this.allHeaders;
      if (this.isMobileTable) {
        return filteredHeaders.filter(header => header.value !== 'notes.isPinned');
      }
      return filteredHeaders;
    },
    getFlights() {
      return [...new Set(this.products.map(cider => cider.flightName))];
    },
    selectedAllFlights() {
      return this.selectedFlights.length === this.getFlights.length;
    },
    selectedSomeFlights() {
      return this.selectedFlights.length > 0 && !this.selectedAllFlights;
    },
    icon() {
      if (this.selectedAllFlights) return 'mdi-close-box'
      if (this.selectedSomeFlights) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    allCiderStates() {
      const stateNames = getStateNumbers();
      return stateNames.map(name => [name, this.$t(String(CiderRatingState[name]))]);
    },
    smallTable() {
      return this.isSmallScreen && this.isMobileTable;
    },
  },
  created() {
    this.loadedTastingFinishedStatus = this.getCurrentTasting.finished;
    this.groupByHeader = this.initialGroupBy;
    this.productsMutable = this.products;
    if (this.$store.state.ciderStore.revealInfos === true) {
      this.isRevealed = true;
    }
    this.$store.set("ciderStore/revealInfos", false);  // to prevent refresh loop
    this.$nextTick(() => this.loaded = true);
  },
  methods: {
    handleContextMenu(contextMenu, row) {
      this.$emit('clickRowContext', {item: row.item, row: contextMenu})
    },
    setGallery(item, index) {
      this.galleryInfo.selectedImage = index;
      this.galleryInfo.images = item.images;
      this.galleryInfo.ciderName = item.name;
      this.showGallery = true;
    },
    selectRow(flightsCidersId) {
      const item = this.products.find(p => Number(p.flightsCidersId) === Number(flightsCidersId));
      if (item != null) this.selectedProducts = [item];
    },
    customFilter(value, search, item) {
      if (search == null) return true;
      search = search.toLowerCase();
      for (const [key, value] of Object.entries(item)) {
        if (typeof value === "string" && value.toLowerCase().includes(search)) return true;
        if (typeof value === "number" && value === Number(search)) return true;
        // only search user cider notes when displayed (chair notes are empty)
        if (key === "notes"
          && (item.chairNotes != null && this.commentIsEmpty(item.chairNotes.kommentar))
          && value != null && value.kommentar != null && value.kommentar.toLowerCase().includes(search)) return true;
        if (key === "chairNotes" && value != null && value.kommentar != null && value.kommentar.toLowerCase().includes(search)) return true;
        if (key === "land" && value.name != null && value.name.toLowerCase().includes(search)) return true;
        if (key === "land" && value != null && value.kontinent != null && value.kontinent.toLowerCase().includes(search)) return true;
      }
      return false;
    },
    toggleAllFlights() {
      this.$nextTick(() => {
        if (this.selectedAllFlights) {
          this.selectedFlights = [];
        } else {
          this.selectedFlights = this.getFlights.slice();
        }
      });
    },
    async createNotesIfNull(cider) {
      if (cider.notes == null) {
        await cider.createNotes(this.$store.getters["usersStore/userId"]());
      }
    },
    async favourite_item(item) {
      this.icon_clicked = true;
      await this.createNotesIfNull(item);
      item.notes.isFavourite = await apiTyped.updateNotesField(
        item.notes.id, item.id, 'is_favourite', !item.notes.isFavourite);
    },
    async pinItem(item) {
      this.icon_clicked = true;
      await this.createNotesIfNull(item);
      item.notes.isPinned = await apiTyped.updateNotesField(
        item.notes.id, item.id, 'is_pinned', !item.notes.isPinned);
    },
    getColor(points) {
      return (this.$store.getters["badgesStore/getColor"](points));
    },
    getBadgeIcon(item, width) {
      if (width === undefined) width = 200;
      // let points = 0;
      // if (!this.$store.getters['ciderStore/userBewertungFinished'](item)) points = 0;
      // else points = this.$store.getters["ciderStore/getTotalPointsForCider"](item);
      return this.$store.getters["badgesStore/getBadgeIcon"](item.points, width);
    },
    updateGroupBy(group) {
      if (Array.isArray(group)) group = group[0]
      if (group === "notes.isFavourite" || group === "notes.isPinned") { this.group_desc = true; }
      if (group === 'land') group = 'land.name'
      if (group === 'badgeData' || group === 'badgeData.punkteMin') { group = 'badgeData.punkteMin'; this.group_desc = true; }
      this.groupByField = group;
      this.groupByHeader = group;
    },
  },
}
</script>

<style scoped>
/deep/ .input-group label {
  text-overflow: unset;
}

/deep/ .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 36px !important;
}

/deep/ .v-data-table {
  background-color: var(--v-light_grey-base) !important;
}

.animate_change {
  transition:all 0.25s linear;
}

/deep/ .v-data-table__mobile-table-row {
  padding-top: 12px;
  border-bottom: medium solid rgba(0,0,0,.12);
}
</style>