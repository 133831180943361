import {MyCollections} from "@/api/types/my-collections";
import {dateFromString} from "@/util/helper";

interface ChairNotes {
    id: number;
    ciderId: number;
    userCreated: string | null;
    userUpdated: string | null;
    dateCreated: Date | null;
    dateUpdated: Date | null;
    started: boolean;
    startedAt: Date | null;
    finished: boolean;
    finishedAt: Date | null;
    restarted: boolean;
    restartedAt: Date | null;
    restartedCount: number;
    kommentar: string;
}


export function createChairNotesFromDatabase(chairNotesDb: MyCollections["chair_notes"]): ChairNotes {
    const chairNotes = <ChairNotes>{
        id: Number(chairNotesDb.id),
        ciderId: Number(chairNotesDb.cider_id),
        userCreated: chairNotesDb.user_created,
        userUpdated: chairNotesDb.user_updated,
        dateCreated: dateFromString(chairNotesDb.date_created),
        dateUpdated: dateFromString(chairNotesDb.date_updated),
        started: chairNotesDb.started ?? false,
        finished: chairNotesDb.finished ?? false,
        restarted: chairNotesDb.restarted ?? false,
        restartedCount: chairNotesDb.restarted_count,
        startedAt: dateFromString(chairNotesDb.started_at),
        finishedAt: dateFromString(chairNotesDb.finished_at),
        restartedAt: dateFromString(chairNotesDb.restarted_at),
        kommentar: chairNotesDb.kommentar || null,
    };
    return chairNotes as ChairNotes;
}

export function updateChairNotesFromDatabase(
    chairNotes: ChairNotes, chairNotesDb: MyCollections["chair_notes"]
): ChairNotes {
    if (chairNotes == null) return chairNotes;
    if (chairNotesDb.id != null) chairNotes.id = chairNotesDb.id;
    if (chairNotesDb.cider_id != null) chairNotes.ciderId = chairNotesDb.cider_id as number;
    if (chairNotesDb.user_created != null) chairNotes.userCreated = chairNotesDb.user_created as string;
    if (chairNotesDb.user_updated != null) chairNotes.userUpdated = chairNotesDb.user_updated as string;
    if (chairNotesDb.date_created != null) chairNotes.dateCreated = dateFromString(chairNotesDb.date_created);
    if (chairNotesDb.date_updated != null) chairNotes.dateUpdated = dateFromString(chairNotesDb.date_updated);
    if (chairNotesDb.started != null) chairNotes.started = chairNotesDb.started ?? false;
    if (chairNotesDb.finished != null) chairNotes.finished = chairNotesDb.finished ?? false;
    if (chairNotesDb.restarted != null) chairNotes.restarted = chairNotesDb.restarted ?? false;
    if (chairNotesDb.restarted_count != null) chairNotes.restartedCount = chairNotesDb.restarted_count;
    if (chairNotesDb.started_at != null) chairNotes.startedAt = dateFromString(chairNotesDb.started_at);
    if (chairNotesDb.finished_at != null) chairNotes.finishedAt = dateFromString(chairNotesDb.finished_at);
    if (chairNotesDb.restarted_at != null) chairNotes.restartedAt = dateFromString(chairNotesDb.restarted_at);
    if (chairNotesDb.kommentar != null) chairNotes.kommentar = chairNotesDb.kommentar;
    return chairNotes;
}

export { ChairNotes };