<template>
  <v-select
    v-model="selectedCountry"
    :items="countries"
    label="Select Language"
    single-line
    return-object
    filled
    dense
    rounded
    hide-details
    item-text="name"
    item-value="code"
    class="ma-0 pa-0 elevation-0"
    @change="$emit('change', selectedCountry)"
  >
    <template
      #item="{item}"
      class="ma-0"
    >
      <country-flag
        :country="(item.flag_code || item.flagCode) || item.code.split('-')[0]"
        size="normal"
      />
      {{ item.name }}
    </template>
    <template #selection="{item}">
      <country-flag
        :country="(item.flag_code || item.flagCode) || item.code.split('-')[0]"
        size="normal"
      />
      {{ item.name }}
    </template>
  </v-select>
</template>

<script>
import CountryFlag from "vue-country-flag";

export default {
  name: "CountrySelect",
  components: {
    CountryFlag
  },
  props: {
    countries: {type: Array, required: true, default: null},
    selectedIdx: {type: Number, required: false, default: 0},
  },
  data: () => ({
    selectedCountry: null,
  }),
  created() {
    this.selectedCountry = this.countries[this.selectedIdx];
  }
}
</script>

<style scoped>

</style>