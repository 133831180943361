import {createTastingProduct, TastingProduct} from "@/types/tastingProduct";
import {createTastingProductPrice, TastingProductPrice} from "@/types/tastingProductPrice";
import {MyCollections} from "@/api/types/my-collections";
import {createInvoice, Invoice} from "@/types/Invoice";
import {User} from "@/types/user";
import {CProducerCiderResults} from "@/types/producerCider";
import tasting from "@/layouts/tasting.vue";
import {dateFromString} from "@/util/helper";

export interface TastingPurchasedProduct {
    id: number;
    position: number;
    tastingProduct: TastingProduct;
    productPrice: TastingProductPrice;
    amount: number;
}

export function createTastingPurchasedProduct(tastingPurchasedProduct: MyCollections["tasting_purchased_products"]) {
    return <TastingPurchasedProduct>{
        id: tastingPurchasedProduct.id,
        position: tastingPurchasedProduct.position,
        tastingProduct: createTastingProduct(
            tastingPurchasedProduct.tasting_product as MyCollections["tasting_products"]),
        productPrice: createTastingProductPrice(tastingPurchasedProduct.tasting_product_price),
        amount: tastingPurchasedProduct.amount
    }
}

export interface TastingPurchase {
    id: number;
    status: "unpaid" | "paid" | "request_refund" | "refunded";
    dateCreated: Date;
    purchasedProducts: TastingPurchasedProduct[];
    tastingId: number;
    referenceId: string;
    totalAmount: number;
    subtotalAmount: number;
    submittedProductIds: number[];
    publishedInvoice: Invoice | null;
}

export function createTastingPurchase(tastingPurchase: MyCollections["tasting_purchases"]) {
    return <TastingPurchase>{
        id: tastingPurchase.id,
        status: tastingPurchase.status,
        purchasedProducts: tastingPurchase.purchased_products != null
            ? tastingPurchase.purchased_products.map(
                p => createTastingPurchasedProduct(p as MyCollections["tasting_purchased_products"]))
            : [],
        dateCreated: dateFromString(tastingPurchase.date_created),
        tastingId: tastingPurchase.tasting,
        referenceId: tastingPurchase.reference_id,
        totalAmount: tastingPurchase.total_amount,
        subtotalAmount: tastingPurchase.subtotal_amount,
        submittedProductIds: tastingPurchase.submitted_products,
        publishedInvoice: tastingPurchase.invoices != null && tastingPurchase.invoices.length === 1
            ? createInvoice(tastingPurchase.invoices[0] as MyCollections["invoices"])
            : null,
    }
}

export interface AdminTastingPurchase extends TastingPurchase {
    user_created: User;
    stripeSessionId: string;
    companyName: string;
    legalForm: string;
    contactPerson: string;
    street: string;
    streetNumber: string;
    city: string;
    postalCode: string;
    provinceState: string;
    country: string;
    mobilePhone: string;
    email: string;
    website: string;
    vat: string;
    paymentIntent: string;
    submittedProducts: CProducerCiderResults[] | number[];
    taxExempt: string;
    amountTax: number;
    amountDiscount: number;
    amountShipping: string;
    invoices: Invoice[];
    latestInvoice: Invoice | null;
}

export function createAdminTastingPurchase(tastingPurchase: MyCollections["tasting_purchases"]) {
    const normalTastingPurchase = createTastingPurchase(tastingPurchase);
    const adminTastingPurchase = <AdminTastingPurchase>{
        ...normalTastingPurchase,
        stripeSessionId: tastingPurchase.stripe_session_id || "",
        companyName: tastingPurchase.company_name || "",
        legalForm: tastingPurchase.legal_form || "",
        contactPerson: tastingPurchase.contact_person || "",
        street: tastingPurchase.street || "",
        streetNumber: tastingPurchase.street_number || "",
        city: tastingPurchase.city || "",
        postalCode: tastingPurchase.postal_code || "",
        provinceState: tastingPurchase.province_state || "",
        country: tastingPurchase.country || "",
        mobilePhone: tastingPurchase.mobile_phone || "",
        email: tastingPurchase.email || "",
        website: tastingPurchase.website || "",
        vat: tastingPurchase.vat || "",
        paymentIntent: tastingPurchase.payment_intent || "",
        submittedProducts: tastingPurchase.submitted_products || "",
        taxExempt: tastingPurchase.tax_exempt || "",
        amountTax: tastingPurchase.amount_tax || "",
        amountDiscount: tastingPurchase.amount_discount || "",
        amountShipping: tastingPurchase.amount_shipping || "",
        invoices: tastingPurchase.invoices?.map(
            i => createInvoice(i as MyCollections["invoices"])).sort(
                (i1, i2) => (i2.dateUpdated || i2.dateCreated).getTime() - (i1.dateUpdated || i1.dateCreated).getTime()) || [],
        publishedInvoice: null,
        latestInvoice: null,
    }
    if (adminTastingPurchase.invoices.length > 0) adminTastingPurchase.latestInvoice = adminTastingPurchase.invoices[0];
    const publishedInvoice = adminTastingPurchase.invoices.find(
        invoice => invoice.status === 'published');
    if (publishedInvoice != null) adminTastingPurchase.publishedInvoice = publishedInvoice;
    return adminTastingPurchase;
}

