<template>
  <v-app style="margin-top: 0 !important;">
    <AdminAppBar v-if="created" />
    <v-main>
      <ActionDrawer />
      <v-container
        fluid
        class="pa-0 ma-0"
      >
        <!--        <AdminTableCard-->
        <!--          v-if="loaded"-->
        <!--          :tables="tables"-->
        <!--          :tasting="tasting"-->
        <!--          :kategorien="kategorien"-->
        <!--        />-->
        <router-view
          v-if="created"
          :key="reloadRouter"
        />
        <loading-circle
          v-if="!created"
          :long-loading="true"
          :loading-message="loadingMessage"
        />
      </v-container>
    </v-main>
    <CiderFooter
      :background-color="$vuetify.theme.currentTheme.background"
      :tasting="tasting"
      :badges-data-array="$store.state.badgesStore.badgesData"
    />
  </v-app>
</template>

<script>
import CiderFooter from "@/components/cider_footer.vue";
import AdminAppBar from "@/components/admin/admin_app_bar"
import ActionDrawer from "@/components/admin/actions_drawer"
import {apiTyped} from "@/api";
import {sync} from "vuex-pathify";
import router from "@/router";
import {RoleName} from "@/types/user";
import user_mixin from "@/components/mixins/user_mixin";
import LoadingCircle from "@/layouts/loading_circle";

export default {
  name: "LayoutAdmin",
  components: {
    LoadingCircle,
    AdminAppBar,
    CiderFooter,
    ActionDrawer,
  },
  mixins: [user_mixin],
  data: () => ({
    loaded: false,
    created: false,
    loadingMessage: "",
  }),
  computed: {
    reloadRouter() {
      return this.$store.state.reload_router;
    },
    tables: sync("tableStore/tables"),
    tastings: sync("usersStore/tastings"),
    kategorien: sync("kategorienStore/kategorieData"),
    currentTastingId: sync("usersStore/currentTastingId"),
    // currentTastingIdx() {
    //   return this.$store.getters["usersStore/getTastingIdxByTastingId"](Number(this.$route.query.tasting));
    // },
    // tasting: sync('usersStore/tastings@[:currentTastingIdx]'),
    tasting() {
      return this.$store.getters["usersStore/currentTasting"]();
    },
  },
  watch: {
    currentTastingId: async function (oldValue, newValue) {
      if (this.loaded && oldValue !== newValue) {
        await this.tastingChange(true);
      }
    },
  },
  async created() {
    document.title = "Cider World - Admin"
    this.$store.commit("usersStore/setUser", await apiTyped.loginRefresh());
    if (this.$store.state.appStore.authenticated === false ||
      this.$store.getters["usersStore/user"]().role !== RoleName.Admin) {
      await router.push("/login")
    }
    this.setTheme();
    this.loadingMessage = "Getting Tastings...";
    this.tastings = await apiTyped.adminGetTastings();
    this.$store.state.usersStore.tastings = this.tastings;
    // this.$store.state.usersStore.currentTastingIdx = 0;
    if (this.$route.query.tasting != null) {
      this.$store.state.usersStore.currentTastingId = Number(this.$route.query.tasting);
      // const queryTastingIdx = this.tastings.findIndex(
      //   tasting => Number(tasting.id) === Number(this.$route.query.tasting));
      // if (queryTastingIdx !== undefined) this.$store.state.usersStore.currentTastingIdx = queryTastingIdx;
    } else {
      this.$store.state.usersStore.currentTastingId = this.tastings[0].id;
    }
    await this.tastingChange();
    this.created = true;
  },
  methods: {
    async tastingChange(reload=false) {
      this.loaded = false;
      this.$store.state.pageLoading = true;
      const tables = await apiTyped.adminGetTablesAndFlights(this.tasting.id)
      const kategorien = await apiTyped.getAllKategorien(this.tasting.id);
      this.loadingMessage = "Getting Tables and Categories...";
      this.tables = tables.sort((t1, t2) =>
        t1.nummer.localeCompare(t2.nummer, undefined, {numeric: true, sensitivity: 'base'}));
      this.$store.commit("kategorienStore/setKategorieData", kategorien);
      this.loadingMessage = "Getting Badge Data...";
      this.$store.state.badgesStore.badgesData = await apiTyped.getBadges([this.tasting]);
      if (reload) {
        this.$store.state.reload_router = !this.$store.state.reload_router;
      }
      this.$store.state.pageLoading = false;
      this.loaded = true;
    }
  },
}
</script>

<style scoped>

</style>