<template>
  <div
    v-if="cider !== null"
    class="d-flex flex-row justify-start"
    style="background-color: white"
  >
    <v-img
      contain
      :lazy-src="getLogoUrl($store.getters['usersStore/currentTasting']().logoMesse, 500)"
      :src="getLogoUrl($store.getters['usersStore/currentTasting']().logoMesse, 500)"
      alt="CiderAwardLogo"
      max-width="150px"
    />
    <v-theme-provider>
      <div
        class="d-flex flex-column align-center justify-start ml-4 pl-4"
        style="border-left: 3px solid var(--v-divider-base) !important;"
      >
        <span
          class="d-flex text-h3 justify-start big_text text-left"
          style="line-height: 1.05"
        >
          {{ cider.kategorie.name }}
        </span>
        <span
          class="d-flex text-h5 justify-start pt-3 mr-auto"
          style="line-height: 1.05; color: #505050"
        >
          Ranking
        </span>
      </div>
    </v-theme-provider>
  </div>
</template>

<script>
import logos_mixin from "@/components/mixins/logos_mixin";
import cider_mixin from "@/components/mixins/cider_mixin";
import {apiTyped} from "@/api";

export default {
  name: "RanglisteHeader",
  mixins: [logos_mixin, cider_mixin],
  props: {
    cider: {type: Object, required: true, default: () => null},
  },
  computed: {
    logoUrl () {
      const logoUrl = this.getLogoUrl(this.$store.getters['usersStore/currentTasting']().logoMesse, 500, true);
      console.log("Logo Url", logoUrl)
      return logoUrl;
    },
  },
  methods: {
    getLogoUrl(id, width) {
      return apiTyped.getImgUrl(id, width);
    },
  }
}
</script>

<style scoped>
.big_text {
  color: black !important;
  text-decoration: underline var(--v-logo_green-base);
  text-underline-offset: 10%;
  text-decoration-thickness: 3px;
}
</style>