<template>
  <v-card
    class="text-left"
    style="max-width: 100vw !important; overflow: auto !important; min-width: min(100vw, 400px);"
    @click.native.stop=""
  >
    <v-list style="overflow-x: auto !important;">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ ciderPrepped.name }}</v-list-item-title>
          <v-list-item-subtitle class="d-flex align-center">
            <div class="d-flex align-center">
              <span>{{ ciderPrepped.land !== null ? cider.land.name : "--" }}</span>
              <country-flag
                class="elevation-2"
                :country="ciderPrepped.land !== null ? cider.land.code : undefined"
                size="normal"
              />
            </div>
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ ciderPrepped.produzent }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="$emit('menuClose')"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-divider style="background-color: var(--v-divider-base)" />
    <v-list style="max-height: 50vh !important; overflow: auto !important;">
      <v-list-item
        v-for="juror in table.juroren"
        :key="juror.id"
      >
        <v-list-item-avatar style="overflow: unset">
          <v-badge
            avatar
            overlap
            label=""
            style="z-index: 9"
            :color="showBadge(ciderPrepped.allPoints[juror.id]) ? 'white' : 'transparent'"
          >
            <template #badge>
              <v-avatar
                v-if="showBadge(ciderPrepped.allPoints[juror.id])"
                size="56"
                class="elevation-5"
              >
                <v-img :src="getBadgeUrl(100, ciderPrepped.allPoints[juror.id])" />
              </v-avatar>
              <!--                                <v-img-->
              <!--                                  v-if="showBadge(cider.allPoints[juror.id])"-->
              <!--                                  width="20"-->
              <!--                                  height="20"-->
              <!--                                  :src="getBadgeUrl(100, cider.allPoints[juror.id])"-->
              <!--                                />-->
            </template>
            <v-avatar
              size="40"
            >
              <v-img
                alt="Avatar"
                :src="getUserAvatar(200, juror)"
              />
            </v-avatar>
          </v-badge>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ juror.firstName }} {{ juror.lastName }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <div class="d-flex">
              <div class="d-flex">
                <v-icon
                  v-if="ciderPrepped.allNotes.hasOwnProperty(juror.id) && ciderPrepped.allNotes[juror.id].isFavourite"
                  small
                >
                  mdi-heart
                </v-icon>
                <v-icon
                  v-if="ciderPrepped.allNotes.hasOwnProperty(juror.id) && ciderPrepped.allNotes[juror.id].isPinned"
                  small
                >
                  mdi-bookmark
                </v-icon>
              </div>
              <v-tooltip top>
                <template #activator="{ on }">
                  <span v-on="on">
                    {{
                      ciderPrepped.allNotes.hasOwnProperty(juror.id)
                        ? ciderPrepped.allNotes[juror.id].kommentar
                        : ""
                    }}
                  </span>
                </template>
                <div
                  class="d-flex"
                  style="white-space: pre; text-align: left;"
                  v-html="ciderPrepped.allNotes.hasOwnProperty(juror.id)
                    ? ciderPrepped.allNotes[juror.id].kommentar
                    : ''"
                />
              </v-tooltip>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="d-flex justify-center align-center">
          <ChipPoints
            :points="ciderPrepped.allPoints[juror.id]"
          />
          <!--          <v-chip-->
          <!--            :color="$store.getters['badgesStore/getColor'](ciderPrepped.allPoints[juror.id])"-->
          <!--            :outlined="!showBadge(ciderPrepped.allPoints[juror.id])"-->
          <!--            label-->
          <!--            small-->
          <!--            style="margin:0 auto; min-width: 64px"-->
          <!--            class="pl-1 pr-1 justify-center ml-0 mr-0"-->
          <!--          >-->
          <!--            <v-icon small>-->
          <!--              mdi-star-->
          <!--            </v-icon>-->
          <!--            {{ ciderPrepped.allPoints[juror.id] }}-->
          <!--          </v-chip>-->
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import cider_mixin from "@/components/mixins/cider_mixin";
import user_mixin from "@/components/mixins/user_mixin";
import CountryFlag from "vue-country-flag";
import ChipPoints from "@/components/chip_points";

export default {
  name: "CiderMenu",
  components: {ChipPoints, CountryFlag},
  mixins: [cider_mixin, user_mixin],
  props: {
    show: {type: Boolean, required: true, default: false},
    cider: {type: Object, required: true, default: () => null},
    table: {type: Object, required: true, default: () => null},
  },
  data: () => ({
    show2: false,
  }),
  computed: {
    ciderPrepped() {
      const newCider = this.cider;
      newCider.allPoints = {};
      if (!(this.cider.allBewertungen == null)) {
        for (const jurorId of Object.keys(this.cider.allBewertungen)) {
          if (this.cider.allBewertungen[jurorId] == null) {
            newCider.allPoints[jurorId] = 0;
          } else {
            newCider.allPoints[jurorId] =
              this.$store.getters['ciderStore/getPointsFromBewertungen'](this.cider.allBewertungen[jurorId]);
          }
        }
      } else {
        newCider.allPoints = {};
        for (const juror of this.table.juroren) {
          newCider.allPoints[juror.id] = 0;
        }
      }
      if (newCider.land === null || newCider.land === undefined)
        newCider.land = {code: "--", name: "--"}
      if (newCider.allNotes === null || newCider.allNotes === undefined) {
        newCider.allNotes = {};
        for (const juror of this.table.juroren)
          newCider.allNotes[juror.id] = {isPinned: false, isFavourite: false, kommentar: ""};
      }
      return newCider;
    }
  },
  mounted() {
    console.log("Starting cider menu", this.cider, this.table, this.show)
  }
}
</script>

<style scoped>

</style>