<!--uses locales-->
<template>
  <v-container
    fluid
    class="pl-md-8 pr-md-8 pl-sm-2 pr-sm-2"
  >
    <PageNavigation
      :title-translation="$t('paymentHandling.submitProducts')"
      :breadcrumbs="[{text: $t('home'), to: '/producer/home'}, {text: $t('paymentHandling.submitProducts'), disabled: true}]"
    />

    <producer-submit-stepper
      :current-step="submitTastingSteps.selectCiders"
      :disable-next="selectedCiderIds.length < 1 || (selectExactAmount && selectedCiderIds.length !== maxSelection)"
    />
    <step-navigation
      :next-disabled="(!submitProductsWithoutData && selectedCiderIds.length < 1)
        || (selectExactAmount && selectedCiderIds.length !== maxSelection)
        || (submitProductsWithoutData && selectedProductAmount === 0)"
      :prev-disabled="false"
      @prev="$router.push(`/producer/submit/selectTastingProducts`)"
      @next="submitProductsWithoutData
        ? $router.push(`/producer/submit/summary`)
        : $router.push(`/producer/submit/confirmData`)"
    />

    <v-tooltip top>
      <template #activator="{ on }">
        <div v-on="on">
          <v-btn-toggle
            v-if="loaded && selectedTasting.tastingSettings.allowProductDataUpdate"
            v-model="selectProductsOrAmount"
            rounded
            text
            mandatory
            class="py-6"
            background-color="transparent"
            color="primary"
          >
            <v-btn
              :disabled="disablePlaceholderSelection"
              style="width: 50%"
              color="transparent"
              v-on="on"
            >
              <span class="mx-3">
                {{ $t('selectProducts') }}
              </span>
            </v-btn>
            <v-btn
              :disabled="disablePlaceholderSelection"
              style="width: 50%"
              color="transparent"
              v-on="on"
            >
              <span class="mx-3">
                {{ $t('chooseAmount') }}
              </span>
            </v-btn>
          </v-btn-toggle>
        </div>
      </template>
      <span>
        {{ $t('paymentHandling.selectCidersOnly', {amount: maxSelection, products: $tc('products', maxSelection), product: $tc('product', maxSelection)}) }}
      </span>
    </v-tooltip>

    <div
      v-if="selectProductsOrAmount === 1"
      class="mx-auto"
    >
      <v-row
        no-gutters
        class="d-flex align-baseline text-left"
        style="max-width: 2000px;"
      >
        <v-col
          cols="12"
          class="pl-md-4 pl-2"
        >
          <div class="d-flex text-h4 mb-0 mt-0 align-center">
            {{ $t('submitProductsWithoutData') }}
          </div>
          <div class="d-flex text-body-2 mb-4 mt-1 align-center text--secondary">
            {{ $t('submitProductsWithoutDataDescription', {deadline: selectedTasting.einreichenBis.toLocaleString()}) }}
          </div>
        </v-col>
      </v-row>

      <v-card
        outlined
        class="mx-auto mt-8"
        width="400"
      >
        <v-card-title>
          {{ $t('selectAmount') }}

        </v-card-title>
        <v-icon
          class="mx-auto"
          size="400"
        >
          mdi-bottle-wine
        </v-icon>
        <div class="py-3">
          <v-btn
            rounded
            outlined
            icon
            :disabled="selectedProductAmount === 1"
            @click="selectedProductAmount <= 2 ? selectedProductAmount = 1 : selectedProductAmount -= 1"
          >
            <v-icon>
              mdi-minus
            </v-icon>
          </v-btn>
          <span class="text-h6 px-3">
            {{ selectedProductAmount }}
          </span>
          <v-btn
            rounded
            outlined
            icon
            @click="selectedProductAmount += 1"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </v-card>
    </div>

    <div
      v-if="selectProductsOrAmount === 0"
      class="ml-auto mr-auto"
      style="max-width: 2000px;"
    >
      <v-row
        no-gutters
        class="d-flex align-baseline"
        style="max-width: 2000px;"
      >
        <v-col
          cols="12"
          xs="6"
          sm="6"
          md="6"
          class="pl-md-4 pl-2"
        >
          <div class="d-flex text-h4 mb-0 mt-0 align-center">
            {{ $t('paymentHandling.myProducts') }}
          </div>
          <div
            v-if="tastingPurchase != null && tastingPurchase.status === 'paid'"
            class="d-flex text-left text-body-2 mb-4 mt-1 align-center text--secondary"
          >
            {{ $t('paymentHandling.selectCidersOnly', {amount: maxSelection, products: $tc('products', maxSelection), product: $tc('product', maxSelection)}) }}
          </div>
          <div class="d-flex text-body-2 mb-4 mt-1 align-center text--secondary">
            {{ maxSelection != null
              ? $t('paymentHandling.myProductsSelectMax', {maxProducts: maxSelection})
              : $t('paymentHandling.myProductsSelect') }}
          </div>
        </v-col>
        <v-col
          cols="12"
          xs="6"
          sm="6"
          md="6"
        >
          <v-card-subtitle class="text-h5 text-right d-flex justify-end align-baseline">
            {{ selectedCiders.length }} {{ maxSelection !== null ? ` / ${maxSelection} ` : "" }} {{ $t('paymentHandling.xOfYSelected') }}

            <v-btn
              icon
              class="ml-2"
              @click="selectedCiderIds = []"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-subtitle>
        </v-col>
      </v-row>


      <v-row
        class="mb-2 mt-0 pt-0 px-md-4 px-2 align-center ml-auto mr-auto"
        style="max-width: 2000px;"
      >
        <v-col
          class="pa-0 pt-2 pb-2"
          cols="auto"
        >
          <div
            class="mr-auto"
          >
            <v-btn
              rounded
              color="primary"
              elevation="0"
              class="mr-4"
              @click="$router.push({ path: '/producer/ciders/create',
                                     query: { from: $router.currentRoute.path, back: 'backToSubmitProcess' } })"
            >
              <v-icon
                left
                size="28"
              >
                mdi-plus
              </v-icon>
              {{ $t('addProduct') }}
            </v-btn>
            <v-menu
              v-if="selectProductsOrAmount === 0 && ciders != null && ciders.length > 0"
              offset-y
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  style="text-transform: unset;"
                  text
                  rounded
                  class="pr-0"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>
                    mdi-sort-variant
                  </v-icon>
                  {{ sortByText }}
                  <v-btn
                    class="ml-1 animate_change"
                    icon
                    :class="{rotate_180: !sortAsc}"
                    @click="sortAsc = !sortAsc"
                    @click.native.stop=""
                  >
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>
                </v-btn>
              </template>
              <v-list>
                <v-subheader>
                  {{ $t('sortBy') }}
                </v-subheader>
                <v-list-item
                  v-for="(item, index) in sortByList"
                  :key="index"
                  link
                  @click="sortBy = item.value; sortByText = item.text;"
                >
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
        <v-col
          v-if="selectProductsOrAmount === 0 && ciders != null && ciders.length > 0"
          class="pa-0 pt-2 pb-2"
        >
          <v-text-field
            v-model="searchProducts"
            append-icon="mdi-magnify"
            :label="$t('search')"
            outlined
            clearable
            dense
            rounded
            single-line
            hide-details
            class="ml-auto"
            style="max-width: 400px; min-width: 300px;"
          />
        </v-col>
      </v-row>

      <template
        v-if="loaded"
      >
        <v-item-group
          v-model="selectedCiderIds"
          active-class=""
          multiple
          :max="selectedTasting.tastingSettings.maxCiders !== null ? selectedTasting.tastingSettings.maxCiders : undefined"
        >
          <v-row no-gutters>
            <v-col
              v-for="cider in cidersFiltered"
              :key="cider.id"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
              class="pb-3 pt-3 pl-0 pr-0 pa-sm-3 align-self-stretch align-stretch"
              style="cursor: pointer;"
            >
              <v-hover
                v-slot="{ hover }"
                style="height: 100%;"
              >
                <v-item
                  v-slot="{ active, toggle }"
                  :value="cider.id"
                  style="height: 100%;"
                >
                  <div
                    style="height: 100%;"
                    @click="selectCider(toggle, cider)"
                  >
                    <cider-card
                      :style="{'opacity': submittedCiders.some(c => c.cider === cider.id) ? 0.6 : 1}"
                      :cider="cider"
                      :selected="active"
                      class="ma-4"
                      style="max-width: 100%; height: 100%;"
                      :elevation="hover ? 10 : 1"
                    />
                  </div>
                </v-item>
              </v-hover>
            </v-col>
          </v-row>
        </v-item-group>
        <step-navigation
          v-if="loaded"
          :next-disabled="(!submitProductsWithoutData && selectedCiderIds.length < 1)
            || (selectExactAmount && selectedCiderIds.length !== maxSelection)
            || (submitProductsWithoutData && selectedProductAmount === 0)"
          :prev-disabled="false"
          class="pt-3 pb-3"
          @prev="$router.push(`/producer/submit/tastings`)"
          @next="$router.push(`/producer/submit/confirmData`)"
        />
      </template>
      <v-progress-circular
        v-else
        indeterminate
        class="mt-auto mb-auto ml-auto mr-auto"
        size="92"
      />
      <Confirm_dialog ref="confirm" />
    </div>
    <v-snackbar
      v-model="showAlreadySubmittedSnackbar"
      color="secondary_card"
      rounded="xl"
      :multi-line="true"
      bottom
      style="margin-top: 108px"
    >
      <span class="text-body-2">
        {{ $t("alreadySubmitted") }}
      </span>
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          rounded
          v-bind="attrs"
          @click="showAlreadySubmittedSnackbar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CiderCard from "@/components/cider_card";
import {apiTyped} from "@/api";
import ProducerSubmitStepper from "@/components/producer/producer_submit_stepper";
import popup_mixin from "@/components/mixins/popup_mixin";
import {sync, get} from "vuex-pathify";
import StepNavigation from "@/components/producer/step_navigation";
import Confirm_dialog from "@/components/confirm_dialog";
import producer_submit_mixin from "@/components/mixins/producer_submit_mixin";
import PageNavigation from "@/components/page_navigation";
import {SubmitTastingStep} from "@/types/submitTastingStep";
import tasting_products_mixin from "@/components/mixins/tasting_products_mixin";

export default {
  name: "ProducerSubmitSelectCider",
  components: {PageNavigation, Confirm_dialog, StepNavigation, ProducerSubmitStepper, CiderCard},
  mixins: [producer_submit_mixin, tasting_products_mixin],
  async beforeRouteLeave(to, from, next) {
    if (this.matchesWhitelist(to.path)) next();
    const okayToLeave = await this.$refs.confirm.open(
      this.$t('unsavedChanges'), this.$t('unsavedChangesMessageProducerSubmit'),
      { color: 'secondary_card', thirdButton: true, thirdButtonText: this.$t('discard') }, {btnOkay: 'stay'}, true)
    if (okayToLeave === "third") next();
  },
  i18n: {
    messages: {
      de: {
        "alreadySubmitted": "Dieses Produkt ist bereits angemeldet. Um die Daten zu ändern, navigieren Sie zur Event-Übersicht",
      },
      en: {
        "alreadySubmitted": "This product is already registered. To change its data, navigate to the event overview",
      }
    }
  },
  data: () => ({
    showAlreadySubmittedSnackbar: false,
    selectProductsOrAmount: 0,
    loaded: false,
    ciders: null,
    sortAsc: true,
    sortBy: 'name',
    sortByText: '',
    searchProducts: '',
    disablePlaceholderSelection: false,
    purchasedAmount: null,
    submittedCiders: [],
  }),
  computed: {
    submitTastingSteps() {
      return SubmitTastingStep
    },
    selectExactAmount() {
      return (this.selectedTasting.tastingSettings.allowProductDataUpdate
          && this.tastingPurchase != null
          && this.tastingPurchase.status === 'paid')
        || this.selectedTasting.tastingSettings.submissionNeedsPayment;
    },
    selectedTasting: get('submitStore/selectedTasting'),
    selectedCiders: sync('submitStore/selectedCiders'),
    selectedTastingProducts: sync('submitStore/selectedTastingProducts'),
    tastingPurchase: sync('submitStore/tastingPurchase'),
    selectedProductAmount: sync('submitStore/selectedProductAmount'),
    submitProductsWithoutData: sync('submitStore/submitProductsWithoutData'),
    confirmedCiderIds: sync('submitStore/confirmedCiderIds'),
    selectedCiderIds: {
      get() {
        return this.selectedCiders != null ? this.selectedCiders.map(c => c.id) : [];
      },
      set(ids) {
        const oldSelectedIds = this.selectedCiders.map(c => c.id);
        const unselectedIds = oldSelectedIds.filter(oldId => !ids.includes(oldId));
        // console.log("Unselected cider ids:", unselectedIds)
        this.confirmedCiderIds = this.confirmedCiderIds.filter(id => !unselectedIds.includes(id));
        // console.log("confirmedCiderIds:", this.confirmedCiderIds)
        this.selectedCiders = this.ciders.filter(c => ids.includes(c.id));
      }
    },
    sortByList() {
      return [
        {
          text: this.$i18n.t('name'),
          value: 'name'
        },
        {
          text: this.$i18n.tc('category', 1),
          value: 'kategorie'
        },
        {
          text: this.$i18n.t('sortDateCreated'),
          value: 'dateCreated'
        },
        {
          text: this.$i18n.t('sortDateUpdated'),
          value: 'dateUpdated'
        }
      ];
    },
    maxSelection() {
      if (this.selectedTasting.tastingSettings.submissionNeedsPayment) {
        return this.$store.state.submitStore.numberOfPaidProducts;
      }
      if (this.selectedTasting.tastingSettings.allowProductDataUpdate && this.tastingPurchase != null && this.tastingPurchase.status === 'paid') {
        return this.tastingPurchase.submittedProductIds.length;
      }
      return this.selectedTasting.tastingSettings.maxCiders;
    },
    cidersFiltered() {
      if (this.sortBy === ""){
        const a = 1;
      }
      const search = this.searchProducts == null ? "" : this.searchProducts.toLowerCase();
      if (this.ciders == null) return null;
      let ciderSearched = this.ciders;
      if (search !== "") {
        ciderSearched = this.ciders.filter(
          c => c.ciderInfos.name.toLowerCase().includes(search)
            || c.ciderInfos.kategorie.toLowerCase().includes(search)
            || c.ciderInfos.salesDesignation.toLowerCase().includes(search)
            || c.ciderInfos.designationOfOrigin.toLowerCase().includes(search))
      }
      ciderSearched = ciderSearched.sort(
        (c1, c2) => {
          if (this.sortBy === "name") {
            console.log("this.sortBy", this.sortBy)
            if (!this.sortAsc) return c2.ciderInfos.name.localeCompare(c1.ciderInfos.name);
            return c1.ciderInfos.name.localeCompare(c2.ciderInfos.name);
          }
          if (this.sortBy === "kategorie") {
            if (!this.sortAsc) return c2.ciderInfos.kategorie.localeCompare(c1.ciderInfos.kategorie);
            return c1.ciderInfos.kategorie.localeCompare(c2.ciderInfos.kategorie);
          }
          if (this.sortBy === "dateUpdated") {
            if (!this.sortAsc) return c2.dateUpdated - c1.dateUpdated;
            return c1.dateUpdated - c2.dateUpdated;
          }
          if (this.sortBy === "dateCreated") {
            if (!this.sortAsc) return c2.dateCreated - c1.dateCreated;
            return c1.dateCreated - c2.dateCreated;
          }
        });
      return ciderSearched;
    },
  },
  watch: {
    selectProductsOrAmount: function (newValue) {
      if (!this.loaded) return;
      if (newValue === 0) this.submitProductsWithoutData = false;
      if (newValue === 1) this.submitProductsWithoutData = true;
    },
  },
  async created() {
    this.sortByText = this.$i18n.t('name');
    if (this.selectedTasting == null) await this.$router.push('/producer/submit/tastings')
    this.ciders = await apiTyped.producerGetAllCiders(this.$store.state.producerStore.producerId, 0, 1, -1);
    this.submittedCiders = await apiTyped.getSubmittedCidersForTasting(this.selectedTasting.id);
    if (this.submittedCiders == null) this.submittedCiders = [];

    // if (this.selectedCiderIds.length === 0) {  // restore selected for that tasting on startup only
    //   this.selectedCiderIds = this.submittedCiders.map(submittedCider => submittedCider.cider);
    // }

    if (this.ciders.length === 0) {
      this.selectProductsOrAmount = 1;
    } else if (this.submitProductsWithoutData) {
      console.log("Setting selectedProductAmount")
      this.selectProductsOrAmount = 1;
    }
    if (!this.selectedTasting.tastingSettings.allowProductDataUpdate) {
      this.selectProductsOrAmount = 0;  // option not available for tasting
    }

    if (this.selectedTasting.tastingSettings.hasPurchasables) {
      this.selectedTasting.products = await apiTyped.producerGetTastingProducts(this.selectedTasting.id);
      this.tastingPurchase = await apiTyped.producerGetTastingPurchaseById(this.tastingPurchaseId);
      if (this.tastingPurchase != null) {
        this.selectProductsOrAmount = 0;  // only select ciders
        this.disablePlaceholderSelection = this.tastingPurchase.status === 'paid';
        this.purchasedAmount = this.tastingPurchase.submittedProductIds.length;
        // const purchasedProductIds = this.tastingPurchase.purchasedProducts.map(p => p.tastingProduct.id);
        // this.selectedTastingProducts = purchasedProductIds.map(p => { return { id: p } } ).filter(
        //   p => this.tastingProducts.find(t => t.productId === p.id) === undefined);
      }
    }
    this.loaded = true;
  },
  methods: {
    selectCider(toggle, cider) {
      console.log("Toggle", cider)
      if (this.submittedCiders.some(c => c.cider === cider.id)) {
        this.showAlreadySubmittedSnackbar = true;
        return;
      }
      if (this.maxSelection == null) {
        toggle();
        return;
      }
      if (this.selectedCiders.length < this.maxSelection) {
        toggle();
        return;
      }
      if (this.selectedCiderIds.includes(cider.id)) {
        toggle();
      }
    }
  },
}
</script>

<style scoped>

</style>