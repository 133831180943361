<template>
  <v-container fluid>
    <PageNavigation
      :title-translation="currentTasting.name"
      :breadcrumbs="[{text: $t('NavJuror.allTastings'), to: allRoutes.tastings.my},
                     {text: $t('NavJuror.productOverview'),
                      to: makeTastingRoute(allRoutes.tastings.ciderList, currentTasting),
                      disabled: true}]"
      class="pb-6 pl-md-3 pl-0"
    />

    <AlertLoadingError
      v-if="error"
      :errors="errors"
    />

    <CiderRatingStepper
      id="progress-stepper"
      active-step="1"
    />

    <!--    <v-container-->
    <!--      class="d-flex flex-column justify-center"-->
    <!--      style="max-width: 1300px"-->
    <!--    >-->
    <!--      <span-->
    <!--        class="d-flex justify-start ml-4 mt-4 text-left text-h4"-->
    <!--        style="color: var(&#45;&#45;v-background_text-base)"-->
    <!--      >-->
    <!--        {{ $t('CiderTable.headerCurrentCider') }}-->
    <!--      </span>-->
    <!--      &lt;!&ndash;      <v-divider class="ml-4 mr-4" />&ndash;&gt;-->
    <!--    </v-container>-->
    <v-row
      justify="center"
      align="center"
      class="mx-auto"
    >
      <v-col
        class="justify-center"
        justify="center"
        align="center"
        style="min-width: min(600px, 90vw);"
      >
        <NextCiderCard
          id="nextCiderCard"
          class="ml-auto mr-auto justify-center"
          :my-width="600"
          :cider="nextCiderToRate"
          :first-cider="isFirstCider(nextCiderToRate)"
          :rating-type="'next'"
          :no-restarted-ciders="yetToRateCiders.length === 0 && startedNotFinishedCiders.length === 0"
          :is-chair="$store.getters['usersStore/isChair']()"
          @updateCider="updateCiderField"
        />
      </v-col>
    </v-row>

    <v-container
      v-if="yetToRateCiders.length > 0"
      class="d-flex flex-column justify-center"
      style="max-width: 1600px"
    >
      <span
        class="d-flex justify-start ml-0 mt-4 text-left text-h5"
        style="color: var(--v-background_text-base)"
      >
        <v-icon left>
          mdi-restart
        </v-icon>
        {{ $t('CiderTable.restartedCiders') }} ({{ yetToRateCiders.length }})
      </span>
      <!--      <v-divider class="ml-4 mr-4" />-->
    </v-container>
    <v-row
      v-show="yetToRateCiders.length > 0"
      id="masonry"
      v-masonry="'masonryRestartedCiders'"
      transition-duration="0.3s"
      justify="center"
      align="center"
      class="pa-0 ma-0 mx-auto d-flex justify-center align-center"
      style="max-width: 1600px;"
    >
      <v-col
        v-for="cider in yetToRateCiders"
        :key="`nextCiderCard_${cider.id}_${cider.displayId}`"
        cols="12"
        lg="3"
        md="4"
        offset-md="0"
        offset-sm="0"
        sm="6"
        xs="12"
        class="justify-center"
        justify="center"
        align="center"
        @load="$redrawVueMasonry('masonryRestartedCiders')"
      >
        <NextCiderCard
          class="ml-auto mr-auto mb-1 justify-center"
          :cider="cider"
          :no-restarted-ciders="yetToRateCiders.length === 0"
          :rating-type="'restarted'"
          :is-chair="$store.getters['usersStore/isChair']()"
          @updateCider="updateCiderField"
        />
      </v-col>
    </v-row>

    <v-container
      v-if="startedNotFinishedCiders.length > 0"
      class="d-flex flex-column justify-center"
      style="max-width: 1600px"
    >
      <span
        class="d-flex justify-start ml-0 mt-4 text-left text-h5"
        style="color: var(--v-background_text-base)"
      >
        <v-icon left>
          mdi-account-edit
        </v-icon>
        {{ $t('CiderTable.unfinishedCiders') }} ({{ startedNotFinishedCiders.length }})
      </span>
      <!--      <v-divider class="ml-4 mr-4" />-->
    </v-container>
    <v-row
      v-show="startedNotFinishedCiders.length > 0"
      id="masonry"
      v-masonry="'masonryUnfinishedCiders'"
      transition-duration="0.3s"
      justify="center"
      align="center"
      class="pa-0 ma-0 mx-auto"
      style="max-width: 1600px;"
    >
      <v-col
        v-for="cider in startedNotFinishedCiders"
        :key="`nextCiderCard_${cider.id}_${cider.displayId}`"
        cols="12"
        lg="3"
        md="4"
        offset-md="0"
        offset-sm="0"
        sm="12"
        xs="12"
        class="justify-center"
        justify="center"
        align="center"
        @load="$redrawVueMasonry('masonryUnfinishedCiders')"
      >
        <NextCiderCard
          :key="`nextCiderCard_${cider.id}_card`"
          class="justify-center"
          :cider="cider"
          :no-restarted-ciders="startedNotFinishedCiders.length === 0"
          :rating-type="'unfinished'"
          :is-chair="$store.getters['usersStore/isChair']()"
          @load="$redrawVueMasonry('masonryUnfinishedCiders')"
          @updateCider="updateCiderField"
        />
      </v-col>
    </v-row>

    <!--    <h1-->
    <!--      style="font-weight: normal"-->
    <!--      class="mb-1"-->
    <!--    >-->
    <!--      Überblick-->
    <!--    </h1>-->
    <!--    <v-container-->
    <!--      class="d-flex flex-column justify-center"-->
    <!--      style="max-width: 1600px"-->
    <!--    >-->
    <!--      <span-->
    <!--        class="d-flex justify-start ml-1 mt-4 text-left text-h4"-->
    <!--        style="color: var(&#45;&#45;v-background_text-base)"-->
    <!--      >-->
    <!--        {{ $t('CiderTable.headerAllCider') }}-->
    <!--      </span>-->
    <!--      &lt;!&ndash;      <v-divider class="ml-4 mr-4" />&ndash;&gt;-->
    <!--    </v-container>-->
    <v-container
      class="d-flex flex-column justify-center pl-4"
      style="max-width: 1600px"
    >
      <span
        class="d-flex justify-start ml-0 mt-4 text-left text-h5"
        style="color: var(--v-background_text-base)"
      >
        {{ $t('CiderTable.headerAllCider') }}
      </span>
    </v-container>

    <div
      id="cider-table"
      class="ma-auto pl-md-4 pr-md-4"
      style="max-width: 1600px"
    >
      <judges-cider-table
        :products="allCidersPrepped"
        :loading-products="loading_cider_list"
        :show-select="enableTableCheckboxes"
        initial-group-by="flightsSort"
        class="pb-4"
        @productsSelected="values => selectedProducts = values"
        @clickRow="data => ciderClick(data.item)"
        @clickRowContext="data => ciderClick(data.item, true)"
      >
        <template #leftButtons>
          <v-btn
            outlined
            rounded
            :ripple="selectedProducts.length > 1"
            class="pr-0"
            style="opacity: 0.8;"
            @click="compareProducts"
          >
            <v-icon
              v-if="!enableTableCheckboxes"
              left
            >
              mdi-star-box-multiple-outline
            </v-icon>
            <v-icon
              v-if="enableTableCheckboxes && selectedProducts.length > 1"
              left
            >
              mdi-check
            </v-icon>
            <span class="pr-3">
              {{ $t('compareProductResults') }}
            </span>
            <v-btn
              v-if="enableTableCheckboxes"
              icon
              style="margin-left: -8px;"
              @click="enableTableCheckboxes = !enableTableCheckboxes; selectedProducts = [];"
              @click.native.stop=""
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-btn>
        </template>
      </judges-cider-table>
      <!--      <v-card-->
      <!--        class="elevation-2"-->
      <!--        color="light_grey"-->
      <!--        :outlined="$vuetify.theme.dark"-->
      <!--      >-->
      <!--        <v-card-title class="pt-2 pb-0">-->
      <!--          <v-row-->
      <!--            align="baseline"-->
      <!--            class="ma-0"-->
      <!--          >-->
      <!--            <p class="ma-0">-->
      <!--              {{ $tc('cider', ciderList.length) }}-->
      <!--            </p>-->
      <!--            <v-spacer />-->
      <!--            <v-select -->
      <!--              v-model="selectedFlights"-->
      <!--              :items="getFlights"-->
      <!--              clearable-->
      <!--              style="max-width: 350px;"-->
      <!--              multiple-->
      <!--              class="ml-auto pl-6"-->
      <!--              :label="$tc('flight', 2)"-->
      <!--            >-->
      <!--              <template #prepend-item>-->
      <!--                <v-list-item-->
      <!--                  ripple-->
      <!--                  @click="toggleAllFlights"-->
      <!--                >-->
      <!--                  <v-list-item-action>-->
      <!--                    <v-icon :color="selectedFlights.length > 0 ? 'primary' : ''">-->
      <!--                      {{ icon }}-->
      <!--                    </v-icon>-->
      <!--                  </v-list-item-action>-->
      <!--                  <v-list-item-content>-->
      <!--                    <v-list-item-title>-->
      <!--                      {{ $t('selectAll') }}-->
      <!--                    </v-list-item-title>-->
      <!--                  </v-list-item-content>-->
      <!--                </v-list-item>-->
      <!--                <v-divider class="mt-2" />-->
      <!--              </template>-->
      <!--              <template #selection="{ item, index }">-->
      <!--                <v-chip-->
      <!--                  v-if="index === 0"-->
      <!--                  small-->
      <!--                >-->
      <!--                  <span>{{ selectedAllFlights ? "All Flights" : item }}</span>-->
      <!--                </v-chip>-->
      <!--                <span-->
      <!--                  v-if="index === 1 && !selectedAllFlights"-->
      <!--                  class="grey&#45;&#45;text caption"-->
      <!--                >-->
      <!--                  (+{{ selectedFlights.length - 1 }})-->
      <!--                </span>-->
      <!--                <span-->
      <!--                  v-if="index === 1 && selectedAllFlights"-->
      <!--                  class="grey&#45;&#45;text caption"-->
      <!--                >-->
      <!--                  ({{ selectedFlights.length }})-->
      <!--                </span>-->
      <!--              </template>-->
      <!--            </v-select>-->
      <!--            <v-spacer />-->
      <!--            <v-select-->
      <!--              id="groupByHeader"-->
      <!--              v-model="groupByHeader"-->
      <!--              :items="groupByHeaders"-->
      <!--              item-value="value"-->
      <!--              style="max-width: 350px;"-->
      <!--              class="ml-4"-->
      <!--              :item-text="item => item.text || item.myText"-->
      <!--              :label="$t('CiderTable.groupBy')"-->
      <!--            >-->
      <!--              <template #prepend-item>-->
      <!--                <v-list-item-->
      <!--                  ripple-->
      <!--                  @click="groupByHeader = null"-->
      <!--                >-->
      <!--                  <v-list-item-content>-->
      <!--                    <v-list-item-title>-->
      <!--                      {{ $t('none') }}-->
      <!--                    </v-list-item-title>-->
      <!--                  </v-list-item-content>-->
      <!--                </v-list-item>-->
      <!--                <v-divider class="mt-2" />-->
      <!--              </template>-->
      <!--            </v-select>-->

      <!--            <v-text-field-->
      <!--              v-model="search"-->
      <!--              class="mb-2 ml-4"-->
      <!--              append-icon="mdi-magnify"-->
      <!--              :label="$t('search')"-->
      <!--              single-line-->
      <!--              hide-details-->
      <!--            />-->

      <!--            <v-tooltip-->
      <!--              v-if="isSmallScreen"-->
      <!--              top-->
      <!--            >-->
      <!--              <template #activator="{ on }">-->
      <!--                <v-btn-->
      <!--                  icon-->
      <!--                  @click="isMobileTable = !isMobileTable"-->
      <!--                  v-on="on"-->
      <!--                >-->
      <!--                  <v-icon>-->
      <!--                    {{ isMobileTable ? 'mdi-table-row' : 'mdi-table-column' }}-->
      <!--                  </v-icon>-->
      <!--                </v-btn>-->
      <!--              </template>-->
      <!--              <span>{{ $t(isMobileTable ? 'CiderTable.toggleBigTable' : 'CiderTable.toggleSmallTable') }}</span>-->
      <!--            </v-tooltip>-->
      <!--          </v-row>-->
      <!--        </v-card-title>-->

      <!--        &lt;!&ndash;        <v-divider class="pb-1" />&ndash;&gt;-->

      <!--        <span class="d-flex ml-3 mt-1 justify-start text-left subtitle-1">-->
      <!--          {{ $t('CiderTable.legendRatingSteps') }}-->
      <!--        </span>-->
      <!--        <v-chip-group-->
      <!--          id="ciderStateChipGroup"-->
      <!--          v-model="statusFilter"-->
      <!--          column-->
      <!--          multiple-->
      <!--          class="ml-3 mr-3"-->
      <!--        >-->
      <!--          <template v-for="(ciderState, idx) in allCiderStates">-->
      <!--            <v-chip-->
      <!--              :key="ciderState[1]"-->
      <!--              small-->
      <!--              outlined-->
      <!--              filter-->
      <!--              class="mr-0"-->
      <!--            >-->
      <!--              <v-icon-->
      <!--                left-->
      <!--                small-->
      <!--                :color="$store.getters['ciderStore/getCiderStateIconColorByState'](ciderState[0], $vuetify)[1]"-->
      <!--              >-->
      <!--                {{ $store.getters["ciderStore/getCiderStateIconColorByState"](ciderState[0], $vuetify)[0] }}-->
      <!--              </v-icon>-->
      <!--              {{ ciderState[1] }}-->
      <!--            </v-chip>-->
      <!--            <v-icon-->
      <!--              v-if="idx < allCiderStates.length - 1"-->
      <!--              :key="idx"-->
      <!--              small-->
      <!--            >-->
      <!--              mdi-chevron-right-->
      <!--            </v-icon>-->
      <!--          </template>-->
      <!--        </v-chip-group>-->

      <!--        <v-divider class="mt-1" />-->

      <!--        <div class="d-flex justify-start pt-3 pb-3 px-3">-->
      <!--          <v-btn-->
      <!--            outlined-->
      <!--            rounded-->
      <!--            class="pr-0"-->
      <!--            style="opacity: 0.8;"-->
      <!--            @click="compareProducts"-->
      <!--          >-->
      <!--            <v-icon-->
      <!--              v-if="!enableTableCheckboxes"-->
      <!--              left-->
      <!--            >-->
      <!--              mdi-star-box-multiple-outline-->
      <!--            </v-icon>-->
      <!--            <v-icon-->
      <!--              v-if="enableTableCheckboxes && selectedProducts.length > 0"-->
      <!--              left-->
      <!--            >-->
      <!--              mdi-check-->
      <!--            </v-icon>-->
      <!--            <span class="pr-3">-->
      <!--              Compare Ratings-->
      <!--            </span>-->
      <!--            <v-btn-->
      <!--              v-if="enableTableCheckboxes"-->
      <!--              icon-->
      <!--              style="margin-left: -8px;"-->
      <!--              @click="enableTableCheckboxes = !enableTableCheckboxes; selectedProducts = [];"-->
      <!--              @click.native.stop=""-->
      <!--            >-->
      <!--              <v-icon>mdi-close</v-icon>-->
      <!--            </v-btn>-->
      <!--          </v-btn>-->
      <!--          <v-spacer />-->
      <!--          <v-btn-->
      <!--            outlined-->
      <!--            rounded-->
      <!--            :disabled="!canReveal"-->
      <!--            @click="isRevealed = !isRevealed"-->
      <!--          >-->
      <!--            <v-icon-->
      <!--              v-if="!isRevealed"-->
      <!--              left-->
      <!--            >-->
      <!--              mdi-curtains-->
      <!--            </v-icon>-->
      <!--            <v-icon-->
      <!--              v-if="isRevealed"-->
      <!--              left-->
      <!--            >-->
      <!--              mdi-curtains-closed-->
      <!--            </v-icon>-->
      <!--            <span v-if="!isRevealed">-->
      <!--              {{ $t('revealProducts') }}-->
      <!--            </span>-->
      <!--            <span v-if="isRevealed">-->
      <!--              {{ $t('hideProductInfos') }}-->
      <!--            </span>-->
      <!--          </v-btn>-->
      <!--          <v-tooltip-->
      <!--            top-->
      <!--          >-->
      <!--            <template #activator="{ on }">-->
      <!--              <v-icon-->
      <!--                class="pl-1"-->
      <!--                v-on="on"-->
      <!--              >-->
      <!--                mdi-information-->
      <!--              </v-icon>-->
      <!--            </template>-->
      <!--            <div-->
      <!--              style="max-width: 600px;"-->
      <!--            >-->
      <!--              <span>-->
      <!--                {{ $t('revealProductsInfo') }}-->
      <!--              </span>-->
      <!--            </div>-->
      <!--          </v-tooltip>-->
      <!--        </div>-->

      <!--        <v-divider class="pb-1" />-->

      <!--        <v-data-table-->
      <!--          v-if="myHeaders"-->
      <!--          v-model="selectedProducts"-->
      <!--          class="pb-3"-->
      <!--          :headers="myHeaders"-->
      <!--          :items="allCidersPrepped"-->
      <!--          :loading="loading_cider_list"-->
      <!--          :mobile-breakpoint="isMobileTable ? 600 : 1"-->
      <!--          :dense="smallTable"-->
      <!--          :group-by="groupByHeader"-->
      <!--          :show-select="enableTableCheckboxes"-->
      <!--          show-group-by-->
      <!--          multi-sort-->
      <!--          hide-default-footer-->
      <!--          loading-text="Loading Ciders... Please wait"-->
      <!--          item-key="flightsCidersId"-->
      <!--          :search="search"-->
      <!--          :disable-pagination="true"-->
      <!--          :group-desc.sync="group_desc"-->
      <!--          @click:row="ciderClick"-->
      <!--          @update:group-by="updateGroupBy"-->
      <!--        >-->
      <!--          &lt;!&ndash;            <template v-slot:header="props">&ndash;&gt;-->
      <!--          &lt;!&ndash;              <thead>&ndash;&gt;-->
      <!--          &lt;!&ndash;              <tr>&ndash;&gt;-->
      <!--          &lt;!&ndash;                <template v-for="header in props.props.headers">&ndash;&gt;-->
      <!--          &lt;!&ndash;                  <th :key="header.value" v-if="header.groupable">&ndash;&gt;-->
      <!--          &lt;!&ndash;                    <span>{{ header.text }}</span>&ndash;&gt;-->
      <!--          &lt;!&ndash;                    <v-btn @click.stop="props.on.sort(header.value)" icon><v-icon>mdi-heart</v-icon></v-btn>&ndash;&gt;-->
      <!--          &lt;!&ndash;                    <v-btn @click.stop="props.on.group(header.value)" icon><v-icon>mdi-heart</v-icon></v-btn>&ndash;&gt;-->
      <!--          &lt;!&ndash;                  </th>&ndash;&gt;-->
      <!--          &lt;!&ndash;                  <th :key="header.value" v-else>&ndash;&gt;-->
      <!--          &lt;!&ndash;                    <span>{{ header.text }}</span>&ndash;&gt;-->
      <!--          &lt;!&ndash;&lt;!&ndash;                    <v-btn @click.stop="props.on.sort(header.value)" icon><v-icon>mdi-heart</v-icon></v-btn>&ndash;&gt;&ndash;&gt;-->
      <!--          &lt;!&ndash;&lt;!&ndash;                    <v-btn @click.stop="props.on.group(header.value)" icon><v-icon>mdi-heart</v-icon></v-btn>&ndash;&gt;&ndash;&gt;-->
      <!--          &lt;!&ndash;                  </th>&ndash;&gt;-->
      <!--          &lt;!&ndash;                </template>&ndash;&gt;-->
      <!--          &lt;!&ndash;              </tr>&ndash;&gt;-->
      <!--          &lt;!&ndash;              </thead>&ndash;&gt;-->
      <!--          &lt;!&ndash;            </template>&ndash;&gt;-->

      <!--          &lt;!&ndash;            <template v-slot:header="props">&ndash;&gt;-->
      <!--          &lt;!&ndash;              <span class="font-weight-bold">&ndash;&gt;-->
      <!--          &lt;!&ndash;                Group {{props.groupIndex + 1}} - {{props.groupName}}&ndash;&gt;-->
      <!--          &lt;!&ndash;              </span>&ndash;&gt;-->
      <!--          &lt;!&ndash;            </template>&ndash;&gt;-->


      <!--          &lt;!&ndash;            <template v-slot:header.props.groupBy="{header}">&ndash;&gt;-->
      <!--          &lt;!&ndash;              <span class="font-weight-bold">&ndash;&gt;-->
      <!--          &lt;!&ndash;                {{header.props.groupBy.toString()}} asd&ndash;&gt;-->
      <!--          &lt;!&ndash;              </span>&ndash;&gt;-->
      <!--          &lt;!&ndash;            </template>&ndash;&gt;-->

      <!--          <template #group.header="{ group, groupBy, headers, toggle, remove, isOpen, items }">-->
      <!--            <td-->
      <!--              :colspan="headers.length"-->
      <!--              style="min-height: 48px !important; box-sizing: border-box; margin: 0 !important; position: relative; width: 100vw;"-->
      <!--              :style="{'background-color': $vuetify.theme.currentTheme.surface}"-->
      <!--            >-->
      <!--              <div class="d-flex flex-row align-center justify-start pl-4">-->
      <!--                <v-btn-->
      <!--                  :ref="group"-->
      <!--                  x-small-->
      <!--                  icon-->
      <!--                  @click="toggle"-->
      <!--                >-->
      <!--                  <transition name="fade">-->
      <!--                    <v-icon v-if="isOpen">-->
      <!--                      mdi-chevron-up-->
      <!--                    </v-icon>-->
      <!--                    <v-icon v-else>-->
      <!--                      mdi-chevron-down-->
      <!--                    </v-icon>-->
      <!--                  </transition>-->
      <!--                </v-btn>-->

      <!--                &lt;!&ndash; Custom Header Groups &ndash;&gt;-->
      <!--                <span-->
      <!--                  v-if="groupBy[0] === 'stateText'"-->
      <!--                  class="d-flex align-center mx-5 font-weight-bold"-->
      <!--                >-->
      <!--                  <v-btn-->
      <!--                    icon-->
      <!--                    small-->
      <!--                    class="d-flex animate_change d-flex"-->
      <!--                    @click.native.stop=""-->
      <!--                  >-->
      <!--                    <v-icon-->
      <!--                      class="d-flex align-center justify-center ma-auto"-->
      <!--                      style="display: inline-flex !important"-->
      <!--                      :color="getCiderStateIconColor(items[0])"-->
      <!--                    >-->
      <!--                      {{ items[0].stateIcon }}-->
      <!--                    </v-icon>-->
      <!--                  </v-btn>-->
      <!--                  {{ items[0].stateText }}-->
      <!--                </span>-->
      <!--                <span-->
      <!--                  v-else-if="groupBy[0] === 'land.name'"-->
      <!--                  class="d-flex align-center mx-5 font-weight-bold"-->
      <!--                >-->
      <!--                  <country-flag-->
      <!--                    class="elevation-2"-->
      <!--                    style="border-radius: 10px !important;"-->
      <!--                    :country="items[0].land.code"-->
      <!--                    size="normal"-->
      <!--                  />-->
      <!--                  {{ items[0].land.name }}-->
      <!--                </span>-->
      <!--                <span-->
      <!--                  v-else-if="groupBy[0] === 'badgeData.punkteMin'"-->
      <!--                  class="d-flex mx-5 font-weight-bold"-->
      <!--                >-->
      <!--                  &lt;!&ndash;                  <img&ndash;&gt;-->
      <!--                  &lt;!&ndash;                    alt="Badge"&ndash;&gt;-->
      <!--                  &lt;!&ndash;                    class="align-center animate_change mt-0"&ndash;&gt;-->
      <!--                  &lt;!&ndash;                    style="height: 35px; width: 35px;"&ndash;&gt;-->
      <!--                  &lt;!&ndash;                    :src="getBadgeUrl(300, items[0].totalPoints)"&ndash;&gt;-->
      <!--                  &lt;!&ndash;                  >&ndash;&gt;-->
      <!--                  <badge-->
      <!--                    v-if="items[0].badgeData != null"-->
      <!--                    :badge-data="items[0].badgeData"-->
      <!--                    :badge-width="35"-->
      <!--                    :show-none-badge="false"-->
      <!--                    :badge-resolution="300"-->
      <!--                  />-->
      <!--                  <span class="d-flex align-center ml-2">-->
      <!--                    {{ items[0].badgeData.punkteMin }} - {{ items[0].badgeData.punkteMax }} {{ $tc('point', 2) }}-->
      <!--                  </span>-->
      <!--                </span>-->
      <!--                <span-->
      <!--                  v-else-if="groupBy[0] === 'notes.isFavourite' && items[0].notes.isFavourite"-->
      <!--                  class="mx-5 font-weight-bold"-->
      <!--                >-->
      <!--                  <v-icon-->
      <!--                    class="d-flex align-center justify-center ma-auto"-->
      <!--                    style="display: inline-flex !important"-->
      <!--                  >-->
      <!--                    mdi-heart-->
      <!--                  </v-icon>-->
      <!--                  {{ $tc('favourite', 2) }}-->
      <!--                </span>-->
      <!--                <span-->
      <!--                  v-else-if="groupBy[0] === 'notes.isFavourite' && !items[0].notes.isFavourite"-->
      <!--                  class="mx-5 font-weight-bold"-->
      <!--                >-->
      <!--                  <v-icon-->
      <!--                    class="d-flex align-center justify-center ma-auto"-->
      <!--                    style="display: inline-flex !important"-->
      <!--                  >-->
      <!--                    mdi-heart-outline-->
      <!--                  </v-icon>-->
      <!--                  {{ $tc('notFavourite', 2) }}-->
      <!--                </span>-->
      <!--                <span-->
      <!--                  v-else-if="groupBy[0] === 'notes.isPinned' && items[0].notes.isPinned"-->
      <!--                  class="mx-5 font-weight-bold"-->
      <!--                >-->
      <!--                  <v-icon-->
      <!--                    class="d-flex align-center justify-center ma-auto"-->
      <!--                    style="display: inline-flex !important"-->
      <!--                  >-->
      <!--                    mdi-bookmark-->
      <!--                  </v-icon>-->
      <!--                  {{ $tc('bookmarked') }}-->
      <!--                </span>-->
      <!--                <span-->
      <!--                  v-else-if="groupBy[0] === 'notes.isPinned' && !items[0].notes.isPinned"-->
      <!--                  class="mx-5 font-weight-bold"-->
      <!--                >-->
      <!--                  <v-icon-->
      <!--                    class="d-flex align-center justify-center ma-auto"-->
      <!--                    style="display: inline-flex !important"-->
      <!--                  >-->
      <!--                    mdi-bookmark-outline-->
      <!--                  </v-icon>-->
      <!--                  {{ $tc('notBookmarked') }}-->
      <!--                </span>-->
      <!--                <span-->
      <!--                  v-else-->
      <!--                  class="mx-5 font-weight-bold"-->
      <!--                >{{ group }}</span>-->
      <!--                <v-btn-->
      <!--                  :ref="group"-->
      <!--                  x-small-->
      <!--                  fab-->
      <!--                  icon-->
      <!--                  @click="remove"-->
      <!--                >-->
      <!--                  <v-icon>mdi-close-circle</v-icon>-->
      <!--                </v-btn>-->
      <!--              </div>-->
      <!--            </td>-->
      <!--          </template>-->

      <!--          <template #item.stateText="{ item }">-->
      <!--            <v-tooltip right>-->
      <!--              <template #activator="{ on }">-->
      <!--                <v-btn-->
      <!--                  icon-->
      <!--                  small-->
      <!--                  class="animate_change d-flex"-->
      <!--                  @click.native.stop=""-->
      <!--                  v-on="on"-->
      <!--                >-->
      <!--                  <v-icon-->
      <!--                    class="d-flex align-center justify-center"-->
      <!--                    style="display: inline-flex !important"-->
      <!--                    :color="getCiderStateIconColor(item)"-->
      <!--                  >-->
      <!--                    {{ item.stateIcon }}-->
      <!--                  </v-icon>-->
      <!--                </v-btn>-->
      <!--              </template>-->
      <!--              <span>{{ item.stateText }}</span>-->
      <!--            </v-tooltip>-->
      <!--          </template>-->

      <!--          <template #item.displayId="{ item }">-->
      <!--            <div class="d-flex justify-center">-->
      <!--              {{ item.displayId }}-->
      <!--            </div>-->
      <!--          </template>-->
      <!--          <template #item.flightName="{ item }">-->
      <!--            <div class="d-flex justify-center">-->
      <!--              {{ item.flightName }}-->
      <!--            </div>-->
      <!--          </template>-->
      <!--          <template #item.kategorie.name="{ item }">-->
      <!--            <div class="d-flex justify-center">-->
      <!--              {{ item.kategorie.name }}-->
      <!--            </div>-->
      <!--          </template>-->
      <!--          <template #item.land="{ item }">-->
      <!--            <div-->
      <!--              v-if="item.land != null && item.land.code != null"-->
      <!--              class="d-flex justify-start align-center flex-shrink-0"-->
      <!--              style="gap: 0 !important;"-->
      <!--            >-->
      <!--              <country-flag-->
      <!--                class="elevation-2"-->
      <!--                style="border-radius: 10px !important;"-->
      <!--                :country="item.land.code"-->
      <!--                size="normal"-->
      <!--              />-->
      <!--              {{ item.land.name || item.land }}-->
      <!--            </div>-->
      <!--            <div-->
      <!--              v-if="item.land == null || item.land.code == null"-->
      <!--              class="d-flex justify-center align-baseline flex-shrink-0"-->
      <!--              style="gap: 0 !important;"-->
      <!--            >-->
      <!--              <p-->
      <!--                class="text-body-2 text&#45;&#45;secondary text-center mb-0"-->
      <!--              >-->
      <!--                {{ $t('notSpec') }}-->
      <!--              </p>-->
      <!--            </div>-->
      <!--          </template>-->

      <!--          <template #item.badgeData.punkteMin="{ item }">-->
      <!--            <div-->
      <!--              class="d-flex justify-center animate_change"-->
      <!--            >-->
      <!--              &lt;!&ndash;              <img&ndash;&gt;-->
      <!--              &lt;!&ndash;                alt="None"&ndash;&gt;-->
      <!--              &lt;!&ndash;                class="animate_change"&ndash;&gt;-->
      <!--              &lt;!&ndash;                style="height: 35px; width: 35px; margin: auto"&ndash;&gt;-->
      <!--              &lt;!&ndash;                :src="getBadgeUrl(300, item.totalPoints)"&ndash;&gt;-->
      <!--              &lt;!&ndash;              >&ndash;&gt;-->
      <!--              <badge-->
      <!--                v-if="item.badgeData != null"-->
      <!--                :badge-data="item.badgeData"-->
      <!--                :badge-width="35"-->
      <!--                :badge-shadow="false"-->
      <!--                :show-none-badge="false"-->
      <!--                :badge-resolution="300"-->
      <!--              />-->
      <!--            </div>-->
      <!--          </template>-->

      <!--          <template #item.awardBadge="{ item }">-->
      <!--            &lt;!&ndash;              <div class="img-circle img-circle&#45;&#45;size" v-bind:style="{Image: `url(${getBadgeIcon(item,300)})`, backgroundSize: 'cover', backgroundPosition: 'center' }"></div>&ndash;&gt;-->
      <!--            <div class="d-flex justify-center animate_change">-->
      <!--              <img-->
      <!--                alt="None"-->
      <!--                class="animate_change"-->
      <!--                style="height: 35px; width: 35px; margin: auto"-->
      <!--                :src="getBadgeIcon(item, 150)"-->
      <!--              >-->
      <!--            </div>-->
      <!--          </template>-->

      <!--          <template #item.notes.kommentar="{ item }">-->
      <!--            <div v-if="item.notes != null">-->
      <!--              {{ item.notes.kommentar }}-->
      <!--            </div>-->
      <!--            &lt;!&ndash;              <tr>&ndash;&gt;-->
      <!--            &lt;!&ndash;                <td class="truncate" >{{ item.kommentar}}</td>&ndash;&gt;-->
      <!--            &lt;!&ndash;              </tr>&ndash;&gt;-->
      <!--          </template>-->

      <!--          <template #item.totalPoints="{ item }">-->
      <!--            &lt;!&ndash;              <v-chip class="elevation-1" @click="cider_click(item)" :color="getColor(item.punkte)" dark text-color="black" >&ndash;&gt;-->
      <!--            &lt;!&ndash;                {{ item.punkte }}&ndash;&gt;-->
      <!--            &lt;!&ndash;              </v-chip>&ndash;&gt;-->
      <!--            <ChipPoints-->
      <!--              :points="$store.getters['ciderStore/userBewertungFinished'](item) ? item.totalPoints : 0"-->
      <!--              :dark-background="false"-->
      <!--            />-->
      <!--            &lt;!&ndash;            <v-chip&ndash;&gt;-->
      <!--            &lt;!&ndash;              label&ndash;&gt;-->
      <!--            &lt;!&ndash;              style="min-width: 70px; max-width: 70px; margin:0 auto"&ndash;&gt;-->
      <!--            &lt;!&ndash;              class="pl-1 pr-1 d-flex justify-center animate_change"&ndash;&gt;-->
      <!--            &lt;!&ndash;              :outlined="getColor(item.totalPoints) === null"&ndash;&gt;-->
      <!--            &lt;!&ndash;              :color="getColor(item.totalPoints) !== null ? getColor(item.totalPoints) : '#aaa'"&ndash;&gt;-->
      <!--            &lt;!&ndash;              dark&ndash;&gt;-->
      <!--            &lt;!&ndash;              :text-color="'#' + $store.getters['badgesStore/getTextColor'](&ndash;&gt;-->
      <!--            &lt;!&ndash;                item.totalPoints, $vuetify.theme.dark)"&ndash;&gt;-->
      <!--            &lt;!&ndash;              @click="ciderClick(item)"&ndash;&gt;-->
      <!--            &lt;!&ndash;            >&ndash;&gt;-->
      <!--            &lt;!&ndash;              <v-icon&ndash;&gt;-->
      <!--            &lt;!&ndash;                v-if="item.totalPoints > 0"&ndash;&gt;-->
      <!--            &lt;!&ndash;                small&ndash;&gt;-->
      <!--            &lt;!&ndash;                class=""&ndash;&gt;-->
      <!--            &lt;!&ndash;              >&ndash;&gt;-->
      <!--            &lt;!&ndash;                mdi-star&ndash;&gt;-->
      <!--            &lt;!&ndash;              </v-icon>&ndash;&gt;-->
      <!--            &lt;!&ndash;              {{ item.totalPoints }}&ndash;&gt;-->
      <!--            &lt;!&ndash;            </v-chip>&ndash;&gt;-->
      <!--          </template>-->

      <!--          <template #item.notes.isFavourite="{ item }">-->
      <!--            <v-btn-->
      <!--              class="animate_change"-->
      <!--              icon-->
      <!--              :disabled="!(tastingIsNotStarted || tastingIsRunning)"-->
      <!--              @click="favourite_item(item)"-->
      <!--            >-->
      <!--              <v-icon-->
      <!--                v-if="item.notes != null && item.notes.isFavourite"-->
      <!--                dark-->
      <!--              >-->
      <!--                mdi-heart-->
      <!--              </v-icon>-->
      <!--              <v-icon-->
      <!--                v-else-->
      <!--                dark-->
      <!--              >-->
      <!--                mdi-heart-outline-->
      <!--              </v-icon>-->
      <!--            </v-btn>-->
      <!--            <v-btn-->
      <!--              v-if="smallTable"-->
      <!--              icon-->
      <!--              @click="pinItem(item)"-->
      <!--            >-->
      <!--              <v-icon-->
      <!--                v-if="item.notes != null && item.notes.isPinned"-->
      <!--                dark-->
      <!--              >-->
      <!--                mdi-bookmark-->
      <!--              </v-icon>-->
      <!--              <v-icon-->
      <!--                v-else-->
      <!--                dark-->
      <!--              >-->
      <!--                mdi-bookmark-outline-->
      <!--              </v-icon>-->
      <!--            </v-btn>-->
      <!--          </template>-->
      <!--          <template-->
      <!--            v-if="!smallTable"-->
      <!--            #item.notes.isPinned="{ item }"-->
      <!--          >-->
      <!--            <v-btn-->
      <!--              icon-->
      <!--              :disabled="!(tastingIsNotStarted || tastingIsRunning)"-->
      <!--              @click="pinItem(item)"-->
      <!--            >-->
      <!--              <v-icon-->
      <!--                v-if="item.notes != null && item.notes.isPinned"-->
      <!--                dark-->
      <!--              >-->
      <!--                mdi-bookmark-->
      <!--              </v-icon>-->
      <!--              <v-icon-->
      <!--                v-else-->
      <!--                dark-->
      <!--              >-->
      <!--                mdi-bookmark-outline-->
      <!--              </v-icon>-->
      <!--            </v-btn>-->
      <!--          </template>-->
      <!--        </v-data-table>-->
      <!--      </v-card>-->
    </div>

    <v-snackbar
      v-model="showMissingCiderAlert"
      top
      right
      rounded="lg"
      color="warning"
    >
      <v-alert
        dismissible
        elevation="0"
        prominent
        type="warning"
        color="transparent"
        max-width="450"
        @input="showMissingCiderAlert = false;"
      >
        {{ $t('CiderTable.missingCider') }}
      </v-alert>
    </v-snackbar>

    <easter-egg-modal
      ref="easterEggModal"
    />
    <MyTour
      name="ciderTableTour"
      :steps="tourSteps"
      :options="$store.getters['tourStore/getTourOptions']()"
      :callbacks="$store.get('tourStore/callbacks')"
    />
  </v-container>
</template>

<script>
import {apiTyped} from "@/api"
import {sync} from "vuex-pathify"
import AlertLoadingError from "@/components/alert_loading_error";
import Vue from "vue";
import CiderRatingStepper from "@/components/cider_rating_stepper";
import {CurrentPage} from "@/stores/app";
import i18n from '@/i18n'
import {setIntervalAsync} from "set-interval-async/fixed";
import {clearIntervalAsync} from "set-interval-async";
import cider_mixin from "@/components/mixins/cider_mixin";
import CiderRatingState, {getStateNumbers} from "@/types/ciderRatingState";
import MyTour from "@/components/my_tour";
import locale_mixin from "@/components/mixins/locale_mixin";
import NextCiderCard from "@/components/next_cider_card";
import {VueMasonryPlugin} from "vue-masonry";
import logos_mixin from "@/components/mixins/logos_mixin";
import easter_egg_mixin from "@/components/mixins/easter_egg_mixin";
import EasterEggModal from "@/components/easter_egg_modal";
import rating_mixin from "@/components/mixins/rating_mixin";
import PageNavigation from "@/components/page_navigation";
import {allRoutes, makeRoute} from "@/router";
import user_mixin from "@/components/mixins/user_mixin";
import JudgesCiderTable from "@/components/judges_cider_table.vue";

Vue.use(VueMasonryPlugin)

export default Vue.extend({
  name: "CiderTable",
  components: {
    JudgesCiderTable,
    PageNavigation,
    EasterEggModal,
    NextCiderCard,
    MyTour,
    CiderRatingStepper,
    AlertLoadingError,
  },
  mixins: [cider_mixin, locale_mixin, logos_mixin, easter_egg_mixin, rating_mixin, user_mixin],
  data: function () {
    return {
      showMissingCiderAlert: false,
      isRevealed: false,
      enableTableCheckboxes: false,
      selectedProducts: [],
      allCidersPrepped: [],
      // selectedFlights: [],
      masonryRestartedCiders: null,
      masonryUnfinishedCiders: null,
      groupByHeader: null,
      // isMobileTable: false,
      groupByField: null,
      search: '',
      group_desc: false,
      info: null,
      // ciders: [],
      error: false,
      errors: [],
      statusFilter: [],
      icon_clicked: false,
      loading_cider_list: true,
      intervalUpdateData: null,
      std_cider_attributes: {
        id: 0,
        kategorie_name: "None",
        flight_name: "",
        land: "None",
        kommentar: "",
        award_string: "None",
        punkte: 0,
        is_favourite: false,
        is_pinned: false,
        profile: "",
        typ: "",
        t: null,
      },
      // header_pinned: this.headers.find(header => header.value === 'is_pinned'),
      tourSteps: [
        {
          target: '#progress-stepper',
          header: {
            title: this.$t("tourStepperTitle"),
          },
          content: this.$t('tourStepperMessage'),
          params: {
            enableScrolling: false,
          }
        },
        {
          target: '#nextCiderCard',
          header: {
            title: this.$t("tourNextCider"),
          },
          content: this.$t('tourNextCiderMessage'),
          params: {
            enableScrolling: false,
          }
        },
        {
          target: '#cider-table',
          header: {
            title: this.$t("tourCiderOverview"),
          },
          content: this.$t("tourCiderOverviewMessage"),
          params: {
            placement: 'top',
          }
        },
        {
          target: '#cider-table > div > div.v-data-table > div > table > thead',
          header: {
            title: this.$t('tourSortTable'),
          },
          content: this.$t('tourSortTableMessage'),
          params: {
            placement: 'top',
          }
        },
        // {
        //   target: '#cider-table > div > div.v-data-table > div > table > tbody > tr:nth-child(1)',
        //   header: {
        //     title: 'Groups',
        //   },
        //   content: `Die Gruppen können einzeln ein- und ausgeklappt werden und es kann die Gruppierung aufgehoben werden.`,
        //   params: {
        //     placement: 'top',
        //   }
        // },
        {
          target: '#cider-table > div > div.v-data-table > div > table > tbody > tr:nth-child(2) > td:nth-child(1) > button > span',
          header: {
            title: this.$t('tourStatus'),
          },
          content: this.$t('tourStatusMessage'),
          params: {
            placement: 'bottom',
          }
        },
        {
          target: ' #cider-table > div > div.v-data-table > div > table > tbody > tr:nth-child(2) > td:nth-child(8) > button',
          header: {
            title: this.$t('tourMarkCiders'),
          },
          content: this.$t('tourMarkCidersMessage'),
          params: {
            placement: 'left',
          }
        },
        {
          target: '#ciderStateChipGroup',
          header: {
            title: this.$t('tourRatingSteps'),
          },
          content: this.$t('tourRatingStepsMessage'),
          params: {
            placement: 'top',
          }
        },
        {
          target: '#appBarUserMenu',
          header: {
            title: this.$t('tourAppBarUser'),
          },
          before: type => new Promise((resolve, reject) =>  {
            this.$store.state.usersStore.showUserMenu = true;
            resolve()
          }),
          params: {
            placement: 'bottom',
            modifiers: [{name: 'offset',
              options: {
                offset: [350, 0]
              }}]
          },
          content: this.$t('tourAppBarUserMessage'),
        },
        {
          target: '#appBarTableFunctions',
          header: {
            title: this.$t('tourTischfunktionen'),
          },
          before: type => new Promise((resolve, reject) =>  {
            this.$store.state.usersStore.showUserMenu = false;
            this.$store.state.tableStore.showTableFunctions = true;
            resolve()
          }),
          params: {
            placement: 'bottom',
            modifiers: [{name: 'offset',
              options: {
                offset: [0, 120]
              }}]
          },
          content: this.$t('tourSupportMessage'),
        },
      ],
    }
  },
  watch: {
    selectedProducts: function (val, oldVal) {
      if (val.length > 25) {
        this.$nextTick(() => {
          this.selectedProducts = oldVal;
        })
      }
    }
  },
  i18n: {
    messages: {
      de: {
        "tourStepperTitle": "Bewertungsschritte",
        "tourStepperMessage": `Navigation zwischen:
                    <ul class="text-left">
                      <li>1) Übersicht</li>
                      <li>2) Einzelbewertung</li>
                      <li>3) Gesamtbewertung</li>
                    </ul>
                    Zeigt ebenfalls den Status der aktuellen Bewertung an.`,
        "tourNextCider": "Nächstes Produkt",
        "tourNextCiderMessage": `Zeigt das nächste zu bewertende Produkt an.<br>Kann ebenfalls vom chair angefordert werden.`,
        "tourCiderOverview": "Produkt-Übersicht",
        "tourCiderOverviewMessage": `Hier können die einzelnen Produkte zu Bewertung ausgewählt werden.<br> Klicken öffnet die individuelle Bewertungsansicht.`,
        "tourSortTable": "Zeilen Sortieren",
        "tourSortTableMessage": "Spalten auswählen um Einträge zu sortieren.",
        "tourStatus": "Status",
        "tourStatusMessage": `Der Bewertungsstatus der Produkte. Für Erklärung lange drücken.`,
        "tourMarkCiders": "Produkt markieren",
        "tourMarkCidersMessage": `Jedes Produkt kann als Favorit oder zum Vormerken markiert werden.`,
        "tourRatingSteps": 'Bewertungsschritte der Produkte',
        "tourRatingStepsMessage": `Die verschiedenen Bewertungsschritte des Produktes, in Reihenfolge. <br>Drücken, um Tabelle zu filtern.`,
        "tourAppBarUser": 'Juror Info und Einstellungen',
        "tourAppBarUserMessage": `Menü öffnen, um: <br>
Sprache zu wechseln, <br>
dieses Tutorial neu zu starten, <br>
sich abzumelden,<br>
oder den dunklen Modus zu aktivieren.`,
        "tourTischfunktionen": "Support",
        "tourSupportMessage": `Hier kann das nächste Produkt aus der Tabelle angefordert werden,<br>
bei fachlichen Fragen gemeldet werden <br>
und bei Fragen zur App Hilfe angefordert werden.`,
      },
      en: {
        "tourStepperTitle": "Evaluation Steps",
        "tourStepperMessage": `Navigation between:
                    <ul class="text-left">
                      <li>1) Overview</li>
                      <li>2) Individual Evaluation</li>
                      <li>3) Overall Evaluation</li>
                    </ul>
                    Also shows the status of the current evaluation.`,
        "tourNextCider": "Next Produkt",
        "tourNextCiderMessage": `Displays the next product to rate.<br>Can also be requested by the chair.`,
        "tourCiderOverview": "Product Overview",
        "tourCiderOverviewMessage": `Here the individual products can be selected for evaluation.<br> Click to open the individual evaluation view.`,
        "tourSortTable": "Sort rows",
        "tourSortTableMessage": "Select columns to sort entries.",
        "tourStatus": "Status",
        "tourStatusMessage": `The Evaluation-Status of the products. Long-Press for explanation.`,
        "tourMarkCiders": "Mark products",
        "tourMarkCidersMessage": `Each product can be marked as a favorite or bookmarked.`,
        "tourRatingSteps": 'Product Evaluation-Steps',
        "tourRatingStepsMessage": `The different Evaluation-Steps of the product, in order. <br>Select to filter the table.`,
        "tourAppBarUser": 'Juror Info und Settings',
        "tourAppBarUserMessage": `Open Menu to: <br>
Change Language, <br>
restart this tutorial, <br>
logout, <br>
or enable dark mode.`,
        "tourTischfunktionen": "Support",
        "tourSupportMessage": `Here the next product from the table may be requested,<br>
technical questions may be reported and <br>
help may be requested for questions about the App.`,
      }
    }
  },
  computed: {
    isMobileTable: sync('ciderTableMobileLayout'),
    currentTasting() {
      return this.$store.getters["usersStore/currentTasting"]();
    },
    canReveal() {
      return this.currentTasting.finished;
    },
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        default: return false
      }
    },
    nextCiderToRate() {
      return this.$store.getters["ciderStore/getCurrentCiderToRate"]();
    },
    yetToRateCiders() {
      const nextCiderToRateId = this.nextCiderToRate !== undefined ? this.nextCiderToRate.id : undefined;
      return this.$store.getters["ciderStore/getCidersYetToRate"]().filter(
        cider => cider.id !== nextCiderToRateId);
    },
    startedNotFinishedCiders() {
      const nextCiderToRateId = this.nextCiderToRate !== undefined ? this.nextCiderToRate.id : undefined;
      return this.$store.getters["ciderStore/getStartedNotFinishedCidersYetToRate"](this.ciderList).filter(
        cider => cider.id !== nextCiderToRateId);
    },
    groupByHeaders() {
      const groupH = this.allHeaders.filter(h => h.myGroupable === true);
      return groupH;
    },
    allHeaders() {
      return [
        {text: i18n.t('status'), value: 'stateText', align: 'center', sortable: true, groupable: false, myGroupable: true, width: 100, revealedInfo: false,
          filter: (value, search, cider) => {
            if (this.statusFilter.length === 0) return true
            return this.statusFilter.includes(this.$store.getters["ciderStore/getCiderRatingState"](cider));
          },
        },
        {text: i18n.t('nr'), value: 'displayId', align: 'center', sortable: true, groupable: false, width: 80, revealedInfo: false},
        {text: i18n.tc('flight', 2) + ' ', value: 'flightName', align: 'center', sortable: true, groupable: false, myGroupable: true, revealedInfo: false,
          filter: value => {
            if (this.selectedFlights.length === 0) return true
            return this.selectedFlights.includes(value);
          },
        },

        {text: i18n.t('productNameBrand'), value: 'name', align: 'center', sortable: false, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('salesDesignation'), value: 'salesDesignation', align: 'center', sortable: false, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('designationOfOrigin'), value: 'designationOfOrigin', align: 'center', sortable: false, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('producer'), value: 'produzent', align: 'center', sortable: false, groupable: false, myGroupable: false, revealedInfo: true},

        {text: i18n.tc('category', 1), value: 'kategorie.name', align: 'center', sortable: true, groupable: false, myGroupable: true, width: 170, revealedInfo: false},
        {text: i18n.tc('country', 1), value: 'land', align: 'center', sortable: true, groupable: false, myGroupable: true, width: 200, revealedInfo: false,
        },
        {text: i18n.tc('point', 2), value: 'totalPoints', align: 'center', sortable: true, groupable: false, width: 100, revealedInfo: false},
        {text: 'Award ', value: 'badgeData.punkteMin', align: 'center', sortable: true, groupable: false, myGroupable: true, width: 100, revealedInfo: false},
        {text: i18n.tc('comment', 1), value: 'notes.kommentar', align: 'center', sortable: true, groupable: false, width: 350, revealedInfo: false},

        {text: i18n.t('alcoholAbv'), value: 'alkohol', align: 'center', sortable: false, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('residualSugar'), value: 'residualSugar', align: 'center', sortable: false, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('productNameBrand'), value: 'residualSugarDescription', align: 'center', sortable: false, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('filtrationClarity'), value: 'filtrationClarity', align: 'center', sortable: false, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('pressure'), value: 'pressure', align: 'center', sortable: false, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('method'), value: 'method', align: 'center', sortable: false, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('vintage'), value: 'vintage', align: 'center', sortable: false, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('bottleContent'), value: 'bottleContent', align: 'center', sortable: false, groupable: false, myGroupable: false, revealedInfo: true},
        {text: i18n.t('retailPrice'), value: 'price', align: 'center', sortable: false, groupable: false, myGroupable: false, revealedInfo: true},

        {text: '', value: 'notes.isFavourite', align: 'center', sortable: false, groupable: false, myGroupable: true, width: 24, myText: i18n.tc('favourite', 1), revealedInfo: false},
        {text: '', value: 'notes.isPinned', align: 'center', sortable: false, groupable: false, myGroupable: true, width: 24, myText: i18n.tc('bookmarked', 1), revealedInfo: false},
      ]
    },
    selectedFlights: sync('tableStore/selectedFlights'),
    ciderList: sync('ciderStore/ciderList'),
    getFlights() {
      return [...new Set(this.ciderList.map(cider => cider.flightName))];
    },
    selectedAllFlights() {
      return this.selectedFlights.length === this.getFlights.length;
    },
    selectedSomeFlights() {
      return this.selectedFlights.length > 0 && !this.selectedAllFlights;
    },
    icon() {
      if (this.selectedAllFlights) return 'mdi-close-box'
      if (this.selectedSomeFlights) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    allCiderStates() {
      const stateNames = getStateNumbers();
      return stateNames.map(name => [name, this.$t(String(CiderRatingState[name]))]);
    },
    smallTable() {
      return this.isSmallScreen && this.isMobileTable;
    },
    myHeaders() {
      let filteredHeaders;
      if (!this.isRevealed) filteredHeaders = this.allHeaders.filter(header => header.revealedInfo === false);
      if (this.isRevealed) filteredHeaders = this.allHeaders;
      if (this.isMobileTable) {
        return filteredHeaders.filter(header => header.value !== 'notes.isPinned');
      }
      return filteredHeaders;
    },
  },
  async created() {
    if (this.$route.query.reload === "true") {
      console.log("Reloading page...")
      const queryParams = { ...this.$route.query };
      delete queryParams.reload;
      await this.$router.replace({ query: queryParams });
      window.location.reload();
    }
    window.addEventListener('keydown', this._keyListener);
    this.$store.state.appStore.currentPage = CurrentPage.ciderTable;
    this.$store.state.ciderStore.currentFlightsCidersId = undefined;
    try {
      await this.updateData(true);  // (we do not have to execute this again, since tasting layout does this beforehand)
      this.intervalUpdateData = setIntervalAsync(this.updateData, 10000);
      this.loading_cider_list = false
      this.$store.state.app_bar_loading = false;
      this.groupByHeader = "flightName";
      if (!this.$store.getters["usersStore/user"]().userSettings.tourTableComplete) {
        this.$tours['ciderTableTour'].start();
      }
      this.$redrawVueMasonry('masonryRestartedCiders');
      this.$redrawVueMasonry('masonryUnfinishedCiders');
      if (this.$route.query.missingCider === "true") {
        const queryParams = { ...this.$route.query };
        delete queryParams.missingCider;
        await this.$router.replace({ query: queryParams });
        this.showMissingCiderAlert = true;
      }
    } catch (error) {
      console.log("Error loading Cider List:", error);
      this.errors.push(JSON.stringify(error, Object.getOwnPropertyNames(error)));
      this.error = true;
    }
  },
  async beforeDestroy() {
    await clearIntervalAsync(this.intervalUpdateData);
    window.removeEventListener("keydown", this._keyListener);
  },
  methods: {
    async compareProducts() {
      if (this.enableTableCheckboxes && this.selectedProducts.length > 1) {
        this.enableTableCheckboxes = false;
        const path = this.makeTastingRoute(allRoutes.tastings.compare, this.currentTasting);
        await this.$router.push({path: path, query: { product: this.selectedProducts.map(c => c.flightsCidersId) }});
      } else {
        this.enableTableCheckboxes = true;
      }
    },
    updateCidersPrepped() {
      const newCiders = this.ciderList.map((cider, index) => {
        cider.uniqueId = index;
        cider.state = this.$store.getters["ciderStore/getCiderRatingState"](cider);
        cider.stateIcon = this.getCiderStateIcon(cider);
        cider.stateText = this.getCiderStateText(cider);
        // only show points if cider is finished by me
        // const points = this.calculateTotalPointsForCider(cider, false, true);
        cider.totalPoints = cider.points;
        cider.badgeData = this.$store.getters['badgesStore/getBadgeData'](cider.totalPoints);
        return cider;
      });
      this.allCidersPrepped = newCiders;
    },
    async updateData(fetchData = true) {
      if (fetchData) {
        const tastingFinished = this.getCurrentTasting.finished;
        const newData = await apiTyped.getFlightsCiders(
          this.ciderList.map(c => c.flightsCidersId), true, tastingFinished);
        for (const [index, flightsCiderData] of newData.entries()) {
          let cider = this.ciderList[index];
          if (cider.flightsCidersId !== flightsCiderData.id) {
            // for performance, if index access did not yield the correct cider find it in all ciders (ciderList)
            cider = this.ciderList.find(c => c.flightsCidersId === flightsCiderData.id);
          }
          if (cider == null) continue;
          cider.updateFlightsCidersData(
            flightsCiderData, this.$store.getters["usersStore/userId"](), null, true);
        }
      }
      this.$redrawVueMasonry('masonryRestartedCiders');
      this.$redrawVueMasonry('masonryUnfinishedCiders');
      window.setTimeout(() => {
        this.$redrawVueMasonry('masonryRestartedCiders');
        this.$redrawVueMasonry('masonryUnfinishedCiders');
      }, 500);
      this.updateCidersPrepped();
    },
    updateCiderField({ciderId, field, value}) {
      const updateCiderIdx = this.ciderList.findIndex(cider => String(cider.id) === String(ciderId))
      if (updateCiderIdx === undefined) return
      const updateCider = this.ciderList[updateCiderIdx];
      Vue.set(updateCider, field, value);
      Vue.set(this.ciderList, updateCiderIdx, updateCider);
    },
    toggleAllFlights() {
      this.$nextTick(() => {
        if (this.selectedAllFlights) {
          this.selectedFlights = []
        } else {
          this.selectedFlights = this.getFlights.slice()
        }
      });
    },
    ciderClick(cider, newTab = false) {
      if (!this.icon_clicked) {
        this.$store.state.ciderStore.currentFlightsCidersId = this.$store.getters["ciderStore/getCiderIdx"](cider)
        const path = makeRoute(
          this.makeTastingRoute(allRoutes.tastings.rating, this.currentTasting),
          { flightsCidersId: cider.flightsCidersId});
        if (newTab) {
          const route = this.$router.resolve({ path: path });
          console.log("pathpath", path, route, cider)
          window.open(path, '_blank');
        }
        else {
          this.$router.push(path);
        }
      }
      this.icon_clicked = false;
    },
    async createNotesIfNull(cider) {
      if (cider.notes == null) {
        await cider.createNotes(this.$store.getters["usersStore/userId"]());
      }
    },
    async favourite_item(item) {
      this.icon_clicked = true;
      await this.createNotesIfNull(item);
      item.notes.isFavourite = await apiTyped.updateNotesField(
        item.notes.id, item.id, 'is_favourite', !item.notes.isFavourite);
    },
    async pinItem(item) {
      this.icon_clicked = true;
      await this.createNotesIfNull(item);
      item.notes.isPinned = await apiTyped.updateNotesField(
        item.notes.id, item.id, 'is_pinned', !item.notes.isPinned);
    },
    getColor(points) {
      return (this.$store.getters["badgesStore/getColor"](points));
    },
    getBadgeIcon(item, width) {
      if (width === undefined) width = 200;
      let points = 0;
      if (!this.$store.getters['ciderStore/userBewertungFinished'](item)) points = 0;
      else points = this.$store.getters["ciderStore/getTotalPointsForCider"](item);
      return this.$store.getters["badgesStore/getBadgeIcon"](points, width);
    },
    updateGroupBy(group) {
      if (Array.isArray(group)) group = group[0]
      if (group === "notes.isFavourite" || group === "notes.isPinned") { this.group_desc = true; }
      if (group === 'land') group = 'land.name'
      if (group === 'badgeData' || group === 'badgeData.punkteMin') { group = 'badgeData.punkteMin'; this.group_desc = true; }
      this.groupByField = group;
      this.groupByHeader = group;
    },
    isFirstCider(cider) {
      if (cider !== undefined && cider !== null && cider.hasOwnProperty("id") && cider.hasOwnProperty("flightId")) {
        return (cider.id === this.ciderList[0].id && cider.flightId === this.ciderList[0].flightId);
      }
      return false;
    }
  },
});
</script>

<style scoped>
/*.truncate {*/
/*  max-width: 38vw;*/
/*  white-space: nowrap;*/
/*  text-overflow: ellipsis;*/
/*  overflow: hidden;*/
/*  display: inline-block;*/
/*}*/
/*.no_scrollbar {*/
/*  -ms-overflow-style: none;  !* Internet Explorer 10+ *!*/
/*  scrollbar-width: none;  !* Firefox *!*/
/*}*/
/*.no_scrollbar::-webkit-scrollbar {*/
/*  display: none;  !* Safari and Chrome *!*/
/*}*/
/deep/ .input-group label {
  text-overflow: unset;
}
/deep/ .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 36px !important;
}

/deep/ .v-data-table {
  background-color: var(--v-light_grey-base) !important;
}

.animate_change {
  transition:all 0.25s linear;
}

</style>
