<!--no translatable strings-->
<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    rounded="xl"
    @keydown.esc="cancel"
  >
    <v-card
      rounded="xl"
      color="background"
    >
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text
        v-show="!!message"
        class="pa-4 pt-0 text-left pl-6"
        style="max-height: 900px; overflow-y: auto"
        v-html="message"
      />
      <v-card-actions
        class="pt-0 d-flex"
        :class="{'flex-column-reverse': isSmallScreen}"
      >
        <v-btn
          v-if="options.thirdButton"
          color="error"
          text
          :width="isSmallScreen ? '100%' : ''"
          @click.native="third"
        >
          {{ options.thirdButtonText }}
        </v-btn>
        <v-spacer v-if="!isSmallScreen" />
        <v-btn
          color="grey"
          text
          :width="isSmallScreen ? '100%' : ''"
          @click.native="cancel"
        >
          {{ $t(buttonTexts.btnCancel) }}
        </v-btn>
        <v-btn
          color="error darken-1"
          text
          :style="{'margin-left': isSmallScreen ? '0 !important' : '',}"
          :width="isSmallScreen ? '100%' : ''"
          @click.native="agree"
        >
          {{ $t(buttonTexts.btnOkay) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 450,
      zIndex: 200,
      thirdButton: false,
      thirdButtonText: "",
    },
    buttonTexts: {
      btnOkay: 'delete',
      btnCancel: 'cancel',
    }
  }),
  computed:{
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true
        default:
          return false
      }
    }
  },
  methods: {
    open(title, message, options, buttonTexts = {}) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      this.buttonTexts = {...this.buttonTexts, ...buttonTexts}
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    },
    third() {
      this.resolve("third")
      this.dialog = false
    },
  },
}
</script>

<style>
.v-dialog {
  border-radius: 24px !important;
}
</style>