var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-auto mr-auto pl-3 pr-3",staticStyle:{"max-width":"2000px"}},[(_vm.openTastings !== null && _vm.loaded && _vm.openTastings.length > 0)?_c('div',{staticClass:"d-flex text-left pl-0 pb-4 text-h2 font-weight-medium align-center",staticStyle:{"font-size":"36px !important","font-family":"'Nunito Sans', serif !important"}},[_vm._v(" "+_vm._s(_vm.$t('availableTastings'))+" ")]):_vm._e(),(_vm.openTastings !== null && _vm.loaded && _vm.openTastings.length > 0)?[_c('v-row',_vm._l((_vm.openTastings),function(tasting){return _c('v-col',{key:tasting.id,attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('TastingCard',{attrs:{"tasting":tasting,"submitted-ciders":[],"elevation":hover ? 8 : 0,"show-more-info-btn":""}})]}}],null,true)})],1)}),1)]:_vm._e(),(_vm.openTastings !== null && _vm.loaded && _vm.openTastings.length > 0)?_c('div',{staticClass:"d-flex text-left pl-0 pt-4 pb-4 text-h2 font-weight-medium align-center",staticStyle:{"font-size":"36px !important","font-family":"'Nunito Sans', serif !important"}},[_vm._v(" "+_vm._s(_vm.$t('myEvents'))+" ")]):_vm._e(),(_vm.myTastings !== null && _vm.loaded && _vm.myTastings.length > 0)?[_c('v-row',{staticClass:"pb-8"},_vm._l((_vm.myTastings),function(tasting,index){return _c('v-col',{key:tasting.id,attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('TastingCard',{attrs:{"tasting":tasting,"submitted-ciders":_vm.submittedCiders[index],"elevation":hover ? 8 : 0,"show-cancel-submission":"","show-more-info-btn":""},on:{"cancelSubmission":function($event){return _vm.reloadTasting(index)}}})]}}],null,true)})],1)}),1)]:_vm._e(),(_vm.loaded && _vm.myTastings != null && _vm.myTastings.length === 0)?_c('v-container',{staticClass:"ml-auto mr-auto",staticStyle:{"position":"relative","min-height":"60vh"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"center-on-page pb-4",staticStyle:{"border-style":"dashed","border-width":"medium","cursor":"pointer","transition":"box-shadow 0.2s ease-in-out"},attrs:{"width":"300","outlined":"","elevation":hover ? 12 : 0},on:{"click":_vm.startCiderSubmit}},[_c('v-icon',{attrs:{"size":"192"}},[_vm._v(" mdi-basket-plus-outline ")]),_c('v-card-subtitle',{staticClass:"pb-2"},[_vm._v(" "+_vm._s(_vm.$t('noEventsSubmittedYet'))+" ")]),_c('v-card-actions',[_c('v-btn',{staticClass:"ml-auto mr-auto text-center",attrs:{"rounded":"","outlined":"","text":""}},[_vm._v(" "+_vm._s(_vm.$t('submitProduct'))+" ")])],1)],1)]}}],null,false,1661122418)})],1):_vm._e(),(_vm.upcomingTastings !== null && _vm.loaded && _vm.upcomingTastings.length > 0)?_c('div',{staticClass:"d-flex text-left pl-0 pb-4 text-h2 font-weight-medium align-center",staticStyle:{"font-size":"36px !important","font-family":"'Nunito Sans', serif !important"}},[_vm._v(" "+_vm._s(_vm.$t('upcomingTastings'))+" ")]):_vm._e(),(_vm.upcomingTastings !== null && _vm.loaded && _vm.upcomingTastings.length > 0)?[_c('v-row',_vm._l((_vm.upcomingTastings),function(tasting){return _c('v-col',{key:tasting.id,attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('TastingCard',{attrs:{"tasting":tasting,"submitted-ciders":[],"elevation":hover ? 8 : 0,"show-more-info-btn":""}})]}}],null,true)})],1)}),1)]:_vm._e(),(_vm.pastTastings !== null && _vm.loaded && _vm.pastTastings.length > 0)?_c('div',{staticClass:"d-flex text-left pl-0 pb-4 text-h2 font-weight-medium align-center",staticStyle:{"font-size":"36px !important","font-family":"'Nunito Sans', serif !important"}},[_vm._v(" "+_vm._s(_vm.$t('pastEvents'))+" ")]):_vm._e(),(_vm.pastTastings !== null && _vm.loaded && _vm.pastTastings.length > 0)?[_c('v-row',_vm._l((_vm.pastTastings),function(tasting){return _c('v-col',{key:tasting.id,attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('TastingCard',{attrs:{"tasting":tasting,"submitted-ciders":[],"elevation":hover ? 8 : 0,"show-more-info-btn":""}})]}}],null,true)})],1)}),1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }