<template>
  <div>
    <loading-circle
      v-if="!created"
      :long-loading="true"
      :loading-message="loadingMessage"
      :custom-image="currentTasting.logoMesse || null"
    />
    <div
      v-if="created"
    >
      <v-card
        rounded="0"
        color="surface"
        style="height: auto !important;"
        elevate-on-scroll
        elevation="4"
        class="d-flex pa-4 pb-2"
        :class="{'sticky-toolbar': stickyToolbar}"
      >
        <v-app-bar-nav-icon @click.stop="showNavigationDrawer = !showNavigationDrawer" />

        <div class="d-flex ml-auto mr-auto flex-wrap justify-center">
          <div
            v-for="(table, index) in tables"
            :key="table.id"
            class="pl-2 pr-2 pb-2"
          >
            <v-badge
              :value="getTableActions(table)"
              :content="getTableActions(table)"
              overlap
              color="error"
            >
              <v-btn
                elevation="0"
                min-width="4"
                style="aspect-ratio: 1/1;"
                color="secondary"
                @click="scrollToTable(index)"
              >
                {{ index + 1 }}
              </v-btn>
            </v-badge>
          </div>
        </div>

        <v-btn
          v-if="false"
          icon
          :disabled="!created"
          class="mr-2"
          @click="printCiderList"
        >
          <v-icon>
            mdi-printer
          </v-icon>
        </v-btn>

        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-cog
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <theme-menu />
                </v-list-item-action>
                <v-list-item-title>
                  Choose Theme
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-switch
                    v-model="autoCollapse"
                    color="primary"
                  ></v-switch>
                </v-list-item-action>
                <v-list-item-title class="text-wrap">
                  Auto Collapse Tables after 15s
                  <v-icon>
                    mdi-format-vertical-align-center
                  </v-icon>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-switch
                    v-model="stickyToolbar"
                    color="primary"
                  ></v-switch>
                </v-list-item-action>
                <v-list-item-title>
                  Sticky Toolbar
                  <v-icon>
                    mdi-format-vertical-align-top
                  </v-icon>
                </v-list-item-title>
              </v-list-item>
              <v-card-text class="pb-0 pl-6" style="font-size: 16px !important;">
                Refresh Table Data every <strong>{{ refreshEvery }}</strong> seconds
              </v-card-text>
              <v-list-item class="overflow-visible">
                <v-list-item-title class="overflow-visible">
                  <v-slider
                    v-model="refreshEvery"
                    step="10"
                    ticks
                    thumb-label
                    min="10"
                    max="120"
                  />
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

      </v-card>
      <v-card
        v-if="tables.length === 0"
        class="ml-auto mr-auto pt-16 mt-16 pb-4"
        width="300"
        outlined
        style="border-style: dashed; border-width: medium; transition: box-shadow 0.2s ease-in-out;"
      >
        <v-icon size="192">
          mdi-google-downasaur
        </v-icon>
        <v-card-subtitle class="pb-2 text-center text-subtitle-1">
          No Data for selected Tasting
        </v-card-subtitle>
      </v-card>
      <v-row
        v-masonry="'serviceTables'"
        class="ma-2"
        :horizontal-order="true"
        dense
      >
        <v-col
          v-for="(table, index) in tables"
          :key="table.id"
          cols="12"
          xs="12"
          sm="6"
          md="4"
          lg="3"
          @load="$redrawVueMasonry('serviceTables')"
        >
          <TableServiceCard
            :ref="`table${index}`"
            :table="table"
            :ciders="cidersByTable[index]"
            :number="index + 1"
            :auto-expand="autoCollapse"
            class="animate-shadow"
            :class="{'elevation-12': highlightedCards[index], 'thick-border': highlightedCards[index]}"
            @updateRequestedCider="updateRequestedCider"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {apiTyped} from "@/api";
import {get, sync} from "vuex-pathify";
import TableServiceCard from "@/components/service/table_service_card";
import {setIntervalAsync} from "set-interval-async/fixed";
import {clearIntervalAsync} from "set-interval-async";
import ThemeMenu from "@/components/theme_menu";
import LoadingCircle from "@/layouts/loading_circle";
import CiderRatingState from "@/types/ciderRatingState";
import service_mixin from "@/components/mixins/service_mixin";

export default {
  name: "TableView",
  components: {LoadingCircle, ThemeMenu, TableServiceCard},
  mixins: [service_mixin],
  data: () => ({
    created: false,
    tables: null,
    cidersByTable: null,
    kategorien: null,
    highlightedCards: [],
    menu: false,
    stickyToolbar: true,
    loadingMessage: '',
    scrollToAnimationTimeout: null,
    intervalUpdateData: null,
  }),
  computed: {
    currentTasting: get('serviceStore/currentTasting'),
    currentTastingId: get('serviceStore/currentTastingId'),
    showNavigationDrawer: sync("serviceStore/showNavigationDrawer"),
    autoCollapse: sync('serviceStore/autoCollapse'),
    refreshEvery: sync('serviceStore/refreshEvery'),
  },
  watch: {
    currentTastingId: async function () {
      this.created = false;
      await this.loadInitial();
      this.created = true;
    },
    refreshEvery: async function (val) {
      await this.clearInterval();
      this.setUpInterval(val);
    }
  },
  async beforeDestroy() {
    await this.clearInterval();
  },
  async created() {
    this.created = false;
    await this.loadInitial();
    this.setUpInterval(this.refreshEvery);
    this.created = true;
  },
  methods: {
    async printCiderList() {
      await this.$router.push('/service/print-tables');
    },
    async loadInitial() {
      this.loadingMessage = 'Loading Categories';
      this.kategorien = await apiTyped.getAllKategorien(this.currentTastingId);
      this.loadingMessage = 'Loading Tables';
      await this.loadTables();
      this.highlightedCards = new Array(this.tables.length).fill(false);
      this.loadingMessage = 'Loading Ciders';
      await this.loadCiders();
    },
    setUpInterval(seconds) {
      this.intervalUpdateData = setIntervalAsync(this.updateData, seconds * 1000);
    },
    async clearInterval() {
      if (this.intervalUpdateData != null) await clearIntervalAsync(this.intervalUpdateData);
    },
    async updateData() {
      await this.loadTables();
      await this.loadCiders();
    },
    scrollToTable(index) {
      this.highlightedCards.fill(false);
      Vue.set(this.highlightedCards, index, true);
      if (this.scrollToAnimationTimeout != null) window.clearTimeout(this.scrollToAnimationTimeout);
      this.$vuetify.goTo(this.$refs[`table${index}`][0], {duration: 100, offset: 96});
      this.scrollToAnimationTimeout = window.setTimeout(() => {Vue.set(this.highlightedCards, index, false);}, 1500);
    },
    getTableActions(table) {
      return table.requestedCiders || 0;
        // + table.restartedCiders
        // + table.nearlyFinishedCiders;
    },
    updateRequestedCider(cider) {
      cider.requestCider = false;
    }
  }
}
</script>

<style scoped>
.v-card > * {
  text-align: left;
}

.rotate-180 {
  -moz-transform:rotate(-180deg);
  -webkit-transform:rotate(-180deg);
  transform:rotate(-180deg);
}

/deep/ .v-alert__icon {
  margin-right: 0px !important;
}

/*/deep/ .v-progress-circular__overlay {*/
/*  transition: stroke-dashoffset 15s linear !important;*/
/*}*/

/deep/ .animate-progress > v-progress-circular__overlay {
  transition: stroke-dashoffset 15s linear !important;
}

.animate-shadow {
  transition: box-shadow 0.5s ease-in-out;
  transition: scale 0.2s linear !important;
}

.thick-border {
  scale: 1.06;
  z-index: 4;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.sticky-toolbar {
  position: sticky;
  top: 0;
  z-index: 5;
}
</style>