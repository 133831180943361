var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-4",staticStyle:{"overflow":"hidden"},attrs:{"min-width":"100","color":"light_grey","outlined":_vm.$vuetify.theme.dark}},[_c('v-card-title',{staticClass:"pt-3 pb-3 align-center"},[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('p',{staticClass:"ma-0 text-h5"},[_vm._v(" All Purchases ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"ml-auto",staticStyle:{"width":"200px","max-width":"400px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"outlined":"","clearable":"","dense":"","rounded":"","single-line":"","hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.loading)?_c('v-progress-circular',{staticClass:"mt-auto mb-auto ml-auto mr-auto",attrs:{"indeterminate":"","size":"24","width":"2"}}):_vm._e()]},proxy:true}]),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function (){}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-account-switch')}})],1),_c('v-list-item-title',[_vm._v(" Migrate existing Judges ")])],1)],1)],1)],1)],1),_c('v-data-table',{key:_vm.dataTableKey,staticStyle:{"overflow-y":"scroll"},attrs:{"items":_vm.purchases,"headers":_vm.purchasesHeaders,"loading":_vm.loading,"show-group-by":"","multi-sort":"","items-per-page":20,"footer-props":{'items-per-page-options': [10, 20, 50, 100, 250, 500]},"loading-text":"Loading Purchases... Please wait","item-key":"id","search":_vm.search,"fixed-header":"","height":"calc(100vh - 128px - 64px - 24px)","show-select":"","options":_vm.tableOptions,"server-items-length":_vm.totalItems},on:{"update:options":function($event){_vm.tableOptions=$event},"click:row":function (item) { return _vm.$router.push(("/admin/purchase/" + (item.id))); }},scopedSlots:_vm._u([{key:"item.menu",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.createInvoice(item)}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v(" mdi-file-document-outline ")])],1),_c('v-list-item-title',[_vm._v(" Create Invoice ")])],1),(item.publishedInvoice)?_c('v-list-item',{on:{"click":function($event){return _vm.sendInvoice(item)}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v(" mdi-send ")])],1),_c('v-list-item-title',[_vm._v(" Send Invoice ")])],1):_vm._e()],1)],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusColor(item.status),"small":"","text":""}},[_vm._v(" "+_vm._s(_vm.statusText(item.status))+" ")])]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatCurrency(item.totalAmount))+" ")])]}},{key:"item.subtotalAmount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatCurrency(item.subtotalAmount))+" ")])]}},{key:"item.publishedInvoiceSent",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"px-1",attrs:{"color":item.publishedInvoice != null && item.publishedInvoice.sent ? 'primary' : 'error',"small":"","text":""}},[(item.publishedInvoice != null && item.publishedInvoice.sent)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)]}},{key:"item.publishedInvoicePosted",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"px-1",attrs:{"color":item.publishedInvoice != null && item.publishedInvoice.posted ? 'primary' : 'error',"small":"","text":""}},[(item.publishedInvoice != null && item.publishedInvoice.posted)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)]}}])})],1),_c('v-snackbar',{attrs:{"rounded":"xl","color":_vm.statusMessageColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","rounded":""},on:{"click":function($event){_vm.showStatusMessage = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.showStatusMessage),callback:function ($$v) {_vm.showStatusMessage=$$v},expression:"showStatusMessage"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.statusMessageText)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }