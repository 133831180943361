var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-2 mb-0",attrs:{"width":"100%","outlined":"","elevation":"0"}},[(_vm.tableHeaders)?_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.ciders,"group-by":_vm.groupByHeader,"items-per-page":100,"footer-props":{'items-per-page-options': [10, 25, 50, 100], 'items-per-page-text': _vm.$t('rowsPerPage')},"show-group-by":"","multi-sort":"","loading-text":_vm.$t('loadingCidersWait'),"item-key":"id","search":_vm.searchProducts,"single-expand":"","expanded":_vm.expanded,"show-expand":false},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":function (item) { return _vm.$emit('clickProduct', item); }},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var groupBy = ref.groupBy;
var headers = ref.headers;
var toggle = ref.toggle;
var remove = ref.remove;
var isOpen = ref.isOpen;
var items = ref.items;
return [_c('td',{staticStyle:{"min-height":"48px !important","box-sizing":"border-box","margin":"0 !important","position":"relative","width":"100vw"},style:({'background-color': _vm.$vuetify.theme.currentTheme.surface}),attrs:{"colspan":headers.length}},[_c('div',{staticClass:"d-flex flex-row align-center justify-start pl-4"},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[_c('transition',{attrs:{"name":"fade"}},[(isOpen)?_c('v-icon',[_vm._v(" mdi-chevron-up ")]):_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1)],1),(groupBy[0] === 'ciderInfos.land.name')?_c('span',{staticClass:"d-flex align-center mx-5 font-weight-bold"},[(items[0].ciderInfos.land != null)?[_c('country-flag',{staticClass:"elevation-2",staticStyle:{"border-radius":"10px !important","min-width":"26px !important","overflow":"visible !important"},attrs:{"country":items[0].ciderInfos.land.code,"size":"normal"}}),_vm._v(" "+_vm._s(items[0].ciderInfos.land.name)+" ")]:_c('span',[_vm._v(" No Country ")])],2):_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(group))]),_c('v-btn',{ref:group,attrs:{"x-small":"","fab":"","icon":""},on:{"click":remove}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1)])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"elevation-0 text-left mb-2",attrs:{"colspan":headers.length}},[_c('div',[_c('v-badge',{attrs:{"content":item.revisions.length || '1',"inline":"","left":""}},[_vm._v(" "+_vm._s(_vm.$t('tastings'))+" ")]),_c('v-data-table')],1)])]}},{key:"item.ciderInfos.land.name",fn:function(ref){
var item = ref.item;
return [_c('TableCountryField',{attrs:{"item":item}})]}},{key:"item.ciderInfos.alkohol",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.ciderInfos.alkohol)+" %")])]}},{key:"item.ciderInfos.profil",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticStyle:{"max-width":"142px","text-overflow":"ellipsis","overflow":"hidden"}},[_c('span',_vm._g({staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},on),[_vm._v(" "+_vm._s(_vm.extractContent(item.ciderInfos.profil))+" ")])])]}}],null,true)},[_c('v-card-text',{staticClass:"text-left pa-0",domProps:{"innerHTML":_vm._s(item.ciderInfos.profil)}})],1)]}}],null,false,3125159890)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }