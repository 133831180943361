<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row
      v-if="!noCiders"
      class="mb-2 pt-2 align-center"
    >
      <v-col
        cols="auto"
      >
        <v-btn-toggle
          v-model="dataMode"
          mandatory
          rounded
          dense
          class="ml-auto pr-0"
        >
          <v-btn
            outlined
            height="40"
            value="cards"
          >
            <v-icon>mdi-view-grid</v-icon>
          </v-btn>
          <v-btn
            outlined
            height="40"
            value="table"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col
        v-if="dataMode === 'cards'"
        cols="auto"
      >
        <div
          class="mr-auto"
        >
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                style="text-transform: unset;"
                text
                rounded
                class="pr-0"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>
                  mdi-sort-variant
                </v-icon>
                {{ sortByText }}
                <v-btn
                  class="ml-1 animate_change"
                  icon
                  :class="{'rotate-180': !sortAsc}"
                  @click="sortAsc = !sortAsc"
                  @click.native.stop=""
                >
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </v-btn>
            </template>
            <v-list>
              <v-subheader>
                {{ $t('sortBy') }}
              </v-subheader>
              <v-list-item
                v-for="(item, index) in sortByList"
                :key="index"
                link
                @click="sortBy = item.value; sortByText = item.text;"
              >
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
      <v-col>
        <v-text-field
          v-model="searchProducts"
          append-icon="mdi-magnify"
          :label="$t('search')"
          outlined
          clearable
          dense
          rounded
          single-line
          hide-details
          class="ml-auto"
          style="max-width: 400px; min-width: 300px;"
        />
      </v-col>
    </v-row>

    <div
      v-if="dataMode === 'cards'"
      style="background-color: var(v--background-base);"
      class="pl-xl-8 pr-xl-8"
    >
      <v-row
        class="align-self-auto align-stretch"
      >
        <v-col
          v-for="cider in cidersFiltered"
          :key="cider.id"
          cols="12"
          xs="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
          class="align-self-stretch align-stretch"
          style="cursor: pointer;"
        >
          <v-hover
            v-slot="{ hover }"
          >
            <div
              style="height: 100%;"
              @click="$emit('clickProduct', cider)"
            >
              <cider-card
                :cider="cider"
                :full-content="false"
                style="max-width: 100%; height: 100%;"
                class="align-self-stretch"
                :elevation="hover ? 10 : 1"
              />
            </div>
          </v-hover>
        </v-col>
      </v-row>
    </div>

    <div
      v-if="dataMode === 'table' && !noCiders"
      class="d-flex"
    >
      <ProducerCiderTable
        :ciders="ciders"
        :search-products="searchProducts"
        @clickProduct="product => $emit('clickProduct', product)"
      />
    </div>

    <v-container
      v-if="loaded && noCiders"
      style="position: relative; min-height: 60vh"
    >
      <v-hover
        v-slot="{ hover }"
      >
        <v-card
          class="center-on-page pb-4 ml-auto mr-auto"
          width="300"
          outlined
          :elevation="hover ? 12 : 0"
          style="border-style: dashed; border-width: medium; cursor: pointer; transition: box-shadow 0.2s ease-in-out;"
          @click="$router.push('/producer/ciders/create')"
        >
          <v-icon size="192">
            mdi-plus
          </v-icon>
          <v-card-subtitle class="pb-2">
            {{ $t('noProducts') }}
          </v-card-subtitle>
          <v-btn
            class="ml-auto mr-auto text-center"
            rounded
            elevation="0"
            outlined
            color="primary"
          >
            {{ $t('addProduct') }}
          </v-btn>
        </v-card>
      </v-hover>
    </v-container>

    <v-expand-transition>
      <v-btn
        v-show="loaded && moreProductsAvailable"
        v-intersect="onLoadingButtonIntersect"
        text
        large
        outlined
        :loading="loadingMoreProducts"
        class="mt-4 mb-4 text-capitalize"
        @click="loadMoreProducts"
      >
        {{ $t('loadMore') }}
      </v-btn>
    </v-expand-transition>

    <div
      v-if="ciders != null && loaded"
      class="d-flex justify-center pt-4"
    >
      <span class="text--secondary text-body-2 pt-2 pb-4">
        {{ cidersFiltered.length }} {{ moreProductsAvailable ? '' : '/' + ciders.length }} {{ $tc('products', 2) }} {{ $t('areDisplayed') }}
      </span>
    </div>
  </v-container>
</template>

<script>
import CiderCard from "@/components/cider_card.vue";
import ProducerCiderTable from "@/components/producer/producer_cider_table.vue";
import {apiTyped} from "@/api";

export default {
  name: "ProducerAllProducts",
  components: {ProducerCiderTable, CiderCard},
  model: {
    prop: 'ciders',
    event: 'update:ciders'
  },
  props: {
    ciders: {type: Array, required: true, default: null},
    moreProductsAvailable: {type: Boolean, required: true, default: true},
    loadingMoreProducts: {type: Boolean, required: true, default: false},
    loaded: {type: Boolean, required: true, default: false},
  },
  data: () => ({
    sortAsc: true,
    sortBy: 'name',
    sortByText: '',
    searchProducts: '',
    dataMode: "cards",
    currentProductsPage: 1,
  }),
  computed: {
    cidersFiltered() {
      if (this.sortBy === ""){
        const a = 1;
      }
      const search = this.searchProducts == null ? "" : this.searchProducts.toLowerCase();
      if (this.ciders == null) return null;
      let ciderSearched = this.ciders;
      console.log("Srat", search, this.sortBy)
      if (search !== "") {
        ciderSearched = this.ciders.filter(
          c => c.ciderInfos.name.toLowerCase().includes(search)
            || c.ciderInfos.kategorie.toLowerCase().includes(search)
            || c.ciderInfos.salesDesignation.toLowerCase().includes(search)
            || c.ciderInfos.designationOfOrigin.toLowerCase().includes(search))
      }
      ciderSearched = ciderSearched.sort(
        (c1, c2) => {
          if (this.sortBy === "name") {
            console.log("this.sortBy", this.sortBy)
            if (!this.sortAsc) return c2.ciderInfos.name.localeCompare(c1.ciderInfos.name);
            return c1.ciderInfos.name.localeCompare(c2.ciderInfos.name);
          }
          if (this.sortBy === "kategorie") {
            if (!this.sortAsc) return c2.ciderInfos.kategorie.localeCompare(c1.ciderInfos.kategorie);
            return c1.ciderInfos.kategorie.localeCompare(c2.ciderInfos.kategorie);
          }
          if (this.sortBy === "dateUpdated") {
            if (!this.sortAsc) return c2.dateUpdated - c1.dateUpdated;
            return c1.dateUpdated - c2.dateUpdated;
          }
          if (this.sortBy === "dateCreated") {
            if (!this.sortAsc) return c2.dateCreated - c1.dateCreated;
            return c1.dateCreated - c2.dateCreated;
          }
        });
      return ciderSearched;
    },
    noCiders() {
      return (this.ciders === null || this.ciders.length === 0);
    },
    sortByList() {
      return [
        {
          text: this.$i18n.t('name'),
          value: 'name'
        },
        {
          text: this.$i18n.tc('category', 1),
          value: 'kategorie'
        },
        {
          text: this.$i18n.t('sortDateCreated'),
          value: 'dateCreated'
        },
        {
          text: this.$i18n.t('sortDateUpdated'),
          value: 'dateUpdated'
        }
      ];
    },
  },
  async created() {
    this.sortByText = this.$i18n.t('name');
    this.dataMode = "cards";
  },
  methods: {
    startCiderSubmit() {
      this.$store.commit("submitStore/clear");
      this.$router.push('/producer/submit/tastings');
    },
    onLoadingButtonIntersect() {
      console.log("On intersect button")
      if (!this.loaded || this.loadingMoreProducts) return;
      this.loadMoreProducts();
    },
    loadMoreProducts() {
      if (!this.moreProductsAvailable) return;
      this.$emit('loadMore');
    },
  }
}
</script>

<style scoped>
.rotate-180 {
    -moz-transform:rotate(-180deg);
    -webkit-transform:rotate(-180deg);
    transform:rotate(-180deg);
}
</style>