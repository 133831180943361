import Vue from 'vue';
import Vuex from 'vuex';
import {make} from 'vuex-pathify';
import {CurrentPage} from "@/stores/app";
import {apiTyped} from "@/api";
import {rootState} from "@/stores";
import i18n from "@/i18n";
import store from "@/stores"

Vue.use(Vuex);


// type storeStates = typeof state;
// // @ts-ignore
// type storeGetters = typeof getters;
type rootStates = typeof store.state;
// type rootGetters = typeof rootGetter;

export async function finishOrSkipTour() {
    console.log("Update user tutorial summ")
    switch (rootState.appStore.currentPage) {
        case CurrentPage.ciderTable:
            const newSettingsTable = await apiTyped.updateUserSettings({"tour_table_complete": true});
            rootState.usersStore.me.userSettings.tourTableComplete = newSettingsTable.tour_table_complete;
            break;
        case CurrentPage.rating:
            const newSettingsRating = await apiTyped.updateUserSettings({"tour_rating_complete": true});
            rootState.usersStore.me.userSettings.tourTableComplete = newSettingsRating.tour_rating_complete;
            break;
        case CurrentPage.summary:
            const newSettingsSummary = await apiTyped.updateUserSettings({"tour_summary_complete": true});
            rootState.usersStore.me.userSettings.tourTableComplete = newSettingsSummary.tour_summary_complete;
            break;
    }
}

const state = {
    // tourOptions: {
    //     useKeyboardNavigation: true,
    //     highlight: true,
    //     labels: {
    //         buttonSkip: i18n.t('Tour.skip'),
    //         buttonPrevious: 'Zurück',
    //         buttonNext: i18n.te('Tour.next'),
    //         buttonStop: 'Abschließen'
    //     }
    // },
    callbacks: {
        onFinish: finishOrSkipTour,
        onSkip: finishOrSkipTour,
    }
}

// @ts-ignore
const getters = {
    ...make.getters(state),
    getTourOptions: () => () => {
        return {
            useKeyboardNavigation: true,
                highlight: true,
                labels: {
                buttonSkip: i18n.t('Tour.skip'),
                    buttonPrevious: i18n.t('Tour.back'),
                    buttonNext: i18n.t('Tour.next'),
                    buttonStop: i18n.t('Tour.stop'),
            }
        }
    }
}

const mutations = {
    // ...make.mutations(state),
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}