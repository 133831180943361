<template>
  <page
    v-if="cider !== null"
    ref="pageA4"
    size="A4"
    class="pa-0 pb-0 ma-0"
    style="transform-origin: top left;"
  >
    <div
      style="position: absolute;"
    >
      <v-img
        v-if="urkundeUrl != null && !hideBackground"
        :src="urkundeUrl"
        :lazy-src="urkundeUrl"
        alt="Certificate"
        style="position: relative; top: 0; left: 0; height: 29.7cm; width: 21cm; min-height: 29.7cm; max-width: 21cm"
      />
    </div>

    <!--    <div-->
    <!--      style="position:relative; top: 18.0cm; left: 0; width: 100%"-->
    <!--    >-->
    <!--      <span-->
    <!--        class="urkunde_text"-->
    <!--      >-->
    <!--        Category: {{ cider.kategorie.name }}<br>-->
    <!--        Product: {{ cider.name }}<br>-->
    <!--        Producer: {{ cider.produzent }}<br>-->
    <!--        Points: {{ calculateTotalPointsForCider(cider, true) }} of 120<br>-->
    <!--        Frankfurt, {{ new Date(tasting.datum).toLocaleDateString(-->
    <!--          "nl", { year: 'numeric', month: 'numeric', day: 'numeric' }) }}<br>-->
    <!--      </span>-->
    <!--    </div>-->

    <div
      class="text-color-no-print"
      v-html="urkundeHtml"
    />
    <!--    <div-->
    <!--      class="ml-auto mr-auto"-->
    <!--      style="position:relative; top: 16.5cm; max-width: 76%;"-->
    <!--    >-->
    <!--      <span-->
    <!--        class="urkunde_text text-wrap"-->
    <!--      >-->
    <!--        <span-->
    <!--          v-if="cider.name.length <= 33"-->
    <!--          class="text-uppercase"-->
    <!--          style="font-size: 32px; font-weight: 800;"-->
    <!--        >-->
    <!--          {{ cider.name }}-->
    <!--        </span>-->
    <!--        <span-->
    <!--          v-if="cider.name.length > 33"-->
    <!--          class="text-uppercase"-->
    <!--          style="font-size: 20px; font-weight: 800;"-->
    <!--        >-->
    <!--          {{ cider.name }}-->
    <!--        </span>-->
    <!--        <br>-->
    <!--        <div class="pt-2">-->
    <!--          <span-->
    <!--            class="text-uppercase"-->
    <!--            style="font-size: 16px; font-weight: 600;"-->
    <!--          >-->
    <!--            {{ cider.kategorie.name }}-->
    <!--          </span>-->
    <!--          <br>-->
    <!--          <span-->
    <!--            class="text-uppercase mb-0"-->
    <!--            style="font-size: 18px; font-weight: 600; line-height: 1 !important;"-->
    <!--          >-->
    <!--            {{ cider.salesDesignation }},-->
    <!--          </span>-->
    <!--          <span-->
    <!--            class="text-uppercase"-->
    <!--            style="font-size: 18px; font-weight: 600; line-height: 1 !important;"-->
    <!--          >-->
    <!--            {{ cider.designationOfOrigin }}, {{ cider.alkohol }}% VOL.-->
    <!--          </span>-->
    <!--          <br>-->
    <!--          <span-->
    <!--            class="text-uppercase"-->
    <!--            style="font-size: 20px; font-weight: 600;"-->
    <!--          >-->
    <!--            {{ calculateTotalPointsForCider(cider, true) }} of 100-->
    <!--          </span>-->
    <!--        </div>-->

    <!--        <br>-->
    <!--        &lt;!&ndash;        Category: {{ cider.kategorie.name }}<br>&ndash;&gt;-->
    <!--        &lt;!&ndash;        Product: {{ cider.name }}<br>&ndash;&gt;-->
    <!--        &lt;!&ndash;        Producer: {{ cider.produzent }}<br>&ndash;&gt;-->
    <!--        &lt;!&ndash;        Points: {{ calculateTotalPointsForCider(cider, true) }} of 120<br>&ndash;&gt;-->
    <!--        &lt;!&ndash;        Frankfurt, {{ new Date(tasting.datum).toLocaleDateString(&ndash;&gt;-->
    <!--        &lt;!&ndash;          "nl", { year: 'numeric', month: 'numeric', day: 'numeric' }) }}<br>&ndash;&gt;-->
    <!--      </span>-->
    <!--    </div>-->
    <!--    <div-->
    <!--      class="ml-auto mr-auto"-->
    <!--      style="position:relative; top: 18cm; max-width: 60%;"-->
    <!--    >-->
    <!--      <span-->
    <!--        class="urkunde_text text-wrap"-->
    <!--      >-->
    <!--        {{ cider.name }}<br><br>-->
    <!--        {{ cider.kategorie.name }}<br>-->
    <!--        {{ calculateTotalPointsForCider(cider, true) }} of 120<br>-->
    <!--        &lt;!&ndash;        Category: {{ cider.kategorie.name }}<br>&ndash;&gt;-->
    <!--        &lt;!&ndash;        Product: {{ cider.name }}<br>&ndash;&gt;-->
    <!--        &lt;!&ndash;        Producer: {{ cider.produzent }}<br>&ndash;&gt;-->
    <!--        &lt;!&ndash;        Points: {{ calculateTotalPointsForCider(cider, true) }} of 120<br>&ndash;&gt;-->
    <!--        &lt;!&ndash;        Frankfurt, {{ new Date(tasting.datum).toLocaleDateString(&ndash;&gt;-->
    <!--        &lt;!&ndash;          "nl", { year: 'numeric', month: 'numeric', day: 'numeric' }) }}<br>&ndash;&gt;-->
    <!--      </span>-->
    <!--    </div>-->
  </page>
</template>

<script>
import logos_mixin from "@/components/mixins/logos_mixin";
import cider_mixin from "@/components/mixins/cider_mixin";
import tasting_mixin from "@/components/mixins/tasting_mixin";
import {apiTyped} from "@/api";

export default {
  name: "UrkundePageA4",
  mixins: [logos_mixin, cider_mixin, tasting_mixin],
  props: {
    cider: {type: Object, required: true, default: () => null},
    tasting: {type: Object, required: false, default: () => null},
    badgeData: {type: Object, required: false, default: null},
    maxPoints: {type: Number, required: false, default: 120},
    hideBackground: {type: Boolean, required: false, default: false},
  },
  data: () => ({
    componentsLoaded: 0,
    points: 0,
    urkundeUrl: null,
  }),
  computed: {
    formattedDate() {
      return new Date(this.tasting.datum).toLocaleDateString(
        "nl", { year: 'numeric', month: 'numeric', day: 'numeric' })
    },
    productName() {
      return this.cider.name;
    },
    productCategory() {
      return this.cider.kategorie.name;  // todo translate
    },
    productProducer() {
      return this.cider.produzent;
    },
    productTotalPoints() {
      return this.calculateTotalPointsForCider(this.cider, true, false, 0.5)
    },
    productTotalPointsRoundInteger() {
      return Math.round(
        this.calculateTotalPointsForCider(this.cider, true, false, 0.1));
    },
    productDesignationOfOrigin() {
      return this.cider.designationOfOrigin;
    },
    productAlkohol() {
      return this.cider.alkohol;
    },
    productSalesDesignation() {
      return this.cider.salesDesignation;
    },
    productNameFontSize() {
      return this.cider.name.length > 24 ? '20px' : '32px';
    },
    urkundeHtml () {
      if (this.badgeData == null || this.badgeData.urkunde == null) return "";
      return this.badgeData.urkunde.textHtml.replace(/{{\s*([\S]+?)\s*}}/g, (full, property) => {
        return escape(this[property])
      })

      function escape (str) {
        if (str == null) {
          return ''
        }

        return String(str)
          .replace(/&/g, '&amp;')
          .replace(/>/g, '&gt;')
          .replace(/</g, '&lt;')
          .replace(/"/g, '&#34;')
          .replace(/'/g, '&#39;')
      }
    }
    // badgesData: sync("badgesStore/badgesData"),
    // badgesData() {
    //   if (this.badgesDataArray == null) return this.$store.state.badgesStore.badgesData;
    //   return this.badgesDataArray;
    // },
  },
  watch: {
    cider: function () {
      this.updateCertificate();
    },
  },
  async created() {
    // this.badgesData = await addUrkundenToBadges(this.badgesData, null, [Format.A4]);
    this.updateCertificate();
  },
  methods: {
    updateCertificate() {
      this.points = this.calculateTotalPointsForCider(this.cider, true);
      if (this.badgeData.urkunde != null) {
        this.urkundeUrl = apiTyped.getImgUrl(this.badgeData.urkunde.image, 0, true, true)
        console.log("this.urkundeUrl", this.urkundeUrl)
      }
      else {
        this.urkundeUrl = null;
      }
    },
  },
}
</script>

<style scoped>
page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
  min-height: 29.7cm;
  min-width: 21cm;
  max-height: 29.7cm;
  max-width: 21cm;
}

.page_grid {
  display: grid;
  align-items: stretch;
  height: 100%;
  grid-template-rows: min-content auto auto;
}

page {
  background: white;
  display: block;
  margin: 0 auto 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
}

.text-color-no-print {
    color: black;
}

@media print {
  .text-color-no-print {
      color: unset !important;
  }
  page {
    width: 21cm;
    height: 29.7cm;
    min-height: 29.7cm;
    min-width: 21cm;
    max-height: 29.7cm;
    max-width: 21cm;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: unset !important;
  }
}

.urkunde_text {
  color: rgb(38, 38, 38);
  text-align: center;
  font-family: "Nunito Sans", serif;
  font-size: 18pt;
  line-height: 25pt;
  letter-spacing: 0;
}

div#preload {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}
</style>