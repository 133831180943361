<template>
  <v-container
    fluid
    class="ma-0"
  >
    <v-row>
      <v-col>
        <div class="d-flex align-center reset-title">
          {{ $i18n.t('setNewPassword') }}
        </div>
      </v-col>
    </v-row>

    <v-card
      v-if="newPasswordSuccessful"
      class="pt-4 mt-4"
      :outlined="$vuetify.theme.dark"
    >
      <v-icon size="72">
        mdi-lock-check
      </v-icon>
      <v-card-title
        class="pb-0"
        style="overflow-wrap: break-word !important; word-break: keep-all !important;"
      >
        {{ $i18n.t('passwortSetSuccessfulTitle') }}
      </v-card-title>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn
          rounded
          outlined
          style="text-transform: none !important;"
          @click="$router.push('/login')"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
          {{ $i18n.t('Login.backToLogin') }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>

    <template v-if="!newPasswordSuccessful">
      <v-form
        ref="passwordForm"
        v-model="passwordsValid"
        lazy-validation
      >
        <v-row
          class="mt-0 mb-0"
        >
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="email"
              outlined
              disabled
              hide-details
              name="E-mail"
              label="E-mail"
              type="email"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="newPassword"
              outlined
              hide-details
              name="password"
              :label="$i18n.t('Login.password')"
              :placeholder="$i18n.t('Login.password')"
              :rules="[rules.required, rules.strongPassword]"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              @keyup.enter="setNewPassword"
            />
          </v-col>
        </v-row>
        <v-row class="mt-0 mb-0">
          <v-col>
            <v-text-field
              id="password"
              v-model="newPasswordCheck"
              outlined
              name="repeatPassword"
              :label="$i18n.t('Login.repeatPassword')"
              :placeholder="$i18n.t('Login.repeatPassword')"
              :rules="[rules.required, rules.strongPassword]"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              @keyup.enter="setNewPassword"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-fade-transition>
        <p
          v-show="infoMsg !== null"
          class="mb-2 mt-2 ml-4 mr-2 text-left"
        >
          {{ $i18n.t(infoMsg) }}
        </p>
      </v-fade-transition>
      <v-row
        class="mt-0 mb-0"
      >
        <v-col cols="auto">
          <v-btn
            text
            @click="$router.push('/login')"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
            {{ $i18n.t('Login.backToLogin') }}
          </v-btn>
        </v-col>
        <v-col
          class="ml-auto"
          cols="auto"
        >
          <v-btn
            elevation="0"
            color="primary"
            :loading="loading"
            :disabled="newPasswordSuccessful || !passwordsValid"
            @click="setNewPassword"
          >
            <v-icon
              v-show="newPasswordSuccessful"
              left
            >
              mdi-check
            </v-icon>
            {{ $i18n.t('Login.confirm') }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import {apiTyped} from "@/api";

export default {
  name: "NewPassword",
  data() {
    return {
      passwordsValid: true,
      newPassword: '',
      newPasswordCheck: '',
      newPasswordSuccessful: false,
      loading: false,
      infoMsg: null,
      showPassword: false,
    };
  },
  i18n: {
    messages: {
      de: {
        "setNewPassword": "Neues Passwort vergeben",
        "passwortSetSuccessfulTitle": "Wir haben erfolgreich das neue Passwort für Ihren Account gesetzt",
        "required": 'Passwort ist erforderlich.',
        "length": 'Das Passwort muss mindestens 8 Zeichen lang sein.',
        "uppercase": 'Das Passwort muss mindestens einen Großbuchstaben enthalten.',
        "lowercase": 'Das Passwort muss mindestens einen Kleinbuchstaben enthalten.',
        "number": 'Das Passwort muss mindestens eine Zahl enthalten.',
      },
      en: {
        "setNewPassword": "Set new Password",
        "passwortSetSuccessfulTitle": "We have successfully set the new password for your account",
        "required": 'Password is required.',
        "length": 'Password must be at least 8 characters long.',
        "uppercase": 'Password must contain at least one uppercase letter.',
        "lowercase": 'Password must contain at least one lowercase letter.',
        "number": 'Password must contain at least one number.',
      }
    }
  },
  computed: {
    rules() {
      return {
        required: value => !!value || 'Required.',
        strongPassword: value => {
          if (!value) {
            return false // let required validator handle empty values (above validation is responsible for that)
          }
          // Check if password contains at least 8 characters
          if (value.length < 8) {
            return this.$i18n.t('length')
          }
          // Check if password contains at least one uppercase letter
          if (!/[A-Z]/.test(value)) {
            return this.$i18n.t('uppercase')
          }
          // Check if password contains at least one lowercase letter
          if (!/[a-z]/.test(value)) {
            return this.$i18n.t('lowercase')
          }
          // Check if password contains at least one number
          if (!/[0-9]/.test(value)) {
            return this.$i18n.t('number')
          }
          return true
        }
      }
    },
    token() {
      return this.$route.query.token;
    },
    email() {
      return this.$route.query.email;
    },
  },
  methods: {
    async setNewPassword() {
      this.infoMsg = null;
      this.$refs.passwordForm.validate();
      if (!this.passwordsValid) return;
      try {
        if (this.newPassword === this.newPasswordCheck) {
          this.loading = true;
          const success = await apiTyped.setNewPassword(this.token, this.newPassword);
          if (success) {
            this.newPasswordSuccessful = true;
            this.loading = false;
          } else {
            this.infoMsg = 'An error occurred while setting the new Password';
            this.loading = false;
          }
        } else {
          this.infoMsg = 'The Passwords must be equal';
        }
      } catch (error) {
        this.infoMsg = 'Not able to set new Password';
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>
.reset-title {
  font-family: "Nunito Sans", serif !important;
  font-weight: 800;
  font-size: 32pt !important;
  text-align: left;
  line-height: 1.25;
}
</style>