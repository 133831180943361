<template>
  <div>
    <div
      style="position: absolute; top: 0; left: 0; z-index: 99; height: 72px; width: 100%; background-color: #121212; opacity: 0.3;"
      class="d-flex pr-16 pl-4"
      @click="$emit('closeGallery')"
    >
      <span
        class="text-h5 d-flex align-center justify-center flex-grow-1"
        style="color: var(v--text_std-base)"
      >
        {{ title }}
      </span>
    </div>
    <div
      style="position: absolute; top: 8px; right: 40px; z-index: 99; width: 32px; height: 32px;"
    >
      <v-btn
        style="position: absolute; top: 8px; left: 8px; background-color: white;"
        icon
        color="text_std_inv"
        elevation="1"
        top
        right
        @click="$emit('closeGallery')"
        @click.native.stop=""
      >
        <v-icon
          color="black"
        >
          mdi-close
        </v-icon>
      </v-btn>
    </div>
    <v-carousel
      v-if="items != null"
      style="width: 100vw; height: 100vh; z-index: 98; padding-top: 72px; padding-bottom: 50px !important;"
      class="blurred-background"
      :show-arrows="items.length > 1"
      :value="selectedImage"
    >
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        class="carousel-item"
      >
        <div class="carousel-img-container">
          <v-img
            v-click-outside="{
              handler: closeIfLoaded,
              include: includeNavigation,
            }"
            :src="item"
            contain
            class="carousel-img"
            style="z-index: 97 !important; height: calc(100vh - 72px - 50px);"
          />
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: "GalleryOverlay",
  props: {
    items: {type: Array, required: true, default: null},
    title: {type: String, required: true, default: ""},
    selected: {type: Number, required: false, default: 0},
  },
  data: () => ({
    loaded: false,
    selectedImage: 0,
  }),
  created() {
    document.addEventListener('keyup', this.keyPresses);
    this.selectedImage = this.selected;
    this.loaded = true;
  },
  methods: {
    keyPresses(evt) {
      if (evt.keyCode === 37) {  // left
        if (this.selectedImage > 0) this.selectedImage--;
      }
      if (evt.keyCode === 39) {  // right
        if (this.selectedImage < this.items.length - 1) this.selectedImage++;
      }
      if (evt.keyCode === 27) {
        this.$emit('closeGallery');
      }
    },
    closeIfLoaded() {
      console.log("CLOSING")
      if (this.loaded) this.$emit('closeGallery')
    },
    includeNavigation () {
      console.log("INCLUDE", [...document.querySelectorAll('.v-window__next,.v-window__prev,.v-carousel__controls')])
      return [...document.querySelectorAll('.v-window__next,.v-window__prev,.v-carousel__controls')]
    },
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.keyPresses);
  }
}
</script>

<style scoped>

.blurred-background {
  -webkit-box-shadow: inset 0 0 200px #00000000;
  -moz-box-shadow: inset 0 0 200px #00000000;
  box-shadow: inset 0 0 200px #00000000;
  backdrop-filter: blur(20px) !important;
}

/deep/ .v-carousel__item {
  height: 100% !important;
}

/deep/ .v-overlay__content {
  border-radius: unset !important;
}

.carousel-item {
    .carousel-img-container {
        height: 100% !important;
        width: 100%;
        display: grid;
        justify-items: center;
        align-items: center;
        .carousel-img {
            align-self: start;
            height: auto;
            max-height: 100%;
        }
    }
}
</style>