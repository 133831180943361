<template>
  <v-container
    class="pa-0 pb-4 ma-0"
    style="min-width: 100%;"
  >
    <!--    <v-slide-y-reverse-transition>-->
    <!--      <v-btn-->
    <!--        v-show="!enableGridEdit"-->
    <!--        fab-->
    <!--        fixed-->
    <!--        bottom-->
    <!--        right-->
    <!--        class="mr-8 mb-8 no-print"-->
    <!--        @click="enableGridEdit = true"-->
    <!--      >-->
    <!--        <v-icon>-->
    <!--          mdi-pencil-->
    <!--        </v-icon>-->
    <!--      </v-btn>-->
    <!--    </v-slide-y-reverse-transition>-->
    <!--    <v-slide-y-reverse-transition>-->
    <!--      <v-btn-->
    <!--        v-show="enableGridEdit"-->
    <!--        fab-->
    <!--        fixed-->
    <!--        bottom-->
    <!--        right-->
    <!--        x-large-->
    <!--        class="mr-8 mb-8 no-print"-->
    <!--        @click="saveGrid"-->
    <!--      >-->
    <!--        <v-icon v-if="!savingGrid">-->
    <!--          mdi-content-save-->
    <!--        </v-icon>-->
    <!--        <v-progress-circular-->
    <!--          v-if="savingGrid"-->
    <!--          indeterminate-->
    <!--          color="text_std"-->
    <!--          style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);"-->
    <!--        />-->
    <!--      </v-btn>-->
    <!--    </v-slide-y-reverse-transition>-->
    <!--    <GridLayout-->
    <!--    v-if="!loading"-->
    <!--    :layout.sync="layout"-->
    <!--    :col-num="12"-->
    <!--    :row-height="rowHeight"-->
    <!--    :is-draggable="enableGridEdit"-->
    <!--    :is-resizable="enableGridEdit"-->
    <!--    :is-mirrored="false"-->
    <!--    :auto-size="true"-->
    <!--    :vertical-compact="false"-->
    <!--    :margin="[20, 20]"-->
    <!--    :use-css-transforms="false"-->
    <!--    :responsive="true"-->
    <!--  >-->
    <!--    <GridItem-->
    <!--      :x="layout[0].x"-->
    <!--      :y="layout[0].y"-->
    <!--      :w="layout[0].w"-->
    <!--      :h="layout[0].h"-->
    <!--      :i="layout[0].i"-->
    <!--    >-->
    <!--      <CardTableCount-->
    <!--        :tables="tables"-->
    <!--      />-->
    <!--    </GridItem>-->

    <!--    <GridItem-->
    <!--      :x="layout[1].x"-->
    <!--      :y="layout[1].y"-->
    <!--      :w="layout[1].w"-->
    <!--      :h="layout[1].h"-->
    <!--      :i="layout[1].i"-->
    <!--    >-->
    <!--      <CardFlightsCount-->
    <!--        :tables="tables"-->
    <!--      />-->
    <!--    </GridItem>-->

    <!--    <GridItem-->
    <!--      :x="layout[2].x"-->
    <!--      :y="layout[2].y"-->
    <!--      :w="layout[2].w"-->
    <!--      :h="layout[2].h"-->
    <!--      :i="layout[2].i"-->
    <!--    >-->
    <!--      <CardUserCount-->
    <!--        :tables="tables"-->
    <!--      />-->
    <!--    </GridItem>-->

    <!--    <GridItem-->
    <!--      :x="layout[3].x"-->
    <!--      :y="layout[3].y"-->
    <!--      :w="layout[3].w"-->
    <!--      :h="layout[3].h"-->
    <!--      :i="layout[3].i"-->
    <!--    >-->
    <!--      <CardProduzentenCount-->
    <!--        :all-ciders="allCidersPrepped"-->
    <!--      />-->
    <!--    </GridItem>-->

    <!--    <GridItem-->
    <!--      :x="layout[4].x"-->
    <!--      :y="layout[4].y"-->
    <!--      :w="layout[4].w"-->
    <!--      :h="layout[4].h"-->
    <!--      :i="layout[4].i"-->
    <!--    >-->
    <!--      <CardCiderStats-->
    <!--        :all-ciders="allCidersPrepped"-->
    <!--      />-->
    <!--    </GridItem>-->

    <!--    <GridItem-->
    <!--      :x="layout[5].x"-->
    <!--      :y="layout[5].y"-->
    <!--      :w="layout[5].w"-->
    <!--      :h="layout[5].h"-->
    <!--      :i="layout[5].i"-->
    <!--    >-->
    <!--      <CardBadgesCount-->
    <!--        :all-ciders="allCidersPrepped"-->
    <!--        @size="sizeChanged"-->
    <!--      />-->
    <!--    </GridItem>-->

    <!--    <GridItem-->
    <!--      :x="layout[6].x"-->
    <!--      :y="layout[6].y"-->
    <!--      :w="layout[6].w"-->
    <!--      :h="layout[6].h"-->
    <!--      :i="layout[6].i"-->
    <!--      ref="CardBestCider"-->
    <!--    >-->
    <!--      <CardBestCider-->
    <!--        :all-ciders="allCidersPrepped"-->
    <!--        @size="sizeChanged"-->
    <!--      />-->
    <!--    </GridItem>-->

    <!--    <GridItem-->
    <!--      :x="layout[7].x"-->
    <!--      :y="layout[7].y"-->
    <!--      :w="layout[7].w"-->
    <!--      :h="layout[7].h"-->
    <!--      :i="layout[7].i"-->
    <!--    >-->
    <!--      <CardBestOfCategories-->
    <!--        :all-ciders="allCidersPrepped"-->
    <!--        @size="sizeChanged"-->
    <!--      />-->
    <!--    </GridItem>-->

    <!--    <GridItem-->
    <!--      :x="layout[8].x"-->
    <!--      :y="layout[8].y"-->
    <!--      :w="layout[8].w"-->
    <!--      :h="layout[8].h"-->
    <!--      :i="layout[8].i"-->
    <!--    >-->
    <!--      <CardCountryGrouped-->
    <!--        :all-ciders="allCidersPrepped"-->
    <!--        @size="sizeChanged"-->
    <!--      />-->
    <!--    </GridItem>-->

    <!--    <GridItem-->
    <!--      :x="layout[9].x"-->
    <!--      :y="layout[9].y"-->
    <!--      :w="layout[9].w"-->
    <!--      :h="layout[9].h"-->
    <!--      :i="layout[9].i"-->
    <!--    >-->
    <!--      <CardTimeline-->
    <!--        :all-ciders="allCidersPrepped"-->
    <!--        :tables="tables"-->
    <!--        :kategorien="kategorien"-->
    <!--        @size="sizeChanged"-->
    <!--      />-->
    <!--    </GridItem>-->
    <!--  </GridLayout>-->
    <!--  </v-container>-->
    <loading-circle
      v-if="loading"
      :long-loading="true"
      loading-message="Loading Dashboard..."
    />
    <v-row
      v-if="!loading"
      id="adminDashboard"
      v-masonry="'adminDashboard'"
      horizontal-order="true"
      transition-duration="0.3s"
      class="pa-0 ma-auto justify-center align-center"
    >
      <v-col
        xl="1"
        lg="2"
        md="3"
        sm="4"
        xs="6"
        cols="6"
      >
        <CardTableCount
          :tables="tables"
        />
      </v-col>
      <v-col
        xl="1"
        lg="2"
        md="3"
        sm="4"
        xs="6"
        cols="6"
      >
        <CardUserCount
          :tables="tables"
        />
      </v-col>
      <v-col
        xl="1"
        lg="2"
        md="3"
        sm="4"
        xs="6"
        cols="6"
      >
        <CardFlightsCount
          :tables="tables"
        />
      </v-col>
      <v-col
        xl="1"
        lg="2"
        md="3"
        sm="4"
        xs="6"
        cols="6"
      >
        <CardProduzentenCount
          :all-ciders="allCidersPrepped"
        />
      </v-col>

      <v-col
        xl="3"
        lg="4"
        md="6"
        sm="6"
        xs="6"
        cols="12"
      >
        <CardCiderStats
          :all-ciders="allCidersPrepped"
        />
      </v-col>

      <v-col
        class="justify-center"
        justify="center"
        align="center"
        cols="12"
        xl="3"
        lg="4"
        md="6"
        sm="6"
        xs="12"
        @load="$redrawVueMasonry('adminDashboard')"
      >
        <CardBadgesCount
          :all-ciders="allCidersPrepped"
          :badges="badgesData"
        />
      </v-col>

      <v-col
        class="justify-center"
        justify="center"
        align="center"
        cols="12"
        xl="3"
        lg="4"
        md="6"
        sm="6"
        xs="12"
        @load="$redrawVueMasonry('adminDashboard')"
      >
        <CardBestCider
          :all-ciders="allCidersPrepped"
        />
      </v-col>
      <v-col
        class="justify-center"
        justify="center"
        align="center"
        cols="12"
        xl="3"
        lg="4"
        md="6"
        sm="6"
        xs="12"
        @load="$redrawVueMasonry('adminDashboard')"
      >
        <CardBestOfCategories
          :all-ciders="allCidersPrepped"
        />
      </v-col>
      <v-col
        class="justify-center"
        justify="center"
        align="center"
        cols="12"
        xl="3"
        lg="4"
        md="6"
        sm="6"
        xs="12"
        @load="$redrawVueMasonry('adminDashboard')"
      >
        <CardCountryGrouped
          :all-ciders="allCidersPrepped"
          @redrawMasonry="redrawMasonry"
        />
      </v-col>
      <!--      <v-col-->
      <!--        class="justify-center"-->
      <!--        justify="center"-->
      <!--        align="center"-->
      <!--        cols="12"-->
      <!--        xl="6"-->
      <!--        lg="6"-->
      <!--        md="12"-->
      <!--        sm="12"-->
      <!--        xs="12"-->
      <!--        @load="$redrawVueMasonry('adminDashboard')"-->
      <!--      >-->
      <!--        <CardTimeline-->
      <!--          :all-ciders="allCidersPrepped"-->
      <!--          :kategorien="kategorien"-->
      <!--          :tables="tables"-->
      <!--        />-->
      <!--      </v-col>-->
    </v-row>
  </v-container>
</template>

<script>
import {get, sync} from "vuex-pathify";
import {apiTyped} from "@/api";
import {VueMasonryPlugin} from 'vue-masonry';
import Vue from "vue";
import CardCiderStats from "@/components/admin/dashboard/card_cider_stats";
import CardBadgesCount from "@/components/admin/dashboard/card_badges_count";
import cider_mixin from "@/components/mixins/cider_mixin";
import CardBestCider from "@/components/admin/dashboard/card_best_cider";
import CardCountryGrouped from "@/components/admin/dashboard/country_grouped";
import CardTimeline from "@/components/admin/dashboard/card_timeline";
import CardBestOfCategories from "@/components/admin/dashboard/card_best_of_categories";
import CardTableCount from "@/components/admin/dashboard/card_table_count";
import CardUserCount from "@/components/admin/dashboard/card_user_count";
import CardFlightsCount from "@/components/admin/dashboard/card_flights_count";
import CardProduzentenCount from "@/components/admin/dashboard/card_produzenten_count";
import LoadingCircle from "@/layouts/loading_circle";
import JurorCider from "@/types/jurorCider";
import admin_mixin from "@/components/mixins/admin_mixin";
// import VueGridLayout from 'vue-grid-layout';

Vue.use(VueMasonryPlugin)

export default {
  name: "AdminDashboard",
  components: {
    LoadingCircle,
    // GridLayout: VueGridLayout.GridLayout,
    // GridItem: VueGridLayout.GridItem,
    CardProduzentenCount,
    CardFlightsCount,
    CardUserCount,
    CardTableCount,
    CardBestOfCategories,
    // CardTimeline,
    CardCountryGrouped,
    CardBestCider,
    CardBadgesCount,
    CardCiderStats
  },
  mixins: [admin_mixin, cider_mixin],
  data: () => ({
    enableGridEdit: false,
    savingGrid: false,
    layout: [
      {name: "countTables",             "x":0,  "y":0,  "w":2,  "h":5,  "i":"0"},
      {name: "CountFlights",            "x":2,  "y":0,  "w":2,  "h":5,  "i":"1"},
      {name: "CountJuroren",            "x":4,  "y":0,  "w":2,  "h":5,  "i":"2"},
      {name: "CardProduzentenCount",    "x":6,  "y":0,  "w":2,  "h":5,  "i":"3"},
      {name: "CardCiderStats",          "x":8,  "y":0,  "w":3,  "h":5,  "i":"4"},
      {name: "CardBadgesCount",         "x":0,  "y":0,  "w":4,  "h":7, "i":"5"},
      {name: "CardBestCider",           "x":4,  "y":0,  "w":4,  "h":7, "i":"6"},
      {name: "CardBestOfCategories",    "x":8,  "y":0,  "w":4,  "h":7,  "i":"7"},
      {name: "CardCountryGrouped",      "x":0,  "y":0,  "w":4,  "h":1,  "i":"8"},
      {name: "CardTimeline",            "x":0,  "y":0,  "w":6,  "h":1,  "i":"9"},
    ],
    rowHeight: 10,
    loading: true,
    allCiders: null,
  }),
  computed: {
    badgesData: get("badgesStore/badgesData"),
    tables: sync("tableStore/tables"),
    tastings: sync("usersStore/tastings"),
    kategorien: sync("kategorienStore/kategorieData"),
    // currentTastingIdx() {
    //   return this.$store.getters["usersStore/getTastingIdxByTastingId"](Number(this.$route.query.tasting));
    // },
    // tasting: sync('usersStore/tastings@[:currentTastingIdx]'),
    tasting() {
      return this.$store.getters["usersStore/currentTasting"]();
    },
    allCidersPrepped() {
      if (this.allCiders === null) return [];
      return this.allCiders.map((cider, index) => {
        cider.uniqueId = index;
        // cider.totalPoints = this.calculateTotalPointsForCider(cider, true);
        cider.totalPoints = cider.points;
        cider.badgeData = this.$store.getters['badgesStore/getBadgeData'](cider.totalPoints);
        return cider;
      });
    },
  },
  async created() {
    try {
      // const resp = await apiTyped.getCidersPreparedByTable(this.tables, this.kategorien);
      // const flightsCidersIds = this.tables.flatMap(
      //   table => this.$store.getters["tableStore/getFlightsCidersIds"](table));
      // const flightsCiders = await apiTyped.getFlightsCiders(flightsCidersIds, false, true);
      // // Add flights data to ciders object
      // const flightsData = this.tables.flatMap(table => table.flights);
      // flightsCiders.forEach(f => f.flights_id = flightsData.find(f_ => f_.id === f.flights_id));
      // // Create ciders
      // const ciders = flightsCiders.map(
      //   f => JurorCider.fromApiData(
      //     f.ciders_id,
      //     this.kategorien.find(k => Number(k.id) === Number(f.ciders_id.kategorie)),
      //     f, this.$store.state.usersStore.me.id,
      //     false,
      //     []
      //   )
      // );
      // this.allCiders = ciders;

      this.allCiders = (await this.getCidersForTables()).flat(1);

      // Add tables to ciders:
      // this.allCiders.map((ciders, tableIndex) => ciders.map(cider => cider.tableId = this.tables[tableIndex].id))
      // const savedLayout = this.$store.state.usersStore.me.userSettings.adminDashboardGridLayout;
      // if (savedLayout !== null) {
      //   console.log("Restoring Layout:", savedLayout)
      //   this.layout = savedLayout;
      // }
    } catch (e) {
      console.error("Error loading Dashboard", e);
    }
    this.loading = false;
    // console.log("allCiders:", this.allCiders)
    // console.log("allUserNotes:", this.allUserNotes)
    // console.log("allChairNotes:", this.allChairNotes)
    // console.log("Bewertungen:", this.allBewertungen)
  },
  methods: {
    sizeChanged({name, size}) {
      this.$redrawVueMasonry('adminDashboard');
      // const item = this.layout.find(item => item.name === name);
      // item.h = Math.ceil((size + 20) / (this.rowHeight + 20));
      // console.log("Got Size changed", name, item, size, (size + 20), (this.rowHeight + 20))
    },
    redrawMasonry() {
      this.$redrawVueMasonry('adminDashboard');
    },
    async saveGrid() {
      this.savingGrid = true;
      const userSettings = await apiTyped.updateUserSettings({"admin_dashboard_grid_layout": this.layout})
      this.$store.getters["usersStore/user"]().userSettings.adminDashboardGridLayout =
        userSettings.admin_dashboard_grid_layout;
      console.log("SerializedGrid", JSON.stringify(this.layout))
      console.log("SerializedGrid", JSON.parse(JSON.stringify(this.layout)))
      this.savingGrid = false;
      this.enableGridEdit = false;
    },
  },
}
</script>

<style>
.vue-grid-item.vue-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  border-radius: 20px !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.vue-resizable-handle {
  background: unset !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
  border-right: solid;
  border-bottom: solid;
  border-width: 1px;
  border-color: var(--v-divider-base);
  /*border-radius: 20px !important;*/
  width: 10px !important;
  height: 10px !important;
}
</style>