import {components, MyCollections} from "@/api/types/my-collections";
import {apiTyped} from "@/api";

enum Format {
    A4 = "A4",
    A3 = "A3",
}

function getFormatFromString(str: string | undefined | null): Format {
    if (str === null || str === undefined) return Format.A4;
    switch (str.toLowerCase()) {
        case "a3":
            return Format.A3;
        case "chair":
            return Format.A4;
        default:
            return Format.A4;
    }
}

type Urkunde = {
    id: number;
    format: Format;
    image: string;
    medailleId: number;
    textHtml: string | null;
}

type Badge = {
    icon: string | null;
    bestOfCategory: string | null;
    id: number;
    stufe: string | null;
    punkteMin: number;
    punkteMax: number;
    color: string | null;
    hasImage: boolean;
    tastings: number[];
    urkunde: Urkunde | null;
    border: boolean;
}

function getTastingIds(tastings: (number | components["schemas"]["ItemsTastingsMedaillen"])[]): number[] {
    console.log("TaST", tastings)
    if (typeof tastings[0] === "number" || !isNaN(<number>tastings[0])) {
        return tastings as number[]
    } else {
        if (typeof tastings[0].tastings_id == "number") {
            return tastings.map((tasting: any) => Number(tasting.tastings_id));
        } else {
            return tastings.map((tasting: any) => Number(tasting.tastings_id?.id));
        }
    }
}

async function addUrkundenToBadges(badges: Badge[], urkunden: Urkunde[] | null = null,
                                   formats: Format[] = [Format.A3, Format.A4]): Promise<Badge[]> {
    if (urkunden == null) {
        urkunden = await apiTyped.getUrkunden(badges, formats) || [];
    }
    for (const urkunde of urkunden) {
        const badge = badges.find(badge => Number(badge.id) === Number(urkunde.medailleId));
        if (badge != null) badge.urkunde = urkunde;
    }
    return badges;
}

function createBadgeFromDatabase(badge: MyCollections["medaillen"] | any): Badge {
    // noinspection UnnecessaryLocalVariableJS
    const newBadge: Badge = {
        icon: typeof badge.icon === "string" ? badge.icon : badge.icon?.id || null,
        bestOfCategory: typeof badge.icon_best_of_category === "string"
            ? badge.icon_best_of_category : badge.icon_best_of_category?.id || null,
        id: Number(badge.id) || 0,
        stufe: badge?.stufe || null,
        punkteMax: Number(badge.punkte_max),
        punkteMin: Number(badge.punkte_min),
        color: badge?.color || null,
        hasImage: badge.hat_medaille || false,
        tastings: badge.tastings != null && badge.tastings.length > 0 ? getTastingIds(badge.tastings) : [],
        urkunde: null,
        border: badge.border || false,
    };
    return newBadge;
}

function createUrkundeFromDatabase(urkunde: MyCollections["urkunden"]): Urkunde {
    // noinspection UnnecessaryLocalVariableJS
    const newUrkunde: Urkunde = {
        id: Number(urkunde.id) || -1,
        format: urkunde?.format !== undefined ? getFormatFromString((urkunde?.format as any[])[0]) : Format.A4,
        image: typeof urkunde.hintergrund === "string" ? urkunde.hintergrund : urkunde.hintergrund?.id || "",
        medailleId: typeof urkunde.medaille === "number" ? urkunde.medaille : urkunde.medaille?.id || -1,
        textHtml: urkunde.text_html || null,
    };
    return newUrkunde;
}

export {
    Badge,
    Format,
    Urkunde,
    createBadgeFromDatabase,
    createUrkundeFromDatabase,
    getFormatFromString,
    addUrkundenToBadges
}