<template>
  <div>
    <v-breadcrumbs
      v-if="componentBreadcrumbs != null"
      :items="componentBreadcrumbs"
      class="pl-0 pb-1"
    >
      <template #divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <div
      class="d-flex text-left text-h2 font-weight-medium align-center pt-2 pt-md-0"
      style="font-size: 36px !important; font-family: 'Nunito Sans', serif !important; line-height: 1;"
    >
      <v-btn
        v-if="showBackButton"
        icon
        x-large
        class="mr-4"
        @click="navigateBack"
      >
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      {{ titleTranslation }}
      <v-spacer />
      <slot name="contentRight" />
    </div>
  </div>
</template>

<script>

export default {
  name: "PageNavigation",
  props: {
    titleTranslation: {type: [Object, String], required: true, default: null},
    breadcrumbs: {type: Array, required: false, default: null},
    backUrl: {type: String, required: false, default: null},
    hideBackButton: {type: Boolean, required: false, default: true},
  },
  data: () => ({
    componentBreadcrumbs: [],
  }),
  computed: {
    showBackButton () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return !this.hideBackButton;
        default: return false;
      }
    },
  },
  methods: {
    async navigateBack() {
      if (this.backUrl != null) await this.$router.push(this.backUrl);
      else await this.$router.go(-1);
    }
  },
  created() {
    if (this.breadcrumbs == null) {
      this.componentBreadcrumbs = this.$store.state.appStore.breadcrumbs;
      // this.componentBreadcrumbs = this.componentBreadcrumbs.map(b => b.disabled = false)
      // this.$store.state.producerStore.breadcrumbs = [];
    } else {
      this.componentBreadcrumbs = this.breadcrumbs;
      this.$store.set("appStore/breadcrumbs", this.componentBreadcrumbs);
    }
  }
}
</script>

<style scoped>
/deep/ li > a {
  text-align: start !important;
}
</style>