<template>
  <v-app
    style="margin-top: 0 !important; display: flex; flex-direction: column !important; "
    :style="{background: $vuetify.theme.currentTheme.background}"
  >
    <transition
      name="fade"
      style="height: 100%; display: flex; flex-direction: column !important; "
    >
      <v-container
        v-if="ready"
        fluid
        class="pa-0 ma-0"
        style="height: 100vh; display: flex; flex-direction: column !important;"
      >
        <app_bar />
        <v-main>
          <router-view :key="reloadRouter" />
        </v-main>
        <CiderFooter
          :background-color="$vuetify.theme.currentTheme.background"
          :tasting="$store.getters['usersStore/currentTasting']()"
          :badges-data-array="$store.state.badgesStore.badgesData"
          show-logo
        />
      </v-container>
    </transition>
    <transition
      v-if="!ready"
      name="fade"
    >
      <LoadingCircle
        :long-loading="longLoading"
        :loading-message="loadingMessage"
      />
    </transition>
  </v-app>
</template>

<script>
import app_bar from "@/components/app_bar";
import CiderFooter from "@/components/cider_footer";
import store from "@/stores";
import {apiTyped} from "@/api";
import logos_mixin from "@/components/mixins/logos_mixin";
import {Theme} from "@/types/user";
import LoadingCircle from "@/layouts/loading_circle";
import {createLocaleMessagesForKategorie} from "@/types/kategorieRated";
import locale_mixin from "@/components/mixins/locale_mixin";
import {extendLocaleMessages} from "@/locales/createLocaleMessageObject";
import i18n from "@/i18n";
import user_mixin from "@/components/mixins/user_mixin";
import tasting_layout_mixin from "@/components/mixins/tasting_layout_mixin";
import JurorCider from "@/types/jurorCider";
import {BreakpointWidth} from "@/types/dynamicIsland";
import {allRoutes} from "@/router";
import {clearIntervalAsync} from "set-interval-async";
import {setIntervalAsync} from "set-interval-async/fixed";

export default {
  name: "LayoutTasting",
  components: {
    LoadingCircle,
    app_bar,
    CiderFooter,
  },
  mixins: [logos_mixin, locale_mixin, user_mixin, tasting_layout_mixin],
  data: () => ({
    ready: false,
    longLoading: true,
    loadingMessage: "",
    intervalUpdateData: null,
  }),
  computed: {
    reloadRouter() {
      return this.$store.state.reload_router;
    },
    tastingId() {
      return this.$route.params.tastingId;
    }
  },
  async created() {
    try {
      await this.tastingCreated();
      if (this.$store.state.usersStore.currentTastingId == null
        || this.$store.state.usersStore.currentTastingId === -1) {
        await this.$router.push(allRoutes.tastings.my);
      }

      this.$store.state.active_tab = 0;
      this.loadingMessage = "Getting Tasting Data...";
      const currentTasting = this.$store.getters["usersStore/currentTasting"]();
      document.title = currentTasting.name
      // Get Data of all Ciders:
      this.loadingMessage = "Getting Ciders... (yummy 😋)";
      this.$store.state.tableStore.tables = await apiTyped.jurorGetTablesAndFlights(currentTasting.id);
      const isChair = this.$store.getters["usersStore/isChair"]();
      const currentTable = this.$store.getters["tableStore/getCurrentTable"]();
      const tableMembers = this.$store.getters["tableStore/getTableMembersIds"](currentTable);
      const flightsCidersIds = this.$store.getters["tableStore/getFlightsCidersIds"](currentTable);
      const categories = await apiTyped.getAllKategorien(currentTasting.id);
      const tastingFinished = currentTasting.finished;
      const flightsCiders = await apiTyped.getFlightsCiders(flightsCidersIds, true, tastingFinished);
      // Add flight data to flightsCiders
      flightsCiders.forEach(f => f.flights_id = currentTable.flights.find(f_ => f_.id === f.flights_id));
      // fetch missing kategorien for whatever reason they are not present in current loaded tasting
      const missingCategoryIds = flightsCiders.filter(
        f => categories.find(k => Number(k.id) === Number(f.ciders_id.kategorie)) == null).map(
          f => Number(f.ciders_id.kategorie));
      if (missingCategoryIds.length > 0) {
        const additionalCategories = await apiTyped.getAllKategorien(-1, missingCategoryIds);
        categories.push(...additionalCategories);
      }
      this.$store.commit("kategorienStore/setKategorieData", categories);
      const ciders = flightsCiders.map(
        f => JurorCider.fromApiData(
          f.ciders_id,
          categories.find(k => Number(k.id) === Number(f.ciders_id.kategorie)),
          f,
          this.$store.getters["usersStore/userId"](),
          isChair,
          tableMembers
        )
      );
      console.log("currentTable", currentTable)
      // const sortIds = currentTable.flights.flatMap(f => f.ciders);
      // const cidersSorted = new Array(ciders.length);
      // ciders.forEach(function (a) {
      //   cidersSorted[sortIds.indexOf(Number(a.flightsCidersId))] = a;
      // });
      // console.log("cidersSorted", cidersSorted);
      // sort in place for correct flights order:
      ciders.sort((c1, c2) => (c1.flightsSort - c2.flightsSort) || (c1.sort - c2.sort));
      this.$store.state.ciderStore.ciderList = ciders;
      // let [ciders, tables, kategorien] = await apiTyped.getUserCidersForTables(currentTasting.id);
      // this.$store.state.ciderStore.ciderList = ciders;
      // this.loadingMessage = "Settings things up...";
      // const cidersBewertungen = await apiTyped.getBewertungenCreateMissing(ciders, currentTasting.id);
      // this.loadingMessage = "Getting Category Data...";
      // this.$store.state.tableStore.tables = tables;
      // this.$store.commit("kategorienStore/setKategorieData", kategorien);
      // console.log("Kategorien:", store.state.kategorienStore.kategorieData);
      // console.log("Faktoren", store.state.kategorienStore.faktorForEigenschaft);
      // this.loadingMessage = "Processing Ciders ...";
      // console.log("Processing Ciders ...", ciders, cidersBewertungen, currentTasting.id)
      // await addBewertungenToCidersKategorieRated(ciders, cidersBewertungen, currentTasting.id);
      // // await store.dispatch("ciderStore/addBewertungenToCiderKategorie", {bewertungen: cidersBewertungen});
      // console.log("Getting bewertungen")
      // const allBewertungenForAllCiders = await apiTyped.getBewertungen(
      //   this.$store.state.ciderStore.ciderList, this.$store.getters["tableStore/getTableMembersIds"]());
      // console.log("Adding bewertungen")
      // await addAllBewertungenToCiders(ciders, allBewertungenForAllCiders);
      // await store.dispatch("ciderStore/addAllBewertungenToCiders", allBewertungenForAllCiders);

      // console.log("Getting badges")
      this.loadingMessage = "Getting Badges 👑...";
      const badges = await apiTyped.getBadges([currentTasting]);
      // console.log("Badges:", badges)
      // this.$store.state.badgesStore.badgesData = badges;
      this.$store.state.badgesStore.badgesData = badges;
      currentTasting.badges = badges;

      // this.loadingMessage = "Getting Cider Notes 📔 ...";
      // await addAllNotesForCiders(
      //   this.$store.state.ciderStore.ciderList, this.$store.getters["tableStore/getTableMembersIds"]())
      // const chairUser = this.$store.getters["tableStore/getChairFromTable"]();
      // if (chairUser == null) {
      //   throw new Error("No Chair found for current table" + this.$store.getters["tableStore/getCurrentTable"]().id);
      // }
      // const chairNotes = await apiTyped.getChairNotesForCider(ciders, true, chairUser.id);
      // // console.log("chairNotes:", chairNotes)
      // this.loadingMessage = "Getting Cider Chair Notes 📔...";
      // this.$store.commit("ciderStore/addChairNotesToCiders", {chairNotes: chairNotes, ciders: ciders})
      // // console.log("All Ciders:", this.$store.state.ciderStore.ciderList)

      // Update Languages:
      this.loadingMessage = "Updating Languages ⚑...";
      for (const kategorie_ of this.$store.state.kategorienStore.kategorieData) {
        const newMessages = createLocaleMessagesForKategorie(kategorie_);
        await this.$store.dispatch("localeStore/setLocaleMessages",  newMessages);
        // for (const locale in newMessages) {
        //   // await extendLocaleMessages(locale, newMessages[locale])
        //   this.localeMessages[locale] = {
        //     ...(this.localeMessages.hasOwnProperty(locale) ? this.localeMessages[locale] : {}),
        //     ...(newMessages.hasOwnProperty(locale) ? newMessages[locale] : {}),
        //     ...i18n.messages[locale],
        //   };
        //   this.$i18n.setLocaleMessage(locale, this.localeMessages[locale])
        // }
      }
      // await this.$store.dispatch("localeStore/setLocaleMessages",  this.localeMessages);
      // const currentLangCode = this.$store.getters["usersStore/getUserLanguageCode"]();
      // console.log("Setting translations:", this.localeMessages, currentLangCode)
      // this.$i18n.setLocaleMessage(currentLangCode, this.localeMessages[currentLangCode])

      console.log("Store ciders", this.$store.state.ciderStore.ciderList[2], ciders[2])

      this.$store.commit("dynamicIslandsStore/clearIslands");
      this.$store.commit("dynamicIslandsStore/addIsland", {
        componentName: "Priority 2",
        componentAttributes: {},
        priority: 2,
        collapsedWidth: {xs:6, sm:6,md:6,lg:6,xl:6},
        expandedWidth: {xs:6, sm:6,md:6,lg:6,xl:6},
        dismissible: true,
        showNotification: true,
      });
      this.$store.commit("dynamicIslandsStore/addIsland", {
        componentName: "Priority 3",
        componentAttributes: {},
        priority: 3,
        collapsedWidth: {xs:3, sm:3,md:3,lg:3,xl:6},
        expandedWidth: {xs:3, sm:6,md:6,lg:6,xl:6},
        dismissible: true,
        showNotification: true,
      });

      this.ready = true;
      this.intervalUpdateData = setIntervalAsync(this.updateTasting, 10000);
    } catch (e) {
      await this.$router.push('/login');
    }
  },
  async beforeDestroy() {
    await clearIntervalAsync(this.intervalUpdateData);
  },
  methods: {
    async updateTasting() {
      const storeTasting = this.$store.getters["usersStore/currentTasting"]();
      if (storeTasting == null) return;
      const tasting = await apiTyped.getTastingById(storeTasting.id);
      this.$store.commit("usersStore/updateTasting", tasting);
    }
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to {
  opacity: 0
}

body {
  /*noinspection CssUnresolvedCustomProperty*/
  background: var(--v-background-base) !important;
}

.v-application {
  background-color: var(--v-background-base) !important;
}

.v-card {
  background-color: var(--v-card_background-base) !important;
  border-radius: 20px !important;
}
.v-card::before {
   border-radius: 20px !important;
 }

</style>