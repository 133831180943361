export interface TastingProductPrice {
    id: number;
    unitPrice: number;
    currency: string;
}

export function createTastingProductPrice(tastingProductPrice: any) {
    return <TastingProductPrice>{
        id: tastingProductPrice.id,
        unitPrice: tastingProductPrice.unit_price,
        currency: tastingProductPrice.currency,
    }
}