import {ServiceApiMixin} from "@/api/serviceApiMixin";
import {requiresLogin} from "@/api/apiBase";
import {UserId} from "@/types/user";
import {MyCollections} from "@/api/types/my-collections";
import {createTableFromDatabase, Table} from "@/types/table";
import JurorCider from "@/types/jurorCider";
import {createTischfunktionFromDatabase, Tischfunktion} from "@/types/tischfunktion";
import {Bewertung, BewertungData} from "@/types/bewertung";
import {FlightsCidersFile} from "@/types/producerCider";
import {createTranslationMessagesFromDb} from "@/types/translationMessages";


class JurorApiMixin extends ServiceApiMixin {

    @requiresLogin
    async jurorGetTablesAndFlights(tastingId: number): Promise<Table[] | null> {
        const resp = await this.sdk.items("tables").readByQuery({
            fields: [
                "*",
                "juror_ids.directus_users_id.*",
                "flight_ids.sort",
                "flight_ids.flights_id.id",
                "flight_ids.flights_id.cider_ids",
                "flight_ids.flights_id.name",
            ],
            filter: {
                juror_ids: {directus_users_id: {_in: this.user?.id}},
                tasting: {_eq: tastingId}
            },
        });
        if (resp?.data == null) return null;
        return resp.data.map(table => createTableFromDatabase(table));
    }

    @requiresLogin
    async getRequestedCiders(flightIds: number[]) {
        const resp: any = await this.sdk.items("flights").readByQuery({
            fields: ['cider_ids.request_cider', 'cider_ids.ciders_id', 'cider_ids.flights_id', 'cider_ids.id'],
            filter: {
                id: { _in : flightIds, }
            },
            limit: -1,
        });
        return resp.data.flatMap((d: any) => d.cider_ids);
    }

    @requiresLogin
    async getAllJurorsMarkedFinished(cider: JurorCider): Promise<boolean> {
        const jurorNotes = await this.sdk.items("cider_notes").readByQuery({
            fields: "finished",
            filter: {
                flights_ciders_id: { _eq: cider.flightsCidersId },
            },
            limit: -1,
        });
        if (jurorNotes.data == null) return false;
        return jurorNotes.data.every(idData => idData?.finished) || false;
    }

    @requiresLogin
    async getFlightsCiderNotes(flightsCidersIds: number): Promise<MyCollections["flights_ciders"] | null> {
        const resp = await this.sdk.items("flights_ciders").readOne(flightsCidersIds, {
            fields: [
                '*',
                'chair_note.*',
                'cider_notes.*',
            ],
        });
        if (resp == null) return null;
        return resp as MyCollections["flights_ciders"];
    }

    @requiresLogin
    async getFlightsCiderNotesAndRating(flightsCidersIds: number): Promise<MyCollections["flights_ciders"] | null> {
        const resp = await this.sdk.items("flights_ciders").readOne(flightsCidersIds, {
            fields: [
                '*',
                'bewertungen.*',
                'chair_note.*',
                'cider_notes.*',
            ],
        });
        if (resp == null) return null;
        return resp as MyCollections["flights_ciders"];
    }

    @requiresLogin
    async getFlightsCidersFiles(flightsCidersId: number) {
        const resp = await this.sdk.items("flights_ciders").readOne(flightsCidersId, {
            fields: ["files.*", "files.translations.*", "files.directus_files_id.*"],
        });
        console.log("FILEEEE", resp);
        if (resp == null || resp.files == null) return null;
        else return resp.files.filter((f: any) => f.directus_files_id != null)
            .map((f: any) => { return <FlightsCidersFile> {
                id: f.id,
                filesId: f.directus_files_id.id,
                flightsCidersId: flightsCidersId,
                icon: f.icon,
                name: f.name || f.directus_files_id.filename_download,
                extension: f.directus_files_id.filename_download.split('.').pop(),
                translations: createTranslationMessagesFromDb(f.translations),
            }});
    }

    @requiresLogin
    async getFlightsCidersPlain(flightCiderId: number) {
        return await this.sdk.items("flights_ciders").readOne(flightCiderId, {
            fields: '*',
        });
    }

    @requiresLogin
    async getFlightsCiders(flightsCidersIds: number[], withRatings = true, withRevealedCiderInfos = false, limit = 20) {
        const responseArray: MyCollections["flights_ciders"][] = []
        let page = 1;
        let respLength = limit;
        const fields = [
            '*',
            'chair_note.*',
            'cider_notes.*',
            'ciders_id.land.*',
            'ciders_id.profil',
            'ciders_id.profil_translations.*',
            'ciders_id.*'  // TODO: move back after award 23 to withRevealedCiderInfos!!!
        ];
        if (withRevealedCiderInfos) {
            // all fields:
            fields.push('ciders_id.*');
            fields.push('ciders_id.images.*');
            fields.push('ciders_id.produzent.produzenten_data.company_name');
            fields.push('ciders_id.produzent.name');
            fields.push('ciders_id.produzent.id');
        } else {
            fields.push(...[
                'ciders_id.date_created',
                'ciders_id.date_updated',
                'ciders_id.display_id',
                'ciders_id.flight_ids',
                'ciders_id.id',
                'ciders_id.kategorie',
                'ciders_id.produzent',
                'ciders_id.sort',
                'ciders_id.typ',
            ]);
        }
        if (withRatings) {
            fields.push('bewertungen.*');
        }
        while (respLength == limit) {
            console.log("Getting fc", page, respLength)
            const resp = await this.sdk.items("flights_ciders").readByQuery({
                fields: fields,
                filter: {
                    id: {_in: flightsCidersIds},
                },
                limit: limit,
                page: page,
            });
            if (resp.data == null) break;
            responseArray.push(...resp.data);
            respLength = resp.data.length;
            page += 1;
        }
        console.log("GOT CIDER DATA", responseArray)
        return responseArray as MyCollections["flights_ciders"][];
    }

    @requiresLogin
    async deleteBewertungen(bewertungenIds: number[]) {
        await this.sdk.items("bewertungen").deleteMany(bewertungenIds);
    }

    @requiresLogin
    async getJurorBewertungen(cider: JurorCider, jurorIds: string[]
    ): Promise<MyCollections["bewertungen"][] | null> {
        const resp = await this.sdk.items("bewertungen").readByQuery({
            fields: [
                "id",
                "eigenschaft_id",
                "cider_id",
                "juror_id",
                "flight_id",
                "flights_ciders_id",
                "punkte",
                "date_created",
                "date_updated"
            ],
            filter: {
                juror_id: { _in: jurorIds },
                flights_ciders_id: { _eq: cider.flightsCidersId }
            },
            limit: -1,  // NoJuroren * NumMaxPrüfmerkmale * NumKategorien * SafetyFactor  // TODO pagination
        });
        if (resp.data == null) return null;
        return resp.data as MyCollections["bewertungen"][];
    }

    @requiresLogin
    public async getChairNotesFieldsForCider(
        cider: JurorCider, fields: string[] = []
    ): Promise<MyCollections["chair_notes"] | null> {
        const defaultFields = [
            'date_updated',
            'restarted',
            'restarted_at',
            'restarted_count',
            'started',
            'started_at',
            'finished',
            'finished_at',
        ];
        if (cider.chairNotes?.id == null) {
            if (cider.flightsCidersId == null) {
                return null;
            }
            const resp = await this.sdk.items("chair_notes").readByQuery({
                fields: [...defaultFields, ...fields],
                filter: {
                    flights_ciders_id: { _eq: Number(cider.flightsCidersId) }
                },
                limit: 1,
            });
            return resp.data != null && resp.data.length === 1 ? resp.data[0] || null : null;
        } else {
            const resp = await this.sdk.items("chair_notes").readOne(cider.chairNotes.id, {
                fields: [...defaultFields, ...fields],
            });
            return resp || null;
        }
    }

    @requiresLogin
    async createBewertungenForCider(
        eigenschaftIds: number[],
        cider: JurorCider,
        userId: string,
        existingBewertungen: MyCollections["bewertungen"][] = []
    ): Promise<MyCollections["bewertungen"][] | null> {
        // Get EigenschaftsIds of existing Bewertungen for current Cider
        const ciderBewertungenIds = existingBewertungen.filter(
            bewertung => bewertung.flights_ciders_id === cider.flightsCidersId).map(
                bewertung => bewertung.eigenschaft_id);
        const toCreateEigenschaftenIds = eigenschaftIds?.filter(
            eigenschaftId => !ciderBewertungenIds.includes(eigenschaftId));
        const newBewertungen = toCreateEigenschaftenIds.map(eigenschaftId => ({
                eigenschaft_id: eigenschaftId,
                flight_id: cider.flightId,
                flights_ciders_id: cider.flightsCidersId,
                cider_id: cider.id,
                juror_id: userId,
            })
        );
        console.log("Creating new bewertungen for cider", cider, newBewertungen);
        const resp = await this.sdk.items("bewertungen").createMany(newBewertungen);
        return resp.data || null;
    }

    @requiresLogin
    async createBewertungenForCiders(
        data: { eigenschaftId: number, ciderId: number, flightId: number}[],
        ciders: JurorCider[]
    ) : Promise<MyCollections["bewertungen"][] | null > {
        if (data.length > 0) {
            // ciderEigenschaftIds: Containing the Cider ID and Eigenschafts ID in an Array of Tuples:
            const newBewertungen = data.map(data => ({
                    eigenschaft_id: data.eigenschaftId,
                    flight_id: data.flightId,
                    flights_ciders_id: ciders.find(cider => cider.id === data.ciderId)?.flightsCidersId,
                    cider_id: data.ciderId,
                    juror_id: this.user?.id,
                })
            );
            console.log("New to be created Bewertungen:", newBewertungen);
            const resp = await this.sdk.items("bewertungen").createMany(newBewertungen);
            return resp.data || null;
        }
        return null;
    }

    @requiresLogin
    async createNoteForCider(cider: JurorCider, userId: string): Promise<MyCollections["cider_notes"]> {
        const newCiderNote = {
            flights_ciders_id: cider.flightsCidersId,
            cider_id: cider.id,
            juror_id: userId,
            flight_id: cider.flightId,
            kommentar: "",
            is_favourite: false,
            is_pinned: false,
            started: false,
            finished: false,
        };
        console.log("Attempt to create cider_notes", newCiderNote)
        const resp = await this.sdk.items("cider_notes").createOne(newCiderNote);
        return resp as MyCollections["cider_notes"];
    }

    @requiresLogin
    async createChairNoteForCider(cider: JurorCider, chairUserId: string): Promise<MyCollections["chair_notes"]> {
        const newChairNote = {
            flights_ciders_id: cider.flightsCidersId,
            cider_id: cider.id,
            juror_id: chairUserId,
            flight_id: cider.flightId,
            kommentar: null,
        };
        const resp = await this.sdk.items("chair_notes").createOne(newChairNote);
        return resp as MyCollections["chair_notes"];
    }

    @requiresLogin
    async createNotesForCider(ciders: JurorCider[]): Promise<MyCollections["cider_notes"][]> {
        const newCiderNotes = ciders.map((cider: JurorCider) => (
            {
                flights_ciders_id: cider.flightsCidersId,
                cider_id: cider.id,
                juror_id: this.user?.id,
                flight_id: cider.flightId,
                kommentar: "",
                is_favourite: false,
                is_pinned: false,
                started: false,
                finished: false,
            })
        );
        const resp = await this.sdk.items("cider_notes").createMany(newCiderNotes);
        return resp.data as Array<MyCollections["cider_notes"]>;
    }

    @requiresLogin
    async createChairNotesForCider(ciders: JurorCider[], chairUserId: string): Promise<MyCollections["chair_notes"][]> {
        const newChairNotes = ciders.map((cider: JurorCider) => ({
            flights_ciders_id: cider.flightsCidersId,
            cider_id: cider.id,
            juror_id: chairUserId,
            flight_id: cider.flightId,
            kommentar: null,
        }));
        const resp = await this.sdk.items("chair_notes").createMany(newChairNotes);
        return resp.data as Array<MyCollections["chair_notes"]>;
    }

    @requiresLogin
    async updateNotesForCider(entryId: number, ciderId: number, payload: { [key: string]: any })
        : Promise<MyCollections["cider_notes"]> {
        const resp = await this.sdk.items("cider_notes").updateOne(entryId, {
            cider_id: ciderId,
            ...payload,
        });
        return resp as MyCollections["cider_notes"];
    }

    @requiresLogin
    async updateChairNotesForCider(entryId: number, ciderId: number, payload: { [key: string]: any })
        : Promise<MyCollections["chair_notes"]> {
        const resp = await this.sdk.items("chair_notes").updateOne(entryId, {
            cider_id: ciderId,
            ...payload,
        });
        return resp as MyCollections["chair_notes"];
    }

    @requiresLogin
    async updateNotesField<fieldType>(
        entryId: number, ciderId: number, field: string, value: fieldType
    ): Promise<fieldType> {
        const resp: any = await this.updateNotesForCider(entryId, ciderId, {[field]: value});
        return resp[field];
    }

    @requiresLogin
    async updateChairNotesField<fieldType>(
        entryId: number, ciderId: number, field: string, value: fieldType
    ): Promise<fieldType> {
        const resp: any = await this.updateChairNotesForCider(entryId, ciderId, {[field]: value});
        return resp[field];
    }

    @requiresLogin
    async resetCiderState(cider: JurorCider): Promise<MyCollections["cider_notes"][] | undefined> {
        const ids = await this.sdk.items("cider_notes").readByQuery({
            fields: "id",
            filter: {
                flights_ciders_id: { _eq : cider.flightsCidersId, },
                // cider_id: { _eq : cider.id, },
                // flight_id: { _eq : cider.flightId, },
            }
        });
        if (ids.data == null) return undefined;
        const resp = await this.sdk.items("cider_notes").updateMany(
            // @ts-ignore
            ids.data?.map(idData => idData?.id),
            {
                started: true,
                // started_at: null,  // don't update started_at when restarting
                finished: true,
                finished_at: null,
            });
        console.log(resp);
        return resp.data as MyCollections["cider_notes"][];
    }

    @requiresLogin
    async updateFlightsCidersRequest(flightsCidersId: number, value: boolean): Promise<boolean | undefined> {
        const resp = await this.sdk.items("flights_ciders").updateOne(flightsCidersId, {
            ...{request_cider: value},
        });
        if (resp == null || resp.request_cider == null) return undefined;
        return resp.request_cider;
    }

    @requiresLogin
    async updateFlightsCidersPoints(
        flightsCidersId: number,
        finalPoints: number,
        badgeId: number
    ): Promise<number | undefined> {
        const resp = await this.sdk.items("flights_ciders").updateOne(flightsCidersId, {
            points: finalPoints,
            badge: badgeId,
        });
        if (resp === null || resp === undefined) return undefined;
        return resp.points || undefined;
    }

    @requiresLogin
    async getTischfunktionen(tableIds: number[], userIds: UserId[])
        : Promise<Tischfunktion[] | undefined> {
        // console.log("Getting tischfunktionen:", tableIds, userIds)
        const resp = await this.sdk.items("tischfunktionen").readByQuery({
            fields: ["*"],
            filter: {
                table_id: { _in : tableIds, },
                user_id: { _in : userIds, },
            }
        });
        return resp.data?.map(
            data => createTischfunktionFromDatabase(data as MyCollections["tischfunktionen"]));
    }

    @requiresLogin
    async createTischfunktionen(tableIds: number[], userIds: UserId[])
        : Promise<MyCollections["tischfunktionen"][] | undefined> {
        if (tableIds.length !== userIds.length) return undefined;
        const newTischfunktionen = tableIds.map((tableId, userIdx) =>
            <MyCollections["tischfunktionen"]>{
                user_id: userIds[userIdx],
                table_id: tableId,
                next_cider_id: null,
                frage: 0,
                melden: 0,
            }
        );
        const resp = await this.sdk.items("tischfunktionen").createMany(newTischfunktionen);
        return resp.data?.map(
            data => createTischfunktionFromDatabase(data as MyCollections["tischfunktionen"]));
    }

    @requiresLogin
    async updateTischfunktionen(entryId: number, payload: { [key: string]: any })
        : Promise<Tischfunktion> {
        console.log("Update tablef", entryId, payload)
        const resp = await this.sdk.items("tischfunktionen").updateOne(entryId, {
            ...payload,
        });
        return createTischfunktionFromDatabase(resp as MyCollections["tischfunktionen"]);
    }

    @requiresLogin
    async getTablesForTastings(tastingIds: number[], userId: string): Promise<Table[] | undefined> {
        const resp = await this.sdk.items("tables").readByQuery({
            fields: [
                "*",
                "juror_ids.directus_users_id.*",
                "flight_ids.flights_id.*.*",
                "chair.*",
                "juror_ids.*"
            ],
            // fields: ["*.*", "juror_ids.directus_users_id.*", "flight_ids.flights_id.*.*"],
            filter: {
                "juror_ids":{"directus_users_id":{"id": {"_eq": userId }}},
                tasting: { _in : tastingIds, },
            }
        });
        console.log("Got Tables for Tasting:", resp.data, userId)
        return resp.data?.map(table => createTableFromDatabase(table as MyCollections["tables"]));
    }

    @requiresLogin
    async getBewertungById(bewertungsId: number): Promise<MyCollections["bewertungen"] | null> {
        const resp = await this.sdk.items("bewertungen").readOne(bewertungsId);
        return resp as MyCollections["bewertungen"];
    }

    @requiresLogin
    async updateBewertungForCider(bewertung: Bewertung | BewertungData, cider: JurorCider)
        : Promise<MyCollections["bewertungen"] | null> {
        if (bewertung.bewertungId === undefined || bewertung.eigenschaftId === undefined
            || bewertung.ciderId === undefined || bewertung.ciderId != cider.id) return null;
        const resp = await this.sdk.items("bewertungen").updateOne(bewertung.bewertungId, {
            cider_id: bewertung.ciderId,
            juror_id: this.user?.id,
            flight_id: cider.flightId,
            flights_ciders_id: cider.flightsCidersId,
            eigenschaft_id: bewertung.eigenschaftId,
            punkte: bewertung.punkte,
        });
        return resp as MyCollections["bewertungen"];
    }

}

export {JurorApiMixin}