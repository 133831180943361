<template>
  <v-container
    fluid
    class="pl-md-8 pr-md-8 pl-sm-2 pr-sm-2"
  >
    <PageNavigation
      :title-translation="$t('updateProductData')"
      :breadcrumbs="[
        {text: $t('home'), to: '/producer/home'},
        {text: selectedTasting.name, to: `/producer/submitted/overview/${selectedTasting.id}`},
        {text: $t('submitProductData'), disabled: true}
      ]"
    />

    <step-navigation
      class="pt-4"
      :prev-disabled="true"
      :next-disabled="selectedCiders.length === 0"
      hide-prev
      @next="$router.push(`/producer/submit-data/confirm-products`)"
    />

    <v-tooltip top>
      <template #activator="{ on }">
        <div v-on="on">
          <v-btn-toggle
            v-if="loaded && selectedTasting.tastingSettings.allowProductDataUpdate"
            v-model="selectProductsOrAmount"
            rounded
            text
            mandatory
            class="py-6"
            background-color="transparent"
            color="primary"
          >
            <v-btn
              :disabled="disablePlaceholderSelection"
              style="width: 50%"
              color="transparent"
              v-on="on"
            >
              <span class="mx-3">
                {{ $t('selectProducts') }}
              </span>
            </v-btn>
            <v-btn
              :disabled="disablePlaceholderSelection"
              style="width: 50%"
              color="transparent"
              v-on="on"
            >
              <span class="mx-3">
                {{ $t('chooseAmount') }}
              </span>
            </v-btn>
          </v-btn-toggle>
        </div>
      </template>
      <span>
        {{ $t('paymentHandling.selectCidersOnly', {amount: maxSelection, products: $tc('products', maxSelection), product: $tc('product', maxSelection)}) }}
      </span>
    </v-tooltip>

    <v-alert
      border="left"
      rounded="20"
      text
      type="success"
      icon="mdi-information-outline"
    >
      <v-row
        no-gutters
      >
        <v-col
          class="text-left text-body-1"
          style="min-width: 320px"
        >
          <div v-if="maxSelection === 1">
            {{ $t('purchaseMoreInfoSingular') }}
          </div>
          <div v-if="maxSelection > 1">
            {{ $t('purchaseMoreInfoPlural', { awardCount: maxSelection }) }}
          </div>
          <div class="text--secondary">
            {{ $t('moreProductsText') }}
          </div>
        </v-col>
        <v-col
          cols="auto"
          class="d-flex justify-end ml-auto"
        >
          <v-btn
            rounded
            outlined
            elevation="0"
            color="text_std lighten-3"
            class="ml-auto my-auto"
            @click="$router.push('/producer/submit/tastings')"
          >
            <v-icon left>
              mdi-basket-plus
            </v-icon>
            {{ $t('bookMoreAwards') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <div
      v-if="selectProductsOrAmount === 1"
      class="mx-auto"
    >
      <v-row
        no-gutters
        class="d-flex align-baseline text-left"
        style="max-width: 2000px;"
      >
        <v-col
          cols="12"
          class="pl-md-4 pl-2"
        >
          <div class="d-flex text-h4 mb-0 mt-0 align-center">
            {{ $t('submitProductsWithoutData') }}
          </div>
          <div class="d-flex text-body-2 mb-4 mt-1 align-center text--secondary">
            {{ $t('submitProductsWithoutDataDescription', {deadline: selectedTasting.einreichenBis.toLocaleString()}) }}
          </div>
        </v-col>
      </v-row>

      <v-card
        outlined
        class="mx-auto mt-8"
        width="400"
      >
        <v-card-title>
          {{ $t('selectAmount') }}
        </v-card-title>
        <v-icon
          class="mx-auto"
          size="400"
        >
          mdi-bottle-wine
        </v-icon>
        <div class="py-3">
          <v-btn
            rounded
            outlined
            icon
            :disabled="selectedProductAmount === 1"
            @click="selectedProductAmount <= 2 ? selectedProductAmount = 1 : selectedProductAmount -= 1"
          >
            <v-icon>
              mdi-minus
            </v-icon>
          </v-btn>
          <span class="text-h6 px-3">
            {{ selectedProductAmount }}
          </span>
          <v-btn
            rounded
            outlined
            icon
            @click="selectedProductAmount += 1"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </v-card>
    </div>

    <div
      v-if="selectProductsOrAmount === 0"
      class="ml-auto mr-auto"
      style="max-width: 2000px;"
    >
      <v-row
        no-gutters
        class="d-flex align-baseline"
        style="max-width: 2000px;"
      >
        <v-col
          cols="12"
          xs="6"
          sm="6"
          md="6"
          class="pl-md-4 pl-2"
        >
          <div class="d-flex text-h4 mb-0 mt-0 align-center">
            {{ $t('paymentHandling.myProducts') }}
          </div>
          <div
            v-if="tastingPurchase != null && tastingPurchase.status === 'paid'"
            class="d-flex text-left text-body-2 mb-4 mt-1 align-center text--secondary"
          >
            {{ $t('paymentHandling.selectCidersOnly', {amount: maxSelection, products: $tc('products', maxSelection), product: $tc('product', maxSelection)}) }}
          </div>
          <div class="d-flex text-body-2 mb-4 mt-1 align-center text--secondary">
            {{ maxSelection != null
              ? $t('paymentHandling.myProductsSelectMax', {maxProducts: maxSelection})
              : $t('paymentHandling.myProductsSelect') }}
          </div>
<!--          <v-alert-->
<!--            v-if="ciders.length === 0"-->
<!--            id="NoCiderBanner"-->
<!--            border="left"-->
<!--            rounded-->
<!--            text-->
<!--            type="warning"-->
<!--            icon="mdi-exclamation"-->
<!--          >-->
<!--            <v-row-->
<!--              no-gutters-->
<!--            >-->
<!--              <v-col-->
<!--                class="text-left text-body-1"-->
<!--                cols="12"-->
<!--                xs="12"-->
<!--                sm="8"-->
<!--              >-->
<!--                <h3>-->
<!--                  {{ $t('noProducts') }}-->
<!--                </h3>-->
<!--              </v-col>-->
<!--              <v-col-->
<!--                cols="12"-->
<!--                xs="12"-->
<!--                sm="4"-->
<!--                class="d-flex justify-end"-->
<!--              >-->
<!--                <v-btn-->
<!--                  rounded-->
<!--                  color="primary"-->
<!--                  elevation="0"-->
<!--                  class="mr-4"-->
<!--                  @click="$router.push({ path: '/producer/ciders/create',-->
<!--                                         query: { from: $router.currentRoute.path, back: 'backToSubmitProcess' } })"-->
<!--                >-->
<!--                  <v-icon-->
<!--                    left-->
<!--                    size="28"-->
<!--                  >-->
<!--                    mdi-plus-->
<!--                  </v-icon>-->
<!--                  {{ $t('addProduct') }}-->
<!--                </v-btn>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-alert>-->
        </v-col>
        <v-col
          cols="12"
          xs="6"
          sm="6"
          md="6"
        >
          <v-card-subtitle class="text-h5 text-right d-flex justify-end align-baseline">
            {{ selectedCiders.length }} {{ maxSelection !== null ? ` / ${maxSelection} ` : "" }} {{ $t('paymentHandling.xOfYSelected') }}

            <v-btn
              icon
              class="ml-2"
              @click="selectedCiderIds = []"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-subtitle>
        </v-col>
      </v-row>


      <v-row
        class="mb-2 mt-0 pt-0 px-md-4 px-2 align-center ml-auto mr-auto"
        style="max-width: 2000px;"
      >
        <v-col
          class="pa-0 pt-2 pb-2"
          cols="auto"
        >
          <div
            class="mr-auto"
          >
            <v-btn
              id="addCiderButton"
              rounded
              color="primary"
              elevation="0"
              class="mr-4"
              @click="$router.push({ path: '/producer/ciders/create',
                                     query: { from: $router.currentRoute.path, back: 'backToSubmitProcess' } })"
            >
              <v-icon
                left
                size="28"
              >
                mdi-plus
              </v-icon>
              {{ $t('addProduct') }}
            </v-btn>
            <v-menu
              v-if="selectProductsOrAmount === 0 && ciders != null && ciders.length > 0"
              offset-y
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  style="text-transform: unset;"
                  text
                  rounded
                  class="pr-0"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>
                    mdi-sort-variant
                  </v-icon>
                  {{ sortByText }}
                  <v-btn
                    class="ml-1 animate_change"
                    icon
                    :class="{rotate_180: !sortAsc}"
                    @click="sortAsc = !sortAsc"
                    @click.native.stop=""
                  >
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>
                </v-btn>
              </template>
              <v-list>
                <v-subheader>
                  {{ $t('sortBy') }}
                </v-subheader>
                <v-list-item
                  v-for="(item, index) in sortByList"
                  :key="index"
                  link
                  @click="sortBy = item.value; sortByText = item.text;"
                >
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
        <v-col
          v-if="selectProductsOrAmount === 0 && ciders != null && ciders.length > 0"
          class="pa-0 pt-2 pb-2"
        >
          <v-text-field
            v-model="searchProducts"
            append-icon="mdi-magnify"
            :label="$t('search')"
            outlined
            clearable
            dense
            rounded
            single-line
            hide-details
            class="ml-auto"
            style="max-width: 400px; min-width: 300px;"
          />
        </v-col>
      </v-row>

      <template
        v-if="loaded"
      >
        <v-item-group
          v-model="selectedCiderIds"
          active-class=""
          multiple
          :max="selectedTasting.tastingSettings.maxCiders !== null ? selectedTasting.tastingSettings.maxCiders : undefined"
        >
          <v-row no-gutters>
            <v-col
              v-for="cider in cidersFiltered"
              :key="cider.id"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
              class="pb-3 pt-3 pl-0 pr-0 pa-sm-3 align-self-stretch align-stretch"
              style="cursor: pointer;"
            >
              <v-hover
                v-slot="{ hover }"
                style="height: 100%;"
              >
                <v-item
                  v-slot="{ active, toggle }"
                  :value="cider.id"
                  style="height: 100%;"
                >
                  <div
                    style="height: 100%;"
                    @click="selectCider(toggle, cider)"
                  >
                    <cider-card
                      :cider="cider"
                      :selected="active"
                      class="ma-4"
                      style="max-width: 100%; height: 100%;"
                      :elevation="hover ? 10 : 1"
                    />
                  </div>
                </v-item>
              </v-hover>
            </v-col>
          </v-row>
        </v-item-group>
      </template>
      <v-progress-circular
        v-else
        indeterminate
        class="mt-auto mb-auto ml-auto mr-auto"
        size="92"
      />
      <Confirm_dialog ref="confirm" />
    </div>

    <step-navigation
      :prev-disabled="true"
      :next-disabled="selectedCiders.length === 0"
      hide-prev
      @next="$router.push(`/producer/submit-data/confirm-products`)"
    />
  </v-container>
</template>

<script>
import translation_mixin from "@/components/mixins/translation_mixin";
import {get, sync} from "vuex-pathify";
import {apiTyped} from "@/api";
import Confirm_dialog from "@/components/confirm_dialog.vue";
import CiderCard from "@/components/cider_card.vue";
import PageNavigation from "@/components/page_navigation.vue";
import StepNavigation from "@/components/producer/step_navigation.vue";
import producer_submit_mixin from "@/components/mixins/producer_submit_mixin";
import purchase_mixin from "@/components/mixins/purchase_mixin";

export default {
  name: "SubmitSelectProducts",
  components: {StepNavigation, PageNavigation, CiderCard, Confirm_dialog},
  mixins: [translation_mixin, producer_submit_mixin, purchase_mixin],
  async beforeRouteLeave(to, from, next) {
    if (this.matchesWhitelist(to.path) && !to.path.startsWith('/producer/submitted/overview')) next();
    const okayToLeave = await this.$refs.confirm.open(
      this.$t('unsavedChanges'), this.$t('unsavedChangesMessageProducerSubmit'),
      { color: 'secondary_card', thirdButton: true, thirdButtonText: this.$t('discard') }, {btnOkay: 'stay'}, true)
    if (okayToLeave === "third") next();
  },
  i18n: {
    messages: {
      de: {
        'purchaseMoreInfoSingular': 'Für dieses Tasting wurde der Award für 1 Produkt gebucht.',
        'purchaseMoreInfoPlural': 'Für dieses Tasting wurde der Award für {awardCount} Produkte gebucht.',
        'moreProductsText': 'Weitere Produkte für den Award können in der Event-Anmeldung gebucht werden.',
        'bookMoreAwards': 'Weitere Produkte anmelden'
      },
      en: {
        'purchaseMoreInfoSingular': 'For this Tasting an Award for 1 product was booked.',
        'purchaseMoreInfoPlural': 'For this Tasting Awards for {awardCount} products were booked.',
        'moreProductsText': 'Additional products for the award can be booked in the event registration.',
        'bookMoreAwards': 'Register more products'
      }
    }
  },
  data: () => ({
    selectProductsOrAmount: 0,
    loaded: false,
    ciders: null,
    sortAsc: true,
    sortBy: 'name',
    sortByText: '',
    searchProducts: '',
    disablePlaceholderSelection: true,
    purchasedAmount: null,
    tastingPurchases: [],
  }),
  computed: {
    selectedTasting: get('submitStore/selectedTasting'),
    selectedCiders: sync('submitStore/selectedCiders'),
    selectedTastingProducts: sync('submitStore/selectedTastingProducts'),
    tastingPurchase: sync('submitStore/tastingPurchase'),
    selectedProductAmount: sync('submitStore/selectedProductAmount'),
    submitProductsWithoutData: sync('submitStore/submitProductsWithoutData'),
    confirmedCiderIds: sync('submitStore/confirmedCiderIds'),
    selectedCiderIds: {
      get() {
        return this.selectedCiders != null ? this.selectedCiders.map(c => c.id) : [];
      },
      set(ids) {
        const oldSelectedIds = this.selectedCiders.map(c => c.id);
        const unselectedIds = oldSelectedIds.filter(oldId => !ids.includes(oldId));
        // console.log("Unselected cider ids:", unselectedIds)
        this.confirmedCiderIds = this.confirmedCiderIds.filter(id => !unselectedIds.includes(id));
        // console.log("confirmedCiderIds:", this.confirmedCiderIds)
        this.selectedCiders = this.ciders.filter(c => ids.includes(c.id));
      }
    },
    sortByList() {
      return [
        {
          text: this.$i18n.t('name'),
          value: 'name'
        },
        {
          text: this.$i18n.tc('category', 1),
          value: 'kategorie'
        },
        {
          text: this.$i18n.t('sortDateCreated'),
          value: 'dateCreated'
        },
        {
          text: this.$i18n.t('sortDateUpdated'),
          value: 'dateUpdated'
        }
      ];
    },
    cidersFiltered() {
      if (this.sortBy === ""){
        const a = 1;
      }
      const search = this.searchProducts == null ? "" : this.searchProducts.toLowerCase();
      if (this.ciders == null) return null;
      let ciderSearched = this.ciders;
      if (search !== "") {
        ciderSearched = this.ciders.filter(
          c => c.ciderInfos.name.toLowerCase().includes(search)
            || c.ciderInfos.kategorie.toLowerCase().includes(search)
            || c.ciderInfos.salesDesignation.toLowerCase().includes(search)
            || c.ciderInfos.designationOfOrigin.toLowerCase().includes(search))
      }
      ciderSearched = ciderSearched.sort(
        (c1, c2) => {
          if (this.sortBy === "name") {
            console.log("this.sortBy", this.sortBy)
            if (!this.sortAsc) return c2.ciderInfos.name.localeCompare(c1.ciderInfos.name);
            return c1.ciderInfos.name.localeCompare(c2.ciderInfos.name);
          }
          if (this.sortBy === "kategorie") {
            if (!this.sortAsc) return c2.ciderInfos.kategorie.localeCompare(c1.ciderInfos.kategorie);
            return c1.ciderInfos.kategorie.localeCompare(c2.ciderInfos.kategorie);
          }
          if (this.sortBy === "dateUpdated") {
            if (!this.sortAsc) return c2.dateUpdated - c1.dateUpdated;
            return c1.dateUpdated - c2.dateUpdated;
          }
          if (this.sortBy === "dateCreated") {
            if (!this.sortAsc) return c2.dateCreated - c1.dateCreated;
            return c1.dateCreated - c2.dateCreated;
          }
        });
      return ciderSearched;
    },
    maxSelection() {
      return this.purchasedAmount;
    },
  },
  async created() {
    this.sortByText = this.$i18n.t('name');
    if (this.selectedTasting == null) await this.$router.push('/producer/submit/tastings')
    this.ciders = await apiTyped.producerGetAllCiders(this.$store.state.producerStore.producerId, 0, 1, -1);
    if (this.selectedCiderIds.length === 0) {  // restore selected for that tasting on startup only
      const submittedCiders = await apiTyped.getSubmittedCidersForTasting(this.selectedTasting.id);
      console.log("got submitted", submittedCiders)
      this.selectedCiderIds = submittedCiders.map(submittedCider => submittedCider.cider);
    }

    if (this.selectedTasting.tastingSettings.hasPurchasables) {
      this.tastingPurchases = await apiTyped.producerGetTastingPurchases(this.selectedTasting.id);
      if (this.tastingPurchases != null && this.tastingPurchases.length > 0) {
        this.selectProductsOrAmount = 0;  // only select ciders
        this.disablePlaceholderSelection = true;
        this.purchasedAmount = this.getNumberOfAwards(this.tastingPurchases);
      }
    }

    const hydratedCiders = this.selectedCiders.filter(c => c.cider != null).map(c => c.cider);   // restore when coming from tasting overview
    console.log("hydratedCiders", hydratedCiders)
    if (hydratedCiders.length > 0) {
      this.selectedCiders = this.ciders.filter(c => hydratedCiders.includes(c.id));
    }
    this.selectedCiders = this.selectedCiders.filter(c => c.hasOwnProperty("ciderInfos"));  // remove placeholder from selection

    this.loaded = true;
  },
  methods: {
    selectCider(toggle, cider) {
      if (this.maxSelection == null) {
        toggle();
        return;
      }
      if (this.selectedCiders.length < this.maxSelection) {
        toggle();
        return;
      }
      if (this.selectedCiderIds.includes(cider.id)) {
        toggle();
      }
    }
  },
}
</script>

<style scoped>

</style>