import CiderRatingState from "@/types/ciderRatingState";
import {apiTyped} from "@/api";

export default {
    methods: {
        getProfile(product) {
            let returnTyp = "";
            const userLanguageCode = this.$store.getters["usersStore/getUserLanguage"]();
            if (product.translations != null
              && product.translations.hasOwnProperty(userLanguageCode)
              && product.translations[userLanguageCode].typ != null) {
                // if typ translation is provided by us in backend use this instead of producer profile
                console.log("Returning type", product.translations, userLanguageCode)
                returnTyp = product.translations[userLanguageCode].typ;
            }
            if (returnTyp === "" && (product.typ == null || product.typ === "")
              && product.profilTranslations != null) {
                // return product.profile
                console.log("Profile has translations__")
                const userLanguage = this.$store.state.usersStore.language;
                if (product.profilTranslations.hasOwnProperty(userLanguage)) {
                    returnTyp = product.profilTranslations[userLanguage];
                } else {
                    const defaultLanguage = this.$store.state.usersStore.DEFAULT_LANG;
                    if (product.profilTranslations.hasOwnProperty(defaultLanguage)) {
                        returnTyp = product.profilTranslations[defaultLanguage];
                    } else {
                        returnTyp = product.profil;
                    }
                }
            } else {
                // fallback for old tastings where typ was used.
                returnTyp = product.translations !== null && product.translations.hasOwnProperty(userLanguageCode)
                  ? product.translations[userLanguageCode].typ : product.typ;
            }
            if (returnTyp === "") returnTyp = product.profil;
            // const regExProducer = new RegExp(product.produzent, "ig");  // case insensitive
            // const regExProduct = new RegExp(product.name, "ig");  // case insensitive
            // return returnTyp.replace(regExProducer, "---").replace(regExProduct, "---");
            return returnTyp;
        },
        commentIsEmpty(comment) {
          return (comment === '<p></p>' || comment === '' || comment === null || comment === undefined);
        },
        getBadgeUrl (width, points = null, bestOfCategory = false) {
            if (points === null) points = this.$store.getters["ciderStore/getPointsForCurrentCider"]();
            const url = this.$store.getters["badgesStore/getBadgeIcon"](points, width, bestOfCategory);
            // console.log("Got url", url, points)
            return url;
        },
        getBadgeData (points = null, badges = null) {
            if (points === null) points = this.$store.getters["ciderStore/getPointsForCurrentCider"]();
            return this.$store.getters["badgesStore/getBadgeData"](points, badges);
        },
        getCiderStateText(cider) {
            const state = this.$store.getters["ciderStore/getCiderRatingState"](cider);
            return this.$t(String(CiderRatingState[state]));
        },
        getCiderStateIcon(cider) {
            return this.$store.getters["ciderStore/getCiderStateIconColor"](cider, this.$vuetify)[0]
        },
        getCiderStateIconColor(cider) {
            const colorString = this.$store.getters["ciderStore/getCiderStateIconColor"](cider, this.$vuetify)[1];
            return colorString;
        },
        getCiderState(cider) {
            return this.$store.getters["ciderStore/getCiderRatingState"](cider);
        },
        showBadge (points) {
            return this.$store.getters["badgesStore/showBadge"](points);
        },
        calculateTotalPointsForCider(cider, includeAllUsers = false, onlyIfUserFinished = false, decPlaces = 1) {  // TODO check if used everywhere this is used (correctly):? $store.getters["ciderStore/getTotalPointsForCider"]
            // includeAllUsers: true -> count points for every user regardless the rating state finished
            if (cider === null) return 0;
            let finishedUsers = null;
            if (!includeAllUsers) {
                finishedUsers = this.$store.getters["ciderStore/getFinishedUserIds"](cider);
                if (finishedUsers.length === 0) return 0;  // return "--";
            } else {

            }
            if (onlyIfUserFinished && !this.$store.getters["ciderStore/userBewertungFinished"](cider)) {
                return 0;
            } else{
                // console.log("CALC POIJNTS", finishedUsers, this.$store.getters["ciderStore/getTotalPointsForCider"](cider, 1, finishedUsers))
                return this.$store.getters["ciderStore/getTotalPointsForCider"](cider, decPlaces, finishedUsers);
            }
        },
        calculateUserPointsForCider(cider) {
            return this.$store.getters["ciderStore/getPointsForCurrentCider"]();
        },
        getChipColor(cider) {
            const state = this.getCiderState(cider)
            if (state === CiderRatingState.notStarted) return '#e0e0e0';
            if (state === CiderRatingState.finishedByChair) return '#e0e0e0';
            return "primary_container";
        },
        // async updateNextCider(nextCiderId, forceId = undefined) {
        //     const payload = {}
        //     console.log("Updating next cider", nextCiderId)
        //     if (nextCiderId === null) {
        //         const nextCider = this.$store.getters["ciderStore/getNextCider"]();
        //         payload['next_cider_id'] = nextCider !== undefined ? nextCider.id : null;
        //     } else payload['next_cider_id'] = null;
        //     if (forceId !== undefined) payload['next_cider_id'] = forceId;
        //     const newValues = await apiTyped.updateTischfunktionen(this.tischfunktionen.id, payload);
        //     console.log("Updating next cider, values", newValues)
        //     return newValues;
        // },
        async requestCider(cider, value = undefined) {
            if (value === undefined) value = !cider.requestCider;
            return await apiTyped.updateFlightsCidersRequest(cider.flightsCidersId, value);
        },
        getNextCiderId() {
            const nextCider = this.$store.getters["ciderStore/getNextCider"]();
            if (nextCider !== undefined && nextCider !== null) return nextCider.displayId
            else return null
        },
    }
};

