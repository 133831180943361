<template>
  <v-container
    class="d-flex flex-column ml-auto mr-auto"
  >
    <v-row
      align="baseline"
      justify="space-around"
      class="mr-auto py-4 ml-1 ml-sm-0"
    >
      <v-btn
        v-if="fromPage != null && backText !== ''"
        text
        @click="$router.push(fromPage)"
      >
        <v-icon
          left
          small
        >
          mdi-arrow-left
        </v-icon>
        {{ $t(backText) }}
      </v-btn>
    </v-row>

    <PageNavigation
      :title-translation="$t('createProduct')"
      :breadcrumbs="fromPage != null ? [] : breadcrumbs"
    />
    <ProducerEditCiderForm
      v-show="loaded"
      :cider="null"
      :check-required-fields="false"
      :back-route="fromPage"
      :back-text="backText"
      @loaded="setLoaded"
      @changeCounter="changeCounter"
    />
    <v-progress-circular
      v-if="!loaded"
      indeterminate
      class="mt-auto mb-auto ml-auto mr-auto"
      size="92"
    />
    <Confirm_dialog ref="confirm" />
  </v-container>
</template>

<script>
import ProducerEditCiderForm from "@/components/producer/producer_edit_cider_form";
import Confirm_dialog from "@/components/confirm_dialog";
import PageNavigation from "@/components/page_navigation";
import i18n from "@/i18n";

export default {
  name: "ProducerCreateCiderPage",
  components: {PageNavigation, Confirm_dialog, ProducerEditCiderForm},
  async beforeRouteLeave(to, from, next) {
    if (this.formChangeCounter > 0) {
      if (to.path === "/login") next();
      const okayToLeave = await this.$refs.confirm.open(
        this.$t('unsavedChanges'), this.$t('unsavedChangesMessageProducer'),
        {color: 'secondary_card', thirdButton: true, thirdButtonText: this.$t('discard')}, {btnOkay: 'stay'})
      if (okayToLeave === "third") next();
    } else {
      next();
    }
  },
  data: () => ({
    cider: null,
    loaded: false,
    formChangeCounter: 0,
  }),
  computed: {
    fromPage() {
      return this.$route.query.from != null ? String(this.$route.query.from) : null;
    },
    backText() {
      return this.$route.query.back != null ? this.$route.query.back : '';
    },
    breadcrumbs() {
      return [
        {
          text: this.$i18n.t('myProducts'),
          disabled: false,
          to: '/producer/home',
        },
        {
          text: this.$i18n.t('createProduct'),
          disabled: true,
        },
      ];
    }
  },
  methods: {
    setLoaded(loaded) {
      this.loaded = loaded;
    },
    async toDashboard() {
      await this.$router.push("/producer/home");
    },
    changeCounter(val) {
      this.formChangeCounter = val;
    }
  }
}
</script>

<style scoped>

</style>