<template>
  <v-card
    ref="card"
    :outlined="$vuetify.theme.dark"
  >
    <v-card-title class="text-h4 text-no-wrap">
      Best of Category
    </v-card-title>
    <v-card-text
      v-if="bestOfCategoryCiders.length === 0"
      class="text--secondary"
    >
      No ratings yet
    </v-card-text>
    <template
      v-for="(cider, index) in bestOfCategoryCiders"
    >
      <v-list-item
        :key="`item_${cider.id}_${index}`"
        three-line
      >
        <v-list-item-content class="pt-1 pb-1">
          <v-list-item-subtitle
            class="text-h6 mb-1 text-left"
            style="font-size: 14pt !important;"
          >
            {{ cider.kategorie.displayId }}: {{ cider.kategorie.name }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            class="text-h6 mb-0 text-left"
            style="font-size: 12pt !important;"
          >
            {{ cider.name }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            class="d-flex justify-start align-center mb-0 text-left"
            style="max-height: 28px; text-overflow: ellipsis; overflow: visible"
          >
            {{ cider.produzent || "---" }}, {{ cider.land !== null ? cider.land.name : "" }}
            <country-flag
              class="elevation-2"
              :country="cider.land.code"
              size="normal"
            />
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action
          class="d-flex align-start justify-center mt-1 mb-1"
          style="min-width: 66px;"
        >
          <v-avatar
            class="d-flex justify-center ma-auto"
            size="42"
          >
            <v-img :src="getBadgeUrl(100, cider.badgeData.punkteMin, true)" />
          </v-avatar>
          <ChipPoints
            :points="cider.totalPoints"
            :max-width="62"
            :is-small="true"
            class="mt-2"
            style="font-size: 9pt;"
          />
        </v-list-item-action>
      </v-list-item>
      <v-divider
        v-if="index < bestOfCategoryCiders.length - 2
          && bestOfCategoryCiders[index].kategorie.id !== bestOfCategoryCiders[index + 1].kategorie.id"
        :key="`div_${cider.id}_${index}`"
        class="d-flex flex-column pb-1"
      />
    </template>
  </v-card>
</template>

<script>
import CountryFlag from "vue-country-flag";
import cider_mixin from "@/components/mixins/cider_mixin";
import ChipPoints from "@/components/chip_points";

export default {
  name: "CardBestOfCategories",
  components: {ChipPoints, CountryFlag},
  mixins: [cider_mixin],
  props: {
    allCiders: {type: Array, required: true, default: null},
  },
  computed: {
    bestOfCategoryCiders() {
      return this.$store.getters["ciderStore/getBestOfCategory"](
        this.allCiders.filter(c => c.totalPoints > 0) || []);
    }
  },
  mounted() {
    if (this.$refs.card != null)
      this.$emit("size", {name: "CardBestOfCategories", size: this.$refs.card.$el.clientHeight})
  },
}
</script>

<style scoped>

</style>