<template>
  <div
    v-if="cider !== null"
    style="transform: translateY(-20px);"
  >
    <GesamtauswertungTableHeader
      :cider="cider"
      :show-individual="showIndividual"
    />
    <GesamtauswertungTableKategorie
      v-for="(bewertungsKategorie, index) in cider.kategorie.bewertungskategorien"
      :key="bewertungsKategorie.pruefmerkmal"
      :bewertungskategorie="bewertungsKategorie"
      :is-last="index === cider.kategorie.bewertungskategorien.length - 1"
      :cider="cider"
      :show-individual="showIndividual"
    />
    <v-divider style="margin-top: 1px; border-color: var(--v-divider_light-base) !important" />
    <div class="parent_grid pt-2 pb-2">
      <div class="two_col d-flex justify-start pl-6">
        <span class="font-weight-bold">
          {{ $t('totalScorePossible') }} (max. {{ maxPoints }})</span>
      </div>
      <div
        class="one_col d-flex justify-center pr-1 font-weight-bold"
        style="border-right: 1px solid var(--v-divider_light-base)"
      >
        {{ cider.points }}
<!--        {{ $store.getters["ciderStore/getTotalPointsForCider"](-->
<!--          cider, 0.1, $store.getters["ciderStore/getFinishedUserIds"](cider)) }}-->
      </div>
      <div class="one_col font-weight-bold">
        {{ maxPoints }}
      </div>
    </div>
    <div class="parent_grid text-left pt-6">
      <div
        class="one_col text-right pr-4"
        style="border-right: 1px solid var(--v-divider_light-base);"
      >
        {{ $t('Summary.headerTextFinalComment') }}
      </div>
      <div
        class="three_col pl-4"
        style="text-align: unset !important;"
      >
        <span
          v-if="ciderPrepped.chairNotes != null"
          class="comment justify-start text-left"
          :class="{'small-comment': ciderPrepped.chairNotes != null && ciderPrepped.chairNotes.kommentar != null && ciderPrepped.chairNotes.kommentar.length > 450}"
          v-html="ciderPrepped.chairNotes.kommentar === '<p></p>' ? defaultKommentar : ciderPrepped.chairNotes.kommentar"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GesamtauswertungTableHeader from "@/components/admin/gesamtauswertung/table_header";
import GesamtauswertungTableKategorie from "@/components/admin/gesamtauswertung/table_kategorie";
import cider_mixin from "@/components/mixins/cider_mixin";
import JurorCider from "@/types/jurorCider";
import i18n from '@/i18n'

export default {
  name: "GesamtauswertungTable",
  components: {GesamtauswertungTableKategorie, GesamtauswertungTableHeader},
  mixins: [cider_mixin],
  props: {
    cider: {type: JurorCider, required: true, default: () => null},
    showIndividual: {type: Boolean, required: true, default: true},
    maxPoints: {type: Number, required: false, default: 120},
  },
  data: () => ({
    defaultKommentar: "Kein Kommentar",
  }),
  computed: {
    ciderPrepped() {
      const cider_ = this.cider;
      if (cider_.chairNotes == null) cider_.chairNotes = { kommentar: this.defaultKommentar };
      return cider_
    }
  }
}
</script>

<style scoped>
/deep/ .parent_grid {
  display: grid;
  grid-template-columns: 6fr 12fr repeat(2, 1.25fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
}

/deep/ .one_col {
  grid-column: auto;
}
/deep/ .two_col {
  grid-column: auto / span 2;
}
/deep/ .three_col {
  grid-column: auto / span 3;
}
/deep/ .comment  *  {
  text-align: left !important;
  margin-bottom: 2px;
}

.small-comment {
  font-size: 9pt !important;
}
</style>