<template>
  <v-app style="margin-top: 0 !important; padding-top: 0 !important;">
    <v-main
      style="background-color: white !important; overflow-x: visible;"
      class="pa-0 ma-0"
    >
      <v-container
        id="allCider"
        fluid
        class="pa-0 ma-0"
      >
        <LoadingCircle
          v-if="loading"
        />
        <template v-if="!loading && filteredCiders.length !== 0">
          <v-btn
            fab
            fixed
            bottom
            right
            x-large
            class="mr-8 mb-8 no-print"
            @click="print"
          >
            <v-icon>
              mdi-printer
            </v-icon>
          </v-btn>
          <v-theme-provider
            v-for="(pageCider, index) in ciders"
            :key="index"
            light
          >
            <div class="page-padding">
              <GesamtauswertungPage
                id="page"
                ref="pagePDF"
                :cider="pageCider"
                :badges-data="pageCider.badgeData"
                :best-of-category="bestOfCategoryCiders.some(c => c.id === pageCider.id)"
                :show-individual="false"
                :badges-data-array="allBadges"
                :tasting="tasting"
                style="background-color: white !important; overflow: visible !important;"
                class="ml-auto mr-auto"
                :class="{first_load: firstLoading}"
              />
            </div>
          </v-theme-provider>
        </template>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import GesamtauswertungPage from "@/components/admin/gesamtauswertung/gesamtauswertung_page";
import {sync} from "vuex-pathify";
import LoadingCircle from "@/layouts/loading_circle";
import cider_mixin from "@/components/mixins/cider_mixin";
import {RoleName} from "@/types/user";

export default {
name: "GesamtbewertungA4",
  components: {
    LoadingCircle,
    GesamtauswertungPage
  },
  mixins: [cider_mixin],
  props: {
    // ciders: {type: Array, required: false, default: null}
  },
  data: () => ({
    cider: null,
    loading: true,
    firstLoading: true,
  }),
  computed: {
    ciders: sync("ciderStore/printCiders"),
    allBadges() {
      return this.$store.state.badgesStore.badgesData;
    },
    tasting() {
      return this.$store.getters["usersStore/currentTasting"]();
    },
    filteredCiders() {
      if (this.ciders === undefined) return [];
      // console.log("Filter:", this.ciders.filter(cider => cider != null)
      //   .map(c => {
      //     c.badgeData = this.$store.getters['badgesStore/getBadgeData'](c.totalPoints);
      //     return c;
      //   }))
      return this.ciders.filter(cider => cider != null)
        .map(c => {
          c.badgeData = this.$store.getters['badgesStore/getBadgeData'](c.totalPoints);
          return c;
        });
    },
    bestOfCategoryCiders() {
      return this.$store.getters["ciderStore/getBestOfCategory"](this.ciders);  // TODO !!!!!! use flights ciders junction data
    },
    pageHeight () {
      return document.body.scrollHeight;
    },
  },
  async created() {
    this.$vuetify.theme.dark = false;
    // const ciderId = this.$route.params.cider;
    // const flightId = this.$route.params.flight;
    // const tableId = this.$route.params.table;
    // console.log("Got cider id", ciderId, flightId);
    // await apiTyped.getTa
    // const resp = await apiTyped.getCidersPreparedByTable(table, this.kategorien);
    // const cider = ciders[0];
    // cider.flightId = Number(flightId);
    // return [allCiders, allChairNotes, allBewertungen, allUserNotes] = getAllFieldsForCiders(cider)
    if (this.ciders === null || this.ciders.length === 0 || this.ciders.every(c => c == null)) {
      console.log("NO CIDERS", this.cider)
      if (this.$store.state.appStore.authenticated === true &&
          this.$store.state.usersStore.me?.role !== RoleName.Admin) {
        await router.push("/admin")
      }
    //   const tastingsPromise = apiTyped.getTastings(true);
    //   const tablesPromise = apiTyped.getTablesForTasting(this.$store.getters["usersStore/currentTasting"]())
    //   const kategorienPromise = apiTyped.getAllKategorien();
    //   this.tastings = await tastingsPromise;
    //   this.tables = await tablesPromise;
    //   const kategorien = await kategorienPromise;
    //   this.$store.state.badgesStore.badgesData = await apiTyped.getBadges();
    //   this.$store.commit("kategorienStore/setKategorieData", kategorien);
    //   this.$store.set("usersStore/currentTastingIdx", 0);
    //   // this.$store.set("usersStore/tastings", tastings);
    //   // this.$store.set("tableStore/tables", tables);
    //   // this.$store.set("kategorienStore/kategorienRated", kategorienRated);
    //   this.loaded = true;
    //   console.log("Got table---", this.tables)
    //   const ciders = await apiTyped.getCidersPreparedByTable(table, this.kategorien);
    //   const cider = ciders[0];
    //   cider.flightId = Number(flightId);
    //   return [allCiders, allChairNotes, allBewertungen, allUserNotes] = getAllFieldsForCiders(cider)
    }
    this.ciders.sort((c1, c2) => c2.points - c1.points);
  },
  mounted() {
    // this.$vuetify.goTo(this.pageHeight, {duration: 0, easing: 'linear'})
    // window.setTimeout(() => {
    //   this.firstLoading = false;
    // }, 1000);
    // // const loadingDuration = this.pageHeight / 3;
    // const loadingDuration = 1000;
    // window.setTimeout(() => {
    //   this.$vuetify.goTo(this.pageHeight, {duration: 0})
    // }, 400);
    // window.setTimeout(() => {
    //   this.$vuetify.goTo(0, {duration: 1000, easing: 'easeInOutCubic'})
    // }, loadingDuration);
    // window.setTimeout(() => {
    //   this.loading = false;
    // }, loadingDuration * 1.5);
    // window.setTimeout(() => {
    //   this.$nextTick(() => {
    //     window.print();
    //   });
    // }, loadingDuration * 2);
    this.loading = false;
    window.setTimeout(() => { this.firstLoading = false; }, 1000);
  },
  methods: {
    print() {
      window.print();
    },
  }
}
</script>

<style scoped>
.first_load {
  position: absolute;
  top: 0;
  left: 0;
}

.page-padding {
    padding-top: 8px;
    padding-bottom: 8px;
}

@media print {
  .page_print {
    margin: 0 !important;
    padding: 32px !important;
  }
  .page-padding {
    padding: 0;
  }
}

@page {
  size: portrait;
  margin: 0 0 0 0 !important;
}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>