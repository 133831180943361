import Vue from 'vue';
import Vuex from 'vuex';
import {make} from 'vuex-pathify';
import {DynamicIsland} from "@/types/dynamicIsland";
// import store, {rootGetter} from "@/stores";

Vue.use(Vuex);

type storeStates = typeof state;
// @ts-ignore
// type storeGetters = typeof getters;
// type rootStates = typeof store.state;
// type rootGetters = typeof rootGetter;

const state: {
    isExpanded: boolean,
    islands: DynamicIsland[]
} = {
    isExpanded: false,
    islands: [],
}

// @ts-ignore
const getters = {
    ...make.getters(state),
}

const mutations = {
    ...make.mutations(state),
    addIsland(state: storeStates, island: DynamicIsland) {
        state.islands.push(island);
        state.islands.sort((i1, i2) => i2.priority - i1.priority);
    },
    clearIslands(state: storeStates) {
        state.islands = [];
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}