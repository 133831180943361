<template>
  <v-container
    fluid
    class="pl-md-8 pr-md-8 pl-sm-2 pr-sm-2"
  >
    <PageNavigation
      :title-translation="$t('updateProductData')"
      :breadcrumbs="[
        {text: $t('home'), to: '/producer/home'},
        {text: selectedTasting.name, to: `/producer/submitted/overview/${selectedTasting.id}`},
        {text: $t('submitProductData'), disabled: true}
      ]"
    />

    <step-navigation
      class="pt-4"
      :next-disabled="!allConfirmed"
      :prev-disabled="false"
      @prev="$router.push(`/producer/submit-data/select-products`)"
      @next="$router.push(`/producer/submit-data/overview`)"
    />

    <div
      v-if="loaded"
      class="ml-auto mr-auto"
      style="max-width: 1200px;"
    >
      <v-row
        no-gutters
        class="d-flex align-baseline pl-0 pr-0"
        style="max-width: 1500px;"
      >
        <v-col
          cols="12"
          xs="8"
          sm="8"
          md="8"
        >
          <div class="d-flex text-h4 mb-0 mt-4 align-center text-left">
            {{ $t('paymentHandling.confirmProductData') }}
          </div>
          <div class="d-flex text-body-2 mb-8 mt-1 align-start text-left text--secondary">
            {{ $t('paymentHandling.confirmProductDataExplanation') }}
          </div>
        </v-col>
        <v-col
          cols="12"
          xs="4"
          sm="4"
          md="4"
        >
          <v-card-subtitle class="text-h5 text-right d-flex justify-end align-baseline">
            {{ confirmedCiderIdx.filter(e => e === true).length }} / {{ confirmedCiderIdx.length }} {{ $t('confirmed') }}
          </v-card-subtitle>
        </v-col>
      </v-row>
      <v-card
        v-for="(cider, index) in selectedCiders"
        :key="cider.id"
        flat
        outlined
        :elevation="expandedCiders[index] ? 3 : 0"
        color="background"
        class="ml-auto mr-auto mb-6 animate_change"
        style="cursor: pointer; max-width: 1000px !important; border-color: var(--v-divider-base) !important;"
        :style="{'max-width': expandedCiders[index] ? '1000px' : '550px'}"
      >
        <div
          :id="`cider_${index}`"
          v-ripple
          class="d-flex text-h5 text-left pb-2 pt-2 align-center expand-cider-card"
          @click="expandCider(index)"
        >
          <v-btn
            icon
            class="mr-1 ml-1"
          >
            <v-icon :class="{rotate_180: expandedCiders[index]}">
              mdi-chevron-down
            </v-icon>
          </v-btn>
          <v-row
            no-gutters
            class="d-flex"
          >
            <v-col
              cols="9"
            >
              <span
                v-show="expandedCiders[index]"
              >
                {{ index + 1 }}. {{ $t('paymentHandling.submissionCounter') }}
              </span>
              <span
                v-show="!expandedCiders[index]"
                class="subtitle-1 text--secondary"
              >
                {{ index + 1 }}. {{ $t('paymentHandling.submissionCounter') }}
              </span>
              <v-card-title
                v-show="!expandedCiders[index]"
                class="text-break pa-0"
              >
                {{ cider.ciderInfos.name }}
              </v-card-title>
              <v-card-subtitle
                v-show="!expandedCiders[index]"
                class="pa-0 pt-4 pb-2"
              >
                {{ cider.ciderInfos.kategorie }}
              </v-card-subtitle>
            </v-col>
            <v-col
              cols="3"
              class="d-flex justify-end align-start mt-1"
            >
              <v-chip
                v-if="confirmedCiderIdx[index]"
                color="primary"
                class="ml-4 mr-4"
                style="min-width: 32px;"
              >
                <v-icon class="ml-auto mr-auto">
                  mdi-check
                </v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </div>
        <v-expand-transition>
          <div v-show="expandedCiders[index]">
            <ProducerEditCiderForm
              :ref="`expandCider_${index}`"
              :cider="cider"
              :desired-translations="selectedTasting.tastingSettings.ciderInfoLanguages"
              :all-categories="kategorien"
              category-required
              :countries="countries"
              :no-preview="true"
              :confirm-checkbox="true"
              :confirm-checkbox-state="confirmedCiderIdx[index]"
              class="pb-2"
              @confirm="confirmData(index, ...arguments)"
              @updateInfos="updateCiderInfos(cider, ...arguments)"
            />
          </div>
        </v-expand-transition>
      </v-card>
    </div>
    <v-progress-circular
      v-if="!loaded"
      indeterminate
      class="mt-auto mb-auto ml-auto mr-auto"
      size="92"
    />
    <Confirm_dialog ref="confirm" />
  </v-container>
</template>

<script>
import translation_mixin from "@/components/mixins/translation_mixin";
import ProducerEditCiderForm from "@/components/producer/producer_edit_cider_form.vue";
import Confirm_dialog from "@/components/confirm_dialog.vue";
import StepNavigation from "@/components/producer/step_navigation.vue";
import PageNavigation from "@/components/page_navigation.vue";
import {get, sync} from "vuex-pathify";
import {apiTyped} from "@/api";
import Vue from "vue";
import producer_submit_mixin from "@/components/mixins/producer_submit_mixin";

export default {
  name: "SubmitConfirmProducts",
  components: {PageNavigation, StepNavigation, Confirm_dialog, ProducerEditCiderForm},
  mixins: [translation_mixin, producer_submit_mixin],
  async beforeRouteLeave(to, from, next) {
    if (this.matchesWhitelist(to.path) && !to.path.startsWith('/producer/submitted/overview')) next();
    const okayToLeave = await this.$refs.confirm.open(
      this.$t('unsavedChanges'), this.$t('unsavedChangesMessageProducerSubmit'),
      { color: 'secondary_card', thirdButton: true, thirdButtonText: this.$t('discard') }, {btnOkay: 'stay'}, true)
    if (okayToLeave === "third") next();
  },
  data: () => ({
    loaded: false,
    expandedCiders: [],
    confirmedCiderIdx: [],
  }),
  computed: {
    selectedTasting: get('submitStore/selectedTasting'),
    selectedCiders: sync('submitStore/selectedCiders'),
    confirmedCiderIds: sync('submitStore/confirmedCiderIds'),
    kategorien: sync('submitStore/allCategories'),
    countries: sync('submitStore/allCountries'),
    allConfirmed() {
      return this.confirmedCiderIdx.every(s => s === true);
    },
  },
  async created() {
    this.expandedCiders = Array(this.selectedCiders.length).fill(true);
    this.confirmedCiderIdx = Array(this.selectedCiders.length).fill(false);

    if (this.selectedTasting == null || this.selectedCiders == null) await this.$router.push('/producer/submit/tastings')

    this.selectedCiders.forEach((cider, index) => {
      if (this.confirmedCiderIds.includes(cider.id)) {
        this.confirmedCiderIdx[index] = true;
        this.expandedCiders[index] = false;  // minimize confirmed ciders
      }
    });

    if (this.countries == null || this.countries.length === 0) {
      this.countries = await apiTyped.getAllCountries() || [];
    }
    // if (this.kategorien === null) this.kategorien = await apiTyped.getActiveKategorien() || [];
    this.kategorien = await apiTyped.getAllKategorien(this.selectedTasting.id);
    this.loaded = true;
  },
  methods: {
    updateCiderInfos(cider, infos) {
      cider.ciderInfos = infos;
    },
    expandCider(at) {
      Vue.set(this.expandedCiders, at, !this.expandedCiders[at]);
      this.$nextTick(this.$refs[`expandCider_${at}`][0].resizedWindow("submit"))
    },
    confirmData(index, val) {
      Vue.set(this.confirmedCiderIdx, index, val);
      const ciderId = this.selectedCiders[index].id;
      if (val) {
        if (!this.confirmedCiderIds.includes(ciderId)) this.confirmedCiderIds.push(ciderId);
        this.$store.set('submitStore/confirmedCiderIds', this.confirmedCiderIds);  // for persistence
      } else {
        console.log(ciderId, this.confirmedCiderIds, this.confirmedCiderIdx)
        const foundIndex = this.confirmedCiderIds.findIndex(c => c === ciderId);
        if (foundIndex !== -1) this.confirmedCiderIds.splice(foundIndex, 1);
      }
      if (val) {
        this.expandCider(index);
        this.$vuetify.goTo(document.getElementById(`cider_${index}`), {duration: 200, offset: 300});
      }
    },
  },
}
</script>

<style scoped>

</style>