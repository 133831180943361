<template>
  <div class="pb-2">
    <v-alert
      v-if="(cider.requestCider || isAutoRequested) && !showUndo"
      v-ripple
      border="left"
      colored-border
      color="primary"
      text
      :icon="alertIcon"
      class="pa-0 pl-4 pt-2 pb-0 ml-4 mr-4 mb-0 rounded-lg"
      style="cursor: pointer;"
      @click="undoRequest"
    >
      <div
        class="pl-2 pr-2 mr-4 pb-1"
        style="display: flex; flex-direction: column; min-width: 0;"
      >
        <v-tooltip
          top
        >
          <template #activator="{ on }">
            <div
              class="pa-0 text-subtitle-1 pb-0 text-left"
              :class="{'text--secondary': currentCider, 'font-weight-bold': boldText}"
              style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; line-height: 1.2;"
              v-on="on"
            >
              {{ prefixCiderName }}{{ cider.name }}
            </div>
          </template>
          {{ prefixCiderName }}{{ cider.name }}
        </v-tooltip>
        <v-card-text
          class="pa-0 pb-0 text--secondary font-weight-400 text-left"
          style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
        >
          <strong>{{ cider.displayId }}</strong>:
          <v-icon
            small
            class="pl-1 pr-1"
            :color="getCiderStateColorByState(cider)"
          >
            {{ getCiderStateIconByState(cider) }}
          </v-icon>
          {{ cider.kategorie.name }}
        </v-card-text>
      </div>
      <div
        v-if="progress != null"
        class="ml-2 mr-4 pb-2"
      >
        <v-progress-linear
          height="12"
          :value="progress"
          color="secondary"
          class="rounded-xl"
        >
        </v-progress-linear>
      </div>
    </v-alert>
    <template v-else>
      <div
        class="pl-4 pr-2 mr-4 pb-0"
        style="display: flex; flex-direction: column; min-width: 0;"
      >
        <v-tooltip
          top
        >
          <template #activator="{ on }">
            <div
              class="pa-0 text-subtitle-1 text-left"
              :class="{'text--secondary': currentCider, 'font-weight-bold': boldText}"
              style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; line-height: 1.2;"
              v-on="on"
            >
              {{prefixCiderName}}{{ cider.name }}
            </div>
          </template>
          {{prefixCiderName}}{{ cider.name }}
        </v-tooltip>
        <v-card-text
          class="pa-0 pb-0 text--secondary font-weight-400 text-left"
          style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
        >
          <strong>{{ cider.displayId }}</strong>:
          <v-icon
            small
            class="pl-1 pr-1"
            :color="getCiderStateColorByState(cider)"
          >
            {{ getCiderStateIconByState(cider) }}
          </v-icon>
          {{ cider.kategorie.name }}
        </v-card-text>
      </div>
      <div
        v-if="showUndo"
        class="d-flex text-left pl-4"
        style="position: relative;"
      >
        <div
          class="pl-3"
          style="position: absolute; right: 16px; top: -38px; background-color: var(--v-card_background-base) !important;"
        >
          <v-btn
            elevation="0"
            color="primary"
            class="progress-btn pa-0"
            rounded
            @click="cancelUndoRequest"
          >
            <v-progress-linear
              :value="undoProgress"
              :color="$vuetify.theme.dark ? 'primary darken-3' : 'primary lighten-2'"
              rounded
              class="animate-progress rounded-xl"
              style="width: 100%; height: 100%;"
            >
              <span style="color: white;">
                Undo
              </span>
            </v-progress-linear>
          </v-btn>
        </div>
      </div>
      <div
        v-if="progress != null"
        class="ml-4 mr-4 pb-0 pt-1"
      >
        <v-progress-linear
          height="12"
          :value="progress"
          color="secondary"
          class="rounded-xl"
        />
      </div>
    </template>
  </div>
</template>

<script>
import JurorCider from "@/types/jurorCider";
import {apiTyped} from "@/api";

export default {
  name: "TableServiceCardAlert",
  props: {
    cider: {type: [Object, JurorCider], required: true, default: null},
    progress: {type: Number, required: false, default: null},
    alertIcon: {type: String, required: true, default: ""},
    prefixCiderName: {type: String, required: false, default: ""},
    currentCider: {type: Boolean, required: false, default: false},
    boldText: {type: Boolean, required: false, default: false},
  },
  data: () => ({
    showUndo: false,
    undoProgress: 100,
    pendingUndo: null,
  }),
  computed: {
    isAutoRequested() {
      return this.cider.hasOwnProperty('autoRequestCider') && this.cider.autoRequestCider === true;
    },
  },
  methods: {
    getCiderStateColorByState(cider) {
      return this.$store.getters["ciderStore/getCiderStateIconColorByState"](cider.chairRatingState, this.$vuetify)[1];
    },
    getCiderStateIconByState(cider) {
      return this.$store.getters["ciderStore/getCiderStateIconColorByState"](cider.chairRatingState, this.$vuetify)[0];
    },
    async undoRequest() {
      this.showUndo = true;
      this.$nextTick(() => {this.undoProgress = 0;});
      if (this.pendingUndo != null) window.clearTimeout(this.pendingUndo);
      this.pendingUndo = window.setTimeout(async() => {await this.handleUndoRequest()}, 5000);
      this.$emit('rerender');
    },
    cancelUndoRequest() {
      this.showUndo = false;
      this.$nextTick(() => {this.undoProgress = 100;});
      if (this.pendingUndo != null) window.clearTimeout(this.pendingUndo);
      this.$emit('rerender');
    },
    async handleUndoRequest() {
      if (!this.showUndo) {  // cancelled by undo button click
        this.undoProgress = 100;
        return;
      }
      if (this.isAutoRequested === true) {
        this.$emit('updateAutoRequestedCider', this.cider);
        this.showUndo = false;
      } else {
        const updatedValue = await apiTyped.updateFlightsCidersRequest(this.cider.flightsCidersId, false);
        if (updatedValue === false) {
          this.$emit('updateRequestedCider', this.cider);
          this.showUndo = false;
        }
      }
    }
  }
}
</script>

<style scoped>
/deep/ .v-alert__wrapper .v-alert__content {
  flex: 1 !important;
  overflow: hidden;
}

/deep/ .v-progress-linear__determinate {
  transition: width 5s linear !important;
}

/deep/ .progress-btn > .v-btn {
  padding: 0 !important;
}

/deep/ .progress-btn > .v-btn__content {
  height: 100% !important;
}
</style>