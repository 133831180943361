<template>
  <div
    class="d-flex pl-3"
    style="min-height: 90vh"
  >
    <v-bottom-sheet v-model="showAddJudgesPanel">
      <v-sheet
        class="text-center"
        height="95vh"
      >
        <table-designer-add-judges
          @close="showAddJudgesPanel = false"
        />
      </v-sheet>
    </v-bottom-sheet>

    <div class="flex-grow-1">
      <v-toolbar
        style="position: sticky !important; top: 0;"
        color="background"
        flat
      >
        <v-btn
          elevation="0"
          color="primary"
          @click="showAddJudgesPanel = true"
        >
          <v-icon class="pr-1">
            mdi-plus
          </v-icon>
          Add Judges
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="isSmallScreen"
          text
          outlined
          @click="showRightDrawer = !showRightDrawer"
        >
          Show Judges
        </v-btn>
      </v-toolbar>
      <v-row>
        <v-col
          v-for="table in tables"
          :key="'table_' + table.id"
          cols="12"
          md="6"
          lg="4"
          xl="3"
        >
          {{ table.id }}
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="3"
          xl="3"
        >
          <v-card
            v-ripple
            style="border-style: dashed; border-width: medium; cursor: pointer;"
            outlined
            height="120"
            min-width="120"
          >
            <v-card-text>
              <v-icon size="48">
                mdi-plus-circle-outline
              </v-icon>
            </v-card-text>
            <v-card-text class="text-center mx-auto justify-center pt-0">
              Add table
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-card height="300">
        <draggable
          style="border: 1px"
          class="list-group"
          :list="list1"
          group="judges"
        >
          <div
            v-for="element in list1"
            :key="element.name"
            class="list-group-item"
          >
          </div>
        </draggable>
      </v-card>
    </div>
    <v-navigation-drawer
      ref="drawer"
      v-model="showRightDrawer"
      :width="rightDrawer.width"
      :permanent="!isSmallScreen"
      :absolute="isSmallScreen"
      :mini-variant="isMiniVariant"
      :expand-on-hover="isMiniVariant"
      hide-overlay
      right
      touchless
      tag="div"
      style="z-index: 9 !important"
      color="background_darken"
    >
      <template #prepend>
        <v-container class="d-flex justify-start pa-1 align-center">
          <v-spacer />
          <v-btn
            icon
            style="margin-right: 6px;"
            @click="showRightDrawer = !showRightDrawer"
          >
            <v-icon :class="{rotate_180: showRightDrawer}">
              mdi-arrow-collapse-left
            </v-icon>
          </v-btn>
        </v-container>
      </template>

      <v-card>
        <draggable
          class="list-group"
          :list="list2"
          group="judges"
        >
          <div
            v-for="(element, index) in list2"
            :key="element.name"
            class="list-group-item"
          >
            {{ element.name }} {{ index }}
          </div>
        </draggable>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import logos_mixin from "@/components/mixins/logos_mixin";
import 'splitpanes/dist/splitpanes.css'
import draggable from 'vuedraggable'
import {sync} from "vuex-pathify";
import TableDesignerAddJudges from "@/components/admin/tableDesigner/add_judges.vue";

export default {
  name: "TableDesignerLayout",
  components: {TableDesignerAddJudges, draggable},
  mixins: [logos_mixin],
  data() {
    return {
      showAddJudgesPanel: false,
      showRightDrawer: true,
      rightDrawer: {
        shown: false,
        oldWidth: 200,
        width: 200,
        minWidth: 200,
        borderSize: 3
      },
      touch: {
        mouseDown: false,
        dragging: false,
      },
      list1: [],
      list2: [{
        name: "John",
        "id": 1
        },
        {
          name: "Joao",
          "id": 2
        },
        ],
    }
  },
  computed: {
    tasting: sync('tableDesignerStore/tasting'),
    allJudges: sync('tableDesignerStore/allJudges'),
    selectedJudges: sync('tableDesignerStore/selectedJudges'),
    tables: sync('tableDesignerStore/tables'),
    flights: sync('tableDesignerStore/flights'),
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return true
        default:
          return false
      }
    },
    isMiniVariant() {
      return !this.showRightDrawer && !this.isSmallScreen;
    }
  },
  watch: {
    showRightDrawer(val) {
      if (!val) {
        this.rightDrawer.oldWidth = this.rightDrawer.width;
        this.rightDrawer.width = 0;
      } else {
        this.rightDrawer.width = this.rightDrawer.oldWidth;
      }
    }
  },
  mounted() {
    this.setEvents();
    if (this.isSmallScreen) {
      this.rightDrawer.width = '90%';
      this.showRightDrawer = false;
    }
  },
  async created() {
    this.tasting = this.$store.getters['usersStore/currentTasting']();
  },
  methods: {
    setEvents() {
      const minSize = this.rightDrawer.borderSize;
      const el = this.$refs.drawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const direction = el.classList.contains("v-navigation-drawer--right")
        ? "right"
        : "left";
      const rightDrawer = this.rightDrawer;

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f = 0;
        if (e.type === "touchmove") {
          f = direction === "right"
            ? document.body.scrollWidth - e.touches[0].clientX
            : e.touches[0].clientX;
        } else {
          f = direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        }
        if (f !== 0) {
          f = Math.max(f, rightDrawer.minWidth);
          el.style.width = f + "px";
        }
        pauseEvent(e);
      }

      function onMouseDown() {
        el.style.transition = "initial";
        if ('ontouchstart' in window) {
          document.addEventListener('touchmove', resize, false)
        }
        document.addEventListener("mousemove", resize, false);
      }

      function onMouseUp() {
        el.style.transition = "";
        rightDrawer.width = el.style.width;
        document.body.style.cursor = "";
        document.removeEventListener("mousemove", resize, false);
        if ('ontouchstart' in window) {
          document.removeEventListener('touchmove', resize, false)
        }
      }

      function pauseEvent(e){
        if(e.stopPropagation) e.stopPropagation();
        if(e.preventDefault) e.preventDefault();
        e.cancelBubble=true;
        e.returnValue=false;
        return false;
      }

      drawerBorder.addEventListener("mousedown", onMouseDown, false);
      document.addEventListener("mouseup", onMouseUp, false);
      if ('ontouchstart' in window) {
        drawerBorder.addEventListener('touchstart', onMouseDown, false)
        document.addEventListener('touchend', onMouseUp, false)
      }
    }
  },
}
</script>


<style scoped>
/deep/ .v-navigation-drawer__border {
  background-color: var(--v-divider-base) !important;
  width: 3px !important;
  cursor: ew-resize;
}

/deep/ .v-navigation-drawer__border:before {
  content: '';
  position: absolute;
  bottom: 0;
  top: 0;
  left: -50%;
  width: 3px;
  transition: all 0.2s;
  background-color: var(--v-divider-base);
  z-index: 999 !important;
}
/deep/ .v-navigation-drawer__border:hover:before {
  opacity: 1;
  width: 6px;
  background-color: var(--v-divider-darken2);
}
.rotate-180 {
  -moz-transform:rotate(180deg);
  -webkit-transform:rotate(180deg);
  transform:rotate(180deg);
}

</style>