<template>
  <!--  <multipane-->
  <!--    v-if="allCiders !== null"-->
  <!--    class="custom-resizer"-->
  <!--    layout="vertical"-->
  <!--  >-->
  <!--    <div class="">-->
  <CardTimeline
    v-if="allCiders !== null"
    :all-ciders="allCidersPrepped"
    :tables="tables"
    :kategorien="kategorien"
    :is-card="true"
    class="ml-2 mr-2"
    style="min-width: 20vw; max-height: calc(100vh - 128px); overflow-y: auto"
  />
<!--    </div>-->
<!--    <multipane-resizer class="pl-4 pr-4"/>-->
<!--    <div-->
<!--      :style="{ flexGrow: 1 }"-->
<!--    >-->
<!--      <iframe-->
<!--        style="width: 100%; height: 100%;"-->
<!--        :src="'https://matrim.uber.space/admin/content/ciders'"-->
<!--      />-->
<!--    </div>-->
<!--  </multipane>-->
</template>

<script>
import CardTimeline from "@/components/admin/dashboard/card_timeline";
import {sync} from "vuex-pathify";
import {apiTyped} from "@/api";
import { Multipane, MultipaneResizer } from 'vue-multipane';
import JurorCider from "@/types/jurorCider";

export default {
  name: "AdminTimeline",
  components: {
    CardTimeline,
    // Multipane,
    // MultipaneResizer
  },
  data: () => ({
    allCiders: null,
    allChairNotes: null,
    allBewertungen: null,
    allUserNotes: null,
  }),
  computed: {
    tables: sync("tableStore/tables"),
    tastings: sync("usersStore/tastings"),
    kategorien: sync("kategorienStore/kategorieData"),
    // currentTastingIdx() {
    //   return this.$store.getters["usersStore/getTastingIdxByTastingId"](Number(this.$route.query.tasting));
    // },
    // tasting: sync('usersStore/tastings@[:currentTastingIdx]'),
    tasting() {
      return this.$store.getters["usersStore/currentTasting"]();
    },
    allCidersPrepped() {
      return this.allCiders.map((cider, index) => {
        cider.uniqueId = index;
        cider.badgeData = this.$store.getters['badgesStore/getBadgeData'](cider.totalPoints);
        return cider;
      });
    },
    baseUrl() {
      return apiTyped.baseUrl + "admin/content/";
    },
  },
  async created() {
    const flightsCidersIds = this.tables.flatMap(
      table => this.$store.getters["tableStore/getFlightsCidersIds"](table));
    const flightsCiders = await apiTyped.getFlightsCiders(flightsCidersIds, false, true);
    // Add flights data to ciders object
    const flightsData = this.tables.flatMap(table => table.flights);
    flightsCiders.forEach(f => f.flights_id = flightsData.find(f_ => f_.id === f.flights_id));
    // Create ciders
    const ciders = flightsCiders.map(
      f => JurorCider.fromApiData(
        f.ciders_id,
        this.kategorien.find(k => Number(k.id) === Number(f.ciders_id.kategorie)),
        f,
        this.$store.getters["usersStore/userId"](),
        false,
        []
      )
    );
    this.allCiders = ciders;
    this.loading = false;
  },
}
</script>

<style scoped>
.vertical-panes > .pane ~ .pane {
  border-left: 1px solid #ccc;
}
.custom-resizer {
  width: 100%;
  height: 1000px;
}
.custom-resizer > .pane {
  text-align: left;
  padding: 15px;
  overflow: hidden;
  background: #eee;
  border: 1px solid #ccc;
}
.custom-resizer > .pane ~ .pane {
}
.custom-resizer > .multipane-resizer {
  margin: 0;
  left: 0;
  position: relative;
}
.multipane-resizer:before {
  display: block;
  content: "";
  width: 3px;
  height: 100%;
  border-left: 6px solid #ccc;
  border-radius: 3px;
}

.multipane-resizer:hover:before {
  border-color: #999;
}
</style>