import {createTranslationMessagesFromDb, TranslationMessages} from "@/types/translationMessages";
import {Country} from "@/types/country";
import {MyCollections} from "@/api/types/my-collections";
import {dateFromString} from "@/util/helper";
import {apiTyped} from "@/api";
import {Badge, createBadgeFromDatabase} from "@/types/badge";
import {Tasting} from "@/types/tasting";

export interface FlightsCidersFile {
    id: number;
    filesId: string;
    flightsCidersId: number;
    name: string;
    icon: string;
    translations: TranslationMessages | null;
    extension: string;
}

export interface CiderTasting {
    id: number;
    logoMesse: string;
    name: string;
    datum: Date | undefined;
    beschreibung: string;
    abbreviation: string;
    translations: TranslationMessages | null;
    expectedPublishDate: Date | null;
}

interface FlightsCiders {
    id: number;
    ciderId: number;
    points: number | undefined;
    bestOfCategory: boolean | undefined;
    badge: Badge | undefined;
    chairComment: string;
    tasting: number;
    tastingFinished: boolean;
    tastingResultPublished: boolean;
    tastingResultPublishedFor: number[];  // producer ids
    hiddenResultsForProducer: number[];  // producer ids
    expectedPublishDate: Date | null;
}

export interface CiderInfos {
    name: string;
    profil: string;
    profilTranslations: TranslationMessages | null;
    alkohol: number | undefined;
    land: Country | undefined;
    kategorieId: number | undefined;
    kategorie: string | undefined;
    kategorieTranslations: TranslationMessages | null;
    images: string[];

    vintage: string;
    salesDesignation: string;
    designationOfOrigin: string;
    varieties: string;
    residualSugar: number;
    residualSugarDescription: string;
    filtrationClarity: string;
    pressure: string;
    method: string;
    bottleContent: number;
    price: string;
}

export interface CiderRevisionInfos {
    loaded: boolean;
    ciderInfo?: CiderInfos | null;
    eingereicht: EingereichteCider;
}

function createCiderInfos(data: MyCollections["ciders"]): CiderInfos {
    const kategorieOrNull = (data?.kategorie as MyCollections["kategorien"]);
    console.log("Got kat", kategorieOrNull)
    return <CiderInfos>{
        name: data.name || "",
        profil: data.profil || "",
        profilTranslations: data?.profil_translations || null,
        alkohol: data.alkohol || undefined,
        land: data?.land as Country,
        kategorieId: kategorieOrNull != null ? kategorieOrNull.id : undefined,
        kategorie: kategorieOrNull != null ? kategorieOrNull.name : "",
        kategorieTranslations: createTranslationMessagesFromDb(
            kategorieOrNull != null ? (kategorieOrNull.translations as TranslationMessages[]) : null),
        images: data.images?.map((i: any) => i.directus_files_id) || [],

        vintage: data.vintage || "",
        salesDesignation: data.sales_designation || "",
        designationOfOrigin: data.designation_of_origin || "",
        varieties: data.varieties || "",
        residualSugar: data.residual_sugar || null,
        residualSugarDescription: data.residual_sugar_description || "",
        filtrationClarity: data.filtration_clarity || "",
        pressure: data.pressure || "",
        method: data.method || "",
        bottleContent: data.bottle_content || null,
        price: data.retail_price || "",
    }
}

interface EingereichteCider {
    id: number;
    status: string | undefined;
    dateCreated: Date | undefined;
    dateUpdated: Date | undefined;
    revision: number | undefined;
    productRevision: number | null;
    kommentar: string;
    tasting: CiderTasting;
}

export interface EditRevisionInfos {
    kommentar: string;
    revisionId: number;
    status: string;
}

export class CProducerCiderResults {
    id: number;
    producerId:  number;
    ciderInfos: CiderInfos;
    dateCreated: Date | null;
    dateUpdated: Date | null;
    badgeIds: number[] | undefined;
    flightsCiders: FlightsCiders[];
    submitted: EingereichteCider[];
    revisions: CiderRevisionInfos[] = [];
    loading = false;
    editRevisionInfos: EditRevisionInfos | null = null;

    constructor(ciderEntry: MyCollections["ciders"]) {
        console.log("Creating cider", ciderEntry)
        let flightsCiders: MyCollections["flights_ciders"][] | undefined;
        const flights = ciderEntry?.eingereichte_ciders?.flatMap(
            e => { if (typeof e !== "number") {
                if (typeof e.product_revision !== "number") return e.product_revision?.flight_ids;
            }});
        console.log("Got ciders flights", flights)
        if (flights != null && flights.length > 0) {
            flightsCiders = flights as MyCollections["flights_ciders"][];
        }
        else flightsCiders = undefined;
        // noinspection UnnecessaryLocalVariableJS
        this.id = ciderEntry?.id || -1;
        this.producerId = typeof ciderEntry.produzent === "number" ? ciderEntry.produzent || -1 : -1;
        this.dateCreated = ciderEntry.date_created != null ? new Date(ciderEntry.date_created) : null;
        this.dateUpdated = ciderEntry.date_updated != null ? new Date(ciderEntry.date_updated) : null;
        this.flightsCiders = flightsCiders?.map(
            flightsCider => {
                const chairNote: MyCollections["chair_notes"] | undefined = flightsCider?.chair_note !== undefined
                    ? (flightsCider?.chair_note[0] as MyCollections["chair_notes"]) : undefined;
                // we assume only one table per tasting and flights ciders!
                // @ts-ignore
                const tasting: MyCollections["tastings"] = flightsCider.flights_id.tables[0].tables_id.tasting;
                console.log("CIDERS TASTING", tasting)
                return <FlightsCiders>{
                    id: flightsCider.id,
                    ciderId: flightsCider.ciders_id as number,
                    points: flightsCider.points || undefined,
                    bestOfCategory: flightsCider.best_of_category,
                    badge: flightsCider.badge != null
                        ? createBadgeFromDatabase(flightsCider.badge as MyCollections["medaillen"]) : undefined,
                    chairComment: chairNote?.kommentar || "",
                    tasting: tasting.id || -1,
                    tastingFinished: tasting.finished || false,
                    tastingResultPublished: tasting.results_published || false,
                    tastingResultPublishedFor:
                        (tasting.published_results_for_producer as MyCollections["tastings_produzenten"][]).map(
                            p => Number(p.produzenten_id)),
                    hiddenResultsForProducer:
                        (tasting.hidden_results_for_producer as MyCollections["tastings_produzenten"][]).map(
                            p => Number(p.produzenten_id)),
                    expectedPublishDate: tasting.expected_publish_date != null
                        ? new Date(tasting.expected_publish_date) : null,
                    // tasting: {
                    //     id: tasting.id || -1,
                    //     logoMesse: (tasting.logo_messe as string) || "",
                    //     name: tasting.name || "",
                    //     datum: dateFromString(tasting.datum || "") || undefined,
                    //     beschreibung: tasting.beschreibung || ""
                    // }
                }
            }
        ) || [];
        this.ciderInfos = createCiderInfos(ciderEntry);
        this.badgeIds = flightsCiders != null ? flightsCiders.map(f => f.badge as number) : [];
        this.submitted = ciderEntry.eingereichte_ciders?.map(
            submitEntry => {
                submitEntry = submitEntry as MyCollections["eingereichte_ciders"];
                // add tasting from submit entry:
                const tasting: MyCollections["tastings"] = submitEntry.tasting as MyCollections["tastings"];
                console.log("TASTIONG INFOSD", submitEntry.product_revision)
                // return submit entry
                return <EingereichteCider>{
                    id: submitEntry.id || -1,
                    dateCreated: dateFromString(submitEntry.date_created) || undefined,
                    dateUpdated: dateFromString(submitEntry.date_updated) || undefined,
                    revision: submitEntry.cider_revision,
                    productRevision: (submitEntry.product_revision as MyCollections["ciders"])?.id || null,
                    status: submitEntry.status,
                    kommentar: submitEntry.kommentar || "",
                    tasting: <CiderTasting>{
                        id: tasting.id || -1,
                        logoMesse: (tasting.logo_messe as string) || "",
                        name: tasting.name || "",
                        datum: dateFromString(tasting.datum || "") || undefined,
                        beschreibung: tasting.beschreibung || "",
                        abbreviation: tasting.abbreviation || "",
                        translations: createTranslationMessagesFromDb(tasting.translations || null),
                        expectedPublishDate: tasting.expected_publish_date != null
                            ? new Date(tasting.expected_publish_date) : null,
                    }
                };
            }).sort((s1, s2) => {
            if (s1.tasting.datum != null && s2.tasting.datum != null)
                return s1.tasting.datum.valueOf() - s2.tasting.datum.valueOf();
            if (s1.tasting.datum != null && s2.tasting.datum == null) return -1;
            if (s1.tasting.datum == null && s2.tasting.datum != null) return 1;
            return 0;
        }) || [];
        this.revisions = this.submitted.map(s => { return <CiderRevisionInfos>{
            ciderInfo: null,
            eingereicht: s,
            loaded: false,
        } })
    }

    createPreviewJahrgang(ciderInfos: CiderInfos, tasting: Tasting, comment: string) {
        const now = new Date(Date.now());
        const ciderTasting: CiderTasting = {
            id: tasting.id,
            beschreibung: tasting.beschreibung,
            datum: tasting.datum || undefined,
            name: tasting.name,
            logoMesse: tasting.logoMesse || "",
            abbreviation: tasting.abbreviation,
            translations: tasting.translations,
            expectedPublishDate: null
        };
        const eingereichteCider: EingereichteCider = {
            id: -1,
            tasting: ciderTasting,
            revision: -1,
            productRevision: -1,
            dateCreated: now,
            kommentar: comment,
            status: "",
            dateUpdated: now,
        };
        const ciderRevisionInfos: CiderRevisionInfos = {
            ciderInfo: ciderInfos,
            eingereicht: eingereichteCider,
            loaded: true
        }
        console.log("CREATING PREVIEW1", ciderRevisionInfos)
        // Add to end
        // this.submitted.unshift(eingereichteCider);
        // this.revisions.unshift(ciderRevisionInfos);
        // console.log("CREATING PREVIEW2", this.revisions)
        return [eingereichteCider, ciderRevisionInfos]
    }

    getTasting(tastingId: number): CiderTasting | undefined {
        return this.submitted.find(s => s.tasting.id === tastingId)?.tasting;
    }

    async loadRevision(revision: CiderRevisionInfos): Promise<CiderInfos | null> {
        console.log("revision.eingereicht.productRevision", revision.loaded === false, revision.ciderInfo == null)
        // const rev = this.revisions.find(r => r.eingereicht.productRevision)
        // if (revision.loaded === false || revision.ciderInfo == null) {
        console.log("LOADING REV")
        const ciderData = await apiTyped.producerGetCiderRevisionData(revision.eingereicht.productRevision as number);
        if (ciderData == null) return null;
        revision.ciderInfo = createCiderInfos(ciderData);
        revision.loaded = true;
        // }
        return revision.ciderInfo;
    }

    async loadRevisions() {
        return;
    }

    async loadRevisionsOld(): Promise<boolean> {
        // if (this.submitted.length === 0) return false;
        // const submitted = this.submitted.filter(s => s.revision != null);
        // console.log("Loading revisions", submitted)
        // // const revisionIds = [...new Set(submitted.filter(s => s.revision != null).map(s => s.revision))];  // no duplicate revisions?
        // const revisionIds = submitted.map(s => s.revision);  // no duplicate revisions?
        // const ciderRevisions = await apiTyped.sdk.revisions.readByQuery({
        //     fields: ['*', 'activity.*'],
        //     filter: {
        //         collection: {_eq: "ciders"},
        //         item: {_eq: this.id},
        //         id: {_in: revisionIds}
        //     },
        //     sort: ['-activity.timestamp'],
        //     limit: -1,  // TODO(pagination?): keep in mind if too many revisions occur later
        // });
        // if (ciderRevisions?.data != null && ciderRevisions.data.length !== 0) {
        //     // -> "land", "kategorie", "images"
        //     const toLoadCountries = ciderRevisions.data.map(
        //         (d: any) => d.data.land).filter((x: any) => x != null);
        //     const toLoadKategorien = ciderRevisions.data.map(
        //         (d: any) => d.data.kategorie).filter((x: any) => x != null);
        //     const toLoadImages = ciderRevisions.data.flatMap(
        //         (d: any) => d.data.images).filter((x: any) => x != null);
        //     const promises = [];
        //     const emptyPromise = new Promise(function(resolve) {
        //         resolve({data: undefined})
        //     });
        //     if (toLoadCountries.length !== 0) {
        //         const countriesPromise = apiTyped.sdk.items("countries").readByQuery({
        //             filter: {
        //                 id: { _in: toLoadCountries }
        //             },
        //             limit: -1,
        //         });
        //         promises.push(countriesPromise);
        //     } else promises.push(emptyPromise);
        //     if (toLoadKategorien.length !== 0) {
        //         const kategorienPromise = apiTyped.sdk.items("kategorien").readByQuery({
        //             fields: ['id', 'name', 'translations.*'],
        //             filter: {
        //                 id: {_in: toLoadKategorien }
        //             },
        //             limit: -1,
        //         });
        //         promises.push(kategorienPromise);
        //     } else promises.push(emptyPromise);
        //     if (toLoadImages.length !== 0) {
        //         const imagesPromises = apiTyped.sdk.items("ciders_files").readByQuery({
        //             filter: {
        //                 id: { _in: toLoadImages }
        //             },
        //             limit: -1,
        //         });
        //         promises.push(imagesPromises);
        //     } else promises.push(emptyPromise);
        //     const [countries, kategorien, images] = await Promise.all(promises);
        //     console.log("GOT REVISION DATA", ciderRevisions.data)
        //     this.revisions = ciderRevisions.data.map((d: PartialItem<any>, index: number) => {
        //         const submittedEntry = submitted.find(s => s.revision === d.id);
        //         let revisionName;
        //         if (submittedEntry != null) {
        //             revisionName = this.getRevisionName(d.activity.timestamp, submittedEntry.tasting.abbreviation, false, false);
        //         } else {
        //             revisionName = this.getRevisionName(d.activity.timestamp, '', false, false);
        //         }
        //         // if (index === 0 && ciderRevisions.data?.length != null && ciderRevisions.data?.length > 1) {
        //         //     const secondRevision = ciderRevisions.data[1];
        //         //     const secondRevisionName = this.getRevisionName(secondRevision.activity);
        //         //     if (revisionName === secondRevisionName) {
        //         //         revisionName = this.
        //         //     }
        //         // }
        //         const kategorieTranslations_ = createTranslationMessagesFromDb(
        //             ((kategorien as any).data?.find((k: any) => k.id === d.data.kategorie)?.translations as any[]) || null);
        //         return <CiderRevisionInfos>{
        //             name: d.data.name || "",
        //             profil: d.data.profil || "",
        //             profilTranslations: d.data?.profil_translations || undefined,
        //             alkohol: d.data.alkohol || -1,
        //             land: (countries as any).data?.find((c: any) => c.id === d.data?.land) as Country,
        //             kategorieId: d.data?.kategorie || -1,
        //             kategorie: (kategorien as any).data?.find((k: any) => k.id === d.data.kategorie)?.name || "",
        //             kategorieTranslations: kategorieTranslations_,
        //             images: (images as any).data?.filter((i: any) => d.data.images?.includes(i.id))?.map((i: any) => i.directus_files_id) || [],
        //             revision: d.id,
        //             activity: d.activity,
        //             // revisionName: revisionName,
        //             eingereicht: submittedEntry,
        //
        //             salesDesignation: d.data.sales_designation || "",
        //             designationOfOrigin: d.data.designation_of_origin || "",
        //             varieties: d.data.varieties || "",
        //             residualSugar: d.data.residual_sugar || null,
        //             residualSugarDescription: d.data.residual_sugar_description || "",
        //             filtrationClarity: d.data.filtration_clarity || "",
        //             pressure: d.data.pressure || "",
        //             method: d.data.method || "",
        //             bottleContent: d.data.bottle_content || null,
        //             price: d.data.retail_price || "",
        //         };
        //     });
        //     return true;
        // }
        return false;
    }

    public getRevisionName(
        activityTimestamp: Date | string,
        tastingAbbreviation: string,
        withMonth = false,
        withDay = false
            ): string {
        // TODO name by submitted date or activity linked to submission?
        const date = new Date(Date.parse(activityTimestamp as string));
        const abbrString = `${tastingAbbreviation}`;
        const fullAbbrString = `${abbrString}${withDay ? '-' +date.getDate()+1 : ''}${withMonth ? '-' + date.getMonth()+1 : ''}`;
        console.log("fullAbbrString", fullAbbrString)
        if (fullAbbrString.endsWith(`${date.getFullYear()}`)) return fullAbbrString;
        else return `${fullAbbrString}-${date.getFullYear()}`
    }

    async getLatestRevision(): Promise<number | undefined> {
        const ciderRevisions = await apiTyped.sdk.revisions.readByQuery({
            fields: ['id'],
            filter: {
                collection: {_eq: 'ciders'},
                item: {_eq: this.id},
            },
            sort: ['-activity.timestamp'],
            limit: 1,
        });
        if (ciderRevisions.data != null) return ciderRevisions.data[0].id;
        return undefined;
    }

}

// export function createProducerCiderResults(ciderEntry: MyCollections["ciders"]): ProducerCiderResults {
//     let flightsCiders: MyCollections["flights_ciders"][] | undefined;
//     if (ciderEntry?.flight_ids != null) flightsCiders = ciderEntry?.flight_ids as MyCollections["flights_ciders"][];
//     else flightsCiders = undefined;
//     // noinspection UnnecessaryLocalVariableJS
//     const newCider: ProducerCiderResults = {
//         id: ciderEntry?.id || -1,
//         flightsCiders: flightsCiders?.map(
//             flightsCider => {
//                 const chairNote: MyCollections["chair_notes"] | undefined = flightsCider?.chair_note !== undefined
//                     ? (flightsCider?.chair_note[0] as MyCollections["chair_notes"]) : undefined;
//                 // we assume only one table per tasting and flights ciders!
//                 // @ts-ignore
//                 const tasting: MyCollections["tastings"] = flightsCider.flights_id.tables[0].tables_id.tasting;
//                 return {
//                     id: flightsCider.id,
//                     points: flightsCider.points || undefined,
//                     bestOfCategory: flightsCider.best_of_category,
//                     badgeId: flightsCider.badge as number || undefined,
//                     chairComment: chairNote?.kommentar || "",
//                     tasting: {
//                         id: tasting.id || -1,
//                         logoMesse: (tasting.logo_messe as string) || "",
//                         name: tasting.name || "",
//                         datum: dateFromString(tasting.datum || "") || undefined,
//                         beschreibung: tasting.beschreibung || ""
//                     }
//                 }
//             }
//         ) || [],
//         name: ciderEntry.name || "",
//         profil: ciderEntry.profil || "",
//         translations: createTranslationMessagesFromDb(ciderEntry?.translations || null),
//         alkohol: ciderEntry.alkohol || -1,
//         land: ciderEntry?.land as Country,
//         kategorieId: (ciderEntry?.kategorie as MyCollections["kategorien"]).id || -1,
//         kategorie: (ciderEntry?.kategorie as MyCollections["kategorien"]).name || "",
//         images: ciderEntry.images?.map((i: any) => i.directus_files_id) || [],
//         badgeIds: flightsCiders != null ? flightsCiders.map(f => f.badge as number) : [],
//     }
//     return newCider;
// }
