<template>
  <v-container
    fluid
    class="text-center center justify-center align-center"
    style="margin: 0 auto;"
  >

    <PageNavigation
      :title-translation="$t('TastingDashboard.selectTasting')"
      :breadcrumbs="[{text: $t('NavJuror.dashboard'), to: '/tastings'}]"
      class="pb-6 pl-4"
    />
    <loading-circle
      v-if="loading"
      :long-loading="false"
      :fullscreen="false"
    />
    <template v-if="!loading">
      <v-card
        v-if="tastings.length === 0"
        class="mx-auto pt-16 mt-16 pb-4"
        width="300"
        outlined
        style="border-style: dashed; border-width: medium; transition: box-shadow 0.2s ease-in-out;"
      >
        <v-icon size="192">
          mdi-google-downasaur
        </v-icon>
        <v-card-subtitle class="pb-2 text-center text-subtitle-1">
          {{ $t('noTastingsAvailable') }}
        </v-card-subtitle>
      </v-card>

      <template
        v-for="tastingData in tastingsObject"
      >
        <div
          v-if="tastingData.tastings.length > 0"
          :key="tastingData.title"
          style="max-width: 1800px !important;"
          class="mr-auto pb-8 text-h5"
        >
          <div
            class="d-flex pl-md-4 text-left text-h5 pb-2 align-center"
          >
            {{ $t('TastingDashboard.' + tastingData.title) }}
          </div>
          <v-row
            v-if="!loading"
            class="pl-md-4 pr-md-4 align-self-auto align-stretch"
          >
            <v-col
              v-for="tasting in tastingData.tastings"
              :key="tasting.id"
              cols="12"
              xs="12"
              sm="6"
              md="6"
              lg="4"
              class="align-self-stretch align-stretch"
              @click="gotoTasting(tasting)"
            >
              <v-hover
                v-slot="{ hover }"
                style="height: 100%;"
                class="align-self-stretch"
              >
                <TastingJurorCard
                  :tasting="tasting"
                  :elevation="hover ? 5 : 0"
                  :tables="tableInfos[tasting.id]"
                  :border-color="tastingData.borderColor"
                  class="align-self-stretch"
                  style="cursor: pointer; height: 100%;"
                />
              </v-hover>
            </v-col>
          </v-row>
        </div>
      </template>
    </template>
  </v-container>
</template>

<script>
import {get} from "vuex-pathify";
import i18n from "@/i18n";
import TastingJurorCard from "@/components/tasting_juror_card";
import {apiTyped} from "@/api";
import LoadingCircle from "@/layouts/loading_circle";
import PageNavigation from "@/components/page_navigation";
import {allRoutes} from "@/router";
import rating_mixin from "@/components/mixins/rating_mixin";

export default {
  name: "TastingDashboard",
  components: {PageNavigation, LoadingCircle, TastingJurorCard},
  mixins: [rating_mixin],
  data: () => ({
    loading: true,
    tastingsLoading: [],
    tableInfos: {},
  }),
  computed: {
    tastings: get("usersStore/tastings"),
    finishedTastings() {
      return this.tastings.filter(t => (t.finished === true && t.started === true)
                                    || (t.finished === true && t.started === false))
    },
    startedTastings() {
      return this.tastings.filter(t => t.finished === false && t.started === true)
    },
    upcomingTastings() {
      return this.tastings.filter(t => t.finished === false && t.started === false)
    },
    tastingsObject() {
      return [
        {
          tastings: this.startedTastings,
          title: 'runningTastings',
          borderColor: 'var(--v-divider-base)',
          borderWidth: 3,
        },
        {
          tastings: this.upcomingTastings,
          title: 'upcomingTastings',
          borderColor: 'var(--v-divider-base)',
          borderWidth: 2,
        },
        {
          tastings: this.finishedTastings,
          title: 'finishedTastings',
          borderColor: 'var(--v-divider-base)',
          borderWidth: 1,
        },
      ]
    },
  },
  async created() {
    this.loading = true;
    document.title = "Tasting - Dashboard"
    this.$store.state.usersStore.currentTastingId = -1;
    // const tableIdsPromise = this.tastings.map(
    //   t => apiTyped.getTablesForTasting(t, this.$store.state.usersStore.me.id));
    // const tableInfos = await Promise.all(tableIdsPromise);
    // // Map table infos to an object keys
    // this.tastings.forEach((t, index) => {
    //   this.tableInfos[t.id] = tableInfos[index];
    // });
    if (this.tastings.length > 0) {
      const tableInfos = await apiTyped.getTablesForTastings(
        this.tastings.map(t => t.id), this.$store.getters["usersStore/userId"]());
      console.log("GOt table infos", tableInfos)
      // Map table infos to an object keys
      this.tastings.forEach((t, index) => {
        this.tableInfos[t.id] = tableInfos.filter(table => table.tasting === t.id);
      });
    }
    this.loading = false;
  },
  methods: {
    async gotoTasting(tasting) {
      this.$store.state.usersStore.currentTastingId = tasting.id;
      const path = this.makeTastingRoute(allRoutes.tastings.ciderList, tasting);
      await this.$router.push(path);
    }
  }
}
</script>

<style scoped>

</style>