<!--no strings-->
<template>
  <div
    class="ml-auto mr-auto"
    style="width: 100%; max-width: 1250px;"
  >
    <v-row
      class="d-flex justify-center ml-0 mr-0"
      style="width: 100%;"
    >
      <v-col
        class="d-flex justify-start"
        align-self="start"
      >
        <v-btn
          v-show="!hidePrev"
          class="ml-md-16 animate_change"
          elevation="0"
          color="tertiary_container"
          :disabled="prevDisabled"
          @click="$emit('prev')"
        >
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          {{ prevText || $t('previous').toUpperCase() }}
        </v-btn>
      </v-col>
      <v-col
        class="d-flex justify-end"
        align-self="end"
      >
        <v-btn
          v-show="!hideNext"
          class="mr-md-16 animate_change"
          elevation="0"
          color="secondary"
          :disabled="nextDisabled"
          @click="$emit('next')"
        >
          {{ nextText || $t('next').toUpperCase() }}
          <v-icon right>
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
  name: "StepNavigation",
  props: {
    nextDisabled: { type: Boolean, required: true, default: true },
    prevDisabled: { type: Boolean, required: true, default: true },
    hidePrev: { type: Boolean, required: false, default: false },
    hideNext: { type: Boolean, required: false, default: false },
    nextText: { type: String, required: false, default: null },
    prevText: { type: String, required: false, default: null },
  },
}
</script>

<style scoped>
.animate_change {
  transition:all 0.25s ease;
}
</style>