<template>
  <v-card
    ref="card"
    style="min-height: 132px !important;"
    :outlined="$vuetify.theme.dark"
    class="d-flex flex-row text-left justify-start align-start"
  >
    <v-row>
      <v-col
        cols="8"
        class="align-end"
      >
        <v-card-text class="align-end pl-4 pb-0 pt-4 text-left ">
          <p class="d-flex justify-start text-h5 text--primary mb-1">
            <v-icon class="pr-2">
              mdi-glass-wine
            </v-icon>
            Cider
          </p>
          <div class="d-flex justify-center pt-4 align-baseline">
            {{ finishedCiders.length }} /
            <span
              class="text-h6"
              style="font-size: 32pt !important; opacity: 0.6;"
            >
              {{ allCiders.length }}
            </span>
            <span
              style="font-size: 10pt !important;"
              class="pl-1 pb-0 align-end"
            >
              bewertet
            </span>
          </div>
        </v-card-text>
      </v-col>
      <v-col
        cols="4"
        class="d-flex flex-column align-center justify-end pr-8"
      >
        <v-progress-circular
          class="ma-0 mr-1 mb-1"
          :rotate="-90"
          :value="(finishedCiders.length / allCiders.length) * 100"
          size="55"
          width="18"
          color="secondary"
        />
        <span
          class="text-center mb-0 ml-auto mr-auto text-caption"
          style="font-size: 12pt !important;"
        >
          {{ Math.round(finishedCiders.length / allCiders.length * 1000) / 10 }}%
        </span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import CiderRatingState from "@/types/ciderRatingState";

export default {
  name: "CardCiderStats",
  props: {
    allCiders: {type: Array, required: true, default: null},
  },
  computed: {
    finishedCiders () {
      return this.allCiders.filter(cider =>
          this.$store.getters["ciderStore/getCiderRatingState"](cider) === CiderRatingState.finishedByChair);
    },
    finishedCidersStates () {
      return this.allCiders.map(cider =>
        this.$store.getters["ciderStore/getCiderRatingState"](cider));
    }
  },
  mounted() {
    this.$emit("size", {name: "CardCiderStats", size: this.$refs.card.$el.clientHeight})
  },
}
</script>

<style scoped>

</style>