<template>
  <div
    v-if="product !== null"
    class="d-flex flex-column"
    style="transform: translateY(-72px); height: calc(100% + 72px);"
  >
    <manual-rating-judge-table-header />
    <manual-rating-judge-table-category
      v-for="(bewertungsKategorie, index) in product.kategorie.bewertungskategorien"
      :key="bewertungsKategorie.pruefmerkmal"
      :bewertungskategorie="bewertungsKategorie"
      :is-last="index === product.kategorie.bewertungskategorien.length - 1"
      :product="product"
    />
    <v-divider style="margin-top: 1px; border-color: var(--v-divider_light-base) !important" />
    <div class="last-row-grid pt-2 pb-2">
      <div class="one_col d-flex justify-start align-end pl-6 pr-2">
        {{ $t('Summary.headerTextFinalComment') }}
      </div>
      <div class="one_col d-flex flex-column justify-center align-end pl-6 pr-2">
        <span class="font-weight-bold">
          {{ $t('totalScorePossible') }} (max. {{ maxPoints }}):
        </span>
        <div class="font-weight-light text-.secondary text-caption">
          {{ $t('roundUpToNextFullNumber') }}
        </div>
      </div>
      <div
        class="two_col text-right pr-4 rounded-xl"
        style="border: 1px solid var(--v-divider_light-base); min-height: 12mm;"
      />
    </div>
    <div style="align-items: stretch; height: 100%; min-height: 0;">
      <div
        class="text-left pt-6 mx-6 pb-3 align-self-stretch"
        style="border: 1px solid var(--v-divider_light-base); border-radius: 10px; height: 100%; min-height: 0;"
      >
        {{ '&nbsp;' }}
      </div>
    </div>
  </div>
</template>

<script>
import JurorCider from "@/types/jurorCider";
import ManualRatingJudgeTableHeader from "@/components/admin/manualRating/manual_rating_judge_table_header.vue";
import ManualRatingJudgeTableCategory from "@/components/admin/manualRating/manual_rating_judge_table_category.vue";

export default {
  name: "ManualRatingJudgeTable",
  components: {
    ManualRatingJudgeTableCategory,
    ManualRatingJudgeTableHeader,
  },
  props: {
    product: {type: JurorCider, required: true, default: () => null},
    maxPoints: {type: Number, required: false, default: 120},
  },
}
</script>

<style scoped>
/deep/ .parent_grid {
    display: grid;
    grid-template-columns: 6fr 12fr repeat(2, 1.25fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
}

/deep/ .last-row-grid {
    display: grid;
    grid-template-columns: 8fr 8fr repeat(2, 1.25fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
}

/deep/ .one_col {
    grid-column: auto;
}
/deep/ .two_col {
    grid-column: auto / span 2;
}
/deep/ .three_col {
    grid-column: auto / span 3;
}
/deep/ .comment  *  {
    text-align: left !important;
    margin-bottom: 2px;
}
</style>