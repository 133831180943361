<template>
  <v-card
    flat
    color="transparent"
    class="overflow-y-auto"
  >
    <v-card-text>
      <v-row
        class="d-flex flex-column rounded-xl overflow-y-auto"
        dense
        align="center"
        justify="center"
        :class="{ 'primary lighten-4': dragover, 'elevation-4': dragover }"
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
      >
        <v-icon
          :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
          size="60"
          @click="$refs.inputUploadFile.click()"
        >
          mdi-cloud-upload
        </v-icon>
        <label for="inputUploadFile">
          <input
            id="inputUploadFile"
            ref="inputUploadFile"
            type="file"
            :multiple="multiple"
            accept="image/png, image/jpeg"
            style="display: none;"
            @change="requestUploadFile"
          >
          {{ $t('uploadImages.dropFilesHere') }}

        </label>
      </v-row>
      <draggable
        v-model="imageObjects"
        class="row overflow-y-auto"
        style="max-height: 450px;"
      >
        <v-col
          v-for="(imageObject, index) in imageObjects"
          :key="`upload_img_${index}`"
          cols="4"
          class="overflow-y-auto"
        >
          <v-hover
            v-slot="{ hover }"
          >
            <div style="position: relative;">
              <v-img
                aspect-ratio="1"
                :src="imageObject.url"
              />
              <!--              <v-btn-->
              <!--                v-show="hover"-->
              <!--                style="position: absolute; top: 8px; right: 8px;"-->
              <!--                icon-->
              <!--                elevation="4"-->
              <!--                color="error"-->
              <!--                top-->
              <!--                right-->
              <!--                @click="removeFile(item.name)"-->
              <!--              >-->
              <!--                <v-icon x-large>-->
              <!--                  mdi-close-circle-->
              <!--                </v-icon>-->
              <!--              </v-btn>-->
              <div
                v-show="hover"
              >
                <v-btn
                  v-show="hover"
                  style="position: absolute; top: 8px; right: 8px;"
                  icon
                  elevation="4"
                  color="error"
                  top
                  right
                  @click="removeFile(imageObject)"
                >
                  <v-icon x-large>
                    mdi-close-circle
                  </v-icon>
                </v-btn>
                <v-menu
                  v-model="showMenus[index]"
                  close-on-content-click
                  transition="scale-transition"
                  top
                  origin="center"
                >
                  <template #activator="{ on }">
                    <v-btn
                      style="position: absolute; top: 8px; left: 8px;"
                      icon
                      elevation="4"
                      color="error"
                      top
                      right
                      v-on="on"
                      @click="showMenus[index] = true"
                    >
                      <v-icon>
                        mdi-arrow-expand
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-img
                    contain
                    width="300"
                    :src="imageObject.url"
                  />
                </v-menu>
              </div>
            </div>
          </v-hover>
        </v-col>
      </draggable>
      <!--          <v-list-item :key="item.name">-->
      <!--            <v-list-item-content>-->
      <!--              <v-list-item-avatar>-->
      <!--                <v-img-->
      <!--                  :src="createLocalImageUrl(item)"-->
      <!--                />-->
      <!--              </v-list-item-avatar>-->
      <!--              <v-list-item-title>-->
      <!--                {{ item.name }}-->
      <!--                <span class="ml-3 text&#45;&#45;secondary">-->
      <!--                  {{ item.size }} bytes</span>-->
      <!--              </v-list-item-title>-->
      <!--            </v-list-item-content>-->

      <!--            <v-list-item-action>-->
      <!--              <v-btn-->
      <!--                icon-->
      <!--                @click.stop="removeFile(item.name)"-->
      <!--              >-->
      <!--                <v-icon> mdi-close-circle </v-icon>-->
      <!--              </v-btn>-->
      <!--            </v-list-item-action>-->
      <!--          </v-list-item>-->
      <!--          <v-divider />-->
    </v-card-text>
    <v-card-actions v-if="!hideHelpText">
      <v-card-text class="text-left pt-0 pb-0 text--secondary text-caption">
        <v-icon small>
          mdi-information-outline
        </v-icon>
        {{ $t('uploadImages.rearrangeByDrag') }}
      </v-card-text>
      <v-spacer />

      <!--      <v-btn-->
      <!--        icon-->
      <!--        @click="closeDialog"-->
      <!--      >-->
      <!--        <v-icon id="close-button">-->
      <!--          mdi-close-->
      <!--        </v-icon>-->
      <!--      </v-btn>-->

      <!--      <v-btn-->
      <!--        icon-->
      <!--        @click.stop="submit"-->
      <!--      >-->
      <!--        <v-icon id="upload-button">-->
      <!--          mdi-upload-->
      <!--        </v-icon>-->
      <!--      </v-btn>-->
    </v-card-actions>
  </v-card>
</template>

<script>
import draggable from 'vuedraggable'
import logos_mixin from "@/components/mixins/logos_mixin";

export default {
  name: "ProducerUploadImage",
  components: {
    draggable
  },
  mixins: [logos_mixin],
  props: {
    multiple: { type: Boolean, default: true },
    hideHelpText: { type: Boolean, default: false },
    existingImageIds: { type: Array, required: false, default: null}
  },
  data() {
    return {
      dragover: false,
      // uploadedFiles: [],
      // removedImageUrls: [],
      // userImageUrls: [],
      // imageUrls: [],
      showMenus: [],
      imageObjects: [],
    };
  },
  computed: {
    // userImageUrls() {
    //   if (this.existingImageIds === null) return [];
    //   return this.existingImageIds.filter(url => !this.removedImageUrls.includes(url))
    // },
  },
  watch: {
    imageObjects: function () {
      this.showMenus = Array(this.imageObjects.length).fill(false);
      this.emitChanges();
    },
  },
  mounted() {
    this.imageObjects = [...(this.existingImageIds.map(imageId => { return {
      directusId: imageId,
      url: this.getImageUrl(imageId, 1000),
      file: null,
      existing: true,
    } }))]
    // this.imageUrls = [...this.existingImageIds];
    this.emitChanges();
  },
  methods: {
    createLocalImageUrl(file) {
      return URL.createObjectURL(file);
    },
    removeFile(imageObject) {
      const index = this.imageObjects.findIndex(imageObject_ => imageObject_.url === imageObject.url);
      if (index > -1) this.imageObjects.splice(index, 1);
      // if (!this.removedImageUrls.includes(url)) this.removedImageUrls.push(url);
      // // If file is in uploaded files remove it
      // const index = this.uploadedFiles.findIndex(file => file.url === url);
      // if (index > -1) this.uploadedFiles.splice(index, 1);
      // // If url is in imageUrls remove it
      // const indexUrls = this.imageUrls.findIndex(imageUrl => imageUrl === url)
      // if (indexUrls > -1) this.imageUrls.splice(indexUrls, 1);
    },
    requestUploadFile(){
      const src = this.$refs.inputUploadFile;
      this.addFiles(src.files);
    },
    addFiles(elements) {
      elements.forEach(element => {
          element.url = this.createLocalImageUrl(element);
          // this.uploadedFiles.push(element);
          // this.imageUrls.push(element.url);
          this.imageObjects.push({
            directusId: null,
            url: element.url,
            file: element,
            existing: false,
          });
        }
      );
      this.emitChanges();
    },
    emitChanges() {
      this.$emit('change', this.imageObjects);
    },
    onDrop(e) {
      this.dragover = false;
      this.addFiles(e.dataTransfer.files);
    },
  }
};
</script>

<style scoped>
.flip-transition-move {
  transition: all 0.7s;
}
</style>