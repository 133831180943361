import {Bewertung, createBewertungFromEigenschaft} from "@/types/bewertung";
import {Bewertungskategorie, BewertungskategorieTranslation} from "@/types/bewertungskategorie";
import {createMultiLocaleMessages} from "@/locales/createLocaleMessageObject";
import {createLocaleMessagesForEigenschaft} from "@/types/eigenschaft";
import {TranslationMessages} from "@/types/translationMessages";

class BewertungskategorieRated {
    id: number;
    pruefmerkmal: string;  // bezeichnung
    info: string;
    bewertungen: Bewertung[];
    translations: BewertungskategorieTranslation[] | undefined;
    translationMessages: TranslationMessages | null;
    // kategorien?: Kategorie[];
    // jurorId: string | undefined;

    constructor() {
        this.id = 0;
        this.pruefmerkmal = "";
        this.info = "";
        // this.jurorId = undefined;
        this.bewertungen = [];
        this.translationMessages = null;
    }
}

export function createBewertungskategorieRatedFromBewertungskategorie(
        bewertungskategorie: Bewertungskategorie): BewertungskategorieRated {
    const bewertungskategorieRated = new BewertungskategorieRated();
    bewertungskategorieRated.id = Number(bewertungskategorie.id);
    bewertungskategorieRated.pruefmerkmal = bewertungskategorie.pruefmerkmal;
    bewertungskategorieRated.info = bewertungskategorie.info;
    // bewertungskategorieRated.jurorId = undefined;
    bewertungskategorieRated.bewertungen = bewertungskategorie.eigenschaften.map((eigenschaft) =>
        createBewertungFromEigenschaft(eigenschaft));
    bewertungskategorieRated.translations = bewertungskategorie.translations;
    bewertungskategorieRated.translationMessages = bewertungskategorie.translationMessages;
    return bewertungskategorieRated;
}

export function createLocaleMessagesForBewertungskategorie(
        bewertungskategorie: BewertungskategorieRated | Bewertungskategorie): any[] {
    const translations = [];
    const bewertungskategorienTranslations = createMultiLocaleMessages(bewertungskategorie.translations,
        getBewertungskategorieTranslationPrefix(bewertungskategorie));
    translations.push(...bewertungskategorienTranslations);
    const eigenschaftenTranslations = [];
    if (bewertungskategorie.hasOwnProperty("bewertungen"))
        eigenschaftenTranslations.push(...((bewertungskategorie as BewertungskategorieRated).bewertungen.flatMap(
            bewertung => createLocaleMessagesForEigenschaft(bewertung))));
    if (bewertungskategorie.hasOwnProperty("eigenschaften"))
        eigenschaftenTranslations.push(...((bewertungskategorie as Bewertungskategorie).eigenschaften.flatMap(
            eigenschaft => createLocaleMessagesForEigenschaft(eigenschaft))));
    translations.push(...eigenschaftenTranslations);
    // const messageObjects = mergeMultiLocaleMessages(translations);
    return translations;
}

export function getBewertungskategorieTranslationPrefix(
        bewertungskategorie: BewertungskategorieRated | Bewertungskategorie): string {
    return "bewertungskategorie_" + String(bewertungskategorie.id) + "_";
}

export { BewertungskategorieRated };