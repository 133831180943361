<!--no strings-->
<template>
  <tiptap-vuetify
    v-model="editorContent"
    :key="rerender"
    :placeholder="placeholder"
    :extensions="extensions"
    :disabled="readonly"
    :toolbar-attributes="toolbarAttributes"
    :card-props="{ flat: true, color: backgroundColor, collapse: true, rounded: '0' }"
    class="text-left test overflow-y-auto"
    style="border-radius: 20px !important; z-index: 0 !important; max-height: 400px !important;"
  >
    <template
      v-if="readonly"
      #toolbar="{ buttons }"
    >
      <pre>{{ buttons }}</pre>
    </template>
  </tiptap-vuetify>
</template>

<script>
import Vue from "vue";
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, BulletList, OrderedList, ListItem,
  Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'


const Component = Vue.extend( {
  components: { TiptapVuetify },
  props: {
    value: {type: String},
    // content: {type: String, required: false, default: "<p></p>"},
    readonly: {type: Boolean, required: false, default: true},
    backgroundColor: {type: String, required: false, default: "background"},
    placeholder: {type: String, required: false, default: "Profile"},
  },
  data: () => ({
    extensions: [
      History,
      Bold,
      Underline,
      Italic,
      Strike,
      Blockquote,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      HorizontalRule,
      HardBreak
    ],
    rerender: 0,  // to rerender the component if placeholder changes
  }),
  watch: {
    placeholder: function () {
      this.rerender += 1;
    },
  },
  computed: {
    isDarkMode() {
      return this.$vuetify.theme.dark
    },
    toolbarAttributes() {
      return this.$vuetify.theme.dark
        ? { color: this.$vuetify.theme.currentTheme.background, dark: true }
        : { color: 'background', rounded: 'xl' }
    },
    editorContent: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
});
export default Component;
</script>

<style scoped>
/deep/ .tiptap-vuetify-editor__content {
  /*border: 1px dashed #f1f1f1;*/
  color: var(--v-text_std-base);
  border-radius: 22px !important;
  margin-bottom: 20px !important;
  /*background-color: var(--v-card_background-base);*/
}

.test > * {
  background-color: red !important;
}

/deep/ .tiptap-vuetify-editor__content--disabled.v-card {
  color: var(--v-text_std-base) !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
/deep/ .tiptap-vuetify-editor__content--disabled {
  color: var(--v-text_std-base) !important;
  /*background-color: var(--v-card_background-base) !important;*/
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

/deep/ .tiptap-vuetify-editor__content--disabled:after {
  background-color: unset !important;
}

/deep/ .tiptap-vuetify-editor__content > p {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

/deep/ .tiptap-vuetify-editor__toolbar {
  z-index: 1 !important;
  position: sticky !important;
  top: 0;
}

/deep/ .v-application >>> rounded-0 {
  /*border-radius: 20px !important;*/
}

</style>