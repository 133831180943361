enum CiderRatingState {
    // TODO also change for server
    notStarted,
    restartedByChair,
    startedByChair,
    startedByUser,
    finishedByUser,
    finishedByChair,
    unknown,
}

export function getStateNumbers() {
    const enumKeys = Object.keys(CiderRatingState);
    return enumKeys.splice(0, enumKeys.length / 2 - 1);
}

export function getStateKeysNames() {
    const enumKeys = Object.entries(CiderRatingState);
    return enumKeys.splice(0, enumKeys.length / 2 - 1);
}

export default CiderRatingState