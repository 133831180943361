<template>
  <v-tour
    :name="name"
    :steps="steps"
    :options="options"
    :callbacks="callbacks"
  />
</template>

<script>
import Vue from 'vue'

export default {
  name: "MyTour",
  props: {
    name: {type: String, required: true, default: () => ""},
    steps: {type: Array, required: true, default: () => []},
    options: {type: Object, required: true, default: () => null},
    callbacks: {type: Object, required: true, default: () => null},
  },
}
</script>

<style scoped>
/deep/ .v-tour__target--highlighted {
  z-index: 999 !important;
  -webkit-box-shadow: 0 0 0 4px var(--v-on_secondary_container-base) !important;
  -moz-box-shadow: 0 0 0 4px var(--v-on_secondary_container-base) !important;
  box-shadow: 0 0 0 4px var(--v-on_secondary_container-base) !important;
}

/deep/ .v-step__header {
  max-width: 100vw;
  background-color: #3d6657 !important;
  border-bottom-style: solid;
  border-bottom-color: var(--v-divider-lighten1);
  margin-right: 8px !important;
  margin-left: 8px !important;
  border-bottom-width: 2px !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  font-weight: normal !important;
  font-size: 20px;
}

/deep/ .v-step__content > * {
  font-weight: lighter !important;
}

/*/deep/ .v-step__header > div {*/
/*  color: var(--v-text_std_inv-base) !important;*/
/*}*/

/deep/ .v-step__arrow::before {
  background-color: var(--v-background-base);
}

/deep/ .v-step__arrow--dark::before {
  background-color: #3d6657 !important;
}

/deep/ .v-step {
  max-width: 100vw;
  background-color: #3d6657 !important;
  opacity: 0.975;
  min-width: min(450px, 100vw);
  -webkit-box-shadow: 0 2px 8px rgba(17, 17, 17, 0.5) !important;
  border-radius: 20px !important;
}
</style>