<template>
  <v-container
    fluid
    style="margin: 0 auto; height: auto !important;"
  >
    <Alert_loading_error
      v-show="error"
      :errors="errors"
    />

    <ConfirmDialog ref="confirm" />

    <PageNavigation
      :title-translation="currentTasting.name"
      :breadcrumbs="[{text: $t('NavJuror.allTastings'), to: tastingRoutes.my},
                     {text: $t('NavJuror.productOverview'), to: makeTastingRoute(tastingRoutes.ciderList, currentTasting)},
                     {text: $t('NavJuror.ratingOf',{number:currentCider.displayId}), to: '', disabled: true}]"
      class="pb-6 pl-md-3 pl-0"
    />

    <v-slide-y-reverse-transition>
      <v-btn
        v-if="showNextBtn && !hideToSummaryButton"
        fixed
        bottom
        right
        rounded
        :fab="isSmallScreen"
        color="primary"
        elevation="9"
        style="z-index: 99"
        @click="toSummary()"
      >
        <span
          v-if="!isSmallScreen"
          style="color: var(--v-primary_text-base)"
        >
          {{ $t('toOverallRating') }}
        </span>
        <v-icon color="primary_text">
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </v-slide-y-reverse-transition>

    <CiderRatingStepper
      ref="ciderRatingStepper"
      active-step="2"
    />

    <!--    <h1-->
    <!--      style="font-weight: normal"-->
    <!--      class="mb-1"-->
    <!--    >-->
    <!--      Bewertung-->
    <!--    </h1>-->

    <CiderInfoExpPanel
      ref="ciderInfoExpPanel"
      :flights-ciders-id="currentCider.flightsCidersId"
      :is-summary="false"
      style="align-self: flex-start !important; width: auto !important;"
      :current-points="currentPoints"
      @userWantsToFinish="userWantsToFinish"
      @updateUserNotesFieldFinishedTrue="updateUserNotesFieldFinishedTrue"
    />

    <!--    <v-expansion-panels v-model="open_infos" class="sticky mb-8" flat>-->
    <!--      <v-expansion-panel :class="`elevation-${info_panel_elevation}`">-->
    <!--        <v-expansion-panel-header @click="handle_info_click" class="pt-2 pb-2 pl-4 pr-4">-->

    <!--          <v-row align="end" no-gutters>-->
    <!--            <v-col>-->
    <!--              <v-list-item two-line class="pa-0">-->
    <!--                <v-list-item-content class="pa-0">-->
    <!--                  <v-list-item-title>Category</v-list-item-title>-->
    <!--                  <v-list-item-subtitle class="flex-xl-shrink-0">{{ this.current_cider.kategorie_name }}</v-list-item-subtitle>-->
    <!--                </v-list-item-content>-->
    <!--              </v-list-item>-->
    <!--            </v-col>-->
    <!--            <v-divider class="ml-2 mr-2" vertical></v-divider>-->
    <!--            <v-col class="mr-2">-->
    <!--              <v-list-item two-line class="pa-0">-->
    <!--                <v-list-item-content>-->
    <!--                  <v-list-item-title>Country</v-list-item-title>-->
    <!--                  <v-list-item-subtitle>{{ this.current_cider.country }}</v-list-item-subtitle>-->
    <!--                </v-list-item-content>-->
    <!--              </v-list-item>-->
    <!--            </v-col>-->
    <!--&lt;!&ndash;            <v-divider v-if="!is_small_screen" class="ml-2 mr-2" vertical></v-divider>&ndash;&gt;-->
    <!--&lt;!&ndash;            <v-col v-if="!is_small_screen">&ndash;&gt;-->
    <!--&lt;!&ndash;              <v-list-item two-line class="pa-0">&ndash;&gt;-->
    <!--&lt;!&ndash;                <img :src="get_badge_url(200, 100)" height="40px" alt="Avatar" class="mr-2">&ndash;&gt;-->
    <!--&lt;!&ndash;                <v-list-item-content>&ndash;&gt;-->
    <!--&lt;!&ndash;                  <v-list-item-title>Total Points</v-list-item-title>&ndash;&gt;-->
    <!--&lt;!&ndash;                  <v-list-item-subtitle>{{ 100 }}</v-list-item-subtitle>&ndash;&gt;-->
    <!--&lt;!&ndash;                </v-list-item-content>&ndash;&gt;-->
    <!--&lt;!&ndash;              </v-list-item>&ndash;&gt;-->
    <!--&lt;!&ndash;            </v-col>&ndash;&gt;-->
    <!--&lt;!&ndash;            <v-divider v-if="!is_small_screen" class="ml-2 mr-2" vertical></v-divider>&ndash;&gt;-->
    <!--            <v-scroll-y-transition>-->
    <!--            <v-col class="ml-auto">-->
    <!--              <v-list-item two-line class="pa-0" style="text-align: right;">-->
    <!--                <v-list-item-content class="mr-3">-->
    <!--                  <v-list-item-title>My Points</v-list-item-title>-->
    <!--                  <v-list-item-subtitle>{{ current_cider_points }}</v-list-item-subtitle>-->
    <!--                </v-list-item-content>-->
    <!--                <v-fab-transition>-->
    <!--                  <v-speed-dial class="mr-2" style="height: 56px; width: 56px;" v-if="show_badge" color="transparent"-->
    <!--                                open-on-hover direction="bottom">-->
    <!--                    <template v-slot:activator >-->
    <!--                      <v-btn color="transparent" dark fab class="">-->
    <!--                        <v-img max-height="56px" max-width="56px" :lazy-src="get_current_badge_url()"-->
    <!--                               :src="get_current_badge_url()" :key="get_current_badge_url()" alt="Badge">-->
    <!--                        </v-img>-->
    <!--                      </v-btn>-->
    <!--                    </template>-->
    <!--                    <div class=""></div>-->
    <!--                    <div v-for="badge in get_badges_data()" :key="badge.id" class="">-->
    <!--                      <v-tooltip disabled left eager nudge-left="24" :value="true" >-->
    <!--                        <v-btn fab dark small color="transparent" slot="activator" :elevation="10" min-height="56px" min-width="56px">-->
    <!--                          <v-img max-height="56px" max-width="56px" :lazy-src="get_badge_url(300, badge.punkte_min)"-->
    <!--                                 :src="get_badge_url(300, badge.punkte_min)" :key="badge.punkte_min" alt="Badge">-->
    <!--                          </v-img>-->
    <!--                        </v-btn>-->
    <!--                        <span>{{ badge.stufe }}: {{badge.punkte_max}}-{{badge.punkte_min}}</span>-->
    <!--                      </v-tooltip>-->
    <!--                      <br>-->
    <!--                    </div>-->
    <!--                  </v-speed-dial>-->
    <!--                </v-fab-transition>-->
    <!--              </v-list-item>-->
    <!--            </v-col>-->
    <!--            </v-scroll-y-transition>-->


    <!--          </v-row>-->

    <!--        </v-expansion-panel-header>-->
    <!--        <v-expansion-panel-content>-->
    <!--          <v-row align="baseline" no-gutters >-->
    <!--            <v-col cols="12" align="start">-->
    <!--              <p> {{ typ }} </p>-->
    <!--            </v-col>-->
    <!--            <v-col cols="12" align="start">-->
    <!--              <div v-html="profile"></div>-->
    <!--            </v-col>-->

    <!--            <v-col cols="3">-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--        </v-expansion-panel-content>-->
    <!--      </v-expansion-panel>-->
    <!--    </v-expansion-panels>-->

    <!--    <transition name="fade">-->
    <!--      <div-->
    <!--        v-if="loading"-->
    <!--        class="d-flex justify-center align-end"-->
    <!--      >-->
    <!--        <v-progress-circular-->
    <!--          class="d-flex justify-center align-center"-->
    <!--          style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) "-->
    <!--          color="primary"-->
    <!--          size="75"-->
    <!--          width="7"-->
    <!--          indeterminate-->
    <!--        >-->
    <!--          <v-img-->
    <!--            contain-->
    <!--            :src="getLogoUrl('logo_app', 300)"-->
    <!--            alt="CiderAwardLogo"-->
    <!--            style="margin: auto;"-->
    <!--            max-height="40px"-->
    <!--            max-width="40px"-->
    <!--          />-->
    <!--        </v-progress-circular>-->
    <!--      </div>-->
    <!--    </transition>-->

    <v-container
      v-intersect="{
        handler: onIntersect,
        options: {
          threshold: [1]
        }
      }"
      class="d-flex flex-column justify-center px-0 px-md-3"
      style="max-width: 1600px"
    >
      <div
        class="d-flex justify-start mt-4 text-left text-h5"
        style="color: var(--v-background_text-base) !important;"
      >
        {{ $t('bewertungskategorien') }}
      </div>
    </v-container>
    <v-row
      v-show="firstLoad"
      v-masonry="'masonry_rating2'"
      class="pa-0 ma-0"
    >
      <v-col
        v-for="id in [-1, -2, -3, -4]"
        :key="id"
        cols="12"
        lg="4"
        md="6"
        offset-md="0"
        offset-sm="0"
        sm="6"
        xs="12"
        @load="$redrawVueMasonry('masonry_rating2')"
      >
        <v-skeleton-loader
          style="background-color: var(v--card_background-base) !important;"
          class="elevation-2 rounded-xl"
          type="table-heading, divider, list-item@4"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="!firstLoad"
      id="ratingMasonry"
      v-masonry="'masonry_rating'"
      transition-duration="0.3s"
      justify="center"
      align="center"
      class="pa-0 ma-0 mx-auto"
      style="max-width: 1600px;"
    >
      <!--      <v-skeleton-loader-->
      <!--          v-bind="loading_attrs" v-if="loading === true" :loading="loading" transition="fade-transition" type="card"-->
      <!--      ></v-skeleton-loader>-->

      <!--      v-for="bewertungs_kategorie in this.$store.get('getKategorieById',-->
      <!--      this.$store.get('current_cider').kategorie_id).bewertungskategorien"-->
      <!--      :max-width="max_card_width" :min-width="max_card_width"-->
      <!--      <v-col class="justify-center" justify="center" align="center"  md="6" offset-md="0" offset-sm="0" sm="12"-->
      <!--             @load="this.$redrawVueMasonry('masonry_rating')"-->
      <!--             v-if="!loading && this.$store.state.current_cider !== null">-->
      <!--      </v-col>-->
      <v-col
        v-for="(bewertungsKategorie, index) in currentCider.kategorie.bewertungskategorien"
        :key="bewertungsKategorie.pruefmerkmal"
        class="justify-center px-0 px-md-3"
        justify="center"
        align="center"
        cols="12"
        lg="4"
        md="6"
        offset-md="0"
        offset-sm="0"
        sm="6"
        xs="12"
        @load="$redrawVueMasonry('masonry_rating')"
      >
        <v-card
          fill-height
          class="pa-0"
          style="max-width: 650px"
          align="center"
          :outlined="$vuetify.theme.dark"
        >
          <v-card-title class="pa-3 pb-1 justify-end">
            <v-row
              align="baseline"
              class="ma-0"
            >
              <p
                class="text-h6 text--primary mb-1"
                style="font-weight: normal"
              >
                <!--                {{ index + 1 }}: {{ $t(tBewertungskategorieString(bewertungsKategorie) + 'pruefmerkmal') }}-->
                {{ index + 1 }}: {{
                  getTranslation(bewertungsKategorie.translationMessages, 'pruefmerkmal', $store.getters["usersStore/getUserLanguage"]())
                }}
              </p>
              <v-tooltip
                v-if="getTranslation(bewertungsKategorie.translationMessages,'info',$store.getters['usersStore/getUserLanguage']()) != null"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    dense
                    color="grey"
                    align="center"
                    class="pl-1"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span
                  style="text-align: unset"
                  v-html="getTranslation(bewertungsKategorie.translationMessages,'info',$store.getters['usersStore/getUserLanguage']())"
                />
              </v-tooltip>
              <div class="d-flex flex-row align-center mb-1 ml-auto">
                <p
                  class="text-6 mb-0"
                  style="font-weight: normal; text-align: right"
                >
                  {{ calculatePointsForBewertungskategorie(bewertungsKategorie) }} /
                  {{ calculateMaxPointsForBewertungskategorie(bewertungsKategorie) }}
                </p>
                <transition name="slide-x-reverse-transition">
                  <v-icon
                    v-if="calculatePointsForBewertungskategorie(bewertungsKategorie) ===
                      calculateMaxPointsForBewertungskategorie(bewertungsKategorie)"
                  >
                    mdi-star
                  </v-icon>
                </transition>
              </div>
            </v-row>
          </v-card-title>

          <v-divider class="pb-1" />
          <div
            v-for="(bewertung, katIndex) in bewertungsKategorie.bewertungen"
            :key="bewertung.bezeichnung"
            class="pt-0 mt-0"
          >
            <v-card-text
              class="pt-0 mt-0 pb-0 pr-0 pl-0"
            >
              <v-container class="mt-0">
                <v-row
                  align="baseline"
                  cols="12"
                  justify="end"
                  class="pb-2"
                >
                  <v-col
                    sm="auto"
                    xs="12"
                    cols="12"
                    class="d-flex justify-xs-start pa-0 mr-auto"
                    style="padding-bottom: 0"
                  >
                    <v-card
                      :v-ripple="bewertung.info != null && bewertung.info !== ''"
                      class="pa-0 pt-2 pb-0 pl-4 flex-grow-1 justify-start text-left rounded-ripple"
                      flat
                      tile
                      style="white-space: nowrap; flex: 0 0 100%;"
                      @click="toggleEigenschaftenInfoStates(bewertung.index)"
                    >
                      <span style="font-size: 12pt;">
                        {{
                          getTranslation(bewertung.translationMessages, 'bezeichnung', $store.getters['usersStore/getUserLanguage']())
                        }}
                      </span>
                      <v-btn
                        v-if="bewertung.info"
                        icon
                        small
                        :class="{rotate_180: eigenschaftenInfoStates[bewertung.index]}"
                        @click="toggleEigenschaftenInfoStates(bewertung.index)"
                        @click.native.stop=""
                      >
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                      <v-expand-transition>
                        <div v-if="eigenschaftenInfoStates[bewertung.index]">
                          <span
                            style="text-align: start !important; font-size: 9pt;"
                            class="text-wrap"
                            v-html="getTranslation(bewertung.translationMessages,'info',$store.getters['usersStore/getUserLanguage']()) || bewertung.info"
                          />
                        </div>
                      </v-expand-transition>
                    </v-card>
                    <!--                    <v-tooltip-->
                    <!--                      v-if="bewertung.info"-->
                    <!--                      top-->
                    <!--                    >-->
                    <!--                      <template #activator="{ on, attrs }">-->
                    <!--                        <v-icon-->
                    <!--                          small-->
                    <!--                          color="grey"-->
                    <!--                          dark-->
                    <!--                          v-bind="attrs"-->
                    <!--                          v-on="on"-->
                    <!--                        >-->
                    <!--                          mdi-information-outline-->
                    <!--                        </v-icon>-->
                    <!--                      </template>-->
                    <!--                      <div-->
                    <!--                        style="text-align: unset"-->
                    <!--                        v-html="bewertung.info"-->
                    <!--                      />-->
                    <!--                    </v-tooltip>-->
                  </v-col>
                  <div
                    align="baseline"
                    class="d-flex justify-end align-end align-baseline"
                  >
                    <v-col
                      md="auto"
                      xs="auto"
                      style="margin-top: 0;"
                      class="pa-1 d-inline-flex align-baseline"
                      @click="ratingClick"
                    >
                      <v-btn
                        icon
                        outlined
                        :disabled="ratingDisabled"
                        x-small
                        :style="{'background-color': bewertung.punkte === 0 ? 'var(--v-secondary-base)' : '#FFFFFF00'}"
                        @click="set_zero_points(bewertung)"
                      >
                        <span
                          :style="{'color': bewertung.punkte === 0 ? 'var(--v-text_std_inv-base)' : 'var(--v-text_std-base)'}"
                        >
                          0
                        </span>
                      </v-btn>
                      <v-rating
                        v-model="bewertung.punkte"
                        :dense="!(highlightOpenRating && bewertung.punkte == null)"
                        hover
                        :style="{scale: highlightOpenRating && bewertung.punkte == null ? 1.1 : 1}"
                        :readonly="ratingDisabled"
                        length="5"
                        size="24"
                        class="animate_change"
                        :color="getRatingColor(bewertung)"
                        :background-color="getRatingColor(bewertung)"
                        @input="ratingChanged(bewertung)"
                      />
                    </v-col>
                    <v-col
                      md="auto"
                      xs="auto"
                      class="d-flex justify-center align-center pa-0"
                    >
                      <v-chip
                        small
                        class="d-flex justify-start align-center; pr-0 pl-1 mr-1"
                        style="overflow: visible; max-width: 90%"
                      >
                        <v-col
                          md="auto"
                          xs="auto"
                          style="margin-top: 0;"
                          class="font-weight-light pa-1 pr-0"
                        >
                          <span
                            style="min-width: 42px"
                          >
                            x {{ bewertung.faktor }}
                          </span>
                        </v-col>
                        <v-col
                          md="auto"
                          xs="auto"
                          class="pa-1 justify-center align-center"
                          align="center"
                        >
                          <v-chip
                            color="secondary_card"
                            class="d-flex justify-center"
                            :style="{'min-width': bewertung.faktor * 5 >= 10 ? '40px' : '40px',
                                     color: 'var(--v-text_std_inv-base)'}"
                          >
                            <span v-show="!bewertungLoadingStates[bewertung.index]">
                              {{ roundToDecimal(bewertung.faktor * bewertung.punkte) }}
                            </span>
                            <span v-show="bewertungLoadingStates[bewertung.index]">
                              <v-progress-circular
                                indeterminate
                                size="16"
                                width="2"
                                style="margin-bottom: 3px;"
                              />
                            </span>
                          </v-chip>
                        </v-col>
                      </v-chip>
                    </v-col>
                  </div>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider
              v-if="katIndex !== bewertungsKategorie.bewertungen.length - 1"
              style="max-width: 32px"
            />
          </div>
          <!--          <v-divider style="border-width: 4px !important;"></v-divider>-->
        </v-card>
      </v-col>

      <v-col
        v-if="!loading && currentCider != null"
        id="ratingKommentarCard"
        class="justify-center px-0 px-md-3"
        justify="center"
        align="center"
        lg="4"
        md="6"
        offset-md="0"
        offset-sm="0"
        sm="12"
        @load="$redrawVueMasonry('masonry_rating')"
      >
        <v-card
          fill-height
          class="mx-auto pa-0 pb-1"
          align="center"
          style="max-width: 600px"
          color="secondary_card"
          dark
          :outlined="$vuetify.theme.dark"
        >
          <v-card-title
            class="text-h6 pa-3 pb-1 mb-1"
            style="font-weight: normal;"
          >
            {{ $tc('comment', 1) }}
          </v-card-title>
          <!--          <v-card-title class="pa-3 pb-1 justify-end">-->
          <!--            <v-row class="ma-0">-->
          <!--              <p class="display-1 text&#45;&#45;primary mb-1" style="font-weight: normal;" >-->
          <!--                Bemerkung-->
          <!--              </p>-->
          <!--              <v-spacer></v-spacer>-->
          <!--              <v-btn class="white&#45;&#45;text" color="secondary" @click="kommentar_submit">-->
          <!--                Submit-->
          <!--              </v-btn>-->
          <!--            </v-row>-->
          <!--          </v-card-title>-->
          <v-divider />
          <v-textarea
            v-model="currentKommentar"
            class="ma-2"
            hide-details
            :label="$t('enterAComment')"
            outlined
            :readonly="ratingDisabled || sendingDisabled"
            style="border-width: 2px !important;"
            color="white"
            full-width
            rows="5"
          />
          <v-card-actions>
            <v-spacer />
            <v-btn
              class="white--text animate_change"
              color="white"
              text
              rounded
              :disabled="currentKommentar === lastKommentar || ratingDisabled || sendingDisabled"
              @click="kommentar_submit"
            >
              <span>
                {{ $t('save') }}
              </span>
              <v-progress-circular
                v-if="sending_comment"
                indeterminate
                color="white"
                style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);"
              />
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-card
        v-show="ratingCanBeFinished && getCurrentTasting.started && !getCurrentTasting.finished"
        class="mx-0 mx-md-auto pt-4 pb-2 mt-8 px-0 px-md-3"
        width="500"
        outlined
        style="border-width: medium; transition: box-shadow 0.2s ease-in-out;"
      >
        <v-icon
          size="148"
          style="opacity: 0.66;"
        >
          mdi-star-check-outline
        </v-icon>
        <v-card-subtitle class="pb-2 text-center text-subtitle-1">
          {{ $t('CiderStepper.step2Subtitle8') }}
        </v-card-subtitle>
        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            elevation="0"
            rounded
            class="ml-auto mr-auto pl-4 pr-4"
            @click="finishCiderJuror"
          >
            <v-icon
              size="22"
              class="pr-1"
            >
              mdi-star-check
            </v-icon>
            {{ $t('CiderInfoExpPanel.finishRatingJuror') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
    <v-expand-transition>
      <v-card
        v-show="ratingIsFinished"
        class="mx-auto pt-4 pb-2 mt-8 px-3"
        width="500"
        outlined
        style="border-width: medium; transition: box-shadow 0.2s ease-in-out;"
      >
        <v-icon
          size="148"
          style="opacity: 0.66;"
        >
          mdi-star-check
        </v-icon>
        <v-card-subtitle class="pb-2 text-center text-subtitle-1">
          {{ $t('CiderStepper.step2Subtitle5') }}
        </v-card-subtitle>
        <v-card-actions>
          <v-btn
            v-intersect="onIntersectToSummaryCard"
            color="primary"
            outlined
            elevation="0"
            rounded
            class="ml-auto mr-auto pl-4 pr-4"
            @click="toSummary()"
          >
            {{ $t('toOverallRating') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>

    <v-snackbar
      v-model="highlightOpenRatingSnackbar"
      color="secondary_card"
      rounded="xl"
      multi-line
      bottom
    >
      <span class="text-left text-subtitle-1">
        <v-icon
          left
          class="pb-1"
        >
          mdi-information-outline
        </v-icon>
        {{ $t('ratingIsNotFinishedYet') }}
      </span>
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          rounded
          class="ml-2"
          v-bind="attrs"
          @click="highlightOpenRatingSnackbar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <h1
      v-if="currentCider === null"
      class="vertical-align: middle; fill-height;"
    >
      Please select a Cider in Cider List
    </h1>
    <easter-egg-modal ref="easterEggModal" />
    <MyTour
      name="ciderRatingTour"
      :steps="tourSteps"
      :options="$store.getters['tourStore/getTourOptions']()"
      :callbacks="$store.state.tourStore.callbacks"
    />
  </v-container>
</template>

<script>
import Vue from 'vue'
import {apiTyped} from "@/api";
import store from "@/stores";
import {VueMasonryPlugin} from 'vue-masonry';
import CiderInfoExpPanel from "@/components/cider_info_exp_panel";
import Alert_loading_error from "@/components/alert_loading_error";
import {createCiderNotesFromDatabase} from "@/types/ciderNotes";
import CiderRatingStepper from "@/components/cider_rating_stepper";
import {sync} from "vuex-pathify";
import CiderRatingState from "@/types/ciderRatingState";
import {clearIntervalAsync} from 'set-interval-async'
import {setIntervalAsync} from 'set-interval-async/fixed'
import {CurrentPage} from "@/stores/app";
import ConfirmDialog from "@/components/confirm_dialog"
import {createLocaleMessagesForKategorie, getKategorieTranslationPrefix} from "@/types/kategorieRated";
import {getBewertungskategorieTranslationPrefix} from "@/types/bewertungskategorieRated";
import {getEigenschaftTranslationPrefix} from "@/types/eigenschaft";
import logos_mixin from "@/components/mixins/logos_mixin";
import tour_mixin from "@/components/mixins/tour_mixin";
import locale_mixin from "@/components/mixins/locale_mixin";
import MyTour from "@/components/my_tour";
import translation_mixin from "@/components/mixins/translation_mixin";
import EasterEggModal from "@/components/easter_egg_modal";
import easter_egg_mixin from "@/components/mixins/easter_egg_mixin";
import rating_mixin from "@/components/mixins/rating_mixin";
import util from "@/util";
import {createChairNotesFromDatabase, updateChairNotesFromDatabase} from "@/types/chairNotes";
import PageNavigation from "@/components/page_navigation";
import {allRoutes, makeRoute} from "@/router";
import {updateBewertungFromDatabase} from "@/types/bewertung";

Vue.use(VueMasonryPlugin)

export default Vue.extend({
  name: "Rating",
  components: {
    PageNavigation,
    EasterEggModal, MyTour, Alert_loading_error, CiderInfoExpPanel, CiderRatingStepper, ConfirmDialog},
  mixins: [logos_mixin, tour_mixin, locale_mixin, translation_mixin, easter_egg_mixin, rating_mixin],
  async beforeRouteLeave(to, from, next) {
    if (!this.ratingDisabled && this.lastKommentar !== this.currentKommentar) {
      const confirmResult = await this.$refs.confirm.open(
        this.$t('unsavedChanges'), this.$t('unsavedChangesMessage'),
        { color: 'secondary_card', thirdButton: true, thirdButtonText: this.$t('discard') },
        {btnOkay: "save"})
      if (confirmResult === true) {
          await this.kommentar_submit();
        next();
      }
      if (confirmResult === false) {
        this.$refs.ciderRatingStepper.setStep(2);
      }
      if (confirmResult === "third") {
        next()
      }
    } else {
      next();
    }
  },
  data: () => ({
    hideToSummaryButton: false,
    highlightOpenRatingSnackbar: false,
    highlightOpenRating: false,
    highlightOpenRatingTimeout: null,
    vTourRating: 100000,
    currentKommentar: "",
    changed_rating: false,
    masonry_rating: null,
    masonry_rating2: null,
    kategorie_name: "",
    firstLoad: true,
    loading: true,
    notes: null,
    error: false,
    errors: [],
    eigenschaftenInfoStates: [],
    bewertungLoadingStates: [],
    bewertungLoadingStatesTimeouts: [],
    sending_comment: false,
    sendingCommentTimeout: null,
    sendingDisabled: false,
    lastKommentar: "",
    rating: 0,
    loading_attrs: {
      class: 'mb-6',
      boilerplate: true,
      elevation: 2,
    },
    open_infos: 0,
    last_scroll: 0,
    wait_for_info_panel: false,
    wait_for_info_panel_timeout: null,
    info_panel_elevation: 1,
    intervalUpdateData: null,
    unsavedChangesDialog: null,
  }),
  computed: {
    tourSteps() {
      return [
        {
          target: '#ciderInfoExpPanel',
          header: {
            title: this.$t('tourProductInfos'),
          },
          content: this.$t('tourProductInfosMessage'),
          params: {
            placement: 'bottom',
            enableScrolling: false,
          }
        },
        {
          target: '#ciderInfoExpPanelPoints',
          header: {
            title: this.$t('tourCurrentPoints'),
          },
          content: this.$t('tourCurrentPointsMessage'),
          params: {
            placement: 'bottom',
            enableScrolling: false,
          }
        },
        {
          target: '#ciderInfoExpPanelPin',
          header: {
            title: this.$t('tourPinInfos'),
          },
          content: this.$t('tourPinInfosMessage'),
        },
        {
          target: '#ciderInfoExpPanelActions',
          header: {
            title: this.$t('tourRatingActions'),
          },
          params: {
            placement: 'bottom',
            enableScrolling: false,
          },
          content: this.$t('tourRatingActionsMessage'),
        },
        {
          target: '#ratingMasonry > div:nth-child(1) > div > div.v-card__title.pa-3.pb-1.justify-end > div',
          header: {
            title: this.$t('tourRatingCard'),
          },
          params: {
            placement: 'top',
            enableScrolling: false,
          },
          content: this.$t('tourRatingCardMessage'),
        },
        {
          target: '#ratingMasonry > div:nth-child(1) > div > div:nth-child(3) > div > div',
          header: {
            title: this.$t('tourRating'),
          },
          params: {
            placement: 'bottom',
            enableScrolling: false,
          },
          content: this.$t('tourRatingMessage'),
        },
        {
          target: '#ratingKommentarCard',
          header: {
            title: this.$t('tourComment'),
          },
          params: {
            placement: 'top',
          },
          content: this.$t('tourCommentMessage'),
        },
        {
          target: '#footerBadgesInfo',
          header: {
            title: this.$t('tourBadges'),
          },
          params: {
            placement: 'top',
          },
          content: this.$t('tourBadgesMessage'),
        },
        {
          target: '#stepperStep3',
          header: {
            title: this.$t('tourStep3'),
          },
          params: {
            placement: 'bottom',
          },
          content: this.$t('tourStep3Message'),
        },
      ]
    },
    currentTasting() {
      return this.$store.getters["usersStore/currentTasting"]();
    },
    currentPoints() {
      return this.$store.getters["ciderStore/getPointsForCurrentCider"]()
    },
    ratingDisabled () {
      return !(this.ciderState === CiderRatingState.startedByUser) || !this.tastingIsRunning;
    },
    ciderState () {
      return this.$store.getters["ciderStore/getCiderRatingState"]();
    },
    // currentCiderIdx: get("ciderStore/getCurrentCiderIdx"),
    currentCiderIdx () {
      return this.$store.getters["ciderStore/getCiderIdxByFlightsCidersId"](Number(this.$route.params.flightsCidersId));
    },
    ratingCanBeFinished() {
      return this.ciderState === CiderRatingState.startedByUser
        && this.$store.getters["ciderStore/userBewertungFinished"]();
    },
    ratingIsFinished() {
      return (this.ciderState === CiderRatingState.finishedByUser || this.ciderState === CiderRatingState.finishedByChair)
        && this.$store.getters["ciderStore/userBewertungFinished"]();
    },
    currentCider: sync('ciderStore/ciderList@[:currentCiderIdx]'),
    // currentCider () {
    //   return this.$store.getters["ciderStore/currentCider"]();
    // },
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        default: return false
      }
    },
    showNextBtn() {
      return this.ciderState === CiderRatingState.finishedByUser
        || this.ciderState === CiderRatingState.finishedByChair
        || this.getCurrentTasting.finished;
    }
  },
  async beforeMount() {
    // this.abschlussbewertung = await api.getGesamtbewertung(this.$store.get("current_cider"), false);
    // this.$store.set('abschlussbewertung', this.abschlussbewertung);
  },
  async created() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('keydown', this._keyListener);
    this.$store.state.appStore.currentPage = CurrentPage.rating;
    this.$store.state.ciderStore.currentFlightsCidersId = Number(this.$route.params.flightsCidersId);
    try {
      this.$store.state.active_tab = 1;
      this.$store.state.app_bar_loading = true;
      // check if cider was removed from flights
      const flightsCider = await apiTyped.getFlightsCidersPlain(this.$store.state.ciderStore.currentFlightsCidersId);
      if (!this.$store.state.tableStore.tables.flatMap(
          t => t.flights.map(f => f.id)).includes(Number(flightsCider.flights_id || -1))) {
        // cider was moved to different flight, show error
        console.error("Cider was moved", flightsCider, this.$store.state.tableStore.tables.flatMap(t => t.flights.map(f => f.id)))
        await this.$router.push(this.makeTastingRoute(this.tastingRoutes.ciderList, this.currentTasting) + "?missingCider=true&reload=true");
      }
      const eigenschaftenIds = this.currentCider.kategorie.bewertungskategorien.flatMap(
        bewKat => bewKat.bewertungen.map(bewertung => bewertung.eigenschaftId));
      const noOfEigenschaften = eigenschaftenIds.length;
      this.eigenschaftenInfoStates = new Array(noOfEigenschaften).fill(false);
      this.bewertungLoadingStates = new Array(noOfEigenschaften).fill(false);
      this.bewertungLoadingStatesTimeouts = new Array(noOfEigenschaften).fill(null);
      let userBewertungen = await apiTyped.getJurorBewertungen(
        this.currentCider, [this.$store.getters["usersStore/userId"]()]) || [];
      // create bewertungen if not yet created or some are missing for category
      if (userBewertungen.length !== noOfEigenschaften) {
        console.log("Creating ratings", eigenschaftenIds, userBewertungen, noOfEigenschaften)
        const userBewertungenCreated = await apiTyped.createBewertungenForCider(
          eigenschaftenIds, this.currentCider, this.$store.getters["usersStore/userId"](), userBewertungen) || [];
        userBewertungen.push(...userBewertungenCreated);
      }
      this.$store.commit("ciderStore/addBewertungenToKategorie",
        {kategorie: this.currentCider.kategorie, bewertungen: userBewertungen});
      console.log("userBewertungen", userBewertungen)
      const toDeleteBewertungen = userBewertungen.filter(b => !b.hasOwnProperty("isSet")).map(b => b.id);
      console.log("toDeleteBewertungen", toDeleteBewertungen)
      if (toDeleteBewertungen.length > 0) {
        await apiTyped.deleteBewertungen(toDeleteBewertungen);
        userBewertungen = await apiTyped.createBewertungenForCider(
          eigenschaftenIds, this.currentCider, this.$store.getters["usersStore/userId"](), userBewertungen) || [];
        this.$store.commit("ciderStore/addBewertungenToKategorie",
          {kategorie: this.currentCider.kategorie, bewertungen: userBewertungen});
      }
      // create user notes (and chair notes if user is chair) if not yet created
      const isChair = this.$store.getters["usersStore/isChair"]();
      if (this.currentCider.notes == null || (isChair && this.currentCider.chairNotes == null)) {
        await this.currentCider.createNotes(this.$store.getters["usersStore/userId"]());
      }
      // await this.updateData();
      // Add increasing index to bewertungen
      let idxCount = 0;
      this.currentCider.kategorie.bewertungskategorien.forEach(
        bewKat => bewKat.bewertungen.forEach(bew => {
          bew.index = idxCount;
          idxCount += 1;
          if (bew.bewertungId == null) console.log("bew.bewertungId == null", bew)
        })
      );
      await this.updateData();
      this.intervalUpdateData = setIntervalAsync(this.updateData, 6000);
      // this.currentKommentar = this.currentCider.notes.kommentar;
      this.currentKommentar = this.currentCider.notes != null ? this.currentCider.notes.kommentar : "";
      this.lastKommentar = this.currentKommentar;
      this.$redrawVueMasonry('masonry_rating');
      this.loading = false;
      this.firstLoad = false;
      if (!this.$store.getters["usersStore/user"]().userSettings.tourRatingComplete) {
        this.$tours['ciderRatingTour'].start();
      }
    } catch (error) {
      console.log("Error loading Ratings:", error);
      this.errors.push(JSON.stringify(error, Object.getOwnPropertyNames(error)));
      this.error = true;
    }
    this.$store.state.app_bar_loading = false;
  },
  updated() {
    this.$redrawVueMasonry('masonry_rating');
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async beforeDestroy() {
    if (this.intervalUpdateData !== null) await clearIntervalAsync(this.intervalUpdateData);
    window.removeEventListener("keydown", this._keyListener);
  },
  i18n: {
    messages: {
      de: {
        "tourProductInfos": 'Produkt-Infos',
        "tourProductInfosMessage": `Hier werden die Infos über das Produkt angezeigt.`,
        "tourCurrentPoints": 'Aktuelle Punktzahl',
        "tourCurrentPointsMessage": `Die aktuelle Punktzahl der eigenen Bewertung. <br>
                  Falls vorhanden wird auch die aktuelle Prämierungsstufe (Medaille) angezeigt.`,
        "tourPinInfos": 'Infos oben anpinnen',
        "tourPinInfosMessage": `Hier kann die Info-Karte oben angepinnt oder gelöst werden.`,
        "tourRatingActions": "Bewertungsaktionen",
        "tourRatingActionsMessage": `Aktionen, um den Bewertungs-Status des aktuellen Produktes zu verändern.`,
        "tourRatingCard": 'Prüfmerkmal',
        "tourRatingCardMessage": 'Anzeige des Prüfmerkmals und der gewichteten Zwischenpunktzahl',
        "tourRating": 'Eingabe der Bewertung',
        "tourRatingMessage": `Zu bewertende Eigenschaft und auswählbare Punkte.`,
        "tourComment": 'Eigener Abschlusskommentar',
        "tourCommentMessage": `Hier wird die Begründung der Bewertung eingegeben und abgeschickt.`,
        "tourBadges": 'Prämierungsstufen',
        "tourBadgesMessage": `Übersicht über die Prämierungsstufen und deren erforderlichen Punktzahl.`,
        "tourStep3": 'Übersicht aller Bewertungen',
        "tourStep3Message": `Erst wenn alle Prüfmerkmale bewertet wurden und die Bewertung als abgeschlossen markiert wurden,
                  können die Bewertungen anderer Juroren sowie die Gesamtpunktzahl eingesehen werden.`,
      },
      en: {
        "tourProductInfos": 'Product-Infos',
        "tourProductInfosMessage": `Information about the product is displayed here.`,
        "tourCurrentPoints": 'Current Score',
        "tourCurrentPointsMessage": `The current score of the individual evaluation. <br>
                   If available, the current award level (badge) is also displayed.`,
        "tourPinInfos": 'Pin info card on top',
        "tourPinInfosMessage": `The info card can be pinned or unpinned here.`,
        "tourRatingActions": "Evaluation Actions",
        "tourRatingActionsMessage": `Actions to change the evaluation status of the current product.`,
        "tourRatingCard": 'Test Characteristic',
        "tourRatingCardMessage": 'Display of the Test characteristic and the weighted intermediate score',
        "tourRating": 'Rating',
        "tourRatingMessage": `Property to evaluate with selectable points.`,
        "tourComment": 'Individual Comment',
        "tourCommentMessage": `A short comment regarding the evaluation can be entered here.`,
        "tourBadges": 'Award Tiers',
        "tourBadgesMessage": `Overview of the award levels and their required scores.`,
        "tourStep3": 'Overview of all evaluations',
        "tourStep3Message": `Only when all inspection characteristics have been evaluated and the evaluation has been marked as complete
                   the ratings of other judges as well as the total score may be viewed.`,
      }
    }
  },
  methods: {
    onIntersectToSummaryCard (entries, observer) {
      this.hideToSummaryButton = entries[0].isIntersecting;
    },
    async finishCiderJuror() {
      await this.$refs.ciderInfoExpPanel?.updateUserNotesFieldFinishedTrue();
    },
    onIntersect (entries, observer) {
      if (this.loading) return;
      if (entries[0].intersectionRatio >= 1) this.$refs.ciderInfoExpPanel?.closePanelFromParent();
    },
    async updateUserNotesFieldFinishedTrue() {
      if (this.lastKommentar !== this.currentKommentar) {
        const confirmResult = await this.$refs.confirm.open(
          this.$t('unsavedChanges'), this.$t('unsavedChangesMessage'),
          { color: 'secondary_card', thirdButton: true, thirdButtonText: this.$t('discard') },
          {btnOkay: "save"})
        if (confirmResult === true) {
          await this.kommentar_submit();
          await this.$refs.ciderInfoExpPanel.doUpdateUserNotesFieldFinishedTrue();
        }
        if (confirmResult === false) {
        }
        if (confirmResult === "third") {
          await this.$refs.ciderInfoExpPanel.doUpdateUserNotesFieldFinishedTrue();
        }
      } else {
        await this.$refs.ciderInfoExpPanel.doUpdateUserNotesFieldFinishedTrue();
      }
    },
    getRatingColor (bewertung) {
      if (this.highlightOpenRating && bewertung.punkte == null) {
        return this.$vuetify.theme.currentTheme.error;
      } else if (this.ratingDisabled) {
        return this.$vuetify.theme.currentTheme.secondary_grey
      } else {
        return this.$vuetify.theme.currentTheme.secondary
      }
    },
    userWantsToFinish() {
      this.highlightOpenRating = true;
      this.highlightOpenRatingSnackbar = true;
      window.setTimeout(() => { this.$redrawVueMasonry('masonry_rating') }, 200);
      if (this.highlightOpenRatingTimeout != null) window.clearTimeout(this.highlightOpenRatingTimeout);
      this.highlightOpenRatingTimeout = window.setTimeout(() => {  // close after 2.5 s
        this.highlightOpenRating = false;
        window.setTimeout(() => { this.$redrawVueMasonry('masonry_rating') }, 200);
      }, 2500);
    },
    ratingClick() {
      if (!(this.ciderState === CiderRatingState.startedByUser)) this.$refs.ciderInfoExpPanel?.ratingClick();
    },
    roundToDecimal(n) {
      return util.util.round_to_decimal(n, 0.1)
    },
    async updateData() {
      await this.updateRatingChairNotes();
      // const allData = Promise.all([
      //   this.updateMarkedFinished(),
      //   this.updateRatingChairNotes(),
      // ])
      // await allData;
    },
    async updateRatingChairNotes() {
      const chairNotesFields = await apiTyped.getChairNotesFieldsForCider(this.currentCider);
      if (chairNotesFields != null && this.currentCider.chairNotes != null) {
        this.currentCider.chairNotes = updateChairNotesFromDatabase(this.currentCider.chairNotes, chairNotesFields);
      }
      if (chairNotesFields != null && this.currentCider.chairNotes == null) {
        this.currentCider.chairNotes = createChairNotesFromDatabase(chairNotesFields);
      }
    },
    // async updateCiderNotes() {
    //   const ciderNotes = await apiTyped.getUserNotesForCider([this.currentCider]);
    //   const chairNotes = await apiTyped.getChairNotesForCider([this.currentCider])
    //   this.currentCider.notes = createCiderNotesFromDatabase(ciderNotes[0])
    //   this.currentCider.chairNotes = chairNotes[0];
    // },
    // async updateMarkedFinished() {
    //   this.markedFinished = await this.$store.dispatch("ciderStore/allBewertungenMarkedFinished", null);
    // },
    // tKategorieString(kategorie) {
    //   return getKategorieTranslationPrefix(kategorie);
    // },
    // tBewertungskategorieString(bewertungskategorie) {
    //   return getBewertungskategorieTranslationPrefix(bewertungskategorie);
    // },
    // tEigenschaftenString(eigenschaft) {
    //   return getEigenschaftTranslationPrefix(eigenschaft);
    // },
    set_zero_points(eigenschaft) {
      eigenschaft.punkte = 0;
      this.ratingChanged(eigenschaft);
    },
    set_info_panel_timeout() {
      this.wait_for_info_panel = false;
      this.wait_for_info_panel_timeout = null;
    },
    async toggleEigenschaftenInfoStates(index) {
      Vue.set(this.eigenschaftenInfoStates, index, !this.eigenschaftenInfoStates[index]);
      for (const i in [1,2]) {
        window.setTimeout(() => {
          this.$redrawVueMasonry('masonry_rating');
        }, 500);
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      this.$redrawVueMasonry("masonry_rating");
    },
    // handle_info_click() {
    //   this.wait_for_info_panel = true;
    //   if (this.wait_for_info_panel_timeout !== null)
    //     window.clearTimeout(this.wait_for_info_panel_timeout);
    //   window.setTimeout(this.set_info_panel_timeout, 1000);
    // },
    getBadgesData() {
      return this.$store.state.badgesStore.badgesData;
    },
    kommentar_submit: async function () {
      this.sendingDisabled = true;
      this.sendingCommentTimeout = window.setTimeout(() => { this.sending_comment = true; }, 1000);
      this.lastKommentar = this.currentKommentar;
      this.currentCider.notes.kommentar = await apiTyped.updateNotesField(
          this.currentCider.notes.id, this.currentCider.id, "kommentar", this.currentKommentar);
      if (this.sendingCommentTimeout != null) window.clearTimeout(this.sendingCommentTimeout);
      this.sending_comment = false;
      this.sendingDisabled = false;
    },
    ratingChanged: async function (bewertung) {
      this.bewertungLoadingStatesTimeouts[bewertung.index] =
        window.setTimeout(() => { Vue.set(this.bewertungLoadingStates, bewertung.index, true); }, 1000);
      try {
        const resp = await apiTyped.updateBewertungForCider(bewertung, this.currentCider);
        if (resp != null) bewertung.punkte = resp.punkte;
        if (resp == null) {
          this.error = true;
        }
      } catch (e) {
        try {  // try getting existing points from server
          const resp = await apiTyped.getBewertungById(bewertung.bewertungId);
          console.error(e)
          bewertung.punkte = resp.punkte;
        } catch (e) {  // fail-safe: show error
          bewertung.punkte = null;
          this.error = true;
          console.error(e)
          this.errors.push(JSON.stringify(error, Object.getOwnPropertyNames(error)));
          return;
        }
      }
      this.$store.commit("ciderStore/updateBewertungForCurrentCider", bewertung);
      if (this.bewertungLoadingStatesTimeouts[bewertung.index] != null) window.clearTimeout(
        this.bewertungLoadingStatesTimeouts[bewertung.index]);
      Vue.set(this.bewertungLoadingStates, bewertung.index, false);
    },
    calculatePointsForBewertungskategorie(bewertungskategorie) {
      return store.getters["ciderStore/getPointsForBewertungskategorie"](bewertungskategorie);
    },
    calculateMaxPointsForBewertungskategorie(bewertungskategorie) {
      return store.getters["ciderStore/getMaxPointsForBewertungskategorie"](bewertungskategorie);
    },
    getCurrentBadgeUrl () {
      return store.getters["badgesStore/getBadgeIcon"](
          this.$store.getters["ciderStore/getPointsForCurrentCider"](), 100);
    },
    getBadgeUrl (width, points) {
      return store.getters["badgesStore/getBadgeIcon"](points, width);
    },
    handleScroll () {
      if (!this.wait_for_info_panel && this.last_scroll - window.scrollY < -1) {
        this.open_infos = null;
      }
      if (window.scrollY <= 25) {
        this.info_panel_elevation = 2;
        // this.open_infos = 0  // open info box
      } else {
        this.info_panel_elevation = 6;
      }
      this.last_scroll = window.scrollY;
    },
    async toSummary() {
      const path = makeRoute(
        this.makeTastingRoute(this.tastingRoutes.summary, this.getCurrentTasting),
        { flightsCidersId: this.currentCider.flightsCidersId})
      await this.$router.push(path);
    }
  },
});
</script>

<style scoped>
.animate_change {
  transition:all 0.25s ease;
}

.flex-gap > div {
  margin: 6px;
}

.rounded_bl {
  border-bottom-left-radius: 20px !important;
}
.rounded_bl::before {
  border-bottom-left-radius: 20px !important;
}

.rounded-ripple {
  border-radius: 20px !important;
}
/deep/ .rounded-ripple > .v-ripple__container {
  margin-left: 4px;
  margin-right: -4px;

}
.rounded-ripple::before {
  border-radius: 20px !important;
  margin-left: 4px;
  margin-right: -4px;
}

/deep/ .theme--light.v-skeleton-loader {
  background-color: var(v--card_background-base) !important;
}
</style>
