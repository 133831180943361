import {components, MyCollections} from "@/api/types/my-collections";
import {dateFromString} from "@/util/helper";

export interface Invoice {
    id: number;
    status: "published" | "draft" | "archived";
    userCreated: string;
    userUpdated: string | null;
    dateCreated: Date;
    dateUpdated: Date | null;
    invoiceNumber: string;
    invoicePrintId: components["schemas"]["Files"];
    tastingPurchaseId: number;
    sent: boolean;
    dateSent: Date | null;
    posted: boolean;
    datePosted: Date | null;
}

export function createInvoice(invoice: MyCollections["invoices"]): Invoice {
    return <Invoice>{
        id: invoice.id,
        status: invoice.status,
        userCreated: invoice.user_created as string,
        userUpdated: invoice.user_updated as string,
        dateCreated: dateFromString(invoice.date_created),
        dateUpdated: dateFromString(invoice.date_updated),
        invoiceNumber: invoice.invoice_number,
        invoicePrintId: typeof invoice.invoice_print !== "string"
            ? invoice.invoice_print?.id || -1 : invoice.invoice_print,
        tastingPurchaseId: typeof invoice.tasting_purchase !== "number"
            ? invoice.tasting_purchase?.id || -1 : invoice.tasting_purchase,
        sent: invoice.sent,
        dateSent: dateFromString(invoice.date_sent),
        posted: invoice.posted,
        datePosted: dateFromString(invoice.date_posted),
    }
}