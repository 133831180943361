import {createEigenschaftFromDatabase, Eigenschaft} from "@/types/eigenschaft";
import {components, MyCollections} from "@/api/types/my-collections";
import {createTranslationMessagesFromDb, TranslationMessages} from "@/types/translationMessages";

interface Bewertungskategorie {
    id: number;
    pruefmerkmal: string;  // bezeichnung
    info: string;
    eigenschaften: Eigenschaft[];
    translations: BewertungskategorieTranslation[] | undefined;
    translationMessages: TranslationMessages | null;
    sort: number;
}

interface BewertungskategorieTranslation {
    lang: string;
    pruefmerkmal: string;
    info: string;
}


export function createBewertungskategorieFromDatabase(
    bewertungskategorie: MyCollections["bewertungskategorien"], sort = -1
) : Bewertungskategorie {
    console.log("Got bew", bewertungskategorie)
    let translations = undefined;
    if (bewertungskategorie.translations?.every(t => typeof t !== "number")) {
        const kategorie_translations =
            bewertungskategorie.translations as components["schemas"]["ItemsBewertungskategorienTranslations"][];
        translations = kategorie_translations?.map(t => {
            if ((t.languages_code as components["schemas"]["ItemsLanguages"]) !== null)
                return <BewertungskategorieTranslation>{
                    lang: (t.languages_code as components["schemas"]["ItemsLanguages"]).code?.substring(0, 2),
                    info: t.info,
                    pruefmerkmal: t.pruefmerkmal,
                }
        });
    }
    // noinspection UnnecessaryLocalVariableJS
    const newBewertungskategorie = <Bewertungskategorie>{
        id: Number(bewertungskategorie?.id),
        pruefmerkmal: bewertungskategorie?.pruefmerkmal || "",
        info: bewertungskategorie.info || "",
        eigenschaften: bewertungskategorie.eigenschaften?.map((eigenschaft) =>
            createEigenschaftFromDatabase(
                (eigenschaft as components["schemas"]["ItemsBewertungskategorienEigenschaften"])
                    .eigenschaften_id as MyCollections["eigenschaften"],
                (eigenschaft as components["schemas"]["ItemsBewertungskategorienEigenschaften"]).sort
                || (eigenschaft as components["schemas"]["ItemsBewertungskategorienEigenschaften"]).id
                || -1)),
        translations: translations,
        translationMessages: createTranslationMessagesFromDb(bewertungskategorie.translations as any[]),
        sort: sort,
    };
    newBewertungskategorie.eigenschaften.sort((e1, e2) => e1.sort - e2.sort);
    return newBewertungskategorie;
}

export { Bewertungskategorie, BewertungskategorieTranslation };