import {Eigenschaft, EigenschaftTranslations} from "./eigenschaft";
import {Bewertungskategorie} from "@/types/bewertungskategorie";
import {MyCollections} from "@/api/types/my-collections";
import {dateFromString} from "@/util/helper";
import {BewertungskategorieRated} from "@/types/bewertungskategorieRated";
import {getBewertungFromKategorieRated, KategorieRated} from "@/types/kategorieRated";
import {TranslationMessages} from "@/types/translationMessages";

type Bewertung = {
    bewertungId: number | undefined;
    jurorId: string | undefined;
    ciderId: number  | undefined;
    eigenschaftId: number;
    punkte: number | null;
    bezeichnung: string;
    faktor: number;
    info: string;
    bewertungskategorie?: Bewertungskategorie | BewertungskategorieRated;
    dateCreated?: Date | null;
    dateUpdated?: Date | null;
    translations: EigenschaftTranslations[] | undefined;
    translationMessages: TranslationMessages | null;
}

type BewertungData = {
    bewertungId: number;
    jurorId: string;
    ciderId: number;
    eigenschaftId: number;
    punkte: number | null;
    // faktor: number;
    dateCreated?: Date | null;
    dateUpdated?: Date | null;
    faktor: number;
}

    // constructor() {
    //     this.bewertungId = -1;
    //     this.jurorId = "";
    //     this.ciderId = "";
    //     this.punkte = 0;
    //     this.bezeichnung = "";
    //     this.eigenschaftId = -1;
    //     this.faktor = 0;
    //     this.info = ""
    // }

export function createBewertungFromEigenschaft(eigenschaft: Eigenschaft): Bewertung {
    // noinspection UnnecessaryLocalVariableJS
    const bewertung: Bewertung = {
        bewertungId: undefined, ciderId: undefined, jurorId: undefined, punkte: null,
        eigenschaftId: Number(eigenschaft.eigenschaftId !== undefined ? eigenschaft.eigenschaftId : -1),
        bewertungskategorie: eigenschaft.bewertungskategorie,
        bezeichnung: eigenschaft.bezeichnung,
        faktor: Number(eigenschaft.faktor),
        info: eigenschaft.info,
        dateUpdated: null,
        dateCreated: null,
        translations: eigenschaft.translations,
        translationMessages: eigenschaft.translationMessages,
    }
    return bewertung;
}

export function updateBewertungFromDatabase(bewertung: Bewertung, newBewertung: MyCollections["bewertungen"])
        : Bewertung {
    bewertung.bewertungId = Number(newBewertung.id || -1);
    bewertung.eigenschaftId = typeof newBewertung.eigenschaft_id === "number" ? newBewertung.eigenschaft_id || -1 :
        newBewertung.eigenschaft_id?.id || -1;
    bewertung.ciderId = typeof newBewertung.cider_id === "number" ?
        newBewertung.cider_id : newBewertung.cider_id?.id || -1;
    bewertung.jurorId = typeof newBewertung.juror_id === "string"
        ? newBewertung.juror_id : newBewertung.juror_id?.id || "";
    bewertung.punkte = newBewertung.punkte === undefined ? null : newBewertung.punkte;
    bewertung.dateUpdated = dateFromString(newBewertung?.date_updated);
    bewertung.dateCreated = dateFromString(newBewertung.date_created);
    return bewertung;
}

export function createBewertungFromDatabase(databaseBewertung: MyCollections["bewertungen"],
                                            kategorieRated: KategorieRated): BewertungData {
    // noinspection UnnecessaryLocalVariableJS
    const bewertung: BewertungData = {
        bewertungId: databaseBewertung?.id || -1,
        eigenschaftId: typeof databaseBewertung.eigenschaft_id === "number" ?
            databaseBewertung.eigenschaft_id || -1 : databaseBewertung.eigenschaft_id?.id || -1,
        ciderId: typeof databaseBewertung.cider_id === "number" ?
            databaseBewertung.cider_id : databaseBewertung.cider_id?.id || -1,
        jurorId: typeof databaseBewertung.juror_id === "string" ?
            databaseBewertung.juror_id : databaseBewertung.juror_id?.id || "",
        punkte: databaseBewertung.punkte === undefined ? null : databaseBewertung.punkte,
        dateCreated: dateFromString(databaseBewertung?.date_created),
        dateUpdated: dateFromString(databaseBewertung?.date_updated),
        faktor: 1,
    }
    const bewertung_ = getBewertungFromKategorieRated(kategorieRated, bewertung.eigenschaftId)
    bewertung.faktor = bewertung_ !== null ? bewertung_.faktor : 1;
    return bewertung;
}

export { Bewertung, BewertungData };