<template>
  <v-card
    class="text-left"
    style="max-width: 100vw !important; overflow: auto !important; "
    @click.native.stop=""
  >
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ flight.flightName }}</v-list-item-title>
          <v-list-item-subtitle>
            <v-icon
              v-if="flightFinished(table, index)"
              left
              small
            >
              {{ flightFinished(table, index) === true ? 'mdi-check-decagram' : undefined }}
            </v-icon>
            Insgesamt {{ flight.ciders.length }} Ciders
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="$emit('menuClose')"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-divider style="background-color: var(--v-divider-base)" />
    <v-list style="max-height: 50vh !important; overflow: auto !important;">
      <v-list-item
        v-for="cider in getCidersForFlight(table, index)"
        :key="cider.id"
        @click="() => {}"
      >
        <v-list-item-avatar
          class="elevation-3"
        >
          <badge
            v-if="getBadgeData(cider.totalPoints) != null"
            :badge-data="getBadgeData(cider.totalPoints)"
            :badge-width="40"
            show-none-badge
          />
<!--          <v-btn-->
<!--            color="transparent"-->
<!--            dark-->
<!--            fab-->
<!--          >-->
<!--            <v-img-->
<!--              max-height="40px"-->
<!--              max-width="40px"-->
<!--              :lazy-src="getBadgeUrl(200, getTotalPointsForCider(cider))"-->
<!--              :src="getBadgeUrl(200, getTotalPointsForCider(cider))"-->
<!--              alt="Badge"-->
<!--            />-->
<!--          </v-btn>-->
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ cider.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-icon
              small
              :color="getCiderStateIconColor(cider)"
            >
              {{ getCiderStateIcon(cider) }}
            </v-icon>
            {{ getCiderStateText(cider) }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="d-flex justify-center align-center">
          <v-chip
            :color="$store.getters['badgesStore/getColor'](getTotalPointsForCider(cider))"
            label
            small
            style="margin:0 auto; min-width: 64px"
            class="pl-1 pr-1 justify-center ml-0 mr-0"
          >
            <v-icon small>
              mdi-star
            </v-icon>
            {{ getTotalPointsForCider(cider) }}
          </v-chip>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import cider_mixin from "@/components/mixins/cider_mixin";
import user_mixin from "@/components/mixins/user_mixin";
import CiderRatingState from "@/types/ciderRatingState.ts";
import Badge from "@/components/badge";

export default {
  name: "FlightMenu",
  components: {Badge},
  mixins: [cider_mixin, user_mixin],
  props: {
    show: {type: Boolean, required: true, default: false},
    flight: {type: Object, required: true, default: () => null},
    table: {type: Object, required: true, default: () => null},
    index: {type: Number, required: true, default: 0},
  },
  mounted() {
    console.log("FLIGHTMENU", this.flight, this.table, this.index, this.getCidersForFlight(this.table, this.index))
  },
  methods: {
    getCidersForFlight(table, flightIdx) {
      if (table.ciders === undefined) {
        if (typeof this.flight.ciders !== "number") {
          return this.flight.ciders;
        } else return [];
      }
      return table.ciders.filter(cider => cider.flightId === table.flights[flightIdx].id);
    },
    flightFinished(table, flightIdx) {
      const ciders = this.getCidersForFlight(table, flightIdx);
      return ciders.every(cider =>
          this.$store.getters["ciderStore/getCiderRatingState"](cider) === CiderRatingState.finishedByChair);
    },
    getTotalPointsForCider(cider) {
      return this.calculateTotalPointsForCider(cider, true);
    },
  },
}
</script>

<style scoped>

</style>