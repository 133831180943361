<template>
  <div v-if="product !== null && bewertungskategorie !== null">
    <div class="parent_grid mt-2">
      <div class="merkmal d-flex justify-end pr-4">
        {{ bewertungskategorie.pruefmerkmal }}
      </div>
      <template
        v-for="(bewertung, index) in bewertungenOrEigenschaften"
      >
        <div
          :key="bewertung.id"
          class="one_col"
        >
          <div
            id="container"
            class="d-flex flex-scroll justify-start header_left eigenschaft_gap pr-3"
            :class="{eigenschaft: index < bewertungenOrEigenschaften.length - 1}"
          >
            <span
              class="d-flex justify-start"
              style="white-space: nowrap;"
            >
              {{ bewertung.bezeichnung || "--" }}
            </span>
            <div
              :key="bewertung.id + '_rating'"
              class="d-flex mb-0 flex-scroll justify-end flex-row align-start"
              style="margin-left: auto;"
            >
              <v-btn
                icon
                outlined
                x-small
                style="opacity: 0.6;"
                :style="{'background-color': bewertung.punkte === 0 ? 'var(--v-secondary-base)' : '#FFFFFF00'}"
              >
                <span
                  :style="{'color': bewertung.punkte === 0 ? 'var(--v-text_std_inv-base)' : 'var(--v-text_std-base)'}"
                >
                  0
                </span>
              </v-btn>
              <v-rating
                size="20"
                style="transform: translateY(-2px);"
                class="align-start"
                color="primary"
                dense
                background-color="primary"
              />
            </div>
          </div>
        </div>
        <div
          :key="bewertung.id"
          class="one_col eigenschaft_gap"
          :class="{eigenschaft: index < bewertungenOrEigenschaften.length - 1}"
        >
          x{{ bewertung.faktor }}
        </div>
        <div
          :key="bewertung.id"
          class="one_col eigenschaft_gap"
          :class="{eigenschaft: index < bewertungenOrEigenschaften.length - 1}"
        />
      </template>
    </div>
    <v-divider
      v-if="!isLast"
      inset
      style="border-width: 1px !important; border-color: var(--v-divider_light-base);
               background-color: var(--v-divider_light-base)"
    />
    <v-divider
      v-if="isLast"
      style="border-color: var(--v-divider_light-base) !important"
    />
  </div>
</template>

<script>
import util from "@/util";

export default {
  name: "ManualRatingJudgeTableCategory",
  props: {
    product: {type: Object, required: true, default: () => null},
    bewertungskategorie: {type: Object, required: true, default: () => null},
    isLast: {type: Boolean, required: false, default: false},
  },
  computed: {
    bewertungenOrEigenschaften() {
      if (this.bewertungskategorie.hasOwnProperty("bewertungen")) return this.bewertungskategorie.bewertungen;
      if (this.bewertungskategorie.hasOwnProperty("eigenschaften")) return this.bewertungskategorie.eigenschaften;
      return [];
    },
  },
  methods: {
    getPointsForBewertung(bewertung, cider, decPlaces = 0.1) {
      const points = this.$store.getters["ciderStore/getBewertungForJuroren"](
        bewertung.eigenschaftId, cider) * bewertung.faktor;
      return util.util.round_to_decimal(points, decPlaces)
    }
  }
}
</script>

<style scoped>
.merkmal {
    grid-row: 1 / 5;
}
.eigenschaft_gap {
    margin-bottom: 3px;
}
.eigenschaft {
    border-bottom: 1px solid var(--v-divider_light-base);
}
.flex-scroll {
    display: flex;
    justify-content: flex-end;
    align-content: flex-start;
    flex-flow: nowrap;
}

.header_left {
    position: sticky;
    left: 0;
    z-index: 2;
    justify-self: start;
    text-align: start;
}

.flex-scroll {
    /*padding: 4px 4px;*/
    display: flex;
    /*align-items: start;*/
    justify-content: flex-end;
    flex-flow: nowrap;
}

#container {
    display: flex;
    overflow: auto;
    flex-flow: row wrap;
    /*justify-content: end;*/
}

/deep/ .v-rating > .v-icon {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.one_col {
    grid-column: auto;
}
</style>