<template>
  <v-card
    outlined
    color="background"
    style="border-color: var(--v-divider-base) !important;"
    rounded="lg"
  >
    <div class="d-flex pa-1 overflow-hidden align-center">
      <v-avatar>
        <img
          v-if="user.avatar"
          :src="getImageUrl(user.avatar.id)"
          alt="Avatar"
        >
        <span
          v-else
          class="white--text text-h5"
        >
          {{ user.firstName.length > 0 ? user.firstName[0] : '' }} {{ user.lastName.length > 0 ? user.lastName : '-' }}
        </span>
      </v-avatar>
      <div class="pl-2 text-left" style="width: calc(100% - 48px) !important;">
        <v-tooltip
          top
        >
          <template #activator="{ on }">
            <div
              style="max-width: 100%; text-overflow: ellipsis !important; white-space: nowrap; overflow: hidden;"
              v-on="on"
            >
              {{ user.firstName || '' }} {{ user.lastName || 'No Name' }}
            </div>
          </template>
          {{ user.firstName || '' }} {{ user.lastName || 'No Name' }}
        </v-tooltip>
        <div
          class="text--secondary text-caption"
          style="max-width: 100%; text-overflow: ellipsis !important; white-space: nowrap; overflow: hidden;"
        >
          At {{ 3 }} tastings
        </div>
      </div>
      <slot name="right" />
    </div>
  </v-card>
</template>

<script>
import logos_mixin from "@/components/mixins/logos_mixin";

export default {
  name: "TableDesignerJudgeCard",
  mixins: [logos_mixin],
  props: {
    user: { type: Object, required: true, default: null },
    stats: { type: Object, required: true, default: null },
    showDetails: { type: Boolean, required: false, default: false },
  },
  data: () => ({
  }),
  methods: {
  },
}
</script>

<style scoped>

</style>