<template>
  <v-container
    v-if="loaded"
    v-resize="resizedWindow"
    fluid
    class="d-flex justify-center"
  >
    <v-row
      ref="container"
      class="d-flex justify-center"
      style="max-width: 2000px;"
    >
      <v-col
        v-if="!hideEditForm"
        cols="12"
        :md="showPreview ? 8 : 9"
        class="d-flex pa-2 justify-center"
      >
        <v-form
          ref="form"
          v-model="valid"
          class="d-flex flex-column"
          style="max-width: 1000px;"
          lazy-validation
        >
          <div
            class="blurred-background mb-4"
            style="width: calc(100% + 16px); position: sticky; z-index: 12; border-radius: 0 !important; margin: 0 -8px 0 -8px;"
            :style="{'background-color': $vuetify.theme.currentTheme.background_blurred + '!important', top: appBarHeight}"
          >
            <span
              class="text-h6 font-weight-light"
            >
              • {{ $t('productNameBrand') }}
            </span>
            <div
              class="d-flex justify-center flex-grow-1 centered-input pl-0 pr-0"
            >
              <v-textarea
                :key="ciderNameKey"
                v-model="name"
                :rules="nameRules"
                auto-grow
                outlined
                rows="1"
                full-width
                style="max-width: 600px;"
                class="text-break cider-name centered-input d-flex rounded-lg"
                required
                hint=""
                :label="$t('enterProductNameBrand')"
                input="this.style.height = &quot;&quot;;this.style.height = this.scrollHeight + &quot;px&quot;"
                @change="changeForm"
              />
            </div>
            <v-divider class="mt-2" />
          </div>
          <div
            v-if="checkRequiredFields"
            class="align-baseline text-left pt-0 pb-8 text--secondary text-caption"
          >
            <v-icon
              small
              class="mb-1"
            >
              mdi-information-outline
            </v-icon>
            <span class="pl-1 text-body-1">
              {{ $t('requiredFields') }} <strong>•</strong>
            </span>
          </div>

          <v-row
            class="pa-0 justify-start d-flex"
            style="max-width: 300px !important;"
          >
            <v-card-title class="pa-0 pl-4 pb-3 text--secondary d-flex align-start">
              <span class="info-label-text">
                • {{ $tc('category', 1) }}
              </span>
              <!--              <v-icon class="ml-2">-->
              <!--                mdi-help-circle-outline-->
              <!--              </v-icon>-->
            </v-card-title>
          </v-row>
          <v-row>
            <v-col
              class="d-flex flex-column ml-auto mr-auto pa-0 pl-3 pr-3 pb-8 ml-0 mr-0 justify-center align-center"
              style="cursor: pointer;"
              @click="openCategoryMenu"
            >
              <v-hover
                v-slot="{ hover }"
              >
                <v-card
                  outlined
                  color="background"
                  class="ma-1"
                  style="transition: box-shadow 0.1s ease-in-out; min-height: 100px; min-width: min(450px, 100%); border-color: var(--v-text_box_border-base) !important;"
                  :class="{'hovered-category': hover}"
                  :elevation="toggleCategoryMenu ? 4 : 0"
                >
                  <v-row no-gutters>
                    <v-col cols="10">
                      <v-card-title
                        class="text-left text-break"
                      >
                        {{
                          (selectedKategorie == null || selectedKategorie.id === -1)
                            ? $t('chooseCategory')
                            : getTranslation(selectedKategorie.translationMessages, 'name', currentLanguage)
                        }}
                      </v-card-title>
                      <v-card-text
                        v-if="selectedKategorie != null"
                        class="text-left small-p-margin"
                        v-html="getTranslation(selectedKategorie.translationMessages,'info',currentLanguage)"
                      />
                    </v-col>
                    <v-col
                      cols="2"
                      class="pt-3 mb-auto"
                    >
                      <v-btn
                        icon
                      >
                        <v-icon :class="{'rotate-180': toggleCategoryMenu}">
                          mdi-chevron-down
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-hover>
              <v-input
                v-model="selectedKategorie"
                :rules="selectedKategorieRules"
                class="d-flex justify-center ml-auto mr-auto"
                @change="changeForm"
              />
            </v-col>
          </v-row>

          <v-expand-transition>
            <v-row
              v-show="toggleCategoryMenu"
              ref="categoryMenu"
            >
              <v-col class="d-flex">
                <v-container class="pa-2">
                  <v-card-title class="text-h4">
                    {{ $t('selectCategory') }}
                  </v-card-title>
                  <v-container class="pa-0 pb-4">
                    <v-item-group
                      v-model="selectedKategorie"
                      active-class=""
                      mandatory
                    >
                      <v-row
                        class="d-flex ml-auto mr-auto"
                      >
                        <v-col
                          v-for="(category, i) in kategorien"
                          :key="i"
                          cols="12"
                          sm="6"
                          class="d-flex align-self-auto pl-0 pr-0 justify-center"
                        >
                          <v-item
                            v-show="category.id !== -1"
                            v-slot="{ active, toggle }"
                            :value="category"
                          >
                            <v-hover
                              v-slot="{ hover }"
                            >
                              <v-card
                                outlined
                                color="background"
                                style="position: relative; overflow: hidden; width: min(100%, 450px); border-color: var(--v-text_box_border-base) !important;"
                                class="ml-2 mr-2"
                                :class="{'hovered-category': hover, 'selected-category': active}"
                                @click="selectKategorie(toggle)"
                              >
                                <div
                                  v-show="active"
                                  class="triangle-top-right rounded-t-xl"
                                />
                                <v-card-title
                                  class="text-left text-break"
                                  style="line-height: 1.25 !important;"
                                >
                                  {{
                                    (category === null || !category)
                                      ? ''
                                      : getTranslation(category.translationMessages, 'name', currentLanguage)
                                  }}
                                </v-card-title>
                                <v-card-text
                                  v-if="category !== null && category"
                                  class="text-left small-p-margin"
                                  v-html="getTranslation(category.translationMessages,'info',currentLanguage)"
                                />
                              </v-card>
                            </v-hover>
                          </v-item>
                        </v-col>
                      </v-row>
                    </v-item-group>
<!--                    <div-->
<!--                      class="ml-auto mr-auto"-->
<!--                    >-->
<!--                      <v-btn-->
<!--                        class="d-flex ml-auto mt-3"-->
<!--                        outlined-->
<!--                        rounded-->
<!--                        @click="closeCategoryMenu"-->
<!--                      >-->
<!--                        <v-icon-->
<!--                          left-->
<!--                          color="primary"-->
<!--                        >-->
<!--                          mdi-check-->
<!--                        </v-icon>-->
<!--                        {{ $t('selectCategory') }}-->
<!--                      </v-btn>-->
<!--                    </div>-->
                  </v-container>
                  <v-divider />
                </v-container>
              </v-col>
            </v-row>
          </v-expand-transition>

          <div
            v-if="selectedKategorie == null || selectedKategorie.id === -1"
            class="d-flex text-caption ml-auto mr-auto pb-3 text--secondary"
          >
            {{ $t('salesDesignationSuggestions') }}
          </div>
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center pt-0 pb-0"
            >
              <!--              <v-text-field-->
              <!--                v-model="salesDesignation"-->
              <!--                :rules="salesDesignationRules"-->
              <!--                outlined-->
              <!--                clearable-->
              <!--                rounded-->
              <!--                class="rounded-lg"-->
              <!--                label="Sales Designation"-->
              <!--                value=""-->
              <!--                @change="changeForm"-->
              <!--              />-->
              <v-combobox
                v-model="salesDesignation"
                :items="categorySalesDesignations"
                :rules="salesDesignationRules"
                :disabled="selectedKategorie == null || selectedKategorie.id === -1"
                :label="'• ' + $t('salesDesignation')"
                hide-no-data
                style="max-width: 100% !important;"
                outlined
                clearable
                class="ma-0 pa-0 rounded-lg"
                @change="changeForm"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center pt-0 pb-0"
            >
              <!--              <v-text-field-->
              <!--                v-model="designationOfOrigin"-->
              <!--                :rules="designationOfOriginRules"-->
              <!--                outlined-->
              <!--                clearable-->
              <!--                rounded-->
              <!--                class="rounded-lg"-->
              <!--                label="Designation of Origin"-->
              <!--                value=""-->
              <!--                @change="changeForm"-->
              <!--              />-->
              <v-combobox
                v-model="designationOfOrigin"
                :rules="designationOfOriginRules"
                :items="allDesignationOfOrigin"
                :label="'• ' + $t('designationOfOrigin')"
                hide-no-data
                style="max-width: 100% !important;"
                outlined
                clearable
                class="ma-0 pa-0 rounded-lg"
                @change="changeForm"
              />
            </v-col>
          </v-row>
          <v-row v-if="varieties != null && varieties !== ''">
            <div
              class="d-flex text-caption ml-auto mr-auto pb-0 text--secondary"
            >
              {{ $t('varietiesNoLonger') }}
            </div>
            <v-col
              cols="12"
              class="d-flex justify-center pt-0 pb-0"
            >
              <v-textarea
                v-model="varieties"
                :rules="varietiesRules"
                readonly
                outlined
                rounded
                auto-grow
                rows="1"
                class="rounded-lg"
                label="Varieties"
                value=""
                @change="changeForm"
              >
                <!--                <template #append class="ma-0">-->
                <!--                  <v-btn-->
                <!--                    ref="varietiesRef"-->
                <!--                    class="ma-0"-->
                <!--                    style="transform:translateY(-6px)"-->
                <!--                    text-->
                <!--                    icon-->
                <!--                    @click="copyText(varieties)"-->
                <!--                  >-->
                <!--                    <v-icon>mdi-content-copy</v-icon>-->
                <!--                  </v-btn>-->
                <!--                </template>-->
              </v-textarea>
            </v-col>
          </v-row>

          <v-row
            class="d-flex justify-center ml-auto mr-auto"
          >
            <v-col
              cols="12"
              md="7"
              class="d-flex justify-center pt-0 pb-0"
            >
              <v-autocomplete
                v-model="selectedCountry"
                :rules="countryRules"
                :items="allCountries"
                :label="'• ' + $t('producedCountry')"
                style="max-width: 400px !important;"
                outlined
                clearable
                return-object
                item-text="name"
                item-value="code"
                class="ma-0 pa-0 rounded-lg"
                @change="changeForm"
              >
                <template
                  #item="{item}"
                  class="ma-0"
                >
                  <country-flag
                    :country="(item.flag_code||item.flagCode) || item.code.split('-')[0]"
                    size="normal"
                  />
                  {{ item.name }}
                </template>
                <template #selection="{item}">
                  <country-flag
                    :country="(item.flag_code||item.flagCode) || item.code.split('-')[0]"
                    size="normal"
                  />
                  {{ item.name }}
                </template>
              </v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              md="5"
              class="d-flex justify-center pt-0 pb-0"
            >
              <v-text-field
                v-model="alcohol"
                :rules="alcoholRules"
                outlined
                clearable
                rounded
                type="number"
                step="0.1"
                class="right-input rounded-lg"
                :label="'• ' + $t('alcoholAbv')"
                value=""
                style="max-width: 216px; min-width: 216px;"
                suffix="% vol."
                @change="changeForm"
              />
            </v-col>
          </v-row>

          <v-row
            class="pa-0 justify-start d-flex"
            style="max-width: 300px !important;"
          >
            <v-card-title class="pa-0 pl-4 pb-3 text--secondary d-flex align-start">
              <span class="info-label-text">
                • {{ $t('sugar') }}
              </span>
              <!--              <v-icon class="ml-2">-->
              <!--                mdi-help-circle-outline-->
              <!--              </v-icon>-->
            </v-card-title>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              xs="7"
              sm="7"
              md="7"
              class="d-flex pt-0 pb-0 justify-center"
            >
              <div class="d-flex flex-column justify-center">
                <v-card-title class="pa-0 pl-4 pb-0 text--secondary d-flex text-left align-start pr-3">
                  • {{ $t('descriptionResidualSugar') }}
                </v-card-title>
                <v-radio-group
                  v-model="residualSugarDescription"
                  :rules="residualSugarDescriptionRules"
                  class="mt-3 ml-auto mr-auto"
                  column
                  @change="changeForm"
                >
                  <v-radio
                    label="Brut <15 g/L"
                    value="Brut <15 g/L"
                  />
                  <v-radio
                    label="Dry, sec 15-35 g/L"
                    value="Dry, sec 15-35 g/L"
                  />
                  <v-radio
                    label="Semi dry, demi-sec 33-50 g/L"
                    value="Semi dry, demi-sec 33-50 g/L"
                  />
                  <v-radio
                    label="Sweet, doux >50 g/L"
                    value="Sweet, doux >50 g/L"
                  />
                </v-radio-group>
              </div>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="5"
              md="5"
              class="d-flex justify-center pt-0 pb-0"
            >
              <v-text-field
                v-model="residualSugar"
                :rules="residualSugarRules"
                outlined
                clearable
                rounded
                type="number"
                class="rounded-lg"
                :label="$t('residualSugar')"
                style="max-width: 300px;"
                suffix="g/L"
                value=""
                @change="changeForm"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              xs="6"
              sm="4"
              md="4"
            >
              <v-row
                class="pa-0 justify-start d-flex"
                style="max-width: 300px !important;"
              >
                <v-card-title class="pa-0 pl-4 pb-3 text--secondary d-flex align-start">
                  <span class="info-label-text">
                    • {{ $t('filtrationClarity') }}
                  </span>
                  <!--              <v-icon class="ml-2">-->
                  <!--                mdi-help-circle-outline-->
                  <!--              </v-icon>-->
                </v-card-title>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-sm-start justify-center pt-0 pb-0"
                >
                  <!--Filtration column declared & styled below-->
                  <v-radio-group
                    v-model="filtrationClarity"
                    :rules="filtrationClarityRules"
                    class="mt-0 d-flex flex-column align-start"
                    column
                    @change="changeForm"
                  >
                    <v-radio
                      :label="$t('filteredClear')"
                      value="Filtered / clear"
                    />
                    <v-radio
                      :label="$t('filteredHazy')"
                      value="Unfiltered / hazy"
                    />
                    <v-radio
                      :label="$t('unfilteredCloudy')"
                      value="Unfiltered / cloudy"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              xs="6"
              sm="4"
              md="4"
            >
              <v-row
                class="pa-0 justify-start d-flex"
                style="max-width: 300px !important;"
              >
                <v-card-title class="pa-0 pl-4 pb-4 text--secondary d-flex align-start">
                  <span class="info-label-text">
                    • {{ $t('pressure') }}
                  </span>
                  <!--                  <v-icon class="ml-2">-->
                  <!--                    mdi-help-circle-outline-->
                  <!--                  </v-icon>-->
                </v-card-title>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-sm-start justify-center pt-0 pb-0"
                >
                  <v-radio-group
                    v-model="pressure"
                    :rules="pressureRules"
                    class="mt-0 d-flex flex-column align-start"
                    column
                    @change="changeForm"
                  >
                    <v-radio
                      :label="$t('pressureStill')"
                      value="still – lighly carbonated <1 bar"
                    />
                    <v-radio
                      label="1-2,5 bar"
                      value="1-2,5 bar"
                    />
                    <v-radio
                      label=">2,5 bar"
                      value=">2,5 bar"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              xs="6"
              sm="4"
              md="4"
            >
              <v-row
                class="pa-0 justify-start d-flex "
                style="max-width: 300px !important;"
              >
                <v-card-title class="pa-0 pl-4 pb-3 text--secondary d-flex align-start">
                  <span class="info-label-text">
                    • {{ $t('method') }}
                  </span>
                  <!--                  <v-icon class="ml-2">-->
                  <!--                    mdi-help-circle-outline-->
                  <!--                  </v-icon>-->
                </v-card-title>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-sm-start justify-center pt-0 pb-0"
                >
                  <v-radio-group
                    v-model="method"
                    :rules="methodRules"
                    class="mt-0 d-flex flex-column align-start"
                    column
                    @change="changeForm"
                  >
                    <v-radio
                      :label="$t('methodStill')"
                      value="No added CO2"
                    />
                    <v-radio
                      :label="$t('methodAdded')"
                      value="added CO2"
                    />
                    <v-radio
                      :label="$t('methodPetNat')"
                      value="Keeved, PetNat, Méthode Ascentrale"
                    />
                    <v-radio
                      :label="$t('methodCharmat')"
                      value="Méthode Charmat"
                    />
                    <v-radio
                      :label="$t('methodFermented')"
                      value="Tradional bottle fermented"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row
            ref="bottleContent"
            class="pt-3"
          >
            <v-col
              cols="12"
              xs="12"
              sm="4"
              md="4"
              class="d-flex justify-center pt-0 pb-0"
            >
              <v-text-field
                v-model="bottleContent"
                :rules="bottleContentRules"
                outlined
                clearable
                rounded
                type="number"
                class="rounded-lg"
                :label="$t('bottleContent')"
                value=""
                suffix="liter"
                @change="changeForm"
              />
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="4"
              md="4"
              class="d-flex justify-center pt-0 pb-0"
            >
              <v-text-field
                v-model="price"
                :rules="priceRules"
                outlined
                clearable
                rounded
                class="rounded-lg"
                :label="$t('retailPrice')"
                value=""
                @change="changeForm"
              />
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="4"
              md="4"
              class="d-flex justify-center pt-0 pb-0"
            >
              <v-text-field
                v-model="vintage"
                :rules="vintageRules"
                outlined
                clearable
                rounded
                class="rounded-lg"
                :label="$t('vintage')"
                value=""
                @change="changeForm"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pa-0">
              <v-card-title class="pa-0 pl-4 text--secondary info-label-text">
                • {{ $t('shortDescriptionProfile') }}
                <!--                <v-icon class="ml-2">-->
                <!--                  mdi-help-circle-outline-->
                <!--                </v-icon>-->
              </v-card-title>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="pl-4 pr-4"
            >
              <v-card
                outlined
              >
                <ProducerProfileEditor
                  v-model="profile"
                  :readonly="false"
                  :placeholder="$t('enterShortDescriptionProfile')"
                  @input="(changedInput) => {profile = changedInput; changeForm(changedInput);}"
                />
              </v-card>
              <v-input
                v-model="profile"
                :rules="profileRules"
                class="pl-2"
              />
            </v-col>
          </v-row>

          <v-row class="d-flex">
            <v-col class="d-flex pa-0">
              <v-card-title class="pa-0 pl-4 text--secondary info-label-text">
                {{ $t('descriptionTranslations') }}
                <!--                <v-icon class="ml-2">-->
                <!--                  mdi-help-circle-outline-->
                <!--                </v-icon>-->
              </v-card-title>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              :md="profileSplit ? 6 : 12"
              class="pl-4 pr-4"
            >
              <v-card
                outlined
              >
                <v-card-title
                  class="pa-3 pb-1 mb-1 text--secondary"
                  style="font-size: 16px !important;"
                >
                  <CountrySelect
                    style="max-width: 200px;"
                    :countries="availableTranslations"
                    @change="changedCountry('profileTranslationOneCode', ...arguments)"
                  />
                  <v-spacer />
                  <v-tooltip
                    v-if="!profileSplit && availableTranslations.length > 1"
                    top
                  >
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        elevation="0"
                        @click="profileSplit = !profileSplit"
                        v-on="on"
                      >
                        <v-icon>mdi-format-columns</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('translationSplitView') }}</span>
                  </v-tooltip>
                </v-card-title>
                <!--                <v-divider />-->
                <ProducerProfileEditor
                  v-model="profileTranslationContentOne"
                  :readonly="false"
                  :placeholder="$t('enterTranslation', {translationName: translationOneName})"
                  @input="(changedInput) => {changeForm(changedInput);}"
                />
              </v-card>
            </v-col>

            <v-col
              v-if="profileSplit"
              cols="12"
              :md="profileSplit ? 6 : 12"
              class="d-flex pr-4 pl-4"
            >
              <v-card
                v-show="profileSplit"
                outlined
                style="width: 100%;"
              >
                <v-card-title
                  class="pa-3 pb-1 mb-1 text--secondary"
                  style="font-size: 16px !important;"
                >
                  <CountrySelect
                    style="max-width: 200px;"
                    :countries="availableTranslations"
                    :selected-idx="availableTranslations.findIndex(l => l.code === profileTranslationTwoCode)"
                    @change="changedCountry('profileTranslationTwoCode', ...arguments)"
                  />
                  <v-spacer />
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        elevation="0"
                        @click="profileSplit = !profileSplit"
                        v-on="on"
                      >
                        <v-icon>mdi-close-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('closeTranslationSplitView') }}</span>
                  </v-tooltip>
                </v-card-title>
                <!--                <v-divider />-->
                <ProducerProfileEditor
                  v-model="profileTranslationContentTwo"
                  :readonly="false"
                  :placeholder="$t('enterTranslation', {translationName: translationTwoName})"
                  @input="(changedInput) => {changeForm(changedInput);}"
                />
              </v-card>
            </v-col>

            <v-row
              class="pl-4 pr-4"
            >
              <v-col
                align-self="center"
              >
                <v-card-title class="pa-0 pb-3 pl-4 text--secondary info-label-text">
                  {{ $t('editCiderForm.images') }}
                  <!--                  <v-icon class="ml-2">-->
                  <!--                    mdi-help-circle-outline-->
                  <!--                  </v-icon>-->
                </v-card-title>
                <v-card
                  outlined
                  class="ml-auto mr-auto pl-4 pr-4"
                >
                  <ProducerUploadImage
                    :existing-image-ids="imageIdsCopy"
                    class="ml-3 mr-3"
                    style="width: 100% !important;"
                    @change="changeUploadedImages"
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-row>

          <v-row v-if="!noPreview">
            <v-col class="d-flex justify-end pl-4 pr-4">
              <v-btn
                v-if="!showPreview"
                ref="previewButton"
                :color="showPreview ? 'secondary' : 'primary'"
                elevation="0"
                class=""
                :disabled="!valid"
                @click="validateAndShowPreview"
              >
                {{ $t('editCiderForm.previews') }}
              </v-btn>
              <v-btn
                v-if="showPreview"
                :color="showPreview ? 'secondary' : 'primary'"
                elevation="0"
                class=""
                @click="closePreview"
              >
                {{ $t('editCiderForm.edit') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row
            v-if="confirmCheckbox"
            class="pt-2"
          >
            <v-col
              cols="12"
              xs="12"
              sm="9"
              md="9"
              class="d-flex justify-start pl-4 pr-4 pt-0 align-center"
            >
              <v-checkbox
                v-model="confirmData"
                :rules="[v => !!v || $t('dataHasToBeConfirmed')]"
                :label="$t('editCiderForm.confirm')"
                required
              >
                <template #label>
                  <div>
                    {{ $t('editCiderForm.confirmCorrectness') }}
                  </div>
                </template>
              </v-checkbox>
            </v-col>

            <v-col
              cols="12"
              xs="12"
              sm="3"
              md="3"
              class="d-flex justify-end pl-4 pr-4 pt-0 align-center"
            >
              <v-btn
                color="primary"
                elevation="0"
                :disabled="!valid"
                :loading="confirmButtonLoading"
                class="mb-3"
                @click="validateAndUpload"
              >
                {{ $t('editCiderForm.confirm') }}
              </v-btn>
            </v-col>
          </v-row>
          <div
            v-if="valid"
            class="pt-0 pb-3 align-start justify-end d-flex"
          >
            <v-icon
              left
              small
              style="opacity: 0.6; transform: translateY(2px);"
              class="mr-1"
            >
              mdi-information-outline
            </v-icon>
            <span
              class="text-body-2 text--secondary text-caption text-left align-start justify-start"
              v-html="$t('confirmProductDataSaved')"
            />
          </div>
          <div
            v-if="!valid"
            class="d-flex justify-end align-start"
          >
            <v-icon
              left
              small
              color="error"
              style="transform: translateY(2px);"
              class="mr-1"
            >
              mdi-information-outline
            </v-icon>
            <span
              class="text-caption pb-3 pr-1"
              style="color: var(--v-error-base) !important;"
            >
              {{ $t('editCiderForm.requiredDataMissing') }}
            </span>
          </div>
        </v-form>
      </v-col>

      <v-col
        v-show="showPreview && !noPreview"
        ref="previewLabel"
        cols="12"
        md="4"
        class="pa-0"
      >
        <div
          class="d-flex flex-column"
          :class="{'card-sticky': !isSmallScreen}"
        >
          <span
            v-if="hideEditForm"
            class="d-flex align-center justify-center text-h4 text-left text--secondary mb-4"
          >
            <v-icon>
              mdi-check
            </v-icon>
            {{ $t('editCiderForm.productCreated') }}
          </span>
          <span
            v-show="!hideEditForm"
            class="d-flex align-center justify-center text-h4 text-left text--secondary mb-4"
          >
            {{ $t('editCiderForm.ciderPreview') }}
          </span>

          <div
            style="max-width: 95vw;"
            class="d-flex justify-center pl-1"
          >
            <CiderCard
              ref="previewPanel"
              :key="infosChanged"
              :hide-years="false"
              :show-edit="false"
              style="max-width: min(100%, 600px); min-width: min(100%, 600px);"
              :cider="ciderData"
              full-content
              image-urls-provided
              disable-revisions
            />
          </div>
          <div
            v-if="!hideEditForm"
            class="d-flex justify-center"
          >
            <v-btn
              elevation="0"
              class="mt-4"
              width="175"
              :loading="confirmButtonLoading"
              color="primary"
              :disabled="disableAddButton"
              style="text-transform: unset !important;"
              @click="uploadCiderData"
            >
              {{ $t(tConfirmButtonText) }}
            </v-btn>
          </div>
          <div
            v-if="!hideEditForm && editMode"
            class="pt-6 pl-8 pr-8 align-start justify-start d-flex"
          >
            <v-icon
              left
              small
              style="opacity: 0.6;"
            >
              mdi-information-outline
            </v-icon>
            <span
              class="text-body-2 text--secondary text-left align-start justify-start d-flex"
              v-html="$t('revisionInfoText')"
            />
          </div>
        </div>

        <v-btn
          v-if="hideEditForm"
          text
          rounded
          outlined
          class="ml-2 mt-8 mb-8"
          @click="$router.push('/producer/home')"
        >
          {{ $t('editCiderForm.showAllCiders') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="showSuccessSnackbar"
      color="secondary_card"
      rounded="xl"
      :multi-line="false"
      bottom
      vertical
      style="opacity: 0.95;"
    >
      <v-card-title class="d-flex pt-2 pb-2 text-center justify-center">
        <v-icon left>
          mdi-check-circle
        </v-icon>
        {{ editMode ? $t('successEdit') : $t('successAdd') }}
      </v-card-title>
      <template #action="{ attrs }">
        <v-btn
          color="white"
          style="opacity: 0.7;"
          text
          rounded
          v-bind="attrs"
          @click="showSuccessSnackbar = false"
        >
          {{ $t('close') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="white"
          text
          rounded
          v-bind="attrs"
          class="ml-2"
          @click="$router.push(backRoute)"
        >
          {{ $t(backText) }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="showErrorSnackbar"
      color="error"
      rounded="xl"
      :multi-line="false"
      bottom
      vertical
      style="opacity: 0.95;"
    >
      <v-card-title class="d-flex pt-2 pb-2 text-center justify-center">
        <v-icon left>
          mdi-alert-circle
        </v-icon>
        {{ editMode ? $t('failedEdit') : $t('failedAdd') }}
      </v-card-title>
      <template #action="{ attrs }">
        <v-spacer />
        <v-btn
          color="white"
          text
          rounded
          v-bind="attrs"
          class="ml-auto ml-4"
          @click="reloadPage"
        >
          {{ $t('editCiderForm.reloadPage') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import {CProducerCiderResults} from "@/types/producerCider";
import {apiTyped} from "@/api";
import CountryFlag from "vue-country-flag";
import ProducerProfileEditor from "@/components/producer/producer_profile_editor";
import CountrySelect from "@/components/producer/country_select";
import Vue from "vue";
import CiderCard from "@/components/cider_card";
import {TranslationMessages} from "@/types/translationMessages";
import {Country} from "@/types/country";
import ProducerUploadImage from "@/components/producer/producer_upload_image";
import logos_mixin from "@/components/mixins/logos_mixin";
import Dialog_select_category from "@/components/producer/dialog_select_category";
import user_mixin from "@/components/mixins/user_mixin";
import i18n from '@/i18n'
import Producer from "@/layouts/producer";
import translation_mixin from "@/components/mixins/translation_mixin";

export default {
  name: "ProducerEditCiderForm",
  components: {
    ProducerUploadImage,
    CiderCard,
    CountrySelect,
    ProducerProfileEditor,
    CountryFlag
  },
  mixins: [logos_mixin, user_mixin, translation_mixin],
  props: {
    cider: {type: [Object, CProducerCiderResults, null], required: false, default: null},
    desiredTranslations: {type: Array, required: false, default: null},
    allCategories: {type: Array, required: false, default: null},
    checkRequiredFields: {type: Boolean, required: false, default: true},
    countries: {type: Array, required: false, default: null},
    noPreview: {type: Boolean, required: false, default: false},
    confirmCheckbox: {type: Boolean, required: false, default: false},
    confirmCheckboxState: {type: Boolean, required: false, default: false},
    categoryRequired: {type: Boolean, required: false, default: false},  // TODO
    backRoute: {type: String, required: false, default: '/producer/home'},
    backText: {type: String, required: false, default: 'editCiderForm.showAllCiders'},
  },
  data: () => ({
    loaded: false,
    disableAddButton: false,
    ciderImages: [],
    infosChanged: -10,
    changeCounter: -1,  // -1 because of images
    valid: true,
    name: '',
    categorySalesDesignations: [],
    salesDesignation: "",
    allDesignationOfOrigin: [],
    designationOfOrigin: "",
    varieties: "",
    varietiesRules: [],
    selectedKategorie: null,
    kategorien: [],
    selectedCountry: null,
    allCountries: [],
    alcohol: '',
    residualSugar: "",
    residualSugarDescription: "",
    filtrationClarity: "",
    pressure: "",
    method: "",
    bottleContent: "",
    price: "",
    vintage: "",
    vintageRules: [],
    profile: '',
    profileTranslations: {},
    profileSplit: false,
    availableTranslations: null,
    profileTranslationOneCode: '',
    profileTranslationTwoCode: '',
    uploadedImages: [],
    showPreview: false,
    imageIdsCopy: [],
    existingImageIds: [],
    imageUrls: [],
    ciderId: -1,
    toggleCategoryMenu: false,
    confirmButtonLoading: false,
    confirmButtonLoadingText: "confirmButtonLoadingUpload",
    showSuccessSnackbar: false,
    showErrorSnackbar: false,
    confirmData: false,
    ciderNameKey: 0,
    hideEditForm: false,
  }),
  i18n: {
    messages: {
      de: {
        'confirmProductDataSaved': 'Durch Bestätigen werden die Produktdaten gespeichert',
        'dataHasToBeConfirmed': 'Daten müssen bestätigt werden',
        'varietiesNoLonger': 'Das Feld Varieties wird nicht mehr genutzt. Bitte kopieren Sie diese Informationen in das Profil',
        'salesDesignationSuggestions': 'Kategorie auswählen, um Vorschläge für die Verkehrsbezeichnung / Sales Designation zu erhalten',
        'requiredFields': 'Pflichtfelder sind mit markiert mit',
        'successAdd': 'Cider erstellt',
        'successEdit': 'Cider Informationen aktualisiert',
        'failedAdd': 'Fehler beim Erstellen',
        'failedEdit': 'Fehler beim Aktualisieren',
        'revisionInfoText': 'Durch das Aktualisieren der Informationen wird kein neues Tasting erstellt. ' +
          'Die aktuellen Informationen werden von den eingegebenen überschrieben.',
        'confirmButtonAdd': 'Cider erstellen',
        'confirmButtonEdit': 'Cider aktualisieren',
        'confirmButtonLoadingUpload': 'Lade Bilder hoch ...',
        'confirmButtonLoadingData': 'Aktualisiere Daten ...',
      },
      en: {
        'confirmProductDataSaved': 'The product data is saved by confirming',
        'dataHasToBeConfirmed': 'Data has to be confirmed',
        'varietiesNoLonger': 'Varieties is no longer available, please move this information to the profile.',
        'salesDesignationSuggestions': 'Select a category to receive suggestions for Sales Designation',
        'requiredFields': 'Required Fields are marked with a',
        'successAdd': 'Cider created',
        'successEdit': 'Cider information updated',
        'failedAdd': 'Failed to create Product',
        'failedEdit': 'Failed to update Product',
        'revisionInfoText': 'Updating the information does not create a new tasting. ' +
          'The current information will be overwritten by the information entered.',
        'confirmButtonAdd': 'Add Product',
        'confirmButtonEdit': 'Update Product',
        'confirmButtonLoadingUpload': 'Uploading Images ...',
        'confirmButtonLoadingData': 'Updating data ...',
      }
    }
  },
  computed: {
    appBarHeight() {
      return this.isSmallScreen ? 56 + "px" : 63 + "px";
    },
    nameRules() {
      return [
        v => !!v || this.$t('editCiderForm.nameIsRequired'),
      ]
    },
    salesDesignationRules() {
      if (!this.checkRequiredFields) return [];
      return [
        v => !!v || this.$t('editCiderForm.salesDesignationIsRequired'),
      ]
    },
    designationOfOriginRules() {
      if (!this.checkRequiredFields) return [];
      return [
        v => !!v || this.$t('editCiderForm.designationOfOriginIsRequired'),
      ]
    },
    selectedKategorieRules() {
      if (!this.checkRequiredFields) return [];
      return [
        v=> (v != null && v.id !== -1) || this.$t('editCiderForm.categoryIsRequired'),
      ]
    },
    countryRules() {
      if (!this.checkRequiredFields) return [];
      return [
        v => !!v || this.$t('editCiderForm.countryIsRequired'),
      ]
    },
    alcoholRules() {
      if (!this.checkRequiredFields) return [];
      return [
        // v => v >= 0 || 'Alcohol must be > 0',
        v => !!v || this.$t('editCiderForm.alcoholIsRequired'),
      ]
    },
    residualSugarRules() {
      if (!this.checkRequiredFields) return [];
      return [
        // v => !!v || this.$t('editCiderForm.residualSugarIsRequired'),
      ]
    },
    residualSugarDescriptionRules() {
      if (!this.checkRequiredFields) return [];
      return [
        v => !!v || this.$t('editCiderForm.sugarDescriptionIsRequired'),
      ]
    },
    filtrationClarityRules() {
      if (!this.checkRequiredFields) return [];
      return [
        v => !!v || this.$t('editCiderForm.filtrationIsRequired'),
      ]
    },
    pressureRules() {
      if (!this.checkRequiredFields) return [];
      return [
        v => !!v || this.$t('editCiderForm.pressureIsRequired'),
      ]
    },
    methodRules() {
      if (!this.checkRequiredFields) return [];
      return  [
        v => !!v || this.$t('editCiderForm.methodIsRequired'),
      ]
    },
    bottleContentRules() {
      if (!this.checkRequiredFields) return [];
      return  [
        // v => !!v || 'The Bottle Content is required',
      ]
    },
    priceRules() {
      if (!this.checkRequiredFields) return [];
      return [
        // v => !!v || 'Price is required',
      ]
    },
    profileRules() {
      if (!this.checkRequiredFields) return [];
      return  [
        v => !!v || this.$t('editCiderForm.descriptionIsRequired'),
        v => v !== "<p></p>" || this.$t('editCiderForm.descriptionIsRequired'),
      ]
    },
    tConfirmButtonText() {
      if (this.editMode) {
        if (this.confirmButtonLoading) return this.confirmButtonLoadingText;
        else return 'confirmButtonEdit'
      } else {
        if (this.confirmButtonLoading) return this.confirmButtonLoadingText;
        else return 'confirmButtonAdd'
      }
    },
    translationOneName() {
      const o = this.availableTranslations.find(t => t.code === this.profileTranslationOneCode);
      if (o == null) return "";
      return o.name;
    },
    translationTwoName() {
      const o = this.availableTranslations.find(t => t.code === this.profileTranslationTwoCode);
      if (o == null) return "";
      return o.name;
    },
    editMode() {
      return this.cider !== null;
    },
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
        case 'sm': return true;
        default: return false
      }
    },
    ciderInfos() {
      return {
        name: this.name || '',
        profil: this.profile || '',
        profilTranslations: this.profileTranslations || null,
        alkohol: this.alcohol || undefined,
        land: this.selectedCountry || undefined,
        kategorieId: this.selectedKategorie ? this.selectedKategorie.id : -1,
        kategorie: this.selectedKategorie ? this.selectedKategorie.name : '',
        kategorieTranslations: this.selectedKategorie != null ? this.selectedKategorie.translationMessages : null,
        images: this.ciderImages.map(i => i.url),

        vintage: this.vintage || "",
        salesDesignation: this.salesDesignation || "",
        designationOfOrigin: this.designationOfOrigin || "",
        varieties: this.varieties || "",
        residualSugar: this.residualSugar || 0,
        residualSugarDescription: this.residualSugarDescription || "",
        filtrationClarity: this.filtrationClarity || "",
        pressure: this.pressure || "",
        method: this.method || "",
        bottleContent: this.bottleContent || 0,
        price: this.price || "",
      };
    },
    ciderData() {
      return {
        // ...this.cider,
        id: this.ciderId,
        ciderInfos: {...this.ciderInfos},
        badgeIds: this.editMode ? this.cider.badgeIds : undefined,
        flightsCiders: this.editMode ? this.cider.flightsCiders : [],
        submitted: this.editMode ? this.cider.submitted : [],
        revisions: this.editMode ? this.cider.revisions : [],
      }
    },
    profileTranslationContentOne: {
      get() {
        return this.getProfile(this.profileTranslationOneCode);
      },
      set(val) {
        this.setProfile(this.profileTranslationOneCode, val)
      }
    },
    profileTranslationContentTwo: {
      get() {
        if (this.profileTranslationTwoCode === this.profileTranslationContentOne) {
          this.setProfile(this.profileTranslationTwoCode, val)
        }
        return this.getProfile(this.profileTranslationTwoCode);
      },
      set(val) {
        if (this.profileTranslationTwoCode === this.profileTranslationContentOne) {
          this.setProfile(this.profileTranslationOneCode, val)
        }
        this.setProfile(this.profileTranslationTwoCode, val)
      }
    }
  },
  watch: {
    loaded: function (val) { this.$emit('loaded', val); },
    name: function () { this.makeCider(); },
    profile: function () { this.makeCider(); },
    profilTranslations: function () { this.makeCider(); },
    profileTranslationContentOne: function () { this.makeCider(); },
    profileTranslationContentTwo: function () { this.makeCider(); },
    alcohol: function () { this.makeCider(); },
    selectedCountry: function () { this.makeCider(); },
    selectedKategorie: async function (v, o) {
      this.makeCider();
      if (this.loaded) this.salesDesignation = "";
      if (this.cider != null
          && this.cider.ciderInfos.salesDesignation !== ""
          && this.cider.ciderInfos.kategorieId === this.selectedKategorie.id) {
        this.salesDesignation = this.cider.ciderInfos.salesDesignation;
      }
      await this.getCategorySalesDesignations();
    },
    // confirmData: function (val) {
    //   this.$emit('confirm', val);
    // },
    valid: function (val) {
      // if (!val && this.confirmData) this.confirmData = false;
      if (!val) this.$emit('confirm', val);
    },
    ciderImages: function (val) {
      this.changeForm(val);
    }
  },
  async created() {
    // Set checkbox data if required and or passed
    if (!this.confirmCheckbox) this.confirmData = true;
    else this.confirmData = this.confirmCheckboxState;

    this.availableTranslations = this.desiredTranslations || this.$store.state.languages;  // if not provided assume languages of the app
    this.profileTranslationOneCode = this.availableTranslations[0].code;
    if (this.availableTranslations.length > 1) this.profileTranslationTwoCode = this.availableTranslations[1].code;
    if (this.allCategories == null) {
      this.kategorien = await apiTyped.getActiveKategorien() || [];
    } else {
      this.kategorien = [...this.allCategories];
    }
    this.kategorien.push({
      id: -1,
      info: '',
      name: '',
      translationMessages: {
        'de-DE': {
          name: 'Keine Kategorie ausgewählt',
          info: 'Bitte eine Kategorie auswählen',
        },
        'en-US': {
          name: 'No Category selected',
          info: 'Please choose a Category',
        }
      }
    })
    this.selectedKategorie = this.kategorien[this.kategorien.length - 1];

    if (this.countries == null || this.countries.length === 0) {
      this.allCountries = await apiTyped.getAllCountries() || [];
    } else {
      this.allCountries = [...this.countries];
    }

    if (this.cider !== null) {
      this.name = this.cider.ciderInfos.name;
      this.selectedKategorie = this.kategorien.find(
        k => k.id === this.cider.ciderInfos.kategorieId) || this.kategorien[this.kategorien.length - 1];
      this.alcohol = this.cider.ciderInfos.alkohol;
      if (this.cider.ciderInfos.land == null) this.selectedCountry = undefined;
      else this.selectedCountry = this.allCountries.find(c => c.code === this.cider.ciderInfos.land.code);
      this.profile = this.cider.ciderInfos.profil;
      this.profileTranslations = this.cider.ciderInfos.profilTranslations || {};
      // important; set existing image ids on first load:
      this.imageIdsCopy = [...this.cider.ciderInfos.images];
      this.existingImageIds = [...this.cider.ciderInfos.images];

      this.salesDesignation = this.cider.ciderInfos.salesDesignation;
      this.designationOfOrigin = this.cider.ciderInfos.designationOfOrigin;
      this.varieties = this.cider.ciderInfos.varieties;
      this.residualSugar = this.cider.ciderInfos.residualSugar;
      this.residualSugarDescription = this.cider.ciderInfos.residualSugarDescription;
      this.filtrationClarity = this.cider.ciderInfos.filtrationClarity;
      this.pressure = this.cider.ciderInfos.pressure;
      this.method = this.cider.ciderInfos.method;
      this.bottleContent = this.cider.ciderInfos.bottleContent;
      this.price = this.cider.ciderInfos.price;
      this.vintage = this.cider.ciderInfos.vintage;
    }
    if (this.profileTranslations === {}) {
      // add missing lang codes so that they are definitely in the database
      for (const lang in this.availableTranslations) {
        if (!this.profileTranslations.hasOwnProperty(lang.code)) {
          this.profileTranslations[lang.code] = '';
        }
      }
    }

    this.allDesignationOfOrigin = await apiTyped.producerGetDesignationOfOrigins();

    this.changeCounter = -1;
    this.loaded = true;
  },
  methods: {
    selectKategorie(toggle) {
      toggle();
      this.openCategoryMenu();
    },
    copyText(text) {
      const storage = document.createElement('textarea');
      storage.value = text;
      this.$refs.varietiesRef.appendChild(storage);
      storage.select();
      storage.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.$refs.reference.removeChild(storage);
    },
    resizedWindow(v) {
      if (v === 'submit') {
        window.setTimeout(() => {
          this.ciderNameKey += 1;
        }, 500);
      }
    },
    changeForm(v) {
      this.changeCounter += 1;
      if (this.confirmData && this.changeCounter > 0) this.confirmData = false;
      this.$emit('changeCounter', this.changeCounter);
    },
    reloadPage() {
      window.location.reload(true)
    },
    async getCategorySalesDesignations() {
      if (this.selectedKategorie == null) return;
      const data = await apiTyped.producerGetSalesDesignations(this.selectedKategorie.id);
      if (data != null) this.categorySalesDesignations = data;
    },
    async uploadCiderData(showDialog = true) {
      this.confirmButtonLoading = true;
      const toUpload = this.ciderImages.filter(ciderImage => ciderImage.existing === false);
      if (toUpload.length > 0) {
        this.confirmButtonLoadingText = 'confirmButtonLoadingUpload';
        const uploadedImageIds = await apiTyped.producerUploadCiderImages(
          toUpload.map(ciderImage => ciderImage.file), this.$store.state.producerStore.ciderImagesFolderId);
          // add new directus id to ciderImages for correct order:
          uploadedImageIds.forEach((uploadedImageId, index) => {
            this.ciderImages.find(ciderImage => ciderImage.url === toUpload[index].url).directusId = uploadedImageId;
        });
      }
      this.confirmButtonLoadingText = 'confirmButtonLoadingData';
      let success = false;
      const myCiderInfos = this.ciderInfos;
      myCiderInfos.images = this.ciderImages.map(ciderImage => ciderImage.directusId);
      this.$emit('updateInfos', myCiderInfos);
      // console.log("New Cider Infos", myCiderInfos);
      // try {
        if (this.editMode) {
          // console.log("Updating Cider", this.ciderId);
          success = await apiTyped.producerUpdateCider(
            this.cider.id, myCiderInfos, this.$store.state.producerStore.producerId)
        } else {
          // console.log("Adding new Cider");
          success = await apiTyped.producerAddCider(myCiderInfos, this.$store.state.producerStore.producerId);
          if (success != null) {
            this.hideEditForm = true;
            this.disableAddButton = true;
          }
        }
      // } catch (e) {
      //   console.error("Error Creating or Updating cider with infos", myCiderInfos, e)
      // }
      if (showDialog) {
        if (success) this.showSuccessSnackbar = true;
        else this.showErrorSnackbar = true;
      }
      this.changeCounter = 0;
      this.$emit('changeCounter', 0);
      this.confirmButtonLoading = false;
    },
    closePreview() {
      this.showPreview = !this.showPreview;
      this.$vuetify.goTo(0, {duration: 250});
    },
    openCategoryMenu() {
      this.toggleCategoryMenu = !this.toggleCategoryMenu;
      if (!this.toggleCategoryMenu) {
        this.$nextTick(() => {
          window.setTimeout(() => {
            this.$vuetify.goTo(this.$refs.categoryMenu, {offset: 600, duration: 250})
          }, 100);
        });
      }
    },
    closeCategoryMenu() {
      this.toggleCategoryMenu = !this.toggleCategoryMenu;
      this.$vuetify.goTo(this.$refs.categoryMenu, {offset: 300, duration: 250})
    },
    changeUploadedImages(images) {
      // this.changeForm(images)
      this.ciderImages = images;
      this.makeCider();
      this.$nextTick(() => {this.makeCider();});
    },
    makeCider() {
      this.infosChanged += 1;
      this.ciderId += 1;
    },
    async validateAndUpload () {
      await this.$refs.form.validate();
      if (this.valid) await this.uploadCiderData(false);
      this.$emit("confirm", this.valid);
    },
    async validate () {
      await this.$refs.form.validate();
      // if (this.valid) await this.uploadCiderData(false);
      this.$emit("confirm", this.valid);
    },
    async validateAndShowPreview() {
      await this.validate();
      if (!this.valid) return;
      const scrollOptions = {
        duration: 500,
        offset: 0,
        easing: 'easeInOutCubic',
      };
      if (this.isSmallScreen) {
        console.log("GOTO sma")
        this.$nextTick(() => {
          this.$vuetify.goTo(this.$refs.previewButton, {...scrollOptions, offset: 0});
        });
      } else {
        console.log("GOTO BIG")
        this.$vuetify.goTo(0, scrollOptions);
      }
      // const panel = this.$refs.previewPanel;
      // this.$vuetify.goTo(panel, {
      //   ...scrollOptions,
      //   container: this.$refs.container
      // });
      this.showPreview = !this.showPreview;
      this.makeCider();
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    getProfile(code) {
      return this.profileTranslations[code];
    },
    setProfile(code, text) {
      Vue.set(this.profileTranslations, code, text);
    },
    changedCountry(data, args){
      this[data] = args.code;
    },
  },
}
</script>

<style scoped>
.card-sticky {
  position: sticky !important;
  top: 72px;
}

.cider-name {
  font-family: 'Nunito Sans', serif !important;
  text-align: center;
  align-self: start;
  line-height: 1;

  font-size: 42px !important;
  /*min-height: 64px !important;*/
}
.centered-input >>> textarea {
  text-align: center;
  align-self: end;
  line-height: 1 !important;

  font-size: 42px !important;
  /*min-height: 64px !important;*/
}

.right-input >>> input {
  text-align: end;
}
.triangle-top-right {
  position: absolute;
  top: -30px;
  right: -30px;
  width: 60px;
  height: 60px;
  transform: rotate(45deg);
  background: var(--v-primary-base);
}
.hovered-category {
  box-shadow: inset 0 0 0 2px var(--v-text_box_border-base);
}
.selected-category {
  box-shadow: inset 0 0 0 2px var(--v-text_box_border-base);
}
.rotate-180 {
  -moz-transform:rotate(180deg);
  -webkit-transform:rotate(180deg);
  transform:rotate(180deg);
}
.with-text-break {
  white-space: nowrap !important;
  text-overflow: unset !important;
  width: 100% !important;
}
.small-p-margin > p {
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}

.info-label-text {
  text-decoration: underline var(--v-divider-base);
  text-underline-offset: 8px;
}

.blurred-background {
  -webkit-box-shadow: inset 0 0 200px #00000000;
  -moz-box-shadow: inset 0 0 200px #00000000;
  box-shadow: inset 0 0 200px #00000000;
  backdrop-filter: blur(6px) !important;
}
</style>