import Vue from 'vue';
import Vuex from 'vuex';
import {make} from 'vuex-pathify';
import {Table} from "@/types/table";
import {Flight} from "@/types/flight";
import {UserId, User} from "@/types/user";
import {Tischfunktion} from "@/types/tischfunktion";
// import store, {rootGetter} from "@/stores";

Vue.use(Vuex);


type storeStates = typeof state;
// @ts-ignore
type storeGetters = typeof getters;
// type rootStates = typeof store.state;
// type rootGetters = typeof rootGetter;

const state = {
    tables: Array<Table>(),
    currentTable: 0,
    selectedFlights: Array<Flight>(),
    showTableFunctions: false,
}

// @ts-ignore
const getters = {
    ...make.getters(state),
    getCurrentTable: (state: storeStates) => (): Table => {
        return state.tables[state.currentTable || 0];
    },
    getTableMembers: (state: storeStates, getter: storeGetters) => (table: Table | null = null): User[] => {
        if (table == null) table = getter.getCurrentTable();
        return table?.juroren || [];
    },
    // getAllTableMembers: (state: storeStates) => (): User[] => {
    //     return state.tables.flatMap((table: Table) => table.juroren || []);
    // },
    getTableMembersIds: (state: storeStates, getter: storeGetters) => (table: Table | null = null): UserId[] => {
        if (table == null) table = getter.getCurrentTable();
        return (getter.getTableMembers(table)).map((user: User) => user.id);
    },
    getUserDataForUserId: (state: storeStates, getter: storeGetters) =>
            (userId: UserId, table: Table | null = null): User | undefined => {
        if (table == null) table = getter.getCurrentTable();
        return table?.juroren.find(user => user.id === userId);
    },
    getChairFromTable: (state: storeStates, getter: storeGetters) => (table: Table | null = null): User | undefined => {
        if (table == null) table = getter.getCurrentTable();
        if (table == null) return undefined;
        if (typeof table.chair !== "string") return table.chair;
        else {
            // @ts-ignore
            return table.juroren.find(juror => juror.id === table.chair);
        }
    },
    getFlightsCidersIds: (state: storeStates, getter: storeGetters) => (table: Table | null = null): number[] => {
        if (table == null) table = getter.getCurrentTable();
        return table?.flights.flatMap(
            f => f.ciders.map(c => typeof c === "number" ? c : c.flightsCidersId) || []) || [];
    },
    // getUserTischfunktion: (state: storeStates, getters: storeGetters) => (userId: UserId): Tischfunktion | undefined => {
    //     const idx = getters.getUserTischfunktionIdx(userId);
    //     if (idx === undefined) return undefined;
    //     return (getters.getCurrentTable() as Table).tischfunktionen[idx];
    // },
    getUserTischfunktionIdx: (state: storeStates, getter: storeGetters) => (userId: UserId): number | undefined => {
        const table = getter.getCurrentTable();
        console.log("Table", state.tables,  userId)
        return table?.tischfunktionen?.findIndex((tischfunktion: Tischfunktion) => tischfunktion.userId === userId);
    },
    // getIconForTischfunktion: (state: storeStates, getters: storeGetters) => (userId: UserId): number | undefined => {
    //     switch ()
    // },
}
const mutations = {
    ...make.mutations(state),
}

export default {
    namespaced: true, // add this if in module
    state,
    getters,
    mutations,
    // actions,
}