import Vue from 'vue';
import Vuex from 'vuex';
import JurorCider from "@/types/jurorCider";
import {make} from 'vuex-pathify';
import {rootGetter, rootState} from "@/stores";
import util from "@/util";
import {KategorieRated} from "@/types/kategorieRated";
import {Bewertung, BewertungData, createBewertungFromDatabase, updateBewertungFromDatabase} from "@/types/bewertung";
import {apiTyped} from "@/api";
import {MyCollections} from "@/api/types/my-collections";
import {groupBy} from "@/util/helper";
import {BewertungskategorieRated} from "@/types/bewertungskategorieRated";
import {CiderNotes} from "@/types/ciderNotes";
import CiderRatingState from "@/types/ciderRatingState";
import {UserId} from "@/types/user";
import {ChairNotes} from "@/types/chairNotes";
import {Bewertungskategorie} from "@/types/bewertungskategorie";
import {Kategorie} from '@/types/kategorie';

Vue.use(Vuex);

type storeStates = typeof state;
// @ts-ignore
type storeGetters = typeof getters;
type rootStates = typeof rootState;
type rootGetters = typeof rootGetter;

const state: {
        ciderList: JurorCider[],
        // currentCiderIdx: undefined | number,
        // currentCiderId: undefined | number,
        currentFlightsCidersId: number | undefined ,
        maxPoints: number,
        printCiders: JurorCider[],
        printCidersSerialized: string | null,
        revealInfos: boolean,
        hideLegendSteps: boolean
    } = {
    ciderList: Array<JurorCider>(),
    // currentCiderIdx: undefined,
    // currentCiderId: undefined,
    currentFlightsCidersId: undefined,
    maxPoints: 5,
    printCiders: Array<JurorCider>(),
    printCidersSerialized: null,
    revealInfos: false,
    hideLegendSteps: false,
}

// @ts-ignore
const getters = {
    ...make.getters(state),
    currentCider: (state: storeStates, getters: storeGetters) => (): JurorCider | undefined => {
        // deprecated, use getCurrentCider
        if (state.currentFlightsCidersId === undefined) return undefined;
        return getters.getCiderByFlightsCidersId(state.currentFlightsCidersId);
    },
    getCiderById: (state: storeStates) => (ciderId: number, flightId: number): JurorCider | undefined => {
        return state.ciderList.find(cider => cider.id === ciderId && cider.flightId === flightId);
    },
    getCiderByFlightsCidersId: (state: storeStates) => (flightsCidersId: number): JurorCider | undefined => {
        return state.ciderList.find(cider => cider.flightsCidersId === flightsCidersId);
    },
    getCiderByIdx: (state: storeStates) => (ciderIdx: number): JurorCider | undefined => {
        return state.ciderList[ciderIdx || 0] || undefined;
    },
    getCiderIdx: (state: storeStates) => (cider: JurorCider): number | undefined => {
        return state.ciderList.findIndex(cider_ => cider_.id === cider.id && cider_.flightId === cider.flightId);
    },
    // getCurrentCiderIdx: (state: storeStates) => (): number | undefined => {
    //     if (state.currentCiderId === undefined) return undefined;
    //     return state.ciderList.findIndex(cider_ => cider_.id === state.currentCiderId);
    // },
    // getCiderIdxById: (state: storeStates) => (ciderId: number): number | undefined => {
    //     return state.ciderList.findIndex(cider_ => cider_.id === ciderId);
    // },
    getCiderIdxByFlightsCidersId: (state: storeStates) => (flightsCidersId: number): number | undefined => {
        return state.ciderList.findIndex(cider_ => cider_.flightsCidersId === flightsCidersId);
    },
    getPointsByCiderIdx: (state: storeStates, getters: storeGetters) => (ciderId: number) => {
        return getters.getPointsForCider(getters.getCiderByIdx(ciderId));
    },
    getPointsByFlightsCidersId: (state: storeStates, getters: storeGetters) => (flightsCidersId: number) => {
        return getters.getPointsForCider(getters.getCiderByFlightsCidersId(flightsCidersId));
    },
    getPointsForCurrentCider: (state: storeStates, getters: storeGetters) => (): number | undefined  => {
        return getters.getPointsByFlightsCidersId(state.currentFlightsCidersId);
    },
    getPointsForCider: (state: storeStates, getters: storeGetters) => (cider: JurorCider): number => {
        if (cider === undefined || cider === null) {
            return 0;
        }
        return getters.getPointsFromCategory(cider?.kategorie)
    },
    getAllPointsForBewertungskategorie: (state: storeStates, getters: storeGetters) => (
        bewertungskategorie: BewertungskategorieRated, cider: JurorCider, roundTo = 0.1): number => {
        if (cider?.allBewertungen == null) return 0;
        let sum = 0;
        const finishedUserIds = getters.getFinishedUserIds(cider);
        const allBewertungen = finishedUserIds.flatMap(
            (userId: string) => cider?.allBewertungen?.hasOwnProperty(userId) && cider?.allBewertungen[userId] != null
                ? cider?.allBewertungen[userId]
                : []);
        // const allBewertungen = Object.values(cider?.allBewertungen).flat();
        bewertungskategorie.bewertungen.forEach(function (bewertung) {
            allBewertungen.filter((userBewertung: BewertungData) =>
                bewertung.eigenschaftId === userBewertung.eigenschaftId).forEach(function (userBewertung: Bewertung) {
                sum += (userBewertung.punkte || 0) * bewertung.faktor;
            });
        });
        // const noOfJuroren = Object.keys(cider.allBewertungen).length || 1;
        const noOfJuroren = Object.keys(getters.getFinishedUserIds(cider)).length || 1;
        sum /= noOfJuroren;
        return util.util.round_to_decimal(sum, roundTo);
    },
    getPointsForJuror: (state: storeStates, getter: storeGetters) => (cider: JurorCider, jurorId: UserId): number => {
        if (cider.allBewertungen === undefined || !cider.allBewertungen?.hasOwnProperty(jurorId)) {
            return 0;
        }
        const jurorBewertungen = cider?.allBewertungen[jurorId];
        if (jurorBewertungen == null) return 0;
        return getter.getPointsFromBewertungen(jurorBewertungen);
    },
    getPointsFromBewertungen: () => (bewertungen: BewertungData[]): number => {
        let sum = 0;
        bewertungen.forEach(bewertung => sum += (bewertung?.faktor || 1) * (bewertung.punkte || 0));
        return sum;
    },
    getPointsFromBewertungenRaw: () => (
        bewertungen: MyCollections["bewertungen"][], kategorien: Kategorie[], decPlaces = 0.1): number => {
        const eigenschaften = kategorien.flatMap(kategorie => kategorie.bewertungskategorien.flatMap(
            (bewertungskategorie: Bewertungskategorie) => bewertungskategorie.eigenschaften.flat()));
        let sum = 0;
        bewertungen.forEach(bewertung =>
            sum += (eigenschaften.find(eigenschaft =>
                eigenschaft.eigenschaftId === bewertung.eigenschaft_id)?.faktor || 1) * (bewertung.punkte || 0));
        return util.util.round_to_decimal(sum / bewertungen.length, decPlaces);
    },
    getPointsForBewertungskategorie: () => (bewertungskategorie: BewertungskategorieRated): number => {
        let sum = 0;
        bewertungskategorie.bewertungen.forEach(function (bewertung) {
            sum += (bewertung.punkte || 0) * bewertung.faktor;
        });
        return util.util.round_to_decimal(sum, 0.1);
    },
    getMaxPointsForBewertungskategorie: (state: storeStates) =>
            (bewertungskategorie: BewertungskategorieRated): number => {
        let sum = 0;
        bewertungskategorie.bewertungen.forEach(function (bewertung) {
            sum += state.maxPoints * bewertung.faktor;
        });
        return sum;
    },
    getPointsFromCategory: () => (kategorie: KategorieRated, decPlaces = 0.1): number => {
        let points = 0;
        kategorie.bewertungskategorien.forEach(function (bewertungskategorie) {
            bewertungskategorie.bewertungen.forEach(function (bewertung) {
                points += (bewertung.punkte || 0) * bewertung.faktor;
            });
        });
        return util.util.round_to_decimal(points, decPlaces);
    },
    getTotalPointsForCiderIdx: (state: storeStates, getters: storeGetters) =>
        (ciderIdx: number | null = null, decPlaces = 0.5): number => {
            const cider = ciderIdx === null ? getters.currentCider() : getters.getCiderByIdx(ciderIdx)
            return getters.getTotalPointsForCider(cider, decPlaces);
        },
    getTotalPointsForFlightsCidersId: (state: storeStates, getters: storeGetters) =>
            (flightsCidersId: number | null = null, decPlaces = 0.5): number => {
        const cider = flightsCidersId === null
            ? getters.currentCider()
            : getters.getCiderByFlightsCidersId(flightsCidersId)
        return getters.getTotalPointsForCider(cider, decPlaces);
    },
    getFinishedUserIds: (state: storeStates, getters: storeGetters) => (cider: JurorCider | null = null): UserId[] => {
        if (cider === null) cider = getters.currentCider();
        if (cider === null || cider.allNotes == null) return [];
        // if (cider.chairNotes != null && cider.chairNotes.restartedCount > 0) return Object.keys(cider.allNotes);
        return Object.entries(cider.allNotes).filter(
            userKeyNote => userKeyNote[1] != null && userKeyNote[1].finished === true)
                .map(userKeyNote => userKeyNote[0]) || [];
    },
    getTotalPointsForCider: (state: storeStates, getters: storeGetters, rootState: rootStates, rootGetter: rootGetters
        ) => (cider: JurorCider, decPlaces = 0.1, finishedUsers: UserId[] | null = null): number => {
        if (cider === undefined || cider?.allBewertungen === undefined || cider?.allBewertungen === null) return 0;
        const points_per_user: number[] = [];
        // cider?.allBewertungen.forEach((userBewertung) => {
        for (const userId in cider?.allBewertungen) {
            if (!cider?.allBewertungen?.hasOwnProperty(userId)) continue;
            if (finishedUsers !== null && !finishedUsers.includes(userId)) continue;  // only fully rated ciders
            const userBewertung = cider?.allBewertungen[userId];
            let userPoints = 0;
            userBewertung?.forEach((bewertung: BewertungData) => {
                userPoints += rootGetter["kategorienStore/getPointsForBewertungData"](bewertung)
            });
            points_per_user.push(userPoints);
        }
        if (points_per_user.length === 0) return 0;
        // const noOfJuroren = cider.allBewertungen?.size || 1;
        const noOfJuroren = finishedUsers !== null
            ? finishedUsers.length : Object.keys(cider.allBewertungen).length || 1;
        let points = points_per_user.reduce((total, punkte) => total + punkte);
        points /= noOfJuroren;
        return util.util.round_to_decimal(points, decPlaces);
    },
    allBewertungFinished: (state: storeStates, getters: storeGetters) => (cider: JurorCider | null = null): boolean => {
        if (cider === null) cider = getters.currentCider();
        let finished = true;
        for (const userKey in cider?.allBewertungen) {
            if (!cider?.allBewertungen.hasOwnProperty(userKey)
                || cider?.allBewertungen[userKey] == null || !cider?.allNotes.hasOwnProperty(userKey)) continue;
            finished = finished && cider?.allBewertungen[userKey]!.every(
                bewertung => bewertung.punkte !== null) || !cider.allNotes[userKey]?.started;
        }
        return finished || false;  // if undefined return false
    },
    allBewertungFinishedOrEmpty: (state: storeStates, getters: storeGetters) => (cider: JurorCider | null = null): boolean => {
        if (cider == null) cider = getters.currentCider();
        if (cider == null) return false;
        let finished = true;
        for (const userKey in cider?.allNotes) {
            finished = finished && !cider.hasOwnProperty(userKey) && cider.allNotes[userKey]?.started || false;
        }
        return finished || false;  // if undefined return false
    },
    userBewertungFinished: (state: storeStates, getters: storeGetters) => (cider: JurorCider | null = null): boolean => {
        if (cider === null) cider = getters.currentCider();
        const finished = cider?.kategorie?.bewertungskategorien.every(bewertungskategorie =>
            bewertungskategorie.bewertungen.every((bewertung) => bewertung.punkte !== null));
        return finished || false;  // if undefined return false
    },
    getBewertungForJuror: (state: storeStates, getters: storeGetters, rootState: rootStates) =>
        (eigenschaftId: number, jurorId: string | null = null, cider: JurorCider | null = null): BewertungData | null => {
        if (jurorId === null) jurorId = rootState.usersStore.me.id as string;
        if (cider === null) cider = getters.currentCider();
        if (jurorId === null || cider === null || !cider?.allBewertungen?.hasOwnProperty(jurorId)) return null;
        const foundBewertung = cider?.allBewertungen[jurorId]?.find(
            (bewertung: BewertungData) => bewertung.eigenschaftId == eigenschaftId);
        if (foundBewertung === undefined) {
            return null;
        }
        return foundBewertung;
    },
    getBewertungMedian: (state: storeStates, getters: storeGetters) =>
        (eigenschaftId: number, cider: JurorCider | null = null, decPlaces = 0.1): number | null => {
            if (cider === null) cider = getters.currentCider();
            if (cider === null || cider.allBewertungen === undefined) return null;
            const medianArray = [];
            for (const jurorId in cider.allBewertungen) {
                if (!cider.allBewertungen?.hasOwnProperty(jurorId)) continue;
                const jurorPoints = cider.allBewertungen[jurorId]?.find(
                    (bewertung: BewertungData) => Number(bewertung.eigenschaftId) === Number(eigenschaftId))?.punkte;
                // do not include ratings from juror with null (not rated!) points
                if (jurorPoints == null) continue;
                medianArray.push(jurorPoints);
            }
            medianArray.sort((v1, v2) => v1-v2);
            console.log("GOT MEDIANS ARRAY", medianArray)
            if (medianArray.length === 0) return null;
            if (medianArray.length % 2 == 0) {  // even array, return avg of medians
                const idx1 = medianArray.length / 2 - 1;
                const idx2 = medianArray.length / 2;
                return util.util.round_to_decimal((medianArray[idx1] + medianArray[idx2]) / 2, decPlaces);
            }
            return medianArray[(medianArray.length - 1) / 2];
    },
    getBewertungForJuroren: (state: storeStates, getters: storeGetters) =>
            (eigenschaftId: number, cider: JurorCider | null = null, decPlaces = 0.1, includeUnfinished = false
            ): number => {
        if (cider === null) cider = getters.currentCider();
        if (cider === null || cider.allBewertungen === undefined) return 0;
        let points = 0;
        const noOfJuroren = new Set();
        console.log("Getting bewertungen for juror", eigenschaftId, cider)
        for (const jurorId in cider.allBewertungen) {
            if (!cider.allBewertungen?.hasOwnProperty(jurorId)) {
                console.log("No bewertung", cider.allBewertungen, jurorId)
                continue;
            }
            if (!includeUnfinished && !cider.allNotes?.hasOwnProperty(jurorId)) {
                console.log("No note", cider.allNotes, jurorId)
                continue;
            }
            if (!includeUnfinished) {
                const jurorNotes = cider.allNotes[jurorId];
                if (jurorNotes == null || jurorNotes?.finished === false) continue;
            }
            const jurorPoints = cider.allBewertungen[jurorId]?.find(
                (bewertung: BewertungData) => Number(bewertung.eigenschaftId) === Number(eigenschaftId))?.punkte;
            // do not include ratings from juror with null (not rated!) points
            console.log("Found juror", jurorId, jurorPoints, eigenschaftId)
            if (jurorPoints == null) continue;
            noOfJuroren.add(jurorId);
            points += jurorPoints;
        }
        // const noOfJuroren = Object.keys(cider?.allBewertungen).length || 1;
        points /= noOfJuroren.size;
        return util.util.round_to_decimal(points, decPlaces);
    },
    getCiderStateIconColor: (state: storeStates, getters: storeGetters) => (cider: JurorCider | null = null, vuetify: any)
        : [string | undefined, string] => {
        if (cider === null) cider = getters.currentCider();
        const ciderState = getters.getCiderRatingState(cider);
        return getters.getCiderStateIconColorByState(Number(ciderState), vuetify);
    },
    getCiderStateIconColorByState: () => (ciderState: CiderRatingState, vuetify: any)
        : [string | undefined, string] => {
        if (vuetify === undefined) return ["", '#ffffff']  // TODO instance is undefined at the start
        switch (Number(ciderState)) {
            // TODO
            case CiderRatingState.notStarted:
                return ['mdi-ray-start-arrow', vuetify.theme.currentTheme.outline]
            case CiderRatingState.startedByChair:
                return ['mdi-clock-edit-outline', vuetify.theme.currentTheme.error]
            case CiderRatingState.startedByUser:
                return ['mdi-account-edit', vuetify.theme.currentTheme.error]
            case CiderRatingState.finishedByUser:
                return ['mdi-star-check', vuetify.theme.currentTheme.primary]
            case CiderRatingState.finishedByChair:
                return ['mdi-check-decagram', vuetify.theme.currentTheme.secondary]
            case CiderRatingState.restartedByChair:
                return ['mdi-restart', vuetify.theme.currentTheme.error]
            default:
                return ['mdi-help-rhombus', "#dedede"]
        }
    },
    getBestOfCategory: (state: storeStates, getters: storeGetters, rootState: rootStates) =>
        (ciders?: JurorCider[], kategorien?: Kategorie[] | KategorieRated[], pointsField = 'totalPoints'
    ): JurorCider[] => {
        if (ciders === undefined) ciders = state.ciderList;
        if (kategorien === undefined) kategorien = rootState.kategorienStore.kategorieData;
        return kategorien?.flatMap(kategorie => {
            const cidersForKategorie = ciders?.filter(
                cider => Number(cider.kategorie?.id) === Number(kategorie.id)) || [];
            const bestScoreForKategorie = Math.max(...cidersForKategorie.map(
                (cider: any) => Number(cider[pointsField])));
            return cidersForKategorie.filter(
                (cider: any) => Number(cider[pointsField]) === bestScoreForKategorie);
        }) || [];
    },
    // getCiderStateText: (state: storeStates, getters: storeGetters) => (cider: Cider | null = null): string => {
    //     if (cider === null) cider = getters.currentCider();
    //     const state = getters.getCiderRatingState(cider);
    //     switch (state) {
    //         case CiderRatingState.notStarted:
    //             return "Noch nicht gestartet"
    //         default:
    //             return [undefined, "#aaaaaa"]
    //     }
    // },
    getCiderRatingState: (state: storeStates, getters: storeGetters) => (cider: JurorCider | null = null): CiderRatingState => {
        if (cider === null) cider = getters.currentCider();
        // TODO also change for server
        if (cider?.chairNotes == null) return CiderRatingState.notStarted;
        return getters.getCiderRatingStateByNotes(cider.notes, cider.chairNotes)
    },
    getCiderRatingStateByNotes: () => (ciderNotes: CiderNotes | null, chairNotes: ChairNotes | null)
            : CiderRatingState => {
        // TODO also change for server

        // console.log("Cider state:",
        //     "Chair started:", chairNotes.started,
        //     "Chair finished", chairNotes.finished,
        //     "Chair restarted", chairNotes.restarted,
        //     "User started", ciderNotes?.started,
        //     "User finished", ciderNotes?.finished)
        // console.log("Getting state by notes:", ciderNotes, chairNotes)

        if (chairNotes == null) return CiderRatingState.notStarted;

        if (!chairNotes.started &&
            !chairNotes.finished &&
            !chairNotes.restarted) return CiderRatingState.notStarted;

        if (chairNotes.restarted &&
            !chairNotes.started &&
            !chairNotes.finished &&
            !ciderNotes?.started &&
            !ciderNotes?.finished) return CiderRatingState.restartedByChair;

        if (chairNotes.finished &&
            chairNotes.started &&
            !chairNotes.restarted) return CiderRatingState.finishedByChair;

        if (chairNotes.started &&
            !chairNotes.finished &&
            !chairNotes.restarted &&
            !ciderNotes?.started) return CiderRatingState.startedByChair;

        if (chairNotes.started &&
            !chairNotes.finished &&
            chairNotes.restarted &&
            ciderNotes?.started&&
            ciderNotes.finished) return CiderRatingState.finishedByUser;

        if (chairNotes.started &&
            !chairNotes.finished &&
            !chairNotes.restarted &&
            ciderNotes?.started &&
            !ciderNotes.finished) return CiderRatingState.startedByUser;

        if (chairNotes.started &&
            !chairNotes.finished &&
            chairNotes.restarted &&
            ciderNotes?.started &&
            !ciderNotes.finished) return CiderRatingState.startedByUser

        if (chairNotes.started &&
            !chairNotes.finished &&
            !chairNotes.restarted &&
            ciderNotes?.started &&
            ciderNotes.finished) return CiderRatingState.finishedByUser;

        if (!chairNotes.started &&
            !chairNotes.finished &&
            !chairNotes.restarted &&
            !ciderNotes?.started &&
            !ciderNotes?.finished) return CiderRatingState.notStarted;

        console.log("Unknown state:",
            "Chair started:", chairNotes.started,
            "Chair finished", chairNotes.finished,
            "Chair restarted", chairNotes.restarted,
            "User started", ciderNotes?.started,
            "User finished", ciderNotes?.finished)
        return CiderRatingState.unknown;
    },
    getNextCider: (state: storeStates) => (ciders: JurorCider[] | null = null): JurorCider | undefined => {
        if (ciders == null) ciders = state.ciderList;
        return ciders.find(cider => cider.chairNotes?.started === false);
    },
    getCurrentCiderToRate: (state: storeStates) => (ciders: JurorCider[] | null = null): JurorCider | undefined => {
        if (ciders == null) ciders = state.ciderList;
        console.log("CIDERSSS", ciders)
        return ciders.find(cider =>
            (cider.chairNotes?.restarted === false
             && cider.chairNotes?.finished === false)
            || cider.chairNotes == null)  // not yet opened;
    },
    getCidersYetToRate: (state: storeStates, getters: storeGetters) => (
            ciders: JurorCider[] | null = null): JurorCider[] | undefined => {
        if (ciders == null) ciders = state.ciderList;
        const currentCiderToRate = getters.getCurrentCiderToRate(ciders);
        const currentCiderToRateId = currentCiderToRate !== undefined ? currentCiderToRate.id : undefined;
        return ciders.filter(cider =>
            cider.chairNotes != null
            && (cider.chairNotes?.restarted === true &&
                cider.chairNotes?.finished === false)
            && cider.id !== currentCiderToRateId);
    },
    getStartedNotFinishedCidersYetToRate: (state: storeStates, getters: storeGetters) => (
            ciders: JurorCider[] | null = null): JurorCider[] | undefined => {
        if (ciders == null) ciders = state.ciderList;
        const currentCiderToRate = getters.getCurrentCiderToRate(ciders);
        const currentCiderToRateId = currentCiderToRate !== undefined ? currentCiderToRate.id : undefined;
        return ciders.filter(cider =>
            cider.chairNotes != null
            && ( cider.chairNotes?.restarted === false &&
                cider.chairNotes?.finished === false &&
                cider.chairNotes?.started === true)
            && cider.id !== currentCiderToRateId);
    },
}

const mutations = {
    ...make.mutations(state),
    addCider(state: storeStates, cider: JurorCider): void {
        state.ciderList.push(cider);
    },
    setPrintCider(state: storeStates, payload: {index: number, cider: JurorCider}): void {
        if (payload.index > state.printCiders.length) return;
        state.printCiders[payload.index] = payload.cider;
    },
    addBewertungenToKategorie(state: storeStates,
                              payload: {kategorie: KategorieRated, bewertungen: MyCollections["bewertungen"][]}): KategorieRated {
        for (const newBewertung of payload.bewertungen) {
            for (const bewertungskategorie of payload.kategorie.bewertungskategorien) {
                bewertungskategorie.bewertungen.forEach((bewertung, index) => {
                    console.log("newBewertung", bewertung);
                    if (Number(bewertung.eigenschaftId) === Number(newBewertung.eigenschaft_id)) {
                        bewertungskategorie.bewertungen[index] = updateBewertungFromDatabase(bewertung, newBewertung);
                        console.log("newBewertung", newBewertung);
                        (newBewertung as any)["isSet"] = true;
                    }
                });
            }
        }
        return payload.kategorie;
    },
    // addBewertungenToCider(state: storeStates, payload: {ciderIdx: number, bewertungen: MyCollections["bewertungen"][]})
    //         : void {
    //     state.ciderList[payload.ciderIdx].bewertungen = payload.bewertungen.map(bewertung =>
    //         createBewertungFromDatabase(bewertung));
    // },
    // addAllBewertungenForJurorToCider(state: storeStates, payload: {ciderIdx: number,
    //         bewertungenByJuror: MyCollections["bewertungen"][]}): void {
    //     const newBewertungen = payload.bewertungenByJuror.map(
    //         bewertung => createBewertungFromDatabase(bewertung,
    //             state.ciderList[payload.ciderIdx].kategorie as KategorieRated));
    //     if (state.ciderList[payload.ciderIdx].allBewertungen === undefined) {
    //         // state.ciderList[payload.ciderIdx].allBewertungen = new Map<string, BewertungData[]>();
    //         state.ciderList[payload.ciderIdx].allBewertungen = {};
    //     }
    //     if (newBewertungen[0].jurorId !== undefined && state.ciderList[payload.ciderIdx].allBewertungen !== undefined) {
    //         // state.ciderList[payload.ciderIdx].allBewertungen?.set(newBewertungen[0].jurorId, newBewertungen);
    //         // @ts-ignore
    //         state.ciderList[payload.ciderIdx].allBewertungen[newBewertungen[0].jurorId] = newBewertungen;
    //     }
    // },
    updateBewertungForCurrentCider(state: storeStates, bewertung: Bewertung | BewertungData) {
        if (state.currentFlightsCidersId === undefined) return;
        const currentCider = state.ciderList.find(c => c.flightsCidersId === state.currentFlightsCidersId);
        const currentCiderIdx = state.ciderList.findIndex(c => c.flightsCidersId === state.currentFlightsCidersId);
        if (currentCider === undefined || currentCiderIdx === -1) {
            console.error("Error finding Cider with flightsCidersId", state.currentFlightsCidersId, state.ciderList);
            return;
        }
        if (currentCider.id !== bewertung.ciderId || currentCider.kategorie?.bewertungskategorien === undefined) {
            console.error("Error updating Bewertung for Current Cider", bewertung, currentCider);
            throw new Error();
        }
        for (const bewertungskategorie of currentCider.kategorie.bewertungskategorien) {
            for (const existingBewertung of bewertungskategorie.bewertungen) {
                if (existingBewertung.ciderId === bewertung.ciderId &&
                        existingBewertung.eigenschaftId === bewertung.eigenschaftId &&
                        existingBewertung.bewertungId === bewertung.bewertungId &&
                        existingBewertung.jurorId === bewertung.jurorId) {
                    existingBewertung.punkte = bewertung.punkte;
                    existingBewertung.dateCreated = bewertung.dateCreated;
                    existingBewertung.dateUpdated = bewertung.dateUpdated;
                    break;
                }
            }
        }
        console.log("Setting Cider Bewertung...");
        state.ciderList[currentCiderIdx] = currentCider;
        state.ciderList[currentCiderIdx].kategorie = currentCider.kategorie;
    },
    addChairNotesToCiders(state: storeStates, payload: {chairNotes: ChairNotes[], ciders: JurorCider[]}) {
        for (const chairNote of payload.chairNotes) {
            const cider = state.ciderList.find(cider => cider.id === chairNote.ciderId);
            if (cider !== undefined) {
                cider.chairNotes = chairNote;
            }
        }
    },
};


const actions = {
    addCider(context: any, cider: JurorCider): void {
        context.commit('addCider', cider);
    },
    // async addBewertungenToCiderKategorie(context: any,
    //                                      payload: {bewertungen: MyCollections["bewertungen"][]}) : Promise<void> {
    //     const bewertungenGrouped = groupBy(payload.bewertungen,
    //         ((bewertung: MyCollections["bewertungen"]) => bewertung.cider_id));
    //     for (const [ciderId, values] of Object.entries(bewertungenGrouped)) {
    //         // const values = bewertungenGrouped[ciderId] as Bewertung;
    //         const ciderIdx = context.state.ciderList.findIndex((cider: Cider) => cider.id === Number(ciderId));
    //         const cider: Cider = context.getters.getCiderByIdx(ciderIdx);
    //         if (ciderIdx === -1) {
    //             console.error("Error finding Cider for Id:", ciderId, context.state.ciderList);
    //             continue;
    //         }
    //         if (cider.kategorie === undefined) {
    //             const newCiders = await apiTyped.addKategorienToCiders([cider]);
    //             cider.kategorie = newCiders[0].kategorie;
    //         }
    //         context.commit("addBewertungenToKategorie", {kategorie: cider.kategorie, bewertungen: values});
    //         context.commit("setCider", {cider})
    //     }
    // },
    // async addAllBewertungenToCiders(context: any,
    //                                 payload: {bewertungen: MyCollections["bewertungen"][], ciders: JurorCider[]}): Promise<void> {
    //     if (payload.bewertungen.length === 0) {
    //         payload.bewertungen = await apiTyped.getBewertungen(payload.ciders || context.state.ciderList,
    //             context.getters["tableStore/getTableMembersIds"])
    //     }
    //     const bewertungenGrouped = groupBy(payload.bewertungen,
    //         ((bewertung: MyCollections["bewertungen"]) => bewertung.cider_id));
    //     for (const ciderId in bewertungenGrouped) {
    //         if (!bewertungenGrouped.hasOwnProperty(ciderId)) continue;
    //         const values: MyCollections["bewertungen"][] = bewertungenGrouped[ciderId];
    //         const bewertungenGroupedByJuror = groupBy(values,
    //             ((bewertung: MyCollections["bewertungen"]) => bewertung.juror_id));
    //         const ciderIdx = context.state.ciderList.findIndex((cider: JurorCider) => cider.id === Number(ciderId));
    //         for (const juror in bewertungenGroupedByJuror) {
    //             if (!bewertungenGroupedByJuror.hasOwnProperty(juror)) continue;
    //             const bewertungByJuror: MyCollections["bewertungen"][] = bewertungenGroupedByJuror[juror];
    //             context.commit("addAllBewertungenForJurorToCider",
    //                 {ciderIdx: ciderIdx, bewertungenByJuror: bewertungByJuror});
    //         }
    //     }
    // },
    // async addAllNotesForCider(context: any, cider: Cider,
    //                           allNotes: MyCollections["cider_notes"][] = []): Promise<void> {
    //     const ciderId = cider.id;
    //     const ciderIdx = context.state.ciderList.findIndex((cider: Cider) => cider.id === ciderId);
    //     if (allNotes.length === 0) {
    //         allNotes = await apiTyped.getAllNotesForCider(cider);
    //     }
    //     const allNotesGroupedByJuror = groupBy(allNotes,
    //         ((note: MyCollections["cider_notes"]) => note.juror_id));
    //     for (const juror in allNotesGroupedByJuror) {
    //         if (!allNotesGroupedByJuror.hasOwnProperty(juror)) continue;
    //         const notesByJuror: MyCollections["cider_notes"][] = allNotesGroupedByJuror[juror];
    //         const notes = notesByJuror.map(note => createCiderNotesFromDatabase(note));
    //         if (context.state.ciderList[ciderIdx].allNotes === undefined) {
    //             context.state.ciderList[ciderIdx].allNotes = new Map<string, CiderNotes>();
    //         }
    //         if (notes.length > 1) {
    //             console.warn("More than one Note Entry found for Juror:", juror, notes);
    //         }
    //         // state.ciderList[ciderIdx].allNotes?.set(juror, notes[0]);
    //         if (state.ciderList[ciderIdx].allNotes !== undefined) {
    //             // @ts-ignore
    //             state.ciderList[ciderIdx].allNotes[juror] = notes[0];
    //         }
    //     }
    // },
    // async addAbschlusskommentarForCider(context: any, cider: Cider): Promise<void> {
    //     const ciderId = cider.id;
    //     const ciderIdx = context.state.ciderList.findIndex((cider: Cider) => cider.id === ciderId);
    //     const abschlusskommentar = await apiTyped.getAbschlusskommentar(ciderId);
    //     context.state.ciderList[ciderIdx].abschlusskommentar = createAbschlusskommentarFromDatabase(abschlusskommentar);
    // },
    async allBewertungenMarkedFinished(context: any, cider: JurorCider | null = null): Promise<boolean> {
        if (cider === null) cider = context.getters.currentCider();
        if (cider == null) return false;
        const userMarkedAsFinished = await apiTyped.getAllJurorsMarkedFinished(cider);
        const finished = getters.allBewertungFinished() && userMarkedAsFinished;
        return finished || false;  // if undefined return false
    },
}

export default {
    namespaced: true, // add this if in module
    state,
    getters,
    mutations,
    actions,
}