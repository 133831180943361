import {MyCollections} from "@/api/types/my-collections";

export interface Partner {
    id: number;
    name: string;
    logo: string;
    url: string;
    description: string;
    backgroundColor: string;
}

export function createPartnerFromDb(partner: MyCollections["partners"]): Partner {
    return <Partner>{
        id: partner.id,
        name: partner.name,
        logo: partner.logo,
        url: partner.url,
        description: partner.description,
        backgroundColor: partner.background_color || "",
    }
}