
export default {
    data: () => ({
        whitelist: [
            '^/producer/submit/tastings$',
            '^/producer/submit/selectTastingProducts',
            '^/producer/submit/selectCiders$',
            '^/producer/submit/confirmData$',
            '^/producer/submit/summary$',
            '^/producer/submit/success$',
            '^/login$',
            '^/producer/submitted/overview/\\d+$',
            '^/producer/submitted/cancel/\\d+$',
            '^/producer/ciders/create$',
            '^/producer/submit-data/select-products$',
            '^/producer/submit-data/confirm-products$',
            '^/producer/submit-data/overview'
        ]
    }),
    methods: {
        matchesWhitelist(path) {
            return this.whitelist.some(whiteUrl => path.match(whiteUrl));
        },
        submitCount(tasting) {
            if (tasting == null) return 0;
            if (tasting.tastingSettings.submissionNeedsPayment) {
                return this.$store.state.submitStore.numberOfPaidProducts || null;
                // const producerPaidProducts = this.paidProducts.find(p => p.tastingId === tasting.id && p.numberOfProducts > 0);
                // if (producerPaidProducts != null) {
                //     return producerPaidProducts.numberOfProducts;
                // }
                // return null
            } else if (tasting.tastingSettings.maxCiders > 0) {
                return Number(tasting.tastingSettings.maxCiders);
            }
            return null;
        }
    },
}