<template>
  <v-navigation-drawer
    ref="drawerComponent"
    v-model="drawerExpand"
    app
    :mini-variant="!isSmallScreen"
    :permanent="!isSmallScreen"
    :temporary="isSmallScreen"
    fixed
    left
    :expand-on-hover="!isSmallScreen"
    class="no-print transition-all"
    :class="{'elevation-3': miniSync}"
    color="background"
    style="z-index: 5"
    @transitionend="p"
  >
    <v-container class="pa-0 grow d-flex flex-column flex-nowrap">
      <v-list-item class="px-2">
        <v-img
          contain
          :lazy-src="getLogoUrl('logo_app', 300)"
          :src="getLogoUrl('logo_app', 300)"
          alt="CiderAwardLogo"
          style="margin: auto;"
          max-height="32px"
          max-width="40px"
        />

        <!--        <v-btn-->
        <!--          icon-->
        <!--          @click.stop="mini = !mini"-->
        <!--        >-->
        <!--          <v-icon>mdi-chevron-left</v-icon>-->
        <!--        </v-btn>-->
      </v-list-item>
      <v-expand-transition>
        <v-select
          v-if="allTastings !== null && allTastings !== undefined"
          v-model="selectedTasting"
          :outlined="$vuetify.theme.dark"
          class="d-flex align-center pl-2 pr-2"
          :items="allTastings"
          style="max-width: 100vw;"
          :item-text="item => getPrettyDate(item.datum) + ': ' + item.name"
          item-value="id"
          label="Tasting auswählen"
          color="background"
          return-object
          solo
          hide-details
        >
        </v-select>
      </v-expand-transition>
      <v-row class="grow fill-height">
        <v-col cols="12">
          <v-list class="">
            <v-list-item
              v-for="item in items"
              :key="item.title"
              link
              :target="item.newTab ? '_blank' : ''"
              class="align-center"
              :to="{ path: item.route, query: { ...$route.query } }"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon v-if="item.newTab">
                <v-icon>mdi-open-in-new</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import logos_mixin from "@/components/mixins/logos_mixin";
import {sync} from "vuex-pathify";

export default {
  name: "ActionsDrawer",
  mixins: [logos_mixin],
  data () {
    return {
      miniSync: false,
      drawer: false,
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard-variant', route: '/admin/home', newTab: false },
        { title: 'Tischübersicht', icon: 'mdi-home-city', route: '/admin/tables', newTab: false },
        { title: 'Alle Cider', icon: 'mdi-glass-wine', route: '/admin/ciders', newTab: false },
        { title: 'Ranglisten', icon: 'mdi-certificate-outline', route: '/admin/print/ranking_a4', newTab: true },
        { title: 'Timeline', icon: 'mdi-timeline-text-outline', route: '/admin/timeline', newTab: false },
        { title: 'Purchases', icon: 'mdi-cash-multiple', route: '/admin/purchases', newTab: false },
        { title: 'Judges', icon: 'mdi-account-details', route: '/admin/judges', newTab: false },
        { title: 'Submit Product', icon: 'mdi-basket-plus', route: '/admin/submit-product', newTab: false },
        { title: 'Table Designer', icon: 'mdi-table-furniture', route: '/admin/table-designer', newTab: false },
        // { title: 'Debug', icon: 'mdi-bug-outline', route: '/admin/debug', newTab: false },
      ],
      mini: false,
    }
  },
  computed: {
    drawerExpand: sync('adminNavDrawer'),
    allTastings: sync("usersStore/tastings"),
    tastingId: sync("usersStore/currentTastingId"),
    // tastingIdx: sync("usersStore/currentTastingIdx"),
    selectedTasting: {
      get() {
        if (this.allTastings === undefined
          || this.tastingId < 0
          || this.allTastings.find(t => t.id === this.tastingId) == null)
          return undefined;
        return this.allTastings.find(t => t.id === this.tastingId);
        // return this.allTastings[this.tastingIdx];
      },
      set(setTasting) {
        const newIdx = this.allTastings.findIndex(tasting => tasting.id === setTasting.id);
        const newId = this.allTastings[newIdx]?.id;
        console.log("Setting new idx", newIdx)
        // this.$store.set("usersStore/currentTastingIdx", newIdx);
        this.$store.set("usersStore/currentTastingId", newId);
        this.$router.push({ query: { tasting: setTasting.id }})
      }
    },
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return true
        default:
          return false
      }
    },
    expanded() {
      if (this.$refs.drawerComponent === null || this.$refs.drawerComponent === undefined) return false;
      return this.$refs.drawerComponent.isMiniVariant
    },
  },
  methods: {
    getPrettyDate(dateStr) {
      const date = new Date(Date.parse(dateStr));
      return `${("0" + date.getDate()).slice(-2)}.${("0" + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`
    },
    p() {
      if (!(this.$refs.drawerComponent === null || this.$refs.drawerComponent === undefined)) {
        console.log("this.miniSync", this.miniSync)
        this.miniSync = !this.$refs.drawerComponent.isMiniVariant && !this.isSmallScreen;
      }
    }
  }
}
</script>

<style scoped>
@media print {
  .no-print, .no-print
  {
    display: none !important;
  }
}
.v-list-item--link::before {
  color: var(--v-text_std-base);
  background-color: var(--v-primary-base);
}
.v-list-item--active::before {
  color: var(--v-text_std-base);
  opacity: 0.34;
  background-color: var(--v-primary-base);
}
.v-list-item--active > .v-list-item__icon {
  color: var(--v-text_std-base);
}
.v-list-item--active > .v-list-item__content {
  color: var(--v-text_std-base);
}
.theme--light.v-list-item:hover::before {
  opacity: 0.54;
}
.v-list-item--link {
  color: var(--v-text_std-base);
}
.transition-all {
  transition: all 0.1s ease;
}
</style>