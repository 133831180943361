<template>
  <v-app style="margin-top: 0 !important; padding-top: 0 !important;">
    <v-main
      style="background-color: white !important; overflow-x: visible;"
      class="pa-0 ma-0"
    >
      <v-container
        id="allCider"
        fluid
        class="pa-0 ma-0"
      >
        <LoadingCircle
          v-if="loading && !error"
        />
        <template v-if="!loading">
          <v-btn
            fab
            fixed
            bottom
            right
            x-large
            class="mr-8 mb-8 no-print"
            @click="print"
          >
            <v-icon>
              mdi-printer
            </v-icon>
          </v-btn>
          <v-theme-provider light>
            <GesamtauswertungPage
              id="page"
              ref="pagePDF"
              :cider="cider"
              :badges-data="badgesData"
              :best-of-category="bestOfCategory"
              :badges-data-array="badgesDataArray"
              :tasting="tasting"
              :show-individual="false"
              style="background-color: white !important; overflow: visible !important;"
              class="mt-8 mb-8 ml-auto mr-auto page_print"
              :class="{first_load: firstLoading}"
            />
          </v-theme-provider>
        </template>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import GesamtauswertungPage from "@/components/admin/gesamtauswertung/gesamtauswertung_page";
import LoadingCircle from "@/layouts/loading_circle";
import cider_mixin from "@/components/mixins/cider_mixin";
import {apiTyped} from "@/api";

export default {
  name: "ProducerGesamtbewertungPrintA4",
  components: {LoadingCircle, GesamtauswertungPage},
  mixins: [cider_mixin],
  data: () => ({
    error: false,
    errorMsg: "asd",
    data: null,
    loading: true,
    firstLoading: true,
  }),
  computed: {
    dataUuid() {
      return this.$route.query.uuid;
    },
    cider() {
      return this.data.cider;
    },
    badgesData() {
      return this.data.badgesData;
    },
    bestOfCategory() {
      return this.data.bestOfCategory;
    },
    badgesDataArray() {
      return this.data.badgesDataArray;
    },
    tasting() {
      return this.data.tasting;
    },
    pageHeight () {
      return document.body.scrollHeight
    },
  },
  async created() {
    this.$vuetify.theme.dark = false;
    const route = this.$store.state.producerStore.lastRouteBeforePrint || "/producer/home";
    const timeout = window.setTimeout(async () => {
      this.setErrorMsg();
      window.setTimeout(async () => await this.$router.push(route), 2500);
    }, 15000)
    const data = await apiTyped.getPrintData(this.dataUuid);
    window.clearTimeout(timeout);
    if (data == null) {
      this.error = true;
      window.clearTimeout(timeout);
      await this.$router.push(route)
      return;
    }
    this.data = data.data;
    this.$store.set("producerStore/lastRouteBeforePrint", this.data?.__lastRoute__ || null);
    try {
      if (await apiTyped.loginRefresh() == null) {
        await apiTyped.loginToken(data["accessToken"]);
      }
    } catch(e) {
      console.log("ERROR", e)
      await apiTyped.loginToken(data["accessToken"]);
    }
    this.loading = false;
  },
  methods: {
    print() {
      window.print();
    },
    setErrorMsg() {
      this.errorMsg = "There was an error loading the print data. Please try again.";
    },
  },
  mounted() {
    // this.$vuetify.goTo(this.pageHeight, {duration: 0, easing: 'linear'})
    window.setTimeout(() => {
      this.firstLoading = false;
    }, 1000);
    // const loadingDuration = this.pageHeight / 3;
    const loadingDuration = 1000;
    window.setTimeout(() => {
      this.$vuetify.goTo(this.pageHeight, {duration: 0})
    }, 400);
    window.setTimeout(() => {
      this.$vuetify.goTo(0, {duration: 1000, easing: 'easeInOutCubic'})
    }, loadingDuration);
    window.setTimeout(() => {
      this.loading = false;
    }, loadingDuration * 1.5);
    window.setTimeout(() => {
      this.$nextTick(() => {
        // window.print();
      });
    }, loadingDuration * 2);
  },
}
</script>

<style scoped>
.first_load {
  position: absolute;
  top: 0;
  left: 0;
}

@media print {
  .page_print {
    margin: 0 !important;
    padding: 32px !important;
  }
}

@page {
  size: portrait;
  margin: 0 0 0 0 !important;
}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>