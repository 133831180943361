<template>
  <div
    v-if="cider != null"
    class="parent"
  >
    <div
      class="logo"
      style="position: relative;"
    >
      <!--      <v-img-->
      <!--        v-if="badgesData.hasImage"-->
      <!--        max-height="150px"-->
      <!--        max-width="150px"-->
      <!--        :src="getBadge(1000)"-->
      <!--        alt="Badge"-->
      <!--        style="position: absolute; top: 20px; left: 20px;"-->
      <!--        @load="$emit('init')"-->
      <!--      />-->
      <div
        style="position: absolute; top: 20px; left: 20px;"
      >
        <Badge
          v-if="badgesData != null && badgesData.hasImage"
          :badge-shadow="false"
          :badge-data="badgesData"
          :best-of-category="bestOfCategory"
          :show-none-badge="false"
          :points="calculateTotalPointsForCider(cider, true)"
          :badge-width="150"
          :badge-resolution="1000"
          :border="false"
          @load="$emit('init')"
        />
        <v-img
          v-else
          contain
          :lazy-src="getLogoUrl(tasting.logoMesse, 500)"
          :src="getLogoUrl(tasting.logoMesse, 500)"
          alt="CiderAwardLogo"
          max-width="175px"
        />
      </div>
    </div>
    <div
      class="one_col flex-column justify-end align-center pl-2 fontSize mt-4"
      style="border-left: 1px solid var(--v-divider_light-base);"
    >
      <span
        :id="`fontSizeCider_${cider.id}_${cider.flightId}`"
        class="d-flex justify-start big_title text-left"
        style="line-height: 1.05"
        :style="{'font-size': fontSizeCider + 'px'}"
      >
        {{ cider.name }}
      </span>
      <span class="d-flex justify-start translated_text pt-1">
        {{ $tc('product', 1) }}
      </span>
    </div>
    <div
      class="one_col flex-column justify-end align-center pl-2 text-left pb-1"
      style="border-left: 1px solid var(--v-divider_light-base);"
    >
      <span
        :id="`fontSizeProduzent_${cider.id}_${cider.flightId}`"
        class="d-flex justify-start big_text"
        :style="{'font-size': fontSizeProduzent + 'px'}"
      >
        {{ cider.hasOwnProperty('companyName') ? cider.companyName : '-' }}
      </span>
      <span class="d-flex justify-start translated_text">
        {{ $t('producer') }}
      </span>
    </div>

    <div
      class="one_col flex-column justify-start align-center pl-2"
      style="border-left: 1px solid var(--v-divider_light-base);"
    >
      <span
        :id="`fontSizeKategorie_${cider.id}_${cider.flightId}`"
        class="d-flex justify-start big_text"
        :style="{'font-size': fontSizeKategorie + 'px'}"
      >
        {{ cider.kategorie.name }}
      </span>
      <span class="d-flex justify-start translated_text">
        {{ $tc('category', 1) }}
      </span>
    </div>
  </div>
</template>

<script>
import logos_mixin from "@/components/mixins/logos_mixin";
import {apiTyped} from "@/api";
import cider_mixin from "@/components/mixins/cider_mixin";
import Badge from "@/components/badge";

export default {
  name: "GesamtauswertungHeader",
  components: {Badge},
  mixins: [logos_mixin, cider_mixin],
  props: {
    cider: {type: Object, required: true, default: () => null},
    badgesData: {type: Object, required: false, default: () => null},
    bestOfCategory: {type: Boolean, required: false, default: false},
    tasting: {type: Object, required: true, default: null},
  },
  data: () => ({
    fontSizeCider: 60,
    fontSizeProduzent: 30,
    fontSizeKategorie: 20,
  }),
  watch: {
    cider: function () {
      console.log("___UPDATE")
      this.fontSizeCider = 60;
      this.fontSizeProduzent = 30;
      this.fontSizeKategorie = 20;
      this.updateFlexFont();
    },
  },
  mounted() {
    this.updateFlexFont();
  },
  updated() {
    this.updateFlexFont();
  },
  methods: {
    getImgUrl(id, width) {
      return apiTyped.getImgUrl(id, width);
    },
    updateFlexFont() {
      this.flexFont(`fontSizeCider_${this.cider.id}_${this.cider.flightId}`, this.fontSizeCider, 'fontSizeCider');
      this.flexFont(`fontSizeProduzent_${this.cider.id}_${this.cider.flightId}`, this.fontSizeProduzent, 'fontSizeProduzent');
      this.flexFont(`fontSizeKategorie_${this.cider.id}_${this.cider.flightId}`, this.fontSizeKategorie, 'fontSizeKategorie');
    },
    getLogoUrl(id, width) {
      return apiTyped.getImgUrl(id, width);
    },
    flexFont (id, maxWidth, name) {
      this.$nextTick(() =>
      {
        const div = document.getElementById(id);
        if (div === null || div === undefined) return "20px";
        let fontWidth = 1000000;
        const divWidth = div.offsetWidth;  // TODO estimate new if layout changes
        // const divWidth = 513;
        console.log("OFFSET WIDTH", divWidth)
        // let i = 0;
        const font = this.getCanvasFontSize(div);
        let fontSize = maxWidth // Number(font[1].slice(0, font[1].length - 2));
        console.log("Start", id)
        fontWidth = this.getTextWidth(div.innerHTML, `${font[0]} ${fontSize}px ${font[2]}`);
        while(divWidth < fontWidth) {
          fontSize = fontSize - 1;
          fontWidth = this.getTextWidth(div.innerHTML, `${font[0]} ${fontSize}px ${font[2]}`);
          console.log("FontW", fontWidth, divWidth, divWidth < fontWidth, fontSize)
        }
        console.log("Font for", id, fontSize, div.innerHTML);
        // console.log("FLEX", div.offsetWidth * 0.05, String(Math.min(div.offsetWidth * 0.05, maxWidth)) + "px")
        // const w = String(Math.min(div.offsetWidth * 0.05 * 0.8, maxWidth)) + "px";
        // div.style.fontSize = String(fontSize) + "px";
        console.log("Font return", String(fontSize))
        this[name] = fontSize;
      });
    },
    getTextWidth(text, font) {
      // re-use canvas object for better performance
      const canvas = this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement("canvas"));
      const context = canvas.getContext("2d");
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    },
    getCssStyle(element, prop) {
      return window.getComputedStyle(element, null).getPropertyValue(prop);
    },
    getCanvasFontSize(el = document.body) {
      const fontWeight = this.getCssStyle(el, 'font-weight') || 'normal';
      const fontSize = this.getCssStyle(el, 'font-size') || '16px';
      const fontFamily = this.getCssStyle(el, 'font-family') || 'Times New Roman';

      return [fontWeight, fontSize, fontFamily];
    },
  }
}
</script>

<style scoped>
.parent {
  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-template-rows: repeat(3, min-content);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.logo {
  grid-area: 1 / 1 / 4 / 2;
  max-height: 225px;
  align-items: self-start;
  /*align-self: center;*/
  /*justify-self: center;*/
}
.one_col {
  grid-column: auto;
}
.translated_text_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: center;
}
.translated_text {
  font-weight: 200;
  font-style: italic;
}
.big_text {
  text-decoration: underline var(--v-divider_light-base);
  text-underline-offset: 10%;
  text-decoration-thickness: 3px;
}
.big_title {
  text-decoration: underline var(--v-divider_light-base);
  text-underline-offset: 10%;
  text-decoration-thickness: 5px;
}
</style>