<template>
  <v-container>
    <v-btn
      @click="migrateRevisions"
    >
      Migrate Revisions
    </v-btn>
  </v-container>
</template>

<script>
import {apiTyped} from "@/api";

export default {
  name: "AdminDebug",
  methods: {
    async migrateRevisions() {
      await apiTyped.migrateRevisions();
    }
  }
}
</script>

<style scoped>

</style>