<template>
  <v-container
    fluid
    class="pl-md-8 pr-md-8 pl-sm-2 pr-sm-2"
    style="max-width: 1800px;"
  >
    <PageNavigation
      :title-translation="submittedCiders != null && submittedCiders.length > 0 ? $t('submissionOverview') : $t('tastingOverview')"
      :breadcrumbs="[{text: $t('home'), to: '/producer/home'},
                     {text: $t(submittedCiders != null && submittedCiders.length > 0 ? 'submissionOverview' : 'tastingOverview'), disabled: true}]"
    />

    <template v-if="loaded">
      <div
        style="max-height: 460px; min-height: 460px; width: 100%; position: relative;"
        class="ml-auto mr-auto mt-4"
      >
        <div>
          <v-img
            v-if="tasting.tastingInfos.headerImage != null"
            :src="getImageUrl(tasting.tastingInfos.headerImage, 2000)"
            :lazy-src="getImageUrl(tasting.tastingInfos.headerImage, 200)"
            :contain="false"
            height="400"
            class="rounded-xl"
          />
          <v-img
            v-else
            style="background-color: white"
            :contain="false"
            height="400"
            class="rounded-xl"
          />
        </div>
        <div style="position: relative;">
          <div
            :class="{'logo-small-screen': $vuetify.breakpoint.name === 'xs',
                     'logo-bigger-screen': $vuetify.breakpoint.name !== 'xs'}"
          >
            <v-card
              outlined
              class="pa-2"
            >
              <v-img
                v-if="tasting.logoMesse != null"
                :src="getImageUrl(tasting.logoMesse, 2000)"
                :lazy-src="getImageUrl(tasting.logoMesse, 200)"
                width="200"
                height="200"
                contain
                class="rounded-xl"
              />
            </v-card>
          </div>
        </div>
      </div>
      
      <div
        class="d-flex text-h2 mb-0 pt-4 align-center text-left"
        style="font-size: 44px !important;"
      >
        {{ tasting.name }}
      </div>
      <div
        class="d-flex py-1 text-body-2"
        style="column-gap: 16px;"
      >
        <div
          id="location indicator"
          class="d-flex align-baseline text--secondary"
        >
          <v-icon
            small
            left
          >
            mdi-map-marker
          </v-icon>
          <span>
            {{ tasting.country }}, {{ tasting.city }}
          </span>
        </div>
      </div>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="9"
        >
          <span
            class="text-left text-subtitle-1 text--secondary"
            style="width: 66%;"
            v-html="getTranslation(tasting.translations,'beschreibung', currentLanguage)"
          />
        </v-col>
        <v-col
          id="-> to website"
          cols="12"
          md="3"
          class="d-flex justify-end"
        >
          <a
            class="justify-end pb-3"
            :href="tasting.tastingInfos.tastingHomepage"
            target="_blank"
            style="text-decoration: none;"
          >
            <v-btn
              rounded
              elevation="0"
              color="text_std lighten-3"
              class="ml-auto my-auto"
              outlined
            >
              <v-icon left>
                mdi-open-in-new
              </v-icon>
              {{ $t('toTastingWebsite') }}
            </v-btn>
          </a>
        </v-col>
      </v-row>

      <v-alert
        v-if="new Date(tasting.einreichenAb) < Date.now()
          && new Date(tasting.einreichenBis) > Date.now()
          && (
            (submittedCiders.length === 0 && !tasting.tastingSettings.hasPurchasables)
            || (tasting.tastingSettings.hasPurchasables && tastingPurchases.length === 0)
          )
        "
        border="left"
        rounded
        text
        type="success"
        icon="mdi-calendar-clock"
      >
        <v-row
          no-gutters
        >
          <v-col
            class="text-left text-body-1"
            cols="12"
            xs="12"
            sm="8"
          >
            <div>
              {{ $t('tastingCard.submissionUntil') }}
            </div>
            <div>
              {{ new Date(tasting.einreichenBis).toLocaleString() }}
            </div>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="4"
            class="d-flex justify-end"
          >
            <v-btn
              rounded
              outlined
              elevation="0"
              color="text_std lighten-3"
              class="ml-auto my-auto"
              @click="$router.push('/producer/submit/tastings')"
            >
              <v-icon left>
                mdi-basket-plus
              </v-icon>
              {{ $t('submitProducts') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <div
        class="ml-auto mr-auto"
      >
        <v-row
          v-if="loaded"
          class="d-flex justify-center px-3"
        >
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="8"
            class="pl-0"
          >
            <div
              v-if="topDescription != null && topDescription !== ''"
              class="d-flex flex-column justify-start align-start text-left ml-auto mr-auto text-body-1 pb-4"
              v-html="topDescription"
            />
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="4"
            align-self="start"
            class="mr-auto px-0"
          >
            <div class="d-flex flex-column mr-auto justify-start align-start">
              <div class="text--secondary text-h5 text-left pl-0 pt-1 d-flex">
                {{ $t('paymentHandling.dates') }}
              </div>
              <div class="d-flex justify-start">
                <v-timeline
                  v-if="tasting.tastingInfos != null && tasting.tastingInfos.eventTimeline != null"
                  dense
                  align-top
                >
                  <v-timeline-item
                    v-for="(timelineEntry, index) in tasting.tastingInfos.eventTimeline"
                    :key="index"
                    :color="timelineEntry.color || 'secondary'"
                    :icon="timelineEntry.icon"
                    :icon-color="contrastColor(timelineEntry.color)"
                    :style="{filter: ('brightness(' + 100 - index * 5 + '%) !important'),}"
                    class="align-center"
                    style="min-width: 100% !important;"
                  >
                    <a
                      target="_blank"
                      :href="timelineEntry.url"
                      style="text-decoration: none; color: unset;"
                    >
                      <v-row
                        no-gutters
                        class="pl-3 pt-0 align-baseline"
                      >
                        <v-col
                          cols="auto"
                          class="d-flex text-right justify-end pr-3"
                        >
                          <span class="text-right">
                            <strong>
                              <!--
                              I did add some options to ensure that the month is displayed in 2-digit style.
                              For this I also had to specify which locale to use.
                              As we do not want this to be set by us but by the preferred language of the user i set it to undefined.
                              This is backed by the Documentation:
                              https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locale_identification_and_negotiation -->
                              {{ new Date(timelineEntry.date).toLocaleDateString(undefined, {year: 'numeric', month: '2-digit', day: 'numeric' }) }}
                            </strong>
                          </span>
                        </v-col>
                        <v-col cols="auto">
                          <div class="text-caption text-left">
                            {{ getTranslation(timelineEntry.eventTranslations, 'event', currentLanguage) }}
                            <v-icon
                              v-if="timelineEntry.url != null && timelineEntry.url !== ''"
                              right
                              small
                            >
                              mdi-open-in-new
                            </v-icon>
                          </div>
                        </v-col>
                      </v-row>
                    </a>
                  </v-timeline-item>
                </v-timeline>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <div
        v-if="bottomDescription != null && bottomDescription !== ''"
        class="d-flex flex-column justify-start align-start text-left ml-auto mr-auto text-body-1 pt-8 px-0 pl-md-0 pr-md-0"
        v-html="bottomDescription"
      />

      <v-row
        flat
        color="background"
        class="d-flex pa-0 py-4 ma-0"
      >
        <!--        <v-btn-->
        <!--          v-if="showPayNow"-->
        <!--          rounded-->
        <!--          outlined-->
        <!--          elevation="0"-->
        <!--          class="mr-0 mt-4"-->
        <!--          :loading="payNowLoading"-->
        <!--          @click="payNow"-->
        <!--        >-->
        <!--          <v-icon left>-->
        <!--            mdi-credit-card-->
        <!--          </v-icon>-->
        <!--          {{ $t('paymentHandling.payNow') }}-->
        <!--        </v-btn>-->
        <v-spacer />
        <v-btn
          v-if="loaded && showCancelSubmissionComputed"
          rounded
          outlined
          elevation="0"
          class="mr-0 mt-4"
          :loading="cancellingSubmission"
          @click="cancelSubmission"
        >
          <v-icon
            left
          >
            mdi-close
          </v-icon>
          {{ $t('paymentHandling.cancelSubmission') }}
        </v-btn>
        <v-btn
          v-if="loaded
            && !tasting.tastingSettings.hasPurchasables
            && (ciderStatus === 'payment_success' || ciderStatus === 'request_refund')
            && !tasting.resultsPublished"
          :disabled="ciderStatus !== 'payment_success'"
          rounded
          outlined
          elevation="0"
          class="mr-0 mt-4"
          :loading="loadingRefund"
          @click="requestRefund"
        >
          <v-icon
            left
          >
            mdi-credit-card-refund
          </v-icon>
          {{ ciderStatus === 'payment_success' ? $t('requestRefund') : $t('requestedRefund') }}
        </v-btn>
      </v-row>

      <div
        v-if="showCheckoutSummary"
        class="px-0 ml-auto"
        style="max-width: 600px;"
      >
        <v-card-title class="pb-1 pl-0 text-h5 text-break text-left pr-0">
          {{ $t('paymentHandling.checkoutSummary') }}
          <v-spacer />
          <v-chip
            small
            class="ml-auto"
            :color="ciderStatusColor"
          >
            <v-icon
              small
              left
            >
              mdi-credit-card-clock-outline
            </v-icon>
            {{ ciderStatusText }}
          </v-chip>
        </v-card-title>
        <v-divider class="pb-3" />
        <checkout-prices-summary
          :ciders="ciders"
          :taxes="tasting.tastingPrices.taxes"
          :currency="tasting.tastingPrices.currency"
          :submission-fee="tasting.tastingPrices.submissionFee"
          :price-per-cider="tasting.tastingPrices.pricePerSubmission"
        />
      </div>


      <div v-if="showPayNow">
        <v-alert
          border="left"
          rounded
          text
          type="warning"
          icon="mdi-calendar-clock"
        >
          <v-row
            no-gutters
          >
            <v-col
              class="text-left text-body-1"
              cols="12"
              xs="12"
              sm="8"
            >
              <div class="pb-3">
                {{ $t('notPayed', { deadline: new Date(tasting.einreichenBis).toLocaleString() }) }}
              </div>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="4"
              class="d-flex justify-end"
            >
              <v-btn
                v-if="showPayNowButton"
                rounded
                outlined
                elevation="0"
                color="primary darken-2"
                class="ml-auto my-auto"
                :loading="payNowLoading"
                @click="payNow"
              >
                <v-icon left>
                  mdi-credit-card
                </v-icon>
                {{ $t('paymentHandling.payNow') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </div>

      <div
        class="ml-auto mr-auto"
      >
        <div
          v-if="ciders != null"
          class="d-flex text-left text-h2 font-weight-medium align-center justify-start mb-3 pt-8"
          style="font-size: 36px !important; font-family: 'Nunito Sans', serif !important;"
        >
          {{ $t('paymentHandling.submittedProducts') }} ({{ numberOfAwards - placeholderProducts.length }}{{ placeholderProducts.length > 0 ? ' / ' + numberOfAwards : '' }})
        </div>
      </div>
      <div
        v-if="tasting.tastingSettings.allowProductDataUpdate
          && tastingPurchases.length > 0
          && numberOfAwards > 0
          && new Date(tasting.einreichenBis) > Date.now()
        "
        class="d-flex justify-end py-3"
      >
        <v-btn
          rounded
          outlined
          elevation="0"
          color="text_std lighten-3"
          class="ml-auto my-auto"
          @click="updateData()"
        >
          <v-icon left>
            mdi-update
          </v-icon>
          {{ $t('updateProductData') }}
        </v-btn>
      </div>
      <v-row
        v-if="loaded"
        no-gutters
        style="max-width: 1800px;"
        justify="center"
        class="ml-auto mr-auto"
      >
        <v-col
          cols="12"
          xs="12"
          align-self="center"
        >
          <div v-if="loaded && ciders != null && ciders.length > 0">
            <v-row
              class="d-flex"
              style="row-gap: 8px;"
            >
              <v-col
                v-for="cider in ciders"
                :key="cider.id"
                cols="12"
                sm="12"
                md="6"
                lg="6"
                xl="4"
                style="cursor: pointer;"
              >
                <v-hover
                  v-slot="{ hover }"
                >
                  <div
                    style="height: 100%;"
                    @click="$router.push(`/producer/ciders/view/${cider.id}`)"
                  >
                    <cider-card
                      :cider="cider"
                      :elevation="hover ? 10 : 1"
                      :select-revision="tasting.id"
                      full-content
                      :show-edit="false"
                    />
                  </div>
                </v-hover>
              </v-col>
            </v-row>
          </div>
          <div v-if="productDataNeeded">
            <v-alert
              border="left"
              rounded
              text
              type="warning"
              class="my-6"
              icon="mdi-calendar-clock"
            >
              <v-row
                no-gutters
              >
                <v-col
                  class="text-left text-body-1"
                  cols="12"
                  xs="12"
                  sm="8"
                >
                  <div class="pb-3">
                    {{ $t('missingProductData',
                          {
                            deadline: new Date(tasting.einreichenBis).toLocaleString(),
                            amount: numberOfAwards,
                            products: $tc('products', numberOfAwards)
                          }) }}
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  class="d-flex justify-end"
                >
                  <v-btn
                    v-if="new Date(tasting.einreichenBis) > Date.now()"
                    rounded
                    outlined
                    elevation="0"
                    color="text_std lighten-3"
                    class="ml-auto my-auto"
                    @click="updateData()"
                  >
                    <v-icon left>
                      mdi-basket-plus
                    </v-icon>
                    {{ $t('submitProductData') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
            <v-row>
              <v-col
                v-for="index in Math.min(placeholderProducts.length, numberOfAwards)"
                :key="index"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <v-card
                  outlined
                  class="mx-auto"
                  style="position: relative; border-style: dashed; border-width: medium; transition: box-shadow 0.2s ease-in-out;"
                  width="240"
                  height="320"
                >
                  <v-card-title>
                    Product {{ index }}
                  </v-card-title>
                  <v-icon
                    class="mx-auto center-on-card"
                    size="240"
                  >
                    mdi-bottle-wine
                  </v-icon>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <div
        v-if="tasting.tastingSettings.hasPurchasables && tastingPurchases.length > 0"
        class="d-flex text-left text-h2 font-weight-medium align-center justify-start mb-3 pt-8"
        style="font-size: 36px !important; font-family: 'Nunito Sans', serif !important;"
      >
        {{ $t('paymentHandling.bookedServices') }}
      </div>

      <div
        v-if="tasting.tastingSettings.hasPurchasables && tastingPurchases.length > 0"
        class="text-body-2 text--secondary text-left pb-1"
      >
        {{ $t('orderHistory') }}
      </div>
      <template v-if="!isSmallScreen && tastingPurchases.length > 0">
        <v-tabs
          v-model="purchaseTab"
          align-with-title
          background-color="background"
          show-arrows
        >
          <v-tab
            v-for="purchase in tastingPurchases"
            :key="'purchaseTab_' + purchase.id"
            style="text-transform: unset !important;"
          >
            <div class="d-flex align-center">
              {{ purchase.dateCreated.toLocaleString() }}
              <v-chip
                small
                :color="statusColor(purchase.status)"
                class="pr-2 ml-2"
              >
                <v-icon
                  x-small
                  left
                >
                  {{ statusIcon(purchase.status) }}
                </v-icon>
                {{ statusText(purchase.status) }}
              </v-chip>
            </div>
          </v-tab>
        </v-tabs>
      </template>
      <v-select
        v-if="isSmallScreen && tastingPurchases.length > 0"
        v-model="purchaseTab"
        outlined
        flat
        class="d-flex align-center"
        :items="tastingPurchases"
        style="max-width: 100vw;"
        :item-value="item => tastingPurchases.indexOf(item)"
        label=""
        rounded
        hide-details
      >
        <template #item="{ item }">
          {{ item.dateCreated.toLocaleString() }}
          <v-chip
            small
            :color="statusColor(item.status)"
            class="pr-2 ml-2"
          >
            <v-icon
              small
              left
            >
              {{ statusIcon(item.status) }}
            </v-icon>
            {{ statusText(item.status) }}
          </v-chip>
        </template>
        <template #selection="{item}">
          {{ item.dateCreated.toLocaleString() }}
          <v-chip
            small
            :color="statusColor(item.status)"
            class="pr-2 ml-2"
          >
            <v-icon
              small
              left
            >
              {{ statusIcon(item.status) }}
            </v-icon>
            {{ statusText(item.status) }}
          </v-chip>
        </template>
      </v-select>
      <v-tabs-items
        v-if="tastingPurchases.length > 0 && loaded"
        v-model="purchaseTab"
        touchless
      >
        <v-tab-item
          v-for="tastingPurchase in tastingPurchases"
          :key="`tastingPurchase_${tastingPurchase.id}`"
        >
          <v-row
            no-gutters
            style="max-width: 1800px;"
            justify="center"
            class="ml-auto mr-auto"
          >
            <v-col
              cols="12"
              xs="12"
              align-self="center"
            >
              <div>
                <div
                  class="d-flex py-3"
                  style="background-color: var(--v-background-base); row-gap: 4px;"
                  :class="{'flex-column': isSmallScreen}"
                >
                  <v-btn
                    :loading="loadingRefund"
                    :disabled="tastingPurchase.status === 'request_refund' || tastingPurchase.status === 'refunded'"
                    text
                    outlined
                    rounded
                    color="error"
                    @click="requestPurchaseRefund(tastingPurchase)"
                  >
                    <v-icon left>
                      mdi-credit-card-refund
                    </v-icon>
                    {{ tastingPurchase.status === 'request_refund' ? $t('requestedRefund') : $t('requestRefund') }}
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    v-if="tastingPurchase.publishedInvoice != null"
                    :loading="downloadButtonLoading"
                    text
                    outlined
                    rounded
                    @click="downloadInvoice(tastingPurchase.publishedInvoice)"
                  >
                    <v-icon left>
                      mdi-file-download-outline
                    </v-icon>
                    {{ $t('downloadInvoice') }}
                  </v-btn>
                </div>
                <v-row
                  class="d-flex"
                  style="row-gap: 8px;"
                >
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-card
                      outlined
                      elevation="0"
                      class="rounded-lg"
                    >
                      <v-data-table
                        :headers="tastingProductsHeaders"
                        :items="tastingProductItems(tastingPurchase)"
                        :mobile-breakpoint="1"
                        hide-default-footer
                        class="rounded-lg"
                      >
                        <template #item.name="{ item }">
                          <div class="d-flex flex-column justify-center align-start py-2">
                            <div class="text-left ">
                              {{ item.name }}
                            </div>
                            <div
                              class="text-left text--secondary no-p-margin-b"
                              v-html="item.description"
                            />
                          </div>
                        </template>

                        <template #body.append>
                          <tr class="no-border">
                            <td
                              class="text-right"
                              colspan="4"
                            >
                              <strong>{{ $t('subtotal') }}</strong>
                            </td>
                            <td
                              class="text-right"
                            >
                              <strong>
                                {{ formatCurrency(tastingPurchase.subtotalAmount, currentLanguage) }}
                              </strong>
                            </td>
                          </tr>
                          <tr class="no-border">
                            <td
                              class="text-right"
                              colspan="4"
                            >
                              {{ $t('vatAmount') }}
                            </td>
                            <td
                              class="text-right"
                            >
                              <strong>
                                {{ formatCurrency(tastingPurchase.totalAmount - tastingPurchase.subtotalAmount, currentLanguage) }}
                              </strong>
                            </td>
                          </tr>
                          <tr class="no-border">
                            <td
                              class="text-right"
                              colspan="4"
                            >
                              <strong>
                                {{ $t('totalAmount') }}
                              </strong>
                            </td>
                            <td
                              class="text-right"
                            >
                              <strong>
                                {{ formatCurrency(tastingPurchase.totalAmount, currentLanguage) }}
                              </strong>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>

      <div>
        <div
          class="partner-title text-left pl-0 pt-6 pb-0 ml-auto mr-auto"
        >
          Partners
        </div>
        <v-row
          class="mt-0 mb-0"
          justify="center"
        >
          <v-col
            v-for="(partner, index) in tasting.tastingInfos.partners"
            :key="`${index}_${partner.name}`"
            class="pt-1"
            style="max-width: 196px;"
          >
            <partner-avatar :partner="partner" />
          </v-col>
        </v-row>
      </div>

      <Submission_cancel_dialog ref="cancelSubmissionDialog" />
      <refund_dialog ref="refundDialog" />
    </template>
    <v-progress-circular
      v-if="!loaded"
      indeterminate
      class="mt-auto mb-auto ml-auto mr-auto"
      size="92"
    />
  </v-container>
</template>

<script>
import {apiTyped} from "@/api";
import CiderCard from "@/components/cider_card";
import refund_dialog from "@/components/producer/refund_dialog";
import CheckoutPricesSummary from "@/components/producer/checkout_prices_summary";
import Vue from "vue";
import PartnerAvatar from "@/components/partner_avatar";
import logos_mixin from "@/components/mixins/logos_mixin";
import translation_mixin from "@/components/mixins/translation_mixin";
import user_mixin from "@/components/mixins/user_mixin";
import {contrastingColor} from "@/util/helper";
import util from "@/util";
import PageNavigation from "@/components/page_navigation";
import i18n from "@/i18n";
import Submission_cancel_dialog from "@/components/producer/submission_cancel_dialog.vue";
import purchase_mixin from "@/components/mixins/purchase_mixin";

export default {
  name: "ProducerTastingSubmittedOverview",
  components: {
    Submission_cancel_dialog,
    PageNavigation, PartnerAvatar, CheckoutPricesSummary, refund_dialog, CiderCard
  },
  mixins: [logos_mixin, translation_mixin, user_mixin, translation_mixin, purchase_mixin],
  data: () => ({
    downloadButtonLoading: false,
    error: false,
    loaded: false,
    tasting: null,
    loadingRefund: false,
    submittedCiders: null,
    ciders: null,
    tastingPurchases: [],
    payNowLoading: false,
    cancellingSubmission: false,
    purchaseTab: 0,
    numberOfAwards: 0,
  }),
  i18n: {
    messages: {
      de: {
        "orderHistory": "Bestellverlauf",
        "notPayed": "Sie haben die Teilnahme am Event gebucht, aber noch nicht bezahlt. " +
          "Bitte holen sie die Zahlung bis spätestens {deadline} nach. Eine spätere Zahlung ist nicht möglich",
        "missingProductData": "Sie haben die Teilnahme von {amount} {products} am Event gebucht. " +
          "Bitte reichen sie die Produktdaten bis {deadline} nach. Eine spätere Einreichung ist nicht möglich",
        "submitProductData": "Produktdaten einreichen",
        "updateProductData": "Produktdaten aktualisieren"
      },
      en: {
        "orderHistory": "Order History",
        "notPayed": "You have booked participation in the event but have not yet paid. " +
          "Please make payment by {deadline} at the latest. Later payment is not possible",
        "missingProductData": "You have booked {amount} {products} for the award. " +
          "Please submit the product data by {deadline}. A later submission is not possible",
        "submitProductData": "Submit Product Data",
      }
    }
  },
  computed: {
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return true
        default:
          return false
      }
    },
    showPayNowButton() {
      return this.tasting.tastingSettings.hasOnlinePayment;
    },
    showPayNow() {
      return this.ciderStatus === 'draft'
        && this.tasting.tastingSettings.submissionNeedsPayment === false
        && this.tasting.tastingSettings.allowProductDataUpdate
        && ((this.tastingPurchases.length > 0 && this.tastingPurchases.some(t => t.status !== 'paid'))
            || this.tastingPurchases.length === 0);
    },
    productDataNeeded() {
      return this.numberOfAwards > 0
        && this.tasting.tastingSettings.allowProductDataUpdate
        && this.submittedCiders.some(c => c.productRevision == null && c.cider == null);
    },
    tastingProductsHeaders() {
      return [
        { text: '#', value: 'position', align: 'start', sortable: true, groupable: false, width: 16 },
        { text: i18n.t('name'), value: 'name', align: 'start', sortable: true, groupable: false, width: 300 },
        { text: i18n.t('quantity'), value: 'quantity', align: 'center', sortable: true, groupable: false, width: 100 },
        { text: i18n.t('unitPrice'), value: 'unitPrice', align: 'end', sortable: true, groupable: false, width: 120 },
        { text: i18n.t('amount'), value: 'amount', align: 'end', sortable: true, groupable: false, width: 100 },
      ]
    },
    placeholderProducts() {
      return this.submittedCiders.filter(c => c.productRevision == null && c.cider == null);
    },
    showCheckoutSummary() {
      return this.ciders != null
        && !this.tasting.tastingSettings.submissionNeedsPayment
        && !this.tasting.tastingSettings.hasPurchasables;
    },
    showCancelSubmissionComputed() {
      return (!this.tasting.tastingSettings.hasPurchasables
          && this.submittedCiders.length > 0
          && this.submittedCiders.every(s => s.status === 'draft' || s.status === 'unpaid')
          && this.tasting.datum.setDate(this.tasting.datum.getDate() - 1) > new Date(Date.now()))
    },
    topDescription() {
      return this.getTranslation(this.tasting.tastingInfos.translations, 'top_description', this.currentLanguage, null);
    },
    bottomDescription() {
      return this.getTranslation(this.tasting.tastingInfos.translations, 'bottom_description', this.currentLanguage, null);
    },
    ciderStatus() {
      return this.submittedCiders != null && this.submittedCiders.length > 0 ? this.submittedCiders[0].status : '';
    },
    ciderStatusText() {
      if (this.ciderStatus === "draft" || this.ciderStatus === "unpaid") return this.capitalizeFirstLetter("Unpaid");
      if (this.ciderStatus === "payment_success") return "Paid";
      if (this.ciderStatus === "request_refund") return "Requested Refund";
      return this.capitalizeFirstLetter(this.ciderStatus);
    },
    ciderStatusColor() {
      if (this.ciderStatus === "payment_success" || this.ciderStatus === "refunded") return "primary";
      else return "error";
    }
  },
  async created() {
    const tastings = await apiTyped.producerGetTastingsByIds(
      0,1,1,[Number(this.$route.params.tastingId)], true);
    if (tastings.length === 1) {
      this.tasting = tastings[0];
    } else {
      this.error = true;
    }
    this.submittedCiders = await apiTyped.getSubmittedCidersForTasting(Number(this.$route.params.tastingId));
    if (this.submittedCiders.length > 0) {
      this.ciders = await apiTyped.producerGetAllCiders(
        this.$store.state.producerStore.producerId,
        0, 1, -1,
        {id: {_in: this.submittedCiders.map(c => c.cider)}}
      );
    } else {
      this.ciders = null;
    }

    if (this.tasting.tastingSettings.hasPurchasables) {
      this.tastingPurchases = await apiTyped.producerGetTastingPurchases(this.tasting.id) || [];
      if (this.tastingPurchases.length === 0 && this.submittedCiders.length > 0) {
        // error handling, no purchases but submitted ciders. DO not show them to the producer!
        this.submittedCiders = [];
        this.ciders = [];
      }
      console.log("tastingPurchases", this.tastingPurchases)
      this.numberOfAwards = this.getNumberOfAwards(this.tastingPurchases);
      if (this.numberOfAwards !== this.submittedCiders.length) {
        // create placeholder ciders to display
        const insertCount = this.numberOfAwards - this.submittedCiders.length;
        for (let i = 0; i < insertCount; i++) {
          this.submittedCiders.push({
            cider: null,
            ciderRevision: null,
            productRevision: null,
            id: -i,
            tasting: this.tasting.id
          })
        }
      }
    }

    this.loaded = true;
  },
  methods: {
    async downloadInvoice(invoice) {
      this.downloadButtonLoading = true;
      let fileUrl = apiTyped.getFile(invoice.invoicePrintId, true);
      const name = `${this.$t('invoice')}-${invoice.invoiceNumber}`;
      let fileDownloadName = `${name.replace(/[^a-zA-Z0-9]/g, '-').replace(/-+/g, '-')}`;
      const blob = await fetch(fileUrl).then(res => res.blob());
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileDownloadName + ".pdf";
      document.body.appendChild(link);
      link.click();
      this.downloadButtonLoading = false;
    },
    tastingProductItems(tastingPurchase) {
      if (tastingPurchase == null) return [];
      return tastingPurchase.purchasedProducts.map(purchasedProduct => {
        return {
          name: this.getTranslation(purchasedProduct.tastingProduct.translations, 'name'),
          description: this.getTranslation(purchasedProduct.tastingProduct.translations, 'description'),
          quantity: purchasedProduct.amount,
          unitPrice: this.formatCurrency(purchasedProduct.productPrice.unitPrice, this.currentLanguage),
          amount: this.formatCurrency(
            purchasedProduct.productPrice.unitPrice * purchasedProduct.amount, this.currentLanguage),
          position: purchasedProduct.position,
        }
      })
    },
    async updateData() {
      this.$store.commit("submitStore/hydrate", {
        tasting: this.tasting,
        ciders: this.submittedCiders,
        tastingProducts: [],
        tastingPurchaseId: null,
        tastingPurchase: null,
        submitProductsWithoutData: false,
        selectedProductAmount: this.submittedCiders.length
      });
      if (this.tasting.tastingSettings.hasPurchasables) {
        await this.$router.push('/producer/submit-data/select-products');
      } else {
        await this.$router.push('/producer/submit/selectCiders');
      }
    },
    async payNow() {
      this.payNowLoading = true;
      let ciders = [];
      if (!this.tasting.tastingSettings.allowProductDataUpdate) {
        ciders = await apiTyped.producerGetCiderByIds(
          this.$store.state.producerStore.producerId, this.submittedCiders.map(c => Number(c.cider)), true);
        if (ciders == null)
          return this.payNowLoading = false;
      }
      this.$store.commit("submitStore/hydrate", {
        tasting: this.tasting,
        ciders: ciders,
        tastingProducts: [],
        tastingPurchase: this.tastingPurchase,
        submitProductsWithoutData: false,
        selectedProductAmount: this.submittedCiders.length
      });
      if (this.tasting.tastingSettings.hasPurchasables) {
        await this.$router.push('/producer/submit/selectTastingProducts');
      } else {
        await this.$router.push('/producer/submit/summary');
      }
    },
    roundToDec(amount, dec) {
      return util.util.round_to_decimal(amount, dec);
    },
    contrastColor(color) {
      if (color == null || color === "") return '';
      return "#" + contrastingColor(color);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async cancelSubmission() {
      this.cancellingSubmission = true;
      const cancelSubmission = await this.$refs.cancelSubmissionDialog.open(this.tasting, this.submittedCiders)
      if (cancelSubmission === false) {
        this.cancellingSubmission = false;
        return;
      }
      await apiTyped.deleteSubmittedCidersForTasting(this.submittedCiders);
      const tastings = await apiTyped.producerGetTastingsByIds(
        0,1,1,[Number(this.$route.params.tastingId)], true);
      if (tastings.length === 1) {
        this.tasting = tastings[0];
      } else {
        this.error = true;
      }
      this.submittedCiders = await apiTyped.getSubmittedCidersForTasting(Number(this.$route.params.tastingId));
      Vue.set(this, 'ciders', null);
      this.cancellingSubmission = false;
    },
    async requestPurchaseRefund(purchase) {
      this.loadingRefund = true;
      const refundComment = await this.$refs.refundDialog.open(this.tasting, [], purchase);
      console.log("Got refund comment", refundComment);
      if (refundComment) {
        await apiTyped.producerRefundTastingPurchases(purchase.id);
        purchase.status = "request_refund";
      }
      this.loadingRefund = false;
    },
    async requestRefund() {
      const refundComment = await this.$refs.refundDialog.open(this.tasting, this.ciders);
      if (refundComment === false) {
        return;
      }
      this.loadingRefund = true;
      await apiTyped.updateSubmittedCidersStatus(this.submittedCiders, 'request_refund', String(refundComment));
      // TODO handle failure
      this.submittedCiders = await apiTyped.getSubmittedCidersForTasting(Number(this.$route.params.tastingId));
      this.loadingRefund = false;
    },
    async startNewSubmissionAfterRefund() {
      await apiTyped.deleteSubmittedCidersForTasting(this.submittedCiders);
      const tastings = await apiTyped.producerGetTastingsByIds(
        0,1,1,[Number(this.$route.params.tastingId)], true);
      if (tastings.length === 1) {
        this.tasting = tastings[0];
      } else {
        this.error = true;
      }
      this.submittedCiders = await apiTyped.getSubmittedCidersForTasting(Number(this.$route.params.tastingId));
      Vue.set(this, 'ciders', null);
    },
  }
}
</script>

<style scoped>
/deep/ .no-p-margin-b > p {
  margin-bottom: 0 !important;
}

/deep/ .v-data-table {
  background-color: var(--v-card_background-base) !important;
}

.no-border {
  border: none !important;
  border-collapse: collapse !important;
  max-height: 20px !important;
  height: 20px !important;
}

.logo-small-screen {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: -150px;
}

.logo-bigger-screen {
  position: absolute;
  left: 75%;
  top: -150px;
}
</style>