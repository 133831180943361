<template>
  <v-row
    v-if="user != null && easterEggActive != null"
    justify="center"
    class="no_scrollbar"
  >
    <v-dialog
      v-model="dialog"
      persistent
      class="no_scrollbar"
      max-width="450"
    >
      <v-card
        rounded="xl"
        :outlined="$vuetify.theme.dark"
        style="border-color: var(--v-divider-base) !important"
        color="background"
        class="no_scrollbar"
      >
        <v-card-title
          class="text-h5 text-left"
          style="word-break: keep-all;"
        >
          <v-icon class="pr-1">
            mdi-egg-easter
          </v-icon>
          {{ user.userSettings.easterEggActive ? 'You did the thing again.' : 'You did a thing.' }}
        </v-card-title>
        <v-card-text
          v-if="!easterEggActive"
          class="text-left"
        >
          Easter egg found.<br>
          Do you want to brag about it by showing it to everybody?
        </v-card-text>
        <v-card-text
          v-if="easterEggActive"
          class="text-left"
        >
          Easter egg found again.<br>
          Do you want to stop bragging about it?
        </v-card-text>
        <v-card-text class="text-left font-weight-bold">
          This will be your new avatar:
        </v-card-text>
        <div
          v-if="easterEggActive === true"
          class="d-flex ml-auto mr-auto justify-center"
        >
          <juror-avatar
            class="d-flex"
            :user="user"
            show-easter-egg
          />
          <v-icon
            x-large
            class="pr-4 pl-4"
          >
            mdi-arrow-right
          </v-icon>
          <juror-avatar
            class="d-flex"
            :user="user"
            hide-easter-egg
          />
        </div>
        <div
          v-if="easterEggActive === false"
          class="d-flex ml-auto mr-auto justify-center"
        >
          <juror-avatar
            class="d-flex"
            :user="user"
            hide-easter-egg
          />
          <v-icon
            x-large
            class="pr-4 pl-4"
          >
            mdi-arrow-right
          </v-icon>
          <juror-avatar
            class="d-flex"
            :user="user"
            show-easter-egg
          />
        </div>
        <v-card-actions
          style="bottom: 0; background-color: var(--v-background-base);"
          :style="{'position': dialog ? 'sticky !important' : 'absolute',}"
        >
          <v-spacer />
          <v-btn
            color="error"
            text
            rounded
            @click.native="cancel"
          >
            Nope
          </v-btn>
          <v-btn
            color="primary"
            text
            rounded
            @click.native="agree"
          >
            Yes please
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import cider_mixin from "@/components/mixins/cider_mixin";
import user_mixin from "@/components/mixins/user_mixin";
import {apiTyped} from "@/api";
import JurorAvatar from "@/components/juror_avatar";

export default {
  name: "EasterEggModal",
  components: {JurorAvatar},
  mixins: [cider_mixin, user_mixin],
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    user: null,
    easterEggActive: null,
  }),
  computed: {
    avatar() {
      return apiTyped.getAvatarUrl(300, this.user, this.$vuetify.theme.currentTheme)
    },
  },
  methods: {
    open(user) {
      this.dialog = true;
      this.user = user;
      this.easterEggActive = user.userSettings.easterEggActive;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },
    agree() {
      if (this.user.userSettings.easterEggActive) this.resolve(false);
      else this.resolve(true);
      this.dialog = false;
      this.user = null;
      this.easterEggActive = null;
    },
    cancel() {
      if (this.user.userSettings.easterEggActive) this.resolve(true);
      else this.resolve(false);
      this.dialog = false;
      this.user = null;
      this.easterEggActive = null;
    },
  }
}
</script>

<style scoped>

</style>