<template>
  <v-sheet class="text-center pa-4">
    <div class="d-flex">
      <div class="text-h6 text-left pb-3">
        Add judges to {{ tasting.name }}
      </div>
      <v-spacer />
      <v-btn
        icon
        @click="$emit('close')"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </div>

    <table-designer-judge-card
      :user="me"
      :stats="{}"
    >
      <template #right>
        <v-btn
          outlined
          rounded
          x-small
          color="primary"
          class="mr-2"
          @click="addJudgeToTasting(me)"
        >
          Add
        </v-btn>
      </template>
    </table-designer-judge-card>
  </v-sheet>
</template>

<script>
import TableDesignerJudgeCard from "@/components/admin/tableDesigner/judge_card.vue";
import {sync} from "vuex-pathify";
import {apiTyped} from "@/api";

export default {
  name: "TableDesignerAddJudges",
  components: {TableDesignerJudgeCard},
  data: () => ({
    sheet: true,
    page: 1,
    searchText: "",
    sortBy: "name",
    sortByText: "Name",
    sortAsc: true,
  }),
  computed: {
    tasting: sync('tableDesignerStore/tasting'),
    me: sync('usersStore/me'),
    sortByList() {
      return [
        {
          text: "Name",
          value: 'name'
        },
        {
          text: this.$t('date'),
          value: 'datum'
        }
      ];
    },
  },
  methods: {
    async addJudgeToTasting(user) {
      console.log(user);
    }
  },
  async created() {
    const allJudges = apiTyped.getJudges(0, this.page, 10, {}, this.searchText, sortBy)
  }
}
</script>

<style scoped>

</style>