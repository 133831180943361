<template>
  <div class="print">
    <iframe
      id="iframe"
      style="display: none;"
    />
  </div>
</template>
<script>
export default {
  name: "PrintView",
  props: {},
  watch: {},
  methods: {
    setBodyHtml(html) {
      console.log("PRINT GOT", html)
      const document = window.document;
      const iframe = window.frames[0];
      iframe.document.head.innerHTML = document.head.innerHTML; // Get the head of the current document to IFRAME
      iframe.document.body.innerHTML = html; //  Put the HTML passing to the IFRAME head

      //  Pictures and style load completion
      Promise.all([this.loadStyle()]).then(res => {
        console.log('print',res);
        //      print
        iframe.window.print();
      });
    },
    loadStyle() {
      const iframe = window.frames[0];
      const styles = iframe.document.head.getElementsByTagName("style"); // <style>
      const links = iframe.document.head.getElementsByTagName("link"); // <link>
      let arrs = [];
      arrs = arrs.concat(...styles, ...links);
      console.log(arrs);
      return new Promise((resolve) => {
        for (let i = 0; i < arrs.length; i++) {
          arrs[i].onload = function() {
            if (i === arrs.length - 2) {
              console.log("STYLE style loading complete");
              resolve("STYLE style loading complete");
            }
          };
        }
      });
    },
      loadImage() {
      const iframe = window.frames[0];
      const imgs = iframe.document.body.getElementsByTagName("img"); // <img>
      return new Promise((resolve) => {
        for (let i = 0; i < imgs.length; i++) {
          console.log('image');
          imgs[i].onload = function() {
            if (i === imgs.length - 1) {
              console.log("IMG load completion");
              resolve("IMG load completion");
            }
          };
        }
      });
    }
  }
};
</script>