<template>
  <div
    class="d-flex flex-column"
  >
    <div
      class="d-flex justify-center align-center"
      style="transform-origin: 0 0;"
      :style="{'max-width': sizePx + 'px', 'min-width': sizePx + 'px',
               transform: sizePx < 4 * 96 ? `scale(${sizePx / (4 * 96)})` : 'scale(1)'}"
    >
      <v-btn-toggle
        v-model="tab"
        rounded
        class="elevation-1"
      >
        <v-btn
          style="min-width: 96px"
          @click="selectTab(0)"
        >
          <v-col cols="12">
            <v-row 
              width="100%"
              style="place-content: center"
            >
              <v-icon>mdi-file-document-outline</v-icon>
            </v-row>
            <v-row
              width="100%"
              style="place-content: center"
            >
              <span class="text-capitalize">Ratings</span>
            </v-row>
          </v-col>
        </v-btn>
        <v-btn
          style="min-width: 96px"
          @click="selectTab(1)"
        >
          <v-col cols="12">
            <v-row
              width="100%"
              style="place-content: center"
            >
              <v-icon>mdi-certificate-outline</v-icon>
            </v-row>
            <v-row
              width="100%"
              style="place-content: center"
            >
              <span class="text-capitalize">A4</span>
            </v-row>
          </v-col>
        </v-btn>
        <v-btn
          style="min-width: 96px"
          @click="selectTab(2)"
        >
          <v-col cols="12">
            <v-row
              width="100%"
              style="place-content: center"
            >
              <v-icon>
                mdi-certificate-outline
              </v-icon>
            </v-row>
            <v-row
              width="100%"
              style="place-content: center"
            >
              <span class="text-capitalize">A3</span>
            </v-row>
          </v-col>
        </v-btn>
        <v-btn
          style="min-width: 96px"
          @click="selectTab(3)"
        >
          <v-col cols="12">
            <v-row
              width="100%"
              style="place-content: center"
            >
              <v-icon>mdi-printer</v-icon>
            </v-row>
            <v-row
              width="100%"
              style="place-content: center"
            >
              <span class="text-capitalize">Rating</span>
            </v-row>
          </v-col>
        </v-btn>
      </v-btn-toggle>
    </div>

    <v-window
      v-model="tab"
      :touch="{left: () => {}, right: () => {}}"
      style="transform-origin: 0 0; width: 23cm; overflow: visible !important"
      :style="{transform: sizePx !== null ? `scale(${pageScale})` : 'scale(1)'}"
    >
      <v-window-item
        key="0"
        class="pa-4"
      >
        <v-checkbox
          v-model="showIndividualPoints"
          class="ma-0 pb-2 text-body-1"
          style="font-size: 16px !important; scale: 1 !important;"
          hide-details
          label="Show individual points"
        />
        <GesamtauswertungPage
          v-show="cider != null"
          id="page"
          ref="pagePDF"
          :cider="cider"
          :badges-data="badgesData"
          :best-of-category="bestOfCategory"
          :show-individual="showIndividualPoints"
          :badges-data-array="allBadges"
          :tasting="$store.getters['usersStore/currentTasting']()"
          :active-window="tab"
        />
      </v-window-item>
      <v-window-item
        key="1"
        class="pa-4"
      >
        <UrkundePageA4
          v-if="cider !== null"
          id="urkundePage"
          ref="urkundePDF"
          :cider="cider"
          :tasting="tasting"
          :badge-data="badgesData"
        />
      </v-window-item>
      <v-window-item
        key="2"
        class="pa-4"
      >
        <UrkundePageA3
          v-if="cider !== null"
          id="urkundePageA3"
          ref="urkundePDFA3"
          :cider="cider"
          :tasting="tasting"
        />
      </v-window-item>
      <v-window-item
        key="3"
        class="pa-4"
      >
        <manual-rating-judge
          v-if="cider !== null"
          :product="cider"
          :tasting="tasting"
          :badges-data-array="allBadges"
        />
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import GesamtauswertungPage from "@/components/admin/gesamtauswertung/gesamtauswertung_page";
import UrkundePageA4 from "@/components/admin/urkunde/urkunde_page_a4";
import UrkundePageA3 from "@/components/admin/urkunde/urkunde_page_a3";
import cider_mixin from "@/components/mixins/cider_mixin";
import ManualRatingJudge from "@/components/admin/manualRating/manual_rating_judge.vue";

export default {
  name: "ReportTabView",
  components: {
    ManualRatingJudge,
    UrkundePageA3,
    UrkundePageA4,
    GesamtauswertungPage,
  },
  mixins: [cider_mixin],
  props: {
    cider: {type: Object, required: true, default: () => null},
    tasting: {type: Object, required: true, default: () => null},
    showIndividual: {type: Boolean, required: true, default: true},
    bestOfCategory: {type: Boolean, required: false, default: false},
    sizePx: {type: Number, required: false, default: null},
  },
  data: () => ({
    tab: 0,
    pageScale: 1,
    pageMaxSizeCm: 21,
    showIndividualPoints: true,
  }),
  computed: {
    allBadges() {
      return this.$store.state.badgesStore.badgesData;
    },
    badgesData() {
      // return this.cider.badgeData;
      return this.$store.getters['badgesStore/getBadgeData'](this.calculateTotalPointsForCider(this.cider, true, false, 0.5));
    },
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        default: return false
      }
    },
  },
  watch: {
    tab: function(newTabNr) {
      this.$emit('reportChange', newTabNr);
    },
    sizePx: function (newValue) {
      console.log("Resize got size", this.sizePx, );
      const padding = this.isSmallScreen ? 32 : 32 * 2
      const availableCm = (newValue - padding /* Padding */) * 0.026458;
      // console.log("resize scale", availableCm > 21 ? 1 : availableCm / 21)
      this.pageScale = availableCm > this.pageMaxSizeCm ? 1 : availableCm / this.pageMaxSizeCm;
      this.$emit('pageScale', this.pageScale);
    },
  },
  methods: {
    selectTab(tabNr) {
      this.tab = tabNr;
      switch (tabNr) {
        case 0:
        case 1:
          this.pageMaxSizeCm = 21;
          this.$emit('pageScale', this.pageScale);
          break;
        case 2:
          this.pageMaxSizeCm = 29.7;
          this.$emit('pageScale', this.pageScale);
          break;
        case 3:
          this.pageMaxSizeCm = 29.7;
          this.$emit('pageScale', this.pageScale);
          break;
        default:
          this.pageMaxSizeCm = 21;
          this.$emit('pageScale', this.pageScale);
          break;
      }
      // this.$emit('pageSize', this.pageMaxSizeCm);
    }
  }
}
</script>

<style scoped>
.btn_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 8px;
  padding-right: 8px;
  line-height: 100%;
}
</style>