<template>
  <div>
    <div class="parent_grid">
      <div class="header_text pa-2 d-flex justify-end align-end pr-4">
        {{ $t('pruefmerkmal') }}
      </div>
      <div class="header_text pa-2 pl-0 d-flex justify-start align-end">
        {{ $t('eigenschaft') }}
        <v-spacer />
        {{ $tc('point', 2) }}
        <div style="padding-right: 92px" />
      </div>
      <div
        class="header_text_vertical d-flex pa-2 justify-end pa-0 align-end"
      >
        {{ $t('weighting') }}
      </div>
      <div
        class="header_text_vertical d-flex align-center pa-2"
      >
        {{ $t('result') }}
      </div>
    </div>
    <v-divider
      style="border-width: 1px !important; border-color: var(--v-divider_light-base);
             background-color: var(--v-divider_light-base)"
    />
  </div>
</template>

<script>
export default {
  name: "ManualRatingJudgeTableHeader",
}
</script>

<style scoped>
.parent_grid {
    grid-template-rows: 1fr;
    grid-auto-rows: minmax(min-content, max-content) !important;
}
.header_text {
    grid-column: auto;
    font-weight: bold;
}
.header_text_vertical {
    grid-column: auto;
    font-weight: bold;
    writing-mode: vertical-rl; /* vertical text */
    overflow: visible;
    justify-content: end;
}

</style>