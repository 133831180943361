import {Tasting} from "@/types/tasting";
import {createTranslationMessagesFromDb, TranslationMessages} from "@/types/translationMessages";
import {createTastingProductPrice, TastingProductPrice} from "@/types/tastingProductPrice";
import {MyCollections} from "@/api/types/my-collections";

export interface TastingProduct {
    id: number;
    availableAmount: number;
    isLimited: boolean;
    name: string;
    description: string;
    type: string;
    stripeProduct: string;
    defaultPrice: TastingProductPrice | null;
    tastings: Tasting[] | null;
    translations: TranslationMessages | null;
    excludesAward: boolean;
    includesAwards: number;
}

export function createTastingProduct(tastingProduct: MyCollections["tasting_products"]) {
    return <TastingProduct>{
        id: tastingProduct.id,
        availableAmount: tastingProduct.available_amount,
        isLimited: tastingProduct.is_limited || false,
        name: tastingProduct.name,
        description: tastingProduct.description,
        type: tastingProduct.type,
        stripeProduct: tastingProduct.stripe_product,
        defaultPrice: typeof tastingProduct.default_price !== "number"
            ? createTastingProductPrice(tastingProduct.default_price)
            : null,
        translations: createTranslationMessagesFromDb(tastingProduct.translations as any[]),
        excludesAward: tastingProduct.excludes_award || false,
        includesAwards: Number(tastingProduct.includes_awards || 0),
    }
}