<template>
  <div>
    <div class="parent_grid">
      <div class="header_text pa-2 d-flex justify-end align-end pr-4">
        {{ $t('pruefmerkmal') }}
      </div>
      <div class="header_text pa-2 pl-0 d-flex justify-start align-end">
        {{ $t('eigenschaft') }}
      </div>
      <div
        class="header_text_vertical d-flex pa-2 justify-end pa-0 align-end"
      >
        {{ $t('result') }}
      </div>
      <div
        class="header_text_vertical d-flex align-center pa-2"
      >
        {{ $t('maximum') }}
      </div>
    </div>
    <v-divider
      style="border-width: 1px !important; border-color: var(--v-divider_light-base);
             background-color: var(--v-divider_light-base)"
    />
    <div class="parent_grid">
      <div class="one_col" />
      <div
        class="pr-3 one_col"
        style="justify-self: flex-end;"
      >
        <v-expand-transition>
          <div
            v-if="showIndividualComputed"
            class="d-flex mb-0 flex-scroll header_left justify-end"
            style="flex: 1 0 100%; background-color:transparent;"
          >
            <template v-if="showHeaderNames">
              <v-card
                v-for="userId in Object.keys(cider.allBewertungen || {})"
                :key="userId"
                tile
                flat
                class="d-flex pa-2 align-self-center justify-center"
                style="min-width: 58px; background-color: white !important;"
                :style="{'border-right': 'solid ' + $vuetify.theme.currentTheme.divider_light + ' 1px'}"
              >
                <v-tooltip
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-list-item-avatar
                      v-bind="attrs"
                      class="ma-auto"
                      v-on="on"
                    >
                      <img
                        :src="getUserAvatar(200, getUserData(userId))"
                        alt="Avatar"
                      >
                    </v-list-item-avatar>
                  </template>
                  <span>
                    {{ getUserData(userId).firstName }}
                    {{ getUserData(userId).lastName }}
                  <!--                  <span-->
                  <!--                    class="comment justify-start text-left"-->
                  <!--                    v-html="cider.allNotes[userId] === '<p></p>' ? '<Kein Kommentar>' : cider.allNotes[userId]"-->
                  <!--                  />-->
                  </span>
                </v-tooltip>
              </v-card>
            </template>
          </div>
        </v-expand-transition>
      </div>
      <div class="one_col" />
      <div class="one_col" />
    </div>
  </div>
</template>

<script>
import user_mixin from "@/components/mixins/user_mixin";
import {sync} from "vuex-pathify";

export default {
  name: "GesamtauswertungTableHeader",
  mixins: [user_mixin],
  props: {
    cider: {type: Object, required: true, default: () => null},
    showIndividual: {type: Boolean, required: true, default: true},
  },
  computed: {
    tables: sync("tableStore/tables"),
    showHeaderNames() {
      return this.cider.allBewertungen != null;
    },
    showIndividualComputed() {
      if (this.showIndividual && (this.tables == null || this.tables.length === 0)) return false;
      return this.showIndividual;
    }
  },
  methods: {
    getUserData(userId) {
      if (this.tables == null || this.tables.length === 0) return {lastName: "", firstName: ""}
      const juror = this.tables?.flatMap(table => table.juroren).find(juror => String(juror.id) === String(userId));
      if (juror === undefined)
        return {lastName: "", firstName: ""}
      return juror;
    },
  },
}
</script>

<style scoped>
.parent_grid {
  grid-template-rows: 1fr;
  grid-auto-rows: minmax(min-content, max-content) !important;
}
.header_text {
  grid-column: auto;
  font-weight: bold;
}
.header_text_vertical {
  grid-column: auto;
  font-weight: bold;
  writing-mode: vertical-rl; /* vertical text */
  overflow: visible;
}
.one_col {
  grid-column: auto;
}
</style>