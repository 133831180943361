import { render, staticRenderFns } from "./manual_rating_judge_a4.vue?vue&type=template&id=6b3863fc&scoped=true&"
import script from "./manual_rating_judge_a4.vue?vue&type=script&lang=js&"
export * from "./manual_rating_judge_a4.vue?vue&type=script&lang=js&"
import style0 from "./manual_rating_judge_a4.vue?vue&type=style&index=0&id=6b3863fc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b3863fc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VBtn,VContainer,VIcon,VMain})
