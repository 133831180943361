export default {
  computed: {
    tastingProducts(){
      return [
        {
          name: this.$t('paymentHandling.addProduct'),
          type: "product_tasting",
          productId: 10,
          price: 190,
          priceDescription: this.$t('paymentHandling.addProduct'),
          description: this.$t('paymentHandling.priceAddProduct'),
        },
        {
          name: this.$t('paymentHandling.includedProduct'),
          type: "product_tasting",
          productId: 9,
          price: 0,
          priceDescription: this.$t('paymentHandling.addProduct'),
          description: this.$t('paymentHandling.includedProduct'),
        }
      ];
    },
    participationProducts() {
      return [
        {
          name: this.$t('paymentHandling.boothTypeNameSpotlight'),
          type: "spotlight",
          allowsAward: true,
          productId: 8,
          price: 350,
          priceDescription: "350",
          description: this.$t('paymentHandling.descriptionBoothSpotlight'),
          features: {
            products: 1,
            preview: true,
            award: 0,
            exkursion: 0,
            party: 0,
            table: this.$t('paymentHandling.productOnSpotlight'),
            seminar: 0,
            marketing: true,
          }
        },
        {
          name: this.$t('paymentHandling.boothTypeNameMerchandise'),
          type: "merch",
          allowsAward: false,
          productId: 7,
          price: 595,
          priceDescription: this.$t('paymentHandling.priceBoothMerch'),
          description: this.$t('paymentHandling.descriptionBoothMerch'),
          features: {
            products: 0,
            preview: false,
            award: 0,
            exkursion: 2,
            party: 2,
            table: this.$t('paymentHandling.boothSizeBooth'),
            seminar: 1,
            marketing: true,
          }
        },
        {
          name: this.$t('paymentHandling.boothTypeNameBooth'),
          type: "booth",
          allowsAward: true,
          productId: -1,
          price: -1,
          priceDescription: this.$t('paymentHandling.priceBoothBooth'),
          description: this.$t('paymentHandling.descriptionBoothBooth'),
          features: {
            products: 1,
            preview: true,
            award: 2,
            exkursion: 2,
            party: 2,
            table:  this.$t('paymentHandling.boothSizeBooth'),
            seminar: 1,
            marketing: true,
          }
        },
        {
          name: this.$t('paymentHandling.boothTypeNameCorporate'),
          type: "corporate",
          allowsAward: true,
          productId: -2,
          price: 730,
          priceDescription: this.$t('paymentHandling.priceBoothCorporate'),
          description: this.$t('paymentHandling.descriptionBoothCorporate'),
          features: {
            products: 1,
            preview: true,
            award: 2,
            exkursion: 2,
            party: 2,
            table:  this.$t('paymentHandling.boothSizeCorporate'),
            seminar: 1,
            marketing: true,
          }
        },
      ]
    },
    boothProducts() {
      return [
        {
          name: this.$t('paymentHandling.boothSizeNameBoothSmall'),
          productId: 6,
          type: "booth",
          excludesAward: false,
          price: 795,
          priceDescription: this.$t('paymentHandling.priceBoothSmall'),
          description: this.$t('paymentHandling.descriptionBoothSmall'),
          features: {
            products: 1,
            preview: true,
            award: 2,
            exkursion: 2,
            party: 2,
            table: this.$t('paymentHandling.boothSizeSmall'),
            seminar: 1,
            marketing: true,
          }
        },
        {
          name: this.$t('paymentHandling.boothSizeNameBoothMedium'),
          productId: 5,
          type: "booth",
          excludesAward: false,
          price: 935,
          priceDescription: this.$t('paymentHandling.priceBoothMedium'),
          description: this.$t('paymentHandling.descriptionBoothMedium'),
          features: {
            products: 1,
            preview: true,
            award: 2,
            exkursion: 2,
            party: 2,
            table: this.$t('paymentHandling.boothSizeMedium'),
            seminar: 1,
            marketing: true,
          }
        },
        {
          name: this.$t('paymentHandling.boothSizeNameBoothLarge'),
          productId: 3,
          type: "booth",
          excludesAward: false,
          price: 1230,
          priceDescription: this.$t('paymentHandling.priceBoothLarge'),
          description: this.$t('paymentHandling.descriptionBoothLarge'),
          features: {
            products: 1,
            preview: true,
            award: 2,
            exkursion: 2,
            party: 2,
            table: this.$t('paymentHandling.boothSizeLarge'),
            seminar: 1,
            marketing: true,
          }
        },
        {
          name: this.$t('paymentHandling.boothSizeNameBoothXLarge'),
          productId: 4,
          type: "booth",
          excludesAward: false,
          price: 1425,
          priceDescription: this.$t('paymentHandling.priceBoothXLarge'),
          description: this.$t('paymentHandling.descriptionBoothXLarge'),
          features: {
            products: 1,
            preview: true,
            award: 2,
            exkursion: 2,
            party: 2,
            table: this.$t('paymentHandling.boothSizeXLarge'),
            seminar: 1,
            marketing: true,
          }
        },
        // merch booths
        {
          name: this.$t('paymentHandling.boothSizeNameBoothSmall'),
          productId: 14,
          type: "merch",
          excludesAward: true,
          price: 795,
          priceDescription: this.$t('paymentHandling.priceMerchSmall'),
          description: this.$t('paymentHandling.descriptionMerchSmall'),
          features: {
            products: 0,
            preview: false,
            award: 0,
            exkursion: 2,
            party: 2,
            table: this.$t('paymentHandling.boothSizeSmall'),
            seminar: 1,
            marketing: true,
          }
        },
        {
          name: this.$t('paymentHandling.boothSizeNameBoothMedium'),
          productId: 13,
          type: "merch",
          excludesAward: true,
          price: 935,
          priceDescription: this.$t('paymentHandling.priceMerchMedium'),
          description: this.$t('paymentHandling.descriptionMerchMedium'),
          features: {
            products: 0,
            preview: false,
            award: 0,
            exkursion: 2,
            party: 2,
            table: this.$t('paymentHandling.boothSizeMedium'),
            seminar: 1,
            marketing: true,
          }
        },
        {
          name: this.$t('paymentHandling.boothSizeNameBoothLarge'),
          productId: 11,
          type: "merch",
          excludesAward: true,
          price: 1230,
          priceDescription: this.$t('paymentHandling.priceMerchLarge'),
          description: this.$t('paymentHandling.descriptionMerchLarge'),
          features: {
            products: 0,
            preview: false,
            award: 0,
            exkursion: 2,
            party: 2,
            table: this.$t('paymentHandling.boothSizeLarge'),
            seminar: 1,
            marketing: true,
          }
        },
        {
          name: this.$t('paymentHandling.boothSizeNameBoothXLarge'),
          productId: 12,
          type: "merch",
          excludesAward: true,
          price: 1225,
          priceDescription: this.$t('paymentHandling.priceMerchXLarge'),
          description: this.$t('paymentHandling.descriptionMerchXLarge'),
          features: {
            products: 0,
            preview: false,
            award: 0,
            exkursion: 2,
            party: 2,
            table: this.$t('paymentHandling.boothSizeXLarge'),
            seminar: 1,
            marketing: true,
          }
        }
      ]
    },
    extraProducts() {
      return [
        {
          name: this.$t('paymentHandling.extraNameMasterClass'),
          productId: 2,
          price: 95,
          priceDescription: this.$t('paymentHandling.priceExtraMasterClass'),
          description: this.$t('paymentHandling.descriptionExtraMasterClass'),
        },
        {
          name: this.$t('paymentHandling.extraNameBowl'),
          productId: 1,
          price: 35,
          priceDescription: this.$t('paymentHandling.priceExtraBowl'),
          description: this.$t('paymentHandling.descriptionExtraBowl'),
        }
      ]
    }
  }
}