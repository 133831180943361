<template>
  <v-app-bar
    v-if="isAuthenticated"
    app
    flat
    class="blur_effect_app_bar"
    :color="$vuetify.theme.currentTheme.app_bar"
  >
    <v-btn
      v-if="!tastingNotSelected"
      icon
      large
      class="ml-2 overflow-visible"
      @click="$router.push(allRoutes.tastings.my)"
    >
      <v-img
        :src="getImageUrl(currentTasting.logoMesse, 250)"
        class="overflow-visible"
        contain
        max-height="40"
        max-width="40"
      />
    </v-btn>
    <v-btn
      v-else
      icon
      class="ml-1"
    >
      <v-img
        contain
        :lazy-src="getLogoUrl('logo_app', 300)"
        :src="getLogoUrl('logo_app', 300)"
        alt="CiderAwardLogo"
        style="margin: auto;"
        max-height="32px"
        max-width="40px"
        @click="$router.push(allRoutes.tastings.my)"
      />
    </v-btn>

<!--    <dynamic-islands-container />-->

    <v-spacer />

    <!--    <v-tooltip bottom>-->
    <!--      <template v-slot:activator="{ on, attrs }">-->
    <!--        <v-btn-->
    <!--          icon-->
    <!--          v-bind="attrs"-->
    <!--          @click="test_fun"-->
    <!--          v-on="on"-->
    <!--        >-->
    <!--          <v-icon>mdi-test-tube</v-icon>-->
    <!--        </v-btn>-->
    <!--      </template>-->
    <!--      <span>Test</span>-->
    <!--    </v-tooltip>-->

    <!--    <v-tooltip bottom>-->
    <!--      <template #activator="{ on, attrs }">-->
    <!--        <v-btn-->
    <!--          id="appBarRefresh"-->
    <!--          icon-->
    <!--          v-bind="attrs"-->
    <!--          @click="reload_page"-->
    <!--          v-on="on"-->
    <!--        >-->
    <!--          <v-icon>mdi-refresh</v-icon>-->
    <!--        </v-btn>-->
    <!--      </template>-->
    <!--      <span>{{ $t('refreshContent') }}</span>-->
    <!--    </v-tooltip>-->

    <v-menu
      v-show="tischfunktionen != null && !tastingNotSelected"
      id="appBarTischfunktionen"
      v-model="showTableFunctions"
      class="pt-2"
      bottom
      rounded="pill"
      offset-y
      transition="slide-y-transition"
      :close-on-content-click="false"
      style="z-index: 99;"
    >
      <template #activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-badge
              v-show="$store.state.usersStore.currentTastingId !== -1"
              :value="sumTischfunktionen > 0 || nextCiderRequested"
              bordered
              color="error"
              :content="badgeTischfunktionen"
              overlap
              offset-x="28"
              offset-y="28"
              @click.native="showTableFunctions = !showTableFunctions"
            >
              <v-btn
                id="appBarTableFunctions"
                icon
                v-bind="attrs"
                class="ma-1 mr-0"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon>mdi-chat-question</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>{{ $t('tableFunctions') }}</span>
        </v-tooltip>
      </template>
      <v-sheet
        v-if="tischfunktionen !== null || tischfunktionen !== undefined"
        class="d-flex align-end justify-center pa-3 pr-6 pl-6"
        align="center"
        rounded
        style="border-radius: 1000px"
      >
        <div class="icon_text">
          <v-btn
            icon
            :outlined="!tableFunctionHand"
            style="border-width: 2px;"
            large
            :disabled="!tastingIsRunning"
            class="animate_change"
            :style="{'background-color': tableFunctionHand
                       ? tastingIsRunning
                         ? 'var(--v-primary-base)'
                         : 'var(--v-card_background-base)'
                       : tastingIsRunning
                         ? '#FFF' : 'var(--v-card_background-base)',
                     'color': tableFunctionHand ? '#FFF' : '#AAA'}"
            @click="updateTableFunction('melden', getNextCiderId())"
          >
            <v-icon>
              mdi-hand-back-left
            </v-icon>
          </v-btn>
          <span
            class="text-caption mt-1"
            style="line-height: 1;"
            v-html="$t('AppBar.notify')"
          />
        </div>
        <div 
          v-if="$store.getters['usersStore/isChair']()"
          class="icon_text"
        >
          <v-badge
            :value="getNextCiderId()"
            bottom
            left
            color="grey"
            :content="'#' + getNextCiderId()"
            overlap
            offset-x="44"
            offset-y="4"
          >
            <v-btn
              icon
              :outlined="!nextCiderRequested"
              style="border-width: 2px;"
              large
              :disabled="getNextCiderId() == null || !tastingIsRunning"
              class="animate_change"
              :style="{'background-color': !(getNextCiderId() == null || !tastingIsRunning)
                         ? nextCiderRequested ? 'var(--v-primary-base)' : '#FFF'
                         : 'var(--v-card_background-base)',
                       'color': nextCiderRequested ? '#FFF' : '#AAA'}"
              @click="updateNextCiderAppBar()"
            >
              <v-icon>
                mdi-glass-wine
              </v-icon>
            </v-btn>
          </v-badge>
          <span
            class="text-caption pt-2"
            style="line-height: 100%"
            :style="{color: getNextCiderId() === null
              ? $vuetify.theme.currentTheme.text_disabled
              : $vuetify.theme.currentTheme.text_std }"
          >
            <!--            <v-chip-->
            <!--              x-small-->
            <!--            >-->
            <!--              {{ '#' + getNextCiderId() }}-->
            <!--            </v-chip>-->
            <br v-if="getNextCiderId() !== null">
            <span v-html="$t('AppBar.nextCider')" />
          </span>
        </div>
        <div class="icon_text">
          <v-btn
            icon
            :outlined="!tableFunctionAsk"
            style="border-width: 2px;"
            large
            :disabled="!tastingIsRunning"
            class="animate_change"
            :style="{'background-color': tableFunctionAsk
                       ? tastingIsRunning
                         ? 'var(--v-primary-base)' : 'var(--v-card_background-base)'
                       : tastingIsRunning
                         ? '#FFF' : 'var(--v-card_background-base)',
                     'color': tableFunctionAsk ? '#FFF' : '#AAA'}"
            @click="updateTableFunction('frage')"
          >
            <v-icon class="pa-3">
              mdi-help-circle
            </v-icon>
          </v-btn>
          <span
            class="text-caption mt-1"
            style="line-height: 1;"
            v-html="$t('AppBar.question')"
          />
        </div>
      </v-sheet>
    </v-menu>

    <v-menu
      v-model="showUserMenu"
      class="pt-2"
      bottom
      left
      rounded="xl"
      offset-y
      transition="slide-y-transition"
      :close-on-content-click="false"
      style="z-index: 99;"
    >
      <template #activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn
              id="appBarUserMenu"
              text
              style="text-transform: unset !important;"
              class="ma-1 ml-0"
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
            >
              <div class="d-flex align-center">
<!--                <span class="text&#45;&#45;primary">-->
<!--                  {{ nameTruncated }} | -->
<!--                </span>-->
                <span class="text--secondary pt-1">
                  {{ $store.getters["usersStore/isChair"]() ? $t('chair') : $t('juror') }}
                  <v-icon
                    right
                    class="ml-0"
                  >
                    mdi-account-circle
                  </v-icon>
                </span>
              </div>
            </v-btn>
          </template>
          <span>{{ $t('account') }}</span>
        </v-tooltip>
      </template>
      <v-card
        ref="userCard"
        max-width="400"
        rounded="20"
      >
        <v-list
          class="pa-0"
          color="dialog_background"
        >
          <v-list-item>
            <theme-menu />
            <v-list-item-content>
              <v-list-item-title style="text-align: right">
                {{ user_info.firstName }} {{ user_info.lastName }}
              </v-list-item-title>
              <v-list-item-subtitle style="text-align: right">
                {{ $store.getters["usersStore/isChair"]() ? $t('chair') : $t('juror') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar>
              <img
                :src="getUserAvatar(200)"
                alt="Avatar"
              >
            </v-list-item-avatar>
          </v-list-item>
        </v-list>

        <v-divider style="background-color: var(--v-divider-base)" />

        <v-list
          color="dialog_background"
        >
          <v-list-item
            align="right"
            class="ma-0"
            style="transform: translateY(12px);"
          >
            <language-select />
          </v-list-item>

          <v-list-item
            link
            color="text_std"
            align="right"
            class="ma-0"
            @click="resetTutorials"
          >
            <v-list-item-icon class="ml-2 mr-4">
              <v-icon size="28">
                mdi-refresh
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="font-size: 16px;">

                <span class="mr-auto">{{ $t('AppBar.restartTutorial') }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
        <v-divider style="background-color: var(--v-divider-base) !important;" />
        <v-card-actions style="background-color: var(--v-dialog_background-base) !important">
          <!--          <v-btn-->
          <!--            color="red"-->
          <!--            text-->
          <!--            @click="clear_cookies"-->
          <!--          >-->
          <!--            Komplett neu laden-->
          <!--          </v-btn>-->
          <v-spacer />
          <v-btn
            color="primary"
            text
            rounded
            @click="logout"
          >
            {{ $t('logout') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <template
      v-if="in_rating_tab"
      #extension
      transition="scroll-y-transition"
    >
      <v-toolbar-title style="padding-left: 4px">
        {{ subtitle }}
      </v-toolbar-title>
      <v-fab-transition>
        <!--          <v-btn v-if="show_badge" color="transparent" fab dark absolute bottom right>-->
        <v-speed-dial
          v-if="show_badge"
          class="mt-8"
          style="top: -16px"
          color="transparent"
          absolute
          bottom
          right
          direction="bottom"
        >
          <template #activator>
            <v-btn
              color="transparent"
              dark
              fab
              class="mb-4"
            >
              <v-img
                :key="get_current_badge_url()"
                max-height="56px"
                max-width="56px"
                :lazy-src="get_current_badge_url()"
                :src="get_current_badge_url()"
                alt="Badge"
              />
            </v-btn>
          </template>
          <div class="pt-8" />
          <div
            v-for="badge in get_badges_data()"
            :key="badge.id"
            class=""
          >
            <v-tooltip
              disabled
              left
              eager
              nudge-left="24"
              :value="true"
            >
              <v-btn
                slot="activator"
                fab
                dark
                small
                color="transparent"
                :elevation="10"
                min-height="56px"
                min-width="56px"
              >
                <v-img
                  :key="badge.punkte_min"
                  max-height="56px"
                  max-width="56px"
                  :lazy-src="get_badge_url(badge.punkte_min)"
                  :src="get_badge_url(badge.punkte_min)"
                  alt="Badge"
                />
              </v-btn>
              <span>{{ badge.stufe }}: {{ badge.punkte_max }}-{{ badge.punkte_min }}</span>
            </v-tooltip>
            <br>
          </div>
        </v-speed-dial>
      </v-fab-transition>
    </template>

    <v-progress-linear
      :active="$store.state.app_bar_loading"
      :indeterminate="$store.state.app_bar_loading"
      absolute
      bottom
    />
  </v-app-bar>
</template>

<script>
import {apiTyped} from '@/api/index';
import Vue from "vue";
import store from "@/stores";
import {get, sync} from "vuex-pathify"
import {createTischfunktionFromDatabase} from "@/types/tischfunktion";
import Vuetify from "vuetify";
import user_mixin from "@/components/mixins/user_mixin";
import tischfunktionen_mixin from "@/components/mixins/tischfunktionen_mixin";
import logos_mixin from "@/components/mixins/logos_mixin";
import {setIntervalAsync} from "set-interval-async/fixed";
import {clearIntervalAsync} from "set-interval-async";
import cider_mixin from "@/components/mixins/cider_mixin";
import ThemeMenu from "@/components/theme_menu";
import LanguageSelect from "@/components/language_select";
import rating_mixin from "@/components/mixins/rating_mixin";
import DynamicIslandsContainer from "@/components/dynamic_islands_container";

Vue.use(Vuetify, {
  options: {
    customProperties: true
  }
})

const Component = Vue.extend(  {
  name: "AppBar",
  components: {
    LanguageSelect,
    ThemeMenu,
  },
  mixins: [user_mixin, tischfunktionen_mixin, logos_mixin, cider_mixin, rating_mixin],
  data: () => ({
    // showUserMenu: false,
    // showTableFunctions: false,
  }),
  computed: {
    tastingNotSelected() {
      return this.$store.state.usersStore.currentTastingId === -1;
    },
    nameTruncated() {
      const name = this.$store.getters["usersStore/fullName"]();
      if (name.length > 24) return this.$store.getters["usersStore/user"]().lastName;
      return name;
    },
    currentTasting() {
      return this.$store.getters["usersStore/currentTasting"]();
    },
    get_user_language: {
      get () {
        return this.$store.getters["usersStore/user"]().language;
      },
      async set (value) {
        await this.$store.dispatch("usersStore/setLanguage", value.code);
      },
    },
    showTableFunctions: sync("tableStore/showTableFunctions"),
    showUserMenu: sync("usersStore/showUserMenu"),
    nextCider() {
      return this.$store.getters['ciderStore/getNextCider']();
    },
    currentTableIdx: sync("tableStore/currentTable"),
    currentTable: sync('tableStore/tables@[:currentTableIdx]'),
    tischfunktionen: sync('tableStore/tables@[:currentTableIdx].myTischfunktionen'),
    tableFunctionAsk () {
      if (this.tischfunktionen === null || this.tischfunktionen === undefined) return false;
      return this.tischfunktionen.frage;
    },
    tableFunctionHand () {
      if (this.tischfunktionen === null || this.tischfunktionen === undefined) return false;
      return this.tischfunktionen.melden;
    },
    tableFunctionNext () {
      if (this.tischfunktionen === null || this.tischfunktionen === undefined) return false;
      return this.tischfunktionen.nextCiderId;
    },
    nextCiderRequested () {
      return this.nextCider?.requestCider || null;
    },
    sumTischfunktionen () {
      if (this.tischfunktionen === null || this.tischfunktionen === undefined) return 0;
      return this.tischfunktionen.melden + this.tischfunktionen.frage + Boolean(this.tischfunktionen.nextCiderId);
    },
    badgeTischfunktionen () {
      if (this.tischfunktionen === null || this.tischfunktionen === undefined) return 0;
      if (this.nextCiderRequested) return "#" + this.nextCider.displayId;
      return this.tischfunktionen.melden + this.tischfunktionen.frage
    },
    isAuthenticated: get("appStore/authenticated"),
    get_minimal_badge_points () {
      return store.get("get_minimal_badge_points");
    },
    get_languages () {
      // const languages_list = this.$store.state.languages.map(entry => (entry.name.concat(" (", entry.code, ")") ));
      console.log("Lang list.", this.$store.state.languages, "Selected:", this.$store.state.user_language);
      return this.$store.state.languages;
    },
    user_info () {
      return this.$store.getters["usersStore/user"]();
    },
    // title () {
    //   return store.get("app_bar_title");
    // },
    // subtitle() {
    //   return store.get("app_bar_subtitle");
    // },
    in_rating_tab () {
      return false;  // TODO
      // return this.$store.state.active_tab >= 1;
    },
    show_badge () {
      return this.$store.getters.show_badge();
    },
    // get_current_cider() {
    //   return this.$store.getters.current_cider();
    // },
    // has_current_cider() {
    //   console.log("HAVING CURRENT CIDER:", this.get_current_cider, (this.get_current_cider !== null && this.get_current_cider !== undefined));
    //   return (this.get_current_cider !== null && this.get_current_cider !== undefined);
    // },
    // is_favourite() {
    //   if (!this.has_current_cider) return false;
    //   return this.$store.get('current_cider').is_favourite;
    // },
    // is_pinned() {
    //   if (!this.has_current_cider) return false;
    //   return this.$store.get('current_cider').is_pinned;
    // },
    loadingData() {
      return this.$store.state.app_bar_loading;
    },
  },
  async mounted() {
    // this.$root.$on('updateNextCider', () => {
    //   this.updateNextCiderAppBar()
    // });
    if (this.$store.getters['usersStore/currentTasting']() != null)
      await this.getTischfunktionen()
    // this.intervalUpdateData = setIntervalAsync(this.getTischfunktionen, 8000);
    // // Update tischfunktionen next Cider ID if null or undefined:
    // const nextCider = this.$store.getters["ciderStore/getNextCider"]();
    // if (nextCider === undefined || nextCider === null) this.updateNextCiderAppBar()
  },
  async beforeDestroy() {
    // await clearIntervalAsync(this.intervalUpdateData);
  },
  methods: {
    async getTischfunktionen() {
      const newTischfunktionen = await apiTyped.getTischfunktionen(
          [this.currentTable?.id], this.currentTable.juroren.map(juror => juror.id));

      if (this.nextCider != null) {
        const requestedCider = (await apiTyped.getRequestedCiders(this.nextCider.flightId))?.find(
          c => c.id === this.nextCider.flightsCidersId);
        this.nextCider.requestCider = requestedCider.request_cider;
      }
      if (newTischfunktionen.find(
          tischfunktion => tischfunktion.userId === this.$store.getters["usersStore/userId"]()) == null) {
        // Create Tischfunktionen Entry for logged in juror:
        console.log("Creating Tischfunktion for Juror (me)")
        const newTischfunktion = await apiTyped.createTischfunktionen(
            [this.currentTable.id], [this.$store.getters["usersStore/userId"]()])
        if (newTischfunktion !== undefined) newTischfunktionen.push(createTischfunktionFromDatabase(newTischfunktion[0]));
      }
      if (newTischfunktionen !== undefined) {
        const myIdx =  newTischfunktionen.findIndex(
            tischfunktion => tischfunktion.userId === this.$store.getters["usersStore/userId"]());
        const myTischfunktion = newTischfunktionen.splice(myIdx, 1);
        const idx = this.$store.state.tableStore.currentTable;
        this.$store.set('tableStore/tables@['+idx+'].newTischfunktionen', newTischfunktionen);
        this.$store.set('tableStore/tables@['+idx+'].myTischfunktionen', myTischfunktion[0]);
      }
    },
    async updateTableFunction(field) {
      console.log("")
      if (!this.tischfunktionen.hasOwnProperty(field)) return;
      const oldValue = Boolean(this.tischfunktionen[field]);
      const newValue = Number(!oldValue);
      this.tischfunktionen = await apiTyped.updateTischfunktionen(this.tischfunktionen.id, {[field]: newValue});
    },
    // get_user_avatar (width) {
    //   try {
    //     const url = apiTyped.getUserAvatarUrl(width);
    //     return url;
    //
    //   } catch(error) {
    //     console.error("Error getting Avatar URL:", error)
    //     return false;
    //   }
    // },
    get_badges_data() {
      console.log("App BAr", this.$store.state.badgesStore.badgesData)
      return this.$store.state.badgesStore.badgesData;
    },
    get_badge_url (points) {
      const url = this.$store.getters["badgesStore/getBadgeIcon"](points, 100);
      // console.log("Got current cider url:", url);
      return url;
    },
    get_current_badge_url () {
      const url = this.$store.getters["badgesStore/getBadgeIcon"](this.$store.getters.get_points_for_current_cider(), 100);
      // console.log("Got current cider url:", url);
      return url;
    },
    reload_page () {
      this.$store.set('reload_router', !this.$store.state.reload_router);
      this.showUserMenu = false;
    },
    async resetTutorials() {
      await apiTyped.updateUserSettings({
        "tour_table_complete": false, "tour_rating_complete": false, "tour_summary_complete": false});
      this.$store.getters["usersStore/user"]().userSettings.tourTableComplete = false;
      this.$store.getters["usersStore/user"]().userSettings.tourRatingComplete = false;
      this.$store.getters["usersStore/user"]().userSettings.tourSummaryComplete = false;
      this.showUserMenu = false;
      this.reload_page()
    },
    async logout() {
      localStorage.clear();
      await store.dispatch("usersStore/logout");
      this.showUserMenu = false;
      window.localStorage.clear();
      // window.location.reload();
    },
    async clear_cookies() {
      await this.logout();
      await this.$store.dispatch('clearStore');
    },
    async updateNextCiderAppBar() {
      const newRequestedValue = await this.requestCider(this.nextCider);
      this.$store.getters["ciderStore/getCiderById"](this.nextCider.id, this.nextCider.flightId).requestCider = newRequestedValue;
      // this.tischfunktionen = newValues;
      // Vue.set(this, 'tischfunktionen', newValues)
    }
  },

});
export default Component;
</script>


<style>

.blur_effect_app_bar {
  backdrop-filter: blur(6px);
  /*-webkit-box-shadow: inset 0 0 200px #00000000;*/
  /*-moz-box-shadow: inset 0 0 200px #00000000;*/
  /*box-shadow: inset 0 0 200px #00000000;*/
}

.rotate_360 {
  -moz-transform:rotate(360deg);
  -webkit-transform:rotate(360deg);
  transform:rotate(360deg);
}

.icon_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 8px;
  padding-right: 8px;
  line-height: 100%;
}

.animate_change {
  transition:all 0.25s linear;
}

.v-list-item--active {
  color: #f1f1f1 !important;
  caret-color: #f1f1f1 !important;
}

.v-menu__content {
  background-color: var(--v-card_background-base) !important;
  border-radius: 20px !important;
}
</style>