import Vue from 'vue';
import Vuex from 'vuex';
import {make} from 'vuex-pathify';
import {apiTyped} from "@/api";
import {Tasting} from "@/types/tasting";
import JurorCider from "@/types/jurorCider";
// import store, {rootGetter} from "@/stores";

Vue.use(Vuex);


type storeStates = typeof state;
// @ts-ignore
type storeGetters = typeof getters;
// type rootStates = typeof store.state;
// type rootGetters = typeof rootGetter;

const state: {
    showNavigationDrawer: boolean,
    tastings: Tasting[],
    currentTastingId: number,
    autoCollapse: boolean,
    stickyToolbar: boolean,
    refreshEvery: number,
    printProducts: JurorCider[],
} = {
    showNavigationDrawer: false,
    tastings: [],
    currentTastingId: -1,
    autoCollapse: true,
    stickyToolbar: true,
    refreshEvery: 30,
    printProducts: [],
}

// @ts-ignore
const getters = {
    ...make.getters(state),
    currentTasting(state: storeStates, getters: storeGetters): Tasting | null {
        return state.tastings?.find(t => t.id === state.currentTastingId) || null;
    }
}

const mutations = {
    ...make.mutations(state),
}

const actions = {
    async getAllTastings(context: any): Promise<void> {
        context.state.tastings = await apiTyped.serviceGetAllTastings();
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}