<template>
  <div
    class="ma-0"
    style="border-radius: 20px; margin: 0 !important;"
    :class="{'selected-cider': selected}"
  >
    <v-card
      v-if="cider !== null"
      id="ciderCard"
      ref="ciderCardRef"
      class="animate-shadow pb-0"
      :elevation="selected ? 0 : 0"
      outlined
      style="overflow: visible; position: relative; width: 100%; height: 100%;"
    >
      <div style="z-index: 1; position: relative; top: 0; right: 0; overflow: hidden;">
        <div
          v-show="selected"
          style="z-index: 100;"
          class="triangle-top-right elevation-0 rounded-t-xl"
        />
        <v-icon
          v-show="selected"
          color="white"
          size="20"
          style="z-index: 101; position: absolute; top: 8px; right: 8px;"
        >
          mdi-check
        </v-icon>
      </div>
      <div
        class="overflow-hidden"
        style="border-top-left-radius: 19px; border-top-right-radius: 19px;"
      >
        <v-carousel
          v-if="ciderInfos.images != null && ciderInfos.images.length > 0 && !hideImages"
          hide-delimiters
          class="overflow-hidden"
          :continuous="false"
          :show-arrows="ciderInfos.images.length > 1"
          :height="cardHeight"
        >
          <v-carousel-item
            v-for="(image, i) in ciderInfos.images"
            :key="i"
            :src="isValidHttpUrl(image) ? image : getImageUrl(image, 1000)"
            style="cursor: pointer;"
            @click="fullContent ? showGallery = true : showGallery = false;"
          >
            <div
              style="position: absolute; top: 0; left: 0;"
            >
              <v-btn
                style="position: absolute; top: 8px; left: 8px; opacity: 0.65; background-color: white;"
                icon
                elevation="4"
                color="black"
                top
                right
                @click="selectedImage = i; showGallery = true"
                @click.native.stop=""
              >
                <v-icon>
                  mdi-arrow-expand
                </v-icon>
              </v-btn>
            </div>
            <!--          <v-menu-->
            <!--            v-model="showMenus[i]"-->
            <!--            transition="scale-transition"-->
            <!--            origin="center"-->
            <!--          >-->
            <!--            <template #activator="{ on }">-->
            <!--              <div-->
            <!--                style="position: absolute; top: 0; left: 0;"-->
            <!--              >-->
            <!--                <v-btn-->
            <!--                  style="position: absolute; top: 8px; left: 8px; opacity: 0.65;"-->
            <!--                  icon-->
            <!--                  elevation="4"-->
            <!--                  color="error"-->
            <!--                  top-->
            <!--                  right-->
            <!--                  v-on="on"-->
            <!--                  @click="showGallery = true"-->
            <!--                >-->
            <!--                  <v-icon>-->
            <!--                    mdi-arrow-expand-->
            <!--                  </v-icon>-->
            <!--                </v-btn>-->
            <!--              </div>-->
            <!--            </template>-->
            <!--            <v-img-->
            <!--              class="rounded-t-xl"-->
            <!--              width="400"-->
            <!--              :src="isValidHttpUrl(image) ? image : getImageUrl(image, 1000)"-->
            <!--            />-->
            <!--          </v-menu>-->
          </v-carousel-item>
        </v-carousel>
      </div>
      <!--    <carousel-->
      <!--      v-if="ciderInfos.images.length > 0"-->
      <!--      :per-page="1"-->
      <!--      :pagination-enabled="false"-->
      <!--      :navigation-enabled="true"-->
      <!--      @click.native.stop=""-->
      <!--    >-->
      <!--      <slide-->
      <!--        v-for="(image, index) in ciderInfos.images"-->
      <!--        :id="`img_${index}`"-->
      <!--        :key="`img_${index}`"-->
      <!--      >-->
      <!--        <div-->
      <!--          style="width: 100%; position: relative;"-->
      <!--        >-->
      <!--          <v-menu-->
      <!--            v-model="showMenus[index]"-->
      <!--            transition="scale-transition"-->
      <!--            origin="center"-->
      <!--          >-->
      <!--            <template #activator="{ on }">-->
      <!--              <div-->
      <!--                style="position: absolute; top: 0; left: 0; z-index: 99;"-->
      <!--              >-->
      <!--                <v-btn-->
      <!--                  style="position: absolute; top: 8px; left: 8px; z-index: 99; opacity: 0.65;"-->
      <!--                  icon-->
      <!--                  elevation="4"-->
      <!--                  color="error"-->
      <!--                  top-->
      <!--                  right-->
      <!--                  v-on="on"-->
      <!--                  @click="showMenus[index] = true"-->
      <!--                >-->
      <!--                  <v-icon>-->
      <!--                    mdi-arrow-expand-->
      <!--                  </v-icon>-->
      <!--                </v-btn>-->
      <!--              </div>-->
      <!--            </template>-->
      <!--            <v-img-->
      <!--              class="rounded-t-xl"-->
      <!--              width="400"-->
      <!--              :src="isValidHttpUrl(image) ? image : getImageUrl(image, 1000)"-->
      <!--            />-->
      <!--          </v-menu>-->
      <!--          <v-img-->
      <!--            class="rounded-t-xl"-->
      <!--            height="250"-->
      <!--            style="width: 100%; display: inline-block; left: 0;"-->
      <!--            :src="isValidHttpUrl(image) ? image : getImageUrl(image, 1000)"-->
      <!--          />-->
      <!--        </div>-->
      <!--&lt;!&ndash;        <v-menu&ndash;&gt;-->
      <!--&lt;!&ndash;          v-model="showMenu"&ndash;&gt;-->
      <!--&lt;!&ndash;          close-on-content-click&ndash;&gt;-->
      <!--&lt;!&ndash;          transition="scale-transition"&ndash;&gt;-->
      <!--&lt;!&ndash;          origin="center"&ndash;&gt;-->
      <!--&lt;!&ndash;        >&ndash;&gt;-->
      <!--&lt;!&ndash;          <template v-slot:activator="{ on, attrs }">&ndash;&gt;-->
      <!--&lt;!&ndash;            <v-img&ndash;&gt;-->
      <!--&lt;!&ndash;              class="rounded-t-xl"&ndash;&gt;-->
      <!--&lt;!&ndash;              :width="cardWidth"&ndash;&gt;-->
      <!--&lt;!&ndash;              :height="cardWidth * 0.66"&ndash;&gt;-->
      <!--&lt;!&ndash;              :src="imageUrlsProvided ? image : getImageUrl(image, cardWidth * 2)"&ndash;&gt;-->
      <!--&lt;!&ndash;              v-bind="attrs"&ndash;&gt;-->
      <!--&lt;!&ndash;              v-on="on"&ndash;&gt;-->
      <!--&lt;!&ndash;            />&ndash;&gt;-->
      <!--&lt;!&ndash;          </template>&ndash;&gt;-->
      <!--&lt;!&ndash;          <v-img&ndash;&gt;-->
      <!--&lt;!&ndash;            class="rounded-t-xl"&ndash;&gt;-->
      <!--&lt;!&ndash;            :width="cardWidth"&ndash;&gt;-->
      <!--&lt;!&ndash;            :src="imageUrlsProvided ? image : getImageUrl(image, cardWidth * 2)"&ndash;&gt;-->
      <!--&lt;!&ndash;          />&ndash;&gt;-->
      <!--&lt;!&ndash;        </v-menu>&ndash;&gt;-->
      <!--      </slide>-->
      <!--    </carousel>-->
      <div
        v-if="ciderInfos.images.length === 0 && !hideImages"
        class="d-flex"
        :style="{'height': cardHeight + 'px'}"
      >
        <span class="d-flex justify-center align-center ml-auto mr-auto text--secondary">
          {{ $t('noImages') }}
        </span>
      </div>

      <div
        v-if="!hideYears"
        class="pl-4 pr-2 pt-0 overflow-visible"
        style="width: 100% !important;"
      >
        <v-card-title
          v-if="!hideYears && fullContent"
          class="pb-0 info-label-text pr-2 pt-1 ml-auto d-flex justify-end"
        >
          Awards
        </v-card-title>
        <div
          class="d-flex pa-0 pt-2 pb-2 pr-0 justify-end ml-auto overflow-visible"
          style="min-width: 88px;"
        >
          <badges-stack
            v-if="flightsCidersPublished.length > 0"
            class="d-flex justify-end pr-2"
            :badge-width="40"
            :badges-data="flightsCidersPublished.map(f => f.badge)"
            :best-of-category="flightsCidersPublished.map(f => f.bestOfCategory)"
            :distance="fullContent ? 32 : 24"
            @click.native.stop=""
          />
          <span
            v-if="flightsCidersPublished.length === 0"
            class="d-flex text--secondary text-caption mb-0 pb-0 pr-3 align-end"
            style="min-width: 64px; min-height: 32px;"
          >
            {{ $t('noAwardBadges') }}
          </span>
        </div>
      </div>

      <v-tooltip
        top
      >
        <template #activator="{ on }">
          <div
            class="text-left pt-2 pl-4 pr-4 pb-0"
            style="max-width: 100%; font-size: 24px !important; text-overflow: ellipsis !important; white-space: nowrap; overflow: hidden;"
            v-on="on"
          >
            {{ ciderInfos.name }}
          </div>
        </template>
        {{ ciderInfos.name }}
      </v-tooltip>

      <div
        class="pl-4 pr-4 text-left pt-0 text-h5"
        style="max-width: 100%; font-size: 18px !important; text-overflow: ellipsis !important; white-space: nowrap; overflow: hidden;"
      >
        {{ ciderInfos.kategorie }}
      </div>
      <div
        v-if="fullContent && ciderInfos.kategorieId != null"
        class="text-left font-weight-regular pl-4 pr-4 pb-2 small-p-margin"
        v-html="getTranslation(ciderInfos.kategorieTranslations,'info',currentLanguage)"
      />
      <div
        v-if="fullContent && ciderInfos.kategorieId == null"
        class="pl-4 pr-4 text-left text--secondary pb-0"
      >
        {{ $t('noCategory') }}
      </div>

      <v-divider
        v-if="fullContent"
        class="ml-4 mr-4 pb-2"
      />

      <div
        class="pl-4 pr-4 pt-0 pb-1 text-left"
        style="width: 100%; display: block;"
      >
        <span
          v-if="fullContent"
          class="info-label text--secondary text-caption"
        >
          {{ $t('salesDesignation') }}
        </span>
        <div
          class="font-weight-regular pr-2 text-subtitle-1"
          :class="{'grey--text': !fullContent}"
          style="max-width: 100%; text-overflow: ellipsis !important; white-space: nowrap; overflow: hidden;"
        >
          {{ ciderInfos.salesDesignation || '-' }}
        </div>
        <span
          v-if="fullContent"
          class="info-label text--secondary text-caption text-left pt-2"
        >
          {{ $t('designationOfOrigin') }}
        </span>
        <div
          class="font-weight-regular pr-2 text-subtitle-1"
          :class="{'grey--text': !fullContent}"
          style="max-width: 100%; text-overflow: ellipsis !important; white-space: nowrap; overflow: hidden;"
        >
          {{ ciderInfos.designationOfOrigin || '-' }}
        </div>
        <!--        <span-->
        <!--          v-if="fullContent"-->
        <!--          class="info-label text&#45;&#45;secondary text-caption text-left"-->
        <!--        >-->
        <!--          Varieties-->
        <!--        </span>-->
        <!--        <div-->
        <!--          v-if="fullContent"-->
        <!--          class="font-weight-regular"-->
        <!--        >-->
        <!--          {{ ciderInfos.varieties || '&nbsp;' }}-->
        <!--        </div>-->
        <span
          v-if="fullContent"
          class="info-label text--secondary text-caption text-left pt-2"
          style="text-transform: capitalize;"
        >
          {{ $t('CiderCharacteristics.origin') }}
        </span>
        <div
          v-if="fullContent"
          class="font-weight-medium"
        >
          {{ ciderInfos.land != null ? ciderInfos.land.name : '-' }}
        </div>
      </div>

      <v-card-title
        v-if="fullContent"
        class="text-truncate pl-4 pr-4 pb-1 pt-1 info-label-text"
        style="max-width: 80%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
      >
        {{ $t('specifications') }}
      </v-card-title>

      <v-row
        v-if="fullContent"
        no-gutters
        class="pl-2 pr-2 pb-2"
        justify="center"
      >
        <v-col
          cols="4"
        >
          <div class="d-flex flex-row">
            <div class="d-flex flex-column align-start pl-2">
              <span class="info-label text--secondary text-caption text-left">
                {{ $t('alcohol') }}
              </span>
              <span class="data-entry text-caption text-left">
                {{ ciderInfos.alkohol }} {{ ciderInfos.alkohol != null ? '% vol.' : '-' }}
              </span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="4"
        >
          <div class="d-flex flex-row">
            <div class="d-flex flex-column align-start pl-2">
              <span class="info-label text--secondary text-caption text-left">
                {{ $t('price') }}
              </span>
              <span class="data-entry text-caption text-left">
                {{ ciderInfos.price || '-' }}
              </span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="4"
        >
          <div class="d-flex flex-column align-start pl-2">
            <span class="info-label text--secondary text-caption text-left">
              {{ $t('bottleContent') }}
            </span>
            <span class="data-entry text-caption text-left">
              {{ ciderInfos.bottleContent || '-' }}
            </span>
          </div>
        </v-col>
      </v-row>

      <v-row
        v-if="fullContent"
        no-gutters
        class="pl-2 pr-2 pb-2"
        justify="start"
      >
        <v-col
          cols="4"
        >
          <div class="d-flex flex-column align-start pl-2">
            <span class="text-left info-label text--secondary text-caption">
              {{ $t('pressure') }}
            </span>
            <span class="text-wrap data-entry text-caption text-left">
              {{ ciderInfos.pressure || '-' }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="8"
        >
          <div class="d-flex flex-column align-start pl-2">
            <span class="info-label text--secondary text-caption text-left">
              {{ $t('residualSugar') }}
            </span>
            <div class="d-flex align-start">
              <span
                class="d-flex data-entry text-caption text-left"
                style="min-width: 64px;"
              >
                {{ ciderInfos.residualSugar }} {{ ciderInfos.alkohol != null ? 'g/L' : '-' }}
              </span>
              <v-icon v-if="ciderInfos.residualSugarDescription !== ''">
                mdi-circle-small
              </v-icon>
              <span
                v-if="ciderInfos.residualSugarDescription !== ''"
                class="d-flex data-entry text-caption text-left flex-grow-0"
              >
                {{ ciderInfos.residualSugarDescription }}
              </span>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row
        v-if="fullContent"
        no-gutters
        class="pl-2 pr-2"
        justify="start"
      >
        <v-col
          cols="4"
        >
          <div class="d-flex flex-row">
            <div class="d-flex flex-column align-start pl-2">
              <span class="info-label text--secondary text-caption text-left">
                {{ $t('method') }}
              </span>
              <span class="data-entry text-caption text-left">
                {{ ciderInfos.method || '-' }}
              </span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="4"
        >
          <div class="d-flex flex-row">
            <div class="d-flex flex-column align-start pl-2">
              <span class="info-label text--secondary text-caption text-left">
                {{ $t('filtrationClarity') }}
              </span>
              <span class="data-entry text-caption text-left">

                {{ ciderInfos.filtrationClarity || '-' }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <!--    </v-card>-->
      <!--  </div>-->
      <!--      <v-tooltip top>-->
      <!--        <template #activator="{ on }">-->
      <!--          <v-card-title-->
      <!--            class="with-text-break text-left pt-0 pb-0"-->
      <!--            v-on="on"-->
      <!--          >-->
      <!--            {{ ciderInfos.name }}-->
      <!--          </v-card-title>-->
      <!--        </template>-->
      <!--        {{ ciderInfos.name }}-->
      <!--      </v-tooltip>-->

      <v-card-text
        v-if="showFlag"
        class="text-left pb-0"
      >
        <div
          v-if="ciderInfos.land != null"
          class="d-flex align-center"
          style="max-height: 39px;"
        >
          <country-flag
            class="elevation-2"
            style="border-radius: 10px !important;"
            :country="ciderInfos.land.code"
          />
          <span class="ml-1">{{ ciderInfos.land.name }}</span>
        </div>
        <div
          v-else
          style="height: 39px;"
        />
      </v-card-text>
      <template
        v-if="fullContent"
      >
        <v-card-title
          class="d-flex pb-0 info-label-text pt-3"
        >
          {{ $t('shortDescriptionProfile') }}
        </v-card-title>
        <!--        <v-divider></v-divider>-->

        <div
          v-if="ciderInfos.profil !== ''"
          class="text-left font-weight-medium pl-4 pr-4"
          v-html="ciderInfos.profil"
        />
        <div
          v-else
          class="text-left font-weight-medium pl-4 pr-4"
        >
          -
        </div>
      </template>
      <!--      <v-card-text-->
      <!--        v-if="fullContent"-->
      <!--        class="text-left pt-0 pb-0 with-text-break-no-white-space"-->
      <!--        :class="{'profile-cut': profilTooLong}"-->
      <!--        :style="{height: profilHeight + 'px'}"-->
      <!--        style="position: relative;"-->
      <!--      >-->
      <!--        <v-scroll-x-transition hide-on-leave>-->
      <!--          <v-card-text-->
      <!--            :key="infosChanged"-->
      <!--            ref="profil"-->
      <!--            class="text-left pa-0 with-text-break-no-white-space small-p-margin"-->
      <!--            v-html="ciderInfos.profil"-->
      <!--          />-->
      <!--        </v-scroll-x-transition>-->
      <!--        <v-tooltip top>-->
      <!--          <template #activator="{ on }">-->
      <!--            <v-btn-->
      <!--              v-show="profilTooLong"-->
      <!--              icon-->
      <!--              style="position: absolute; bottom: 0px; left: 50%; transform: translateX(-50%); font-weight: 800;"-->
      <!--              :ripple="false"-->
      <!--              v-on="on"-->
      <!--            >-->
      <!--              ...-->
      <!--            </v-btn>-->
      <!--          </template>-->
      <!--          <v-card-text-->
      <!--            class="text-left pa-0"-->
      <!--            v-html="ciderInfos.profil"-->
      <!--          />-->
      <!--        </v-tooltip>-->
      <!--      </v-card-text>-->

      <v-card-title
        v-if="!hideYears && fullContent"
        class="pb-0 pt-0 info-label-text pr-4"
      >
        {{ $t('tastings') }}
      </v-card-title>
      <div
        v-if="!hideYears"
        class="pl-4 pr-2 pt-0"
        style="width: 100% !important;"
      >
        <div
          class="d-flex pa-0 ma-0 align-center justify-space-between"
        >
          <div
            class="pa-0 pb-1"
            style="width: calc(100% - 8px) !important;"
          >
            <v-chip-group
              v-model="selectedRevision"
              show-arrows
              disabled
              :mandatory="!hideCurrent"
              active-class="secondary"
              class="pb-0 d-flex justify-end"
              @click.native.stop=""
            >
              <v-chip
                v-if="!hideCurrent"
                value="-1"
                class="d-flex"
                :filter="!hideCurrent"
                :disabled="disableRevisions"
                @click="setCiderInfos(cider.ciderInfos)"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span v-on="on">
                      Current
                    </span>
                  </template>
                  <span>Current Data</span>
                </v-tooltip>
              </v-chip>
              <v-chip
                v-for="(revision, index) in revisionsReversed"
                :key="`${revision.eingereicht.id}_${index}`"
                :value="index"
                class="d-flex"
                variant="outlined"
                :filter="!hideCurrent"
                :disabled="disableRevisions"
                :filter-icon="loadingCiderRevision ? 'mdi-timer-sand' : 'mdi-check'"
              >
                <!--                <v-progress-circular-->
                <!--                  v-if="loadingCiderRevision"-->
                <!--                  size="16"-->
                <!--                  width="2"-->
                <!--                  class="mr-2"-->
                <!--                  indeterminate-->
                <!--                />-->
                <v-tooltip
                  bottom
                  open-delay="500"
                  color="transparent"
                  content-class="blurred-background"
                >
                  <template #activator="{ on }">
                    <span v-on="on">
                      {{ revision.eingereicht.tasting.abbreviation }}
                    </span>
                  </template>
                  <v-card
                    class="blurred-background"
                  >
                    <tasting-card
                      :tasting="revision.eingereicht.tasting"
                      :elevation="6"
                      :selected="false"
                      hide-more-infos
                      hide-buttons
                      style="max-width: 450px;"
                    />
                  </v-card>
                </v-tooltip>
              </v-chip>
            </v-chip-group>
          </div>
        </div>
      </div>

      <v-btn
        v-if="fullContent && showEdit"
        color="primary"
        fab
        width="56"
        height="56"
        absolute
        top
        left
        style="bottom: 128px !important; opacity: 1;"
        :style="{top: cardHeight - 28 + 'px'}"
        @click="editCider"
      >
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
<!--      <v-row-->
<!--        v-if="!hideStats"-->
<!--        class="pb-2 justify-center"-->
<!--        no-gutters-->
<!--      >-->
<!--        <v-col-->
<!--          v-if="cider.flightsCiders.length > 0"-->
<!--          cols="4"-->
<!--        >-->
<!--          <span style="font-size: 12px;">{{ cider.flightsCiders.length }} {{ $t('tastings') }}</span>-->
<!--        </v-col>-->
<!--        <template v-if="flightsCidersFinished.length > 0">-->
<!--          <v-divider vertical />-->
<!--          <v-col cols="4">-->
<!--            <span style="font-size: 12px;">⌀ {{ flightsCidersFinished.reduce((sum, f) => f.points + sum, 0) }} {{ $t('points') }}</span>-->
<!--          </v-col>-->
<!--          <template v-if="flightsCidersFinished.length > 1">-->
<!--            <v-divider vertical />-->
<!--            <v-col cols="4">-->
<!--              <span style="font-size: 12px;">-->
<!--                {{ Math.min(...flightsCidersFinished.map(f => f.points)) }} - {{ Math.max(...flightsCidersFinished.map(f => f.points)) }} points</span>-->
<!--            </v-col>-->
<!--          </template>-->
<!--        </template>-->
<!--      </v-row>-->
    </v-card>

    <v-overlay
      v-model="showGallery"
      color="#090909"
      z-index="96"
    >
      <gallery-overlay
        :items="ciderInfos.images.map(i => isValidHttpUrl(i) ? i : getImageUrl(i, 1000))"
        :selected="selectedImage"
        :title="ciderInfos.name"
        @closeGallery="showGallery = false"
        @click.native.stop=""
      />
    </v-overlay>
  </div>
</template>

<script>
// import { Carousel, Slide } from 'vue-carousel';
import BadgesStack from "@/components/badges_stack";
import logos_mixin from "@/components/mixins/logos_mixin";
import CountryFlag from "vue-country-flag";
import {CProducerCiderResults} from "@/types/producerCider";
import i18n from '@/i18n'
import TastingCard from "@/components/producer/tasting_card";
import user_mixin from "@/components/mixins/user_mixin";
import translation_mixin from "@/components/mixins/translation_mixin";
import GalleryOverlay from "@/components/gallery_overlay";

export default {
  name: "CiderCard",
  components: {
    GalleryOverlay,
    TastingCard,
    // TastingCard,
    CountryFlag,
    BadgesStack,
  },
  mixins: [logos_mixin, user_mixin, translation_mixin],
  props: {
    cider: {type: Object, required: true, default: null},
    hideYears: {type: Boolean, required: false, default: false},
    hideStats: {type: Boolean, required: false, default: false},
    imageUrlsProvided: {type: Boolean, required: false, default: false},
    elevation: {type: Number, required: false, default: 1},
    selected: {type: Boolean, required: false, default: false},
    selectRevision: {type: Number, required: false, default: null},
    disableRevisions: {type: Boolean, required: false, default: false},
    outlined: {type: Boolean, required: false, default: false},
    hideImages: {type: Boolean, required: false, default: false},
    showFlag: {type: Boolean, required: false, default: false},
    fullContent: {type: Boolean, required: false, default: false},
    hideCurrent: {type: Boolean, required: false, default: false},
    showEdit: {type: Boolean, required: false, default: true},
  },
  data: () => ({
    cardHeight: 320,
    selectedImage: 0,
    showGallery: false,
    profilHeight: 92,
    profilTooLong: false,
    ciderInfos: null,
    infosChanged: false,
    selectedRevision: 0,
    selectedRevisionId: -1,
    loadingCiderRevision: false,
    showMenus: [],
  }),
  computed: {
    flightsCidersPublished() {
      return this.cider.flightsCiders.filter(f =>
          (f.tastingResultPublished || f.tastingResultPublishedFor.includes(this.$store.state.producerStore.producerId))
          && !f.hiddenResultsForProducer.includes(this.$store.state.producerStore.producerId));
    },
    categoryName() {
      if (this.ciderInfos.kategorieId === -1 || this.ciderInfos.kategorieId == null
          || this.ciderInfos.kategorieTranslations == null) {
        return this.$t('noCategory');
      }
      return this.ciderInfos.kategorieTranslations[this.userLang].name;
    },
    userLang() {
      return this.$store.getters["usersStore/getUserLanguage"]();
    },
    revisionsReversed() {
      // const repeat = (arr, n) => Array.from({ length: arr.length * n }, (_, i) => arr[i % arr.length]);
      // return repeat(this.cider.revisions.map((item,idx) => this.cider.revisions[this.cider.revisions.length-1-idx]),8);
      return this.cider.revisions.map((item,idx) => this.cider.revisions[this.cider.revisions.length-1-idx]);
    },
  },
  watch: {
    cider: function () {
      this.showMenus = Array(this.cider.ciderInfos.images.length).fill(false);
    },
    selectedRevision: async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const revision = this.revisionsReversed[newVal];
        if (revision != null) await this.loadRevision(revision);
      }
    },
    selectedRevisionId: async function (newVal, oldVal) {
      if (newVal !== -1 && newVal !== oldVal) {
        const revisionIdx = this.revisionsReversed.findIndex(r => r.eingereicht.productRevision === newVal);
        if (revisionIdx !== -1) this.selectedRevision = revisionIdx;
      }
    },
  },
  created() {
    this.ciderInfos = this.cider.ciderInfos;
    this.selectedRevision = this.cider.revisions.length;
    this.$nextTick(() => {
      const profilHtml = this.$refs.profil;
      if (profilHtml != null && profilHtml.offsetHeight > this.profilHeight) {
        this.profilTooLong = true;
      }
    });
    if (this.selectRevision != null) {
      const foundIndex = this.revisionsReversed.findIndex(r => r.eingereicht.tasting.id === this.selectRevision)
      this.selectedRevision = foundIndex || 0;
      // console.log("FOUNDDD", foundIndex)
      // if (foundIndex != null) this.selectedRevision = this.cider.length - this.selectedRevision;
      // // this.selectedRevision = this.selectRevision < 0 ? this.cider.revisions.length - this.selectRevision : this.selectRevision;
    }
    this.showMenus = Array(this.cider.ciderInfos.images.length).fill(false);
    this.$nextTick(() => {
      if (this.$refs.ciderCardRef != null) this.cardHeight = Math.min(this.$refs.ciderCardRef.$el.clientWidth, 600);
      // if (this.ciderInfos.images.length === 0) this.cardHeight = 320;
    });
  },
  methods: {
    selectCiderRevision(ciderRevisionId) {
      const revIdx = this.ciderInfos.revisions.findIndex(r => r.eingereicht.productRevision === ciderRevisionId);
      if (revIdx !== -1) this.selectedRevision = revIdx;
    },
    async editCider() {
      this.$store.state.appStore.breadcrumbs[this.$store.state.appStore.breadcrumbs.length - 1].disabled = false;
      this.$store.state.appStore.breadcrumbs.push({
        text: this.$i18n.t('editProduct'),
        disabled: true
      });
      this.$store.set("appStore/breadcrumbs", this.$store.state.appStore.breadcrumbs);  // commit persistent
      await this.$router.push(`/producer/ciders/edit/${this.cider.id}`)
    },
    async loadRevision(revision) {
      this.loadingCiderRevision = true;
      let revisionInfos = null;
      if (!revision.loaded) revisionInfos = await this.cider.loadRevision(revision);
      this.loadingCiderRevision = false;
      console.log("SETTING INFOS", revisionInfos, revision)
      if (revisionInfos != null) {
        this.setCiderInfos(revisionInfos)
      }
      else {
        this.setCiderInfos(revision.ciderInfo)
      }
    },
    setCiderInfos(ciderInfos) {
      this.ciderInfos = ciderInfos;
      this.infosChanged = !this.infosChanged;
    }
  }
}
</script>

<style scoped>
.with-text-break {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  width: 100% !important;
  display: block;
}
.with-text-break-no-white-space {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  width: 100% !important;
  display: block;
}
/deep/ .VueCarousel-navigation-next {
  right: 30px !important;
  background-color: rgba(224, 224, 224, 0.31) !important;
}
/deep/ .VueCarousel-navigation-prev {
  left: 30px !important;
  background-color: rgba(224, 224, 224, 0.31) !important;
}

/deep/ .v-slide-group__prev {
  max-width: 24px !important;
  flex-shrink: 1;
}

/deep/ .v-slide-group__next {
  max-width: 24px !important;
}

.animate-shadow {
  transition: box-shadow 0.3s ease-in-out;
}

/*.profile-cut {*/
/*  -webkit-mask-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1)), linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,1));*/
/*  -webkit-mask-size: 100% 100%;*/
/*  -webkit-mask-repeat: no-repeat;*/
/*}*/

/deep/ .small-p-margin > p {
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}

.selected-chip {
  background-color: var(--v-text_std-base) !important;
  color: #0d47a1;
}

.triangle-top-right {
  position: absolute;
  top: -40px;
  right: -40px;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  background: var(--v-primary-base);
}

.v-application {
  box-shadow: unset !important;
}

.selected-cider {
  outline: 0 !important;
  box-shadow: 0 0 0 3px var(--v-primary-base) !important;
}

.blurred-background {
  opacity: 0.95 !important;
  backdrop-filter: blur(8px) !important;
  -webkit-box-shadow: inset 0 0 200px #00000000;
  -moz-box-shadow: inset 0 0 200px #00000000;
  box-shadow: inset 0 0 200px #00000000;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 20px;
}

.info-label {
  font-size: 14px !important;
}
.data-entry {
  font-size: 16px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  /*width: 100%;*/
}

.info-label-text {
  text-decoration: underline var(--v-divider-base);
  text-underline-offset: 4px;
  opacity: 0.6;
}

/deep/ .v-slide-group__prev {
  max-width: 24px !important;
  margin-left: -12px;
  flex: 0 1 24px;
  min-width: 24px !important;
}
/deep/ .v-slide-group__next {
  max-width: 24px !important;
  margin-right: -12px;
  flex: 0 1 24px;
  min-width: 24px !important;
}
</style>