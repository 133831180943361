import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import pathify from './pathify'
import {make} from 'vuex-pathify'
import {apiTyped} from "@/api";
import util from "@/util";
import adminStore from "@/stores/admin.ts";
import badgesStore from "@/stores/badges.ts";
import ciderStore from "@/stores/ciders.ts";
import kategorienStore from "@/stores/kategorien.ts";
import usersStore from "@/stores/users.ts";
import tablesStore from "@/stores/tables.ts";
import appStore from "@/stores/app.ts";
import tourStore from "@/stores/tours.ts";
import localeStore from "@/stores/locale.ts";
import producerStore from "@/stores/producer.ts";
import submitStore from "@/stores/submit";
import serviceStore from "@/stores/service";
import tableDesignerStore from "@/stores/tableDesigner";
import dynamicIslandsStore from "@/stores/dynamicIslands";
import {version} from '@/../package.json'
// import {appPersistentStates} from "@/stores/app.ts";

const state = {
    showCookieBanner: true,
    app_bar_loading: false,
    active_tab: 0,
    adminNavDrawer: false,
    ciderTableMobileLayout: false,
    api: null,
    logged_in: false,
    languages: null,
    changedTheme: false,
    newTheme: '',
    user_language: null,
    feedbackDialog: false,
    user: {
        login: null,
        password: null,
        data: null,
    },
    // current_cider: null,
    cider_list: [],
    current_cider_idx: 10010,
    category_data: null,
    abschlussbewertung: null,
    reload_router: false,
    pageLoading: true,
    logo_ids: null,
    badges_data: null,
    selected_flights: [],
    currentAppVersion: 0,
    appVersion: version || '0',
}

const getters = {
    ...make.getters(state),
    app_bar_title: (state, getters) => () =>  {
        if (getters["ciderStore/currentCider"]() === null) {
            return "Please select a Cider";
        } else {
            // return "#".concat(getters.current_cider().id, ": ", getters.current_cider().flight_name);
            console.log("App Bar Title: ", getters["ciderStore/currentCider"]())
            return "#".concat(getters["ciderStore/currentCider"]()?.id || "");
        }
    },
    app_bar_subtitle: (state, getters) => () =>  {
        if (getters["ciderStore/currentCider"]() === null) {
            return "Please select a Cider";
        } else {
            if (state.active_tab === 1) {  // in individual rating tab
                return "".concat(getters["ciderStore/currentCider"]().kategorie.name, ": ",
                    getters["ciderStore/getPointsForCurrentCider"](), " Points");
            } else if (state.active_tab === 2) {  // in summary tab
                if (state.abschlussbewertung === null) {
                    return "Loading...";
                }
                return "".concat(getters["ciderStore/currentCider"]().kategorie.name, ": ",
                    getters["ciderStore/getTotalPointsForCider"](getters["ciderStore/currentCider"](), true), " Points");
            }
        }
    },
    get_points_for_current_cider: (state, getters) => () => {
        if (state.active_tab === 1)
            return getters["ciderStore/getPointsByFlightsCidersId"](state.ciderStore.currentFlightsCidersId);
        else if (state.active_tab === 2) {
            if (state.app_bar_loading) {
                return "Loading...";
            }
            return getters["ciderStore/getTotalPointsForFlightsCidersId"](state.ciderStore.currentFlightsCidersId);
        }
    },
    get_cider_idx_by_id: (state) => (cider_id) => {
        return state.cider_list.findIndex(cider => cider.id === cider_id);
    },
    get_points_for_cider: (state, getters) => (cider) => {
        // console.log("GOT CIDER:", cider)
        if (cider === undefined || cider === null) {
            return 0;
        }
        const points = getters["ciderStore/getTotalPointsForCiderIdx"](cider.ciderStore.curr);
        return points;
    },
    get_points: (state, getters) => (cider_idx) => {
        const cider = getters.get_cider_by_idx(cider_idx);
        return getters.get_points_for_cider(cider);
    },
    get_points_from_category: () => (bewertungskategorien_) => {
        let points = 0;
        console.log("GOT B:", bewertungskategorien_)
        bewertungskategorien_.forEach(function (bewertungskategorie) {
            bewertungskategorie.eigenschaften.forEach(async function (eigenschaft) {
                points += eigenschaft.punkte * eigenschaft.faktor;
            });
        });
        // console.log("GOT POINTS:", points)
        return util.util.round_to_decimal(points);
    },
    current_cider: (state) => () => {
        if (state.current_cider_idx === null) return null;
        return state.cider_list[state.current_cider_idx];
    },
    get_cider_by_idx: (state) => (idx) => {
        return state.cider_list[idx];
    },
    show_badge: (state, getters) => (points = null) => {
        if (points === null) points = getters.get_points_for_current_cider()
        return points > getters.get_minimal_badge_points();
    },
    getBadgeData: (state) => (points) => {
        if (state.badges_data === null) return null
        const found_badge = state.badges_data.find(function(badge) { return points >= badge.punkte_min; });
        console.log("Found badge:", found_badge, points);
        return found_badge;
    },
    get_minimal_badge_points: (state) => () => {
        return state.badges_data.reduce(function(prev, curr) {
            return prev.punkte_min < curr.punkte_min ? prev : curr;
        }).punkte_max;
    },
    get_badge_icon: (state, getters) => (points, width) => {
        const found_badge = getters.getBadgeData(points);  // TODO round to next whole points?
        if (found_badge === undefined)
            return "";
        // console.log("Found Badge:", api.getBadgeImgUrl(found_badge.icon, width));
        return apiTyped.getBadgeImgUrl(found_badge.icon, width);
    },
    // getCurrentCider: (state) => () =>  {
    //     return this.getCiderById(state);
    // },
    // getCiderById: (state) => (id) => {
    //     return state.cider_list.find(wine => wine.id === id);
    // },
    getKategorieById: (state) => (id) => {
        if (state.category_data !== null) {
            const found = state.category_data.find(kategorie => kategorie.id == id);
            // console.log("Found category data", found, "ID:", id);
            return found;
        }
        return null;
    },
    getEigenschaftenIds: (state, getters) => (category_id) => {
        const category = getters.getKategorieById(category_id)
        const eigenschaften_ids = [];
        category.bewertungskategorien.forEach(function (bewertungskategorie) {
            bewertungskategorie.eigenschaften.forEach(async function (eigenschaft) {
                eigenschaften_ids.push(eigenschaft.id);
            })
        });
        return eigenschaften_ids;
    },
    current_category: (state, getters) => () => {
        const current_cat = getters.getKategorieById(getters.current_cider().id);
        console.log("Got Current Kategorie:", current_cat);
        return current_cat;
    },

    // isChair: (state) => () => {
    //     return (state.user.data.role.name === "Chair" || state.user.data.role.name === "Administrator");
    // },
    getLogoUrl: (state) => (logo_name, width) => {
        if (state.logo_ids === null || state.logo_ids === undefined) {
            console.warn("Logo ids are null. Please login again!");
            return "";
        }
        // console.log("Current logos", state.logo_ids);
        const logo_id = state.logo_ids[logo_name]
        if (logo_id === null || logo_id === undefined) {
            console.warn("Logo not found for name", logo_name);
            return "";
        }
        // console.log("Found logo", logo_id);
        return apiTyped.getImgUrl(logo_id, width)
    },

    // getLogoUrl: (state) => (logo_name, width) => {
    //     // console.log("Current logos", state.logo_ids);
    //     const logo_id = state.logo_ids[logo_name]
    //     if (logo_id === null || logo_id === undefined) {
    //         console.warn("Logo not found for name", logo_name);
    //         return "";
    //     }
    //     // console.log("Found logo", logo_id);
    //     return apiTyped.getImgUrl(logo_id, width)
    // }
}

const mutations = make.mutations(state)

const persistent_states =
    ['user.login', 'user.password', 'current_cider_idx', 'cider_list', 'logo_ids', 'abschlussbewertung',
    'selected_flights', 'ciderStore/ciderList', 'ciderStore/currentCiderIdx']
const default_states =
    [null, null, null, [], null, null, []]

const loginState = createPersistedState({
    paths: ["usersStore"],
})

const actions = {
    clearStore () {
        // for (const i in persistent_states) {
        //     console.log("Setting state:", persistent_states[i], "to:", default_states[i]);
        //     context.state[persistent_states[i]] = default_states[i];
        // }
    },
}

Vue.use(Vuex)
// pathify.debug()
const store = new Vuex.Store({
    // properties
    state,
    actions,
    getters,
    mutations,
    modules: {
        namespaced: true,
        appStore: appStore,
        adminStore: adminStore,
        badgesStore: badgesStore,
        ciderStore: ciderStore,
        kategorienStore: kategorienStore,
        producerStore: producerStore,
        usersStore: usersStore,
        tableStore: tablesStore,
        tourStore: tourStore,
        localeStore: localeStore,
        submitStore: submitStore,
        serviceStore: serviceStore,
        tableDesignerStore: tableDesignerStore,
        dynamicIslandsStore: dynamicIslandsStore,
    },
    plugins: [
        pathify.plugin,
        createPersistedState(
            {
                paths: [
                    "currentAppVersion",
                    "showCookieBanner",
                    "languages",
                    "logo_ids",
                    "usersStore.language",
                    "usersStore.theme",
                    "usersStore.tastings",
                    "usersStore.currentTastingId",
                    "appStore.authenticated",
                    "appStore.accessTokenExpiry",
                    "submitStore.selectedTasting",
                    "submitStore.selectedCiders",
                    "submitStore.submitProductsWithoutData",
                    "submitStore.selectedProductAmount",
                    "submitStore.tastingPurchaseId",
                    "submitStore.tastingPurchase",
                    "submitStore.confirmedCiderIds",
                    "submitStore.inPayingProcess",
                    "submitStore.numberOfPaidProducts",
                    "submitStore.selectedTastingProducts",
                    "ciderStore.printCiders",
                    "ciderStore.printCidersSerialized",
                    "ciderStore.revealInfos",
                    "ciderStore.hideLegendSteps",
                    "kategorienStore.kategorieData",
                    "kategorienStore.kategorienRated",
                    "kategorienStore.faktorForEigenschaft",
                    "badgesStore.badgesData",
                    "serviceStore.currentTastingId",
                    "serviceStore.autoCollapse",
                    "serviceStore.refreshEvery",
                    "serviceStore.stickyToolbar",
                    "serviceStore.printProducts",
                    "tableStore.tables",
                    "producerStore.lastRouteBeforePrint",
                    "appStore.breadcrumbs",
                    "adminStore.emulatedUser",
                    "adminStore.emulatedUserActive",
                    "tableDesignerStore.tasting",
                    "tableDesignerStore.allJudges",
                    "tableDesignerStore.selectedJudges",
                    "tableDesignerStore.allProducts",
                    "tableDesignerStore.selectedProducts",
                    "tableDesignerStore.tables",
                    "tableDesignerStore.flights"
                ],
                key: "vuexStore",
                // reducer (val) {
                //     console.log("Store access", val)
                //     // if(val.appStore.authenticated === false) { // return empty state when user logged out
                //     //     localStorage.clear();
                //     //     localStorage.removeItem('vuexStore');
                //     //     return {}
                //     // }
                //     return val
                // }
            }
        ),
    ]
});

const rootGetter = store.getters;
const rootState = {...store.state, ...store.modules}
export { rootState, rootGetter };

export default store;