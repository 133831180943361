<template>
  <v-card
    ref="card"
    :outlined="$vuetify.theme.dark"
  >
    <v-card-title class="text-h4">
      Beste Cider
    </v-card-title>
    <v-card-text
      v-if="bestCiders.length === 0"
      class="text--secondary"
    >
      No ratings yet
    </v-card-text>
    <template
      v-for="(cider, index) in bestCiders"
    >
      <v-list-item
        :key="`item_${cider.id}_${index}`"
        three-line
      >
        <v-list-item-content>
          <v-list-item-subtitle class="text-h6 mb-1 text-left">
            {{ cider.name }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="d-flex justify-start align-center">
            <span>{{ cider.land != null ? cider.land.name : "" }}</span>
            <country-flag
              v-if="cider.land != null"
              class="elevation-2"
              :country="cider.land.code"
              size="normal"
            />
          </v-list-item-subtitle>
          <v-list-item-subtitle class="text-left pb-2">
            {{ cider.produzent }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action
          class="d-flex align-start justify-center "
          style="min-width: 66px;"
        >
          <v-avatar
            class="d-flex justify-center ma-auto"
            size="52"
          >
            <v-img :src="getBadgeUrl(100, cider.badgeData.punkteMin, true)" />
          </v-avatar>
          <ChipPoints
            :points="cider.totalPoints"
            :max-width="64"
            class="mt-2"
          />
        </v-list-item-action>
      </v-list-item>
      <v-divider
        v-if="index !== bestCiders.length - 1"
        :key="`div_${cider.id}_${index}`"
        class="d-flex flex-column"
      />
    </template>
  </v-card>
</template>

<script>
import CountryFlag from "vue-country-flag";
import cider_mixin from "@/components/mixins/cider_mixin";
import ChipPoints from "@/components/chip_points";

export default {
  name: "CardBestCider",
  components: {ChipPoints, CountryFlag},
  mixins: [cider_mixin],
  props: {
    allCiders: {type: Array, required: true, default: null},
  },
  computed: {
    bestCiders () {
      // Copy array since sort is in place...
      const best = [...this.allCiders].filter(c => c.totalPoints != 0)?.sort(function(a, b) { return b.totalPoints - a.totalPoints });
      return best?.filter(cider => cider.totalPoints === best[0].totalPoints) || [];
    }
  },
  mounted() {
    this.$emit("size", {name: "CardBestCider", size: this.$refs.card.$el.clientHeight})
  },
}
</script>

<style scoped>

</style>