<template>
  <v-container
    fluid
    class="justify-center align-center pa-0 ma-0"
  >
    <v-card
      v-if="(cider === null || cider === undefined) && noRestartedCiders && (getCurrentTasting.started || getCurrentTasting.finished)"
      fill-height
      :max-width="myWidth"
      elevation="0"
      class="pa-4"
      color="filled_card"
    >
      <v-img
        max-width="82"
        contain
        class="rounded-0"
        :src="getTastingLogoUrl()"
        style="position: absolute; top: 12px; right: 12px;"
      />
      <v-icon
        color="primary"
        size="100"
      >
        mdi-check-decagram
      </v-icon>
      <v-card-title class="text-center justify-center text-h4">
        {{ $t('NextCiderCard.finished') }}
      </v-card-title>
      <v-card-subtitle class="pb-0">
        {{ $t('NextCiderCard.allCidersRated') }}
      </v-card-subtitle>
    </v-card>
    <v-card
      v-if="cider !== null"
      v-ripple
      fill-height
      :max-width="myWidth"
      elevation="0"
      align="center"
      color="filled_card"
      @click="gotoRating"
    >
      <v-row
        cols="12"
        no-gutters
        class="grow fill-height align-stretch"
      >
        <v-col
          cols="8"
          no-gutters
          align="start"
          class="d-flex"
          style="flex-direction:column"
        >
          <v-card-text class="text-left pb-0">
            <div style="color: var(--v-on_surface_variant-base); opacity: 0.6;">
              {{ $t('NextCiderCard.' + nextCiderText) }}
            </div>
            <p class="text-left text-h4 text--primary mb-0 mt-1">
              #{{ preppedCider.displayId }}
            </p>
            <v-card-title
              class="pa-0"
              style="word-break: normal !important;"
            >
              {{ preppedCider.kategorie.name }}
            </v-card-title>
            <div class="d-flex align-center text-h6">
              <p
                v-if="preppedCider.land != null && preppedCider.land.code != null"
                class="text-subtitle-1 text--primary mt-auto mb-auto"
              >
                {{ preppedCider.land.name }}
              </p>
              <country-flag
                v-if="preppedCider.land != null && preppedCider.land.code != null"
                :country="preppedCider.land.code"
                style="border-radius: 10px !important;"
                class="elevation-2"
                size="normal"
              />
              <p
                v-if="preppedCider.land == null || preppedCider.land.code == null"
                class="text-subtitle-1 text--secondary"
              >
                {{ $t('countryNotSpec') }}
              </p>
            </div>
          </v-card-text>
        </v-col>
<!--        <v-fade-transition>-->
<!--          <v-progress-circular-->
<!--            v-show="showBadge(preppedCider.totalPoints) && !badgeLoaded"-->
<!--            color="primary"-->
<!--            size="56"-->
<!--            style="position: absolute; left: calc(83.33% - 28px); top: 36px;"-->
<!--            indeterminate-->
<!--          ></v-progress-circular>-->
<!--        </v-fade-transition>-->
<!--        <v-scroll-x-transition>-->
        <v-col
          v-show="showBadge(preppedCider.totalPoints) && userCompleted"
          cols="4"
          no-gutters
          class="justify-center align-center pa-2 mt-auto mb-auto"
        >
          <Badge
            v-if="preppedCider.badgeData != null"
            class="mb-2"
            :points="preppedCider.totalPoints"
            :show-none-badge="false"
            :badge-data="preppedCider.badgeData"
            :badge-shadow="true"
            @badgeLoaded="onBadgeLoaded"
          />
          <ChipPoints
            :points="preppedCider.totalPoints || 0"
            :dark-background="false"
          />
        </v-col>
<!--        </v-scroll-x-transition>-->
      </v-row>
      <v-card-text class="justify-start text-left pt-0 ma-0 pl-4 pr-4 pb-2">
        <v-icon
          class="pr-1"
          small
          style="display: inline-flex !important;"
          :color="getCiderStateIconColor(preppedCider)"
        >
          {{ getCiderStateIcon(preppedCider) }}
        </v-icon>
        <span
          class="text-left text-caption"
          style="opacity: 0.8; font-size: 10pt !important;"
        >{{ getCiderStateText(preppedCider) }}</span>
      </v-card-text>
      <v-divider
        v-if="ciderTyp"
        style="background-color: var(--v-divider-base)"
      />
      <p
        v-if="ciderTyp !== ''"
        class="mt-2 pa-1 pl-4 pr-2"
        style="text-align: left; margin-bottom: -8px !important;"
        v-html="ciderTyp"
      />
      <v-card-actions
        v-if="tastingIsRunning"
        class="d-flex wrap flex-wrap pt-0 pb-2"
      >
        <div
          v-if="isChair || cider.requestCider"
          @click="!isChair ? showRequestSnack = true : showRequestSnack = false"
          class="d-flex"
        >
          <v-btn
            elevation="0"
            rounded
            :text="(!isChair && !isNextCider) && cider.requestCider === false"
            :disabled="!isChair && !isNextCider"
            :depressed="cider.requestCider"
            :color="cider.requestCider ? 'primary' : 'transparent' "
            :style="{color: requestTextColor}"
            class="ml-0 mt-2"
            :loading="requestCiderLoading"
            style="font-size: 10pt !important;"
            @click="requestCider_"
            @click.native.stop=""
          >
            <v-icon small left>
              mdi-glass-wine
            </v-icon>
            {{ $t(cider.requestCider ? 'NextCiderCard.requestActionEnabled' : 'NextCiderCard.requestAction') }}
          </v-btn>
        </div>
        <v-spacer />
        <v-btn
          text
          rounded
          color="primary"
          class="mt-2"
          style="font-size: 10pt !important;"
          @click="gotoRating"
          @click.native.stop=""
        >
          {{ $t('NextCiderCard.' + nextCiderButtonText) }}
<!--          <v-icon small>-->
<!--            mdi-arrow-right-->
<!--          </v-icon>-->
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar
      v-model="showRequestSnack"
      color="secondary_card"
      :multi-line="true"
      bottom
      timeout="8000"
    >
      {{ $t('requestCiderDisabled') }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showRequestSnack = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Badge from "@/components/badge";
import ChipPoints from "@/components/chip_points";
import cider_mixin from "@/components/mixins/cider_mixin";
import CountryFlag from "vue-country-flag";
import store from "@/stores";
import CiderRatingState from "@/types/ciderRatingState";
import logos_mixin from "@/components/mixins/logos_mixin";
import {apiTyped} from "@/api";
import {sync} from "vuex-pathify";
import Vue from "vue";
import rating_mixin from "@/components/mixins/rating_mixin";
import {allRoutes, makeRoute} from "@/router";

export default {
  name: "NextCiderCard",
  components: {ChipPoints, Badge, CountryFlag},
  mixins: [cider_mixin, logos_mixin, rating_mixin],
  props: {
    cider: {type: Object, required: false, default: null},
    noRestartedCiders: {type: Boolean, required: true, default: false},
    ratingType: {type: String, required: true, default: "next"},
    myWidth: {type: Number, required: false, default: 380},
    isChair: {type: Boolean, required: true, default: false},
    firstCider: {type: Boolean, required: false, default: false},
  },
  data: () => ({
    showRequestSnack: false,
    badgeLoaded: false,
    requestCiderLoading: false,
    requestCiderLoadingHandle: null,
  }),
  computed: {
    showTableFunctions: sync("tableStore/showTableFunctions"),
    showUserMenu: sync("usersStore/showUserMenu"),
    currentTableIdx: sync("tableStore/currentTable"),
    currentTable: sync('tableStore/tables@[:currentTableIdx]'),
    tischfunktionen: sync('tableStore/tables@[:currentTableIdx].myTischfunktionen'),
    requestTextColor() {
      if (this.$vuetify.theme.dark){
        return this.cider.requestCider ? 'black' : 'white'
      } else {
        return this.cider.requestCider ? 'white' : 'black'
      }
    },
    isNextCider() {
      return this.$store.getters['ciderStore/getNextCider']()?.flightsCidersId === this.cider.flightsCidersId;
    },
    userCompleted() {
      // return true;
      return this.$store.getters["ciderStore/getFinishedUserIds"](this.preppedCider).includes(
        this.$store.getters["usersStore/userId"]())
    },
    preppedCider() {
      if (this.cider === null) return null
      const newPreppedCider = this.cider;
      newPreppedCider.totalPoints = this.calculateTotalPointsForCider(this.cider);
      newPreppedCider.state = this.$store.getters["ciderStore/getCiderRatingState"](this.cider);
      newPreppedCider.badgeData = this.getBadgeData(newPreppedCider.totalPoints);
      if (newPreppedCider.land === null) {newPreppedCider.land = {name: "unknown", "code": null}}
      return newPreppedCider
    },
    ciderTyp () {
      if (this.cider === null) return null
      const userLanguageCode = this.$store.getters["usersStore/getUserLanguageCode"]();
      return this.cider.translations !== null && this.cider.translations.hasOwnProperty(userLanguageCode)
        ? this.cider.translations[userLanguageCode].typ : this.cider.typ;
    },
    nextCiderText() {
      if (this.cider === null) return null
      if (this.ratingType === "next" || this.ratingType === "restarted") {
        if (this.firstCider) return "firstCiderToRate"
        if (this.preppedCider.state === CiderRatingState.notStarted ||
          this.preppedCider.state === CiderRatingState.startedByChair) return 'nextCiderToRate'
        if (this.preppedCider.state === CiderRatingState.startedByUser ||
          this.preppedCider.state === CiderRatingState.finishedByUser) return 'currentCiderToRate'
        if (this.preppedCider.state === CiderRatingState.restartedByChair) return 'againCiderToRate'
        return 'nextCiderToRate'
      }
      if (this.ratingType === "unfinished") {
        return 'unfinishedCiderToRate'
      }
      return 'nextCiderToRate'
    },
    nextCiderButtonText() {
      if (this.cider === null) return null
      if (this.preppedCider.state === CiderRatingState.notStarted ||
        this.preppedCider.state === CiderRatingState.startedByChair) return 'startRating'
      else return 'gotoRating'
    }
  },
  // watch: {
    // tischfunktionen: async function (newValue, oldValue) {
    //   if (this.cider === null || newValue === undefined || oldValue === undefined) return;
    //   if (newValue.nextCiderId === this.cider.id || oldValue.nextCiderId === this.cider.id) {
    //     console.log("Got new tisch", newValue)
    //     const value = newValue.nextCiderId !== null;
    //     console.log("Tischfunktionen changed, updating req cider", newValue, oldValue)
    //     await this.updateRequestedCider(value, true);
    //   }
    // },
  // },
  methods: {
    async requestCider_() {
      window.clearTimeout(this.requestCiderLoadingHandle);
      this.requestCiderLoadingHandle = window.setTimeout(() => {this.requestCiderLoading = true}, 1000)
      const newRequestedValue = await this.requestCider(this.cider);
      this.$store.getters["ciderStore/getCiderById"](this.cider.id, this.cider.flightId).requestCider = newRequestedValue;
      if (this.requestCiderLoadingHandle != null) window.clearTimeout(this.requestCiderLoadingHandle);
      this.requestCiderLoading = false;
    },
    async gotoRating () {
      // this.$store.state.ciderStore.currentCiderIdx = this.$store.getters["ciderStore/getCiderIdx"](this.cider)
      this.$store.state.ciderStore.currentFlightsCidersId = this.cider.flightsCidersId;
      const path = makeRoute(
        this.makeTastingRoute(allRoutes.tastings.rating, this.getCurrentTasting),
        { flightsCidersId: this.cider.flightsCidersId});
      console.log("CIder card goto ", path)
      await this.$router.push(path);
    },
    getTastingLogoUrl() {
      return apiTyped.getImgUrl(this.$store.getters['usersStore/currentTasting']().logoMesse, 500)
    },
    onBadgeLoaded() {
      Vue.set(this, 'badgeLoaded', true);
    }
    // async updateRequestedCider(forceValue = undefined, onlyFlightsCiders = false) {
    //   if (forceValue === undefined) forceValue = !this.cider.requestCider;
    //   const newRequestCider =
    //     await apiTyped.updateFlightsCidersRequest(this.cider.flightsCidersId, forceValue);
    //   this.$emit('updateCider', {ciderId: this.cider.id, field: "requestCider", value: newRequestCider})
    //   // console.log("Force upd", this.$store.getters["ciderStore/getNextCider"]())
    //   if (!onlyFlightsCiders
    //       && this.$store.getters["ciderStore/getNextCider"]() !== undefined
    //       && this.$store.getters["ciderStore/getNextCider"]().id === this.cider.id) {
    //     const idOrNull = newRequestCider ? this.cider.id : null
    //     this.tischfunktionen = await this.updateNextCider(this.tischfunktionen.nextCiderId, idOrNull)
    //   }
    // }
  },
}
</script>

<style scoped>

</style>