<template>
  <v-card
    outlined
    class="pa-4"
  >
    <div class="d-flex">
      <span class="text-left text-h5">
        {{ $t('billingInformation') }}
      </span>
      <v-spacer />
      <v-btn
        icon
        @click="enableEdit"
      >
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
    </div>
    <v-form
      v-show="!valid"
      ref="form"
      v-model="valid"
      class="pb-2"
    >
      <v-input
        v-model="producerDataValid"
        :rules="[v => v === true || 'Billing Information are incomplete']"
        class="d-flex justify-center ml-auto mr-auto"
      />
    </v-form>
    <div>
      <div class="d-flex pb-1">
        <v-icon
          class="mr-2"
          small
        >
          mdi-domain
        </v-icon>
        <span class="d-flex info-label-text text-left">
          {{ $t('companyInfo') }}
        </span>
      </div>
      <v-row no-gutters>
        <v-col
          cols="7"
          class="pb-1"
        >
          <div class="d-flex flex-column align-start pl-2">
            <span class="info-label text--secondary text-caption text-left">
              {{ $t('companyName') }}
            </span>
            <span class="data-entry text-caption text-left">
              {{ value.companyName || '-' }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="5"
        >
          <div class="d-flex flex-column align-start pl-2">
            <span class="info-label text--secondary text-caption text-left">
              {{ $t('legalForm') }}
            </span>
            <span class="data-entry text-caption text-left">
              {{ value.legalForm || '-' }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          class="pb-1"
        >
          <div class="d-flex flex-column align-start pl-2">
            <span class="info-label text--secondary text-caption text-left">
              {{ $t('contactPerson') }}
            </span>
            <span class="data-entry text-caption text-left">
              {{ value.contactPerson || '-' }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="7"
        >
          <div class="d-flex flex-column align-start pl-2">
            <span class="info-label text--secondary text-caption text-left">
              {{ $t('website') }}
            </span>
            <span class="data-entry text-caption text-left">
              {{ value.website || '-' }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="5"
        >
          <div class="d-flex flex-column align-start pl-2">
            <span class="info-label text--secondary text-caption text-left">
              {{ $t('euVatNo') }}
            </span>
            <span class="data-entry text-caption text-left">
              {{ value.vat || '-' }}
            </span>
          </div>
        </v-col>
      </v-row>

      <div class="d-flex pt-2">
        <v-icon
          class="mr-2"
          small
        >
          mdi-home-city
        </v-icon>
        <span class="d-flex info-label-text text-left">
          {{ $t('address') }}
        </span>
      </div>
      <v-row no-gutters>
        <v-col
          cols="7"
        >
          <div class="d-flex flex-column align-start pl-2">
            <span class="info-label text--secondary text-caption text-left">
              {{ $t('streetName') }}
            </span>
            <span class="data-entry text-caption text-left">
              {{ value.street || '-' }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="5"
        >
          <div class="d-flex flex-column align-start pl-2">
            <span class="info-label text--secondary text-caption text-left">
              {{ $t('streetNumber') }}
            </span>
            <span class="data-entry text-caption text-left">
              {{ value.streetNumber || '-' }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="7"
        >
          <div class="d-flex flex-column align-start pl-2">
            <span class="info-label text--secondary text-caption text-left">
              {{ $t('city') }}
            </span>
            <span class="data-entry text-caption text-left">
              {{ value.city || '-' }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="5"
        >
          <div class="d-flex flex-column align-start pl-2">
            <span class="info-label text--secondary text-caption text-left">
              {{ $t('postalCode') }}
            </span>
            <span class="data-entry text-caption text-left">
              {{ value.postalCode || '-' }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="7"
        >
          <div class="d-flex flex-column align-start pl-2">
            <span class="info-label text--secondary text-caption text-left">
              {{ $t('province') }}
            </span>
            <span class="data-entry text-caption text-left">
              {{ value.province || '-' }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="5"
        >
          <div class="d-flex flex-column align-start pl-2">
            <span class="info-label text--secondary text-caption text-left">
              {{ $tc('country', 1) }}
            </span>
            <span class="data-entry text-caption text-left">
              {{ value.country != null ? value.country.name : '-' }}
            </span>
          </div>
        </v-col>
      </v-row>

      <div class="d-flex pt-2">
        <v-icon
          class="mr-2"
          small
        >
          mdi-account-outline
        </v-icon>
        <span class="d-flex info-label-text text-left">
          {{ $t('contactInformation') }}
        </span>
      </div>
      <v-row no-gutters>
        <v-col
          cols="7"
        >
          <div class="d-flex flex-column align-start pl-2">
            <span class="info-label text--secondary text-caption text-left">
              {{ $t('email') }}
            </span>
            <span class="data-entry text-caption text-left">
              {{ value.email || '-' }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="5"
        >
          <div class="d-flex flex-column align-start pl-2">
            <span class="info-label text--secondary text-caption text-left">
              {{ $t('phoneNumber') }}
            </span>
            <span class="data-entry text-caption text-left">
              {{ value.mobilePhone || '-' }}
            </span>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="edit"
      persistent
      max-width="1000px"
      style="scrollbar-gutter: stable both-edges;"
      :fullscreen="isSmallScreen"
    >
      <v-card
        class="pa-4"
        color="background"
      >
        <div class="d-flex text-left text-h4 align-center pl-4 pt-4 pb-0 mb-2">
          <v-btn
            icon
            class="mb-auto mr-3"
            @click="closeProducerEdit(false)"
          >
            <v-icon size="32">
              mdi-close
            </v-icon>
          </v-btn>
          {{ $t('editAccountInformation') }}
        </div>
        <producer-edit-information-form
          v-show="edit"
          style="width: 100%"
          class="d-flex"
          :producer-data="value"
          :countries="countries"
          :redirect-url="''"
          :hide-success="true"
          :hide-reset="true"
          hide-delete-account
          @submit="closeProducerEdit"
          @updateData="setNewProducerData"
          @changeCounter="setEditInformationChanged"
        />
      </v-card>
    </v-dialog>
    <producer-edit-information-form
      v-show="false"
      :key="reRenderCopy"
      ref="editInformationForm"
      :producer-data="dataCopy"
      :countries="countries"
      :redirect-url="''"
      :hide-success="true"
      hide-delete-account
      :hide-reset="true"
    />
    <Confirm_dialog ref="confirm" />
  </v-card>
</template>

<script>
import Vue from "vue";
import ProducerEditInformationForm from "@/components/producer/producer_edit_information_form";
import Confirm_dialog from "@/components/confirm_dialog";

export default {
  name: "ProducerDataCard",
  components: {Confirm_dialog, ProducerEditInformationForm},
  props: {
    value: { type: Object, required: true, default: null },
    countries: { type: Array, required: false, default: null },
  },
  data: () => ({
    edit: false,
    editInformationChanged: false,
    producerDataValid: false,
    valid: false,
    dataCopy: null,
    reRenderCopy: 0,
  }),
  computed: {
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        default: return false
      }
    },
  },
  watch: {
    value: async function (val) {
      this.reRenderCopy += 1;
      Vue.set(this, 'dataCopy', val);
      await this.$nextTick();
      await this.validate();
    },
  },
  created() {
    this.dataCopy = this.value;
  },
  async mounted() {
    await this.validate();
  },
  methods: {
    async validate() {
      this.producerDataValid = await this.$refs.editInformationForm.validate();
      await this.$refs.form.validate();
      console.log("producerDataValid", this.producerDataValid)
      this.$emit('valid', this.producerDataValid);
      return this.producerDataValid;
    },
    enableEdit() {
      this.edit = true;
    },
    async setNewProducerData(data) {
      this.$emit('input', data);
      await this.validate();
    },
    setEditInformationChanged(count) {
      if (count > 0) this.editInformationChanged = true;
      if (count === 0) this.editInformationChanged = false;
    },
    async closeProducerEdit(result) {
      if (this.editInformationChanged) {
        const okayToLeave = await this.$refs.confirm.open(
          this.$t('unsavedChanges'), this.$t('unsavedChangesMessageProducer'),
          { color: 'secondary_card', thirdButton: true, thirdButtonText: this.$t('discard') }, {btnOkay: 'stay'})
        if (okayToLeave === "third") this.edit = false;
      } else {
        this.edit = false;
      }
      // console.log("Close result", result, this.value)
      this.producerDataValid = result;
      if (!result) {
        this.producerDataValid = await this.validate();
        // console.log("Eval", this.producerDataValid)
      }
    }
  },
}
</script>

<style scoped>
.info-label {
  font-size: 12px;
}
.data-entry {
  font-size: 14px;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  width: 100%;
  justify-self: start;
}
.info-label-text {
  text-decoration: underline var(--v-divider-base);
  text-underline-offset: 4px;
}
/deep/ ::-webkit-scrollbar-track {
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 20px;
}
/deep/ ::-webkit-scrollbar {
  width: 10px;
}
</style>