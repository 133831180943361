<template>
  <v-card
    v-if="ciders != null"
    outlined
    color=""
    class="ml-auto mr-auto ma-0 overflow-hidden"
    style="border-top: 0 !important;"
  >
    <v-progress-linear
      :value="finishedCiders"
      color="tertiary_container darken-1"
      rounded
      height="8"
      style="position: absolute; top: 0;"
      class="rounded-xl"
    />
    <v-card-title
      v-ripple
      class="pb-2"
      @click="expandCard()"
    >
      {{ tableChair.firstName }} {{ tableChair.lastName }}
      <v-spacer />
      <v-btn
        icon
        @click="expandCard()"
        @click.native.stop=""
      >
        <v-icon
          v-show="expanded"
        >
          mdi-format-vertical-align-center
        </v-icon>
        <v-icon
          v-show="!expanded"
          class="text--secondary"
        >
          mdi-arrow-expand-vertical
        </v-icon>
      </v-btn>
      <strong>
        {{ number }}
      </strong>
    </v-card-title>

    <v-card-text v-if="finishedTable">
      <v-icon left>
        mdi-check-circle-outline
      </v-icon>
      Table finished
    </v-card-text>

    <v-expand-transition>
      <div
        v-show="expanded"
        v-scroll.self="onScroll"
        class="overflow-y-auto"
        style="max-height: 300px;"
      >
        <div v-show="cidersBeforeCurrentlyRated == null">
          <span class="text--secondary text-caption">
            No previous products
          </span>
        </div>
        <div
          v-for="(cider, index) in cidersBeforeCurrentlyRated"
          :key="cider.flightsCidersId"
        >
          <v-divider
            v-if="index > 0 && index !== cidersBeforeCurrentlyRated.length && cider.flightId === cidersBeforeCurrentlyRated[index-1].flightId"
            class="pb-0 pt-1 ml-auto mr-auto"
            style="max-width: 32px !important;"
          />
          <v-divider
            v-if="index > 0 && index !== cidersBeforeCurrentlyRated.length-1 && cider.flightId !== cidersBeforeCurrentlyRated[index-1].flightId"
            class="pb-1 mt-2 mr-16 ml-16"
          />
          <div
            v-if="index === 0 || (index !== cidersBeforeCurrentlyRated.length-1 && cider.flightId !== cidersBeforeCurrentlyRated[index-1].flightId)"
            class="text--secondary text-caption text-center pb-1"
          >
            <v-icon
              small
              class="pr-1 text--secondary"
            >
              mdi-airplane-takeoff
            </v-icon>
            <span class="text-flight-name">
              <strong>{{ cider.flightName }}</strong>
            </span>
          </div>
          <div
            v-if="cider.requestCider || (cider.chairNotes != null && cider.chairNotes.restarted)"
            style="position: relative;"
          >
            <v-icon
              color="primary"
              style="position: absolute; right: 4px; top: 50%;"
            >
              {{ getCiderIcon(cider) }}
            </v-icon>
          </div>
          <div
            class="d-flex flex-column overflow-hidden pl-4 pr-4 mr-4"
            style="width: 100%;"
          >
            <v-tooltip
              top
            >
              <template #activator="{ on }">
                <div
                  class="pa-0 text-subtitle-1 text--secondary pb-0 text-ellipsis text-no-wrap text-left"
                  style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; line-height: 1.2;"
                  v-on="on"
                >
                  {{ index + 1 }}: {{ cider.name }}
                </div>
              </template>
              {{ index + 1 }}: {{ cider.name }}
            </v-tooltip>
            <v-tooltip
              top
            >
              <template #activator="{ on }">
                <div
                  class="pa-0 pb-0 text--secondary font-weight-400 text-left"
                  style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; line-height: 1.2; font-size: 0.875rem;"
                  v-on="on"
                >
                  <strong>{{ cider.displayId }}</strong>:
                  <v-icon
                    small
                    class="pl-0 pr-0"
                    style="padding-bottom: 2px;"
                    :color="getCiderStateColorByState(cider)"
                  >
                    {{ getCiderStateIconByState(cider) }}
                  </v-icon>
                  {{ cider.kategorie.name }}
                </div>
              </template>
              {{ cider.kategorie.name }}
            </v-tooltip>
          </div>
        </div>
      </div>
    </v-expand-transition>
    <v-divider 
      v-show="expanded" 
      class="mb-4" 
    />
  
    <template
      v-if="currentlyRatedCider != null"
    >
      <!--Show alert when requested or restarted:-->
      <table-service-card-alert
        :cider="currentlyRatedCider"
        :alert-icon="currentlyRatedCider.requestCider ? 'mdi-share-circle' : 'mdi-refresh-circle'"
        :progress="currentCiderProgress"
        current-cider
        prefix-cider-name="Current: "
        @updateRequestedCider="updateRequestedCider"
        @rerender="rerenderMasonry"
      />
    </template>

    <!--Next Cider To Rate-->
    <template
      v-if="nextCiderToRate != null"
    >
      <table-service-card-alert
        :key="recomputeNextCider"
        :cider="nextCiderToRate"
        :alert-icon="nextCiderIcon"
        :progress="null"
        prefix-cider-name="Next: "
        bold-text
        class="pt-1 pb-3"
        @updateRequestedCider="updateRequestedCider"
        @updateAutoRequestedCider="updateAutoRequestedCider"
        @rerender="rerenderMasonry"
      />
    </template>

    <template
      v-if="requestedCiders != null"
    >
      <table-service-card-alert
        v-for="requestedCider in requestedCiders"
        :key="`${requestedCider.flightsCidersId}_requested`"
        :cider="requestedCider"
        :alert-icon="requestedCider.chairNotes != null && requestedCider.chairNotes.restarted ? 'mdi-refresh-circle' : 'mdi-share-circle'"
        :progress="null"
        prefix-cider-name="Requested: "
        @updateRequestedCider="updateRequestedCider"
        @updateAutoRequestedCider="updateAutoRequestedCider"
        @rerender="rerenderMasonry"
      />
    </template>

    <v-divider
      v-show="expanded && !finishedTable"
      class="mb-1"
    />
    <v-expand-transition>
      <div
        v-show="expanded"
        v-scroll.self="onScroll"
        class="overflow-y-auto mb-1"
        style="max-height: 300px;"
      >
        <div v-show="cidersAfterCurrentlyAndNext == null && !finishedTable">
          <span class="text--secondary text-caption">
            No further products
          </span>
        </div>
        <div
          v-for="(cider, index) in cidersAfterCurrentlyAndNext"
          :key="cider.flightsCidersId"
        >
          <v-divider
            v-if="index > 0 && index !== cidersAfterCurrentlyAndNext.length && cidersAfterCurrentlyAndNext[index-1] != null && cider.flightId === cidersAfterCurrentlyAndNext[index-1].flightId"
            class="pb-0 pt-1 ml-auto mr-auto"
            style="max-width: 32px !important;"
          />
          <v-divider
            v-if="index > 0 && index !== cidersAfterCurrentlyAndNext.length-1 && cider.flightId !== cidersAfterCurrentlyAndNext[index-1].flightId"
            class="pb-1 mt-2 mr-16 ml-16"
          />
          <div
            v-if="index === 0 || (index !== cidersAfterCurrentlyAndNext.length-1 && cider.flightId !== cidersAfterCurrentlyAndNext[index-1].flightId)"
            class="text--secondary text-caption text-center pb-1"
          >
            <v-icon
              small
              style="text-decoration: none !important;"
              class="pr-1 text--secondary"
            >
              mdi-airplane-takeoff
            </v-icon>
            <span class="text-flight-name">
              <strong>{{ cider.flightName }}</strong>
            </span>
          </div>
          <div
            v-if="cider.requestCider || (cider.chairNotes != null && cider.chairNotes.restarted)"
            style="position: relative;"
          >
            <v-icon
              color="primary"
              style="position: absolute; right: 4px; top: 50%;"
            >
              {{ getCiderIcon(cider) }}
            </v-icon>
          </div>
          <div
            class="d-flex flex-column overflow-hidden pl-4 pr-4 mr-4"
            style="width: 100%;"
          >
            <v-tooltip
              top
            >
              <template #activator="{ on }">
                <div
                  class="pa-0 text-subtitle-1 text--secondary pb-0 text-ellipsis text-no-wrap text-left"
                  style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; line-height: 1.2;"
                  v-on="on"
                >
                  {{ index + 1 + cidersBeforeCurrentlyRated.length + (nextCiderToRate != null | 0) + (currentlyRatedCider != null | 0) }}: {{ cider.name }}
                </div>
              </template>
              {{ index + 1 + cidersBeforeCurrentlyRated.length + (nextCiderToRate != null | 0) + (currentlyRatedCider != null | 0) }}: {{ cider.name }}
            </v-tooltip>
            <v-tooltip
              top
            >
              <template #activator="{ on }">
                <div
                  class="pa-0 pb-0 text--secondary font-weight-400 text-left"
                  style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; line-height: 1.2; font-size: 0.875rem;"
                  v-on="on"
                >
                  <strong>{{ cider.displayId }}</strong>:
                  <v-icon
                    small
                    class="pl-0 pr-0"
                    style="padding-bottom: 2px;"
                    :color="getCiderStateColorByState(cider)"
                  >
                    {{ getCiderStateIconByState(cider) }}
                  </v-icon>
                  {{ cider.kategorie.name }}
                </div>
              </template>
              {{ cider.kategorie.name }}
            </v-tooltip>
          </div>
        </div>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import {Table} from "@/types/table";
import CiderRatingState from "@/types/ciderRatingState";
import TableServiceCardAlert from "@/components/service/table_service_card_alert";

export default {
  name: "TableServiceCard",
  components: {TableServiceCardAlert},
  props: {
    table: {type: Object, required: true, default: null},
    ciders: {type: Array, required: true, default: null},
    number: {type: Number, required: true, default: -1},
    autoExpand: {type: Boolean, required: false, default: true},
  },
  data: () => ({
    expanded: false,
    minimizeTimeout: null,
    autoRequestedRemoved: false,
    recomputeNextCider: 0,
  }),
  computed: {
    tableChair() {
      return this.table.juroren.find(j => j.id === this.table.chair);
    },
    finishedCiders() {
      return this.ciders.filter(c => c.chairNotes?.finished).length / this.ciders.length * 100;
    },
    finishedTable() {
      return this.currentlyRatedCider == null
        && this.nextCiderToRate == null
        && this.ciders.every(c => c.chairNotes?.finished);
    },
    nextCiderIcon() {
      return this.getCiderIcon(this.nextCiderToRate);
    },
    currentlyRatedCiderIdx() {
      const idx = this.ciders.findIndex(c =>
        (c.chairRatingState === CiderRatingState.startedByChair &&
         c.chairRatingState !== CiderRatingState.finishedByChair &&
         c.chairRatingState !== CiderRatingState.restartedByChair)
      )
      return idx !== -1 ? idx : null;
    },
    currentlyRatedCider() {
      // return this.ciders[0];  // TODO remove
      if (this.currentlyRatedCiderIdx == null) return null;
      return this.ciders[this.currentlyRatedCiderIdx];
    },
    currentCiderProgress() {
      if (this.currentlyRatedCider == null) return 100;
      return this.currentlyRatedCider.progress * 100;
    },
    requestedCiders() {
      // Requested ciders are: restarted, nearly finished or restarted
      const nextCiderToRateFlightsCidersId = this.nextCiderToRate != null ? this.nextCiderToRate.flightsCidersId : '-1'
      return this.ciders.filter(c =>
        c.requestCider === true
        && c.flightsCidersId !== nextCiderToRateFlightsCidersId
        && (this.currentlyRatedCider == null || (c.flightsCidersId !== this.currentlyRatedCider.flightsCidersId))
      );
    },
    nextCiderToRate() {
      // noinspection BadExpressionStatementJS
      this.recomputeNextCider;  // for force recompute
      if (this.currentlyRatedCiderIdx == null){
        const c = this.ciders.find(c => c.chairRatingState === CiderRatingState.notStarted);
        if (c != null) return c;
        if (this.currentlyRatedCiderIdx + 1 < this.ciders.length) return null;
      }
      const nextCiderToRate_ = this.ciders[this.currentlyRatedCiderIdx + 1];
      // const nextCiderToRate_ = this.ciders[1];  // TODO remove
      if (nextCiderToRate_ != null ) {
        if (nextCiderToRate_.progress > 0.85 && this.autoRequestedRemoved === false) {
          nextCiderToRate_.autoRequestCider = true;
        } else {
          nextCiderToRate_.autoRequestCider = false;
        }
      }
      return nextCiderToRate_;
    },
    cidersBeforeCurrentlyRated() {
      const idx = this.currentlyRatedCiderIdx
        || (this.nextCiderToRate != null ? this.ciders.findIndex(c => c.id === this.nextCiderToRate.id) : null);
      if (idx == null || idx === -1) return this.ciders;
      return this.ciders.slice(0, idx);
    },
    cidersAfterCurrentlyAndNext() {
      if (this.currentlyRatedCiderIdx == null && this.nextCiderToRate == null) return null;
      if (this.nextCiderToRate == null
        && this.currentlyRatedCiderIdx != null
        && this.currentlyRatedCiderIdx + 2 < this.ciders.length) return null;
      if (this.currentlyRatedCiderIdx == null && this.nextCiderToRate != null) {
        const idx = this.ciders.findIndex(c => c.id === this.nextCiderToRate.id);
        if (idx !== -1) return this.ciders.slice(idx + 1);  // +1 since only next cider is displayed, no current cider
      }
      return this.ciders.slice(this.currentlyRatedCiderIdx + 2);
    }
  },
  watch: {
    ciders: function () {
      window.setTimeout(() => {this.$redrawVueMasonry('serviceTables')}, 50);
    },
    nextCiderToRate: function (oldCider, newCider) {
      if (oldCider.id !== newCider.id) {
        console.log("Next has changed")
        this.autoRequestedRemoved = false;
      }  // reset autoRequestedRemoved if next cider changed
    },
  },
  methods: {
    getCiderIcon(cider) {
      if (cider == null) return '';
      if (cider.requestCider) return 'mdi-share-circle';
      if (cider.chairNotes?.restarted) return 'mdi-refresh-circle';
      if (cider.progress > 0.85) return 'mdi-star-check';
      return 'mdi-share-circle'
    },
    getCiderStateColorByState(cider) {
      return this.$store.getters["ciderStore/getCiderStateIconColorByState"](cider.chairRatingState, this.$vuetify)[1];
    },
    getCiderStateIconByState(cider) {
      return this.$store.getters["ciderStore/getCiderStateIconColorByState"](cider.chairRatingState, this.$vuetify)[0];
    },
    expandCard() {
      this.expanded = !this.expanded;
      if (this.expanded && this.autoExpand) {
        this.minimizeTimeout = window.setTimeout(() => { this.expandCard(); }, 15000);
      } else {
        window.clearTimeout(this.minimizeTimeout);
      }
      const start = 0;
      const end = 500;
      const step = 10;
      const arrayLength = Math.floor(((end - start) / step)) + 1;
      for (const i of [...Array(arrayLength).keys()].map(x => (x * step) + start)) {
        window.setTimeout(() => {this.$redrawVueMasonry('serviceTables')}, i);
      }
    },
    updateRequestedCider(cider) {
      this.$emit('updateRequestedCider', cider);
      window.setTimeout(() => {this.$redrawVueMasonry('serviceTables')}, 50);
    },
    updateAutoRequestedCider() {
      this.autoRequestedRemoved = true;
      this.recomputeNextCider++;
    },
    onScroll() {
      // reset timeout for collapse when scrolling
      window.clearTimeout(this.minimizeTimeout);
      this.minimizeTimeout = window.setTimeout(() => { this.expandCard(); }, 15000);
    },
    rerenderMasonry() {
      window.setTimeout(() => {this.$redrawVueMasonry('serviceTables')}, 150);
    }
  }
}
</script>

<style scoped>
.text-flight-name {
  text-decoration: underline var(--v-primary-base);
  text-underline-offset: 10%;
  text-decoration-thickness: 2px;
}
</style>