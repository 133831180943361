<template>
  <page
    v-if="cider != null"
    ref="pageA4"
    :key="activeWindow"
    size="A4"
    class="pa-8 pb-2 ma-0"
    style="transform-origin: 0 0; overflow: scroll;"
    :style="{'background-color': $vuetify.theme.currentTheme.page_background}"
    :class="{'page-dark': $vuetify.theme.dark, 'page-light': !$vuetify.theme.dark}"
  >
    <div
      v-if="badgesData != null"
      id="preload"
    >
      <v-img
        v-for="badge in badgesDataArray"
        :key="`badge_${badge.id}`"
        :src="getBadgeUrlPreload(badge.punkteMin, 500, false)"
      />
      <!--Preload best of Category Badges too-->
      <v-img
        v-for="badge in badgesDataArray"
        :key="badge.id + '_bestOfCategory'"
        :src="getBadgeUrlPreload(badge.punkteMin, 500, true)"
      />
    </div>
    <div style="position: relative;">
      <v-img
        v-if="badgesData != null && badgesData.hasImage"
        contain
        :lazy-src="getImgUrl(tasting.logoMesse, 500)"
        :src="getImgUrl(tasting.logoMesse, 500)"
        alt="CiderAwardLogo"
        max-width="300px"
        style="position: absolute; right: 10px; top: 10px; opacity: 0.35"
        @load="initCounter('logo')"
      />
    </div>
    <div
      v-if="cider != null"
      class="page_grid"
    >
      <GesamtauswertungHeader 
        :cider="cider"
        :badges-data="badgesData"
        :best-of-category="bestOfCategory"
        :tasting="tasting"
        @init="initCounter('header')"
      />
      <GesamtauswertungTable
        :cider="cider"
        :show-individual="showIndividual"
        :max-points="maxPoints"
      />
      <CiderFooter
        :force-show-badges="true"
        :show-date="true"
        :show-logo="true"
        :badge-shadow="false"
        :show-current-year="false"
        :badges-data-array="badgesDataArray"
        :tasting="tasting"
        :background-color="$vuetify.theme.currentTheme.page_background"
        style="align-self: flex-end;"
        @init="initCounter('footer')"
      />
    </div>
  </page>
</template>

<script>
import Vue from "vue"
import GesamtauswertungHeader from "@/components/admin/gesamtauswertung/header";
import GesamtauswertungTable from "@/components/admin/gesamtauswertung/table";
import CiderFooter from "@/components/cider_footer";
import {apiTyped} from "@/api";
import logos_mixin from "@/components/mixins/logos_mixin";
import cider_mixin from "@/components/mixins/cider_mixin";
import i18n from '@/i18n'

Vue.config.ignoredElements = ['page'];

export default {
  name: "GesamtauswertungPage",
  components: {CiderFooter, GesamtauswertungTable, GesamtauswertungHeader},
  mixins: [logos_mixin, cider_mixin],
  props: {
    cider: {type: Object, required: true, default: () => null},
    badgesData: {type: Object, required: false, default: () => null},
    showIndividual: {type: Boolean, required: true, default: true},
    badgesDataArray: {type: Array, required: true, default: null},
    tasting: {type: Object, required: true, default: null},
    bestOfCategory: {type: Boolean, required: false, default: false},
    activeWindow: {type: Number, required: false, default: 0},
  },
  data: () => ({
    componentsLoaded: 0,
    pageScale: 1,
  }),
  computed: {
    maxPoints() {
      let max = 0;
      if (this.badgesDataArray == null) return null;
      this.badgesDataArray.forEach(b => {
        if (b.punkteMax > max) max = b.punkteMax;
      });
      return max;
    },
    totalPoints() {
      return this.calculateTotalPointsForCider(this.cider, true, false, 0.1);
    },
  },
  methods: {
    getImgUrl(id, width) {
      return apiTyped.getImgUrl(id, width);
    },
    initCounter(comp) {
      this.componentsLoaded = this.componentsLoaded + 1;
      if (this.componentsLoaded >= 3) this.$emit('init');
    },
    getBadgeUrl(punkte, width) {
      return this.getBadgeUrlPreload(punkte, width, this.bestOfCategory)
    },
    getBadgeUrlPreload(punkte, width, bestOfCat) {
      const badgeImageId = bestOfCat ? this.badgesData.bestOfCategory : this.badgesData.icon;
      if (badgeImageId == null) return "";
      return apiTyped.getBadgeImgUrl(badgeImageId, width);
    }
  },
}
</script>

<style scoped>

page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
  min-height: 29.7cm;
  min-width: 21cm;
  max-height: 29.7cm;
  max-width: 21cm;
  overflow: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

page::-webkit-scrollbar {
  display: none;
}

.page_grid {
  display: grid;
  align-items: stretch;
  height: 100%;
  grid-template-rows: min-content auto auto;
}

page {
  display: block;
  margin: 0 auto 0.5cm;
}

@media print {
  page {
    width: 21cm;
    height: 29.7cm;
    min-height: 29.7cm;
    min-width: 21cm;
    max-height: 29.7cm;
    max-width: 21cm;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: unset !important;
  }
}

div#preload {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.page-light {
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.page-dark {
  border: 1px solid;
  border-color: var(--v-divider-base) !important;
  border-radius: 5px;
}
</style>