import {apiTyped} from "@/api";
import {Theme} from "@/types/user";
import {allRoutes, makeRoute} from "@/router";

export default {
    computed: {
        currentLanguage() {
            return this.$store.state.usersStore.language;
        },
        allRoutes() {
            return allRoutes;
        }
    },
    methods: {
        makeRoute(route, params) {
          return makeRoute(route, params);
        },
        getUserAvatar(width, user) {
            try {
                return apiTyped.getUserAvatarUrl(width, user, this.$vuetify.theme.currentTheme);
            } catch(error) {
                console.error("Error getting Avatar URL:", error)
                return false;
            }
        },
        async toggleDarkMode() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            await apiTyped.updateTheme(this.$vuetify.theme.dark ? 'dark' : 'light');
        },
        // async setTheme(themeName) {
        //     const theme = this.$vuetify.theme.defaults[themeName];
        //     const name = theme.name;
        //     const dark = theme.dark;
        //     const light = theme.light;
        //     // set themes
        //     Object.keys(dark).forEach(i => {
        //         this.$vuetify.theme.themes.dark[i] = dark[i];
        //     });
        //     Object.keys(light).forEach(i => {
        //         this.$vuetify.theme.themes.light[i] = light[i];
        //     });
        //     // also save theme name to disable selection
        //     this.$vuetify.theme.themes.name = name;
        // },
        setTheme() {
            const userTheme = this.$store.getters["usersStore/getUserTheme"]();
            let isDark = userTheme === Theme.Dark;
            if (userTheme === Theme.Auto) {
                isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            }
            // console.log("Setting theme", isDark, userTheme)
            if (!isDark) this.$vuetify.theme.dark = false;
            if (isDark) this.$vuetify.theme.dark = true;
        }
    }
};

