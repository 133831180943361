






















import { Component, Vue } from 'vue-property-decorator';
import {apiTyped} from "@/api";
import i18n from "@/i18n";
import {Theme} from "@/types/user";

@Component({
  name: 'App',
  data: () => ({
    loaded: false,
  }),
  async beforeCreate () {
    // this.$store.state.languages = await apiTyped.getLanguages();
    // api.login(this.$store.state.user.email, this.$store.state.user.password)
    // api.getWines().then(response => {
    //   this.$store.set("wines", response.data)
    //   // this.info = response.data;
    // })
    //     .catch(error => {
    //       console.log(error)
    //       this.errored = true
    //     })
    //     .finally(() => this.loading = false);
  },
  async created() {
    console.log("Environment:", process.env.NODE_ENV);
    console.log("App version", this.$store.state.appVersion, "current:", this.$store.state.currentAppVersion);
    if (this.$store.state.currentAppVersion == 0 || this.$store.state.currentAppVersion != this.$store.state.appVersion) {
      console.log("Old App Version, performing a Logout.")
      if (apiTyped.isLoggedIn()) await apiTyped.logout();
      localStorage.clear();
      //@ts-ignore
      this.$store.set('currentAppVersion', this.$store.state.appVersion);
      //@ts-ignore
      window.location.reload(true);
      // try {
      //   await this.$router.push('/login');
      // } catch (e) {}
    }
    // Set Locale
    i18n.locale = this.$store.getters["usersStore/getUserLanguageCode"]();
    // Set Theme
    const userTheme = this.$store.getters["usersStore/getUserTheme"]();
    let isDark = userTheme === Theme.Dark;
    if (userTheme === Theme.Auto) {
      isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
    if (!isDark) this.$vuetify.theme.dark = false;
    if (isDark) this.$vuetify.theme.dark = true;
    // this.$store.state.languages = await apiTyped.getLanguages();
  },
  mounted() {
    // document.title = "Cider World"
    // this.$router.push("/login")
  },
  computed: {
    reloadRouter() {
      return this.$store.state.reload_router;
    }
  },
})
export default class App extends Vue {}
