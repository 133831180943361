import {apiTyped} from "@/api";
import login_mixin from "@/components/mixins/login_mixin";
import {RoleName} from "@/types/user";
import router from "@/router";

export default {
    methods: {
        async tastingCreated() {
            this.longLoading = false;
            setTimeout(() => this.longLoading = true, 5000)
            console.log("Tasting layout mounted.")
            this.loadingMessage = "Connecting...";
            const loginUser = await login_mixin.methods.refreshLogin(RoleName.Juror)
            if (loginUser == null) {
                await this.$router.push("/login");
                return;
            }
            this.$store.commit("usersStore/setUser", loginUser);
            this.setTheme();

            console.log("GOT TASTING ID___", this.tastingId)
            if (this.$store.state.usersStore.currentTastingId == null
                || this.$store.state.usersStore.currentTastingId === -1) {
                // Get all tastings for tasting dashboard
                const tastings = await apiTyped.getTastings();
                console.log("GETTING TASTINGS::::::", tastings)
                if (tastings.length === 0) {
                    console.error("No tasting found")
                    this.errorMsg = 'noTastingFound';
                    // throw {
                    //     name: 'NoTastingsError',
                    //     message: 'No Tasting found for current user.'
                    // };
                }
                this.$store.state.usersStore.tastings = tastings;
            } else {
                this.$store.state.usersStore.tastings = [await apiTyped.getTastingById(this.tastingId)];
                // this.$store.state.usersStore.currentTastingIdx = 0;
            }

            if (this.$store.state.usersStore.tastings != null) {
                this.$store.state.usersStore.tastings.sort((t1, t2) => {
                    if (t1.datum != null && t2.datum != null) return t2.datum.valueOf() - t1.datum.valueOf();
                    if (t1.datum != null && t2.datum == null) return 1;
                    if (t1.datum == null && t2.datum != null) return -1;
                    return 0;
                });
            }
            console.log("Tasting created")
        },
    },
}