<template>
  <div>
    <v-toolbar
      color="transparent"
      flat
      height="48"
      class="mt-2 mb-4"
    >
      <v-tabs
        v-model="selectedTab"
        centered
        slider-color="primary"
        class="rounded-xl"
        active-class="rounded-xl"
      >
        <v-tab
          active-class="rounded-xl"
          class="mr-1"
          :href="`#my-tastings`"
        >
          {{ $tc('events') }}
        </v-tab>
        <v-tab
          active-class="rounded-xl"
          class="ml-1"
          :href="`#my-products`"
        >
          {{ $tc('product', 2) }}
        </v-tab>
      </v-tabs>
    </v-toolbar>

    <v-tabs-items
      v-model="selectedTab"
      touchless
      :v-ripple="false"
    >
      <v-tab-item
        value="my-tastings"
        class="pa-4 pt-0"
      >
        <PageNavigation
          :title-translation="$t('events')"
          :breadcrumbs="[{text: $t('home'), disabled: true}]"
          class="pl-3 pb-8"
        />
        <producer-my-tastings />
      </v-tab-item>
      <v-tab-item
        value="my-products"
      >
        <div
          class="pl-md-8 pr-md-8 pl-2 pr-2 mx-auto"
          style="max-width: 2800px;"
        >
          <PageNavigation
            :title-translation="$t('myProducts')"
            :breadcrumbs="[{text: $t('home'), disabled: true}]"
          />

          <div
            class="ml-auto mr-auto pb-4"
          >
            <v-row
              flat
              color="background"
              class="d-flex pa-0 pt-4 pb-0 ma-0"
            >
              <v-fade-transition>
                <v-btn
                  rounded
                  color="primary"
                  elevation="0"
                  class="mr-4 mt-4"
                  @click="$router.push('ciders/create')"
                >
                  <v-icon
                    left
                    size="28"
                  >
                    mdi-plus
                  </v-icon>
                  {{ $t('addProduct') }}
                </v-btn>
              </v-fade-transition>
              <v-spacer />
              <v-btn
                rounded
                outlined
                elevation="0"
                class="mt-4"
                @click="startCiderSubmit"
              >
                <v-icon left>
                  mdi-basket-plus
                </v-icon>
                {{ $t('submitProducts') }}
              </v-btn>
            </v-row>
          </div>

          <producer-all-products
            v-model="ciders"
            :loaded="loaded"
            :loading-more-products="loadingMoreProducts"
            :more-products-available="moreProductsAvailable"
            @loadMore="loadMoreProducts"
            @clickProduct="product => $router.push(`/producer/ciders/view/${product.id}`)"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
    <v-progress-circular
      v-if="!loaded"
      indeterminate
      class="mt-auto mb-auto ml-auto mr-auto"
      size="92"
    />
  </div>
</template>

<script>
import {apiTyped} from "@/api";
import CiderCard from "@/components/cider_card";
import ProducerCiderTable from "@/components/producer/producer_cider_table";
import ProducerMyTastings from "@/components/producer/producer_my_tastings";
import PageNavigation from "@/components/page_navigation";
import i18n from '@/i18n'
import ProducerAllProducts from "@/components/producer/producer_all_products.vue";


export default {
  name: "ProducerDashboard",
  components: {
    ProducerAllProducts,
    PageNavigation,
    ProducerMyTastings,
    // ProducerCiderTable,
    // CiderCard
  },
  data: () => ({
    sortAsc: true,
    sortBy: 'name',
    sortByText: '',
    searchProducts: '',
    loaded: false,
    ciders: [],
    dataMode: "cards",
    loadingMoreProducts: false,
    currentProductsPage: 1,
    moreProductsAvailable: true,
  }),
  computed: {
    sortByList() {
      return [
        {
          text: this.$i18n.t('name'),
          value: 'name'
        },
        {
          text: this.$i18n.tc('category', 1),
          value: 'kategorie'
        },
        {
          text: this.$i18n.t('sortDateCreated'),
          value: 'dateCreated'
        },
        {
          text: this.$i18n.t('sortDateUpdated'),
          value: 'dateUpdated'
        }
      ];
    },
    cidersFiltered() {
      if (this.sortBy === ""){
        const a = 1;
      }
      const search = this.searchProducts == null ? "" : this.searchProducts.toLowerCase();
      if (this.ciders == null) return null;
      let ciderSearched = this.ciders;
      console.log("Srat", search)
      if (search !== "") {
        ciderSearched = this.ciders.filter(
          c => c.ciderInfos.name.toLowerCase().includes(search)
            || c.ciderInfos.kategorie.toLowerCase().includes(search)
            || c.ciderInfos.salesDesignation.toLowerCase().includes(search)
            || c.ciderInfos.designationOfOrigin.toLowerCase().includes(search))
      }
      ciderSearched = ciderSearched.sort(
        (c1, c2) => {
          if (this.sortBy === "name") {
            console.log("this.sortBy", this.sortBy)
            if (!this.sortAsc) return c2.ciderInfos.name.localeCompare(c1.ciderInfos.name);
            return c1.ciderInfos.name.localeCompare(c2.ciderInfos.name);
          }
          if (this.sortBy === "kategorie") {
            if (!this.sortAsc) return c2.ciderInfos.kategorie.localeCompare(c1.ciderInfos.kategorie);
            return c1.ciderInfos.kategorie.localeCompare(c2.ciderInfos.kategorie);
          }
          if (this.sortBy === "dateUpdated") {
            if (!this.sortAsc) return c2.dateUpdated > c1.dateUpdated;
            return c1.dateUpdated > c2.dateUpdated;
          }
          if (this.sortBy === "dateCreated") {
            if (!this.sortAsc) return c2.dateCreated > c1.dateCreated;
            return c1.dateCreated > c2.dateCreated;
          }
        });
      return ciderSearched;
    },
    selectedTab: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get () {
        return this.$route.query.tab
      }
    },
    noCiders() {
      return (this.ciders === null || this.ciders.length === 0);
    }
  },
  async created() {
    // this.sortByText = this.$i18n.t('name');
    await this.loadMoreProducts();
    // this.dataMode = "cards";
    this.loaded = true;
  },
  methods: {
    async loadMoreProducts() {
      console.log("MAIN LOAD MORE")
      if (!this.moreProductsAvailable) return;
      this.loadingMoreProducts = true;
      let sortBy;
      // const filter = this.ciders != null ? {id: {_nin: this.ciders.map(c => c.id)}} : {};
      // if (this.sortBy === "name") sortBy = "name";
      // if (this.sortBy === "kategorie") sortBy = "kategorie";
      // if (this.sortBy === "dateUpdated") sortBy = "date_updated";
      // if (this.sortBy === "dateCreated") sortBy = "date_created";
      const newCiders = await apiTyped.producerGetAllCiders(
        this.$store.state.producerStore.producerId, 0, this.currentProductsPage, 10) || null;
      if (newCiders.length > 0) {
        if (this.ciders == null) this.ciders = [];  // init cider list
        this.ciders.push(...newCiders);
        this.currentProductsPage += 1;
      } else {
        this.moreProductsAvailable = false;
      }
      this.loadingMoreProducts = false;
    },
    startCiderSubmit() {
      this.$store.commit("submitStore/clear");
      this.$router.push('/producer/submit/tastings');
    },
    async onLoadingButtonIntersect() {
      console.log("On intersect button")
      if (!this.loaded || this.loadingMoreProducts) return;
      await this.loadMoreProducts();
    },
  }
}
</script>

<style scoped>
.center-on-page {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
}

/deep/ .v-tabs-items {
  background-color: var(--v-background-base);
}

/deep/ .v-tabs-slider-wrapper {
  height: 48px !important;
  border-radius: 20px !important;
  opacity: 0.5 !important;
}

/deep/ .v-tab::before {
  border-radius: 20px !important;
}

/deep/ .v-tab > .v-ripple__container {
  border-radius: 20px !important;
}

/deep/ .v-tabs-slider {
  border-radius: 20px !important;
}

.rotate-180 {
  -moz-transform:rotate(180deg);
  -webkit-transform:rotate(180deg);
  transform:rotate(180deg);
}

.animate_change {
  transition:all 0.25s ease;
}
</style>