<template>
  <div
    style="color: transparent; overflow: visible !important;"
    :style="{'min-width': badgeWidth + 'px', 'min-height': badgeWidth + 'px'}"
    class="overflow-visible"
  >
    <v-btn
      v-if="badgeData != null"
      fab
      :ripple="false"
      dark
      small
      disabled
      :color="showNoneBadge && !badgeData.hasImage ? 'rgba(204,204,204,0.27)' : 'transparent'"
      style="left: 0; top: 0; overflow: visible !important; aspect-ratio: 1 / 1;"
      :elevation="badgeShadow ? 10 : 0"
      :max-height="badgeWidth"
      :max-width="badgeWidth"
      :height="badgeWidth"
      :width="badgeWidth"
    >
      <v-fade-transition>
        <v-img
          v-show="badgeData.hasImage || showNoneBadge && !badgeData.hasImage"
          :width="badgeWidth"
          contain
          :src="badgeUrl"
          alt="Badge"
          style="overflow: visible !important;"
          @load="$emit('badgeLoaded');"
        />
      </v-fade-transition>
    </v-btn>
  </div>
</template>

<script>
import cider_mixin from "@/components/mixins/cider_mixin";
import {apiTyped} from "@/api";

export default {
  name: "Badge",
  mixins: [cider_mixin],
  props: {
    badgeData: {type: Object, required: true, default: null},
    bestOfCategory: {type: Boolean, required: false, default: false},
    showNoneBadge: {type: Boolean, required: false, default: false},
    badgeShadow: {type: Boolean, required: false, default: true},
    badgeWidth: {type: Number, required: false, default: 56},
    points: {type: Number, required: false, default: 0},
    badgeResolution: {type: Number, required: false, default: 300},
    border: {type: Boolean, required: false, default: false},
  },
  computed: {
    badgeUrl() {
      const imageId = this.bestOfCategory ? this.badgeData.bestOfCategory : this.badgeData.icon;
      return imageId != null && imageId !== "" ? apiTyped.getBadgeImgUrl(imageId, this.badgeResolution) : "";
    }
  },
}
</script>

<style scoped>
.circle {
  background-color: transparent;
  border: 0.1px solid rgba(0, 0, 0, 1);
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

/deep/ .v-btn__content {
  overflow: visible !important;
}
</style>