import "axios";
import {components, MyCollections} from "@/api/types/my-collections"
import {createUserSettingsFromDatabase, RoleName, User, UserId} from "@/types/user";
import {createKategorieFromDatabase, Kategorie} from "@/types/kategorie";
import JurorCider from "@/types/jurorCider";
import {createKategorieRatedFromKategorie} from "@/types/kategorieRated";
import {Badge, createBadgeFromDatabase, createUrkundeFromDatabase, Format, Urkunde} from "@/types/badge";
import {createTastingFromDatabase, Tasting} from "@/types/tasting";
import {requiresLogin} from "@/api/apiBase"
import {AdminApiMixin} from "@/api/adminApiMixin";


class ApiAccess extends AdminApiMixin {

    @requiresLogin
    async getAllKategorien(tastingId: number, onlyByIds: null | number[] = null): Promise<Kategorie[]> {
        let filter;
        if (onlyByIds === null || onlyByIds.length === 0) {
            filter = { "tastings": { "tastings_id": { _eq: tastingId }}};
        } else {
            filter = { id: { _in: onlyByIds } };
        }
        console.log("Getting kategorien", filter)
        const resp = await this.sdk.items("kategorien").readByQuery({
           fields: [
               "id",
               "display_id",
               "name",
               "info",
               "translations.info",
               "translations.name",
               "translations.languages_code.code",
               "bewertungskategorien.id",
               "bewertungskategorien.sort",
               "bewertungskategorien.bewertungskategorien_id.id",
               "bewertungskategorien.bewertungskategorien_id.pruefmerkmal",
               "bewertungskategorien.bewertungskategorien_id.translations.info",
               "bewertungskategorien.bewertungskategorien_id.translations.pruefmerkmal",
               "bewertungskategorien.bewertungskategorien_id.translations.languages_code.code",
               "bewertungskategorien.bewertungskategorien_id.eigenschaften.id",
               "bewertungskategorien.bewertungskategorien_id.eigenschaften.sort",
               "bewertungskategorien.bewertungskategorien_id.eigenschaften.eigenschaften_id.id",
               "bewertungskategorien.bewertungskategorien_id.eigenschaften.eigenschaften_id.bezeichnung",
               "bewertungskategorien.bewertungskategorien_id.eigenschaften.eigenschaften_id.faktor",
               "bewertungskategorien.bewertungskategorien_id.eigenschaften.eigenschaften_id.info",
               "bewertungskategorien.bewertungskategorien_id.eigenschaften.eigenschaften_id.translations.info",
               "bewertungskategorien.bewertungskategorien_id.eigenschaften.eigenschaften_id.translations.bezeichnung",
               "bewertungskategorien.bewertungskategorien_id.eigenschaften.eigenschaften_id.translations.languages_code.code",
           ],
            filter: filter,
            limit: -1,
        });
        if (resp?.data == null) return [];
        return resp.data.map((kategorie: any) => createKategorieFromDatabase(kategorie));
    }

    @requiresLogin
    async getBadgeById(badgeId: number): Promise<Badge | undefined> {
        // TODO Test method
        const resp = await this.sdk.items("medaillen").readOne(badgeId);
        if (resp == null) return undefined;
        return createBadgeFromDatabase({
            beschreibung: resp.beschreibung,
            border: resp.border,
            color: resp.color,
            hat_medaille: resp.hat_medaille,
            icon: resp.icon,
            icon_best_of_category: resp.icon_best_of_category,
            id: resp.id,
            punkte_max: resp.punkte_max,
            punkte_min: resp.punkte_min,
            status: resp.status,
            stufe: resp.stufe,
            tastings: resp.tastings,
        });
    }

    @requiresLogin
    async getBadges(tastings: Tasting[] | null = null, tastingIds: number[] | null = null): Promise<Badge[]> {
        const tastingIds_: number[] = tastings != null
            ? tastings.map(tasting => tasting.id)
            : tastingIds || [];
        const resp = await this.sdk.items("medaillen").readByQuery({
            fields: [
                "id",
                "status",
                "stufe",
                "punkte_max",
                "punkte_min",
                "icon",
                "icon_best_of_category",
                "color",
                "tastings",
                "hat_medaille",
                "beschreibung",
                "border"
            ],
            filter: {
                "tastings": { "tastings_id": { _in: tastingIds_ } },
            }
        });
        let badges = resp.data?.map((badge: MyCollections["medaillen"]) => createBadgeFromDatabase({
            beschreibung: badge.beschreibung,
            border: badge.border,
            color: badge.color,
            hat_medaille: badge.hat_medaille,
            icon: badge.icon,
            icon_best_of_category: badge.icon_best_of_category,
            id: badge.id,
            punkte_max: badge.punkte_max,
            punkte_min: badge.punkte_min,
            status: badge.status,
            stufe: badge.stufe,
            tastings: badge.tastings,
        })) as Badge[];
        badges = badges.sort((b1, b2) => b1.punkteMin - b2.punkteMin);
        if (tastings != null) {
            tastings.forEach(tasting => tasting.badges = badges.filter(badge => badge.tastings.includes(tasting.id)))
        }
        return badges;
    }

    getUserAvatarUrl(width: number, user: User | null = null, theme: any): string {
        if (this.user === undefined) return "";
        if (user === null) user = this.user;
        return this.getAvatarUrl(width, user, theme);
    }

    rgbToHex(r: number, g: number, b: number): string {
        return "" + ((1 << 24) + (Math.round(r) << 16) + (Math.round(g) << 8) + Math.round(b)).toString(16).slice(1);
    }

    hexToRgb(hex: string): number[] {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if(result){
            const r= parseInt(result[1], 16);
            const g= parseInt(result[2], 16);
            const b= parseInt(result[3], 16);
            return [r, g, b];
        }
        return [0, 0, 0];
    }

    getAvatarUrl(width: number, userData: User, theme: any): string {
        if (userData.avatar !== null && userData.avatar !== undefined && this.sdk.auth.token !== null
            && typeof userData.avatar !== "string" && userData.avatar.id !== undefined) {
            return this.baseUrl.concat('assets/', userData.avatar.id, "?access_token=", this.sdk.auth.token,
                '&fit=contain&width=', String(width));
        } else {
            const first_name = userData.firstName.trim().charAt(0) || "P";
            const last_name = userData.lastName.trim().charAt(0) || "x";
            const color1 = this.hexToRgb(theme.primary_container);
            const color2 = this.hexToRgb(theme.tertiary);
            const colorDiff = color1.map(function (num, idx) { return Math.abs(num - color2[idx]) });
            const char1 = (first_name.toLowerCase().charCodeAt(0) - 97) / 26  // 97 == "a"
            const char2 = (last_name.toLowerCase().charCodeAt(0) - 97) / 26  // 97 == "a"
            const newColor = this.rgbToHex(
                colorDiff[0] * (char1 + char2) / 2 + Math.min(color1[0], color2[0]),
                colorDiff[1] * char1 + Math.min(color1[1], color2[1]),
                colorDiff[2] * char2 + Math.min(color1[2], color2[2]));
            return "https://eu.ui-avatars.com/api/?name=" + first_name + "+" + last_name +
                "&background=" + newColor + "&rounded=true";
        }
    }

    getBadgeImgUrl(badgeImgId: string, width: number): string {
        return this.getImgUrl(badgeImgId, width);
    }

    getImgUrl(imgId: string, width: number, no_contain = false, no_width = false, auth = true): string {
        // if (this.sdk.auth.token === null) return "";
        if (imgId == null) {
            console.warn("Trying to fetch null image");
            return "";
        }
        const containStr = no_contain ? "" : "&fit=contain";
        const widthStr = (no_width || width === -1) ? "" : `&width=${width}`;
        if (auth) return `${this.baseUrl}assets/${imgId}?access_token=${this.sdk.auth.token}${containStr}${widthStr}`;
        return `${this.baseUrl}assets/${imgId}?${containStr}${widthStr}`;
    }

    getFile(fileId: string, auth = true): string {
        if (fileId == null) {
            console.warn("Trying to fetch null file");
            return "";
        }
        if (auth) return `${this.baseUrl}assets/${fileId}?access_token=${this.sdk.auth.token}`;
        return `${this.baseUrl}assets/${fileId}`;
    }

    getLogoUrl(logoId: string, width: number): string {
        if (logoId == null) {
            console.warn("Trying to fetch null image");
            return "";
        }
        const widthStr = (width === -1) ? "" : `&width=${width}`;
        return `${this.baseUrl}assets/${logoId}?fit=contain${widthStr}`;
    }

    async getLogoIds(): Promise<MyCollections["logos"][]> {
        const resp = await this.sdk.items("logos").readByQuery({ fields: "*", });
        return resp.data as Array<MyCollections["logos"]>;
    }

    @requiresLogin
    async getUserById(id: string) {
        if (this.user?.role !== RoleName.Admin) return undefined;
        const resp = await this.sdk.users.readOne(id);
        if (resp === undefined) return resp
        return new User(<components["schemas"]["Users"]>resp);
    }

    @requiresLogin
    async getTastings(allTastings=false): Promise<Tasting[] | undefined> {
        let tableFilter = {};
        // Admin gets all Tastings:
        if (this.user?.role !== RoleName.Admin && !allTastings) {
            tableFilter = {
                directus_users_id: {_eq: this.user?.id}
            };
        }
        const resp = await this.sdk.items("tables_directus_users").readByQuery({
            fields: [
                "tables_id.tasting.*"
            ],
            filter: tableFilter,
        });
        let tastings = resp.data?.filter(d => d.tables_id !== null)?.map((table: MyCollections["tables_directus_users"]) =>
            // @ts-ignore
            createTastingFromDatabase((table.tables_id as MyCollections["tables"]).tasting));
        if (tastings === undefined) return undefined;
        tastings = tastings.sort((a, b) => {
            const date1 = a.datum !== null ? a.datum.getTime() : 0
            const date2 = b.datum !== null ? b.datum.getTime() : 0
            return date1 - date2;
        });
        return [...new Set(tastings)];
    }

    @requiresLogin
    async getTastingById(tastingId: number): Promise<Tasting | undefined> {
        const resp = await this.sdk.items("tastings").readOne(tastingId, {
            fields: ["*"],
        });
        if (resp == null) return undefined;
        return createTastingFromDatabase(resp);
    }

    @requiresLogin
    async getTableIds(tasting: number, user: User | undefined = undefined, allTables=false)
            : Promise<number[]> {
        if (user === undefined && this.user !== undefined) await this.getUser();
        let tableFilter = `tables_id: {tasting: {id: {_eq: ${tasting}} }}`
        if (this.user?.role !== RoleName.Admin && !allTables) {
            tableFilter = tableFilter + `, directus_users_id: {id: {_eq: "${this.user?.id}"} }`
        } else {
            tableFilter = "";
        }
        const query = "query { " +
            "tables_directus_users (filter: {" + tableFilter + "}) { " +
            "id, directus_users_id { id }," +
            "tables_id { id, tasting { id } }" +
            "} }";
        const resp = await this.sdk.graphql.items(query);
        return (resp.data as any)["tables_directus_users"].flatMap((d: any) => d.tables_id.id);
    }

    @requiresLogin
    async getAllNotesForCider(cider: JurorCider, userIds: UserId[]): Promise<MyCollections["cider_notes"][]> {
        const resp = await this.sdk.items("cider_notes").readByQuery({
            fields: ["*"],
            filter: {
                "cider_id": { _eq : cider.id },
                "flight_id": { _eq : cider.flightId },
                "juror_id": { _in: userIds },
            },
            sort: ["-date_updated"],
        });
        return resp.data as MyCollections["cider_notes"][];
    }

    @requiresLogin
    async getAllNotesForCiders(ciders: JurorCider[], usersIds: UserId[]): Promise<MyCollections["cider_notes"][]> {
        // console.log("GETTING USER NOTES", ciders.map(cider => cider.id).join(), ciders.map(cider => cider.flightId).join(), users.map(user => user.id).join())
        const resp = await this.sdk.items("cider_notes").readByQuery({
            fields: ["*"],
            filter: {
                "cider_id": { _in : ciders.map(cider => cider.id) },
                "flight_id": { _in : ciders.map(cider => cider.flightId) },
                "juror_id": { _in: usersIds },
            },
            limit: -1,
            sort: ["-date_updated"],
        });
        return resp.data as MyCollections["cider_notes"][];
    }

    // @requiresLogin
    // async updateAbschlusskommentar(ciderId: number, kommentar: string): Promise<MyCollections["abschlusskommentare"]> {
    //     const resp = await this.sdk.items("abschlusskommentare").updateOne(
    //         ciderId,{ kommentar: kommentar}, {fields: ["kommentar"]});
    //     return resp as MyCollections["abschlusskommentare"];
    // }

    // @requiresLogin
    // async createAbschlusskommentar(ciderId: number): Promise<MyCollections["abschlusskommentare"]> {
    //     const resp = await this.sdk.items("abschlusskommentare").createOne( {
    //         cider_id: ciderId,
    //         kommentar: "",
    //     });
    //     console.log("Created Abschlusskommentar", resp);
    //     return resp as MyCollections["abschlusskommentare"];
    // }

    // @requiresLogin
    // async getAbschlusskommentar(ciderId: number): Promise<MyCollections["abschlusskommentare"]> {
    //     const resp = await this.sdk.items("abschlusskommentare").readByQuery( {
    //         fields: ["kommentar", "id"],
    //         filter: {
    //             "cider_id": { _eq : ciderId},
    //         },
    //     });
    //     if (resp.data == undefined || resp.data.length === 0)
    //         return await this.createAbschlusskommentar(ciderId);
    //     console.log("Got Abschlusskommentar:", resp.data);
    //     if (resp.data.length > 1) console.error("Got more than one Abschlusskommentar:", resp.data, ciderId);
    //     return resp.data[0] as MyCollections["abschlusskommentare"];
    // }

    @requiresLogin
    async getUserSettings(userIds: number[]): Promise<MyCollections["user_settings"][]> {
        const resp = await this.sdk.items("user_settings").readByQuery({
            filter: {
                "user_id": { _in : userIds },
            },
            limit: -1,
        });
        if (resp.data == null) return [];
        return resp.data as MyCollections["user_settings"][];
    }

    @requiresLogin
    async addKategorienToCiders(ciderList: JurorCider[], tastingId: number, kategorien: Kategorie[] | null = null
            ): Promise<JurorCider[]> {
        const ciderIds = ciderList.map(cider => cider.id);
        if (kategorien === null) kategorien = await this.getAllKategorien(tastingId);
        const ciderKategorien = await this.sdk.items("ciders").readByQuery({
            filter: {
                id: {_in: ciderIds},
            },
            fields: "kategorie",
        }).then(value => value.data?.map(data => data.kategorie));
        ciderList.forEach(function (cider, index) {
            const kategorie = kategorien?.find(kategorie => kategorie?.id === ciderKategorien?.[index]);
            if (kategorie !== undefined) {
                cider.kategorie = createKategorieRatedFromKategorie(kategorie);
            } else {
                console.error("Undefined Kategorie for Cider:", cider)
            }
        });
        // Removing Cider without Category?
        // ciderList = ciderList.filter(cider => cider.kategorie !== undefined);  // TODO
        return ciderList;
    }

    @requiresLogin
    async getBewertungen(ciderList: JurorCider[], jurorIds: UserId[] = []): Promise<MyCollections["bewertungen"][]> {
        const ciderIds = ciderList.map(cider => cider.id);
        const flightIds = ciderList.map(cider => cider.flightId);
        const filter_ = {
            "_and": [
                {
                    cider_id: {_in: ciderIds || [-1]},
                },
                {
                    flight_id: {_in: flightIds || [-1]},
                },
            ],
            // nur eigene, falls keine Juror-Ids angegeben:
            ...(jurorIds.length === 0 && this.user?.id !== undefined && {"juror_id": {_eq: this.user?.id}}),
            ...(jurorIds.length > 0 && {"juror_id": {_in: jurorIds}})  // nur z.B. die von Juroren aus dem Table
        }
        const resp = await this.sdk.items("bewertungen").readByQuery({
            fields: ["eigenschaft_id", "cider_id", "punkte", "juror_id", "id", "date_created", "date_updated",
                     "flight_id"],
            filter: filter_,
            limit: -1,  // NoJuroren * NumMaxPrüfmerkmale * NumKategorien * SafetyFactor  // TODO pagination
        });
        // console.log("All Bewertungen:", resp.data)
        return resp.data as MyCollections["bewertungen"][];
    }

    @requiresLogin
    async updateUserSettings(payload: { [key: string]: any }): Promise<MyCollections["user_settings"]> {
        const resp = await this.sdk.items("user_settings").updateOne(this.user?.id as string, {
            ...payload,
        });
        (<User>this.user).userSettings = createUserSettingsFromDatabase(resp as MyCollections["user_settings"]);
        return resp as MyCollections["user_settings"];
    }

    @requiresLogin
    async setLanguage(languageCode: string): Promise<string | undefined> {
        const resp = await this.sdk.items("directus_users").updateOne(this.user?.id as string, {
            language: languageCode,
        });
        (<User>this.user).language = (<components["schemas"]["Users"]>resp).language;
        return (<components["schemas"]["Users"]>resp).language;
    }

    @requiresLogin
    async getUrkunden(badges: Badge[], formats: Format[] = [Format.A3, Format.A4]): Promise<Urkunde[] | undefined> {
        const badgesIds = badges.map(badge => badge.id);
        const resp = await this.sdk.items("urkunden").readByQuery({
            fields: ['*'],
            filter: {
                medaille: { _in : badgesIds },
                // format: { _in : formats.join() },  // TODO
            }
        });
        console.log("API Urkunden Data:", badges.map(badge => badge.id).join(), formats.join(), resp.data)
        return resp?.data?.map(urkunde => createUrkundeFromDatabase(urkunde));
    }

    @requiresLogin
    async updateTheme(theme_: "dark" | "light" | "auto") {
        // TODO: emulated users
        await this.sdk.users.me.update({
            theme: theme_,
        });
        // this.user?.theme = getThemeFromString(theme_);
    }

    async createProducerAccountRequest(name: string, email: string): Promise<boolean> {
        try {
            await this.sdk.items('producer_account_requests').createOne({
                name: name,
                email: email,
            });
            return true;
        } catch (e) {
            console.error("Error requesting account", name, email, e)
            return false;
        }
    }

    async migrate() {
        const flightsCiders = await this.sdk.items("flights_ciders").readByQuery({
            fields: ['*', 'flights_id.tables.tables_id.tasting', 'flights_id.id'],
            filter: {
                "flights_id":{"tables":{"tables_id":{"tasting":{"id":{"_eq":"4"}}}}}
            },
            limit: -1,
        });
        console.log("DEEEEP", flightsCiders)
        if (flightsCiders?.data == null) return
        // for (const flightsCider of flightsCiders?.data as MyCollections["flights_ciders"][]) {
        //     console.log('Flights Ciders', flightsCider.id)
        //     const flightsCidersId = flightsCider.id;
        //     const ciderId = flightsCider.ciders_id;
        //     // @ts-ignore
        //     const flightId = flightsCider.flights_id.id;
        //     // @ts-ignore
        //     console.log("Tables:", flightsCider.flights_id.tables)
        //     // @ts-ignore
        //     if (flightsCider.flights_id.tables.length === 0) {
        //         console.log("Cont.")
        //         continue;
        //     }
        //     // @ts-ignore
        //     const tastingId = flightsCider.flights_id.tables[0].tables_id.tasting;
        //     if (tastingId == null) continue;
        //
        //     const bewertungen = await this.sdk.items("bewertungen").readByQuery({
        //         fields: [
        //             'id',
        //             'flights_ciders_id',
        //             'juror_id',
        //             'punkte',
        //             'eigenschaft_id.id',
        //             'eigenschaft_id.faktor',
        //         ],
        //         filter: {
        //             cider_id: { _eq: ciderId },
        //             flight_id: { _eq: flightId },
        //         },
        //         limit: -1,
        //     });
        //     let points = bewertungen?.data?.reduce((partialSum, bewertung) => {
        //         // @ts-ignore
        //         return partialSum + ((bewertung.punkte || 0) * bewertung?.eigenschaft_id?.faktor)
        //     }, 0);
        //     // @ts-ignore
        //     points = util.util.round_to_decimal(points / flightsCider.cider_notes.length, 0.5);
        //     console.log("Points", points)
        //     const badges = await this.sdk.items("medaillen").readByQuery({
        //         fields: ['*', 'tastings.tastings_id.id'],
        //         sort: ['punkte_min'],
        //         deep: {
        //             tastings: {
        //                 _filter: {
        //                     tastings_id: { _eq: tastingId }
        //                 }
        //             }
        //         },
        //         limit: -1,
        //     });
        //     // @ts-ignore
        //     const badge = badges.data.filter(b => b.tastings.find(t => t.tastings_id.id === tastingId))?.find(
        //         b => (b.punkte_min || 0) <= (Math.ceil(points || 0)) && (b.punkte_max || 0) >= (Math.ceil(points || 0)));
        //     const updateData: any = {};
        //     if (points != null) {
        //         updateData["points"] = points
        //     }
        //     if (badge != null) {
        //         updateData["badge"] = badge.id
        //     }
        //     if (points != null || badge != null) {
        //         // @ts-ignore
        //         await this.sdk.items("flights_ciders").updateOne(flightsCider.id, {
        //             ...updateData,
        //         });
        //     }
        //
        //     // const ciderNotes = await this.sdk.items("cider_notes").readByQuery({
        //     //     fields: ['*'],
        //     //     filter: {
        //     //         cider_id: { _eq: ciderId },
        //     //         flight_id: { _eq: flightId },
        //     //     },
        //     //     limit: -1,
        //     // });
        //     // if (ciderNotes?.data != null) {
        //     //     for (const ciderNote of ciderNotes?.data as MyCollections["cider_notes"][]) {
        //     //         await this.sdk.items("cider_notes").updateOne(ciderNote.id!, {
        //     //             ...{flights_ciders_id: flightsCidersId},
        //     //         });
        //     //     }
        //     // } else console.log('No Cider Notes')
        //
        //     // const chairNotes = await this.sdk.items("chair_notes").readByQuery({
        //     //     fields: ['*'],
        //     //     filter: {
        //     //         cider_id: { _eq: ciderId },
        //     //         flight_id: { _eq: flightId },
        //     //     },
        //     //     limit: -1,
        //     // });
        //     // if (chairNotes?.data != null && chairNotes?.data.length === 1) {
        //     //     if (chairNotes.data[0].id === undefined) {
        //     //         console.log("Chair notes id undef", chairNotes)
        //     //     } else {
        //     //         await this.sdk.items("chair_notes").updateOne(chairNotes.data[0].id!, {
        //     //             ...{flights_ciders_id: flightsCidersId},
        //     //         });
        //     //     }
        //     // } else {
        //     //     console.log('Chair Notes error', chairNotes.data)
        //     // }
        //
        //     // const bewertungen = await this.sdk.items("bewertungen").readByQuery({
        //     //     fields: ['*'],
        //     //     filter: {
        //     //         cider_id: { _eq: ciderId },
        //     //         flight_id: { _eq: flightId },
        //     //     },
        //     //     limit: -1,
        //     // });
        //     // if (bewertungen?.data != null) {
        //     //     for (const bewertung of bewertungen?.data as MyCollections["bewertungen"][]) {
        //     //         await this.sdk.items("bewertungen").updateOne(bewertung.id!, {
        //     //             ...{flights_ciders_id: flightsCidersId},
        //     //         });
        //     //     }
        //     // } else console.log("No Bewertungen")
        // }
    }
}


const apiTyped = new ApiAccess();
export { ApiAccess, apiTyped, requiresLogin };