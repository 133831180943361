<template>
  <v-container
    fluid
    class="ma-0"
  >
    <v-row>
      <v-col>
        <div class="d-flex align-center reset-title">
          {{ $i18n.t('acceptInvite') }}
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!newPasswordSuccessful">
      <v-col>
        <div class="d-flex align-center reset-title-2">
          {{ $i18n.t('setPasswordForNewAccount') }}
        </div>
      </v-col>
    </v-row>

    <v-card
      v-if="newPasswordSuccessful"
      class="pt-4 mt-4"
      :outlined="$vuetify.theme.dark"
    >
      <v-icon size="72">
        mdi-lock-check
      </v-icon>
      <v-card-title
        class="pb-6"
        style="overflow-wrap: break-word !important; word-break: keep-all !important;"
      >
        {{ $i18n.t('passwortSetSuccessful') }}
      </v-card-title>
      <v-card-subtitle
        class="pb-2"
      >
        {{ $i18n.t('loginPossible') }}
      </v-card-subtitle>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn
          rounded
          outlined
          style="text-transform: none !important;"
          @click="$router.push('/login')"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
          {{ $i18n.t('Login.backToLogin') }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>

    <template
      v-if="!newPasswordSuccessful"
    >
      <v-row
        class="mt-0 mb-0"
      >
        <v-col>
          <v-text-field
            v-model="email"
            outlined
            disabled
            hide-details
            name="E-mail"
            label="E-mail"
            type="email"
          />
        </v-col>
      </v-row>
      <v-row
        class="mt-0 mb-0"
      >
        <v-col>
          <v-text-field
            v-model="newPassword"
            outlined
            hide-details
            name="password"
            :label="$i18n.t('Login.password')"
            type="password"
            :placeholder="$i18n.t('Login.password')"
            @keyup.enter="setNewPassword"
          />
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-0">
        <v-col>
          <v-text-field
            id="password"
            v-model="newPasswordCheck"
            outlined
            hide-details
            name="repeatPassword"
            :label="$i18n.t('Login.repeatPassword')"
            type="password"
            :placeholder="$i18n.t('Login.repeatPassword')"
            @keyup.enter="setNewPassword"
          />
        </v-col>
      </v-row>
      <v-fade-transition>
        <p
          v-show="infoMsg !== null"
          class="mb-2 mt-2 ml-4 mr-2 text-left"
        >
          {{ $i18n.t(infoMsg) }}
        </p>
      </v-fade-transition>
      <v-row
        v-if="!newPasswordSuccessful"
        class="mt-0 mb-0"
      >
        <v-col cols="auto">
          <v-btn
            text
            style="text-transform: none !important;"
            @click="$router.push('/login')"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
            {{ $i18n.t('Login.backToLogin') }}
          </v-btn>
        </v-col>
        <v-col
          class="ml-auto"
          cols="auto"
        >
          <v-btn
            elevation="0"
            color="primary"
            :loading="loading"
            :disabled="newPasswordSuccessful"
            @click="setNewPassword"
          >
            <v-icon
              v-show="newPasswordSuccessful"
              left
            >
              mdi-check
            </v-icon>
            {{ $i18n.t('Login.confirm') }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import {apiTyped} from "@/api";
import i18n from '@/i18n'

export default {
  name: "AcceptInvite",
  data() {
    return {
      newPassword: '',
      newPasswordCheck: '',
      newPasswordSuccessful: false,
      loading: false,
      infoMsg: null,
    };
  },
  i18n: {
    messages: {
      de: {
        "passwortSetSuccessful": "Wir haben erfolgreich ein Passwort für Ihren neuen Account angelegt",
        "loginPossible": "Sie können sich nun mit Ihren Benutzerdaten anmelden",
        "acceptInvite": "Einladung akzeptieren",
        "setPasswordForNewAccount": "Geben Sie ein Passwort für Ihren neuen Account ein",
      },
      en: {
        "passwortSetSuccessful": "We have successfully set the password for your new account",
        "loginPossible": "You can now log in with your credentials",
        "acceptInvite": "Accept invite",
        "setPasswordForNewAccount": "Set a password for your new account",
      }
    }
  },

  computed: {
    token() {
      return this.$route.query.token;
    },
    email() {
      return this.$route.query.email;
    },
  },
  methods: {
    async setNewPassword() {
      this.infoMsg = null;
      try {
        if (this.newPassword === this.newPasswordCheck) {
          this.loading = true;
          const success = await apiTyped.acceptInvite(this.token, this.newPassword);
          if (success) {
            this.newPasswordSuccessful = true;
            this.loading = false;
          } else {
            this.infoMsg = 'An error occurred while setting the new Password';
            this.loading = false;
          }
        } else {
          this.infoMsg = 'The Passwords must be equal';
        }
      } catch (error) {
        this.infoMsg = 'Not able to set new Password';
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>
.reset-title {
  font-family: "Nunito Sans", serif !important;
  font-weight: 800;
  font-size: 32pt !important;
  text-align: left;
  line-height: 1.25;
}
.reset-title-2 {
  font-family: "Nunito Sans", serif !important;
  font-weight: 800;
  font-size: 22pt !important;
  text-align: left;
  line-height: 1.25;
}
</style>