import Vue from 'vue';
import Vuex from 'vuex';
import {make} from 'vuex-pathify';
import store, {rootGetter} from "@/stores";
import {Badge, createBadgeFromDatabase} from "@/types/badge";
import {apiTyped} from "@/api";
import {contrastingColor} from "@/util/helper";
import util from "@/util";
import badge from "@/components/badge.vue";

Vue.use(Vuex);


type storeStates = typeof state;
// @ts-ignore
type storeGetters = typeof getters;
type rootStates = typeof store.state;
type rootGetters = typeof rootGetter;

const state = {
    badgesData: Array<Badge>(),
}

// @ts-ignore
const getters = {
    ...make.getters(state),
    showBadge: (state: storeStates, getters: storeGetters, rootState: rootStates, rootGetter: rootGetters) =>
            (points: number | null = null, totalPoints = false): boolean => {
        if (points === null) {
            points = totalPoints ?
                rootGetter["ciderStore/getTotalPointsForCiderIdx"]()
                : rootGetter["ciderStore/getPointsForCurrentCider"]();
        }
        if (points === undefined || points === null) return false;
        // console.log("Minimal: ", getters.getMinimalBadgePoints(), "Points", points)
        return points > getters.getMinimalBadgePoints();
    },
    getBadgesSorted: (state: storeStates) => (): Badge[] =>  {
        return state.badgesData.sort((a, b) => a.punkteMin - b.punkteMin);
    },
    getBadgeData: (state: storeStates) => (points: number, badges: Badge[] | null = null): Badge | null => {
        console.log("Got ", points)
        const oldP = Number(points);
        points = util.util.round_to_decimal(points, 1);  // round 0.5 to 1.0 (e.g. 69.5 points results in badge for 70 points)
        console.log("Got rounded", points)
        const badges_ = badges != null ? badges : state.badgesData;
        const foundBadge =  badges_?.find((badge: Badge) =>
            (points >= badge.punkteMin && points <= badge.punkteMax)) || null;
        if (oldP === 69.3) console.log("Got badge for 69.3", foundBadge)
        return foundBadge;
    },
    getMinimalBadgePoints: (state: storeStates) => (): number => {
        if (state.badgesData === null) {
            console.error("No badge data");
            return 0;
        }
        if (state.badgesData.length === 0) return 0;
        return state.badgesData.reduce(function(prev: Badge, curr: Badge) {
            return prev.punkteMin < curr.punkteMin ? prev : curr;
        }).punkteMax;
    },
    getBadgeIcon: (state: storeStates, getters: storeGetters) => (
            points: number, width: number, bestOfCategory = false): string | null => {
        const foundBadge = getters.getBadgeData(util.util.round_to_decimal(points, 0.1));
        if (foundBadge === null)
            return null;
        const badgeImageId = bestOfCategory ? foundBadge.bestOfCategory : foundBadge.icon;
        if (badgeImageId == null) return "";
        return apiTyped.getBadgeImgUrl(badgeImageId, width);
    },
    getColor: (state: storeStates, getters: storeGetters) => (points: number): string | null => {
        const foundBadge = getters.getBadgeData(util.util.round_to_decimal(points, 0.1));
        if (foundBadge === null || foundBadge.color === null)
            return null
        return foundBadge.color;
    },
    getTextColor: (state: storeStates, getters: storeGetters) => (points: number, dark = false): string => {
        const color = getters.getColor(points);
        if (color === null) return dark ? 'fff' : '000';
        return contrastingColor(color);
    },
    getTextColorFromBadge: () => (badge: Badge, dark = false): string => {
        const color = badge.color;
        if (color === null) return dark ? 'fff' : '000';
        return contrastingColor(color);
    },
}

const mutations = {
    ...make.mutations(state),
}

const actions = {
    async fetchBadgeData(context: any, payload: {badgeId: number}): Promise<Badge | undefined> {
        const existingBadge = context.state.badgesData.find((badgeData: Badge) => badgeData.id === payload.badgeId);
        if (existingBadge != null) {
            return existingBadge;
        }
        const resp = await apiTyped.sdk.items('medaillen').readOne(payload.badgeId)
        if (resp != null) {
            const newBadge = createBadgeFromDatabase(resp);
            context.state.badgesData.push(newBadge);
            return newBadge;
        }
        return undefined;
    },
    async fetchBadgesData(context: any, payload: {badgeIds: number[]}): Promise<Badge[] | undefined> {
        const existingBadgeIds = context.state.badgesData.map((badgeData: Badge) => badgeData.id)
        const notExistingBadgeIds = payload.badgeIds.filter(badgeId => !existingBadgeIds.includes(badgeId));
        if (notExistingBadgeIds == null) {
            const badges = context.state.badgesData.filter(
                (badgeData: Badge) => payload.badgeIds.includes(badgeData.id));
            // return in same order as badgeIds
            return badges.sort((a: Badge, b: Badge) => payload.badgeIds.indexOf(a.id) - payload.badgeIds.indexOf(b.id));
        }
        const resp = await apiTyped.sdk.items('medaillen').readByQuery({
            fields: ['*'],
            filter: {
                id: { _in: notExistingBadgeIds }
            },
            limit: -1,
        })
        if (resp != null && resp.data != null) {
            const newBadges = resp.data.map((b: any) => createBadgeFromDatabase(b));
            context.state.badgesData.push(...newBadges);
            const badges = context.state.badgesData.filter(
                (badgeData: Badge) => payload.badgeIds.includes(badgeData.id));
            // return in same order as badgeIds
            return badges.sort((a: Badge, b: Badge) => payload.badgeIds.indexOf(a.id) - payload.badgeIds.indexOf(b.id));
        }
        return undefined;
    }
}

export default {
    namespaced: true, // add this if in module
    state,
    getters,
    mutations,
    actions,
}