<template>
  <page
    v-if="product !== null"
    ref="pageA4"
    size="A4"
    class="pa-0 pb-0 ma-0"
    style="transform-origin: 0 0;"
  >
    <div
      v-if="product != null"
      class="page_grid"
    >
      <rating-judge-header
        :product="product"
        :tasting="tasting"
      />
      <manual-rating-judge-table
        :product="product"
        :max-points="maxPoints"
        class="pa-4"
        style="align-self: stretch;"
      />
      <CiderFooter
        :force-show-badges="true"
        :show-date="true"
        :show-logo="true"
        :badge-shadow="false"
        :show-current-year="false"
        :badges-data-array="badgesDataArray"
        :tasting="tasting"
        :background-color="$vuetify.theme.currentTheme.page_background"
        style="min-height: 172px !important;"
      />
    </div>
  </page>
</template>

<script>
import JurorCider from "@/types/jurorCider";
import GesamtauswertungTable from "@/components/admin/gesamtauswertung/table.vue";
import CiderFooter from "@/components/cider_footer.vue";
import RatingJudgeHeader from "@/components/admin/manualRating/manual_rating_judge_header.vue";
import ManualRatingJudgeTable from "@/components/admin/manualRating/manual_rating_judge_table.vue";

export default {
  name: "ManualRatingJudge",
  components: {ManualRatingJudgeTable, RatingJudgeHeader, CiderFooter},
  props: {
    product: {type: JurorCider, required: true, default: () => null},
    tasting: {type: Object, required: false, default: () => null},
    badgesDataArray: {type: Array, required: true, default: null},
  },
  computed: {
    maxPoints() {
      let max = 0;
      if (this.badgesDataArray == null) return 100;
      this.badgesDataArray.forEach(b => {
        if (b.punkteMax > max) max = b.punkteMax;
      });
      return max;
    },
  }
}
</script>

<style scoped>
page[size="A4"] {
    width: 21cm;
    height: 29.7cm;
    min-height: 29.7cm;
    min-width: 21cm;
    max-height: 29.7cm;
    max-width: 21cm;
}

page::-webkit-scrollbar {
    display: none;
}

.page_grid {
    display: grid;
    align-items: stretch;
    height: 29.7cm !important;
    grid-template-rows: min-content auto 172px;
}

page {
    background: white;
    display: block;
    margin: 0 auto 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
}

@media print {
    page {
        width: 21cm;
        height: 29.7cm;
        min-height: 29.7cm;
        min-width: 21cm;
        max-height: 29.7cm;
        max-width: 21cm;
        margin: 0 !important;
        padding: 0 !important;
        box-shadow: unset !important;
    }
}
</style>