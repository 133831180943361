<template>
  <v-row
    justify="center"
    class="no_scrollbar"
  >
    <v-dialog
      v-if="points !== null"
      v-model="dialog"
      persistent
      class="no_scrollbar"
      max-width="450"
    >
      <v-card
        rounded="xl"
        :outlined="$vuetify.theme.dark"
        style="border-color: var(--v-divider-base) !important"
        color="background"
        class="no_scrollbar"
      >
        <v-card-title
          class="text-h5 text-left"
          style="word-break: keep-all;"
        >
          {{ isChair ? $t("CiderInfoExpPanel.finishRatingChair") : $t("CiderInfoExpPanel.finishRatingJuror") }}
        </v-card-title>
        <v-sheet
          v-if="showCider"
          rounded="xl"
          outlined
          class="pl-8 pr-8 mt-8 mr-8 ml-8 mb-2 pb-2 overflow-visible no_scrollbar"
          style="border-width: 2.5px; background-color: var(--v-finishCiderBackground-base)"
          :style="{'border-color': badgeData.color}"
        >
          <v-row justify="center">
            <Badge
              style="margin-top: -16px; z-index: 99 !important;"
              :badge-data="badgeData"
              :badge-shadow="true"
              :show-none-badge="true"
              :points="points"
            />
          </v-row>
          <v-row
            justify="center"
            class="pt-2"
          >
            <ChipPoints
              :points="points"
            />
          </v-row>
          <v-row justify="center">
            <v-chip
              class="mt-1 mb-0"
              small
              :color="badgeData.color"
              :style="{color: badgeData.color}"
            >
              <span style="color: black !important;">
                {{ $t('awardLevel') }}: {{ $t('Badges.' + badgeData.stufe.toLowerCase()) }}
              </span>
            </v-chip>
          </v-row>
          <v-row
            justify="center"
            class="pb-4 pt-2"
          >
            #{{ cider.displayId }}
          </v-row>
          <v-row
            justify="center"
            class="pt-1 pb-4 mt-1 text-h6 pb-1"
            style="line-height: 1;"
          >
            {{ cider.kategorie.name }}
          </v-row>
          <v-row
            justify="center"
            align="center"
            class="pt-0 pb-4 mt-0 text-h6"
          >
            <v-col
              cols="auto"
              class="align-center pa-0"
            >
              <p
                v-if="cider.land != null && cider.land.code != null"
                class="text-subtitle-1 text--primary mb-0"
              >
                {{ cider.land.name }}
              </p>
              <p
                v-if="cider.land == null || cider.land.code == null"
                class="text-subtitle-1 text--secondary mb-0"
              >
                {{ $t('countryNotSpec') }}
              </p>
            </v-col>
            <v-col
              cols="auto"
              class="align-center pa-0"
            >
              <country-flag
                v-if="cider.land != null && cider.land.code != null"
                :country="cider.land.code"
                class="d-flex elevation-2 mt-auto mb-auto"
                style="border-radius: 10px !important;"
                size="normal"
              />
<!--              <country-flag-->
<!--                :country="cider.land.code"-->
<!--                class="d-flex elevation-2 mt-auto mb-auto"-->
<!--                style="border-radius: 10px !important;"-->
<!--                size="normal"-->
<!--              />-->
            </v-col>

          </v-row>
          <v-divider
            class="mt-3"
          />
          <v-row
            justify="center"
            class="pt-0 mt-0 mb-0 pb-0 text-h6"
            style="font-size: 11pt !important; line-height: 1;"
          >
<!--            <span-->
<!--              v-if="!commentEmpty"-->
<!--              class="pt-1 text-h6 pr-1"-->
<!--              style="color: var(&#45;&#45;v-on_surface_variant-base)"-->
<!--            >“</span>-->
            <Comment_editor
              v-if="isChair"
              :readonly="true"
              class="text-break text-wrap text-ellipsis"
              style="max-width: 100% !important;"
              :content="!chairCommentEmpty ? comment : $t('noComment')"
              :background-color="'finishCiderBackground'"
            />
            <v-card-subtitle
              v-if="!isChair"
              class="pt-3 pb-1 pl-0 pr-0 text-wrap"
              style="white-space: pre; text-align: center;"
              v-html="!userCommentEmpty ? comment : $t('noComment')"
            />
<!--            <span-->
<!--              v-if="!commentEmpty"-->
<!--              class="pt-1 text-h6 pl-1"-->
<!--              style="color: var(&#45;&#45;v-on_surface_variant-base)"-->
<!--            >”</span>-->
          </v-row>
        </v-sheet>
        <v-card-text
          v-if="chairCommentEmpty"
          class="pl-8 pr-8  text-left text-h6"
          style="color: var(--v-error-base)"
        >
          {{ $t('enterAFinalComment') }}
        </v-card-text>
        <v-card-text
          v-if="userCommentEmpty"
          class="pl-8 pr-8 text-left text-h6"
          style="color: var(--v-error-base)"
        >
          {{ $t('enterAJurorComment') }}
        </v-card-text>
        <v-card-text
          class="pl-8 pr-8  text-left"
          v-html="isChair ? $t('messageChair') : $t('messageJuror')"
        />
        <v-card-actions
          class="pb-4"
          style="bottom: 0; background-color: var(--v-background-base);"
          :style="{'position': dialog ? 'sticky !important' : 'absolute',}"
        >
          <v-spacer />
          <v-btn
            color="error"
            text
            rounded
            @click.native="cancel"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            :color="commentEmpty ? 'text_box_border' : 'primary'"
            text
            :disabled="commentEmpty"
            rounded
            outlined
            :style="{'border-color': commentEmpty ? 'var(--v-text_box_border-base)' : 'var(--v-primary-base)'}"
            @click.native="agree"
          >
            {{ $t("okay") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import cider_mixin from "@/components/mixins/cider_mixin";
import Badge from "@/components/badge";
import CountryFlag from 'vue-country-flag'
import ChipPoints from "@/components/chip_points";
import Comment_editor from "@/components/comment_editor";
import i18n from '@/i18n'

export default {
  name: "ModalFinishCider",
  components: {Comment_editor, ChipPoints, Badge, CountryFlag},
  mixins: [cider_mixin],
  props: {
    cider: {type: Object, required: true, default: null},
    points: {type: Number, required: true, default: null},
    // showCider: {type: Boolean, required: false, default: true},
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    isChair: false,
    comment: null,
    showCider: true,
    routerTarget: null,
  }),
  computed: {
    badgeData() {
      return this.getBadgeData(this.points);
    },
    commentEmpty() {
      return this.isChair ? this.chairCommentEmpty : this.userCommentEmpty
    },
    userCommentEmpty() {
      return !this.isChair && (this.comment === '' || this.comment === null || this.comment === undefined);
    },
    chairCommentEmpty() {
      if (this.isChair && this.commentIsEmpty(this.comment))
          return true
      else return false
    },
  },
  i18n: {
    messages: {
      de: {
        messageJuror:
          `Individuelle Bewertung als abgeschlossen markieren.<br>` +
          `Die Bewertungen der anderen Juroren können daraufhin eingesehen werden.<br>` +
          "Solange der Cider vom Chair noch nicht als abgeschlossen markiert wurde, kann die Bewertung geändert werden.",
        messageChair:
          `Cider als abgeschlossen und bewertet markieren.<br>` +
          `Um den Cider erneut zu bewerten, muss er erst wieder durch den Chair freigegeben werden.`,
      },
      en: {
        messageJuror:
          `Mark individual evaluation as complete.<br>` +
          `The evaluations of the other jurors can then be viewed.<br>` +
          "As long as the cider has not yet been marked as complete by the chair, the rating can be changed.",
        messageChair:
          `Mark cider as complete and rated.<br>` +
          `In order to evaluate the cider again, the evaluation has to be restarted by the chair.`,
      }
    }
  },
  methods: {
    open(isChair = false, comment = null, showCider = true, routerTarget = null) {
      this.isChair = isChair;
      this.comment = comment;
      this.showCider = showCider;
      this.routerTarget = routerTarget;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },
    async agree() {
      this.resolve(true)
      this.dialog = false
      if (this.routerTarget != null) await this.$router.push(this.routerTarget);
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    },
  }
}
</script>

<style scoped>
.dashed {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 24px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no_scrollbar::-webkit-scrollbar /deep/ {
  display: none !important;
}

/deep/ .tiptap-vuetify-editor__content--disabled {
  color: var(--v-text_std-base) !important;
  border: 0px !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no_scrollbar /deep/ {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}
</style>