<!--uses locales-->
<template>
  <v-container
    v-if="loaded"
    style="max-width: 1000px; width: 100%;"
  >
    <v-form
      ref="form"
      v-model="valid"
      class="d-flex flex-column"
      lazy-validation
    >
      <v-row
        id="companyInfoLabel"
        class="info-label"
      >
        <v-col class="pa-0 pb-4">
          <v-card-title class="pa-0 pl-4 text--secondary">
            <v-icon class="mr-2">
              mdi-domain
            </v-icon>
            <span class="info-label-text">
              {{ $t('accountHandling.companyInformation') }}
            </span>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row
        id="companyInfo"
        no-gutters
      >
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
        >
          <v-text-field
            v-model="companyName"
            :rules="companyNameRules"
            outlined
            :label="$t('accountHandling.companyName')"
            value=""
            @change="changeForm"
          />
        </v-col>

        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
        >
          <v-text-field
            v-model="legalForm"
            :rules="legalFormRules"
            outlined
            :label="$t('accountHandling.legalForm')"
            value=""
            class="ml-0 ml-sm-2 ml-md-2"
            @change="changeForm"
          />
        </v-col>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="contactPerson"
            :rules="contactPersonRules"
            outlined
            :label="$t('accountHandling.contactPerson')"
            value=""
            @change="changeForm"
          />
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <v-text-field
            v-model="website"
            :rules="websiteRules"
            outlined
            :label="$t('accountHandling.website')"
            value=""
            @change="changeForm"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="vat"
            :rules="vatRules"
            outlined
            :label="$t('accountHandling.vat')"
            value=""
            class="ml-0 ml-md-2"
            @change="changeForm"
          />
        </v-col>
      </v-row>

      <v-row
        id="legalAddressLabel"
        class="info-label"
      >
        <v-col class="pa-0 pb-4">
          <v-card-title class="pa-0 pl-4 text--secondary">
            <v-icon class="mr-2">
              mdi-home-city
            </v-icon>
            <span class="info-label-text">
              {{ $t('accountHandling.legalAdress') }}
            </span>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row
        id="street"
        no-gutters
      >
        <v-col
          cols="12"
          xs="12"
          sm="10"
          md="10"
        >
          <v-text-field
            v-model="street"
            :rules="streetRules"
            outlined
            :label="$t('accountHandling.streetName')"
            value=""
            @change="changeForm"
          />
        </v-col>
        <v-col
          cols="12"
          xs="12"
          sm="2"
          md="2"
        >
          <v-text-field
            v-model="streetNumber"
            :rules="streetNumberRules"
            outlined
            :label="$t('accountHandling.number')"
            value=""
            class="ml-0 ml-sm-2 ml-md-2"
            @change="changeForm"
          />
        </v-col>
      </v-row>
      <v-row
        id="city"
        no-gutters
      >
        <v-col
          cols="12"
          xs="12"
          sm="8"
          md="8"
        >
          <v-text-field
            v-model="city"
            :rules="cityRules"
            outlined
            :label="$t('accountHandling.city')"
            value=""
            @change="changeForm"
          />
        </v-col>
        <v-col
          cols="12"
          xs="12"
          sm="4"
          md="4"
        >
          <v-text-field
            v-model="postalCode"
            :rules="postalCodeRules"
            outlined
            :label="$t('accountHandling.postalCode')"
            value=""
            class="ml-0 ml-sm-2 ml-md-2"
            @change="changeForm"
          />
        </v-col>
      </v-row>
      <v-row
        id="state"
        no-gutters
      >
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
        >
          <v-text-field
            v-model="state"
            :rules="stateRules"
            outlined
            :label="$t('accountHandling.provinceState')"
            value=""
            @change="changeForm"
          />
        </v-col>
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
        >
          <!--          <v-text-field-->
          <!--            v-model="country"-->
          <!--            :rules="countryRules"-->
          <!--            outlined-->
          <!--            label="Country"-->
          <!--            value=""-->
          <!--            class="ml-0 ml-sm-2 ml-md-2"-->
          <!--          />-->
          <v-autocomplete
            v-model="selectedCountry"
            :items="allCountries"
            :label="$t('accountHandling.country')"
            :rules="countryRules"
            outlined
            clearable
            return-object
            item-text="name"
            item-value="code"
            class="ma-0 pa-0 ml-0 ml-sm-2 ml-md-2"
            @change="changeForm"
          >
            <template
              #item="{item}"
              class="ma-0"
            >
              <country-flag
                :country="(item.flag_code||item.flagCode) || item.code.split('-')[0]"
                size="normal"
              />
              {{ item.name }}
            </template>
            <template #selection="{item}">
              <country-flag
                :country="(item.flag_code||item.flagCode) || item.code.split('-')[0]"
                size="normal"
              />
              {{ item.name }}
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row
        id="contactLabel"
        class="info-label"
      >
        <v-col class="pa-0 pb-4">
          <v-card-title class="pa-0 pl-4 text--secondary">
            <v-icon class="mr-2">
              mdi-account-outline
            </v-icon>
            <span class="info-label-text">
              {{ $t('accountHandling.contactInformation') }}
            </span>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row
        id="contact"
        no-gutters
      >
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="email"
            :rules="emailRules"
            outlined
            :label="$t('accountHandling.email')"
            value=""
            @change="changeForm"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="phone"
            :rules="phoneRules"
            outlined
            :label="$t('accountHandling.phoneNumber')"
            value=""
            class="ml-0 ml-md-2"
            @change="changeForm"
          />
        </v-col>
      </v-row>

<!--      <v-row-->
<!--        id="contactLabel"-->
<!--        class="info-label"-->
<!--      >-->
<!--        <v-col class="pa-0 pb-4">-->
<!--          <v-card-title class="pa-0 pl-4 text&#45;&#45;secondary">-->
<!--            <v-icon class="mr-2">-->
<!--              mdi-domain-->
<!--            </v-icon>-->
<!--            <span class="info-label-text">-->
<!--              {{ $t('accountHandling.publicProfile') }}-->
<!--            </span>-->
<!--          </v-card-title>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--      <v-row-->
<!--        id="contact"-->
<!--        no-gutters-->
<!--      >-->
<!--        <v-col-->
<!--          cols="12"-->
<!--          md="12"-->
<!--        >-->
<!--          <div-->
<!--            style="text-align: left"-->
<!--            class="pb-2 pt-4 pl-4"-->
<!--          >-->
<!--            {{ $t('accountHandling.companyLogo') }}-->
<!--          </div>-->

<!--          <ProducerUploadImage-->
<!--            :existing-image-ids="imageIdsCopy"-->
<!--            class="ml-3 mr-3"-->
<!--            style="width: 100% !important;"-->
<!--            @change="changeUploadedImages"-->
<!--          />-->
<!--        </v-col>-->
<!--        <v-col-->
<!--          cols="12"-->
<!--          md="6"-->
<!--        />-->
<!--      </v-row>-->
<!--      <v-row-->
<!--        id="contact"-->
<!--        no-gutters-->
<!--      >-->
<!--        <v-col-->
<!--          cols="12"-->
<!--          md="12"-->
<!--        >-->
<!--          <div-->
<!--            style="text-align: left"-->
<!--            class="pb-2 pt-4 pl-4"-->
<!--          >-->
<!--            {{ $t('accountHandling.banner') }}-->
<!--          </div>-->

<!--          <ProducerUploadImage-->
<!--            :existing-image-ids="imageIdsCopy"-->
<!--            class="ml-3 mr-3"-->
<!--            style="width: 100% !important;"-->
<!--            @change="changeUploadedImages"-->
<!--          />-->
<!--        </v-col>-->
<!--        <v-col-->
<!--          cols="12"-->
<!--          md="12"-->
<!--        />-->
<!--      </v-row>-->
<!--      <v-row-->
<!--        id="contact"-->
<!--        no-gutters-->
<!--      >-->
<!--        <v-col-->
<!--          cols="12"-->
<!--          md="12"-->
<!--        >-->
<!--          <div-->
<!--            style="text-align: left"-->
<!--            class="pb-2 pt-4 pl-4"-->
<!--          >-->
<!--            {{ $t('accountHandling.banner') }}-->
<!--          </div>-->

<!--          <ProducerUploadImage-->
<!--            :existing-image-ids="imageIdsCopy"-->
<!--            class="ml-3 mr-3"-->
<!--            style="width: 100% !important;"-->
<!--            @change="changeUploadedImages"-->
<!--          />-->
<!--        </v-col>-->
<!--        <v-col-->
<!--          cols="12"-->
<!--          md="6"-->
<!--        />-->
<!--      </v-row>-->

      <v-row
        no-gutters
        class="d-flex align-center pt-10"
      >
        <v-divider />
        <v-col
          cols="12"
          xs="6"
          md="6"
          class="d-flex justify-start align-center"
        >
          <div
            class="d-flex justify-start align-center btn-full-width pb-3"
          >
            <v-btn
              v-show="!hideReset"
              class="mt-4"
              width="175"
              :min-width="isSmallScreen ? '100%' : ''"
              color="error"
              elevation="0"
              :disabled="confirmButtonLoading || parentStillLoading"
              style="text-transform: unset !important;"
              @click="reset"
              @change="changeForm"
            >
              {{ $t('resetData') }}
            </v-btn>
          </div>
        </v-col>
        <v-col
          cols="12"
          xs="6"
          md="6"
          class="d-flex justify-end align-center flex-row pb-3"
        >
          <v-row
            class="d-flex justify-end align-center pl-3 pr-3"
          >
            <v-col
              cols="6"
              class="d-flex justify-end align-center pa-0 pr-2"
            >
              <v-btn
                class="mt-4"
                :width="isSmallScreen ? '100%' : '175'"
                :disabled="confirmButtonLoading || parentStillLoading"
                color="outline"
                elevation="0"
                style="text-transform: unset !important; color: white;"
                @click="cancelEvent"
              >
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              class="d-flex justify-end align-center pa-0 pl-2"
            >
              <v-btn
                class="mt-4"
                :width="isSmallScreen ? '100%' : '175'"
                :loading="confirmButtonLoading || parentStillLoading"
                color="primary"
                elevation="0"
                style="text-transform: unset !important;"
                @click="validateAndUpload"
              >
                {{ $t('Login.confirm') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!--      Delete Account Area-->
      <v-row
        v-if="!hideDeleteAccount"
        id="contactLabel"
        class="info-label pt-16"
      >
        <v-col class="pa-0 pb-4">
          <v-card-title class="pa-0 pl-4 text--secondary">
            <v-icon class="mr-2">
              mdi-domain
            </v-icon>
            <span class="info-label-text">
              {{ $t('accountHandling.deleteAccount') }}
            </span>
          </v-card-title>
          <div
            style="text-align: left"
            class="pb-2 pt-4 pl-4"
          >
            {{ $t('accountHandling.pleaseDeleteAccount') }}
          </div>
        </v-col>
      </v-row>

      <v-banner
        v-model="showDeleteBanner"
        transition="slide-y-transition"
        style="max-width: 650px; font-size: 14px !important;"
        outlined
        sticky
        :mobile-breakpoint="$vuetify.breakpoint.name"
        rounded="xl"
        class="text-caption ma-4 text-left ml-auto mr-auto"
      >
        <div class="d-flex align-baseline text-h5 pb-4">
          <v-icon
            left
            size="32"
          >
            mdi-account-remove
          </v-icon>
          {{ $t('Login.requestDeletionProducer') }}
        </div>
        <span class="text--secondary">
          {{ $t('Login.deleteAccountResponse') }}
        </span>
        <template #actions="{ dismiss }">
          <v-btn
            rounded
            text
            color="primary"
            @click="dismiss"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            rounded
            text
            color="error"
            :loading="loadingDelete"
            @click="sendDeletionRequest"
          >
            {{ $t('Login.requestDeletion') }}
            <!--          <a-->
            <!--            style="color: unset !important;"-->
            <!--            :href="deletionLink"-->
            <!--            class="ml-1"-->
            <!--            @click.stop-->
            <!--          >-->
            <!--            Request Deletion-->
            <!--          </a>-->
          </v-btn>
        </template>
      </v-banner>
      <v-row
        v-show="!showDeleteBanner && !hideDeleteAccount"
      >
        <v-col class="d-flex justify-start">
          <v-btn
            class=""
            :width="isSmallScreen ? '100%' : ''"
            :loading="loadingDelete"
            color="error"
            elevation="0"
            style="text-transform: unset !important;"
            @click="openDeleteBanner"
          >
            <v-icon left>
              mdi-account-remove
            </v-icon>
            {{ $t('Login.requestAccountDeletion') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>






    <v-snackbar
      v-model="showSuccessSnackbar"
      color="secondary_card"
      rounded="xl"
      :multi-line="false"
      bottom
      style="opacity: 0.95;"
    >
      <v-card-title class="text-break d-flex pt-2 pb-2 text-center justify-center pr-0">
        <v-icon left>
          mdi-check-circle
        </v-icon>
        {{ $t('successEdit') }}
      </v-card-title>
      <template #action="{ attrs }">
        <div
          class="d-flex pl-0 ml-0"
          :class="{'flex-column': isSmallScreen}"
        >
          <v-btn
            color="white"
            style="opacity: 0.7;"
            text
            rounded
            v-bind="attrs"
            @click="showSuccessSnackbar = false"
          >
            {{ $t('close') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="white"
            text
            rounded
            v-bind="attrs"
            class="ml-2"
            @click="$router.push(redirectUrl)"
          >
            <v-icon left>
              mdi-glass-wine
            </v-icon>
            {{ $t('showMyProducts') }}
          </v-btn>
        </div>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="showErrorSnackbar"
      color="error"
      rounded="xl"
      :multi-line="false"
      bottom
      style="opacity: 0.95;"
    >
      <v-card-title class="text-break d-flex pt-2 pb-2 text-center justify-center">
        <v-icon left>
          mdi-alert-circle
        </v-icon>
        {{ $t('errorEdit') }}
      </v-card-title>
      <template #action="{ attrs }">
        <v-spacer />
        <v-btn
          color="white"
          text
          rounded
          v-bind="attrs"
          class="ml-auto ml-4"
          @click="reloadPage"
        >
          {{ $t('reloadPage') }}
        </v-btn>
      </template>
    </v-snackbar>

    <Confirm_dialog ref="confirm" />
    <Delete_dialog ref="deleteDialog" />

    <v-snackbar
      v-model="showSuccessDeletionSnackbar"
      color="secondary_card"
      rounded="xl"
      :multi-line="false"
      bottom
      vertical
      style="opacity: 0.95;"
    >
      <v-card-title class="text-break d-flex pt-2 pb-2 text-center justify-center">
        <v-icon left>
          mdi-check-circle
        </v-icon>
        {{ $t('requestReceived') }}
      </v-card-title>
      <template #action="{ attrs }">
        <v-btn
          color="white"
          style="opacity: 0.7;"
          text
          rounded
          v-bind="attrs"
          @click="showSuccessDeletionSnackbar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="showErrorDeletionSnackbar"
      color="error"
      rounded="xl"
      :multi-line="true"
      bottom
      vertical
      style="opacity: 0.95;"
    >
      <v-card-title class="text-break d-flex pt-2 pb-2 text-left justify-center">
        <v-icon left>
          mdi-alert-circle
        </v-icon>
        {{ $t('login.requestFailed') }}
      </v-card-title>
      <template #action="{ attrs }">
        <v-spacer />
        <v-btn
          color="white"
          text
          rounded
          v-bind="attrs"
          class="ml-auto ml-4"
          @click="reloadPage"
        >
          {{ $t('reloadPage') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import {ProducerData} from "@/types/producer";
import {apiTyped} from "@/api";
import CountryFlag from "vue-country-flag";
import Confirm_dialog from "@/components/confirm_dialog";
import ProducerUploadImage from "@/components/producer/producer_upload_image";
import Delete_dialog from "@/components/producer/delete_dialog";

export default {
  name: "ProducerEditInformationForm",
  components: {
    // Heading2,
    Delete_dialog,
    Confirm_dialog,
    CountryFlag,
  },
  props: {
    producerData: {type: Object, required: true, default: null},
    countries: {type: Array, required: false, default: null},
    redirectUrl: {type: String, required: false, default: '/producer/home'},
    hideSuccess: {type: Boolean, required: false, default: false},
    hideReset: {type: Boolean, required: false, default: false},
    hideDeleteAccount: {type: Boolean, required: false, default: false},
    parentStillLoading: {type: Boolean, required: false, default: false},
  },
  i18n: {
    messages: {
      de: {
        'nameRequired': 'Name wird benötigt',
        'legalFormRequired': 'Rechtsform wird benötigt',
        'contactPersonRequired': 'Ansprechpartner wird benötigt',
        'streetRequired': 'Straßenname wird benötigt',
        'houseNumberRequired': 'Hausnummer wird benötigt',
        'cityRequired': 'Stadt wird benötigt',
        'postalCodeRequired': 'Postleitzahl wird benötigt',
        'countryRequired': 'Land wird benötigt',
        'emailRequired': 'E-mail Adresse wird benötigt',
        'emailInvalid': 'E-mail falsch formatiert',
        'phoneRequired': 'Telefonnummer wird benötigt',
        'websiteRequired': 'Website wird benötigt',
        'resetAccountMessage': `Löschen der Account Informationen.<br>
        <strong>Nachdem die Änderungen gespeichert wurden, können sie nicht mehr rückgängig gemacht werden.</strong>`,
        'successEdit': 'Informationen aktualisiert',
        'errorEdit': 'Fehler beim aktualisieren der Informationen',
      },
      en: {
        'nameRequired': 'Name is required',
        'legalFormRequired': 'Legal Form is required',
        'contactPersonRequired': 'Contact Person is required',
        'streetRequired': 'Street is required is required',
        'houseNumberRequired': 'House Number is required',
        'cityRequired': 'City is required',
        'postalCodeRequired': 'Postal Code is required',
        'countryRequired': 'Country is required',
        'emailRequired': 'E-mail Address is required',
        'emailInvalid': 'E-mail must be valid',
        'phoneRequired': 'Phone Number is required',
        'websiteRequired': 'A Website is required',
        'resetAccountMessage': `Do you want to reset your Account Information?<br>
        <strong>After saving the changes, this action cannot be undone.</strong>`,
        'successEdit': 'Information updated',
        'errorEdit': 'Information updated failed',
      }
    }
  },
  data: () => ({
    loaded: false,
    valid: false,
    loadingDelete: false,
    hasDeletionRequest: false,
    showSuccessDeletionSnackbar: false,
    showErrorDeletionSnackbar: false,
    showDeleteBanner: false,
    changeCounter: 0,
    confirmButtonLoading: false,
    companyName: "",
    legalForm: "",
    contactPerson: "",
    street: "",
    streetNumber: "",
    city: "",
    postalCode: "",
    state: "",
    stateRules: [],
    allCountries: [],
    selectedCountry: null,
    phone: "",
    email: "",
    website: "",
    vat: "",
    vatRules: [],
    showSuccessSnackbar: false,
    showErrorSnackbar: false,
  }),
  computed: {
    // deletionLink() {
    //   return `mailto:info@cider-world.com?subject=Account%20Deletion&body=I request the deletion of my producer account: ${this.$store.state.usersStore.me.email}`
    // },
    companyNameRules() {
      return [
        v => !!v || this.$t('nameRequired'),
      ]
    },
    legalFormRules() {
      return [
        v => !!v || this.$t('legalFormRequired'),
      ]
    },
    contactPersonRules() {
      return [
        v => !!v || this.$t('contactPersonRequired'),
      ]
    },
    streetRules() {
      return [
        v => !!v || this.$t('streetRequired'),
      ]
    },
    streetNumberRules() {
      return [
        v => !!v || this.$t('houseNumberRequired'),
      ]
    },
    cityRules() {
      return [
        v => !!v || this.$t('cityRequired'),
      ]
    },
    postalCodeRules() {
      return [
        v => !!v || this.$t('postalCodeRequired'),
      ]
    },
    countryRules() {
      return [
        v => !!v || this.$t('countryRequired'),
      ]
    },
    emailRules() {
      return [
        v => !!v || this.$t('emailRequired'),
        v => /.+@.+\..+/.test(v) || this.$t('emailInvalid'),
      ]
    },
    phoneRules() {
      return [
        v => !!v || this.$t('phoneRequired'),
      ]
    },
    websiteRules() {
      return [
        v => !!v || this.$t('websiteRequired'),
      ]
    },
    producerDataForm() {
      return {
        id: this.producerData.id,
        companyName: this.companyName,
        contactPerson: this.contactPerson,
        legalForm: this.legalForm,
        street: this.street,
        streetNumber: this.streetNumber,
        city: this.city,
        postalCode: this.postalCode,
        province: this.state,
        country: this.selectedCountry,
        mobilePhone: this.phone,
        email: this.email,
        website: this.website,
        vat: this.vat,
      };
    },
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true
        case 'sm':
          return false
        default:
          return false
      }
    },
  },
  async created() {
    if (this.countries == null || this.countries.length === 0) {
      this.allCountries = await apiTyped.getAllCountries() || [];
    } else {
      this.allCountries = this.countries;
    }
    this.companyName = this.producerData.companyName;
    this.contactPerson = this.producerData.contactPerson;
    this.legalForm = this.producerData.legalForm;
    this.street = this.producerData.street;
    this.streetNumber = this.producerData.streetNumber;
    this.city = this.producerData.city;
    this.postalCode = this.producerData.postalCode;
    this.state = this.producerData.province;
    this.selectedCountry = this.producerData.country;
    this.phone = this.producerData.mobilePhone;
    this.email = this.producerData.email;
    this.website = this.producerData.website;
    this.vat = this.producerData.vat;

    this.changeCounter = 0;
    this.loaded = true;
  },
  methods: {
    setButtonLoading(state) {
      console.log("Waiting", state)
      this.confirmButtonLoading = state;
    },
    async openDeleteBanner() {
      // this.hasRequest = await apiTyped.hasRequestAccountDeletionProducer();
      this.showDeleteBanner = true;
    },
    async sendDeletionRequest() {
      this.loadingDelete = true;
      const resp = await apiTyped.requestAccountDeletionProducer();
      if (resp == null) {
        this.showErrorDeletionSnackbar = true;
      }
      this.showSuccessDeletionSnackbar = true;
      this.showDeleteBanner = false;
      this.loadingDelete = false;
    },
    async cancelEvent() {
      this.$emit('submit', false);
      if (this.redirectUrl) await this.$router.push(this.redirectUrl);
    },
    async reset () {
      const okayToDel = await this.$refs.deleteDialog.open(
        "Reset Data",
        this.$t('resetAccountMessage'),
        {color: 'secondary_card', thirdButton: false}, {btnOkay: 'Login.reset'})
      if (okayToDel === true) {
        this.$refs.form.reset();
      }
    },
    async validate() {
      await this.$refs.form.validate();
      return this.valid;
    },
    async validateAndUpload () {
      await this.$refs.form.validate();
      // console.log("Vals", this.valid)
      if (this.valid) await this.uploadProducerData();
      this.$emit("confirm", this.valid);
    },
    async uploadProducerData(showDialog = true) {
      this.confirmButtonLoading = true;
      const newProducerData = this.producerDataForm;
      let success = false;
      try {
        newProducerData.firstLogin = false;
        success = await apiTyped.producerUpdateData(newProducerData);
        this.$store.commit("usersStore/setUser", apiTyped.user);
      } catch (e) {
        console.error("Error updating Producer Data:", e)
      }
      // console.log("Upload", success)
      if (showDialog && !this.hideSuccess) {
        console.log("SHOW NACK", this.parentStillLoading)
        if (success && !this.parentStillLoading) this.showSuccessSnackbar = true;
        else this.showErrorSnackbar = true;
      }
      this.changeCounter = 0;
      this.$emit('changeCounter', 0);
      this.$emit('updateData', newProducerData);
      this.$emit('submit', success)
      this.confirmButtonLoading = false;
    },
    changeForm() {
      this.changeCounter += 1;
      // console.log("Changed", this.changeCounter)
      this.$emit('changeCounter', this.changeCounter);
    },
    reloadPage() {
      window.location.reload(true)
    },
  },

}
</script>

<style scoped>
.info-label {
  padding-bottom: 16px;
}

.info-label-text {
  text-decoration: underline var(--v-divider-base);
  text-underline-offset: 8px;
}

.btn-full-width {
  width: 100%;
}
</style>