<template>
  <v-select
    v-model="getUserLanguage"
    :items="languages"
    :label="$t('language')"
    outlined
    return-object
    item-text="name"
    item-value="code"
    style="border-radius: 20px !important;"
    class="ma-0 pa-0"
  >
    <template
      #item="{item}"
      class="ma-0"
    >
      <country-flag
        :country="item.flag_code || item.code.split('-')[0]"
        size="normal"
      />
      {{ item.name }}
    </template>
    <template #selection="{item}">
      <country-flag
        :country="item.flag_code || item.code.split('-')[0]"
        size="normal"
      />
      {{ item.name }}
    </template>
  </v-select>
</template>

<script>
import CountryFlag from "vue-country-flag";

export default {
  name: "LanguageSelect",
  components: {
    CountryFlag
  },
  computed: {
    languages() {
      if (this.$store.state.languages !== null) return this.$store.state.languages
      else return [];
    },
    getUserLanguage: {
      get() {
        return this.$store.getters["usersStore/getUserLanguage"]();
      },
      async set(value) {
        await this.$store.dispatch("usersStore/setLanguage", value.code);
      },
    },
  },
}
</script>

<style scoped>

</style>