<template>
  <v-container
    class="d-flex flex-row pa-0 justify-end ml-0 mr-0 overflow-visible"
    :style="{'height': badgeWidth + 'px', 'width': distance * Math.min(badgesWithImages.length, maxCountBadges) + badgeWidth + extraWidth - 4 + 'px !important',}"
  >
    <div
      style="position: relative; width: 100%; right: 0; overflow: visible !important;"
    >
<!--      <v-menu-->
<!--        v-for="(badge, index) in badgesDataFiltered"-->
<!--        :key="index"-->
<!--        open-on-hover-->
<!--        open-delay="250"-->
<!--        origin="center bottom"-->
<!--        top-->
<!--        :position-y="32"-->
<!--        transition="scale-transition"-->
<!--      >-->
<!--        <template #activator="{ on }">-->
<!--          <div-->
<!--            v-on="on"-->
<!--          >-->
<!--            <badge-->
<!--              :badge-data="badge"-->
<!--              :best-of-category="bestOfCategory[index]"-->
<!--              :badge-resolution="500"-->
<!--              :show-none-badge="showNoneBadge"-->
<!--              :badge-width="badgeWidth"-->
<!--              :badge-shadow="badgeShadow"-->
<!--              style="position: absolute; overflow: visible;"-->
<!--              :style="{right: index * distance + 'px', 'z-index': badgesDataFiltered.length - 1 - index, 'min-height': badgeWidth + 'px'}"-->
<!--            />-->
<!--          </div>-->
<!--        </template>-->
<!--        <v-card-->
<!--          color="transparent"-->
<!--        >-->
<!--          <badge-->
<!--            :badge-data="badge"-->
<!--            :best-of-category="bestOfCategory[index]"-->
<!--            :badge-resolution="500"-->
<!--            :show-none-badge="showNoneBadge"-->
<!--            :badge-width="250"-->
<!--            :badge-shadow="badgeShadow"-->
<!--            style="opacity: 1 !important;"-->
<!--          />-->
<!--        </v-card>-->
<!--      </v-menu>-->
      <v-hover
        v-for="(badge, index) in badgesDataFiltered"
        :key="index"
        open-delay="100"
        close-delay="160"
        v-slot="{ hover }"
      >
        <v-card
          :elevation="hover ? 6 : 0"
          class="pa-0 ma-0"
          color="background"
          :class="{'scale-on-hover': hover}"
          style="position: absolute; overflow: visible; transition: all 0.25s linear; box-sizing: border-box;"
          :style="{right: index * distance + 'px', 'z-index': badgesDataFiltered.length - 1 - index, 'min-height': badgeWidth + 'px'}"
        >
          <badge
            class="pa-0"
            style="transition: all 0.25s ease;"
            :badge-data="badge"
            :best-of-category="bestOfCategory[index]"
            :badge-resolution="500"
            :show-none-badge="showNoneBadge"
            :badge-width="badgeWidth"
            :badge-shadow="badgeShadow"
          />
<!--          <div-->
<!--            style="transition:all 0.25s linear; transform: scale(1);"-->
<!--            :class="{'hidden-scale': !hover, 'max-scale-1': hover}"-->
<!--          >-->
<!--            <span-->
<!--            >-->
<!--              Hovered-->
<!--            </span>-->
<!--          </div>-->
        </v-card>
      </v-hover>

      <v-btn
        v-if="moreBadgesToShow"
        :min-width="badgeWidth"
        :min-height="badgeWidth"
        :max-width="badgeWidth"
        :max-height="badgeWidth"
        icon
        disabled
        outlined
        style="position: absolute; left: 4px; top: 0;"
      >
        <!--      :style="{right: (badgesDataFiltered.length * distance + distance*2 + 4) + 'px'}"-->
        <span v-if="badgesWithImages.length - maxCountBadges < 100">
          +{{ badgesWithImages.length - maxCountBadges }}
        </span>
        <span
          v-else
          style="font-size: 9px !important;"
        >
          +{{ badgesWithImages.length - maxCountBadges }}
        </span>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import Badge from "@/components/badge";

export default {
  name: "BadgesStack",
  components: {Badge},
  props: {
    badgesData: {type: Array, required: true, default: null},
    bestOfCategory: {type: Array, required: true, default: null},
    maxCountBadges: {type: Number, required: false, default: 6},
    distance: {type: Number, required: false, default: 16},
    badgeWidth: {type: Number, required: false, default: 32},
    showNoneBadge: {type: Boolean, required: false, default: false},
    badgeShadow: {type: Boolean, required: false, default: false},
    badgeInfos: {type: Array, required: false, default: null},
  },
  computed: {
    extraWidth() {
      return this.moreBadgesToShow ? 32 : 0;
    },
    badgesWithImages() {
      // const repeat = (arr, n) => Array.from({ length: arr.length * n }, (_, i) => arr[i % arr.length]);
      // return repeat(this.badgesData.filter(b => b != null && b.hasImage || this.showNoneBadge),12);
      return this.badgesData.filter(b => b != null && b.hasImage || this.showNoneBadge);
    },
    badgesDataFiltered() {
      return this.badgesWithImages.slice(0, this.maxCountBadges);
    },
    moreBadgesToShow() {
      return this.badgesWithImages.length > this.maxCountBadges;
    }
  }
}
</script>

<style scoped>
.scale-on-hover {
  transform: scale(5) translateY(-8px);
  transform-origin: center bottom;
  overflow: visible;
  z-index: 99 !important;
}
.hidden-scale {
  transform: scale(0) !important;
}
.max-scale-1 {
  transform: scale(0.2) !important;
}
.scale-card {
  transform: scale(5);
}
</style>