<template>
  <v-container class="pa-0 pb-4">
    <v-alert
      v-if="!tastingIsRunning"
      text
      width="640"
      style="max-width: 90vw !important;"
      border="left"
      class="text-left ml-auto mr-auto rounded-lg"
      :type="$store.getters['usersStore/currentTasting']().finished ? 'success' : 'info'"
    >
      {{ $t(tastingStatusText) }}
    </v-alert>
    <v-stepper
      v-model="activeStep"
      alt-labels
      flat
      elevation="0"
      class="mb-4 ma-auto"
      style="max-width: 850px; background-color: #FFFFFF00"
    >
      <v-stepper-header style="justify-content: space-between" class="elevation-0">
        <v-stepper-step
          step="1"
          :editable="currentCider !== undefined"
          :complete="step1Complete"
          complete-icon=""
          class="pa-0 pa-sm-6"
          color="secondary"
        >
          {{ step1Title }}
          <small>
            {{ step1Subtitle }}
          </small>
        </v-stepper-step>

        <v-divider :class="{small_divider: isSmallScreen}" />

        <v-stepper-step
          step="2"
          :editable="step2Enabled"
          complete-icon=""
          edit-icon=""
          :complete="step2Complete"
          class="pa-0 pa-sm-6"
          color="primary"
          @click.native="showSnackbar2"
        >
          {{ step2Title }}
          <small>
            {{ step2Subtitle }}
          </small>
        </v-stepper-step>
        <v-divider :class="{small_divider: isSmallScreen}" />

        <v-stepper-step
          id="stepperStep3"
          step="3"
          :editable="step2Enabled && step3Enabled"
          :complete="step3Complete"
          class="pa-0 pa-sm-6"
          color="red"
          @click.native="showSnackbar3"
        >
          {{ step3Title }}
          <small>
            {{ step3Subtitle }}
          </small>
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>

    <v-snackbar
      v-if="tastingIsRunning"
      v-model="snackbarStep2"
      color="secondary_card"
      rounded="xl"
      :multi-line="true"
      top
      vertical
      style="margin-top: 108px;"
    >
      <v-row
        no-gutters
        class="d-flex align-start"
      >
        <v-col
          cols="11"
          class="pt-1"
        >
          <span>
            {{ $t('CiderStepper.step2Disabled') }}
          </span>
        </v-col>
        <v-col
          cols="1"
          class="d-flex justify-end"
        >
          <v-btn
            icon
            @click="snackbarStep2 = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <template #action="{ attrs }">
<!--        <v-btn-->
<!--          color="white"-->
<!--          style="opacity: 0.7;"-->
<!--          text-->
<!--          rounded-->
<!--          v-bind="attrs"-->
<!--          @click="snackbarStep2 = false"-->
<!--        >-->
<!--          {{ $t('close') }}-->
<!--        </v-btn>-->
        <v-btn
          v-if="tastingIsRunning && nextCiderToRate !== null && nextCiderToRate !== undefined"
          color="white"
          text
          rounded
          outlined
          v-bind="attrs"
          style="text-transform: unset;"
          @click="gotoRating"
        >
          {{ $t('NextCiderCard.startRatingFor') }}: #{{ nextCiderToRate.displayId || nextCiderToRate.id }}
<!--          <v-icon small>-->
<!--            mdi-arrow-right-->
<!--          </v-icon>-->
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-if="tastingIsRunning"
      v-model="snackbarStep3"
      color="secondary_card"
      rounded="xl"
      :multi-line="true"
      top
      style="margin-top: 108px"
    >
      {{ $t("CiderStepper.step3Disabled") }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          rounded
          v-bind="attrs"
          @click="snackbarStep3 = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from "vue";
import CiderRatingState from "@/types/ciderRatingState.ts";
import {RoleName} from "@/types/user";
import {sync} from "vuex-pathify";
import rating_mixin from "@/components/mixins/rating_mixin";
import {allRoutes, makeRoute} from "@/router";

const Component = Vue.extend({
  name: "CiderRatingStepper",
  mixins: [rating_mixin],
  props: {
    markedFinished: Boolean,
  },
  data: () => ({
    // markedFinished: false,
    activeStep: 1,
    snackbarStep2: false,
    snackbarStep3: false,
  }),
  computed: {
    nextCiderToRate() {
      return this.$store.getters["ciderStore/getCurrentCiderToRate"]();
    },
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        default: return false
      }
    },
    currentCiderIdx () {
      return this.$store.getters["ciderStore/getCiderIdxByFlightsCidersId"](Number(this.$route.params.flightsCidersId));
    },
    currentCider: sync('ciderStore/ciderList@[:currentCiderIdx]'),
    ciderState () {
      return this.$store.getters["ciderStore/getCiderRatingState"]();
    },
    step1Title() {
      return this.$t('CiderStepper.step1Title');
    },
    step1Subtitle() {
      if (this.activeStep === 1) {
          return this.$t('CiderStepper.step1Subtitle1');
      }
      if (this.activeStep >= 2) {
        return this.$t('CiderStepper.step1Subtitle2', { ciderDisplayId: this.currentCider.displayId });
      }
      else return "";
    },
    step2Title() {
      return this.$t('CiderStepper.step2Title');
    },
    step2Subtitle() {
      if (this.activeStep >= 2) {
        switch (this.ciderState) {
          case CiderRatingState.notStarted:
            if (this.$store.getters["usersStore/isChair"]())
              return this.$t('CiderStepper.step2Subtitle1');
            else
              return this.$t('CiderStepper.step2Subtitle2');
          case CiderRatingState.startedByChair:
            return this.$t('CiderStepper.step2Subtitle3');
          case CiderRatingState.startedByUser:
            return this.$t('CiderStepper.step2Subtitle4');
          case CiderRatingState.finishedByUser:
            return this.$t('CiderStepper.step2Subtitle5');
          case CiderRatingState.finishedByChair:
            return this.$t('CiderStepper.step2Subtitle6');
          case CiderRatingState.restartedByChair:
            return this.$t('CiderStepper.step2Subtitle7');
          default:
            return ""
        }
      } else {
        return ""
      }
    },
    step3Title() {
      return this.$t('CiderStepper.step3Title');
    },
    step3Subtitle() {
      if (this.activeStep === 2) {
        switch (this.ciderState) {
          case CiderRatingState.finishedByChair:
            return this.$t('CiderStepper.step3SubtitleFinishedByChair');
          default:
            return "";
        }
      }
      if (this.activeStep >= 3) {
        switch (this.ciderState) {
          case CiderRatingState.notStarted:
            if (this.$store.getters["usersStore/isChair"]())
              return this.$t('CiderStepper.step3SubtitleNotStartedChair');
            else
              return this.$t('CiderStepper.step3SubtitleNotStartedJuror');
          case CiderRatingState.startedByChair:
            return this.$t('CiderStepper.step3SubtitleStartedByChair');
          case CiderRatingState.startedByUser:
            return this.$t('CiderStepper.step3Subtitle5StartedByUser');
          case CiderRatingState.finishedByUser:
            if (!this.markedFinished) {
              return this.$t('CiderStepper.step3SubtitleFinishedByUserNotComplete');
            }
            else if (!this.$store.getters["usersStore/isChair"]()) {
              return this.$t('CiderStepper.step3SubtitleFinishedByUserJuror');
            }
            else if (this.$store.getters["usersStore/isChair"]()) {
              return this.$t('CiderStepper.step3SubtitleFinishedByUserChair');
            }
            return "";
          case CiderRatingState.finishedByChair:
            return this.$t('CiderStepper.step3SubtitleFinishedByChair');
          case CiderRatingState.restartedByChair:
            return this.$t('CiderStepper.step3SubtitleRestartedByChair');
          default:
            return ""
        }
      } else {
        return ""
      }
    },
    step1Complete() {
      return false;
      // return this.activeStep > 1 && this.ciderState > CiderRatingState.notStarted;
    },
    step2Complete() {
      return false;
      // return this.activeStep > 2 && this.ciderState > CiderRatingState.notStarted;
    },
    step2Enabled() {
      return this.currentCider !== undefined;
    },
    step3Complete() {
      return false;
      // return this.activeStep > 3 && this.ciderState > CiderRatingState.notStarted;
    },
    step3Enabled() {
      // Enabled, if finished by chair or user, or if was already finished (restarted by chair):
      if (!this.step2Enabled) return false;
      return (this.ciderState === CiderRatingState.finishedByUser
        || this.ciderState === CiderRatingState.finishedByChair
        || this.currentCider.chairNotes?.restartedCount > 0
        || this.getCurrentTasting.finished)
    }
  },
  watch: {
    activeStep: async function (newValue, oldValue) {
      if (oldValue === null || newValue === null) return;
      try {
        let path = "";
        switch (Number(newValue)) {
          case 1:
            path = this.makeTastingRoute(allRoutes.tastings.ciderList, this.getCurrentTasting);
            break;
          case 2:
            path = makeRoute(
              this.makeTastingRoute(allRoutes.tastings.rating, this.getCurrentTasting),
              { flightsCidersId: this.currentCider.flightsCidersId});
            break;
          case 3:
            path = makeRoute(
              this.makeTastingRoute(allRoutes.tastings.summary, this.getCurrentTasting),
              { flightsCidersId: this.currentCider.flightsCidersId});
            break;
          default:
            break;
        }
        if (this.$router.currentRoute.path !== path) await this.$router.push(path);
      }
      catch (error_) {
        // if (error_.name !== 'NavigationDuplicated' &&
        //     !error_.message.includes('Avoided redundant navigation to current location'))
          console.error(error_);
      }
    }
  },
  async mounted() {
    this.activeStep = Number(this.$attrs['active-step'])
  },
  methods: {
    gotoRating () {
      this.$store.state.ciderStore.currentFlightsCidersId = this.nextCiderToRate.flightsCidersId;
      const path = makeRoute(
        this.makeTastingRoute(allRoutes.tastings.rating, this.getCurrentTasting),
        { flightsCidersId: this.nextCiderToRate.flightsCidersId});
      this.$router.push(path);
    },
    setStep(step) {
      this.activeStep = step;
    },
    aaa() {
      console.log("aaa")
    },
    showSnackbar2() {
      if (!this.step2Enabled) {
        this.snackbarStep2 = true;
      }
    },
    showSnackbar3() {
      if (!this.step2Enabled) {
        this.snackbarStep2 = true;
        return
      }
      if (!this.step3Enabled) {
        this.snackbarStep3 = true;
      }
    },
  }
});
export default Component;
</script>

<style>

@media only screen and (max-width: 600px) {
  div.v-stepper__step--active > div.v-stepper__label {
    display: flex !important;
  }
}

@media only screen and (max-width: 959px) and (min-width: 600px) {
  div.v-stepper__label {
    display: flex !important;
  }
}

div.v-stepper__step--editable {
  padding: 4px !important;
}
div.v-stepper__step--editable:hover {
  border-radius: 20px !important;
  background-size: 50% 50%;
}

div.v-stepper__label {
  text-align: center;
  align-items: center;
}

div.v-stepper__label > small {
  text-align: center;
  align-items: center;
  padding-bottom: 2px;
  overflow: visible !important;
}

div.v-stepper__step {
  flex-basis: 240px !important;
  padding: 4px !important;
}

.small_divider {
  max-width: 16px !important;
  margin-top: 16px !important;
}
@media only screen and (max-width: 600px) {
  div.v-stepper__step {
    max-width: 120px;
  }
  .v-stepper {
    overflow: visible !important;
  }
}

</style>