import Vue from "vue";
import i18n from '@/i18n'

export default {
    data: () => ({
        tourLabels: {
            useKeyboardNavigation: true,
            labels: {
                buttonSkip: i18n.t('markCiderAsFavourite'),
                buttonPrevious: 'ss',
                buttonNext: 's',
                buttonStop: 'Finish'
            }
        },
    }),
}