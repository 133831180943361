<template>
  <v-container
    :key="container"
    ref="documentView"
    fluid
    class="d-flex flex-column"
  >
    <span
      class="text-left pl-0 pb-4 text-h4 pt-7"
      style="font-family: 'Nunito Sans', serif !important;"
    >
      {{ $t('resultsForLastTastings') }}
    </span>

    <v-chip-group
      v-model="selectedResultRevision"
      show-arrows
      mandatory
      active-class="secondary"
      class="pb-1 d-flex justify-end"
      @click.native.stop=""
    >
      <div
        v-for="(revision, index) in revisionsReversed"
        :key="`${revision.eingereicht.id}_${index}`"
        class="d-flex"
      >
        <div
          class="d-flex flex-column"
          style="min-width: 80px;"
        >
          <div
            class="d-flex justify-start"
          >
            <span
              class="text-left pl-3 text-h6"
              style="font-family: 'Nunito Sans', serif !important;"
            >
              {{ 
                index === 0 
                  || (index > 0 
                    && revision.eingereicht.tasting.datum.getFullYear() !== revisionsReversed[index - 1].eingereicht.tasting.datum.getFullYear()) 
                  ? revision.eingereicht.tasting.datum.getFullYear() 
                  : '&nbsp;' 
              }}
            </span>
          </div>
          <v-chip
            class="d-flex"
            variant="outlined"
            filter
            pill
            :disabled="loadingRevisionId !== -1"
            style="min-height: 42px; border-radius: 99px;"
            @click="selectCiderResult(revision)"
          >
            <v-tooltip
              top
              open-delay="500"
              color="transparent"
              content-class="blurred-background"
            >
              <template #activator="{ on }">
                <div
                  class="d-flex"
                  v-on="on"
                >
                  <div class="d-flex flex-column align-start justify-center pr-1">
                    <span class="text-left">
                      {{ revision.eingereicht.tasting.abbreviation }}
                    </span>
                  </div>
                  <v-progress-circular
                    v-if="revision.eingereicht.productRevision === loadingRevisionId"
                    size="24"
                    width="2"
                    class="ml-2 mr-2 mt-auto mb-auto"
                    indeterminate
                  />
                  <badge
                    v-if="flightsCidersForRevision(revision) != null
                      && resultIsPublished(flightsCidersForRevision(revision))
                      && flightsCidersForRevision(revision).badge != null
                      && flightsCidersForRevision(revision).badge.hasImage"
                    :badge-data="flightsCidersForRevision(revision).badge"
                    :badge-resolution="200"
                    :badge-width="32"
                    :best-of-category="flightsCidersForRevision(revision).bestOfCategory"
                    :points="flightsCidersForRevision(revision).points"
                    :badge-shadow="true"
                    class="ml-2 overflow-hidden elevation-1"
                    style="border-radius: 99px;"
                  />
                </div>
              </template>
              <v-card
                class="blurred-background"
              >
                <tasting-card
                  :tasting="revision.eingereicht.tasting"
                  :elevation="6"
                  :selected="false"
                  hide-more-infos
                  hide-buttons
                  style="max-width: 450px;"
                />
              </v-card>
            </v-tooltip>
          </v-chip>
        </div>
        <div
          v-if="index + 1 < revisionsReversed.length &&
            revision.eingereicht.tasting.datum.getFullYear() !== revisionsReversed[index + 1].eingereicht.tasting.datum.getFullYear()"
          class="d-flex"
        >
          <span
            class="mr-2"
            style="margin-left: -20px; letter-spacing: -2px; font-family: 'Nunito Sans', serif !important;
             transform: translateY(2px); color: var(--v-divider-base); max-height: 32px;"
          >
            ――
          </span>
        </div>
      </div>
    </v-chip-group>

    <div
      v-if="selectedRevision != null"
    >
      <div class="d-flex align-baseline">
        <div
          class="d-flex text-left text-h2 font-weight-medium align-center pt-4 link-hover"
          style="font-size: 36px !important; font-family: 'Nunito Sans', serif !important; line-height: 1;"
        >
          <router-link
            :to="`/producer/submitted/overview/${selectedRevision.eingereicht.tasting.id}`"
            style="text-decoration: unset; color: unset;"
          >
            {{ selectedRevision.eingereicht.tasting.name }}
          </router-link>
        </div>
        <div class="d-flex">
          <v-btn
            icon
            large
            class="ml-2 mt-1"
            style="text-decoration: none !important;"
          >
            <v-icon>
              mdi-information-outline
            </v-icon>
          </v-btn>
        </div>
      </div>
      <!--      <div class="d-flex mr-auto">-->
      <!--        <v-btn-->
      <!--          class="pl-0 pr-0"-->
      <!--          text-->
      <!--          link-->
      <!--          small-->
      <!--          :to="`/producer/submitted/overview/${selectedRevision.eingereicht.tasting.id}`"-->
      <!--        >-->
      <!--          {{ $t('tastingDetails') }}-->
      <!--        </v-btn>-->
      <!--      </div>-->
    </div>

    <template v-if="ciderHasResults">
      <v-row
        class="d-flex flex-row mt-4 pb-4"
      >
        <v-col cols="auto">
          <v-btn-toggle
            v-if="!loading"
            v-model="showDocument"
            mandatory
            rounded
            dense
          >
            <v-btn
              outlined
              :value="0"
            >
              <v-icon>
                mdi-certificate
              </v-icon>
              <span class="ml-1">
                {{ $t('results') }}
              </span>
            </v-btn>
            <v-btn
              v-if="flightsCider.badge != null && flightsCider.badge.hasImage"
              outlined
              :value="1"
            >
              <v-icon>
                mdi-file-certificate
              </v-icon>
              <span class="ml-1">
                {{ $t('certificate') }}
              </span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col
          v-if="!loading"
          class="d-flex justify-end ml-auto"
        >
          <v-btn
            elevation="0"
            text
            outlined
            color="primary"
            class="mr-1"
            :loading="loadingPrinting"
            @click="printDocument"
          >
            <v-icon left>
              mdi-printer
            </v-icon>
            {{ $t('print') }}
          </v-btn>

          <v-menu
            v-if="hasBadge || hasFiles"
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-btn
                elevation="0"
                text
                outlined
                :loading="downloadButtonLoading"
                color="primary"
                class="mr-1 pr-1"
                v-bind="attrs"
                v-on="on"
              >
                {{ $t('download') }}
                <v-icon class="ml-auto">
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list v-if="hasBadge || hasFiles">
              <v-list-item
                v-if="hasBadge"
                link
                @click="downloadBadge"
              >
                <v-list-item-icon>
                  <v-icon>
                    mdi-star-circle-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('badge') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <template v-if="hasFiles">
                <v-list-item
                  v-for="file in resultCiderFiles"
                  :key="file.id"
                  link
                  @click="downloadFile(file)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      {{ file.icon != null && file.icon !== "" ? file.icon : 'mdi-file-document-outline' }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ getTranslation(file.translations, 'name', currentLanguage, file.name) 
                        || file.name.split('.').slice(0, -1).join('.') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>

          <!--          <v-btn-->
          <!--            v-if="flightsCider.badge != null && flightsCider.badge.hasImage"-->
          <!--            elevation="0"-->
          <!--            text-->
          <!--            outlined-->
          <!--            color="primary"-->
          <!--            class="mr-1"-->
          <!--            @click="downloadBadge"-->
          <!--          >-->
          <!--            <v-icon left>-->
          <!--              mdi-download-->
          <!--            </v-icon>-->
          <!--            {{ $t('badge') }}-->
          <!--          </v-btn>-->
          <!--          <v-btn-->
          <!--            v-if="false"-->
          <!--            elevation="0"-->
          <!--            text-->
          <!--            outlined-->
          <!--            color="primary"-->
          <!--            class="mr-1"-->
          <!--            :loading="loadingDownload"-->
          <!--            @click="downloadDocument"-->
          <!--          >-->
          <!--            <v-icon left>-->
          <!--              mdi-download-->
          <!--            </v-icon>-->
          <!--            {{ $t('download') }}-->
          <!--          </v-btn>-->
        </v-col>
      </v-row>
      <div
        v-if="!loading && !error"
        ref="scaledView"
        class="ml-auto mr-auto"
        style="transform-origin: 0 0; max-width: 21cm; overflow: visible !important; position: relative;"
        :style="{transform: `scale(${pageScale})`}"
      >
        <!--      <v-btn-->
        <!--        fab-->
        <!--        style="position: absolute; bottom: 16px; right: 16px; z-index: 99;"-->
        <!--      >-->
        <!--        <v-icon>-->
        <!--          mdi-printer-->
        <!--        </v-icon>-->
        <!--      </v-btn>-->

        <div
          v-show="showDocument === 0"
        >
          <gesamtauswertung-page
            v-if="ciderHasResults"
            id="pageGesamtauswertung"
            ref="pageGesamtauswertungRef"
            :key="pageGesamtauswertungKey"
            :cider="resultCider"
            :badges-data="flightsCider.badge"
            :best-of-category="flightsCider.bestOfCategory || false"
            :show-individual="false"
            :active-window="1"
            :badges-data-array="badgesData"
            :tasting="tasting"
          />
        </div>
        <div
          v-show="showDocument === 1"
        >
          <urkunde-page-a4
            v-if="flightsCider.badge != null"
            id="urkundePage"
            ref="urkundePDF"
            :cider="resultCider"
            :badge-data="flightsCider.badge"
            :max-points="maxPoints"
            :tasting="tasting"
          />
        </div>
        <div
          v-if="showDocument === 0"
          class="pt-6 pl-8 pr-8 align-start justify-start d-flex pl-16 ml-2 pb-6"
        >
          <v-icon
            left
            small
            style="opacity: 0.6;"
          >
            mdi-information-outline
          </v-icon>
          <span
            class="text-body-2 text--secondary text-left align-start justify-start d-flex "
            v-html="$t('informationAboutWeights')"
          />
        </div>
      </div>
    </template>
    <v-card
      v-if="!loading && !ciderHasResults && loadingRevisionId === -1 && !ciderWasNotRated"
      class="ml-auto mr-auto pt-16 mt-16 pb-12"
      width="300"
      outlined
      style="border-style: solid; border-width: medium; transition: box-shadow 0.2s ease-in-out; aspect-ratio: 21/29.7;"
    >
      <span class="strikethrough mt-auto">
        <v-icon size="192">
          mdi-file-certificate-outline
        </v-icon>
      </span>
      <v-card-subtitle class="text-center text-subtitle-1">
        {{ $t('noResultsYet') }}
      </v-card-subtitle>
      <v-card-subtitle
        v-if="selectedRevision != null && selectedRevision.eingereicht.tasting.expectedPublishDate != null"
        class="text-center text-subtitle-2 pt-0 pb-0"
        v-html="$t('expectedPublishDate', {date: selectedRevision.eingereicht.tasting.expectedPublishDate.toLocaleString()})"
      />
    </v-card>
    <v-card
      v-if="!loading && !ciderHasResults && loadingRevisionId === -1 && ciderWasNotRated && selectedRevision.eingereicht.tasting.expectedPublishDate != null"
      class="ml-auto mr-auto pt-16 mt-16 pb-12"
      width="300"
      outlined
      style="border-style: solid; border-width: medium; transition: box-shadow 0.2s ease-in-out; aspect-ratio: 21/29.7;"
    >
      <span class="strikethrough mt-auto">
        <v-icon size="192">
          mdi-file-certificate-outline
        </v-icon>
      </span>
      <v-card-subtitle class="text-center text-subtitle-1">
        {{ $t('noResults') }}
      </v-card-subtitle>
      <v-card-subtitle
        class="text-center text-subtitle-2 pt-0 pb-0"
      >
        {{ $t('theProductWasNotRated') }}
      </v-card-subtitle>
    </v-card>
  </v-container>
</template>

<script>
import {CProducerCiderResults} from "@/types/producerCider";
import {apiTyped} from "@/api";
import GesamtauswertungPage from "@/components/admin/gesamtauswertung/gesamtauswertung_page";
import UrkundePageA4 from "@/components/admin/urkunde/urkunde_page_a4";
import {Format} from "@/types/badge";
import TastingCard from "@/components/producer/tasting_card.vue";
import Badge from "@/components/badge.vue";
import translation_mixin from "@/components/mixins/translation_mixin";
import user_mixin from "@/components/mixins/user_mixin";

export default {
  name: "ProducerCiderResults",
  components: {Badge, TastingCard, UrkundePageA4, GesamtauswertungPage},
  mixins: [translation_mixin, user_mixin],
  props: {
    cider: { type: [Object, CProducerCiderResults], required: true, default: null },
  },
  i18n: {
    messages: {
      de: {
        "noResults": "Keine Ergebnisse",
        "theProductWasNotRated": "Das Produkt wurde bei diesem Event nicht bewertet. Für weitere Informationen kontaktieren Sie uns bitte über den Support.",
        "informationAboutWeights": `Die Gesamtpunktzahl wird nicht auf den oben angezeigten gewichteten Punktzahlen berechnet,<br>da die einzelnen gewichteten Punktzahlen zur besseren Lesbarkeit auf eine Dezimalstelle gerundet sind.`,
        "resultsForLastTastings": "Tasting-Ergebnisse",
      },
      en: {
        "noResults": "No Results",
        "theProductWasNotRated": "The Product was not rated in this tasting. For further information, please contact us via support.",
        "informationAboutWeights": `The total of weighted scores is not calculated on the displayed characteristic scores above,<br>as individual characteristic ratings are rounded to one decimal place for improved readability.`,
        "resultsForLastTastings": "Tasting-Results",
      }
    }
  },
  data: () => ({
    container: 1,
    downloadButtonLoading: false,
    pageGesamtauswertungKey: 0,
    noFlightYet: false,
    cachedResultCiders: new Map(),
    cachedResultCidersFiles: new Map(),
    cachedResultTasting: new Map(),
    cachedResultBadges: new Map(),
    selectedResultRevision: 0,
    resultCider: null,
    resultCiderFiles: [],
    flightsCider: null,
    updateFlightsCider: 0,
    tasting: null,
    finishedFlightsCider: [],
    badgesData: null,
    loading: true,
    loadingRevisionId: -1,
    error: false,
    maxHeight: '100%',
    pageMaxSizeCm: 21,
    pageScale: 1,
    showDocument: 0,
    loadingPrinting: false,
    loadingDownload: false,
    link0: null,
    link1: null,
  }),
  computed: {
    hasBadge() {
      const a = this.updateFlightsCider;
      return this.flightsCider.badge != null && this.flightsCider.badge.hasImage === true;
    },
    hasFiles() {
      return this.resultCiderFiles.length > 0;
    },
    ciderWasNotRated() {
      return (this.resultCider != null && Object.keys(this.resultCider.allBewertungen).length === 0)
        || (this.resultCider != null && Object.values(this.resultCider.allBewertungen).flat(1).every(
          b => b.punkte == null));
    },
    maxPoints() {
      let max = 0;
      if (this.badgesData == null) return null;
      this.badgesData.forEach(b => {
        if (b.punkteMax > max) max = b.punkteMax;
      });
      return max;
    },
    selectedRevision() {
      if (this.cider.revisions.length > 0 && this.selectedResultRevision < this.cider.revisions.length)
        return this.revisionsReversed[this.selectedResultRevision];
      return null;
    },
    revisionsReversed() {
      return this.cider.revisions.map((item,idx) => this.cider.revisions[this.cider.revisions.length-1-idx]);
    },
    ciderHasResults() {
      // only show cider with results (tasting published)
      return !this.noFlightYet
        && this.cider.flightsCiders.some(f => this.resultIsPublished(f))
        && this.resultCider != null
        && !this.ciderWasNotRated;
    },
    // pageScale() {
    //   const docView = this.$refs.documentView;
    //   if (docView == null) return 1;
    //   const availableWidth = docView.clientWidth;
    //   return availableWidth * 0.026458 / this.pageMaxSizeCm;
    // },
  },
  watch: {
    showDocument: function () {
      this.updateGesamtauswertungPage();
    },
  },
  async created() {
    let foundRevision = false;
    // select first revision with results
    for (const [index, revision] of Object.entries(this.revisionsReversed)) {
      if (this.cider.flightsCiders.find(
        f => this.resultIsPublished(f) && revision.eingereicht.productRevision === f.ciderId)) {
        this.selectedResultRevision = Number(index);
        await this.selectCiderResult(revision);
        foundRevision = true;
        break;
      }
    }
    if (!foundRevision) {
      this.selectedResultRevision = 0;
      await this.selectCiderResult(this.revisionsReversed[0]);
    }
    // this.finishedFlightsCider = this.cider.flightsCiders.filter(f => f.tastingResultPublished);
    // if (this.finishedFlightsCider.length > 0) {
    //   this.flightsCider = this.finishedFlightsCider[0];
    //   this.selectedResultRevision = this.revisionsReversed.findIndex(
    //     r => r.eingereicht.productRevision === this.flightsCider.ciderId);
    //   await this.getResultCider(this.flightsCider);
    // }
    this.loading = false;
    this.$nextTick(() => { this.container += 1; });
  },
  unmounted() {
    window.removeEventListener('resize', this.calcPageScale);
  },
  mounted() {
    window.addEventListener('resize', this.calcPageScale);
    this.calcPageScale();
  },
  methods: {
    resultIsPublished(flightsCiders) {
      return (flightsCiders.tastingResultPublished
          || flightsCiders.tastingResultPublishedFor.includes(this.$store.state.producerStore.producerId))
        && !flightsCiders.hiddenResultsForProducer.includes(this.$store.state.producerStore.producerId);
    },
    updateGesamtauswertungPage() {
      this.pageGesamtauswertungKey += 1;
    },
    flightsCidersForRevision(revision) {
      return this.cider.flightsCiders.find(f => f.ciderId === revision.eingereicht.productRevision) || null;
    },
    uuidV4() {
      let d = new Date().getTime();  // Timestamp
      // Time in microseconds since page-load or 0 if unsupported
      let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
        } else {  // Use microseconds since page-load if supported
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    },
    async downloadFile(flightsCidersFile) {
      this.downloadButtonLoading = true;
      let fileUrl = apiTyped.getFile(flightsCidersFile.filesId, true);
      const name = this.getTranslation(flightsCidersFile.translations, 'name', this.currentLanguage, flightsCidersFile.name) || flightsCidersFile.name.split('.').slice(0, -1).join('.');
      console.log("GOT NAME", name)
      let fileDownloadName = `${name.replace(/[^a-zA-Z0-9]/g, '-').replace(/-+/g, '-')}`;
      const blob = await fetch(fileUrl).then(res => res.blob());
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileDownloadName + "." + flightsCidersFile.extension;
      document.body.appendChild(link);
      link.click();
      this.downloadButtonLoading = false;
    },
    async downloadBadge() {
      this.downloadButtonLoading = true;
      let badgeUrl = apiTyped.getBadgeImgUrl(this.flightsCider.badge.icon, 1000);
      let badgeDownloadName = `${this.tasting.name.replace(/[^a-zA-Z0-9]/g, '-').replace(/-+/g, '-')}-Badge-${this.flightsCider.badge.stufe}`;
      if (this.flightsCider.bestOfCategory && this.flightsCider.badge.bestOfCategory != null) {
        badgeUrl = apiTyped.getBadgeImgUrl(this.flightsCider.badge.bestOfCategory, 1000);
        badgeDownloadName += "-star";
      }
      const blob = await fetch(badgeUrl).then(res => res.blob());
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = badgeDownloadName + ".png";
      document.body.appendChild(link);
      link.click();
      this.downloadButtonLoading = false;
    },
    async printDocument() {
      this.downloadButtonLoading = true;
      this.loadingPrinting = true;
      const route = this.$router.currentRoute.fullPath;
      const uuid = this.uuidV4();
      if (this.showDocument === 0) {  // Gesamtauswertung
        await apiTyped.uploadPrintData(uuid, {
          cider: this.resultCider,
          badgesData: this.flightsCider.badge,
          bestOfCategory: this.flightsCider.bestOfCategory || false,
          badgesDataArray: this.badgesData,
          tasting: this.tasting,
          __lastRoute__: route,
        });
        const url = `${apiTyped.frontendUrl}producer/print/cider/overview?uuid=${uuid}`;
        // const url = `http://localhost:8080/producer/print/cider/overview?uuid=${uuid}`;
        window.open(url, '_blank');
      } else if(this.showDocument === 1) {
        await apiTyped.uploadPrintData(uuid, {
          resultCider: this.resultCider,
          badge: this.flightsCider.badge,
          tasting: this.tasting,
          __lastRoute__: route,
        });
        const url = `${apiTyped.frontendUrl}producer/print/cider/certificateA4?uuid=${uuid}`;
        // const url = `http://localhost:8080/producer/print/cider/certificateA4?uuid=${uuid}`;
        window.open(url, '_blank');
      }
      this.loadingPrinting = false;
      this.downloadButtonLoading = false;
    },
    async downloadDocument() {
      this.loadingDownload = true;
      if (this.showDocument === 0) {  // Gesamtauswertung
        if (this.link0 != null) {
          this.link0.click();
          this.loadingDownload = false;
          return;
        }
        const response = await apiTyped.getOverviewA4Download(
          {
            cider: this.resultCider,
            badgesData: this.flightsCider.badge,
            bestOfCategory: this.flightsCider.bestOfCategory || false,
            badgesDataArray: this.badgesData,
            tasting: this.tasting
          }
        );
        console.log("GOT RESP", response)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        this.link0 = document.createElement('a');
        this.link0.href = url;
        const filename = `${this.tasting.abbreviation}_Overview_${this.cider.ciderInfos.name}`
        this.link0.setAttribute(
          'download',
          filename.replace(/[^a-z0-9]/gi, '_') + ".pdf");  // sanitize filename
        document.body.appendChild(this.link0);
        this.link0.click();
      } else if(this.showDocument === 1) {
        if (this.link1 != null) {
          this.link1.click();
          this.loadingDownload = false;
          return;
        }
        const response = await apiTyped.getCertificateA4Download(
          {
            resultCider: this.resultCider,
            badge: this.flightsCider.badge,
          }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        this.link1 = document.createElement('a');
        this.link1.href = url;
        const filename = `${this.tasting.abbreviation}_Certificate_${this.cider.ciderInfos.name}`
        this.link1.setAttribute(
          'download',
          filename.replace(/[^a-z0-9]/gi, '_') + ".pdf");
        document.body.appendChild(this.link1);
        this.link1.click();
      }
      this.loadingDownload = false;
    },
    calcPageScale() {
      const docView = this.$refs.documentView;
      if (docView == null) return 1;
      const availableWidth = docView.clientWidth;
      this.pageScale = (availableWidth - 32) * 0.026458 / this.pageMaxSizeCm;
      if (this.pageScale > 1) this.pageScale = 1;
      this.$nextTick(() => {
        if (this.$refs.scaledView != null) {
          this.maxHeight = String(this.$refs.scaledView.clientHeight * this.pageScale + 128) + "px";
        }
      });
    },
    async selectCiderResult(revision) {
      const flightCiders = this.cider.flightsCiders.find(f => f.ciderId === revision.eingereicht.productRevision);
      if (flightCiders == null) {
        console.log("Could not find a flights ciders for revision", revision)
        this.noFlightYet = true;
        return;
      }
      this.noFlightYet = false;
      this.updateFlightsCider += 1;
      this.flightsCider = flightCiders;
      console.log("Select", revision, flightCiders)
      this.$emit('selectCiderRevisionId', revision.eingereicht.productRevision);
      await this.getResultCider(flightCiders);
    },
    async getResultCider(flightsCider) {
      if (this.resultIsPublished(flightsCider) === false) {
        this.resultCider = null;
        this.loadingRevisionId = -1;
        return;
      }
      if (this.cachedResultCiders.has(flightsCider.ciderId)) {
        // load cached result
        console.log("FOUND CACHE")
        this.resultCider = this.cachedResultCiders.get(flightsCider.ciderId);
        this.resultCiderFiles = this.cachedResultCidersFiles.get(flightsCider.ciderId);
        this.tasting = this.cachedResultTasting.get(flightsCider.tasting);
        this.badgesData = this.cachedResultBadges.get(flightsCider.tasting);
        this.loadingRevisionId = -1;
        return;
      }
      this.loadingRevisionId = flightsCider.ciderId;
      console.log("GETTING RESULT CIDER", flightsCider)
      const producerData = this.$store.state.producerStore.producerData;
      const resultCider = (await apiTyped.serviceGetCidersClient([flightsCider.id]))[0];
      const files = await apiTyped.getFlightsCidersFiles(flightsCider.id);
      // this.resultCider.addAllJurorBewertungen(Object.values(this.resultCider.allBewertungen).flat(1), "");
      const badges = await apiTyped.getBadges(null, [flightsCider.tasting]);
      const tasting = (await apiTyped.producerGetTastingsByIds(
        0,1,1,[flightsCider.tasting]))[0];
      resultCider.companyName = producerData.companyName;
      this.badgesData = badges;
      this.tasting = tasting;
      this.tasting = (await apiTyped.producerGetTastingsByIds(0,1,1,[flightsCider.tasting]))[0];
      if (flightsCider.badge != null) {
        const urkunde = await apiTyped.getUrkunden([flightsCider.badge], [Format.A4]);
        flightsCider.badge.urkunde = urkunde[0];
      }
      this.calcPageScale();
      this.cachedResultCiders.set(flightsCider.ciderId, resultCider);
      this.cachedResultCidersFiles.set(flightsCider.ciderId, files);
      this.cachedResultTasting.set(tasting.id, tasting);
      this.cachedResultBadges.set(tasting.id, badges);
      this.resultCider = resultCider;
      this.resultCiderFiles = files;
      this.loadingRevisionId = -1;
    }
  },
}
</script>

<style scoped>
/deep/ .v-slide-group__prev {
  max-width: 24px !important;
  margin-left: -12px;
  flex: 0 1 24px;
  min-width: 24px !important;
}
/deep/ .v-slide-group__next {
  max-width: 24px !important;
  margin-right: -12px;
  flex: 0 1 24px;
  min-width: 24px !important;
}
.link-hover:hover {
  text-decoration: underline;
}
</style>