import {
    BewertungskategorieRated,
    createBewertungskategorieRatedFromBewertungskategorie, createLocaleMessagesForBewertungskategorie
} from "@/types/bewertungskategorieRated";
import {Kategorie, KategorieTranslation} from "@/types/kategorie";
import {MyCollections} from "@/api/types/my-collections";
import {Bewertung, updateBewertungFromDatabase} from "@/types/bewertung";
import VueI18n from "vue-i18n";
import LocaleMessageObject = VueI18n.LocaleMessageObject;
import {createMultiLocaleMessages, mergeMultiLocaleMessages} from "@/locales/createLocaleMessageObject";
import {TranslationMessages} from "@/types/translationMessages";

class KategorieRated {
    id: string;
    displayId: string;
    name: string;
    info: string;
    bewertungskategorien: BewertungskategorieRated[];
    translations: KategorieTranslation[] | undefined;
    translationMessages: TranslationMessages | null;

    constructor() {
        this.id = "";
        this.displayId = "";
        this.name = "";
        this.info = "";
        this.bewertungskategorien = [];
        this.translations = undefined;
        this.translationMessages = null;
    }
}

export function createKategorieRatedFromKategorie(kategorie: Kategorie): KategorieRated {
    const kategorieRated = new KategorieRated();
    kategorieRated.id = String(kategorie.id);
    kategorieRated.displayId = String(kategorie.displayId);
    kategorieRated.name = kategorie.name;
    kategorieRated.info = kategorie.info;
    kategorieRated.bewertungskategorien = kategorie.bewertungskategorien.map((bewertungskategorie) =>
        createBewertungskategorieRatedFromBewertungskategorie(bewertungskategorie));
    kategorieRated.translations = kategorie.translations;
    kategorieRated.translationMessages = kategorie.translationMessages;
    return kategorieRated;
}

export function addBewertungenToKategorie(kategorie: KategorieRated, bewertungen: MyCollections["bewertungen"][]
        ): void {
    for (const newBewertung of bewertungen) {
        kategorie.bewertungskategorien.forEach(bewertungskategorie => {
            for (let bewertung of bewertungskategorie.bewertungen) {
                if (bewertung.eigenschaftId === newBewertung.eigenschaft_id) {
                    bewertung = updateBewertungFromDatabase(bewertung, newBewertung);
                }
            }
        });
    }
    // return kategorie;
}

export function getBewertungFromKategorieRated(kategorie: KategorieRated, eigenschaftId: number): Bewertung | null {
    for (const bewertungskategorie of kategorie.bewertungskategorien) {
        const bewertung = bewertungskategorie.bewertungen.find(bewertung => bewertung.eigenschaftId === eigenschaftId);
        if (bewertung !== undefined) return bewertung;
    }
    return null;
}

export function createLocaleMessagesForKategorie(kategorie: KategorieRated | Kategorie): LocaleMessageObject[] {
    const translations = [];
    const kategorieTranslations = createMultiLocaleMessages(
        kategorie.translations, getKategorieTranslationPrefix(kategorie));
    translations.push(...kategorieTranslations);
    const bewertungskategorieTranslations = kategorie.bewertungskategorien.flatMap(bewertungskategorie =>
        createLocaleMessagesForBewertungskategorie(bewertungskategorie));
    translations.push(...bewertungskategorieTranslations)
    const messageObjects = mergeMultiLocaleMessages(translations);
    console.log("Created Translations:", messageObjects);
    return messageObjects;
}

export function getKategorieTranslationPrefix(kategorie: KategorieRated | Kategorie): string {
    return "kategorie_" + String(kategorie.id) + "_";
}

export { KategorieRated };