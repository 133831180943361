import {requiresLogin} from "@/api/apiBase";
import {createTastingFromDatabase, Tasting} from "@/types/tasting";
import {ProducerApiMixin} from "@/api/producerApiMixin";
import JurorCider from "@/types/jurorCider";
import {apiTyped} from "@/api/apiTyped";
import {MyCollections} from "@/api/types/my-collections";
import {Kategorie} from "@/types/kategorie";
import {KategorieRated} from "@/types/kategorieRated";


class ServiceApiMixin extends ProducerApiMixin {

    @requiresLogin
    async serviceGetAllTastings(): Promise<Tasting[] | null> {
        const tastings = await this.sdk.items('tastings').readByQuery({
            fields: '*',
            sort: ['-datum'],
        });
        if (tastings == null || tastings.data == null) return null
        return tastings.data.map(t => createTastingFromDatabase(t));
    }

    @requiresLogin
    async serviceGetCiders(
        ciderFlightIds: number[],
        withChairNotes = true,
        withJurorNotes = true,
        withPoints = true,
        withIndividualPoints = true
    ): Promise<JurorCider[] | undefined> {
        const path = "ciders/cidersForFlights"
        const data = { flightsCidersIds: ciderFlightIds }
        const resp = await this.sdk.transport.post(
            path, data, { params: {withChairNotes, withJurorNotes, withPoints, withIndividualPoints} });
        return resp.raw as JurorCider[];
    }

    @requiresLogin
    async serviceGetCidersClient(
        flightsCidersIds: number[],
        categories: Kategorie[] | null = null,
        limit = 20
    ): Promise<JurorCider[] | null> {
        const flightsCiders = await apiTyped.getFlightsCiders(
            flightsCidersIds, true, true, limit);
        // fetch categories
        if (categories == null) {
            const categoryIds = flightsCiders.map(f => (f.ciders_id as MyCollections["ciders"]).kategorie) as number[];
            categories = await apiTyped.getAllKategorien(-1, categoryIds);
        }
        // fetch missing kategorien for whatever reason they are not present in current loaded tasting

        const missingCategoryIds = flightsCiders.filter(
            // @ts-ignore
            f => categories?.find(k => Number(k.id) === Number(f.ciders_id.kategorie)) == null).map(
            // @ts-ignore
                f => Number(f?.ciders_id?.kategorie as number));
        if (missingCategoryIds.length > 0) {
            console.log("GOT MISSING CAT", missingCategoryIds);
            const additionalCategories = await apiTyped.getAllKategorien(-1, missingCategoryIds);
            categories.push(...additionalCategories);
        }

        console.log("Up to here", flightsCiders, categories);
        const ciders = flightsCiders.map(
            (f, index) => JurorCider.fromApiData(
                f.ciders_id as MyCollections["ciders"],
                categories?.find(c => Number(c.id) === Number((f.ciders_id as MyCollections["ciders"]).kategorie)) as Kategorie,
                f,
                "",
                false,
                []
            )
        );
        if (ciders == null) return null;
        return ciders;
    }
}

export {ServiceApiMixin}