
import {Country} from "@/types/country";
import {MyCollections} from "@/api/types/my-collections";

export interface ProducerAssets {
    id: number;
    logo: string | null;
    banner: string | null;
    gallery: string[] | null;
}

export interface ProducerData {
    id: number;
    firstLogin: boolean;
    companyName: string;
    legalForm: string;
    contactPerson: string;
    street: string;
    streetNumber: string;
    province: string;
    city: string;
    postalCode: string;
    country: Country | null;
    mobilePhone: string;
    email: string;
    website: string;
    vat: string;
    companyDescription: string;
    agreeDataPublish: boolean;
    assets: ProducerAssets | null;
}

function createProducerAssetsFromDb(producerAssets: MyCollections["produzenten_assets"]) {
    console.log("GOT producerAssets", producerAssets)
    if (producerAssets == null) return null;
    return <ProducerAssets>{
        id: producerAssets.id,
        logo: producerAssets.logo,
        banner: producerAssets.banner,
        // gallery: producerAssets.gallery,
    }
}

export function createProducerDataFromDb(
    producerData: MyCollections["produzenten_data"], assets: MyCollections["produzenten_assets"] | null
) {
    return <ProducerData>{
        id: producerData.id || -1,
        firstLogin: producerData.first_login != null ? producerData.first_login : true,
        companyName: producerData.company_name || "",
        legalForm: producerData.legal_form || "",
        contactPerson: producerData.contact_person || "",
        street: producerData.street || "",
        streetNumber: producerData.street_number || "",
        city: producerData.city || "",
        postalCode: producerData.postal_code || "",
        province: producerData.province_state || "",
        country: producerData.country || null,
        mobilePhone: producerData.mobile_phone || "",
        email: producerData.email || "",
        website: producerData.website || "",
        vat: producerData.vat || "",
        companyDescription: producerData.company_description || "",
        agreeDataPublish: producerData.agree_data_publish || false,
        assets: assets != null && typeof assets !== "number"
            ? createProducerAssetsFromDb(assets as MyCollections["produzenten_assets"])
            : null,
    }
}