<template>
  <v-menu
    offset-y
  >
    <template #activator="{ on, attrs }">
      <v-btn
        large
        icon
        :color="$vuetify.theme.dark ? 'tertiary' : 'tertiary'"
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-scroll-x-transition>
          <v-icon
            class="animate_change"
            size="32"
          >
            {{ getThemeIcon(currentTheme) }}
          </v-icon>
        </v-scroll-x-transition>
      </v-btn>
    </template>
    <v-list>
      <div
        class="text-h6 pl-4 pr-4"
        style="font-size: 14pt !important;"
      >
        {{ $i18n.t('setTheme') }}
      </div>
      <v-list-item
        v-for="(item, index) in themes"
        :key="index"
        class="d-flex justify-center"
      >
        <v-btn
          rounded
          :color="currentTheme === item.value ? 'primary' : ''"
          style="text-transform: none !important;"
          elevation="0"
          @click="setTheme(item.value)"
        >
          <v-icon
            class="pr-1"
            :color="$vuetify.theme.dark || currentTheme === item.value ? 'white' : 'tertiary'"
          >
            {{ getThemeIcon(item.value) }}
          </v-icon>
          {{ $i18n.t(item.value) }}
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {apiTyped} from "@/api";
import {Theme} from "@/types/user";
import store from "@/stores";
import {commit} from "vuex-pathify";
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex)

export default {
  // TODO Translation of theme strings
  name: "ThemeMenu",
  data: () => ({
    // theme: 'auto',
    themes: [{
      name: 'Light',
      value: 'light'
    }, {
      name: 'Dark',
      value: 'dark'
    }, {
      name: 'Auto',
      value: 'auto'
    }]
  }),
  i18n: {
    messages: {
      de: {
        "setTheme": "Erscheinungsbild",
      },
      en: {
        "setTheme": "Set Theme",
      }
    }
  },
  watch: {
    currentTheme: function (newTheme) {
      this.theme = newTheme;
    }
  },
  computed: {
    currentTheme() {
      return this.$store.getters["usersStore/getUserTheme"]();
    },
  },
  created() {
    this.$store.state.changedTheme = false;
    this.$store.state.newTheme = '';
  },
  methods: {
    getThemeIcon (theme) {
      switch (theme) {
        case Theme.Light:
          return 'mdi-white-balance-sunny';
        case Theme.Dark:
          return 'mdi-weather-night';
        case Theme.Auto:
          return 'mdi-theme-light-dark';
        default:
          return 'mdi-theme-light-dark';
      }
    },
    async setTheme(theme) {
      this.theme = theme;
      this.$store.state.changedTheme = true;
      this.$store.state.newTheme = theme;
      let isDark = this.theme === Theme.Dark
      if (theme === Theme.Auto) {
        isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      }
      this.$vuetify.theme.dark = isDark;
      // console.log("Updating theme", theme, isDark)
      await this.$store.dispatch('usersStore/setTheme', theme);
      // store.commit('usersStore/SET_THEME', theme)
      store.commit('usersStore/theme', theme)
    },
  },
  mounted() {
    if (this.$store.getters["usersStore/user"]() == null && this.$store.state.usersStore.theme == null) {
      const darkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      this.$vuetify.theme.dark = darkTheme;
      this.$store.state.usersStore.theme = darkTheme ? Theme.Dark : Theme.Light;
    }
    this.theme = this.currentTheme;
  }
}
</script>

<style scoped>
.animate_change {
  transition: all 1.5s ease;
}
</style>