<template>
  <v-chip
    label
    :ripple="false"
    style="border-radius: 10px !important;"
    :style="{'min-width': maxWidth + 'px', 'max-width': maxWidth + 'px', 'margin': '0 auto',
             'border-style': pointsColor === null ? 'dashed' : 'solid',
             'stroke-dasharray': '25 40'}"
    class="pl-1 pr-1 d-flex justify-center align-center animate_change"
    :outlined="pointsColor === null"
    :color="pointsColor !== null ? pointsColor : '#aaa'"
    dark
    :small="isSmall"
    :text-color="'#' + textColor"
    @click="$emit('click')"
  >
    <div class="d-flex align-center justify-center">
      <v-icon
        v-if="pointsRounded > 0"
        style="transform: translateY(-0.55px);"
        small
      >
        mdi-star
      </v-icon>
      <span>{{ pointsRounded !== 0 ? pointsRounded : '--' }}</span>
    </div>
  </v-chip>
</template>

<script>
import util from "@/util";

export default {
name: "ChipPoints",
  props: {
    points: {type: Number, required: true, default: 0},
    maxWidth: {type: Number, required: false, default: 70},
    darkBackground: {type: Boolean, required: false, default: null},
    isSmall: {type: Boolean, required: false, default: false},
    roundTo: {type: Number, required: false, default: 0.1},
    badgeData: {type: Object, required: false, default: null}
  },
  computed: {
    textColor() {
      if (this.badgeData != null) {
        return this.$store.getters['badgesStore/getTextColorFromBadge'](
          this.badgeData, this.darkBackground || this.$vuetify.theme.dark)
      } else {
        return this.$store.getters['badgesStore/getTextColor'](
          this.pointsRounded, this.darkBackground || this.$vuetify.theme.dark)
      }
    },
    pointsColor() {
      if (this.badgeData != null) return this.badgeData.color;
      return this.$store.getters['badgesStore/getColor'](this.pointsRounded);
    },
    pointsRounded() {
      return util.util.round_to_decimal(this.points, this.roundTo)
    }
  }
}
</script>

<style scoped>
.v-chip:before {
  background-color: unset !important;
}
</style>