<template>
  <v-container
    ref="islandContainer"
    style="position: relative;"
  >
    <v-row>
      <v-col
        v-for="(island, index) in islands"
        :key="island.componentName + '_' + index"
        class="pt-0 pb-0"
        cols="12"
        :xs="island.collapsedWidth.xs"
        :sm="island.collapsedWidth.sm"
        :md="island.collapsedWidth.md"
        :lg="island.collapsedWidth.lg"
        :xl="island.collapsedWidth.xl"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-card
            :ref="island.componentName + '_' + index"
            dark
            class="open-island-transition exclude-click-outside"
            :color="hover ? 'filled_card darken-1' : 'filled_card'"
            rounded="xl"
            elevation="0"
            @click="expandIsland(island.componentName + '_' + index)"
          >
            {{ $vuetify.theme.themes.dark.filled_card }}
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-card
      ref="allIslands"
      v-click-outside="{
        handler: closeIslands,
        include: closeIslandsInclude
      }"
      class="all-islands"
      :style="{width: pressedIslandWidth + 'px',
               height: pressedIslandHeight + 'px',
               left: pressedIslandLeft + 'px',
               top: pressedIslandTop + 'px'}"
      :class="{'expanded-islands': isExpanded, 'open-island-transition': isExpanded}"
    />
  </v-container>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
  name: "DynamicIslandsContainer",
  watch: {
    islands: function (old, newIslands) {
      console.log("newIslands", newIslands);
    },
    isExpanded: function (newValue) {
      window.setTimeout(() => { this.delayedIsExpanded = newValue }, 1000);
    }
  },
  data: () => ({
    delayedIsExpanded: false,
    pressedIslandWidth: 0,
    pressedIslandHeight: 0,
    pressedIslandLeft: 0,
    pressedIslandTop: 0,
  }),
  computed: {
    isExpanded: sync("dynamicIslandsStore/isExpanded"),
    islands: sync("dynamicIslandsStore/islands"),
  },
  methods: {
    closeIslands() {
      this.isExpanded = false;
    },
    closeIslandsInclude() {
      return [...document.querySelectorAll('.exclude-click-outside')];
    },
    expandIsland(ref) {
      const island = this.$refs[ref][0];
      if (island.$el != null) {
        const islandContainer = this.$refs.islandContainer;
        this.pressedIslandWidth = island.$el.clientWidth;
        this.pressedIslandHeight = island.$el.clientHeight;
        const rect = island.$el.getBoundingClientRect();
        const islandContainerRect = islandContainer.getBoundingClientRect();
        this.pressedIslandLeft = rect.left - islandContainerRect.left;
        this.pressedIslandTop = rect.top - islandContainerRect.top;
      }
      window.setTimeout(() => this.isExpanded = true, 10);
    }
  }
}
</script>

<style scoped>
.open-island-transition {
  transition: all 0.25s cubic-bezier(0.25, 0.1, 0.25, 1.0);
}

.all-islands {
  position: absolute;
  visibility: hidden;
}
.expanded-islands {
  visibility: visible !important;
  transform: translateX(-50%);
  height: 50vh !important;
  width: 66vw !important;
  top: 4px !important;
  left: 50% !important;
  z-index: 99;
}
</style>