<!--uses locales-->
<template>
  <v-container
    fluid
    class="d-flex flex-column align-start ma-0"
  >
    <PageNavigation
      :title-translation="$t('Login.accountInformation')"
      :breadcrumbs="[{text: $t('home'), to: '/producer/home'}, {text: $t('Login.accountInformation'), disabled: true}]"
      class="pl-md-8 pr-md-8 pl-2 pr-2"
    />

    <div
      v-if="loaded"
      style="max-width: min(1200px, 100%);"
      class="d-flex flex-column ml-auto mr-auto"
    >
      <div
        style="max-height: 460px; min-height: 460px; width: 100%; position: relative;"
        class="pl-md-8 pr-md-8 ml-auto mr-auto"
      >
        <v-hover v-slot="{ hover }">
          <div>
            <v-img
              v-if="imgBannerUrl != null"
              :src="imgBannerUrl"
              :contain="false"
              height="400"
              class="rounded-xl"
            >
              <div
                v-show="hover"
                style="position: absolute; top: 8px; right: 12px;"
              >
                <v-btn
                  icon
                  top
                  right
                  @click="imgBannerUrl = null; producerData.assets.banner = null"
                >
                  <v-icon x-large>
                    mdi-close-circle
                  </v-icon>
                </v-btn>
              </div>
              <!--              <div-->
              <!--                v-show="hover"-->
              <!--                style="position: absolute; top: 8px; right: 58px;"-->
              <!--              >-->
              <!--                <v-btn-->
              <!--                  icon-->
              <!--                  elevation="4"-->
              <!--                  top-->
              <!--                  right-->
              <!--                >-->
              <!--                  <v-icon x-large>-->
              <!--                    mdi-pencil-circle-outline-->
              <!--                  </v-icon>-->
              <!--                </v-btn>-->
              <!--              </div>-->
            </v-img>
          </div>
        </v-hover>
        <v-card
          v-if="imgBannerUrl == null"
          outlined
          ripple
          style="width: 100%; max-height: 400px; min-height: 400px; border-style: dashed; border-width: medium; cursor: pointer;"
          @click="$refs.inputUploadBanner.click()"
        >
          <v-card-text class="text-h4 text--secondary">
            {{ $t('clickToUploadBanner') }}
          </v-card-text>
          <v-card-text
            v-if="isSmallerScreen"
            class="text--secondary ml-auto mr-auto"
            style="max-width: 400px;"
          >
            {{ $t('agreementBannerInfo') }}
          </v-card-text>
          <v-btn
            icon
            rounded
            style="margin-top: 32px; padding: 72px; border-radius: 999px;"
            @click="$refs.inputUploadBanner.click()"
            @click.native.stop=""
          >
            <v-icon size="72">
              mdi-cloud-upload
            </v-icon>
            <label for="inputUploadBanner">
              <input
                id="inputUploadBanner"
                ref="inputUploadBanner"
                type="file"
                :multiple="false"
                accept="image/png, image/jpeg"
                style="display: none;"
                @change="requestUploadBanner"
              >
            </label>
          </v-btn>
          <v-card-text
            v-if="!isSmallerScreen"
            class="text--secondary ml-auto mr-auto"
            style="max-width: 400px;"
          >
            {{ $t('agreementBannerInfo') }}
          </v-card-text>
        </v-card>
        <div
          style="position: relative;"
        >
          <div
            :class="{'logo-small-screen': isSmallScreen, 'logo-bigger-screen': !isSmallScreen}"
          >
            <v-hover v-slot="{ hover }">
              <div>
                <v-img
                  v-if="imgLogoUrl != null"
                  :src="imgLogoUrl"
                  width="200"
                  height="200"
                  contain
                  class="rounded-xl"
                >
                  <div
                    v-show="hover"
                    style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                  >
                    <v-btn
                      icon
                      top
                      right
                      @click="imgLogoUrl = null; producerData.assets.logo = null"
                    >
                      <v-icon x-large>
                        mdi-close-circle
                      </v-icon>
                    </v-btn>
                  </div>
                </v-img>
              </div>
            </v-hover>

            <v-card
              v-if="imgLogoUrl == null"
              elevation="3"
              width="200"
              height="200"
            >
              <v-btn
                icon
                rounded
                class="pa-7"
                style="margin-top: 54px;"
                @click="$refs.inputUploadLogo.click()"
              >
                <v-icon x-large>
                  mdi-cloud-upload
                </v-icon>
                <label for="inputUploadLogo">
                  <input
                    id="inputUploadLogo"
                    ref="inputUploadLogo"
                    type="file"
                    :multiple="false"
                    accept="image/png, image/jpeg"
                    style="display: none;"
                    @change="requestUploadLogo"
                  >
                </label>
              </v-btn>
              <v-card-text
                class="text--secondary"
              >
                {{ $t('clickToUploadLogo') }}
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>

      <div
        class="d-flex flex-column ml-auto mr-auto mt-4 pl-4 pr-4 pb-8"
        style="max-width: 100%;"
      >
        <v-row
          class="info-label"
        >
          <v-col class="pa-0 pb-4">
            <v-card-title class="pa-0 pl-4 text--secondary">
              <v-icon class="mr-2">
                mdi-text-account
              </v-icon>
              <span class="info-label-text">
                {{ $t('accountHandling.companyDescription') }}
              </span>
            </v-card-title>
          </v-col>
        </v-row>
        <div class="">
          <v-card
            outlined
            style="max-width: 100%; width: 972px"
          >
            <Producer_profile_editor
              v-model="companyDescription"
              :readonly="false"
              :placeholder="$t('enterShortDescriptionCompany')"
            />
          </v-card>
        </div>
        <v-checkbox
          v-model="agreeDataPublish"
          :rules="value => !!value || 'Required.'"
          :label="$t('agreeToPublishData',
                     {info: $t('accountHandling.companyInformation'),
                      address: $t('accountHandling.legalAdress'),
                      contact: $t('accountHandling.contactInformation')})"
          style="max-width: 976px;"
        />
      </div>
    </div>

    <div
      v-if="loaded"
      class="d-flex align-start ml-auto mr-auto"
    >
      <ProducerEditInformationForm
        ref="editInformationForm"
        :producer-data="producerData"
        :countries="countries"
        :hide-delete-account="false"
        :parent-still-loading="updateDataLoading"
        hide-success
        @changeCounter="changeCounter"
        @submit="uploadProducerDataRest"
      />
    </div>
    <v-progress-circular
      v-else
      indeterminate
      class="mt-auto mb-auto ml-auto mr-auto"
      size="92"
    />
    <Confirm_dialog ref="confirm" />
  </v-container>
</template>

<script>
import ProducerEditInformationForm from "@/components/producer/producer_edit_information_form";
import {sync} from "vuex-pathify";
import Confirm_dialog from "@/components/confirm_dialog";
import {apiTyped} from "@/api";
import logos_mixin from "@/components/mixins/logos_mixin";
import Producer_profile_editor from "@/components/producer/producer_profile_editor";
import PageNavigation from "@/components/page_navigation";

export default {
  name: "ProducerEditProducerDataPage",
  components: {PageNavigation, Producer_profile_editor, Confirm_dialog, ProducerEditInformationForm},
  mixins: [logos_mixin],
  async beforeRouteLeave(to, from, next) {
    if (this.formChangeCounter > 0) {
      const okayToLeave = await this.$refs.confirm.open(
        this.$t('unsavedChanges'), this.$t('unsavedChangesMessageProducer'),
        {color: 'secondary_card', thirdButton: true, thirdButtonText: this.$t('discard')})
      if (okayToLeave === "third") next();
    } else {
      next();
    }
  },
  i18n: {
    messages: {
      de: {
        'agreeToPublishData': 'Ich stimme der Veröffentlichung der hochgeladenen Bilder sowie der Unternehmensbeschreibung auf den Website(s) der Tastings zu. Genaue Infos zu den Websites können im jeweiligen Tasting gefunden werden. Die Zustimmung kann jederzeit durch Abwählen dieser Auswahl widerrufen werden. {info}, {address}, {contact} sowie alle weiteren persönlichen Daten werden nicht veröffentlicht.',
        'enterShortDescriptionCompany': 'Geben Sie eine kurze Beschreibung des Unternehmens, der Produkte, etc. an',
        'clickToUploadBanner': 'Hier Klicken, um ein Profil-Banner hochzuladen',
        'clickToUploadLogo': 'Klicken Sie hier, um ein Firmenlogo oder Bild hochzuladen',
        'agreementBannerInfo': 'Mit Ihrer Zustimmung können diese Bilder verwendet werden, um Ihr Unternehmen auf der Tasting-Website besser darzustellen',
      },
      en: {
        'agreeToPublishData': 'I agree to the publication of the uploaded images and the company description on the website(s) of the tastings. Exact information about the websites can be found in the respective tasting. Consent can be revoked at any time by deselecting this selection. {info}, {address}, as well as {contact} and all other personal information will not be published.',
        'enterShortDescriptionCompany': 'Provide a brief description of the company, products, etc',
        'clickToUploadBanner': 'Click to upload a Profile Banner here',
        'clickToUploadLogo': 'Click to upload a company logo or image here',
        'agreementBannerInfo': 'With your agreement this images can be used to better represent your company on the tasting website',
      }
    }
  },
  data: () => ({
    loaded: false,
    updateDataLoading: false,
    imgBannerFile: null,
    imgLogoFile: null,
    imgBannerUrl: "",
    imgLogoUrl: "",
    formChangeCounter: 0,
    countries: [],
  }),
  computed: {
    producerData: sync('producerStore/producerData'),
    companyDescription: sync('producerStore/producerData@companyDescription'),
    agreeDataPublish: sync('producerStore/producerData@agreeDataPublish'),
    isSmallScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        default: return false
      }
    },
    isSmallerScreen () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        default: return false
      }
    },
  },
  async created() {
    await this.$store.dispatch('producerStore/getProducerData');
    this.countries = await apiTyped.getAllCountries() || [];
    this.imgBannerUrl = this.getImageUrl(this.producerData.assets.banner, 2000) || null;
    this.imgLogoUrl = this.getImageUrl(this.producerData.assets.logo, 1000) || null;
    this.loaded = true;
  },
  methods: {
    requestUploadBanner() {
      const src = this.$refs.inputUploadBanner;
      this.imgBannerFile = src.files[0];
      this.imgBannerUrl = URL.createObjectURL(this.imgBannerFile);
    },
    requestUploadLogo() {
      const src = this.$refs.inputUploadLogo;
      this.imgLogoFile = src.files[0];
      this.imgLogoUrl = URL.createObjectURL(this.imgLogoFile);
    },
    async uploadProducerDataRest(wasSuccess) {
      if (!this.agreeDataPublish) return;
      if (!wasSuccess) return;
      this.updateDataLoading = true;
      let newImageIdLogo = this.producerData.assets.logo;
      let newImageIdBanner = this.producerData.assets.banner;
      if (this.imgLogoFile != null) {
        console.log("Uplaodeing imgLogoFile", this.imgLogoFile)
        const resp = await apiTyped.producerUploadCiderImages(
          [this.imgLogoFile], this.$store.state.producerStore.ciderImagesFolderId);
        this.imgLogoFile = null;
        newImageIdLogo = resp[0];
        this.imgLogoUrl = this.getImageUrl(newImageIdLogo, 1000)
      }
      if (this.imgBannerFile != null) {
        console.log("Uplaodeing imgBannerFile", this.imgLogoFile, this.imgBannerFile)
        const resp = await apiTyped.producerUploadCiderImages(
          [this.imgBannerFile], this.$store.state.producerStore.ciderImagesFolderId);
        this.imgBannerFile = null;
        newImageIdBanner = resp[0];
        this.imgBannerUrl = this.getImageUrl(newImageIdBanner, 2000) || null;
      }
      // await new Promise(resolve => setTimeout(resolve, 5000));
      console.log("Uplaodeing", newImageIdBanner, newImageIdLogo)
      await apiTyped.producerUpdateCompanyDescription(
        this.producerData, this.agreeDataPublish, this.companyDescription, newImageIdBanner, newImageIdLogo);
      this.updateDataLoading = false;
      this.$refs.editInformationForm.showSuccessSnackbar = true;
    },
    changeCounter(val) {
      this.formChangeCounter = val;
    }
  }
}
</script>

<style scoped>
.logo-small-screen {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: -150px;
}
.logo-bigger-screen {
  position: absolute;
  left: 64px;
  top: -150px;
}
.info-label {
  padding-bottom: 16px;
}
.info-label-text {
  text-decoration: underline var(--v-divider-base);
  text-underline-offset: 8px;
}
</style>