<template>
  <div v-if="cider !== null && bewertungskategorie !== null">
    <div class="parent_grid mt-2">
      <div class="merkmal d-flex justify-end pr-4">
        {{ bewertungskategorie.pruefmerkmal }}
      </div>
      <template
        v-for="(bewertung, index) in bewertungenOrEigenschaften"
      >
        <div
          :key="bewertung.id"
          class="one_col"
        >
          <div
            id="container"
            class="d-flex flex-scroll justify-start header_left eigenschaft_gap pr-3"
            :class="{eigenschaft: index < bewertungenOrEigenschaften.length - 1}"
          >
            <span
              class="d-flex justify-start"
              style="white-space: nowrap;"
            >
              {{ bewertung.bezeichnung || "--" }}
            </span>
            <div
              :key="bewertung.id + '_rating'"
              class="d-flex mb-0 flex-scroll justify-end flex-row align-end"
              style="margin-left: auto;"
            >
              <template v-if="showIndividual">
                <v-card
                  v-for="userId in Object.keys(cider.allBewertungen || {})"
                  :key="userId"
                  class="pa-0 pr-1 pl-1 d-flex justify-center animate_change"
                  tile
                  flat
                  style="min-width: 58px; justify-self: flex-end; background-color: white !important;"
                  :style="{'border-right': 'solid ' + $vuetify.theme.currentTheme.divider_light + ' 1px'}"
                >
                  <v-chip
                    color="grey"
                    outlined
                    label
                    small
                    style="min-width: 32px"
                    class="pa-1 mb-1 justify-center"
                  >
                    <v-icon
                      small
                      class="mr-1"
                    >
                      mdi-star
                    </v-icon>
                    {{ $store.getters["ciderStore/getBewertungForJuror"](bewertung.eigenschaftId, userId, cider) == null
                      || $store.getters["ciderStore/getBewertungForJuror"](bewertung.eigenschaftId, userId, cider).punkte == null
                      || isNaN($store.getters["ciderStore/getBewertungForJuror"](bewertung.eigenschaftId, userId, cider).punkte) ? "--" :
                        $store.getters["ciderStore/getBewertungForJuror"](bewertung.eigenschaftId, userId, cider).punkte }}
                  </v-chip>
                </v-card>
              </template>
            </div>
          </div>
        </div>
        <div
          :key="bewertung.id"
          class="one_col eigenschaft_gap"
          :class="{eigenschaft: index < bewertungenOrEigenschaften.length - 1}"
        >
          {{ isNaN(getPointsForBewertung(bewertung, cider)) ? '--' : getPointsForBewertung(bewertung, cider) }}
        </div>
        <div
          :key="bewertung.id"
          class="one_col eigenschaft_gap"
          :class="{eigenschaft: index < bewertungenOrEigenschaften.length - 1}"
        >
          {{ bewertung.faktor * 5 }}
        </div>
      </template>
    </div>
    <v-divider
      v-if="!isLast"
      inset
      style="border-width: 1px !important; border-color: var(--v-divider_light-base);
               background-color: var(--v-divider_light-base)"
    />
    <v-divider v-if="isLast" style="border-color: var(--v-divider_light-base) !important" />
  </div>
</template>

<script>
import util from "@/util";

export default {
  name: "GesamtauswertungTableKategorie",
  props: {
    cider: {type: Object, required: true, default: () => null},
    bewertungskategorie: {type: Object, required: true, default: () => null},
    isLast: {type: Boolean, required: false, default: false},
    showIndividual: {type: Boolean, required: true, default: true},
  },
  computed: {
    bewertungenOrEigenschaften() {
      if (this.bewertungskategorie.hasOwnProperty("bewertungen")) return this.bewertungskategorie.bewertungen;
      if (this.bewertungskategorie.hasOwnProperty("eigenschaften")) return this.bewertungskategorie.eigenschaften;
      return [];
    },
  },
  methods: {
    getPointsForBewertung(bewertung, cider, decPlaces = 0.1) {
      const points = this.$store.getters["ciderStore/getBewertungForJuroren"](
        bewertung.eigenschaftId, cider, 0.1, true) * bewertung.faktor;
      return util.util.round_to_decimal(points, decPlaces)
    }
  }
}
</script>

<style scoped>
.merkmal {
  grid-row: 1 / 5;
}
.eigenschaft_gap {
  /*margin-top: 4px;*/
  margin-bottom: 8px;
}
.eigenschaft {
  border-bottom: 1px solid var(--v-divider_light-base);
}
.flex-scroll {
  /*padding: 4px 4px;*/
  display: flex;
  /*align-items: start;*/
  justify-content: flex-end;
  flex-flow: nowrap;
}

.header_left {
  position: sticky;
  left: 0;
  z-index: 2;
  justify-self: start;
  text-align: start;
}

.flex-scroll {
  /*padding: 4px 4px;*/
  display: flex;
  /*align-items: start;*/
  justify-content: flex-end;
  flex-flow: nowrap;
}

#container {
  display: flex;
  overflow: auto;
  flex-flow: row wrap;
  /*justify-content: end;*/
}
</style>