import {Bewertungskategorie, createBewertungskategorieFromDatabase} from "@/types/bewertungskategorie";
import {components, MyCollections} from "@/api/types/my-collections";
import {createTranslationMessagesFromDb, TranslationMessages} from "@/types/translationMessages";

interface Kategorie {
    id: number;
    displayId: string;
    name: string;
    info: string;
    bewertungskategorien: Bewertungskategorie[];
    translations: KategorieTranslation[] | undefined;
    translationMessages: TranslationMessages | null;
}

interface KategorieTranslation {
    lang: string;
    name: string;
    info: string;
}

export function createKategorieFromDatabase(kategorie: MyCollections["kategorien"]): Kategorie {
    // if (kategorie.bewertungskategorien?.find(bewertungskategorie => typeof bewertungskategorie === "number")) {
    //     return undefined;
    // }
    console.log("Got kateg", kategorie)
    let translations = undefined;
    if (kategorie.translations?.every(t => typeof t !== "number")) {
        const kategorie_translations = kategorie.translations as components["schemas"]["ItemsKategorienTranslations"][];
        translations = kategorie_translations?.map(t => {
            if ((t.languages_code as components["schemas"]["ItemsLanguages"]) !== null)
                return <KategorieTranslation>{
                    lang: (t.languages_code as components["schemas"]["ItemsLanguages"]).code?.substring(0, 2),
                    info: t.info,
                    name: t.name,
                }
        });
    }
    // noinspection UnnecessaryLocalVariableJS
    const newKategorie = <Kategorie>{
        id: kategorie?.id,
        displayId: String(kategorie.display_id) || "",
        name: kategorie?.name,
        info: kategorie.info || "",
        bewertungskategorien: kategorie.bewertungskategorien?.filter(
            b => typeof b !== "number" && typeof b !== "string").map(
            (b) => createBewertungskategorieFromDatabase(
                (b as components["schemas"]["ItemsKategorienBewertungskategorien"])
                    .bewertungskategorien_id as MyCollections["bewertungskategorien"],
                (b as components["schemas"]["ItemsKategorienBewertungskategorien"]).sort
                || (b as components["schemas"]["ItemsKategorienBewertungskategorien"]).id
                || -1)),
        translations: translations,
        translationMessages: createTranslationMessagesFromDb(kategorie.translations as any[]),
    };
    newKategorie.bewertungskategorien.sort((b1, b2) => b1.sort - b2.sort);
    return newKategorie;
}

export { Kategorie, KategorieTranslation };