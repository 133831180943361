<template>
  <v-card
    tile
    flat
    width="100%"
  >
    <v-divider />
    <v-card-text
      :style="{'background-color': $vuetify.theme.currentTheme.background}"
      class="d-flex align-center justify-center pb-1"
    >
      <span>{{ new Date().getFullYear() }}</span>
      <v-icon
        small
        class="mr-0 ml-0"
      >
        mdi-circle-small
      </v-icon>
      <span>{{ $i18n.t('madeWith') }}</span>
      <v-icon
        small
        class="mr-1 ml-1 pb-1"
      >
        mdi-heart
      </v-icon>
      <span>{{ $i18n.t('inHessen') }}</span>
    </v-card-text>
    <footer-impressum-privacy
      :style="{'background-color': $vuetify.theme.currentTheme.background}"
    />
    <div
      style="position: absolute; right: 8px; bottom: 8px;"
      class="text-caption text--secondary"
    >
      v{{ $store.state.appVersion }}
    </div>
  </v-card>
</template>

<script>
import FooterImpressumPrivacy from "@/components/footer_impressum_privacy";
import i18n from '@/i18n'

export default {
  name: "LoginFooterCard",
  components: {FooterImpressumPrivacy},
  i18n: {
    messages: {
      de: {
        "madeWith": "Mit",
        "inHessen": "in Hessen entwickelt"
      },
      en: {
        "madeWith": "Made with",
        "inHessen": "in Hessen"
      }
    }
  },
}
</script>

<style scoped>

</style>