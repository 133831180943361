import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                // name: 'light',
                // primary: '#8d8f1b',
                // primary_darken: '#6e7015',
                // secondary: '#C9BB0D',
                // secondary_grey: '#cbcab1',
                // primary_grey: '#80801c',
                // accent: colors.yellow.accent1,
                // app_bar: "rgba(255,255,255,0.67)",
                // expansion_panel: "rgba(255,255,255,0.9)",
                // light_grey: '#f0ecec',
                // text_std: '#000000',
                // text_std_inv: '#ffffff',
                // text_disabled: '#a0a0a0',
                // divider: '#e0e0e0',
                // divider_light: '#e0e0e0',
                // background: '#ffffff',
                // card_background :'#ffffff',
                // card_background_transparent: "rgba(255,255,255,0.5)",

                // primary: '#606200',
                // primary_darken: '#1c1d00',
                // secondary: '#606042',
                // secondary_grey: '#e6e4c0',
                // background: '#fffcf3',
                // app_bar: "rgba(255,252,243,0.67)",
                // expansion_panel: "rgba(255,255,255,0.9)",
                // light_grey: '#fff9eb',
                // text_std: '#48473b',
                // text_std_inv: '#e5e2d9',
                // text_disabled: '#48473b',
                // text_card: '#48473b',
                // divider: '#e6e3d1',
                // divider_light: '#fffcf3',
                // editor_background: '#fffcf3',
                // card_background: '#fffcf3',
                // surface_variant: '#e6e3d1',
                // card_background_transparent: "rgba(255,252,243,0.5)",
                // secondary_container: '#e6e4c0',

                app_bar: "rgba(255,252,243,0.67)",
                background_blurred: "rgba(255,252,243,0.67)",
                expansion_panel: "rgba(230,227,209,0.9)",
                secondary_card: '#606043',
                finishCiderBackground: '#e6e3d1',
                light_grey: '#fff9eb',
                text_std: '#000000',
                text_card: '#000000',
                logo_green: '#8d8f19',
                background_text: '#1c1c16',
                filled_card: '#e6e3d1',
                text_box_border: '#9e9c97',
                page_background: '#ffffff',

                primary: '#606200',
                primary_text: '#ffffff',
                warning: '#ff8c00',
                on_primary: '#ffffff',
                primary_container: '#e7e971',
                on_primary_container: '#1c1d00',
                primary_darken: '#1c1d00',
                secondary: '#606042',
                on_secondary: '#ffffff',
                secondary_container: '#e6e4c0',
                secondary_grey: '#e6e4c0',
                on_secondary_container: '#1c1d06',
                tertiary: '#3d6657',
                on_tertiary: '#ffffff',
                tertiary_container: '#bfecd9',
                on_tertiary_container: '#002117',
                error: '#ba1b1b',
                on_error: '#ffffff',
                error_container: '#ffdad4',
                on_error_container: '#410001',
                outline: '#797869',
                divider: '#e0ddd5',
                background: '#fffcf3',
                background_darken: '#faf7ee',
                card_background: '#fff9eb',
                dialog_background: '#fffcf3',
                text_std_inv: '#fffcf3',
                on_background: '#1c1c16',
                surface: '#fffcf3',
                on_surface: '#1c1c16',
                surface_variant: '#e6e3d1',
                divider_light: '#e6e3d1',
                on_surface_variant: '#48473b',
                text_disabled: '#48473b',
                inverse_surface: '#31302b',
                inverse_on_surface: '#f4f0e8',
            },
            dark: {
                app_bar: "rgba(39,39,39,0.67)",
                background_blurred: "rgba(28,28,22,0.67)",
                expansion_panel: "rgba(39,39,32,0.9)",
                // expansion_panel: "rgba(30,30,30,0.9)",
                divider: '#383832',
                secondary_card: '#48482d',
                dialog_background: '#48482d',
                finishCiderBackground: '#272720',
                background_text: '#d4d1c8',
                secondary_grey: '#48482d',
                filled_card: '#272720',
                text_box_border: '#52524e',
                page_background: '#1c1c16',

                primary: '#a2a448',  // '#cacc58'
                primary_text: '#313300',
                warning: '#ff9500',
                on_primary: '#313300',
                primary_container: '#484a00',
                on_primary_container: '#e7e971',
                secondary: '#6c6b4d',  // '#c9c8a4'
                on_secondary: '#323219',
                secondary_container: '#48482d',
                on_secondary_container: '#e6e4c0',
                tertiary: '#a3d0bd',
                on_tertiary: '#09372a',
                tertiary_container: '#254e40',
                on_tertiary_container: '#bfecd9',
                error: '#ff6953',  // ffb4a9
                on_error: '#680003',
                error_container: '#930006',
                on_error_container: '#ffdad4',
                outline: '#939182',
                background: '#1c1c16',
                background_darken: '#21211b',
                card_background: '#1c1c16',
                on_background: '#e5e2d9',
                surface: '#1c1c16',
                on_surface: '#e5e2d9',
                surface_variant: '#48473b',
                on_surface_variant: '#c9c7b6',
                inverse_surface: '#e5e2d9',
                inverse_on_surface: '#1c1c16',
                // primary: '#4b4c0e',
                // primary_darken: '#2e2f09',
                // secondary: '#C9BB0D',
                // secondary_grey: '#cbcab1',
                // app_bar: "rgba(53,53,53,0.67)",
                // expansion_panel: "rgba(50,50,50,0.9)",
                // light_grey: '#282424',
                text_std: '#ffffff',
                text_std_inv: '#111111',
                // text_disabled: '#4c4c4c',
                // divider: '#393939',
                divider_light: '#e0e0e0',
                // background: '#121212',
                // card_background :'#1e1e1e',
                // card_background_transparent: "rgba(30,30,30,0.5)",
            },
            materialLight: {
                name: 'materialLight',
                primary: '#606200',
                primary_darken: '#1c1d00',
                secondary: '#606042',
                secondary_grey: '#e6e4c0',
                background: '#fffcf3',
                app_bar: "rgba(255,252,243,0.67)",
                expansion_panel: "rgba(255,252,243,0.9)",
                light_grey: '#3d6657',
                text_std: '#1c1c16',
                text_std_inv: '#e5e2d9',
                text_disabled: '#48473b',
                divider: '#e6e3d1',
                divider_light: '#fffcf3',
                editor_background: '#fffcf3',
                card_background :'#f3f0e8',
                card_background_transparent: "rgba(243,240,232,0.5)",
            }
        },
    },
});
