<template>
  <div
    class="d-flex justify-start align-center flex-shrink-0"
    style="gap: 0 !important;"
  >
    <country-flag
      v-if="item.ciderInfos.land !== null"
      class="elevation-2"
      style="border-radius: 10px !important; min-width: 52px !important; max-width: 52px !important; overflow: visible !important;"
      :country="item.ciderInfos.land.code"
      size="normal"
    />
    {{ item.ciderInfos.land !== null ? item.ciderInfos.land.name || item.ciderInfos.land : '' }}
  </div>
</template>

<script>
import {CProducerCiderResults} from "@/types/producerCider";
import CountryFlag from "vue-country-flag";

export default {
  name: "TableCountryField",
  components: {CountryFlag},
  props: {
    item: {type: CProducerCiderResults, required: true, default: null},
  },
}
</script>

<style scoped>

</style>