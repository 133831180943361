<template>
  <div>
    <ConfirmDialog ref="confirm" />
    <v-card
      class="ma-4"
      min-width="100"
      style="overflow: hidden"
      color="light_grey"
      :outlined="$vuetify.theme.dark"
    >
      <v-card-title class="pt-2 pb-0 align-center">
        <v-row
          align="center"
          class="ma-0"
        >
          <p class="ma-0 text-h5">
            Judges
          </p>

          <v-spacer />

          <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            :label="$t('search')"
            outlined
            clearable
            dense
            rounded
            single-line
            hide-details
            class="ml-auto"
            style="width: 200px; max-width: 400px;"
          >
            <template #append>
              <v-progress-circular
                v-if="loading"
                indeterminate
                class="mt-auto mb-auto ml-auto mr-auto"
                size="24"
                width="2"
              />
            </template>
          </v-text-field>
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="migrateExisting">
                <v-list-item-icon class="mr-2">
                  <v-icon v-text="'mdi-account-switch'" />
                </v-list-item-icon>
                <v-list-item-title>
                  Migrate existing Judges
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </v-card-title>
      <v-row align="center">
        <v-col>
          <judges-filter />
        </v-col>
      </v-row>

      <v-data-table
        v-model="selectedUsers"
        :items="users"
        :headers="userHeaders"
        :loading="loading"
        show-group-by
        multi-sort
        :items-per-page="20"
        :footer-props="{'items-per-page-options': [10, 20, 50, 100, 250, 500]}"
        loading-text="Loading Users... Please wait"
        item-key="id"
        :search="search"
        fixed-header
        height="calc(100vh - 128px - 64px - 24px)"
        show-select
        style="overflow-y: scroll"
        :options.sync="options"
        :server-items-length="totalJudges"
      >
        <template #item.resetPassword="{ item }">
          <v-btn
            color="red"
            small
            elevation="0"
            outlined
            text
            @click="resetPassword(item)"
          >
            Send Mail
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="debugDialog"
      persistent
      max-width="600px"
    >
      <v-card class="px-4">
        <v-card-title class="pl-0">
          <span class="text-h5 text-left">
            {{ debugTitle }}
          </span>
        </v-card-title>
        <v-textarea
          id="debugOutput"
          ref="debugOutput"
          readonly
          label="Output"
          :value="debugText"
        />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="debugDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {apiTyped} from "@/api";
import ConfirmDialog from "@/components/confirm_dialog";
import JudgesFilter from "@/components/admin/judges/judges_filter.vue";

export default {
  name: "AdminUsers",
  components: {JudgesFilter, ConfirmDialog},
  data: () => ({
    users: [],
    selectedUsers: [],
    search: "",
    loading: false,
    searchText: "",
    page: 1,
    options: {},
    totalJudges: 0,
    getJudgesTimeout: null,
    debugDialog: false,
    debugText: "",
    debugTitle: "",
  }),
  computed: {
    userHeaders() {
      return [
        {text: "Nachname", value: 'lastName', align: 'start', sortable: true, groupable: false},
        {text: "Vorname", value: 'firstName', align: 'start', sortable: true, groupable: false},
        {text: "E-Mail", value: 'email', align: 'start', sortable: true, groupable: false},
        {text: "Sprache", value: 'language', align: 'start', sortable: true, groupable: true},
        {text: "Passwort zurücksetzen", value: 'resetPassword', align: 'end', sortable: false, groupable: false},
      ]
    }
  },
  watch: {
    options: {
      handler () {
        this.getJudgesDebounced();
      },
      deep: true,
    },
    searchText: function (newValue) {
      if (newValue !== "") {
        this.getJudgesDebounced();
      }
    },
  },
  async created() {
    const currentTasting = this.$store.getters["usersStore/currentTasting"]()
    console.log("CURR", currentTasting.id)
    // await this.getJudges();
  },
  methods: {
    getJudgesDebounced() {
      // cancel pending call
      if (this.getJudgesTimeout != null) {
        clearTimeout(this.getJudgesTimeout)
      }
      // delay new call 500ms
      this.getJudgesTimeout = setTimeout(async () => { await this.getJudges() }, 500)
    },
    async getJudges() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      console.log("Got options", this.options);
      const sortByMap = {
        firstName: "judge.first_name",
        lastName: "judge.last_name",
        email: "judge.email",
        language: "judge.language",
      }
      const sortByList = sortBy.length > 0
        ? sortBy.map((s, index) => {
          if (sortDesc[index]) return "-" + sortByMap[s];
          else return sortByMap[s];
        })
        : ['judge.last_name'];
      console.log("sortByList", sortByList)
      // const sortByStr = this.sortAsc ? ["-" + this.sortBy] : [this.sortBy];  // todo
      const searchText = this.searchText !== "" ? this.searchText : null;
      const users = await apiTyped.getJudges(0, page, itemsPerPage + 1, {}, searchText, sortByList);
      if (users.length > itemsPerPage) {
        // const newTotal = itemsPerPage * page + 1;
        this.totalJudges = itemsPerPage * page + 1;
        users.pop();
      } else {
        this.totalJudges = users.length;
      }
      this.users = users;
      this.loading = false;
    },
    async migrateExisting() {
      this.debugTitle = "Migrating Users to Judges Data";
      this.debugText = "";
      this.debugDialog = true;
      // get all users with role judge
      const allJudgeUsers = await apiTyped.sdk.items('directus_users').readByQuery({
        fields: ['id', 'role.name', 'first_name', 'last_name'],
        filter: {
          role: { name: { _eq: "Juror" } }
        },
        limit: -1,
      });
      this.debugText += "\nGot " + allJudgeUsers.data.length + " Users with the Role 'Juror'";
      console.log("Got allJudgeUsers", allJudgeUsers.data.map(d => d.id));
      // get all judges_data entries which have a user id related
      const allJudgesData = await apiTyped.sdk.items('judges_data').readByQuery({
        fields: ['id', 'judge.id'],
        filter: {
          judge: { _in: allJudgeUsers.data.map(d => d.id) }
        },
        limit: -1,
      });
      console.log("Got related judges data", allJudgesData);
      this.debugText += "\nGot " + allJudgesData.data.length + " related judges_data";
      // get all the judges who do not have related judges_data entries
      const judgesWithoutData = allJudgeUsers.data.filter(
        user => allJudgesData.data.find(d => d.judge.id === user.id) === undefined);
      this.debugText += "\nFiltered " + judgesWithoutData.length + " Users without judges_data";
      console.log("judgesWithoutData", judgesWithoutData)
      if (judgesWithoutData.length > 0) {
        this.debugText += "\nCreating judges_data:\n";
        try {
          for (const judgeWithoutData of judgesWithoutData) {
            this.debugText += "\nCreating judges_data for: " + judgeWithoutData.first_name + " " + judgeWithoutData.last_name;
            await apiTyped.sdk.items('judges_data').createOne({
              judge: judgeWithoutData.id,
            });
          }
          // document.getElementById("debugOutput").scrollIntoView({ block: "end" });
          this.debugText += "\n\nCreating judges_data done."
        } catch (e) {
          this.debugText += "\n\nError while creating judges_data:" + e.message;
        }
      }
    },
    async resetPassword(userItem) {
      const modalHeaderText = this.selectedUsers.length === 0
        ? `Reset Password für User ${userItem.firstName} ${userItem.lastName}`
        : `Reset Passwörter für (${this.selectedUsers.length}) User?`;
      const modalContentText = this.selectedUsers.length === 0
        ? `Es wird eine Mail mit einem Link zur Eingabe eines neuen Passwortes an ${userItem.email} gesendet.`
        : `Es wird eine Mail mit einem Link zur Eingabe eines neuen Passwortes gesendet an: ${this.selectedUsers.map(user => user.email + "<br>").join("")}`
      if (await this.$refs.confirm.open(modalHeaderText, modalContentText, { color: 'secondary_card', width: 600 })) {
        const userItemsToReset = []
        if (this.selectedUsers.length === 0) userItemsToReset.push(userItem);
        else userItemsToReset.push(...this.selectedUsers)
        console.log("Resetting passwords for:", userItemsToReset);
        const resetPromises = userItemsToReset.map(userItem => apiTyped.resetPasswort(userItem.email));
        await Promise.all(resetPromises);
      }
    },
  }
}
</script>

<style scoped>
/deep/ .v-data-table-header > tr > th {
  background-color: var(--v-light_grey-base) !important;
}
/deep/ .v-data-table {
  background-color: var(--v-light_grey-base) !important;
}
</style>