<template>
  <v-footer
    padless
    bottom
    inset
  >
    <v-card
      flat
      tile
      :color="backgroundColor"
      width="100%"
      class="lighten-1 text-center"
    >
      <v-divider />
      <!--      <span-->
      <!--        v-if="showBadges"-->
      <!--        style="max-width: 300px;"-->
      <!--        class="text-h6 text-left "-->
      <!--      >-->
      <!--        Bewertungsstufen-->
      <!--      </span>-->
      <FooterBadges
        v-if="showBadges"
        :badges-data="badgesData"
        :show-none-badge="forceShowNoneBadge"
        :badge-shadow="badgeShadow"
        :style="{'background-color': backgroundColor + '!important'}"
        @init="initCounter('badge_component')"
      />
      <v-divider v-if="showBadges" />
      <v-card-text
        :style="{'background-color': backgroundColor + '!important'}"
        class="d-flex align-center justify-center"
      >
        <span v-if="showCurrentYear">{{ new Date().getFullYear() }}</span>
        <v-icon
          v-if="showCurrentYear"
          small
          class="mr-0 ml-0"
        >
          mdi-circle-small
        </v-icon>
        <span class="font-weight-bold">{{ tastingName }}</span>
        <v-icon
          v-if="showDate"
          small
          class="mr-0 ml-0"
        >
          mdi-circle-small
        </v-icon>
        <span v-if="showDate">{{ tastingDate }}</span>
        <v-icon
          small
          class="mr-0 ml-0"
        >
          mdi-circle-small
        </v-icon>
        <v-img
          v-if="showLogo"
          contain
          :src="getLogoUrl('logo_app', 300)"
          alt="CiderAwardLogo"
          class="ml-2"
          max-height="32px"
          max-width="40px"
          @load="initCounter('logo_black')"
        />
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import logos_mixin from "@/components/mixins/logos_mixin";
import {CurrentPage} from "@/stores/app";
import FooterBadges from "@/components/footer_badges";
import {sync} from "vuex-pathify";

export default {
  name: "CiderFooter",
  components: {FooterBadges},
  mixins: [logos_mixin],
  props: {
    badgesDataArray: {type: Array, required: true, default: null},
    tasting: {type: Object, required: true, default: null},
    forceShowBadges: {type: Boolean, required: false, default: null},
    forceShowNoneBadge: {type: Boolean, required: false, default: null},
    showDate: {type: Boolean, required: false, default: false},
    showCurrentYear: {type: Boolean, required: false, default: true},
    badgeShadow: {type: Boolean, required: false, default: true},
    backgroundColor: {type: String, required: false, default: () => 'white'},
    showLogo: {type: Boolean, required: false, default: true},
  },
  data: () => ({
    componentsLoaded: 0,
  }),
  computed:{
    badgesData() {
      return this.badgesDataArray;
    },
    tastingName() {
      return this.tasting?.hasOwnProperty("name") ? this.tasting.name : "";
    },
    tastingDate() {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return this.tasting?.hasOwnProperty("datum")
        ? new Date(this.tasting.datum).toLocaleDateString("de", options)
        : "";
    },
    showBadges() {
      return (this.$store.state.appStore?.currentPage === CurrentPage.ciderTable ||
              this.$store.state.appStore?.currentPage === CurrentPage.rating ||
              this.$store.state.appStore?.currentPage === CurrentPage.summary)
          || this.forceShowBadges;
    },
  },
  methods: {
    initCounter(comp) {
      this.componentsLoaded = this.componentsLoaded + 1;
      // console.log('COMPONENT INIT FOOTER', comp)
      if (this.componentsLoaded >= 2) this.$emit('init');
    }
  },
}
</script>

<style scoped>

</style>