<template>
  <div>
    <v-row
      v-if="submissionFee != null"
      no-gutters
      class="pb-4"
    >
      <v-col
        cols="8"
      >
        <span class="d-flex pl-4 text-caption text-left checkout-item-text">
          {{ $t('registrationFee') }}
        </span>
      </v-col>
      <v-col
        cols="4"
        align-self="end"
        class="ml-auto"
      >
        <div class="d-flex pl-4 text-caption text-left font-weight-bold text-right justify-end checkout-item-text">
          {{ formatCurrency(submissionFee, currentLanguage) }}
        </div>
      </v-col>
    </v-row>

    <div
      v-for="(product, index) in [...products].sort((p1, p2) => p2.defaultPrice.unitPrice - p1.defaultPrice.unitPrice)"
      :key="`${product.id}_${index}`"
    >
      <v-row
        no-gutters
        class="pb-4"
      >
        <v-col
          cols="8"
        >
          <span class="d-flex pl-4 text-caption text-left checkout-item-text">
            {{ product.name }}
          </span>
        </v-col>
        <v-col
          cols="4"
          align-self="end"
          class="ml-auto"
        >
          <div class="d-flex pl-4 text-caption text-left font-weight-bold text-right justify-end checkout-item-text">
            {{ `${formatCurrency(product.defaultPrice.unitPrice, currentLanguage)}` }}
          </div>
        </v-col>
      </v-row>
    </div>

    <div
      v-for="(cider, index) in ciders"
      :key="cider.id"
    >
      <v-row
        no-gutters
        class="pb-4"
      >
        <v-col
          cols="8"
        >
          <span class="d-flex pl-4 text-caption text-left checkout-item-text">
            {{ index + 1 }}. {{ $t('submittedProduct') }}
          </span>
          <span class="d-flex pl-8 text--secondary text-caption text-left checkout-item-text">
            {{ cider.ciderInfos.name }}
          </span>
        </v-col>
        <v-col
          cols="4"
          align-self="end"
          class="ml-auto"
        >
          <div class="d-flex pl-4 text-caption text-left font-weight-bold text-right justify-end checkout-item-text">
            {{ index - priceIncludesCiders < 0 ? '-' : `${formatCurrency(pricePerCider, currentLanguage)}` }}
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider class="pb-4" />
    <v-row
      v-if="!priceIncludingTaxes"
      no-gutters
      class="pb-2"
    >
      <v-col
        cols="6"
      >
        <span class="d-flex pl-4 text-caption text-left checkout-item-text">
          {{ $t('subtotal') }}
        </span>
      </v-col>
      <v-col
        cols="6"
        align-self="end"
        class="ml-auto"
      >
        <div class="d-flex pl-4 text-caption text-left font-weight-bold text-right justify-end checkout-item-text">
          {{ formatCurrency(subTotalPrice, currentLanguage) }}
        </div>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="pb-4"
    >
      <v-col
        cols="9"
      >
        <span class="d-flex pl-4 text-caption text-left checkout-item-text d-flex align-baseline">
          {{ priceIncludingTaxes ? $t('taxesEstimatedAndIncluded') : $t('taxesEstimated') }}
          <v-chip
            v-if="taxExempt !== 'none'"
            small
            class="text-caption text--secondary ml-2"
          >
            {{ taxExemptText }}
          </v-chip>
        </span>
      </v-col>
      <v-col
        cols="3"
        align-self="end"
        class="ml-auto"
      >
        <div class="d-flex pl-4 text-caption text-left font-weight-bold text-right justify-end checkout-item-text">
          {{ formatCurrency(taxesAmount, currentLanguage) }}
        </div>
      </v-col>
    </v-row>
    <v-divider class="pb-4" />
    <v-row
      no-gutters
      class="pb-4"
    >
      <v-col
        cols="6"
      >
        <span
          class="d-flex pl-4 text-caption text-left font-weight-bold checkout-item-text"
          style="font-size: 16px !important;"
        >
          {{ $t('total') }}
        </span>
        <span
          v-if="priceIncludingTaxes"
          class="d-flex pl-4 text--secondary text-caption text-left checkout-item-text"
        >
          {{ $t('includingTaxes') }}
        </span>
      </v-col>
      <v-col
        cols="6"
        align-self="end"
        class="ml-auto mb-auto"
      >
        <div
          class="d-flex pl-4 text-caption text-left font-weight-bold text-right justify-end checkout-item-text"
          style="font-size: 16px !important;"
        >
          {{ formatCurrency(totalPrice, currentLanguage) }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import util from "@/util";
import purchase_mixin from "@/components/mixins/purchase_mixin";
import user_mixin from "@/components/mixins/user_mixin";

export default {
  name: "CheckoutPricesPurchases",
  mixins: [purchase_mixin, user_mixin],
  props: {
    ciders: { type: Array, required: true, default: null },
    priceIncludesCiders: { type: Number, required: false, default: 0 },
    pricePerCider: { type: Number, required: true, default: 0 },
    submissionFee: { type: Number, required: false, default: null },
    products: { type: Array, required: true, default: null },
    currency: { type: String, required: true, default: "€" },
    taxes: { type: Number, required: true, default: 0 },
    priceIncludingTaxes: { type: Boolean, required: true, default: false },
    taxExempt: { type: String, required: true, default: "none" },
  },
  computed: {
    taxExemptText() {
      if (this.taxExempt === 'reverse') return "Reverse Charge";
      if (this.taxExempt === 'exempt') return this.$t('noVatApplies');
      return "None"
    },
    subTotalPrice() {
      return this.$store.getters["submitStore/subTotalPrice"];
      // let subtotal = 0;
      // subtotal += this.submissionFee;
      // if (this.ciders.length > 0) {
      //   subtotal += (this.ciders.length - this.priceIncludesCiders) * this.pricePerCider;
      // }
      // subtotal += this.products.reduce((total, product) => total + product.defaultPrice.unitPrice, 0);
      // return subtotal;
    },
    taxesAmount() {
      return this.$store.getters["submitStore/taxesAmount"];
      // if (this.priceIncludingTaxes) {
      //   return util.util.round_to_decimal(this.subTotalPrice * this.taxes, 0.01);
      // } else {
      //   return util.util.round_to_decimal(this.subTotalPrice * this.taxes, 0.01);
      // }
    },
    totalPrice() {
      return this.$store.getters["submitStore/totalPrice"];
      // if (this.priceIncludingTaxes) {
      //   return util.util.round_to_decimal(this.subTotalPrice, 0.01);
      // } else {
      //   return util.util.round_to_decimal(this.subTotalPrice + this.subTotalPrice * this.taxes, 0.01);
      // }
    },
  },
}
</script>

<style scoped>

</style>